import { useState } from 'react'

export const useCopyBtn = () => {
  const [copied, setCopied] = useState({ name: '', status: false })

  const onCopy = (data: string, fielddName?: string) => {
    navigator.clipboard.writeText(data)
    setCopied({ name: fielddName || '', status: true })
  }
  return { onCopy, copied }
}
