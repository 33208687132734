import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'
import { ILegalDocument } from '../modules/systemManagement/types'
import { IOption } from '../types/form'

export enum EAddEditDocumentFields {
  documentName = 'documentName',
  documentContent = 'documentContent',
  requiresApproval = 'requiresApproval',
  identifier = 'identifier'
}
export interface IFormValues {
  [EAddEditDocumentFields.documentName]: string
  [EAddEditDocumentFields.documentContent]: string
  [EAddEditDocumentFields.identifier]: string
  [EAddEditDocumentFields.requiresApproval]: string
}

export const useAddEditDocumentSchema = (field?: ILegalDocument) => {
  const { t, i18n } = useTranslation('systemManagement')

  const radioList: IOption[] = [
    { value: 'true', label: i18n.t('radioList.yes') },
    { value: 'false', label: i18n.t('radioList.no') }
  ]

  const getDefaultValues = (obj?: ILegalDocument) => ({
    [EAddEditDocumentFields.documentName]: obj?.name || '',
    [EAddEditDocumentFields.documentContent]: obj?.content || '',
    [EAddEditDocumentFields.identifier]: obj?.identifier || '',
    [EAddEditDocumentFields.requiresApproval]: radioList[0].value
  })
  const defaultValues = useMemo(() => getDefaultValues(field), [field])

  const validationSchema = object().shape({
    [EAddEditDocumentFields.documentName]: string().required(
      t('emailTemplates.form.errors.invalid')!
    ),
    [EAddEditDocumentFields.documentContent]: string(),
    [EAddEditDocumentFields.requiresApproval]: string().required(
      t('emailTemplates.form.errors.invalid')!
    )
  })

  return { validationSchema, defaultValues, radioList }
}
