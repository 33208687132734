import { useTranslation } from 'react-i18next'
import { SDKSelect } from '../../../components/SDK/Select'
import { Theme } from '../../../constants/theme'
import { useApp } from '../../../context/app.context'

export const SelectTheme = () => {
  const { t } = useTranslation()
  const {
    updateStyleContext,
    appContext: {
      style: { theme }
    }
  } = useApp()

  const data = Object.entries(Theme).map(([label, value]) => ({
    value,
    label
  }))

  const onSelect = (val: Theme) => {
    updateStyleContext({ theme: val })
  }

  return (
    <SDKSelect
      data={data}
      value={theme}
      className='select-theme ap-header-options-item'
      placeholder={t('header.chooseTheme')!}
      onSelect={(val: Theme) => onSelect(val)}
    />
  )
}
