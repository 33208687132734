import api from '../../api'
import { CountrySnapshotUrl, FiscalYearUrl } from '../../constants/urls'

export const deleteSubcategoryRequest = async (
  yearId: number,
  categoryId: number,
  subcategoryId: number
): Promise<string> => {
  return (
    await api.delete(
      `${FiscalYearUrl.Years}/${yearId}/${CountrySnapshotUrl.Categories}/${categoryId}/${CountrySnapshotUrl.Subcategories}/${subcategoryId}`
    )
  ).data
}
