import { ITemplateCalculation } from '../../../modules/project/types/projectCalculation'
import api from '../../api'
import { CalculationUrl } from '../../constants/urls'

export const uploadDataBySightlineWithCalculation = async (
  dateOnly: boolean,
  sightlineInputFileId: string,
  projectId: number
): Promise<ITemplateCalculation> => {
  return (
    await api.post(
      `${CalculationUrl.Calculation}/${projectId}/${CalculationUrl.Sightline}?sightlineInputFileId=${sightlineInputFileId}&?dateOnly=${dateOnly}`
    )
  ).data
}
