import type { IParentScopeYearTotal } from '../../../../types/scope'

export const useFeesAmountCalculation = (yearsCount: number, fees?: IParentScopeYearTotal[]) => {
  const modifiedFees =
    yearsCount && fees && yearsCount === fees.length
      ? fees
      : fees?.filter((_, index) => !(index >= yearsCount))

  return { modifiedFees: modifiedFees || [] }
}
