export enum EPricingTab {
  ScopeDefinition = 'scopeDefinition',
  ProjectSetup = 'projectSetup',
  MasterFile = 'masterFile',
  LocalFile = 'localFile',
  Benchmark = 'benchmarkingStudies',
  TPForms = 'tpForms',
  CbCR = 'cbCR',
  DataCollection = 'dataCollection',
  ScopeAssumptions = 'scopeAssumptions',
  ProjectManagement = 'projectManagement',
  Discount = 'discount',
  CustomModule = 'customModule'
}

export enum EPricingTabId {
  ScopeDefinition = 1,
  ProjectSetup = 2,
  MasterFile = 3,
  LocalFile = 4,
  Benchmark = 5,
  TPForms = 6,
  CbCR = 7,
  DataCollection = 8,
  ProjectManagement = 9,
  Discount = 10,
  ScopeAssumptions = 11,
  CustomModule = 12
}

export const LAST_PRICING_TAB_ID = EPricingTabId.ScopeAssumptions

export const PricingTab: { [x: number]: EPricingTab } = {
  [EPricingTabId.ScopeDefinition]: EPricingTab.ScopeDefinition,
  [EPricingTabId.ProjectSetup]: EPricingTab.ProjectSetup,
  [EPricingTabId.MasterFile]: EPricingTab.MasterFile,
  [EPricingTabId.LocalFile]: EPricingTab.LocalFile,
  [EPricingTabId.Benchmark]: EPricingTab.Benchmark,
  [EPricingTabId.TPForms]: EPricingTab.TPForms,
  [EPricingTabId.CbCR]: EPricingTab.CbCR,
  [EPricingTabId.DataCollection]: EPricingTab.DataCollection,
  [EPricingTabId.ProjectManagement]: EPricingTab.ProjectManagement,
  [EPricingTabId.Discount]: EPricingTab.Discount,
  [EPricingTabId.ScopeAssumptions]: EPricingTab.ScopeAssumptions
}

export enum ELocalFileCardType {
  Basic = 'basic',
  Sightline = 'sightline'
}

export enum EScope {
  DesktopReview = 1,
  LocalRegulatoryAndTechnicalReview = 2,
  LocalValidationAndTechnicalReview = 3,
  LocalPreparationBasedOnCentralModules = 4,
  CentralPreparation = 5
}

export enum EComplexity {
  Small = 1,
  Medium = 2,
  Large = 3
}

export enum EBenchmarkingType {
  Global = 1,
  Regional = 2,
  Local = 3
}

export enum ETemplateType {
  TaraFull = 1,
  TaraCalendarOnly = 2
}

export enum EPriceType {
  Fee = 1,
  Translation = 2
}

export enum ELocalFileSyncType {
  Synced = 1,
  OutOfSync = 2
}
