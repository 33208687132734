import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { SDKBadge } from '../../../../../../../../../components/SDK/Badge'
import { SDKParagraph } from '../../../../../../../../../components/SDK/Paragraph'
import { SDKText } from '../../../../../../../../../components/SDK/Text'
import { useProjectFromUrl } from '../../../../../../../../../hooks/useProjectFromUrl'
import { useCardGridColumnCalculation } from '../../../../../../../../../utils/useCardGridColumnCalculation'
import { useTransformNumberToMoney } from '../../../../../../../utils/useTransformNumberToMoney'
import { EFeeType } from '../../../../../types/resultsTab'
import type { IParentScopeYearTotal } from '../../../../../types/scope'
import { useGetTotalTitleByType } from '../../utils/getTotalTitleByType'
import { useFeesAmountCalculation } from '../../utils/useFeesAmountCalculation'
import style from './index.module.scss'

interface ITotalCard {
  fees: IParentScopeYearTotal[]
  sum: number
  type: EFeeType
  discountPercentage?: number
  customSubtitle?: string
  isGreyBg?: boolean
  showQuotesWithFractionDigits?: boolean
}

export const TotalCard = ({
  type,
  fees,
  sum,
  customSubtitle,
  discountPercentage,
  isGreyBg = true,
  showQuotesWithFractionDigits
}: ITotalCard) => {
  const { t } = useTranslation('pricing')
  const { textCol, feeCol } = useCardGridColumnCalculation()
  const { project } = useProjectFromUrl()
  const yearsCount = project?.yearsCount ? project.yearsCount : 1
  const { modifiedFees } = useFeesAmountCalculation(yearsCount, fees)
  const { totalTitle } = useGetTotalTitleByType(type)
  const transformNumberToMoney = useTransformNumberToMoney()

  const isPrimaryColor = type === EFeeType.Discount
  const withBadge = type === EFeeType.Discount && discountPercentage !== null
  const firstLetterToLowerCase = (s: string) => s[0].toLocaleLowerCase() + s.slice(1)

  const subtitle =
    customSubtitle || isPrimaryColor
      ? `${t('tabs.results.totalDiscount')}:`
      : type === EFeeType.Total
      ? t('tabs.results.totals.grandTotal')
      : `${t(`tabs.results.totals.${firstLetterToLowerCase(EFeeType[type])}`)}:`
  const textColor = isPrimaryColor ? 'ap-text-color-text-primary' : 'ap-text-neutral-17'
  const subtitleTextColor = isPrimaryColor ? 'ap-text-color-text-primary' : 'ap-text-neutral-14'

  return (
    <div
      className={classNames(
        style['total-card'],
        isPrimaryColor && style['dotted-border'],
        isGreyBg
          ? 'ap-bg-color-background-default ap-my-spacing-5 ap-p-spacing-6'
          : 'ap-bg-color-background-alt'
      )}
    >
      <div className='row g-0 w-100'>
        <div className={`col col-${textCol} p-0 ap-mb-spacing-5`}>
          <div className='ap-flex justify-content-between'>
            <SDKText type='subheading' className={textColor} weight={2}>
              {totalTitle}
            </SDKText>
            {withBadge && (
              <div className='ap-flex'>
                <SDKBadge
                  type='primary'
                  value={`${discountPercentage}%`}
                  className='ap-mr-spacing-7 '
                />
                <div className='border-right h-100'></div>
              </div>
            )}
          </div>
        </div>

        <div className={`col col-sm-${feeCol} ap-pl-spacing-7 ap-pr-spacing-1`}>
          <div className={`ap-flex justify-content-around align-items-center w-100`}>
            {modifiedFees?.map((item, index) => (
              <div
                key={`key-${item.totalValue}-${index}`}
                className={`col col-sm-${12 / yearsCount} text-align-right ap-pr-spacing-4`}
              >
                <SDKText type='subheading' className={textColor} weight={2}>
                  {transformNumberToMoney(item.totalValue, showQuotesWithFractionDigits)}
                </SDKText>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='ap-flex justify-content-end ap-mt-spacing-3'>
        <SDKParagraph type='body' className={subtitleTextColor} weight={2}>
          {subtitle}
        </SDKParagraph>
      </div>
      <div className='ap-flex justify-content-end'>
        <SDKParagraph type='heading' className={subtitleTextColor} weight={2}>
          {transformNumberToMoney(sum, showQuotesWithFractionDigits)}
        </SDKParagraph>
      </div>
    </div>
  )
}
