import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../../store'

export const getCategories = (state: RootState) => state.countriesSnapshot.categories
export const getAddCategory = (state: RootState) => state.countriesSnapshot.addCategory
export const getUpdateCategory = (state: RootState) => state.countriesSnapshot.updateCategory
export const getCategoryById = (id: number | null) =>
  createSelector(getCategories, state => state.data.find(({ categoryId }) => id === categoryId))
export const getSubcategoryById = (catId: number | null, subcatId: number | null) =>
  createSelector(getCategories, state =>
    state.data
      .find(({ categoryId }) => catId === categoryId)
      ?.subcategories.find(({ subcategoryId }) => subcatId === subcategoryId)
  )
export const getAddSubcategory = (state: RootState) => state.countriesSnapshot.addSubcategory
export const getUpdateSubcategory = (state: RootState) => state.countriesSnapshot.updateSubcategory

export const deleteCategory = (state: RootState) => state.countriesSnapshot.deleteCategory
export const deleteSubcategory = (state: RootState) => state.countriesSnapshot.deleteSubcategory

export const getQuestions = (state: RootState) => state.countriesSnapshot.questions
export const getQuestionById = (id: number) =>
  createSelector(getQuestions, state => state.data.find(({ questionId }) => id === questionId))
export const deleteQuestion = (state: RootState) => state.countriesSnapshot.deleteQuestion
export const swapQuestionsOrder = (state: RootState) => state.countriesSnapshot.swapQuestionsOrder
export const getAddQuestion = (state: RootState) => state.countriesSnapshot.addQuestion
export const getUpdateQuestion = (state: RootState) => state.countriesSnapshot.updateQuestion
