export enum ECardListItem {
  LegalDocumentCard = 'legal-document-card',
  PowerBITemplateCard = 'power-bi-template-card',
  ProjectCard = 'project-card',
  QuestionnaireQuestionCard = 'questionnaire-question-card',
  TaraFieldsCard = 'requirements-deadlines-penalties-fields-card',
  UserCard = 'user-card'
}

export enum EAction {
  Copy = 'copy',
  RollForward = 'rollForward',
  Delete = 'delete',
  Edit = 'edit'
}
