import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Url } from '../../../constants/urls'
import { useApp } from '../../../context/app.context'
import type { IProjectCard } from '../../../modules/myprojects/types'
import { formatLocalFromUtc } from '../../../utils/dateFormatters'
import { Divider } from '../../Divider'
import { Label } from '../../Label'
import { SDKBadge } from '../../SDK/Badge'
import { SDKIcon } from '../../SDK/Icon'
import { SDKPanel } from '../../SDK/Panel'
import { SDKText } from '../../SDK/Text'
import { SDKTextButton } from '../../SDK/TextButton'
import { CardField } from '../components/CardField'
import { CardFooter } from '../components/CardFooter'
import { DropdownMenu } from '../components/DropdownMenu'
import { EAction } from '../const'
import { IActionCallback } from '../type'
import './ProjectCard.scss'

export const ProjectCard = ({
  project,
  actionCallback
}: {
  actionCallback: (_callback: IActionCallback) => void
  project: IProjectCard
}) => {
  const { t } = useTranslation('myprojects')
  const navigate = useNavigate()
  const {
    appContext: {
      style: { isMobile }
    }
  } = useApp()

  const menuItems = [
    {
      label: t('projectCard.functions.copy'),
      iconName: 'copy',
      url: Url.MyProjectsPage,
      onClick: (id: number) => actionCallback?.({ action: EAction.Copy, id })
    },
    {
      label: t('projectCard.functions.rollForward'),
      iconName: 'duplicate',
      url: Url.MyProjectsPage,
      onClick: (id: number) => actionCallback?.({ action: EAction.RollForward, id })
    },
    {
      label: t('projectCard.functions.delete'),
      iconName: 'delete',
      url: Url.LoginPage,
      onClick: (id: number) => actionCallback?.({ action: EAction.Delete, id })
    }
  ]

  const dropdownMenu = <DropdownMenu id={project.projectId} menuItems={menuItems} />

  const fiscalYear = (
    <>
      <Label>{t('projectCard.fiscalYear.label')}</Label>
      <div>
        <SDKBadge value={project.yearName} size={'lg'} type={'info-outlined'} />
      </div>
    </>
  )

  const projectName = (
    <SDKText type='heading' weight={3}>
      {project.name}
    </SDKText>
  )

  const viewProject = (
    <SDKTextButton
      onClick={() => navigate(`${Url.MyProjectsPage}/${project.projectId}#CS-questions`)}
      className='ap-link text-no-wrap'
    >
      <>
        {t('projectCard.viewProject.text')} <SDKIcon code='right-chevron'></SDKIcon>
      </>
    </SDKTextButton>
  )

  const warning = (
    <>
      <SDKIcon code='warning' outline={false} className='warning-icon' />
      <SDKText>{t('projectCard.warning')}</SDKText>
    </>
  )

  if (isMobile) {
    return (
      <SDKPanel
        className='project-card'
        footer={
          <CardFooter>
            <>
              <div className='col col-8 ap-flex align-items-center justify-content-start'>
                {!!project.hasChangedValues && warning}
              </div>

              <div className='col col-4 ap-flex align-items-center justify-content-end'>
                {viewProject}
              </div>
            </>
          </CardFooter>
        }
      >
        <div className='ap-container mx-0 gx-0'>
          <div className='row'>
            <div className='col col-10 text-ellipsis'>{projectName}</div>

            <div className='col col-2'>{dropdownMenu}</div>
          </div>
        </div>

        <div className='ap-container mx-0'>
          <div className='row'>
            <div className='col col-6'>{fiscalYear}</div>

            <div className='col col-6'>
              <div className='ap-container mx-0'>
                <div className='row'>
                  <div className='ap-flex'>
                    <div className='col col-6'>
                      <CardField
                        label={t('projectCard.createdDate.label')}
                        value={formatLocalFromUtc(project.createdDate)}
                      />
                    </div>
                    <div className='col col-6'>
                      <CardField
                        label={t('projectCard.updatedDate.label')}
                        value={formatLocalFromUtc(project.updatedDate)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Divider />
      </SDKPanel>
    )
  } else {
    return (
      <SDKPanel className='project-card desktop'>
        <div className='ap-container mx-0'>
          <div className='row'>
            <div className='col'>{projectName}</div>
            <div className='col secondary-column'>
              <div className='ap-container mx-0'>
                <div className='row ap-flex justify-content-end'>
                  <div className='col col-3 ap-flex align-items-center justify-content-start'>
                    {!!project.hasChangedValues && warning}
                  </div>
                  <div className='col col-2 right-divider'>{fiscalYear}</div>
                  <div className='col col-2 right-divider'>
                    <CardField
                      label={t('projectCard.createdDate.label')}
                      value={formatLocalFromUtc(project.createdDate)}
                    />
                  </div>
                  <div className='col col-2'>
                    <CardField
                      label={t('projectCard.updatedDate.label')}
                      value={formatLocalFromUtc(project.updatedDate)}
                    />
                  </div>
                  <div className='col col-2'>{viewProject}</div>
                  <div className='col col-1'>{dropdownMenu}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SDKPanel>
    )
  }
}
