import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { SDKPanel } from '../../../../../components/SDK/Panel'
import { SDKParagraph } from '../../../../../components/SDK/Paragraph'
import { PricingTabContextProvider, usePricingTab } from '../../../../../context/pricingTab.context'
import { useTransformNumberToMoney } from '../../../utils/useTransformNumberToMoney'
import TabWrapper from '../components/TabWrapper'
import { usePricingTabs } from '../hooks/usePricingTabs'
import { createCustomModule } from '../store/selectors'
import { ControllableTabs } from './components/ControllableTabs'
import style from './tabs.module.scss'

export const GCDPricingInputs = () => {
  const { t } = useTranslation('pricing')
  const [activeIndex, setActiveIndex] = useState(0)
  const [groundTotal, setGroundTotal] = useState(0)
  const { tabs } = usePricingTabs(activeIndex)
  const { setCustomModuleId } = usePricingTab()
  const transformNumberToMoney = useTransformNumberToMoney()
  const { data } = useSelector(createCustomModule)

  useEffect(() => {
    if (data) {
      setCustomModuleId(data.moduleId)
    }
  }, [data])

  return (
    <div className={style.inputs}>
      <SDKParagraph type='subheading' className='text-align-right ap-mb-spacing-4' weight={2}>
        {t('tabs.inputs.grandTotal', { value: transformNumberToMoney(groundTotal, true) })}
      </SDKParagraph>

      <SDKPanel>
        <ControllableTabs activeIndex={activeIndex} setActiveIndex={setActiveIndex} tabs={tabs} />
        <PricingTabContextProvider
          currentTabIndex={activeIndex + 1}
          setCurrentTabIndex={setActiveIndex}
        >
          <TabWrapper setGroundTotal={setGroundTotal} />
        </PricingTabContextProvider>
      </SDKPanel>
    </div>
  )
}
