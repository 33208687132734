import { useEffect } from 'react'
import type { UseFormReset } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../../../../../context/app.context'
import { useProject } from '../../../../../../../context/project.context'
import type { IFormValues } from '../../../../../../../schemas/useAddEditScopeSchema'
import { LoadingStatus } from '../../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../../store'
import type { IChildScope } from '../../../types/scope'
import * as actions from '../../store/actions'
import * as selectors from '../../store/selectors'
import { usePricingTab } from './../../../../../../../context/pricingTab.context'

export const useChildScope = (
  scope: IChildScope | null,
  onCloseModal: (params?: { refreshData: boolean }) => void,
  reset: UseFormReset<IFormValues>
) => {
  const { t } = useTranslation('pricing')
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { loading, error } = useSelector(
    scope?.childScopeId ? selectors.updateChildScope : selectors.createChildScope
  )
  const { project } = useProject()
  const clearAction = scope?.childScopeId
    ? actions.clearUpdateChildScope
    : actions.clearCreateChildScope
  const notificationText = t(
    `tabs.inputs.childScopeModal.notification.${
      scope?.childScopeId ? 'editSuccessInfo' : 'addSuccessInfo'
    }`,
    {
      name: scope?.name
    }
  )
  const {
    customModuleId,
    tabContent: { tab }
  } = usePricingTab()

  const onClose = (params?: { refreshData: boolean }) => {
    dispatch(clearAction())
    reset()
    onCloseModal(params)
  }

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: notificationText,
        status: 'success'
      })
      onClose({ refreshData: true })
    } else if (loading === LoadingStatus.Failed && error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
      onClose()
    }
  }, [loading])

  const onSubmit = (data: IFormValues) => {
    if (scope?.childScopeId) {
      dispatch(
        actions.updateChildScope({
          projectId: project?.projectId!,
          scope: {
            customModuleId,
            ...scope,
            ...data
          },
          tab
        })
      )
    } else {
      dispatch(
        actions.createChildScope({
          projectId: project?.projectId!,
          scope: {
            customModuleId,
            ...scope,
            ...data
          } as Partial<IChildScope>,
          tab
        })
      )
    }
  }

  return { onSubmit, onClose, loading }
}
