import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { HeaderButtons } from '../../../../components/HeaderButtons'
import { Layout } from '../../../../components/Layout'
import { useSubmitButton } from '../../../../hooks/useSubmitButton'
import {
  EAddEditDocumentFields,
  IFormValues,
  useAddEditDocumentSchema
} from '../../../../schemas/useAddEditDocumentSchema'
import { useAppDispatch } from '../../../../store'
import * as actions from '../../store/actions'
import { getLegalDocumentById, getLegalDocuments } from '../../store/selectors'
import { AddEditDocumentForm } from './components/AddEditDocumentForm'
import { useAddEditLegalDocument } from './utils/useAddEditLegalDocument'

export const AddEditLegalDocument = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const currentDocument = useSelector(getLegalDocumentById(Number(id)))
  const documents = useSelector(getLegalDocuments)
  const { validationSchema, defaultValues, radioList } = useAddEditDocumentSchema(currentDocument)

  useEffect(() => {
    if (!documents.data.length) {
      dispatch(actions.getLegalDocuments())
    }
  }, [documents])

  const { handleSubmit, control, setError, setValue, formState } = useForm<
    FieldValues,
    IFormValues
  >({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    Object.keys(defaultValues).map((key: string) =>
      setValue(key as EAddEditDocumentFields, defaultValues[key as EAddEditDocumentFields])
    )
  }, [currentDocument])

  const { onSubmit, error } = useAddEditLegalDocument(setError, currentDocument)
  const { disabled } = useSubmitButton({ formState })

  return (
    <Layout
      buttonComponent={
        <HeaderButtons
          icon='save'
          name={t('buttons.save')}
          onClick={handleSubmit(onSubmit)}
          disabled={disabled}
        />
      }
    >
      <form>
        <AddEditDocumentForm
          control={control}
          isDirty={formState.isDirty}
          radioList={radioList}
          error={error}
        />
      </form>
    </Layout>
  )
}
