export function kebabCase2pascalCase(
  text?: string | null,
  type: 'upper' | 'lower' = 'upper'
): string {
  if (!text) {
    return ''
  }

  const pascalCase = text
    .trim()
    .toLowerCase()
    .replace(/-[a-z]/g, (cutText: string) => {
      return cutText.substring(1, 2).toUpperCase()
    })

  return type === 'upper'
    ? pascalCase.substring(0, 1).toUpperCase() + pascalCase.substring(1)
    : pascalCase
}
