import { useTranslation } from 'react-i18next'
import { SDKIcon } from '../../../../../../components/SDK/Icon'

interface INavigationMobileHeaderProps {
  setSidebarVisibility: () => void
}

export const NavigationMobileHeader = ({ setSidebarVisibility }: INavigationMobileHeaderProps) => {
  const { t } = useTranslation()

  return (
    <div onClick={setSidebarVisibility} className='pointer'>
      <span className='ap-text-color-text-body ap-mr-spacing-2'>
        <SDKIcon code='close'></SDKIcon>
      </span>
      <span>{t('nav.close')}</span>
    </div>
  )
}
