import { useEffect } from 'react'
import { DESKTOP_MIN_WIDTH } from '../components/Layout/const'
import { useApp } from '../context/app.context'

export const useDetectIsMobile = () => {
  const { updateStyleContext } = useApp()

  const handleScreenType = () => {
    updateStyleContext({ isMobile: !!(window.innerWidth < DESKTOP_MIN_WIDTH) })
  }

  useEffect(() => {
    window.addEventListener('resize', handleScreenType)
    handleScreenType()
    return () => window.removeEventListener('resize', handleScreenType)
  }, [])
}
