import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Module } from '../../../constants/modules'
import type { IGetNewsfeedEventsPayload } from '../../../services/requests/updates/getNewsfeedEvents'
import { getNewsfeedEvents } from '../../../services/requests/updates/getNewsfeedEvents'

const moduleName: string = String(Module.Updates)

export const getNewsfeed = createAsyncThunk(
  `${moduleName}/getNewsfeed`,
  ({ searchValue, sortOrder, limit, offset }: IGetNewsfeedEventsPayload) =>
    getNewsfeedEvents({ searchValue, sortOrder, limit, offset })
)
export const clearGetNewsfeed = createAction(`${moduleName}/clearGetNewsfeed`)
