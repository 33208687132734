import { RefObject, useEffect } from 'react'

export const useOutsideNavigation = (
  ref: RefObject<HTMLInputElement>,
  setSidebarVisibility: () => void,
  isMobile: boolean,
  sidebarVisibility: boolean
) => {
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (
        sidebarVisibility &&
        isMobile &&
        ref.current &&
        !ref.current.contains(event.target as Node)
      ) {
        setSidebarVisibility()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, sidebarVisibility, isMobile])
}
