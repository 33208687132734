export enum Url {
  AddCountryPage = '/add-country',
  AddLegalDocumentPage = '/add-document',
  AddPricingToolImport = '/add-pricing-tool-import',
  AddProjectPage = '/add-project',
  AddQuestionPage = '/add-question',
  AddTaraFieldsPage = '/add-fields',
  AuthenticationFailedPage = '/authentication-failed',
  ChangedValuesPage = '/changed-values',
  CookiesPolicy = '/cookies-policy',
  CountriesPage = '/countries',
  CountriesSnapshotPage = '/countries-snapshots',
  CountrySnapshotPage = '/country-snapshots',
  DashboardPage = '/dashboard',
  IndexPage = '/',
  EditCountryPage = '/edit-country',
  EditEmailTemplatePage = '/edit-email-template',
  EditLegalDocumentPage = '/edit-document',
  EditProjectPage = '/edit-project',
  EditQuestionPage = '/edit-question',
  EditTaraFieldsPage = '/edit-fields',
  EmailTemplatesPage = '/email-templates',
  FiscalYearPage = '/fiscal-year',
  GateQuestions = '/gate-questions',
  LegalDocumentsPage = '/legal-documents',
  LoginPage = '/login',
  MyProjectsPage = '/my-projects',
  NotFound = '/404',
  PowerBITemplatesManagementPage = '/power-bi-templates-management',
  PowerBITemplatesPage = '/power-bi-templates',
  PreferencesPage = '/preferences',
  PricingToolImportPage = '/pricing-tool-import',
  ProjectSettingsPage = '/project-settings',
  QuestionnairesPage = '/questionnaire',
  RegionsPage = '/regions',
  ReportsPage = '/reports',
  Search = '/search',
  Server = '/server',
  SignOutPage = '/sign-out',
  SystemManagementPage = '/system-management',
  TaraFieldsPage = '/requirements-deadlines-penalties-fields',
  Updates = '/updates',
  UsersPage = '/users'
}

export const AVATAR_URL = `${process.env.REACT_APP_AVATAR_URL}Person/Image/`
