import { useTranslation } from 'react-i18next'
import { EAnswerTypeForEdit } from '../../../modules/countriesSnapshot/pages/AddEditQuestion/constants/answerType'
import { TCalendarQuestionOptions } from '../../../modules/countriesSnapshot/types'
import { IAnswer, ICalendarAnswerResponse } from '../../../modules/project/types/projectResults'
import { convertToTranslationCalendarName } from '../../../utils/convertToTranslationCalendarName'
import { SDKText } from '../../SDK/Text'
import './index.scss'

interface IAnswerTextProps {
  answerText: string | null
  type: EAnswerTypeForEdit
  selectedAnswers: IAnswer[]
  id: number
  isNotAnswered: boolean
  daysOffset?: number
  monthsOffset?: number
  calendarQuestionOptions?: TCalendarQuestionOptions
  calendarAnswerResponse?: ICalendarAnswerResponse
  isEnabled?: boolean
}
export const AnswerText = ({
  answerText,
  type,
  calendarQuestionOptions,
  calendarAnswerResponse,
  selectedAnswers,
  id,
  isNotAnswered,
  daysOffset,
  monthsOffset,
  isEnabled
}: IAnswerTextProps) => {
  const { t } = useTranslation('common')
  const answerComponent = () => {
    if (isNotAnswered && !answerText && !selectedAnswers && type !== EAnswerTypeForEdit.Calendar) {
      return <SDKText>{t(isEnabled ? 'notAnswered' : 'na')}</SDKText>
    }
    if (selectedAnswers && selectedAnswers.length) {
      return (
        <>
          {selectedAnswers.map(selectedAnswer => (
            <div key={`${id}-${selectedAnswer.id}`}>{selectedAnswer.possibleAnswerText}</div>
          ))}
        </>
      )
    }
    const displayText = (name: string) =>
      t(`calendarOptions.${convertToTranslationCalendarName(name, true)}`)

    switch (type) {
      case EAnswerTypeForEdit.Calendar:
        if (answerText) return <SDKText>{answerText}</SDKText>
        else if (calendarAnswerResponse)
          return (
            <>
              <div>{displayText(calendarAnswerResponse.dateActionPerformer)}</div>
              <div>{displayText(calendarAnswerResponse.dateOffsetType)}</div>
              <div>{`${t('calendarOptions.deadlineDate')}: ${
                calendarAnswerResponse.monthsOffset
              } ${t('calendarOptions.months')}, ${calendarAnswerResponse.daysOffset} ${t(
                'calendarOptions.days'
              )}`}</div>
            </>
          )
        else if (calendarQuestionOptions) {
          const calendarOptions = Object.entries(calendarQuestionOptions)
            .filter(option => option[1])
            .map(option => t(`calendarOptions.${t(option[0])}`))
          const calendarAnswer = [
            ...calendarOptions,
            `${t('calendarOptions.deadlineDate')}: ${monthsOffset || 0} ${t(
              'calendarOptions.months'
            )}, ${daysOffset || 0} ${t('calendarOptions.days')}`
          ] as string[]
          return (
            <>
              {calendarAnswer.map((answer, i) => (
                <div key={`calendar-${id}-${i}`}>{answer}</div>
              ))}
            </>
          )
        } else return <SDKText>{t('na')}</SDKText>
      default:
        return <>{answerText}</>
    }
  }
  return <div className='answer-wrapper'>{answerComponent()}</div>
}
