import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { RequiredLabel } from '../../../../Form/RequiredLabel'
import './index.scss'

interface IModalFooter {
  withRequired?: boolean
  customFooterComponent?: JSX.Element
  className?: string
}

export const ModalFooter = ({
  withRequired,
  customFooterComponent,
  children,
  className
}: PropsWithChildren<IModalFooter>) => {
  return (
    <div
      className={classNames([
        'default-modal-footer ap-mt-spacing-3',
        !withRequired && !customFooterComponent && 'footer-without-required',
        className
      ])}
    >
      {withRequired ? <RequiredLabel /> : customFooterComponent || <></>}
      <div className={'ap-flex'}>{children}</div>
    </div>
  )
}
