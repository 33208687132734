import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { InfoPanel } from '../InfoPanel'
import { Steps } from './Steps'
import { CalculationActionType } from './UploadModal'

interface ITaraInputProps {
  setActiveIndex: Dispatch<SetStateAction<number>>
  setActiveType: Dispatch<SetStateAction<CalculationActionType | null>>
}

export const TaraInput = ({ setActiveIndex, setActiveType }: ITaraInputProps) => {
  const { t } = useTranslation('myprojects')

  return (
    <div className='ap-mt-spacing-4'>
      <InfoPanel text={t(`projectDetail.projectTabs.tara.input.info`)!} />
      <Steps setActiveIndex={setActiveIndex} setActiveType={setActiveType} />
    </div>
  )
}
