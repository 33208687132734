import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../../../context/app.context'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../store'
import { clearGetCalculationResult, getCalculationResult } from '../../../store/actions'
import * as selectors from '../../../store/selectors'
import { EFileType } from '../../../types/projectCalculation'

export const useDownloadInputData = (
  setShowLoadingModal: (value: boolean) => void,
  calculationId?: number
) => {
  const { t } = useTranslation('myprojects')
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { error, loading, data } = useSelector(selectors.getCalculationResult)

  const downloadInputData = () => {
    if (calculationId) {
      setShowLoadingModal(true)
      dispatch(
        getCalculationResult({
          calculationId: calculationId,
          fileType: EFileType.InputData
        })
      )
    }
  }

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded && data?.fileType === EFileType.InputData) {
      setShowLoadingModal(false)
      sendNotification({
        message: t('projectDetail.projectTabs.tara.results.downloadInputDataSuccess'),
        status: 'success'
      })
    } else if (loading === LoadingStatus.Failed && error?.length) {
      setShowLoadingModal(false)
      sendNotification({
        message: error,
        status: 'error'
      })
    }
    dispatch(clearGetCalculationResult())
  }, [loading])

  return { downloadInputData }
}
