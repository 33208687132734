import type { FiscalYearInfo } from '../../../modules/fiscalYearSetup/types'
import api from '../../api'
import { FiscalYearUrl } from '../../constants/urls'
import type { IAddFiscalYearPayload } from './addFiscalYear'

export interface IUpdateFiscalYearPayload extends IAddFiscalYearPayload {
  isDeleted: boolean
}

export const updateFiscalYear = async (
  params: IUpdateFiscalYearPayload,
  yearId: number
): Promise<FiscalYearInfo> => {
  return (await api.put(`${FiscalYearUrl.Years}/${yearId}`, params)).data
}
