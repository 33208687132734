import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { usePricingTab } from '../../../../../../context/pricingTab.context'
import { DragLayer } from '../../components/DragLayer'
import { FeeQuote } from '../../components/FeeQuote'
import { LocalFileCardsWrapper } from '../../components/LocalFileCardsWrapper'
import { ScopeConfigurator } from '../../components/ScopeConfigurator'
import { ScopesWrapper } from '../../components/ScopesWrapper'
import { EPricingTab } from '../../const/enums'
import LocalFileCountriesSkippedModal from '../../modals/LocalFileCountriesSkippedModal'
import LocalFileParseErrorModal from '../../modals/LocalFileParseErrorModal'
import UploadFileModal from '../../modals/UploadFileModal'
import { ELocalFile } from '../../types'
import type { ITransformedData } from '../../types/dnd'
import type { ILocalFile } from '../../types/inputsTab'
import { ILocalFileParseError } from '../../types/scope'
import { transformDataWithoutParentScope } from '../../utils/transformDataWithoutParentScope'
import { LocalFileResultsTable } from '../components/LocalFileResultsTable'
import { useLocalFileUpload } from './utils/useLocalFileUpload'

const LocalFileContent = ({ data }: { data: ILocalFile | null }) => {
  const [transformedData, setTransformedData] = useState<ITransformedData | null>(null)
  const [uploadModal, setUploadModal] = useState<ELocalFile | null>(null)
  const [localFileParseErrorModal, setLocalFileParseErrorModal] = useState<
    ILocalFileParseError[] | null
  >(null)
  const [localFileCountriesSkippedModal, setLocalFileCountriesSkippedModal] = useState<
    string[] | null
  >(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const { handleUpload, modalTitle, loading } = useLocalFileUpload(
    uploadModal,
    setUploadModal,
    setLocalFileParseErrorModal,
    setLocalFileCountriesSkippedModal,
    setSelectedFile
  )

  useEffect(() => {
    if (data) {
      const newData = transformDataWithoutParentScope(
        data.scopeIncludes,
        data.scopeExcludes,
        data.scopeAssumes,
        { type: data.type, name: data.name, projectId: data.projectId }
      )
      setTransformedData(newData)
    }
  }, [data])

  return (
    <>
      <ScopeConfigurator name={EPricingTab.LocalFile} moduleMetadata={data?.moduleMetadata}>
        <LocalFileCardsWrapper
          setUploadModal={setUploadModal}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      </ScopeConfigurator>
      {data && data.moduleMetadata.isApplicable && (
        <ScopesWrapper
          totalFee={data.quotes?.scopeTotal}
          withIcons={true}
          localFileSynchronization={data.localFileSynchronization}
          setUploadModal={setUploadModal}
          parentScope={data.parentScopes}
        >
          <>
            {data.parentScopes.length > 0 && (
              <LocalFileResultsTable data={data.parentScopes} quotes={data.quotes} />
            )}
            <FeeQuote
              fees={data.quotes.parentScopeYearTotals}
              withoutButtons={true}
              gridValues={[8, 4]}
              textClass='ap-mr-spacing-4'
              flexStyles='justify-content-end'
              localFile
            />
            {transformedData && (
              <DragLayer transformedData={transformedData} quotes={data.quotes} />
            )}
            {uploadModal && (
              <UploadFileModal
                title={modalTitle}
                setVisible={setUploadModal}
                onSubmit={handleUpload}
                loading={loading}
              />
            )}
            {localFileParseErrorModal && (
              <LocalFileParseErrorModal
                data={localFileParseErrorModal}
                setVisible={setLocalFileParseErrorModal}
              />
            )}
            {localFileCountriesSkippedModal && (
              <LocalFileCountriesSkippedModal
                data={localFileCountriesSkippedModal}
                setVisible={setLocalFileCountriesSkippedModal}
              />
            )}
          </>
        </ScopesWrapper>
      )}
    </>
  )
}

const LocalFile = () => {
  const {
    tabContent: { selector }
  } = usePricingTab()
  const { data } = useSelector(selector)

  return <LocalFileContent data={data as ILocalFile} />
}

export default LocalFile
