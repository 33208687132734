import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FormCard } from '../../../../../../components/Form/FormCard'
import { FormInput } from '../../../../../../components/Form/FormInput'
import { SDKButton } from '../../../../../../components/SDK/Button'
import { SDKTooltip } from '../../../../../../components/SDK/Tooltip'
import { useApp } from '../../../../../../context/app.context'
import { useSubmitButton } from '../../../../../../hooks/useSubmitButton'
import {
  EEEmailTestFields,
  IFormValues,
  useEmailTestSchema
} from '../../../../../../schemas/useEmailTestSchema'
import { LoadingStatus } from '../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../store'
import * as actions from '../../../../store/actions'
import { createLegalDocumentTest } from '../../../../store/selectors'
import './index.scss'

export const EmailTestSection = () => {
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { t } = useTranslation('systemManagement')
  const { id } = useParams()
  const { error, loading } = useSelector(createLegalDocumentTest)
  const { validationSchema, defaultValues } = useEmailTestSchema()

  useEffect(() => {
    if (error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
      dispatch(actions.clearCreateLegalDocumentTest())
    }
    if (loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t('emailTemplates.emailTestForm.successInfo')!,
        status: 'success'
      })
      dispatch(actions.clearCreateLegalDocumentTest())
    }
  }, [error, loading])

  const { handleSubmit, control, formState } = useForm<FieldValues, IFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  })

  const onSubmit = async (data: FieldValues) => {
    dispatch(actions.createLegalDocumentTest({ templateId: id!, email: data.emailAddres }))
  }

  const { disabled } = useSubmitButton({
    formState,
    conditions: {
      mustBeDirty: true
    }
  })

  return (
    <form>
      <div className='email-test-section-wrapper'>
        <div className='email-test-section ap-mt-spacing-4 '>
          <FormCard withRequired title={t('emailTemplates.emailTestForm.testEmail')}>
            <div className='row'>
              <div className='col-md-8'>
                <SDKTooltip content={t('emailTemplates.emailTestForm.fields.emailAddressTooltip')!}>
                  <FormInput
                    name={EEEmailTestFields.emailAddres}
                    type='text'
                    label={t('emailTemplates.emailTestForm.fields.emailAddress')!}
                    required={true}
                    control={control}
                    suffixIcon
                  />
                </SDKTooltip>
              </div>
              <div className='col-md-4'>
                <SDKButton
                  className='send-test-email-btn'
                  onClick={handleSubmit(onSubmit)}
                  type='submit'
                  icon='icon-email-outline'
                  kind='secondary'
                  disabled={disabled}
                >
                  {t('emailTemplates.emailTestForm.sendTestEmail')}
                </SDKButton>
              </div>
            </div>
          </FormCard>
        </div>
      </div>
    </form>
  )
}
