import { useTranslation } from 'react-i18next'
import type { ISDKModal } from '../..'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { SDKButton } from '../../../Button'

export type SaveButtonProps = Pick<
  ISDKModal,
  'disabledSubmit' | 'loading' | 'onSubmit' | 'submitLabel'
>

export const SaveButton = ({ disabledSubmit, loading, onSubmit, submitLabel }: SaveButtonProps) => {
  const { t } = useTranslation()

  return (
    <SDKButton
      onClick={onSubmit}
      disabled={disabledSubmit}
      loading={loading === LoadingStatus.Pending}
      type='submit'
    >
      {submitLabel ? submitLabel : t('buttons.save')}
    </SDKButton>
  )
}
