import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import type { ITabberItem } from '../../../../../components/SDK/Tabber'
import { string2kebabCase } from '../../../../../formatters/string2kebabCase'
import { useProjectFromUrl } from '../../../../../hooks/useProjectFromUrl'
import { useAppDispatch } from '../../../../../store'
import { PRICING_TABS_COMPONENTS, PRICING_TABS_KEYS } from '../const'
import { EPricingTab } from '../const/enums'
import * as actions from '../store/actions'
import * as selectors from '../store/selectors'

export const usePricingTabs = (
  activeIndex: number
): {
  TabComponent: () => JSX.Element
  tabs: ITabberItem[]
  refreshCustomModules: () => void
  refreshIsPermittedTabs: () => void
  isScopeDefinitionCompleted: boolean
  areTransactionsProvided: boolean | null
} => {
  const dispatch = useAppDispatch()
  const { data: customModules } = useSelector(selectors.getCustomModules)
  const { data: scopeDefinitionData } = useSelector(selectors.getScopeDefinitionData)
  const { data: projectSetupData } = useSelector(selectors.getProjectSetup)
  const { project } = useProjectFromUrl()

  const refreshCustomModules = () => {
    if (project) {
      dispatch(actions.getCustomModules(project.projectId))
    }
  }

  useEffect(() => {
    refreshCustomModules()
  }, [])

  const isScopeDefinitionCompleted =
    scopeDefinitionData?.projectId == project!.projectId &&
    scopeDefinitionData?.scopeQuestions.clientName.length !== 0 &&
    scopeDefinitionData?.scopeQuestions.countryId !== 0 &&
    scopeDefinitionData?.scopeQuestions.yearsCount !== 0

  const areTransactionsProvided =
    projectSetupData?.projectId == project!.projectId &&
    projectSetupData?.transactionModule.length > 0

  useEffect(() => {
    if (!areTransactionsProvided && project !== null) {
      dispatch(actions.getProjectSetup({ projectId: project!.projectId }))
    }
  }, [areTransactionsProvided, isScopeDefinitionCompleted])

  const shouldTabBePermitted = (tab: EPricingTab): boolean => {
    let shouldBePermitted = false

    switch (tab) {
      case EPricingTab.ScopeDefinition:
        shouldBePermitted = true
        break
      case EPricingTab.ProjectSetup:
        shouldBePermitted = isScopeDefinitionCompleted
        break
      default:
        shouldBePermitted = isScopeDefinitionCompleted && !!areTransactionsProvided
        break
    }

    return shouldBePermitted
  }

  const { t } = useTranslation('pricing')
  const [currentTabs, setCurrentTabs] = useState<ITabberItem[]>([])

  const tabs = useMemo(() => {
    const predefinedTabs: ITabberItem[] = PRICING_TABS_KEYS.map((tab, index) => {
      return {
        label: `${t(`inputs.tabs.${tab}.tabTitle`)}`,
        value: index,
        hashUrl: string2kebabCase('pricing-' + tab || ''),
        isPermitted: shouldTabBePermitted(tab)
      }
    })

    const customTabs: ITabberItem[] =
      customModules?.map((tab, index) => ({
        label: tab.name,
        value: PRICING_TABS_KEYS.length + index,
        hashUrl: string2kebabCase('pricing-' + tab.name),
        moduleId: tab.moduleId,
        isPermitted: areTransactionsProvided ?? false
      })) || []

    const definedTabs = [...predefinedTabs, ...customTabs].filter(tab => tab.isPermitted)
    setCurrentTabs(definedTabs)
    return definedTabs
  }, [customModules, activeIndex, isScopeDefinitionCompleted, areTransactionsProvided])

  const refreshIsPermittedTabs = () => {
    const updatedTabs = tabs.map(tab => ({ ...tab, isPermitted: true }))
    setCurrentTabs(updatedTabs)
  }

  // TODO remove
  const TabComponent = useMemo(
    () => PRICING_TABS_COMPONENTS[PRICING_TABS_KEYS[activeIndex]],
    [activeIndex]
  )

  return {
    TabComponent,
    tabs: currentTabs,
    refreshCustomModules,
    refreshIsPermittedTabs,
    isScopeDefinitionCompleted,
    areTransactionsProvided
  }
}
