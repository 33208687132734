import api from '../../api'
import { SystemManagementUrl } from '../../constants/urls'

export const deleteLegalStatementDocument = async ({
  documentId
}: {
  documentId: number
}): Promise<null> => {
  return (await api.delete(`${SystemManagementUrl.LegalDocuments}/?documentId=${documentId}`)).data
}
