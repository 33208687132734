import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../../store'

export const getQuestionnaireCountries = (state: RootState) => state.questionnaire.countries

export const getQuestionnaireCountryByName = (CountryName: string) =>
  createSelector(getQuestionnaireCountries, state =>
    state.data.find(({ name }) => name.toLowerCase() == CountryName.toLowerCase())
  )
export const getQuestionnaireQuestions = (state: RootState) => state.questionnaire.questions
export const getQuestionnaireQuestionById = (id?: number) =>
  createSelector(getQuestionnaireQuestions, state =>
    state.data.find(({ questionId }) => id === questionId)
  )
export const getQuestionnaireYears = (state: RootState) => state.questionnaire.years
export const getQuestionnaireYearById = (id: number | null) =>
  createSelector(getQuestionnaireYears, state => state.data.find(({ yearId }) => id === yearId))
export const getQuestionnaireYearByName = (yearName: string) =>
  createSelector(getQuestionnaireYears, state =>
    state.data.find(({ name }) => String(name) === yearName)
  )
export const getAddAnswer = (state: RootState) => state.questionnaire.addAnswer
export const updateCountryPublish = (state: RootState) => state.questionnaire.updateCountryPublish
export const updateCountryVerify = (state: RootState) => state.questionnaire.updateCountryVerify
export const getAllLanguages = (state: RootState) => state.questionnaire.languages
