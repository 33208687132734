import classNames from 'classnames'
import { SDKListItem } from '../../../../components/SDK/ListItem'
import { SDKText } from '../../../../components/SDK/Text'
import { GMT_TIME, NEWSFEED_DATE_FORMAT, formatUtc } from '../../../../utils/dateFormatters'
import styles from './index.module.scss'

export const Newsfeed = (item: {
  index: number
  id: string
  primary: string
  secondary: string
}) => {
  const { index, primary, secondary } = item

  return (
    <SDKListItem
      key={index}
      role='option'
      className={classNames(index % 2 ? 'ap-bg-color-background-default' : '', 'row')}
    >
      <div className='primary-text col-md-10 col-9'>{primary}</div>
      <div
        className={classNames([
          'secondary-text left-divider col-md-2 col-3 ap-pr-spacing-1',
          styles['left-divider']
        ])}
      >
        <SDKText type='body-s' className='ap-text-neutral-14'>
          {`${formatUtc(secondary, NEWSFEED_DATE_FORMAT)} ${GMT_TIME}`}
        </SDKText>
      </div>
    </SDKListItem>
  )
}
