import { object, string } from 'yup'
import type { IChildScope } from '../modules/project/components/GCDPricing/types/scope'

export interface IFormValues {
  name: string
}

export const useAddEditScopeSchema = (scope: IChildScope | null) => {
  const getDefaultValues = (scopeData: IChildScope | null) => ({
    name: scopeData?.name || ''
  })

  const defaultValues = getDefaultValues(scope)

  const validationSchema = object()
    .shape({
      name: string().required()
    })
    .required()

  return {
    defaultValues,
    getDefaultValues,
    validationSchema
  }
}
