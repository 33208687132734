import { useEffect, useState } from 'react'
import type { Control, FieldValues, UseFormResetField, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormCheckboxGroup } from '../../../../../../components/Form/FormCheckboxGroup'
import { FormRadioButton } from '../../../../../../components/Form/FormRadioButton'
import { FormSelect } from '../../../../../../components/Form/FormSelect'
import { FormTextArea } from '../../../../../../components/Form/FormTextArea'
import {
  RHF_SET_VALUE_OPTIONS,
  RHF_SET_VALUE_SHOULD_DIRTY_ONLY
} from '../../../../../../components/Form/utils'
import { SDKCheckbox } from '../../../../../../components/SDK/Checkbox'
import { ISelectValue } from '../../../../../../components/SDK/Select'
import { SDKText } from '../../../../../../components/SDK/Text'
import { EAddQuestionFields, IFormValues } from '../../../../../../schemas/useAddQuestionSchema'
import { IOption } from '../../../../../../types/form'
import { IAnswer } from '../../../../../project/types/projectResults'
import * as selectors from '../../../../store/selectors'
import { EOperator, TCategory, TQuestion, TSubcategory } from '../../../../types'
import './index.scss'

interface IQuestionInfo {
  control: Control<FieldValues, IFormValues>
  question: TQuestion | null
  resetField: UseFormResetField<IFormValues>
  setValue: UseFormSetValue<FieldValues>
  parentQuestion: TQuestion | null
  setParentQuestion: (a: TQuestion) => void
  category: TCategory | null
  subcategory: TSubcategory | null
  isEditMode: boolean
}

export const QuestionDependenciesComponent = ({
  control,
  question,
  resetField,
  setValue,
  parentQuestion,
  category,
  subcategory,
  isEditMode,
  setParentQuestion
}: IQuestionInfo) => {
  const { t } = useTranslation('countriesSnapshot')
  const { data: allQuestions } = useSelector(selectors.getQuestions)
  const [availableQuestions, setAvailableQuestions] = useState<TQuestion[]>([])

  useEffect(() => {
    if (!!category && !!subcategory && allQuestions?.length > 0) {
      const filteredQuestions = allQuestions.filter(
        q =>
          q.category.questionCategoryId == category.categoryId &&
          q.subcategory.questionSubcategoryId == subcategory.subcategoryId &&
          (q.type == 'SingleChoice' || q.type == 'MultipleChoice') &&
          q.questionId != question?.questionId
      )
      setAvailableQuestions(filteredQuestions)
    }
  }, [category, subcategory])

  useEffect(() => {
    if (!isEditMode) {
      return
    }

    const currentParentQuestionId = question?.questionConditions[0]?.parentQuestionId
    const currentParentQuestion = allQuestions.find(q => q.questionId == currentParentQuestionId)

    if (currentParentQuestion) {
      setParentQuestion(currentParentQuestion)
    }
  }, [allQuestions])

  useEffect(() => {
    if (parentQuestion) {
      resetField(EAddQuestionFields.parentQuestionId)
      resetField(EAddQuestionFields.parentQuestionAnswerIds)
      setValue(
        EAddQuestionFields.parentQuestionId,
        parentQuestion?.questionId,
        RHF_SET_VALUE_OPTIONS
      )
      setValue(
        EAddQuestionFields.parentQuestionAnswerIds,
        isEditMode
          ? question?.questionConditions
              ?.filter(q => q.parentQuestionId == parentQuestion.questionId)
              .map(q => q.possibleAnswerDefinitionId)
          : []
      )
    }
  }, [parentQuestion])

  const onSelectParentQuestion = (parentQuestionId: ISelectValue) => {
    const newParentQuestion = allQuestions.find(q => q.questionId === parentQuestionId)
    if (newParentQuestion) {
      resetField(EAddQuestionFields.parentQuestionId)
      resetField(EAddQuestionFields.parentQuestionAnswerIds)
      setParentQuestion(newParentQuestion)
    }
  }

  const onAnswerChange = (value: (string | number)[]) => {
    setValue(EAddQuestionFields.parentQuestionAnswerIds, value, RHF_SET_VALUE_SHOULD_DIRTY_ONLY)
  }

  const operatorOptions: IOption[] = [
    {
      value: EOperator.AND.toString(),
      label: t('newQuestion.form.fields.questionDependencyOperatorAnd')
    },
    {
      value: EOperator.OR.toString(),
      label: t('newQuestion.form.fields.questionDependencyOperatorOr')
    }
  ]

  return (
    <>
      <FormSelect
        name={EAddQuestionFields.parentQuestionId}
        label={t('newQuestion.form.fields.parentQuestion')!}
        required={!!parentQuestion}
        valueKey={'questionId'}
        labelKey={'questionText'}
        className='ap-mb-spacing-5'
        data={availableQuestions}
        control={control}
        onSelect={onSelectParentQuestion}
        showErrorMessage={true}
        disabled={!(!!category && !!subcategory)}
      />

      <div className='ap-mb-spacing-5'>
        <FormCheckboxGroup
          name={EAddQuestionFields.parentQuestionAnswerIds}
          required={true}
          control={control}
          onChange={onAnswerChange}
        >
          {parentQuestion?.possibleAnswers.map((answer: IAnswer) => (
            <SDKCheckbox
              key={`${answer.id}`}
              value={answer.id}
              label={`${answer.possibleAnswerText}`}
            />
          ))}
        </FormCheckboxGroup>
      </div>

      {!!parentQuestion && (
        <>
          <SDKText type='body-s' className='ap-text-neutral-14'>
            {t('newQuestion.form.fields.questionDependencyOperator')}
          </SDKText>

          <FormRadioButton
            name={EAddQuestionFields.questionDependencyOperator}
            className='ap-mb-spacing-5'
            control={control}
            options={operatorOptions}
            withFlex
            required={!!parentQuestion}
          />

          <FormTextArea
            name={EAddQuestionFields.defaultNaText}
            label={t('newQuestion.form.fields.defaultNaText')!}
            className='ap-mb-spacing-5'
            required={!!parentQuestion}
            control={control}
          />
        </>
      )}
    </>
  )
}
