import { Dispatch, SetStateAction, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useSightlineVisibility } from '../../../../../../context/hooks/useSightlineVisibility'
import { useProjectFromUrl } from '../../../../../../hooks/useProjectFromUrl'
import { useAppDispatch } from '../../../../../../store'
import { syncWithSightline } from '../../../../../myprojects/store/actions'
import { ESightlineRedirectAction, SIGHTLINE_ACTION } from '../../../../constants'
import { ELocalFileCardType } from '../../const/enums'
import { getLocalFile } from '../../store/selectors'
import { ELocalFile } from '../../types'
import { LocalFileCard } from '../LocalFileCard'

interface ILocalFileCardsWrapper {
  setUploadModal: Dispatch<SetStateAction<ELocalFile | null>>
  selectedFile: File | null
  setSelectedFile: Dispatch<SetStateAction<File | null>>
}

export const LocalFileCardsWrapper = (props: ILocalFileCardsWrapper) => {
  const { project } = useProjectFromUrl()
  const dispatch = useAppDispatch()
  const { visible } = useSightlineVisibility()
  const { data } = useSelector(getLocalFile)

  useEffect(() => {
    const action = localStorage.getItem(SIGHTLINE_ACTION)
    if (action === ESightlineRedirectAction.SyncWithSightline && project) {
      dispatch(syncWithSightline({ projectId: project.projectId }))
      localStorage.removeItem(SIGHTLINE_ACTION)
    }
  }, [])

  return (
    <div className='row'>
      {data && data.moduleMetadata.isApplicable && (
        <>
          {visible && project?.tpEngagementId && (
            <LocalFileCard
              className='ap-mx-spacing-5'
              type={ELocalFileCardType.Sightline}
              sightlineMetadata={data.sightlineMetadata}
            />
          )}
          <LocalFileCard className='ap-mr-spacing-5' type={ELocalFileCardType.Basic} {...props} />
        </>
      )}
    </div>
  )
}
