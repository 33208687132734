import { useTranslation } from 'react-i18next'
import { SDKText } from '../../SDK/Text'

export const RequiredLabel = () => {
  const { t } = useTranslation()
  return (
    <span className='relative'>
      <span className='required-dot'></span>
      <SDKText type='body-s' className='ap-ml-spacing-5 ap-text-neutral-14'>
        {t('form.requiredFields')!}
      </SDKText>
    </span>
  )
}
