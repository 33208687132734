import type { BadgeProps } from '@appkit4/react-components/badge'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SDKBadge } from '../../../../../../../components/SDK/Badge'

export const FiscalYearStatusBadge = ({ isActive }: { isActive: boolean }) => {
  const { t } = useTranslation('fiscalYearSetup')

  const type = useMemo(
    (): BadgeProps['type'] => (isActive ? 'success-outlined' : 'info-outlined'),
    [isActive]
  )

  const value = useMemo(
    (): string => `fiscalYearStatus.${isActive ? 'isActive' : 'notActive'}`,
    [isActive]
  )

  return <SDKBadge type={type} value={t(value)} />
}
