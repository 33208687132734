import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { EmptyState } from '../../../../components/EmptyState'
import { SDKLoading } from '../../../../components/SDK/Loading'
import { SDKText } from '../../../../components/SDK/Text'
import { useApp } from '../../../../context/app.context'
import { LoadingStatus } from '../../../../shared/types/enums'
import { useAppDispatch } from '../../../../store'
import * as actions from '../../store/actions'
import { getAddQuestion, getQuestions, getUpdateQuestion } from '../../store/selectors'
import { TQuestion } from '../../types'
import { QuestionsList } from '../QuestionsList'

interface IQuestionsContainerProps {
  yearId: number
  openEditQuestion: (qId: number) => void
}

export const QuestionsContainer = ({ yearId, openEditQuestion }: IQuestionsContainerProps) => {
  const { t } = useTranslation('countriesSnapshot')
  const dispatch = useAppDispatch()
  const { data: allQuestions, error, loading } = useSelector(getQuestions)
  const { loading: addQuestionLoading } = useSelector(getAddQuestion)
  const { loading: updateQuestionLoading } = useSelector(getUpdateQuestion)
  const { sendNotification } = useApp()
  const [questionsDictionary, setQuestionsDictionary] = useState<TQuestion[][] | null>(null)

  useEffect(() => {
    return () => {
      dispatch(actions.clearAddQuestion())
      dispatch(actions.clearUpdateQuestion())
    }
  }, [])

  useEffect(() => {
    const questionsBySubcategory: TQuestion[][] = []

    allQuestions
      .map(q => q.category)
      .forEach(category => {
        allQuestions
          .filter(q => q.category == category)
          .map(q => q.subcategory)
          .forEach(subcategory => {
            const test = allQuestions.filter(
              q =>
                q.category.questionCategoryId == category.questionCategoryId &&
                q.subcategory.questionSubcategoryId == subcategory.questionSubcategoryId
            )
            const index = category.questionCategoryId + subcategory.questionSubcategoryId
            questionsBySubcategory[index] = test
          })
      })

    setQuestionsDictionary(questionsBySubcategory)
  }, [allQuestions])

  useEffect(() => {
    if (
      addQuestionLoading === LoadingStatus.Succeeded ||
      updateQuestionLoading === LoadingStatus.Succeeded
    ) {
      sendNotification({
        message: t('newQuestion.form.successInfo'),
        status: 'success'
      })
    }
    if (error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
    }
  }, [addQuestionLoading, error])

  useEffect(() => {
    if (yearId) {
      dispatch(actions.getQuestions(yearId))
    }
  }, [yearId])

  return (
    <>
      {loading === LoadingStatus.Pending && <SDKLoading />}
      {!allQuestions.length && <EmptyState />}
      {loading === LoadingStatus.Succeeded &&
        questionsDictionary?.map(
          questions =>
            questions.length > 0 && (
              <div
                key={`${questions[0].category.name} > ${questions[0].subcategory.name}`}
                className='ap-py-spacing-5'
              >
                <SDKText type='subheading'>{`${questions[0].category.name} > ${questions[0].subcategory.name}`}</SDKText>
                <div>
                  <QuestionsList
                    questions={questions}
                    openEditQuestion={openEditQuestion}
                    yearId={yearId}
                  />
                </div>
              </div>
            )
        )}
    </>
  )
}
