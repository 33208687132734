import { RootState } from '../../../store'

export const getFormulaOverrides = (state: RootState) => state.taraFields.formulaOverrides
export const getGateQuestionsByCountry = (state: RootState) => state.taraFields.gateQuestions
export const getFormulaOverrideByCountry = (state: RootState) =>
  state.taraFields.formulaOverrideByCountry
export const getFormulaOverrideDescription = (state: RootState) =>
  state.taraFields.formulaOverrideDescription
export const updateFormulaOverride = (state: RootState) => state.taraFields.updateFormulaOverride
export const addFormulaOverride = (state: RootState) => state.taraFields.addFormulaOverride
export const addFormulaValidation = (state: RootState) => state.taraFields.formulaValidation
export const updateFormulaOverrideDescription = (state: RootState) =>
  state.taraFields.updateFormulaOverrideDescription
