import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useApp } from '../../../../../context/app.context'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../store'
import { clearGetCalculationResult, getCalculationResult } from '../../../store/actions'
import * as selectors from '../../../store/selectors'
import { EFileType } from '../../../types/projectCalculation'

export const useDownloadExcel = (
  setShowLoadingModal: (value: boolean) => void,
  calculationId?: number
) => {
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { error, loading } = useSelector(selectors.getCalculationResult)

  const downloadExcel = () => {
    if (calculationId) {
      dispatch(
        getCalculationResult({
          calculationId: calculationId,
          fileType: EFileType.Excel
        })
      )
      setShowLoadingModal(true)
    }
  }

  useEffect(() => {
    if (loading === LoadingStatus.Failed && error?.length) {
      setShowLoadingModal(false)
      sendNotification({
        message: error,
        status: 'error'
      })
    } else if (loading === LoadingStatus.Succeeded) {
      setShowLoadingModal(false)
    }
    dispatch(clearGetCalculationResult())
  }, [loading])

  return { downloadExcel }
}
