import { RegionContainer } from '../../../../../../../shared/types/enums'
import { addDefaultSection } from '../../../../../../../shared/utils/addDefaultSections'
import type { IOption } from '../../../../../../../types/form'
import type { IRegion } from '../../../../../../regions/types'

export type TRegionsSection = {
  [key: string]: IOption[]
}

export const mapRegionsDataToOptions = (regions: IRegion[]) => {
  const sections = Object.entries(
    regions.reduce((acc: TRegionsSection, region) => {
      if (!(region.type in RegionContainer)) {
        return acc
      }
      return Object.assign(acc, {
        [region.type]: (acc[region.type] || []).concat({
          value: region.regionId.toString(),
          label: region.name
        })
      })
    }, {})
  )
  return addDefaultSection(sections)
}
