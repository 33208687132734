import type { RadioProps } from '@appkit4/react-components/radio'
import type { Control, FieldValues, Path } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import type { IOption } from '../../../types/form'
import { SDKRadioButton } from '../../SDK/RadioButton'

interface IFormRadioButtonProps<T extends FieldValues> extends RadioProps {
  name: Path<T>
  control: Control<T, any>
  required: boolean
  options: IOption[]
  withFlex?: boolean
  label?: string
  withDivider?: boolean
  classsName?: string
  defaultValue?: number | string
}

type Props<T extends FieldValues> = IFormRadioButtonProps<T>

export const FormRadioButton = <T extends FieldValues>({
  label,
  control,
  name,
  options,
  withDivider,
  defaultValue,
  classsName,
  ...props
}: Props<T>) => {
  return (
    <Controller
      name={name as Path<T>}
      control={control}
      render={({ field }) => (
        <SDKRadioButton
          title={label}
          {...field}
          {...props}
          name={name}
          defaultValue={defaultValue}
          defaultChecked
          options={options}
          onClick={field.onChange}
          fieldValue={field.value}
          withDivider={withDivider}
          className={classsName}
        />
      )}
    />
  )
}
