import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AnswerText } from '../../../../../../../components/QuestionWithAnswersCard/components/AnswerText'
import { SDKAccordion } from '../../../../../../../components/SDK/Accordion'
import { SDKAccordionItem } from '../../../../../../../components/SDK/AccordionItem'
import { SDKColumn } from '../../../../../../../components/SDK/Column'
import { SDKTableSeparatedByLines } from '../../../../../../../components/SDK/TableSeparatedByLines'
import { SDKText } from '../../../../../../../components/SDK/Text'
import {
  EAnswerTypeForEdit,
  EAnswerTypeFromBE
} from '../../../../../../countriesSnapshot/pages/AddEditQuestion/constants/answerType'
import { IAnswer } from '../../../../../../questionnaires/type'
import { IProjectCopy } from '../../../../../types'

interface ICopyProjectSummaryProps {
  data: IProjectCopy
}

export interface ISummaryItem {
  description?: JSX.Element | string
  oldValue: JSX.Element | string
  newValue: JSX.Element | string
}

export interface ISummaryGroup {
  key: string
  title: string
  items: ISummaryItem[]
}

export const CopyProjectSummmaryPage = ({ data }: ICopyProjectSummaryProps) => {
  const { t } = useTranslation('myprojects')
  const [summaryData, setSummaryData] = useState<ISummaryGroup[]>([])

  const returnQuestionAnswer = (questionType: number, answer: IAnswer) => {
    const type = Object.keys(EAnswerTypeFromBE).find(
      key => EAnswerTypeFromBE[key as keyof typeof EAnswerTypeFromBE] === questionType
    )

    let answerText: string
    switch (questionType as EAnswerTypeFromBE) {
      case EAnswerTypeFromBE.CurrencyRate:
      case EAnswerTypeFromBE.TaxRate:
        if (answer.isNotAnswered) {
          answerText = answer.answerText
        } else {
          answerText = answer.rate!.toString()
        }
        break
      default:
        answerText = answer.answerText
        break
    }

    return (
      <AnswerText
        answerText={answerText}
        type={type as EAnswerTypeForEdit}
        calendarAnswerResponse={answer.calendarAnswerResponse}
        selectedAnswers={answer.selectedAnswers}
        id={answer.answerId || 0}
        isNotAnswered={answer.isNotAnswered}
        daysOffset={answer.calendarAnswerResponse?.daysOffset}
        monthsOffset={answer.calendarAnswerResponse?.monthsOffset}
      />
    )
  }

  const getSummaryData = (): ISummaryGroup[] => {
    const result: ISummaryGroup[] = []

    if (data?.missingCountries && data.missingCountries.length > 0) {
      result.push({
        key: 'missing-countries',
        title: t(`projectCard.copyProject.summary.missingCountries`),
        items: data!.missingCountries.map(country => ({
          description: t(`projectCard.copyProject.summary.country`) || '',
          oldValue: country.name,
          newValue: 'Country Not Published In New Year'
        }))
      })
    }

    if (data?.missingQuestions && data.missingQuestions.length > 0) {
      result.push({
        key: 'missing-questions',
        title: t(`projectCard.copyProject.summary.missingQuestions`),
        items: data!.missingQuestions.map(question => ({
          description: t(`projectCard.copyProject.summary.questionText`) || '',
          oldValue: question.questionText,
          newValue: 'Question Not Published In New Year'
        }))
      })
    }

    if (data?.changedQuestions && data.changedQuestions.length > 0) {
      result.push({
        key: 'changed-questions',
        title: t(`projectCard.copyProject.summary.changedQuestions`),
        items: data!.changedQuestions.map(question => ({
          description: question.subcategory,
          oldValue: question.oldQuestionText,
          newValue: question.newQuestionText
        }))
      })
    }

    if (data?.changedAnswers && data.changedAnswers.length > 0) {
      result.push({
        key: 'changed-answers',
        title: t(`projectCard.copyProject.summary.changedAnswers`),
        items: data!.changedAnswers.map(item => ({
          description: (
            <>
              <div>
                <SDKText weight={2} className='ap-text-neutral-14'>{`${item.countryName}`}</SDKText>
              </div>
              <div>
                <SDKText className='ap-text-neutral-14'>{`${item.questionText}`}</SDKText>
              </div>
            </>
          ),
          oldValue:
            item.oldAnswer && item.questionType !== null
              ? returnQuestionAnswer(item.questionType, item.oldAnswer)
              : '',
          newValue:
            item.newAnswer && item.questionType !== null
              ? returnQuestionAnswer(item.questionType, item.newAnswer)
              : ''
        }))
      })
    }

    return result
  }

  useEffect(() => {
    setSummaryData(getSummaryData())
  }, [data])

  return (
    <>
      <div className='w-100'>
        <SDKText className='ap-text-neutral-14'>
          {t(`projectCard.copyProject.summary.description`)}
        </SDKText>
      </div>
      <div className='w-100'>
        <SDKAccordion>
          {summaryData.map(summaryItem => (
            <SDKAccordionItem
              className='ap-bg-color-background-alt'
              itemKey={`item-key-${summaryItem.key}`}
              key={`item-key-${summaryItem.key}`}
              templateHeader={() => (
                <SDKText type='subheading' weight={2}>
                  {summaryItem.title}
                </SDKText>
              )}
              expanded={true}
            >
              <SDKTableSeparatedByLines originalData={summaryItem.items} hasTitle>
                <SDKColumn
                  field={'description'}
                  renderCell={(row: any) => <SDKText weight={2}>{row.description}</SDKText>}
                  key={`desktop-column-${summaryItem.key}-description`}
                >
                  <SDKText className='ap-text-neutral-14'>
                    {t(`projectCard.copyProject.summary.item`)}
                  </SDKText>
                </SDKColumn>
                <SDKColumn
                  field={'oldValue'}
                  renderCell={(row: any) => <SDKText weight={2}>{row.oldValue}</SDKText>}
                  key={`desktop-column-${summaryItem.key}-oldValue`}
                >
                  <SDKText className='ap-text-neutral-14'>
                    {t(`projectCard.copyProject.summary.oldValue`)}
                  </SDKText>
                </SDKColumn>
                <SDKColumn
                  field={'newValue'}
                  renderCell={(row: any) => <SDKText weight={2}>{row.newValue}</SDKText>}
                  key={`desktop-column-${summaryItem.key}-newValue`}
                >
                  <SDKText className='ap-text-neutral-14'>
                    {t(`projectCard.copyProject.summary.newValue`)}
                  </SDKText>
                </SDKColumn>
              </SDKTableSeparatedByLines>
            </SDKAccordionItem>
          ))}
        </SDKAccordion>
      </div>
    </>
  )
}
