import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../../../context/app.context'
import { useAppDispatch } from '../../../../../store'
import { getProjectResults } from '../../../store/actions'
import * as selectors from '../../../store/selectors'
import { mapQuestionsToCountries } from '../../../utils/mapQuestionsToCountries'

export const useResultsTab = (yearId: number, projectId: number) => {
  const { t } = useTranslation('myprojects')
  const dispatch = useAppDispatch()
  const { sendNotification, updateProjectYearIdContext } = useApp()
  const { error, data: questions, loading } = useSelector(selectors.getProjectResults)

  const countries = mapQuestionsToCountries(questions)

  const options = [
    { value: 0, label: t('results.showByCountry') },
    { value: 1, label: t('results.showByQuestion') }
  ]

  useEffect(() => {
    dispatch(getProjectResults({ yearId, projectId }))
    updateProjectYearIdContext(yearId)
  }, [yearId, projectId])

  useEffect(() => {
    if (error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
    }
  }, [error])

  return { options, countries, loading, questions }
}
