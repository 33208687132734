import common from './common.json'
import countries from './countries.json'
import countriesSnapshot from './countriesSnapshot.json'
import documents from './documents.json'
import errors from './errors.json'
import fiscalYearDetails from './fiscalYearDetails.json'
import fiscalYearSetup from './fiscalYearSetup.json'
import myprojects from './myprojects.json'
import pricing from './pricing.json'
import questionnaires from './questionnaires.json'
import regions from './regions.json'
import search from './search.json'
import speechRecognition from './speechRecognition.json'
import systemManagement from './systemManagement.json'
import taraFields from './taraFields.json'
import updates from './updates.json'
import users from './users.json'

const enResources = {
  common,
  countries,
  countriesSnapshot,
  documents,
  errors,
  fiscalYearDetails,
  fiscalYearSetup,
  myprojects,
  pricing,
  questionnaires,
  regions,
  search,
  speechRecognition,
  systemManagement,
  taraFields,
  users,
  updates
}

export default enResources
