import { EPricingTab } from '../../../modules/project/components/GCDPricing/const/enums'
import { IDiscountData } from '../../../modules/project/components/GCDPricing/types/inputsTab'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const getDiscountData = async (projectId: number): Promise<IDiscountData> => {
  return (
    await api.get(
      `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/${ProjectsUrl.Modules}/${EPricingTab.Discount}`
    )
  ).data
}
