import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SDKTabber } from '../../../../components/SDK/Tabber'
import { ECSTab, ECSTabHashUrl } from '../../constants/tab'
import { ProjectCategoriesList } from '../ProjectCategoriesList'
import { ResultsTab } from '../ResultsTab'

const CSTabs = () => {
  const { t } = useTranslation('myprojects')
  const [activeIndex, setActiveIndex] = useState(0)

  const tabs = [
    {
      label: t('projectDetail.questions'),
      value: ECSTab.Questions,
      hashUrl: ECSTabHashUrl.Questions,
      component: <ProjectCategoriesList />
    },
    {
      label: t('projectDetail.results'),
      value: ECSTab.Results,
      hashUrl: ECSTabHashUrl.Results,
      component: <ResultsTab />
    }
  ]
  return (
    <div className='ap-mt-spacing-4'>
      <SDKTabber tabs={tabs} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
      {tabs[activeIndex].component}
    </div>
  )
}

export default CSTabs
