import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { EmptyState } from '../../../../components/EmptyState'
import { SDKLoading } from '../../../../components/SDK/Loading'
import { LoadingOverlay } from '../../../../components/SDK/LoadingOverlay'
import { useApp } from '../../../../context/app.context'
import { LoadingStatus } from '../../../../shared/types/enums'
import { useAppDispatch } from '../../../../store'
import * as actions from '../../store/actions'
import * as selectors from '../../store/selectors'
import type { IMappedProjectCategory, IProjectQuestion } from '../../types/projectQuestions'
import { getSelectedIds } from '../../utils/getSelectedIds'
import { normalizeCategoriesList } from '../../utils/normalizeCategoriesList'
import { ProjectCategoryItem } from '../ProjectCategoryItem'

import { useProjectFromUrl } from '../../../../hooks/useProjectFromUrl'
import './index.scss'

export const ProjectCategoriesList = () => {
  const dispatch = useAppDispatch()
  const [questions, setQuestions] = useState<IProjectQuestion[]>([])
  const [categories, setCategories] = useState<IMappedProjectCategory[]>([])
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const { error, data, loading } = useSelector(selectors.getProjectQuestions)
  const { error: updateError, loading: selectionLoading } = useSelector(
    selectors.getUpdateProjectQuestions
  )
  const { sendNotification } = useApp()
  const { project } = useProjectFromUrl()

  const refreshData = () => {
    if (project) {
      dispatch(
        actions.getProjectQuestions({
          yearId: Number(project.yearId),
          projectId: project.projectId
        })
      )
    }
  }

  useEffect(() => {
    refreshData()
  }, [])

  useEffect(() => {
    refreshData()
  }, [project])

  useEffect(() => {
    setQuestions(data)

    if (data) {
      const selectedQuestionIds = getSelectedIds(data)
      setSelectedIds(selectedQuestionIds)
    }
  }, [data])

  useEffect(() => {
    if (questions) {
      const categoriesList = normalizeCategoriesList(questions)
      const selectedQuestionIds = getSelectedIds(questions)
      setCategories(categoriesList)
      setSelectedIds(selectedQuestionIds)
    }
  }, [questions])

  useEffect(() => {
    if (error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
      dispatch(actions.clearProjectQuestions())
    }
    if (updateError?.length) {
      sendNotification({
        message: updateError,
        status: 'error'
      })
      dispatch(actions.clearUpdateProjectQuestions())
    }
  }, [error, updateError])

  const setNewQuestions = (questionIds: number[]) => {
    const newQuestions = questions.map(question => {
      if (!questionIds.find(q => q === question.questionId)) {
        return { ...question, isSelected: false }
      } else {
        return { ...question, isSelected: true }
      }
    })
    setQuestions(newQuestions)
  }

  const setSelectedQuestionIds = (selectedQuestionIds: number[]) => {
    setNewQuestions(selectedQuestionIds)
    setSelectedIds(selectedQuestionIds)

    if (project) {
      dispatch(
        actions.updateProjectQuestions({
          params: { selectedQuestionIds },
          yearId: Number(project.yearId),
          projectId: project.projectId
        })
      )
    }
  }
  return (
    <div className='project-categories-list-container ap-my-spacing-4'>
      {loading === LoadingStatus.Pending && <SDKLoading />}
      {selectionLoading === LoadingStatus.Pending && <LoadingOverlay />}

      {categories.length === 0 && loading !== LoadingStatus.Pending ? (
        <EmptyState />
      ) : (
        categories.map((category, categoryIndex) => (
          <div className='ap-bg-color-background-alt ap-border-radius-2' key={categoryIndex}>
            <ProjectCategoryItem
              key={String(category.questionCategoryId)}
              category={category}
              categoryIndex={categoryIndex}
              setSelectedQuestionIds={setSelectedQuestionIds}
              questions={questions}
              selectedIds={selectedIds}
            />
          </div>
        ))
      )}
    </div>
  )
}
