import type { CheckboxGroupProps } from '@appkit4/react-components/checkbox'
import type { UseControllerProps } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { SDKCheckboxGroup } from '../../SDK/CheckboxGroup'

interface IFormCheckboxGroup extends CheckboxGroupProps {
  required: boolean
}

type Props = Partial<IFormCheckboxGroup> & UseControllerProps

export const FormCheckboxGroup = ({ required, control, name, ...props }: Props) => {
  return (
    <>
      <Controller
        name={name}
        rules={{ required }}
        control={control}
        render={({ field }) => <SDKCheckboxGroup {...field} {...props} />}
      />
    </>
  )
}
