import { EPricingTab } from '../../../modules/project/components/GCDPricing/const/enums'
import { IBasicPricingData } from '../../../modules/project/components/GCDPricing/types/inputsTab'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const getDataCollectionData = async (projectId: number): Promise<IBasicPricingData> => {
  return (
    await api.get(
      `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/${ProjectsUrl.Modules}/${EPricingTab.DataCollection}`
    )
  ).data
}
