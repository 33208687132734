import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CardListWrapper } from '../../components/CardListWrapper'
import { Layout } from '../../components/Layout'
import { useProjectFromUrl } from '../../hooks/useProjectFromUrl'
import { useAppDispatch } from '../../store'
import FilterProjectsModal from './components/FilterProjectsModal'
import { MyProjectsHeaderButton } from './components/MyProjectsHeaderButton'
import { ProjectList } from './components/ProjectList'
import * as actions from './store/actions'
import * as selectors from './store/selectors'

export const MyProjectsContent = () => {
  const dispatch = useAppDispatch()
  const { clearProject } = useProjectFromUrl()

  const resource = useSelector(selectors.getMyProjects)
  useEffect(() => {
    clearProject()
    dispatch(actions.getMyProjects())
  }, [])

  return (
    <CardListWrapper classNames='my-projects-page mt-2' resource={resource}>
      <ProjectList list={resource.data} />
    </CardListWrapper>
  )
}

const MyProjects = () => {
  const [showFilterModal, setShowFilterModal] = useState(false)

  return (
    <Layout
      buttonComponent={<MyProjectsHeaderButton showFilter={() => setShowFilterModal(true)} />}
    >
      <MyProjectsContent />
      {showFilterModal && <FilterProjectsModal onClose={() => setShowFilterModal(false)} />}
    </Layout>
  )
}

export default MyProjects
