import classNames from 'classnames'
import { Dispatch, PropsWithChildren, SetStateAction, useEffect, useRef, useState } from 'react'
import type { DragSourceMonitor } from 'react-dnd'
import { useDrag, useDrop } from 'react-dnd'
import { useDragOrderUpdate } from '../../hooks/useDragOrderUpdate'
import { IDragItem, ITransformedData, TableType } from '../../types/dnd'
import styles from './index.module.scss'

interface IDraggableItems {
  id: string | number
  index: number
  setContent: Dispatch<SetStateAction<ITransformedData>>
  moveRowHandler: (
    itemId: string,
    hoverIndex: number,
    sourceColumn: string,
    targetColumn: string
  ) => void
  tableId: string
  tableName: TableType
  acceptType: TableType[]
  hoveredItemIndex: number | null
  setHoveredItemIndex: Dispatch<SetStateAction<number | null>>
  content: ITransformedData
}

export const DraggableItem = ({
  children,
  id,
  index,
  setContent,
  moveRowHandler,
  tableId,
  tableName,
  acceptType,
  hoveredItemIndex,
  setHoveredItemIndex,
  content
}: PropsWithChildren<IDraggableItems>) => {
  const ref = useRef<HTMLDivElement>(null)
  const { onUpdateParentScopeOrder, onUpdateChildScopeOrder } = useDragOrderUpdate()
  const [originalContent, setOriginalContent] = useState<ITransformedData>()
  const [, drop] = useDrop({
    accept: acceptType,
    hover(item: IDragItem, monitor) {
      if (!ref.current) return
      if (item.id === id || !monitor.canDrop()) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      const sourceColumn = item.tableId
      const targetColumn = tableId

      //Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset && clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY && hoverClientY > hoverMiddleY) {
        return
      }

      moveRowHandler(item.id, hoverIndex, sourceColumn, targetColumn)
      item.index = hoverIndex
      item.tableId = targetColumn
    }
  })

  const [{ isDragging }, drag] = useDrag({
    type: tableName,
    item: () => {
      if (acceptType.some(item => item === TableType.ScopeWithoutDnD)) return
      setContent(prevState => ({ ...prevState, draggedItem: { id: String(id), index, tableId } }))
      return { id, index, tableId }
    },
    end: (_, monitor: DragSourceMonitor) => {
      setHoveredItemIndex(null)
      const item: IDragItem = monitor.getItem()
      if (monitor.getDropResult()) {
        if (tableName === TableType.Scope) {
          onUpdateParentScopeOrder(Number(item.id), item.index)
        } else {
          onUpdateChildScopeOrder(Number(id), Number(item.tableId), item.index)
        }
        return setContent(prevState => ({ ...prevState, draggedItem: undefined }))
      } else {
        if (originalContent) {
          setContent(originalContent)
          setOriginalContent(undefined)
        }
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  useEffect(() => {
    if (isDragging) {
      setOriginalContent(content)
    }
  }, [isDragging])

  const opacity = isDragging || hoveredItemIndex === index ? 0 : 1

  drag(drop(ref))

  return (
    <>
      <div ref={ref} className={classNames(!opacity && styles.transparent)}>
        {children}
      </div>
    </>
  )
}
