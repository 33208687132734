import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SDKBadge } from '../../../components/SDK/Badge'
import { SDKButton } from '../../../components/SDK/Button'
import { SDKDropdownButton, SDKItemDataType } from '../../../components/SDK/DropdownButton'
import { SDKIcon } from '../../../components/SDK/Icon'
import { SDKText } from '../../../components/SDK/Text'
import { Url } from '../../../constants/urls'
import { useAppDispatch } from '../../../store'
import * as actions from '../store/actions'
import type { ICountry } from '../types'

interface ICountryItemProps {
  item: ICountry
  setCountry: (c: ICountry) => void
  setShowHideModal: (x: boolean) => void
}
export const CountryItem = ({ item, setCountry, setShowHideModal }: ICountryItemProps) => {
  const { t } = useTranslation('countries')
  const navigation = useNavigate()
  const dispatch = useAppDispatch()
  const menuItems = [
    { label: t('functions.unpublish'), iconName: 'view-off', disabled: !item.isPublished }
  ]

  const onCountryEdit = (country: ICountry) => {
    dispatch(actions.clearAddCountry())
    dispatch(actions.clearUpdateCountry())
    dispatch(actions.getCountryById({ yearId: country.yearId, countryId: country.countryId }))
    navigation(`${location.pathname}${Url.EditCountryPage}/${country.countryId}`)
  }
  const hiddenBadge = <SDKBadge value={t('hidden')} type={'info-outlined'} />
  const visibleBadge = <SDKBadge value={t('visible')} type={'success-outlined'} />

  const onSelect = (_value: number | string, selectedItem: SDKItemDataType) => {
    if (selectedItem.label === t('functions.unpublish')) {
      setCountry(item)
      setShowHideModal(true)
    } else {
      alert('not implemented yet')
    }
  }
  return (
    <>
      <SDKText weight={2}>{item.name}</SDKText>
      <div className='buttons-wrapper'>
        {item.isPublished ? visibleBadge : hiddenBadge}
        <SDKButton
          onClick={() => onCountryEdit(item)}
          kind='text'
          heightAuto
          className='p-0 ap-mx-spacing-4 ap-text-neutral-17'
        >
          <SDKIcon code='edit' />
          <SDKText className='edit-txt'>{t('functions.edit')}</SDKText>
        </SDKButton>
        <SDKDropdownButton
          className='ap-text-color-text-link-primary pointer'
          customTriggerNode={true}
          data={menuItems}
          onSelect={onSelect}
          prefixTemplate={i => <SDKIcon code={i.iconName} />}
        >
          <SDKIcon code={'vertical-more'} />
        </SDKDropdownButton>
      </div>
    </>
  )
}
