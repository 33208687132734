import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SDKPanel } from '../../../../../../components/SDK/Panel'
import { SDKText } from '../../../../../../components/SDK/Text'
import { useAppDispatch } from '../../../../../../store'
import { usePermittedTab } from '../../hooks/usePermittedTab'
import { clearGetProjectSetup } from '../../store/actions'
import { IBusinessUnit } from '../../types/inputsTab'
import { BusinessUnitForm } from './components/BusinessUnitForm'
import { FormWrapper } from './components/FormWrapper'
import { TransactionForm } from './components/TransactionForm'

interface IProjectSetupForms {
  businessUnits: IBusinessUnit[]
  transactions: string
  isPermitted: boolean
}

export const ProjectSetupForms = ({
  transactions,
  businessUnits,
  isPermitted
}: IProjectSetupForms) => {
  const { t } = useTranslation('pricing')
  const dispatch = useAppDispatch()
  usePermittedTab(isPermitted)

  useEffect(() => {
    return () => {
      dispatch(clearGetProjectSetup())
    }
  }, [])

  return (
    <SDKPanel className='ap-mb-spacing-5 ap-mt-spacing-3 border-1 ap-border-color-background-border'>
      <SDKText type='heading' weight={2} className='ap-text-color-text-heading'>
        {t(`tabs.projectSetup.projectSetup`)}
      </SDKText>
      <div className='row'>
        <FormWrapper
          title={t(`tabs.projectSetup.businessUnit`)}
          panelText={t(`tabs.projectSetup.businessUnitInfo`)}
        >
          <BusinessUnitForm businessUnits={businessUnits} />
        </FormWrapper>
        <FormWrapper
          title={t(`tabs.projectSetup.transaction`)}
          panelText={t(`tabs.projectSetup.transactionInfo`)}
          withLine
        >
          <TransactionForm transaction={transactions} />
        </FormWrapper>
      </div>
    </SDKPanel>
  )
}
