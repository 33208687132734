import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { usePricingTab } from '../../../../../context/pricingTab.context'
import { DragLayer } from '../components/DragLayer'
import { ScopeConfigurator } from '../components/ScopeConfigurator'
import { ScopesWrapper } from '../components/ScopesWrapper'
import { EPricingTab } from '../const/enums'
import type { ITransformedData } from '../types/dnd'
import type { IBasicPricingData } from '../types/inputsTab'
import { transformData } from '../utils/transformData'

const MasterFileContent = ({ data }: { data: IBasicPricingData | null }) => {
  const [transformedData, setTransformedData] = useState<ITransformedData | null>(null)

  useEffect(() => {
    if (data) {
      const { type, name, projectId } = data
      const newData = transformData(
        data.parentScopes,
        data.scopeIncludes,
        data.scopeExcludes,
        data.scopeAssumes,
        { type, name, projectId }
      )
      setTransformedData(newData)
    }
  }, [data])

  return (
    <>
      <ScopeConfigurator name={EPricingTab.MasterFile} moduleMetadata={data?.moduleMetadata} />
      {data && data.moduleMetadata.isApplicable && (
        <ScopesWrapper totalFee={data.quotes?.scopeTotal}>
          <>
            {transformedData && (
              <DragLayer transformedData={transformedData} quotes={data.quotes} />
            )}
          </>
        </ScopesWrapper>
      )}
    </>
  )
}

const MasterFile = () => {
  const {
    tabContent: { selector }
  } = usePricingTab()
  const { data } = useSelector(selector)

  return <MasterFileContent data={data as IBasicPricingData} />
}

export default MasterFile
