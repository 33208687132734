import type { TextAreaProps } from '@appkit4/react-components/field'
import classNames from 'classnames'
import type { Control, FieldValues, Path } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { SDKTextArea } from '../../SDK/TextArea'

interface IFormTextAreaProps<T extends FieldValues> extends TextAreaProps {
  label: string
  name: Path<T>
  control: Control<T, any>
  disabledSpellcheck?: boolean
}

type Props<T extends FieldValues> = IFormTextAreaProps<T>

export const FormTextArea = <T extends FieldValues>({
  label,
  control,
  name,
  className,
  ...props
}: Props<T>) => {
  return (
    <Controller
      name={name as Path<T>}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <SDKTextArea
            title={label}
            className={classNames([!!error && 'error', className])}
            {...field}
            {...props}
            id={name}
          />
          {!!error && <div className='ap-field-email-validation-error'>{error?.message}</div>}
        </>
      )}
    />
  )
}
