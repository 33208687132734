import api from '../../api'
import { FiscalYearUrl } from '../../constants/urls'

export const swapQuestionsOrderRequest = async (
  previousQuestionId: number,
  nextQuestionId: number,
  yearId: number
): Promise<string> => {
  return (
    await api.put(
      `${FiscalYearUrl.Years}/${yearId}/swapQuestionsOrder/${previousQuestionId}/${nextQuestionId}`
    )
  ).data
}
