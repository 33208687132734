import type { NotificationProps } from '@appkit4/react-components/notification'
import { Notification } from '@appkit4/react-components/notification'
import { useState } from 'react'
import './index.scss'

export const SDKNotification = ({
  status,
  title,
  message,
  duration = 2000,
  onClose
}: NotificationProps) => {
  const [visible, setVisible] = useState(true)

  return visible ? (
    <div className='notification-wrapper'>
      <Notification
        title={title || ''}
        message={message}
        duration={duration}
        status={status}
        closeable={true}
        onClose={() => {
          onClose?.()
          setVisible(false)
        }}
      />
    </div>
  ) : (
    <></>
  )
}
