import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Module } from '../../../constants/modules'
import {
  getCountrySnapshotMfeResultsData,
  ICountrySnapshotMfeResultsPayload
} from '../../../services/requests/countriesSnapshotMFE/getProjectResultsDataMFE'

const moduleName: string = String(Module.CountriesSnapshotMFE)

export const getCountrySnapshotMfeResults = createAsyncThunk(
  `${moduleName}/getCountrySnapshotMfeResults`,
  ({ yearName, params }: { yearName: string; params: ICountrySnapshotMfeResultsPayload }) =>
    getCountrySnapshotMfeResultsData(yearName, params)
)
export const clearGetCountrySnapshotMfeResults = createAction(
  `${moduleName}/clearGetCountrySnapshotMfeResults`
)
