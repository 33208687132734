import { DocumentCard } from './cards/DocumentCard'
import { DocumentWithImageCard } from './cards/DocumentWithImageCard'
import { ProjectCard } from './cards/ProjectCard'
import { QuestionnaireQuestionCard } from './cards/QuestionnaireQuestionCard'
import { TaraFieldsCard } from './cards/TaraFieldsCard'
import { UserCard } from './cards/UserCard'
import { ECardListItem } from './const'
import './index.scss'
import type { TCardListItem } from './type'

export const CardListItem = (props: TCardListItem) => {
  const Component = () => {
    switch (props.cardListItem) {
      case ECardListItem.LegalDocumentCard:
        return (
          <DocumentCard
            actions={props.actions}
            actionCallback={props.handleClick}
            document={props}
          />
        )

      case ECardListItem.PowerBITemplateCard:
        return (
          <DocumentWithImageCard
            actions={props.actions}
            actionCallback={props.handleClick}
            asyncImage
            document={props}
          />
        )

      case ECardListItem.ProjectCard:
        return <ProjectCard project={props} actionCallback={props.handleClick} />

      case ECardListItem.QuestionnaireQuestionCard:
        return (
          <QuestionnaireQuestionCard
            question={props}
            actionCallback={props.handleClick}
            actions={props.actions}
          />
        )

      case ECardListItem.UserCard:
        return <UserCard actions={props.actions} actionCallback={props.handleClick} user={props} />

      case ECardListItem.TaraFieldsCard:
        return (
          <TaraFieldsCard
            actionCallback={props.handleClick}
            actions={props.actions}
            country={props}
          />
        )
      default:
        return <></>
    }
  }

  return <Component />
}
