import { SIGHTLINE_ACTION } from '../../modules/project/constants'

export const authorizeSightline = (text: string, actionToRepeat?: string) => {
  const url = text.match(/\bhttps?:\/\/\S+/gi)![0].replace(/=[^=]+$/, '')
  const urlWithRedirect = `${url}=${window.location.href}`
  window.open(urlWithRedirect, '_blank')
  if (actionToRepeat) {
    localStorage.setItem(SIGHTLINE_ACTION, actionToRepeat)
  }
}
