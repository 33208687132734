import type { FieldValues } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useYearFromUrl } from '../../../../../hooks/useYearFromUrl'
import { useAppDispatch } from '../../../../../store'
import * as actions from '../../../../fiscalYearSetup/store/actions'
import * as selectors from '../../../../fiscalYearSetup/store/selectors'

export const useAddEditPowerBITemplate = (id?: number) => {
  const dispatch = useAppDispatch()
  const { year } = useYearFromUrl()
  const request = useSelector(id ? selectors.updatePBITemplate : selectors.addPBITemplate)

  const cleanUp = () => {
    if (id) {
      dispatch(actions.clearUpdatePBITemplate())
    } else {
      dispatch(actions.clearAddPBITemplate())
    }
  }

  const onSubmit = (data: FieldValues) => {
    const payload = {
      identifier: data.identifier,
      name: data.name,
      templateType: data.templateType || null,
      templateFile: data.templateFile?.originFile || null,
      thumbnailFile: data.thumbnailFile?.originFile || null,
      yearId: year?.yearId!
    }

    if (id) {
      dispatch(actions.updatePBITemplate({ ...payload, templateId: data.templateId }))
    } else {
      dispatch(actions.addPBITemplate(payload))
    }
  }

  return { cleanUp, onSubmit, request }
}
