import './index.scss'

const IFrame = ({
  height,
  src,
  title
}: {
  height: number
  src: string
  title: string
}): JSX.Element => {
  if (!src) {
    return <></>
  }

  return (
    <iframe
      className='i-frame'
      title={title || ''}
      src={src}
      width='100%'
      height={height ?? '100%'}
      frameBorder='0'
    />
  )
}

export default IFrame
