import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { SDKModal } from '../../../../components/SDK/Modal'
import { useAddEditFiscalYearSchema } from '../../../../schemas/useAddFiscalYearSchema'
import { AddEditFiscalYearForm } from './components/AddEditFiscalYearForm'
import { useAddEditFiscalYear } from './utils/useAddEditFiscalYear'

interface IAddEditFiscalYearModal {
  visible: boolean
  setVisible: (data: boolean) => void
  yearId: number | null
}

export const AddEditFiscalYearModal = ({
  visible,
  setVisible,
  yearId
}: IAddEditFiscalYearModal) => {
  const { validationSchema, defaultValues, dropdownList, radioList, modalTitle } =
    useAddEditFiscalYearSchema(yearId)

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid, isDirty }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'all',
    reValidateMode: 'onChange'
  })

  const { onSubmit, onClose } = useAddEditFiscalYear(yearId, setVisible, reset)
  return (
    <form>
      <SDKModal
        visible={visible}
        onCancel={onClose}
        title={modalTitle}
        disabledSubmit={yearId ? !isDirty : !isValid}
        onSubmit={handleSubmit(onSubmit)}
      >
        <AddEditFiscalYearForm
          control={control}
          dropdownList={dropdownList}
          radioList={radioList}
          yearId={yearId}
        />
      </SDKModal>
    </form>
  )
}
