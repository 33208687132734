import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../../../../../context/app.context'
import { usePricingTab } from '../../../../../../../context/pricingTab.context'
import { useProject } from '../../../../../../../context/project.context'
import type { IMetadataParams } from '../../../../../../../services/requests/pricing/updateModuleMetadataRequest'
import { LoadingStatus } from '../../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../../store'
import { EPricingTab } from '../../../const/enums'
import { clearUpdateModuleMetadata, updateModuleMetadata } from '../../../modals/store/actions'
import * as selectors from '../../../modals/store/selectors'

export const useUpdateMetadata = (tabName: EPricingTab, customModuleId?: number) => {
  const { t } = useTranslation('pricing')
  const dispatch = useAppDispatch()
  const { project } = useProject()
  const { sendNotification } = useApp()
  const { loading, error } = useSelector(selectors.updateModuleMetadata)
  const { refreshData } = usePricingTab()

  const updateMetadata = (params: IMetadataParams) => {
    if (project?.projectId) {
      dispatch(
        updateModuleMetadata({
          projectId: Number(project?.projectId),
          tabName: tabName,
          params,
          customModuleId
        })
      )
    }
  }

  useEffect(() => {
    if (loading === LoadingStatus.Failed && error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
      dispatch(clearUpdateModuleMetadata())
    } else if (loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t(`tabs.inputs.moduleMetadata.successInfo`),
        status: 'success'
      })
      refreshData()
      dispatch(clearUpdateModuleMetadata())
    }
  }, [loading])

  useEffect(() => {
    return () => {
      dispatch(clearUpdateModuleMetadata())
    }
  }, [])

  return { updateMetadata, loading }
}
