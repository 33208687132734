import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Module } from '../../../constants/modules'
import {
  IAddApproveLegalDocumentDataPayload,
  addApproveLegalDocumentData
} from '../../../services/requests/systemManagement/addApproveLegalDocumentData'
import type { IAddLegalDocumentPayload } from '../../../services/requests/systemManagement/addLegalDocumentData'
import { addLegalDocumentData } from '../../../services/requests/systemManagement/addLegalDocumentData'
import { addPricingImport } from '../../../services/requests/systemManagement/addPricingImport'
import { createLegalDocumentTestRequest } from '../../../services/requests/systemManagement/createLegalDocumentTestRequest'
import { deleteLegalStatementDocument } from '../../../services/requests/systemManagement/deleteLegalStatementDocument'
import { downloadPricingToolImportTemplate } from '../../../services/requests/systemManagement/downloadPricingToolImportTemplate'
import { downloadProjectsReportData } from '../../../services/requests/systemManagement/downloadProjectsReportData'
import { downloadQuestionsAndAnswersReportData } from '../../../services/requests/systemManagement/downloadQuestionsAndAnswersReportData'
import { downloadRDPFormulasReportData } from '../../../services/requests/systemManagement/downloadRDPFormulasReportData'
import { downloadUsersReportData } from '../../../services/requests/systemManagement/downloadUsersReportData'
import { getEmailTemplatesData } from '../../../services/requests/systemManagement/getEmailTemplatesData'
import { getLegalDocumentsData } from '../../../services/requests/systemManagement/getLegalDocumentsData'
import { getLegalDocumentsPublicData } from '../../../services/requests/systemManagement/getLegalDocumentsPublicData'
import { getPricingToolsImportInfoData } from '../../../services/requests/systemManagement/getPricingToolsImportInfoData'
import { getUnapprovedLegalDocumentsData } from '../../../services/requests/systemManagement/getUnapprovedLegalDocumentsData'
import type { IUpdateEmailTemplatePayload } from '../../../services/requests/systemManagement/updateEmailTemplateData'
import { updateEmailTemplateData } from '../../../services/requests/systemManagement/updateEmailTemplateData'
import type { IUpdateLegalDocumentPayload } from '../../../services/requests/systemManagement/updateLegalDocumentData'
import { updateLegalDocumentData } from '../../../services/requests/systemManagement/updateLegalDocumentData'

const moduleName: string = String(Module.SystemManagement)

export const getEmailTemplates = createAsyncThunk(`${moduleName}/getEmailTemplates`, () =>
  getEmailTemplatesData()
)
export const clearGetEmailTemplates = createAction(`${moduleName}/clearGetEmailTemplates`)
export const updateEmailTemplate = createAsyncThunk(
  `${moduleName}/updateEmailTemplate`,
  ({ templateId, params }: { templateId: string; params: IUpdateEmailTemplatePayload }) =>
    updateEmailTemplateData(templateId, params)
)
export const clearUpdateEmailTemplate = createAction(`${moduleName}/clearUpdateEmailTemplate`)

export const getLegalDocuments = createAsyncThunk(`${moduleName}/getLegalDocuments`, () =>
  getLegalDocumentsData()
)
export const clearGetLegalDocuments = createAction(`${moduleName}/clearGetLegalDocuments`)

export const getUnapprovedLegalDocuments = createAsyncThunk(
  `${moduleName}/getUnapprovedLegalDocuments`,
  () => getUnapprovedLegalDocumentsData()
)
export const clearGetUnapprovedLegalDocuments = createAction(
  `${moduleName}/clearGetUnapprovedLegalDocuments`
)

export const addApproveLegalDocument = createAsyncThunk(
  `${moduleName}/addApproveLegalDocument`,
  (params: IAddApproveLegalDocumentDataPayload) => addApproveLegalDocumentData(params)
)
export const clearAddApproveLegalDocument = createAction(
  `${moduleName}/clearAddApproveLegalDocument`
)

export const addLegalDocument = createAsyncThunk(
  `${moduleName}/addLegalDocument`,
  (params: IAddLegalDocumentPayload) => addLegalDocumentData(params)
)
export const clearAddLegalDocument = createAction(`${moduleName}/clearAddLegalDocument`)

export const updateLegalDocument = createAsyncThunk(
  `${moduleName}/updateLegalDocument`,
  (params: IUpdateLegalDocumentPayload) => updateLegalDocumentData(params)
)
export const clearUpdateLegalDocument = createAction(`${moduleName}/clearUpdateLegalDocument`)

export const createLegalDocumentTest = createAsyncThunk(
  `${moduleName}/createLegalDocumentTest`,
  ({ templateId, email }: { templateId: string; email: string }) =>
    createLegalDocumentTestRequest(templateId, email)
)
export const clearCreateLegalDocumentTest = createAction(
  `${moduleName}/clearCreateLegalDocumentTest`
)

export const deleteLegalDocument = createAsyncThunk(
  `${moduleName}/deleteLegalDocument`,
  (documentId: { documentId: number }) => deleteLegalStatementDocument(documentId)
)
export const clearDeleteLegalDocument = createAction(`${moduleName}/clearDeleteLegalDocument`)

export const downloadProjectsReport = createAsyncThunk(
  `${moduleName}/downloadProjectsReport`,
  ({ yearId }: { yearId: number }) => downloadProjectsReportData(yearId)
)
export const clearDownloadProjectsReport = createAction(`${moduleName}/clearDownloadProjectsReport`)

export const downloadQuestionsAndAnswersReport = createAsyncThunk(
  `${moduleName}/downloadQuestionsAndAnswersReport`,
  ({ yearId }: { yearId: number }) => downloadQuestionsAndAnswersReportData(yearId)
)
export const clearDownloadQuestionsAndAnswersReport = createAction(
  `${moduleName}/clearDownloadQuestionsAndAnswersReport`
)

export const downloadRDPFormulasReport = createAsyncThunk(
  `${moduleName}/downloadRDPFormulasReport`,
  ({ yearId }: { yearId: number }) => downloadRDPFormulasReportData(yearId)
)
export const clearDownloadRDPFormulasReport = createAction(
  `${moduleName}/clearDownloadRDPFormulasReport`
)

export const downloadUsersReport = createAsyncThunk(`${moduleName}/downloadUsersReport`, () =>
  downloadUsersReportData()
)
export const clearDownloadUsersReport = createAction(`${moduleName}/clearDownloadUsersReport`)

export const getLegalDocumentsPublic = createAsyncThunk(
  `${moduleName}/getLegalDocumentsPublic`,
  (identifier?: string[]) => getLegalDocumentsPublicData(identifier)
)

export const clearGetLegalDocumentsPublic = createAction(
  `${moduleName}/clearGetLegalDocumentsPublic`
)

export const getPricingToolsImportInfo = createAsyncThunk(
  `${moduleName}/getPricingToolsImportInfo`,
  () => getPricingToolsImportInfoData()
)

export const clearGetPricingToolsImportInfo = createAction(
  `${moduleName}/clearGetPricingToolsImportInfo`
)

export const addPricingToolsImport = createAsyncThunk(
  `${moduleName}/addPricingToolsImport`,
  (importFile: File) => addPricingImport(importFile)
)

export const clearAddPricingToolsImport = createAction(`${moduleName}/clearAddPricingToolsImport`)

export const downloadPricingToolImport = createAsyncThunk(
  `${moduleName}/downloadPricingToolImportTemplate`,
  ({ versionId }: { versionId: number }) => downloadPricingToolImportTemplate(versionId)
)
export const clearDownloadPricingToolImport = createAction(
  `${moduleName}/clearDownloadPricingToolImport`
)
