import { useTranslation } from 'react-i18next'
import { SDKPanel } from '../../../../../../components/SDK/Panel'
import type { IPricingToolSearchResult } from '../../../../types/searchResults'
import { ResultsData } from '../ResultsData'

const PricingToolSearchResult = ({ resultObject }: { resultObject: IPricingToolSearchResult }) => {
  const { t } = useTranslation('search')
  return (
    <SDKPanel className='ap-mb-spacing-6'>
      <ResultsData
        data={[{ label: t('fields.pricingTool.projectName'), value: resultObject.projectName }]}
      />
      <ResultsData
        data={[{ label: t('fields.pricingTool.moduleName'), value: resultObject.moduleName }]}
      />
      <ResultsData
        data={[{ label: t('fields.pricingTool.scopeName'), value: resultObject.scopeName }]}
      />
    </SDKPanel>
  )
}

export default PricingToolSearchResult
