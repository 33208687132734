import type {
  IChildScope,
  IParentScope,
  IPriceYear
} from '../../../../modules/project/components/GCDPricing/types/scope'
import { Divider } from '../../../Divider'
import { EAction } from '../../const'
import type { IActionCallback } from '../../type'
import { CardFees } from '../cardItems/CardFees'
import { CardTextWithIcon } from '../cardItems/CardTextWithIcon'
import { DnDIcon } from '../cardItems/DnDIcon'
import { MobileFooter } from '../cardItems/MobileFooter'

interface ICardMobile {
  actions: EAction[]
  data: IParentScope | IChildScope
  actionCallback?: (_callback: IActionCallback) => void
  id: number
  fees?: IPriceYear[]
  isMobile: boolean
}

export const CardMobile = ({ actions, actionCallback, data, id, fees, isMobile }: ICardMobile) => {
  return (
    <div className='ap-flex ap-px-spacing-5 align-items-start'>
      <DnDIcon />
      <div className='w-100'>
        <CardTextWithIcon isUserItem={data.isUserItem} text={data.name} isMobile={isMobile} />
        {fees && (
          <>
            <Divider />
            <CardFees fees={fees} isMobile={isMobile} id={id} />
          </>
        )}
        <MobileFooter
          actions={actions}
          actionCallback={actionCallback}
          isUserItem={data.isUserItem}
          id={id}
          isEnabled={data.isEnabled}
        />
      </div>
    </div>
  )
}
