import { useEffect } from 'react'
import { useAuth } from 'react-oidc-context'
import { useLocation } from 'react-router-dom'
import { Url } from '../../constants/urls'

const Login = () => {
  const auth = useAuth()
  const location = useLocation()
  useEffect(() => {
    auth.signinRedirect({
      state:
        `${location.state?.from?.pathname}${location.state?.from?.hash}${location.state?.from?.search}` ||
        Url.MyProjectsPage
    })
  }, [])

  return null
}

export default Login
