export enum AuthorizationUrl {
  SignIn = '../signin',
  Logout = '../signout',
  RefreshToken = 'refresh'
}

export enum BackgroundJobsUrl {
  TpiUsersSync = 'backgroundJobs/tpiUsersSync'
}

export enum CalculationUrl {
  Calculation = 'calculation',
  Files = 'files',
  PowerBI = 'powerBI',
  Reports = 'reports',
  Sightline = 'sightline'
}

export enum CountryUrl {
  Countries = 'countries',
  PublishedCountries = 'countries/published',
  Visibility = 'visibility'
}

export enum CountrySnapshotUrl {
  Answers = 'answers',
  Categories = 'categories',
  CountrySnapshot = 'CountrySnapshot',
  Questions = 'questions',
  Results = 'results',
  Subcategories = 'subcategories',
  Type = 'type'
}

export enum DataExportUrl {
  DataExport = 'dataExport',
  PricingToolImportTemplate = 'pricingTemplate'
}

export enum FiscalYearUrl {
  Answers = 'answers',
  Countries = 'countries',
  GateQuestins = 'gatequestions',
  GateQuestinsExists = 'gatequestions/exists',
  PublishedYears = 'published',
  Years = 'years'
}

export enum PowerBITemplateUrl {
  PowerBITemplates = 'PowerBITemplates'
}

export enum ProjectsUrl {
  AllAvailable = 'allavailable',
  Assigned = 'assignedUsers',
  BusinessUnits = 'businessUnits',
  Calendar = 'calendar',
  Changes = 'changes',
  ChildScopes = 'childScopes',
  CustomCountries = 'customCountries',
  CustomModules = 'customModules',
  Entities = 'entities',
  Export = 'export',
  Flat = 'flat',
  Full = 'full',
  LocalFile = 'localFile',
  Modules = 'modules',
  Order = 'order',
  ParentScopes = 'parentScopes',
  PricingTool = 'pricingTool',
  Projects = 'projects',
  Results = 'results',
  Sightline = 'sightline',
  SyncWithSightline = 'countries/sync',
  Template = 'template',
  Toggle = 'toggle',
  Transactions = 'transactions',
  Users = 'users'
}

export enum RegionUrl {
  Regions = 'regions'
}

export enum SearchUrl {
  GetSearchResults = 'search'
}

export enum SystemManagementUrl {
  AdminDataExport = 'adminDataExport',
  Approvals = 'approvals',
  DataImport = 'dataImport',
  EmailTemplates = 'emailTemplates',
  LegalDocumentApprovals = 'LegalDocumentApprovals',
  LegalDocuments = 'legalDocuments',
  PricingTool = 'pricingTool',
  Public = 'public',
  QuestionsAndAnswers = 'questionsAndAnswers',
  Test = 'test',
  Unapproved = 'unapproved'
}

export enum TaraFieldsUrl {
  Country = 'Country',
  Description = 'Description',
  Formula = 'Formula',
  Formulas = 'Formulas',
  Overrides = 'Overrides',
  Validation = 'Validation'
}

export enum Token {
  Access = 'access_token',
  Id = 'id_token',
  Refresh = 'refresh_token'
}

export enum TypeUrl {
  Currencies = 'currencies'
}

export enum UpdatesUrl {
  Newsfeed = 'newsfeed'
}

export enum UploadUrl {
  uploadTemplateToSightline = 'upload/sightline'
}

export enum UserUrl {
  ServiceAccount = 'serviceAccount',
  UpdateUserRole = 'isAdministrator',
  Users = 'users',
  UpdateUser = 'updateUser',
  UpdateUserCookieSettings = 'UpdateUserCookieSettings'
}

export enum QuestionnaireUrl {
  IsPublished = 'isPublished',
  IsVerified = 'IsVerified',
  Languages = 'languages',
  Questionnaire = 'Questionnaire',
  Years = 'years'
}
