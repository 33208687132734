import { EPricingTab } from '../../../modules/project/components/GCDPricing/const/enums'
import type { IUpdateParentScope } from '../../../modules/project/components/GCDPricing/types/scope'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const updateParentScopeToggleRequest = async (
  projectId: number,
  parentScopeId: number,
  tabName: EPricingTab,
  customModuleId?: number | null
): Promise<IUpdateParentScope> => {
  let url = `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/`

  if (tabName == EPricingTab.CustomModule) {
    url += `${ProjectsUrl.Modules}/${ProjectsUrl.CustomModules}/${customModuleId}/${ProjectsUrl.ParentScopes}/${parentScopeId}/${ProjectsUrl.Toggle}`
  } else {
    url += `${ProjectsUrl.Modules}/${tabName}/${ProjectsUrl.ParentScopes}/${parentScopeId}/${ProjectsUrl.Toggle}`
  }

  return (await api.patch(url)).data
}
