import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingModal } from '../../../../components/LoadingModal'
import { SDKDropdownButton } from '../../../../components/SDK/DropdownButton'
import { SDKIcon } from '../../../../components/SDK/Icon'
import { SDKRadioButton } from '../../../../components/SDK/RadioButton'
import { useProjectFromUrl } from '../../../../hooks/useProjectFromUrl'
import i18n from '../../../../translations'
import { SortedResultsContainer } from './components/SortedResultsContainer'
import './index.scss'
import { useDropdownData } from './utils/useDropdownData'
import { useResultsTab } from './utils/useResultsTab'

export const ResultsTab = () => {
  const { t } = useTranslation('myprojects')
  const { project } = useProjectFromUrl()
  const { options, countries, loading, questions } = useResultsTab(
    Number(project?.yearId)!,
    project?.projectId!
  )
  const [activeOption, setActiveOption] = useState(0)
  const [showLoadingModal, setShowLoadingModal] = useState(false)
  const [showSendExcelToSightlineModal, setShowSendExcelToSightlineModal] = useState(false)
  const { dropdownData, defaultExportAction } = useDropdownData(
    project?.projectId!,
    setShowLoadingModal,
    setShowSendExcelToSightlineModal
  )
  const modalText = showSendExcelToSightlineModal
    ? t('projectDetail.projectTabs.tara.results.exportText')
    : t('results.downloadExcelText')

  return (
    <>
      <div className='results-tab ap-mt-spacing-4'>
        <div className='results-tab-header'>
          <SDKDropdownButton
            className='ap-flex justify-content-end export-btn'
            splitButton={true}
            prefixTemplate={item => item.iconName && <SDKIcon code={item.iconName} />}
            compact
            onClick={() => defaultExportAction()}
            onSelect={(_, item) => item.onClick()}
            data={dropdownData}
          >
            {i18n.t('buttons.export')}
          </SDKDropdownButton>
          <SDKRadioButton
            defaultValue={0}
            options={options}
            onChange={(value: number) => setActiveOption(value)}
            withFlex
          />
        </div>
        <SortedResultsContainer
          activeOption={activeOption}
          countries={countries}
          loading={loading}
          questions={questions}
        />
      </div>
      {(showSendExcelToSightlineModal || showLoadingModal) && (
        <LoadingModal setVisible={setShowSendExcelToSightlineModal} text={modalText} />
      )}
    </>
  )
}
