import { EAddEditCountryFields } from '../../../../../../../schemas/useAddEditCountrySchema'
import { RegionContainer } from '../../../../../../../shared/types/enums'

export const getRadioButtonFieldName = (title: RegionContainer | string) => {
  switch (title) {
    case RegionContainer.Tara:
    case 'Requirements, Deadlines & Penalties':
      return EAddEditCountryFields.regionTara
    case RegionContainer.GcdPricing:
    case 'GCD Pricing':
      return EAddEditCountryFields.regionGCD
    case RegionContainer.BenchmarkAndIndustryAnalysis:
    case 'Benchmark & Industry Analysis':
      return EAddEditCountryFields.regionBenchmark
    default:
      return EAddEditCountryFields.regionTara
  }
}
