import classNames from 'classnames'
import { SDKLoading } from '../Loading'

interface ILoadingOverlay {
  type?: 'modal' | 'page'
}

export const LoadingOverlay = ({ type = 'page' }: ILoadingOverlay) => (
  <SDKLoading className={classNames('overlay', type === 'modal' && 'modal')} />
)
