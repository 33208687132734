import { useTranslation } from 'react-i18next'
import { SDKSwitch } from '../../../../../../components/SDK/Switch'
import { SDKText } from '../../../../../../components/SDK/Text'

interface IListTitleProps {
  hasUnansweredQuestions: boolean
  onHasUnansweredQuestionsChange: (v: boolean) => void
}
const ListTitle = ({ hasUnansweredQuestions, onHasUnansweredQuestionsChange }: IListTitleProps) => {
  const { t } = useTranslation('questionnaires')

  return (
    <div className='ap-flex justify-content-between ap-my-spacing-5 row'>
      <div className='col col-6'>
        <SDKText weight={2}>{t('questions')}</SDKText>
      </div>
      <div className='ap-flex justify-content-end col col-6'>
        <SDKText className='ap-text-neutral-14 ap-pr-spacing-2'>{t('showAnswerLabel')}</SDKText>
        <SDKSwitch
          checked={hasUnansweredQuestions}
          onChange={checked => onHasUnansweredQuestionsChange(checked)}
        ></SDKSwitch>
      </div>
    </div>
  )
}

export default ListTitle
