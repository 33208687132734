import {
  EAnswerTypeForEdit,
  EAnswerTypeFromBE
} from '../../../../../../countriesSnapshot/pages/AddEditQuestion/constants/answerType'
import { IAnswer } from '../../../../../../questionnaires/type'
import { EProjectFields, IProjectCopy } from '../../../../../types'

export const downloadCSV = (data: IProjectCopy | null) => {
  if (!data) {
    return
  }

  const returnQuestionAnswer = (questionType: number, answer: IAnswer): string => {
    type TCalendarValue = { [key: string]: string }

    const calendarOptions: TCalendarValue = {
      LocalEntityOption: 'Performed by Local Entity',
      OtherOption: 'Other (please specify)',
      RelativeToCalendarYearOption: 'Not Tax Return or FYE (relative to calendar year)',
      RelativeToFilingTaxReturnOption: 'Relative to Filing Tax Return',
      RelativeToFyeOption: 'Relative to FYE',
      RelativeToInquiryOrAuditOption: 'Relative To Inquiry or Audit',
      UltimateParentEntityOption: 'Performed by Ultimate Parent Entity'
    }

    if (!answer || questionType === null) {
      return ' '
    }

    const type = Object.keys(EAnswerTypeFromBE).find(
      key => EAnswerTypeFromBE[key as keyof typeof EAnswerTypeFromBE] === questionType
    )

    let answerText: string
    switch (questionType as EAnswerTypeFromBE) {
      case EAnswerTypeFromBE.CurrencyRate:
      case EAnswerTypeFromBE.TaxRate:
        if (answer.isNotAnswered) {
          answerText = answer.answerText
        } else {
          answerText = answer.rate!.toString()
        }
        break
      default:
        answerText = answer.answerText
        break
    }

    if (
      answer.isNotAnswered &&
      !answerText &&
      !answer.selectedAnswers &&
      type !== EAnswerTypeForEdit.Calendar
    ) {
      return 'N/A'
    }

    if (answer.selectedAnswers && answer.selectedAnswers.length) {
      return answer.selectedAnswers
        .map(selectedAnswer => selectedAnswer.possibleAnswerText)
        .join(',')
    }

    if (type != EAnswerTypeForEdit.Calendar) {
      return answerText
    }

    if (answerText) {
      return answerText
    } else if (answer.calendarAnswerResponse)
      return `${
        calendarOptions[`${`${answer.calendarAnswerResponse.dateActionPerformer}Option`}`]
      } ${
        calendarOptions[`${`${answer.calendarAnswerResponse.dateOffsetType}Option`}`]
      } ${`Deadline date: ${answer.calendarAnswerResponse.monthsOffset} months, ${answer.calendarAnswerResponse.daysOffset} days`}`
    else {
      return 'N/A'
    }
  }

  const getCsvString = (): string => {
    const header = `"Title","Old Value","New Value"`

    let missingCountries: string = ''
    if (data?.missingCountries && data.missingCountries.length > 0) {
      missingCountries = data!.missingCountries
        .map(country =>
          ['"Missing Country"', `"${country.name}"`, 'Country Not Published In New Year'].join(',')
        )
        .join('\n')
    }

    let missingQuestions: string = ''
    if (data?.missingQuestions && data.missingQuestions.length > 0) {
      missingQuestions = data!.missingQuestions
        .map(question =>
          [
            '"Missing Question"',
            `"${question.questionText}"`,
            'Question Not Published In New Year'
          ].join(',')
        )
        .join('\n')
    }

    let changedQuestions: string = ''
    if (data?.changedQuestions && data.changedQuestions.length > 0) {
      changedQuestions = data!.changedQuestions
        .map(question =>
          [
            `"Changed Question | ${question.subcategory}"`,
            `"${question.oldQuestionText}"`,
            `"${question.newQuestionText}"`
          ].join(',')
        )
        .join('\n')
    }

    let changedAnswers: string = ''
    if (data?.changedAnswers && data.changedAnswers.length > 0) {
      changedAnswers = data!.changedAnswers
        .map(item =>
          [
            `"Changed Answer | ${item.countryName} | ${item.questionText}"`,
            `"${returnQuestionAnswer(item.questionType, item.oldAnswer)}"`,
            `"${returnQuestionAnswer(item.questionType, item.newAnswer)}"`
          ].join(',')
        )
        .join('\n')
    }

    const csvString = [
      header,
      missingCountries,
      missingQuestions,
      changedQuestions,
      changedAnswers
    ].join('\n')

    return csvString
  }

  // Create a Blob from the CSV string
  const blob = new Blob([getCsvString()], { type: 'text/csv' })

  // Generate a download link and initiate the download
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = `project-${
    data?.projectResponse[EProjectFields.ProjectId]
  }-roll-forward-summary.csv`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}
