import { ICustomModuleData } from '../../../modules/project/components/GCDPricing/types/inputsTab'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const getCustomModuleData = async (
  projectId: number,
  customModuleId: number
): Promise<ICustomModuleData> => {
  return (
    await api.get(
      `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/${ProjectsUrl.Modules}/${ProjectsUrl.CustomModules}/${customModuleId}`
    )
  ).data
}
