import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { SDKIcon } from '../../SDK/Icon'
import { SDKText } from '../../SDK/Text'
import { SDKTooltip } from '../../SDK/Tooltip'
import { RequiredLabel } from '../RequiredLabel'
import './index.scss'

interface IFormCard {
  title: string
  withRequired?: boolean
  withRequiredTitle?: boolean
  className?: string
  tooltipText?: string
}

export const FormCard = ({
  withRequired,
  withRequiredTitle,
  title,
  tooltipText,
  className,
  children
}: PropsWithChildren<IFormCard>) => {
  return (
    <div className={`form-card ap-bg-neutral-01 ${className || ''}`}>
      {withRequiredTitle ? (
        <span className='relative'>
          <SDKText className={classNames(withRequiredTitle && 'relative')} type='body' weight={3}>
            {title}
          </SDKText>
          <span className='required-dot title'></span>
        </span>
      ) : (
        <SDKText type='body' weight={3}>
          <>
            {title}
            {tooltipText && (
              <SDKTooltip disabled={false} content={tooltipText}>
                <div className='display-inline'>
                  <SDKIcon code='information' />
                </div>
              </SDKTooltip>
            )}
          </>
        </SDKText>
      )}
      <div className='ap-my-spacing-5'>{children}</div>
      {withRequired && (
        <div className='ap-mb-spacing-3'>
          <RequiredLabel />
        </div>
      )}
    </div>
  )
}
