import { yupResolver } from '@hookform/resolvers/yup'
import { Dispatch, SetStateAction } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormUploadInput } from '../../../../../../components/Form/FormUploadInput'
import { SDKButton } from '../../../../../../components/SDK/Button'
import { LoadingOverlay } from '../../../../../../components/SDK/LoadingOverlay'
import { SDKModal } from '../../../../../../components/SDK/Modal'
import { useSubmitButton } from '../../../../../../hooks/useSubmitButton'
import {
  EUploadPricingLocalFileTemplate,
  IFormValues,
  useUploadPricingLocalFileTemplate
} from '../../../../../../schemas/uploadPricingLocalFileTemplate'
import { LoadingStatus } from '../../../../../../shared/types/enums'
import { ACCEPTED_LOCAL_FILE_TYPE, ELocalFile } from '../../types'

interface IUploadModal {
  setVisible: Dispatch<SetStateAction<ELocalFile | null>>
  onSubmit: (data: FieldValues) => void
  title: string
  loading?: LoadingStatus
}

const UploadFileModal = ({ setVisible, onSubmit, title, loading }: IUploadModal) => {
  const { t, i18n } = useTranslation('pricing')

  const { defaultValues, validationSchema } = useUploadPricingLocalFileTemplate()

  const { control, formState, handleSubmit } = useForm<FieldValues, IFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange'
  })
  const { disabled } = useSubmitButton({ formState })

  return (
    <SDKModal
      className='upload-modal'
      visible={true}
      onCancel={() => setVisible(null)}
      title={title}
      withRequired={false}
      footer={
        <SDKButton disabled={disabled} type='submit' onClick={handleSubmit(onSubmit)}>
          {i18n.t('buttons.continue')}
        </SDKButton>
      }
    >
      {loading === LoadingStatus.Pending && <LoadingOverlay type='modal' />}
      <form>
        <FormUploadInput
          name={EUploadPricingLocalFileTemplate.inputFile}
          control={control}
          label={t('tabs.inputs.localFile.table.upload.uploadInstruction')!}
          acceptFileType={ACCEPTED_LOCAL_FILE_TYPE}
        />
      </form>
    </SDKModal>
  )
}

export default UploadFileModal
