import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../../../../../store'
import { getCustomModules } from '../../store/selectors'

export const getScopeMetadata = (state: RootState) => state.PricingHelper.getScopeMetadata
export const createChildScope = (state: RootState) => state.PricingHelper.createChildScope
export const updateChildScope = (state: RootState) => state.PricingHelper.updateChildScope
export const createParentScope = (state: RootState) => state.PricingHelper.createParentScope
export const getParentScope = (state: RootState) => state.PricingHelper.getParentScope
export const updateParentScope = (state: RootState) => state.PricingHelper.updateParentScope
export const updateChildScopeToggle = (state: RootState) =>
  state.PricingHelper.updateChildScopeToggle
export const updateParentScopeToggle = (state: RootState) =>
  state.PricingHelper.updateParentScopeToggle
export const updateChildScopeOrder = (state: RootState) => state.PricingHelper.updateChildScopeOrder
export const updateParentScopeOrder = (state: RootState) =>
  state.PricingHelper.updateParentScopeOrder
export const updateModuleMetadata = (state: RootState) => state.PricingHelper.updateModuleMetadata
export const updateBusinessUnit = (state: RootState) => state.PricingHelper.updateBusinessUnit
export const updateTransactions = (state: RootState) => state.PricingHelper.updateTransactions
export const uploadLocalFile = (state: RootState) => state.PricingHelper.uploadLocalFile
export const getLocalFileResults = (state: RootState) => state.PricingHelper.localFileResults
export const getLocalFileScopePricingResults = (state: RootState) =>
  state.PricingHelper.localFileScopePricing
export const getLocalFileScopeTranslationPricingResults = (state: RootState) =>
  state.PricingHelper.localFileScopeTranslationPricing
export const getDeleteCustomModule = (state: RootState) => state.PricingHelper.deleteCustomModule
export const getCustomModuleById = (id: number | null) =>
  createSelector(getCustomModules, state => state.data?.find(({ moduleId }) => id === moduleId))
