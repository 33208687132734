import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormTextArea } from '../../../../../../../components/Form/FormTextArea'
import { SDKButton } from '../../../../../../../components/SDK/Button'
import { SDKIcon } from '../../../../../../../components/SDK/Icon'
import { useApp } from '../../../../../../../context/app.context'
import { usePricingTab } from '../../../../../../../context/pricingTab.context'
import { useProjectFromUrl } from '../../../../../../../hooks/useProjectFromUrl'
import {
  ETransactionFields,
  IFormValues,
  useAddTransactionSchema
} from '../../../../../../../schemas/useAddTransactionSchema'
import { LoadingStatus } from '../../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../../store'
import * as actions from '../../../modals/store/actions'
import * as selectors from '../../../modals/store/selectors'

interface ITransactionForm {
  transaction: string
}

export const TransactionForm = ({ transaction }: ITransactionForm) => {
  const { t } = useTranslation('pricing')
  const { project } = useProjectFromUrl()
  const { refreshData } = usePricingTab()
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { loading, error } = useSelector(selectors.updateTransactions)

  useEffect(() => {
    if (error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
      dispatch(actions.clearUpdateTransactions())
    }
    if (loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t('tabs.projectSetup.transactionSuccessInfo'),
        status: 'success'
      })
      refreshData()
      dispatch(actions.clearUpdateTransactions())
    }
  }, [error, loading])

  const { validationSchema, defaultValues } = useAddTransactionSchema(transaction)
  const { handleSubmit, control, formState, getValues, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const onSubmit = (params: IFormValues) => {
    if (project) {
      dispatch(
        actions.updateTransactions({
          projectId: Number(project.projectId),
          params
        })
      )

      reset(getValues())
    }
  }

  return (
    <form>
      <FormTextArea
        name={ETransactionFields.transactions}
        label={t(`tabs.projectSetup.transaction`)}
        className='ap-mt-spacing-3'
        control={control}
        required={true}
        placeholder={t(`tabs.projectSetup.transactionGuidance`)!}
        disabledSpellcheck={true}
      />

      <SDKButton
        kind='secondary'
        className='ap-my-spacing-5'
        disabled={!formState.isValid || !formState.isDirty}
        onClick={handleSubmit(onSubmit)}
      >
        <SDKIcon code='check-mark' /> {t(`tabs.projectSetup.apply`)}
      </SDKButton>
    </form>
  )
}
