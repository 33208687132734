import type {
  Control,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormCard } from '../../../../../../components/Form/FormCard'
import { FormInput } from '../../../../../../components/Form/FormInput'
import { SDKTooltip } from '../../../../../../components/SDK/Tooltip'
import {
  EAddEditCountryFields,
  IFormValues
} from '../../../../../../schemas/useAddEditCountrySchema'
import { ContactSection } from './components/ContactSection'
import { RegionsSection } from './components/RegionsSection'

interface IAddEditCountryForm {
  control: Control<IFormValues, any>
  isDirty: boolean
  clearErrors: UseFormClearErrors<IFormValues>
  setValue: UseFormSetValue<IFormValues>
  getValues: UseFormGetValues<IFormValues>
  trigger: UseFormTrigger<IFormValues>
}

export const AddEditCountryForm = ({
  control,
  isDirty,
  clearErrors,
  setValue,
  getValues,
  trigger
}: IAddEditCountryForm) => {
  const { t } = useTranslation('countries')

  return (
    <div className='row country-form'>
      <div className='col-lg-4 ap-mb-spacing-5'>
        <FormCard withRequired title={t('addEditCountry.form.countryInfo')}>
          <SDKTooltip disabled={isDirty} content={t('addEditCountry.form.nameTooltip')!}>
            <FormInput
              name={EAddEditCountryFields.name}
              type='text'
              label={t('addEditCountry.form.fields.internationalName')!}
              required={true}
              control={control}
              suffixIcon
            />
          </SDKTooltip>
          <div className='ap-my-spacing-4 '>
            <div className='row'>
              <div className='col-6'>
                <SDKTooltip
                  disabled={isDirty}
                  content={t('addEditCountry.form.countryCodeTooltip')!}
                >
                  <FormInput
                    name={EAddEditCountryFields.code2}
                    type='text'
                    label={t('addEditCountry.form.fields.countryCode')!}
                    required={true}
                    control={control}
                    suffixIcon
                  />
                </SDKTooltip>
              </div>
              <div className='col-6'>
                <SDKTooltip
                  disabled={isDirty}
                  content={t('addEditCountry.form.countryCode3Tooltip')!}
                >
                  <FormInput
                    name={EAddEditCountryFields.code3}
                    type='text'
                    label={t('addEditCountry.form.fields.countryCode3')!}
                    required={true}
                    control={control}
                    suffixIcon
                  />
                </SDKTooltip>
              </div>
            </div>
          </div>
          <div className='ap-my-spacing-4'>
            <SDKTooltip disabled={isDirty} content={t('addEditCountry.form.currencyTooltip')!}>
              <FormInput
                name={EAddEditCountryFields.currency}
                type='text'
                label={t('addEditCountry.form.fields.currency')!}
                required={true}
                control={control}
                suffixIcon
              />
            </SDKTooltip>
          </div>
        </FormCard>
      </div>
      <div className='col-lg-4 form-section ap-mb-spacing-5'>
        <FormCard withRequired withRequiredTitle title={t('addEditCountry.form.regions')}>
          <RegionsSection control={control} clearErrors={clearErrors} />
        </FormCard>
      </div>
      <div className='col-lg-4 form-section ap-mb-spacing-5'>
        <FormCard title={t('addEditCountry.form.contactInfo')}>
          <ContactSection setValue={setValue} getValues={getValues} trigger={trigger} />
        </FormCard>
      </div>
    </div>
  )
}
