import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePricingTab } from '../../../../context/pricingTab.context'
import { IQuote } from '../../../../modules/project/components/GCDPricing/types/inputsTab'
import type { IPriceYear } from '../../../../modules/project/components/GCDPricing/types/scope'
import { useTransformNumberToMoney } from '../../../../modules/project/utils/useTransformNumberToMoney'
import { useCardGridColumnCalculation } from '../../../../utils/useCardGridColumnCalculation'
import { SDKText } from '../../../SDK/Text'

interface ICardTextWithIconCard {
  fees: IPriceYear[]
  isMobile: boolean
  id: number
  quotes?: IQuote
  withoutLeftBorder?: boolean
}

export const CardFees = ({
  fees,
  id,
  isMobile,
  quotes,
  withoutLeftBorder = false
}: ICardTextWithIconCard) => {
  const { t } = useTranslation('myprojects')
  const { feeCol, feeItemCol } = useCardGridColumnCalculation()
  const { priceYears } = usePricingTab()
  const transformNumberToMoney = useTransformNumberToMoney()
  const [feeByYear, setFeeByYear] = useState<IPriceYear[]>([])

  const transformFees = () => {
    const years = quotes!.parentScopeYearTotals.map(q => q.yearName)
    const currentFeesByYear: IPriceYear[] = []
    years.forEach(year => {
      const currentFee = fees.find(fee => fee.yearName === year)
      if (currentFee) {
        currentFeesByYear.push(currentFee)
      } else {
        currentFeesByYear.push({
          centralFeeValue: 0,
          localFeeCountryId: 0,
          localFeeValue: 0,
          totalFeeValue: 0,
          yearName: year
        })
      }
    })
    setFeeByYear(currentFeesByYear)
  }

  useEffect(() => {
    if (quotes) {
      transformFees()
    }
  }, [quotes, fees])

  return (
    <div className={classNames(!isMobile && 'align-items-center', `col col-${feeCol} ap-flex`)}>
      <div
        className={classNames(
          isMobile ? 'justify-content-between' : `row ${withoutLeftBorder ? '' : 'border-left'}`,
          'ap-flex w-100'
        )}
      >
        {fees &&
          feeByYear.map((item, index) => (
            <div
              key={`card-${id}-${index}`}
              className={classNames(
                `col col-${feeItemCol}`,
                !(index < priceYears.length) && 'hidden'
              )}
            >
              {/* TODO temporary solution - remove when it will base on BE */}
              {index < priceYears.length ? (
                <div className='text-ellipsis flex-column'>
                  {isMobile && (
                    <SDKText weight={2}>{`${t(`projectDetail.projectTabs.pricingTools.fy`, {
                      year: item.yearName
                    })}:`}</SDKText>
                  )}
                  <SDKText>{transformNumberToMoney(item.totalFeeValue)}</SDKText>
                </div>
              ) : (
                <></>
              )}
            </div>
          ))}
      </div>
    </div>
  )
}
