import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { EmptyState } from '../../components/EmptyState'
import { Layout } from '../../components/Layout'
import { SDKLoading } from '../../components/SDK/Loading'
import { useApp } from '../../context/app.context'
import { LoadingStatus } from '../../shared/types/enums'
import { useAppDispatch } from '../../store'
import * as yearActions from '../fiscalYearSetup/store/actions'
import * as yearSelectors from '../fiscalYearSetup/store/selectors'
import { CountriesHeader } from './components/CountriesHeader'
import { CountriesList } from './components/CountriesList'
import './index.scss'
import * as actions from './store/actions'
import { getCountries } from './store/selectors'

const Countries = () => {
  const dispatch = useAppDispatch()
  const { year: currentYear } = useParams()
  const { data: countries, error, loading } = useSelector(getCountries)
  const { error: yearsModalError, data: years } = useSelector(yearSelectors.getYears)
  const year = useSelector(yearSelectors.getYearByName(currentYear!))
  const memoizedYear = useMemo(() => year, [currentYear, years.length])
  const { sendNotification } = useApp()

  useEffect(() => {
    if (error) {
      sendNotification({ message: error, status: 'error' })
    }
  }, [error])

  useEffect(() => {
    if (yearsModalError) {
      sendNotification({ message: yearsModalError, status: 'error' })
    }
  }, [yearsModalError])

  useEffect(() => {
    if (!year) {
      dispatch(yearActions.getYears())
    }
  }, [])

  useEffect(() => {
    if (year?.yearId) {
      dispatch(actions.getCountries(year.yearId))
    }
  }, [memoizedYear])

  return (
    <Layout
      buttonComponent={<CountriesHeader />}
      onClick={() => {
        dispatch(actions.clearCountries())
        dispatch(actions.clearGetCountryById())
        dispatch(actions.clearAddCountry())
        dispatch(actions.clearUpdateCountry())
      }}
    >
      <div className='countries-page ap-my-spacing-5'>
        {loading === LoadingStatus.Pending ? (
          <SDKLoading />
        ) : countries.length === 0 ? (
          <EmptyState />
        ) : (
          <CountriesList countries={countries} />
        )}
      </div>
    </Layout>
  )
}

export default Countries
