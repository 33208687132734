import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Module } from '../../../constants/modules'
import { dataExportProject } from '../../../services/requests/projects/dataExportProject'
import { dataExportProjectToSightline } from '../../../services/requests/projects/dataExportProjectToSightline'
import { exportProjectData } from '../../../services/requests/projects/exportProjectData'
import { exportProjectDataFromSightlineCS } from '../../../services/requests/projects/exportProjectDataFromSightlineCS'
import { getCalculationById } from '../../../services/requests/projects/getCalculationById'
import { getCalculationResultByFormat } from '../../../services/requests/projects/getCalculationResultByFormat'
import { getCalculationResultByFormatFromSightline } from '../../../services/requests/projects/getCalculationResultByFormatFromSightline'
import { getDataExportInputTemplate } from '../../../services/requests/projects/getDataExportInputTemplate'
import { getPowerBICalculationResultByTemplateId } from '../../../services/requests/projects/getPowerBICalculationResultByTemplateId'
import { getPowerBICalculationResultByTemplateIdFromSightline } from '../../../services/requests/projects/getPowerBICalculationResultByTemplateIdFromSightline'
import { getProjectAvailableUsersData } from '../../../services/requests/projects/getProjectAvailableUsersData'
import { getProjectQuestionsData } from '../../../services/requests/projects/getProjectQuestionsData'
import { getProjectResultsData } from '../../../services/requests/projects/getProjectResultsData'
import { getSightlineFilesList } from '../../../services/requests/projects/getSightlineFilesList'
import { updateCalendarRequest } from '../../../services/requests/projects/updateCalendarRequest'
import {
  ICustomCountriesPayload,
  updateCustomCountriesRequest
} from '../../../services/requests/projects/updateCustomCountriesRequest'
import {
  IUpdateProjectQuestionsPayload,
  updateProjectSelectedQuestions
} from '../../../services/requests/projects/updateProjectSelectedQuestions'
import { uploadDataBySightlineWithCalculation } from '../../../services/requests/projects/uploadDataBySightlineWithCalculation'
import { uploadEntitiesFromTemplateRequest } from '../../../services/requests/projects/uploadEntitiesFromTemplateRequest'
import { uploadTemplateDataWithCalculation } from '../../../services/requests/projects/uploadTemplateDataWithCalculation'
import { uploadToSightline } from '../../../services/requests/projects/uploadToSightline'
import type { TFileType } from '../types/projectCalculation'

const moduleName: string = String(Module.Project)

export const getProjectQuestions = createAsyncThunk(
  `${moduleName}/getProjectQuestions`,
  ({ yearId, projectId }: { yearId: number; projectId: number }) =>
    getProjectQuestionsData(yearId, projectId)
)
export const clearProjectQuestions = createAction(`${moduleName}/clearProjectQuestions`)
export const updateProjectQuestions = createAsyncThunk(
  `${moduleName}/updateProjectQuestions`,
  ({
    params,
    yearId,
    projectId
  }: {
    params: IUpdateProjectQuestionsPayload
    yearId: number
    projectId: number
  }) => updateProjectSelectedQuestions(params, yearId, projectId)
)
export const clearUpdateProjectQuestions = createAction(`${moduleName}/clearupdateProjectQuestions`)
export const getProjectUsers = createAsyncThunk(
  `${moduleName}/getProjectUsers`,
  ({ projectId, searchText }: { projectId: number; searchText: string }) =>
    getProjectAvailableUsersData(projectId, searchText)
)
export const clearGetProjectUsers = createAction(`${moduleName}/clearGetProjectUsers`)
export const getProjectResults = createAsyncThunk(
  `${moduleName}/getProjectResults`,
  ({ yearId, projectId }: { yearId: number; projectId: number }) =>
    getProjectResultsData(yearId, projectId)
)
export const clearProjectResults = createAction(`${moduleName}/clearProjectResults`)

export const exportProject = createAsyncThunk(`${moduleName}/exportProject`, (projectId: string) =>
  exportProjectData(projectId)
)
export const clearExportProject = createAction(`${moduleName}/clearExportProject`)

export const exportTemplate = createAsyncThunk(
  `${moduleName}/ExportTemplate`,
  ({ countryIds, projectId }: { countryIds: number[]; projectId: number }) =>
    getDataExportInputTemplate(countryIds, projectId)
)
export const clearExportTemplate = createAction(`${moduleName}/clearExportTemplate`)

export const uploadTemplateWithCalculation = createAsyncThunk(
  `${moduleName}/uploadTemplateWithCalculation`,
  ({ dateOnly, inputFile, projectId }: { dateOnly: boolean; inputFile: File; projectId: number }) =>
    uploadTemplateDataWithCalculation(dateOnly, inputFile, projectId)
)
export const clearUploadTemplateWithCalculation = createAction(
  `${moduleName}/clearUploadTemplateWithCalculation`
)

export const uploadEntitiesFromTemplate = createAsyncThunk(
  `${moduleName}/uploadEntitiesFromTemplate`,
  ({ inputFile, projectId }: { inputFile: File; projectId: number }) =>
    uploadEntitiesFromTemplateRequest(inputFile, projectId)
)
export const clearUploadEntitiesFromTemplate = createAction(
  `${moduleName}/clearUploadEntitiesFromTemplate`
)

export const getTemplateCalculationById = createAsyncThunk(
  `${moduleName}/getTemplateCalculationById`,
  (projectId: number) => getCalculationById(projectId)
)
export const clearGetTemplateCalculationById = createAction(
  `${moduleName}/clearGetTemplateCalculationById`
)

export const uploadTemplateToSightline = createAsyncThunk(
  `${moduleName}/uploadTemplateToSightline`,
  ({ countryIds, projectId }: { countryIds: number[]; projectId: number }, { rejectWithValue }) =>
    uploadToSightline({ countryIds, projectId }, rejectWithValue)
)
export const clearUploadTemplateToSightline = createAction(
  `${moduleName}/clearUploadTemplateToSightline`
)
export const getCalculationResult = createAsyncThunk(
  `${moduleName}/getCalculationResult`,
  ({ calculationId, fileType }: { calculationId: number; fileType: TFileType }) =>
    getCalculationResultByFormat(calculationId, fileType)
)
export const clearGetCalculationResult = createAction(`${moduleName}/clearGetCalculationResult`)

export const uploadBySightlineWithCalculation = createAsyncThunk(
  `${moduleName}/uploadBySightlineWithCalculation`,
  ({
    dateOnly,
    sightlineInputFileId,
    projectId
  }: {
    dateOnly: boolean
    sightlineInputFileId: string
    projectId: number
  }) => uploadDataBySightlineWithCalculation(dateOnly, sightlineInputFileId, projectId)
)
export const clearUploadBySightlineWithCalculation = createAction(
  `${moduleName}/clearUploadBySightlineWithCalculation`
)

export const getSightlineFiles = createAsyncThunk(
  `${moduleName}/getSightlineFiles`,
  (projectId: number, { rejectWithValue }) => getSightlineFilesList(projectId, rejectWithValue)
)
export const clearGetSightlineFiles = createAction(`${moduleName}/clearGetSightlineFiles`)

export const getCalculationResultFromSightline = createAsyncThunk(
  `${moduleName}/getCalculationResultFromSightline`,
  (
    { calculationId, fileType }: { calculationId: number; fileType: TFileType },
    { rejectWithValue }
  ) => getCalculationResultByFormatFromSightline(calculationId, fileType, rejectWithValue)
)
export const clearGetCalculationResultFromSightline = createAction(
  `${moduleName}/clearGetCalculationResultFromSightline`
)

export const getPBICalculationResultByTemplateId = createAsyncThunk(
  `${moduleName}/getPBICalculationResultByTemplateId`,
  ({ calculationId, templateId }: { calculationId: number; templateId: number }) =>
    getPowerBICalculationResultByTemplateId(calculationId, templateId)
)
export const clearGetPBICalculationResultByTemplateId = createAction(
  `${moduleName}/clearGetPBICalculationResultByTemplateId`
)

export const getPBICalculationResultByTemplateIdFromSightline = createAsyncThunk(
  `${moduleName}/getPBICalculationResultByTemplateIdFromSightline`,
  (
    { calculationId, templateId }: { calculationId: number; templateId: number },
    { rejectWithValue }
  ) =>
    getPowerBICalculationResultByTemplateIdFromSightline(calculationId, templateId, rejectWithValue)
)
export const clearGetPBICalculationResultByTemplateIdFromSightline = createAction(
  `${moduleName}/clearGetPBICalculationResultByTemplateIdFromSightline`
)

export const updateCustomCountries = createAsyncThunk(
  `${moduleName}/updateCustomCountries`,
  ({ params, projectId }: { params: ICustomCountriesPayload; projectId: number }) =>
    updateCustomCountriesRequest(params, projectId)
)
export const clearUpdateCustomCountries = createAction(`${moduleName}/clearUpdateCustomCountries`)

export const exportProjectFromSightlineCS = createAsyncThunk(
  `${moduleName}/exportProjectFromSightlineCS`,
  ({ projectId }: { projectId: number }, { rejectWithValue }) =>
    exportProjectDataFromSightlineCS(projectId, rejectWithValue)
)
export const clearExportProjectFromSightlineCS = createAction(
  `${moduleName}/clearExportProjectFromSightlineCS`
)

export const createExportProject = createAsyncThunk(
  `${moduleName}/createExportProject`,
  ({ projectId }: { projectId: number }) => dataExportProject(projectId)
)
export const clearCreateExportProject = createAction(`${moduleName}/clearCreateExportProject`)

export const createExportProjectToSightline = createAsyncThunk(
  `${moduleName}/createExportProjectToSightline`,
  ({ projectId }: { projectId: number }, { rejectWithValue }) =>
    dataExportProjectToSightline(projectId, rejectWithValue)
)
export const clearCreateExportProjectToSightline = createAction(
  `${moduleName}/clearCreateExportProjectToSightline`
)

export const updateCalendar = createAsyncThunk(
  `${moduleName}/updateCalendar`,
  ({ projectId }: { projectId: number }) => updateCalendarRequest(projectId)
)
export const clearUpdateCalendar = createAction(`${moduleName}/clearUpdateCalendar`)
