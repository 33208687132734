import classNames from 'classnames'
import { IconVariant } from './const'
import styles from './index.module.scss'

export const SDKIcon = ({
  className = '',
  code,
  outline = true,
  size,
  withDot = false,
  dotColor,
  disabled = false
}: {
  className?: string
  code: string
  outline?: boolean
  dotColor?: string
  size?: 16 | 24 | 32 | 40
  withDot?: boolean
  disabled?: boolean
}): any => {
  const iconVariant = outline ? IconVariant.Outline : IconVariant.Fill
  const iconClass = `icon-${code}-${iconVariant}`
  const iconSize = size ? `ap-font-${size}` : null

  return (
    <span
      className={classNames([
        'Appkit4-icon',
        withDot && 'relative',
        iconClass,
        iconSize,
        ...className?.split(' '),
        disabled && styles['disabled-icon']
      ])}
      aria-hidden='true'
    >
      {withDot ? (
        <span
          className={classNames(
            styles['with-dot'],
            'Appkit4-icon icon-hb-full-fill ap-font-10',
            dotColor && `ap-text-${dotColor}`
          )}
        ></span>
      ) : null}
    </span>
  )
}
