import { SDKPanel } from '../../SDK/Panel'
import { SDKParagraph } from '../../SDK/Paragraph'
import { CardActionButtonGroup } from '../components/CardActionButtonGroup'
import { EAction } from '../const'
import type { IActionCallback } from '../type'

import './SimpleCard.scss'

interface SimpleCardItem {
  id: number
  name: string
}

export const SimpleCard = ({
  actions,
  actionCallback,
  item
}: {
  actions: EAction[]
  className?: string
  actionCallback: (_callback: IActionCallback) => void
  item: SimpleCardItem
}) => {
  return (
    <SDKPanel className='simple-card'>
      <div className='ap-flex w-100 justify-content-between'>
        <div className='ap-flex flex-row'>
          <div className='ap-flex flex-column'>
            <SDKParagraph weight={2}>{item.name}</SDKParagraph>
          </div>
        </div>

        <CardActionButtonGroup actions={actions} id={item.id} actionCallback={actionCallback} />
      </div>
    </SDKPanel>
  )
}
