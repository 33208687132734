import classNames from 'classnames'
import { CSSProperties } from 'react'
import { IconWithTooltip } from '../IconWithTooltip'
import { SDKIcon } from '../SDK/Icon'

export const ActionButton = ({
  content,
  icon,
  handleClick,
  kind = 'primary',
  disabled,
  className = '',
  tooltipStyle
}: {
  content: string
  icon: string
  handleClick: (event: React.MouseEvent) => void
  kind?: 'primary' | 'neutral'
  disabled?: boolean
  className?: string
  tooltipStyle?: CSSProperties
}) => {
  return (
    <div
      className={classNames('ap-mx-spacing-2', disabled ? 'not-allowed' : 'pointer', className)}
      onClick={e => !disabled && handleClick(e)}
    >
      {content ? (
        <IconWithTooltip
          code={icon}
          iconClassName={kind === 'primary' && !disabled ? 'ap-text-color-text-primary' : ''}
          content={content}
          disabled={disabled}
          tooltipStyle={tooltipStyle}
        />
      ) : (
        <SDKIcon
          code={icon}
          className={kind === 'primary' && !disabled ? 'ap-text-color-text-primary' : ''}
          disabled={disabled}
        />
      )}
    </div>
  )
}
