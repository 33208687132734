import { ECardDnDItem } from '../../../../../components/CardDnDWrapper/const'
import { ERole } from '../components/ScopeWrapper'
import { TableType, TableTypeId } from '../types/dnd'
import type { IChildScope, IScopeTabBase } from '../types/scope'

export const transformDataWithoutParentScope = (
  scopeIncludes: IChildScope[],
  scopeExcludes: IChildScope[],
  scopeAssumes: IChildScope[],
  baseData: IScopeTabBase
) => {
  return {
    draggedItem: undefined,
    moduleId: baseData.type,
    projectId: baseData.projectId,
    name: baseData.name,
    tables: [
      {
        id: TableTypeId.ScopeIncludes,
        tableName: TableType.ScopeIncludes,
        acceptType: [TableType.ScopeIncludes, TableType.ScopeExcludes],
        rowsType: ECardDnDItem.defaultCard,
        rows: scopeIncludes.map(e => ({ ...e, id: String(e.childScopeId) })),
        role: ERole.Parent
      },
      {
        id: TableTypeId.ScopeExcludes,
        tableName: TableType.ScopeExcludes,
        acceptType: [TableType.ScopeIncludes, TableType.ScopeExcludes],
        rowsType: ECardDnDItem.defaultCard,
        rows: scopeExcludes.map(e => ({ ...e, id: String(e.childScopeId) })),
        role: ERole.Child
      },
      {
        id: TableTypeId.ScopeAssumes,
        tableName: TableType.ScopeAssumes,
        acceptType: [TableType.ScopeAssumes],
        rowsType: ECardDnDItem.defaultCard,
        rows: scopeAssumes.map(e => ({ ...e, id: String(e.childScopeId) })),
        role: ERole.Child
      }
    ]
  }
}
