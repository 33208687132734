import classNames from 'classnames'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as yearActions from '../../modules/fiscalYearSetup/store/actions'
import { getYearByName } from '../../modules/fiscalYearSetup/store/selectors'
import * as actions from '../../modules/regions/store/actions'
import { getRegions } from '../../modules/regions/store/selectors'
import { IRegion } from '../../modules/regions/types'
import { RegionContainer } from '../../shared/types/enums'
import { useAppDispatch } from '../../store'
import { SDKAccordion } from '../SDK/Accordion'
import { SDKAccordionItem } from '../SDK/AccordionItem'
import './index.scss'

interface IRegionsAccordion<T> {
  mapRegions: (value: IRegion[]) => [string, T[]][]
  children: (data: { title: string; sectionRegions: T[] }) => ReactElement
  withBorder?: boolean
  getFieldName?: (data: string) => void
}

export const RegionsAccordion = <T,>({
  mapRegions,
  children,
  withBorder,
  getFieldName
}: IRegionsAccordion<T>) => {
  const { t } = useTranslation('regions')
  const dispatch = useAppDispatch()
  const { data: regions } = useSelector(getRegions)
  const { year: currentYear } = useParams()
  const year = useSelector(getYearByName(currentYear!))
  const [activeKeys, setActiveKeys] = useState<string[]>([])

  const defaultSections = [
    [RegionContainer.Tara, []],
    [RegionContainer.GcdPricing, []],
    [RegionContainer.BenchmarkAndIndustryAnalysis, []]
  ]

  useEffect(() => {
    if (!year) {
      dispatch(yearActions.getYears())
    }
  }, [])

  useEffect(() => {
    if (year?.yearId) {
      dispatch(actions.getRegions(year.yearId))
    }
  }, [year])

  const onClickAccordion = (keys: string[], event: React.MouseEvent<HTMLElement>) => {
    setActiveKeys(keys)
    if (getFieldName && event.currentTarget.innerText) {
      getFieldName(event.currentTarget.innerText)
    }
  }

  const sections = useMemo(() => {
    if (regions.length) {
      return mapRegions(regions)
    } else return defaultSections
  }, [regions])

  return (
    <SDKAccordion
      className='regions-accordion'
      multiple={true}
      onClick={onClickAccordion}
      activeKeys={activeKeys}
    >
      {sections &&
        sections.map(([title, sectionRegions], index) => (
          <SDKAccordionItem
            title={t(`containers.${title}`) || ''}
            itemKey={String(index)}
            key={`regions-details-list-element-${index}`}
            className={classNames(withBorder && 'border')}
          >
            {children({ title: title as string, sectionRegions: sectionRegions as [] })}
          </SDKAccordionItem>
        ))}
    </SDKAccordion>
  )
}
