export enum EQuestionTypes {
  SingleChoice = 'Single choice',
  MultipleChoice = 'Multiple choice',
  PlainText = 'Plain Text',
  Calendar = 'Calendar',
  Language = 'Language'
}

export enum EQuestionStates {
  SystemAnsweredQuestions = 'System answered questions',
  AdminReviewRequired = 'Admin review required'
}

export enum EQuestionToDelete {
  SingleChoice = 0,
  MultipleChoice = 1,
  PlainText = 2,
  Calendar = 3,
  Language = 4
}
