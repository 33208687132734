import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { SDKColumn } from '../../../../../../../components/SDK/Column'
import { SDKTableSeparatedByLines } from '../../../../../../../components/SDK/TableSeparatedByLines'
import { SDKText } from '../../../../../../../components/SDK/Text'
import { usePricingTab } from '../../../../../../../context/pricingTab.context'
import { IQuote } from '../../../types/inputsTab'
import type { IParentComplexScope } from '../../../types/scope'
import styles from './index.module.scss'
import { useTableColumn } from './utils/useTableColumn'

interface ILocalFileResultsTable {
  data: IParentComplexScope[]
  quotes: IQuote
}

export const LocalFileResultsTable = ({ data, quotes }: ILocalFileResultsTable) => {
  const { t } = useTranslation('pricing')
  const { columns } = useTableColumn(quotes)
  const { priceYears } = usePricingTab()

  const modifiedFees =
    priceYears.length === data[0].priceYears?.length
      ? data[0].priceYears
      : data[0].priceYears?.filter((_, index) => !(index >= priceYears.length)) //TODO-remove it when BE is ready

  return (
    <div className={classNames(styles['local-file-result'], 'ap-pt-spacing-3')}>
      <SDKTableSeparatedByLines originalData={data} hasTitle disableDefaultSort={true}>
        {columns.map(col => (
          <SDKColumn
            field={col.key}
            renderCell={col?.renderCell}
            key={`desktop-column-${col.key}`}
            className={classNames(col.class && styles[col.class])}
          >
            {col.key === 'priceYears' ? (
              modifiedFees.map((el, index) => (
                <SDKText
                  key={`${el.yearName}-${index}`}
                  weight={2}
                  className={classNames(
                    'ap-text-neutral-14 ap-inline-block',
                    styles['column-width']
                  )}
                >
                  {t(`tabs.inputs.localFile.table.${col.key}`, { name: el.yearName })}
                </SDKText>
              ))
            ) : col.key !== 'parentScopeId' ? (
              <SDKText weight={2} className='ap-text-neutral-14'>
                {t(`tabs.inputs.localFile.table.${col.key}`)}
              </SDKText>
            ) : (
              <></>
            )}
          </SDKColumn>
        ))}
      </SDKTableSeparatedByLines>
    </div>
  )
}
