import { useEffect, useState } from 'react'
import type { Control, FieldValues, UseFormResetField } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormSelect } from '../../../../../components/Form/FormSelect'
import { FormTextArea } from '../../../../../components/Form/FormTextArea'
import { ISelectValue } from '../../../../../components/SDK/Select'
import { EAddQuestionFields, IFormValues } from '../../../../../schemas/useAddQuestionSchema'
import { useAppDispatch } from '../../../../../store'
import * as actions from '../../../store/actions'
import { getCategories } from '../../../store/selectors'
import { TCategory, TSubcategory } from '../../../types'

interface IQuestionInfo {
  control: Control<FieldValues, IFormValues>
  yearId: number
  resetField: UseFormResetField<IFormValues>
  category: TCategory | null
  setCategory: (c: TCategory) => void
  setSubcategory: (a: TSubcategory) => void
  isEditMode: boolean
  defaultValues: IFormValues
}

export const QuestionInfoComponent = ({
  control,
  yearId,
  resetField,
  category,
  setCategory,
  setSubcategory,
  isEditMode,
  defaultValues
}: IQuestionInfo) => {
  const [subcategories, setSubcategories] = useState<TSubcategory[]>([])
  const dispatch = useAppDispatch()
  const { t } = useTranslation('countriesSnapshot')
  const { data: categories } = useSelector(getCategories)

  useEffect(() => {
    if (!categories || !categories?.length) {
      dispatch(actions.getCategories(yearId))
    }
  }, [yearId])

  useEffect(() => {
    const currentCategory = categories.find(c => c.name === defaultValues.category)
    const currentSubcategory = currentCategory?.subcategories.find(
      s => s.name === defaultValues.subcategory
    )
    if (currentCategory && currentSubcategory) {
      setSubcategory(currentSubcategory)
      setCategory(currentCategory)
    }
  }, [])

  const onSelectCategory = (categoryName: ISelectValue) => {
    const currentCategory = categories.find(c => c.name === categoryName)
    if (currentCategory) {
      resetField(EAddQuestionFields.subcategory)
      setSubcategories(currentCategory.subcategories)
      setCategory(currentCategory)
    }
  }

  const onSelectSubcategory = (subcategoryName: ISelectValue) => {
    const currentSubcategory = category?.subcategories?.find(s => s.name === subcategoryName)
    if (currentSubcategory) {
      setSubcategory(currentSubcategory)
    }
  }

  return (
    <>
      <FormTextArea
        name={EAddQuestionFields.question}
        label={t('newQuestion.form.fields.question')!}
        className='ap-mb-spacing-5'
        required={true}
        control={control}
      />
      <FormSelect
        name={EAddQuestionFields.category}
        label={t('newQuestion.form.fields.category')!}
        required={true}
        valueKey={String(category?.categoryId)}
        className='ap-mb-spacing-5'
        data={isEditMode ? [defaultValues.category] : categories.map(c => c.name)}
        control={control}
        onSelect={onSelectCategory}
        showErrorMessage={true}
        disabled={isEditMode}
      />
      <FormSelect
        name={EAddQuestionFields.subcategory}
        label={t('newQuestion.form.fields.subcategory')!}
        required={true}
        className='ap-mb-spacing-5'
        data={isEditMode ? [defaultValues.subcategory] : subcategories.map(s => s.name)}
        control={control}
        onSelect={onSelectSubcategory}
        showErrorMessage={true}
        disabled={isEditMode}
      />
    </>
  )
}
