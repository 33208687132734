import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../context/app.context'
import { usePricingTab } from '../../../context/pricingTab.context'
import { useProjectFromUrl } from '../../../hooks/useProjectFromUrl'
import {
  findLocalFeeCountryId,
  getParentScopeModalTab
} from '../../../modules/project/components/GCDPricing/modals/ParentScopeModal/const'
import {
  clearUpdateChildScopeToggle,
  clearUpdateParentScopeToggle,
  updateChildScopeToggle,
  updateParentScopeToggle
} from '../../../modules/project/components/GCDPricing/modals/store/actions'
import * as selectors from '../../../modules/project/components/GCDPricing/modals/store/selectors'
import { getScopeDefinition } from '../../../modules/project/components/GCDPricing/store/actions'
import type {
  IChildScope,
  TParentScope
} from '../../../modules/project/components/GCDPricing/types/scope'
import { LoadingStatus } from '../../../shared/types/enums'
import { DEFAULT_ERROR } from '../../../shared/utils/store'
import { useAppDispatch } from '../../../store'
import { EAction } from '../const'
import type { IActionCallback } from '../type'

type TAction = 'parent' | 'child'

export const useOnCardClick = () => {
  const { t } = useTranslation('pricing')
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { tabContent } = usePricingTab()
  const [actionType, setActionType] = useState<TAction | null>(null)
  const [isEnabled, setIsEnabled] = useState<boolean | null>(null)
  const { loading: childLoading, error: childError } = useSelector(selectors.updateChildScopeToggle)
  const { loading: parentLoading, error: parentError } = useSelector(
    selectors.updateParentScopeToggle
  )
  const {
    customModuleId,
    triggerChildScopeModal,
    triggerParentScopeModal,
    tabContent: { tab },
    refreshData
  } = usePricingTab()
  const { project } = useProjectFromUrl()

  const tabName = tabContent.tab

  const onChildScopeClick = (callback: IActionCallback, scope: IChildScope) => {
    setActionType('child')
    const childScopeId = Number(callback.id)
    if (callback.action === EAction.Enable) {
      setIsEnabled(!isEnabled)
      if (project?.projectId) {
        setIsEnabled(!isEnabled)
        dispatch(
          updateChildScopeToggle({
            projectId: Number(project?.projectId),
            childScopeId,
            tabName,
            customModuleId
          })
        )
      } else {
        sendNotification({
          message: DEFAULT_ERROR,
          status: 'error'
        })
      }
    } else if (callback.action === EAction.Edit) {
      triggerChildScopeModal(scope)
    }
  }

  const onParentScopeClick = (callback: IActionCallback, scope: TParentScope) => {
    setActionType('parent')
    const parentScopeId = Number(callback.id)
    if (callback.action === EAction.Enable) {
      if (project?.projectId) {
        dispatch(
          updateParentScopeToggle({
            projectId: Number(project?.projectId),
            parentScopeId,
            tabName,
            customModuleId
          })
        )
      } else {
        sendNotification({
          message: DEFAULT_ERROR,
          status: 'error'
        })
      }
    } else if (callback.action === EAction.Edit) {
      triggerParentScopeModal(
        {
          localFeeCountryId: findLocalFeeCountryId(scope),
          name: scope.name,
          parentScopeId,
          priceYears: scope.priceYears
        },
        getParentScopeModalTab(tab)
      )
    }
  }
  const loading = actionType === 'child' ? childLoading : parentLoading
  const error = actionType === 'child' ? childError : parentError

  useEffect(() => {
    if (loading === LoadingStatus.Failed && error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
      dispatch(getScopeDefinition({ projectId: project?.projectId! }))
    } else if (loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t(
          `tabs.inputs.${
            actionType === 'child' ? 'childScopeToggle' : 'parentScopeToggle'
          }.successInfo`
        ),
        status: 'success'
      })
      refreshData()
    }
  }, [loading])

  useEffect(() => {
    return () => {
      dispatch(clearUpdateParentScopeToggle())
      dispatch(clearUpdateChildScopeToggle())
    }
  }, [tabContent.tabId])

  return { onChildScopeClick, onParentScopeClick, loading, isEnabled }
}
