import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const deleteCustomModalRequest = async (
  projectId: number,
  customModuleId: number
): Promise<string> => {
  return (
    await api.delete(
      `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/${ProjectsUrl.Modules}/${ProjectsUrl.CustomModules}/${customModuleId}`
    )
  ).data
}
