import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CardListWrapper } from '../../../../components/CardListWrapper'
import { FiscalYearLayout } from '../../../../components/Layout'
import { SDKAccordion } from '../../../../components/SDK/Accordion'
import { SDKAccordionItem } from '../../../../components/SDK/AccordionItem'
import { FiscalYearContextProvider, useFiscalYear } from '../../../../context/fiscalYear.context'
import { useAppDispatch } from '../../../../store'
import * as actions from '../../../fiscalYearSetup/store/actions'
import * as selectors from '../../../fiscalYearSetup/store/selectors'
import { AnswerView } from '../../../questionnaires/pages/Questions/components/AnswerView'
import * as questionnairesActions from '../../../questionnaires/store/actions'
import * as questionnairesSelectors from '../../../questionnaires/store/selectors'
import type { IAnswer } from '../../../questionnaires/type'
import { EmptyState } from './components/EmptyState'
import { GQQuestinItem } from './components/GQQuestinItem'
import './index.scss'
import type { IGateQuestionProhibitedAnswer, IGateQuestionQuestion } from './types'

const GateQuestionsList = ({
  onEditAnswer,
  editMode
}: {
  editMode: boolean
  onEditAnswer: ({
    question,
    answer,
    countryId
  }: {
    question: IGateQuestionQuestion
    answer: IGateQuestionProhibitedAnswer
    countryId: number
  }) => void
}) => {
  const dispatch = useAppDispatch()
  const resource = useSelector(selectors.getGateQuestions)
  const { fiscalYearInfo } = useFiscalYear()

  const getFreshData = () => {
    if (fiscalYearInfo) {
      dispatch(actions.getGateQuestions(fiscalYearInfo.yearId))
    }
  }

  useEffect(() => {
    getFreshData()
  }, [fiscalYearInfo])

  return (
    <>
      <CardListWrapper
        classNames='gate-questions'
        emptyStateComponent={<EmptyState />}
        resource={{ ...resource }}
      >
        <>
          {resource.data?.map(countryGroup => {
            const countryKey = `accordion-country-${countryGroup.countryId}`

            return (
              <SDKAccordion multiple={true} key={countryKey}>
                <SDKAccordionItem
                  itemKey={countryKey}
                  title={countryGroup.countryName}
                  className={classNames(
                    'ap-bg-color-background-alt',
                    'ap-border-radius-3',
                    'ap-level-1',
                    'ap-my-spacing-5',
                    'country-group'
                  )}
                >
                  <GQQuestinItem
                    countryId={countryGroup.countryId}
                    questions={countryGroup.questions}
                    onEditAnswer={({ question, answer }) =>
                      onEditAnswer({ question, answer, countryId: countryGroup.countryId })
                    }
                    yearId={fiscalYearInfo?.yearId || 0}
                    editMode={editMode}
                  />
                </SDKAccordionItem>
              </SDKAccordion>
            )
          })}
        </>
      </CardListWrapper>
    </>
  )
}

const GateQuestionsContent = () => {
  const [editQuestion, setEditQuestion] = useState<{
    question: IGateQuestionQuestion
    answer: IAnswer
    countryId: number
  } | null>(null)

  const { fiscalYearInfo } = useFiscalYear()
  const dispatch = useAppDispatch()
  const question = useSelector(
    questionnairesSelectors.getQuestionnaireQuestionById(editQuestion?.question.questionId)
  )

  const onAnswerSave = () => {
    dispatch(actions.clearGetGateQuestionsPending())
    if (fiscalYearInfo && editQuestion) {
      dispatch(
        actions.getGateQuestionsPending({
          countryId: editQuestion.countryId,
          gateQuestionId: editQuestion.question.questionId,
          yearId: fiscalYearInfo.yearId
        })
      )
    }
    setEditQuestion(null)
  }

  useEffect(() => {
    dispatch(questionnairesActions.clearQuestionnaireQuestions())

    if (editQuestion && fiscalYearInfo) {
      dispatch(
        questionnairesActions.getQuestionnaireQuestions({
          yearId: fiscalYearInfo.yearId,
          countryId: editQuestion.countryId
        })
      )
    }
  }, [editQuestion])

  return (
    <FiscalYearLayout>
      <GateQuestionsList
        editMode={!!editQuestion}
        onEditAnswer={({ question: selectedQuestion, answer: selectedAnswer, countryId }) => {
          dispatch(actions.clearRejectGateQuestionDraft())

          setEditQuestion({
            question: selectedQuestion,
            answer: { ...selectedAnswer } as IAnswer,
            countryId
          })
        }}
      />
      {editQuestion && question && (
        <AnswerView
          countryId={editQuestion.countryId}
          draftAnswer={editQuestion.answer}
          onAnswerSave={() => onAnswerSave()}
          question={question}
          query={null}
          yearId={fiscalYearInfo?.yearId}
          onModalClose={() => setEditQuestion(null)}
        />
      )}
    </FiscalYearLayout>
  )
}

const GateQuestions = () => {
  return (
    <FiscalYearContextProvider>
      <GateQuestionsContent />
    </FiscalYearContextProvider>
  )
}

export default GateQuestions
