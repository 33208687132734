import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { mixed, object, string } from 'yup'
import { FiscalYearStatus } from '../modules/fiscalYearSetup/const'
import * as selectors from '../modules/fiscalYearSetup/store/selectors'
import { yearLengthRegExp } from '../services/utils/validation'
import type { IOption } from '../types/form'

export interface ISelectOption extends IOption {
  descValue: string
}

export enum EAddFiscalYearFields {
  year = 'year',
  status = 'status',
  fiscalYearData = 'fiscalYearData'
}
export interface IFormValues {
  [EAddFiscalYearFields.year]: string
  [EAddFiscalYearFields.status]: FiscalYearStatus
  [EAddFiscalYearFields.fiscalYearData]: IOption['value']
}

const convertToEnum = (value: boolean) =>
  value ? FiscalYearStatus.Enabled : FiscalYearStatus.Disabled

export const useAddEditFiscalYearSchema = (yearId: number | null) => {
  const { t } = useTranslation('fiscalYearSetup')
  const year = useSelector(selectors.getYearById(yearId))

  const modalTitle = yearId
    ? t('newFiscalYear.editTitle', { year: year?.name })
    : t('newFiscalYear.title')

  const dropdownList: ISelectOption[] = [
    {
      value: FiscalYearStatus.Enabled,
      descValue: t('newFiscalYear.form.dropdownList.enabled'),
      label: t('newFiscalYear.form.dropdownList.enabledDesc')
    },
    {
      value: FiscalYearStatus.Disabled,
      descValue: t('newFiscalYear.form.dropdownList.disabled'),
      label: t('newFiscalYear.form.dropdownList.disabledDesc')
    }
  ]

  const radioList: IOption[] = [
    { value: '1', label: t('newFiscalYear.form.radioList.useExistingData') },
    { value: '0', label: t('newFiscalYear.form.radioList.startFromScratch') }
  ]

  const defaultValues = {
    [EAddFiscalYearFields.year]: (year && year.name) || '',
    [EAddFiscalYearFields.status]:
      (year && convertToEnum(year.isActive)) || FiscalYearStatus.Disabled,
    [EAddFiscalYearFields.fiscalYearData]: radioList[1].value
  }

  const validationSchema = object()
    .shape({
      [EAddFiscalYearFields.year]: string()
        .required(t('newFiscalYear.form.errors.year')!)
        .matches(yearLengthRegExp, {
          message: t('newFiscalYear.form.errors.invalidYear')!,
          excludeEmptyString: true
        }),
      [EAddFiscalYearFields.status]: mixed<FiscalYearStatus>()
        .oneOf(Object.values(FiscalYearStatus))
        .required(t('form.errors.status')!),
      [EAddFiscalYearFields.fiscalYearData]: string().required()
    })
    .required()

  return { validationSchema, defaultValues, dropdownList, radioList, modalTitle }
}
