import { IEditProjectForm, IProject } from '../../../modules/myprojects/types'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const updateProjectRequest = async (
  params: IEditProjectForm,
  projectId: number
): Promise<IProject> => {
  return (await api.put(`${ProjectsUrl.Projects}/${projectId}`, params)).data
}
