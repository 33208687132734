import { useEffect } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Url } from '../../../../../constants/urls'
import { useApp } from '../../../../../context/app.context'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../store'
import * as actions from '../../../store/actions'
import { addPricingToolsImport } from '../../../store/selectors'

export const useAddPricingImport = () => {
  const { t } = useTranslation('systemManagement')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { sendNotification } = useApp()

  const { loading, error } = useSelector(addPricingToolsImport)

  const onSubmit = async (data: FieldValues) => {
    await dispatch(actions.addPricingToolsImport(data.inputFile.originFile))
  }

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded) {
      navigate(`${Url.SystemManagementPage}${Url.PricingToolImportPage}`)
      sendNotification({
        message: t('pricingToolImport.successInfo'),
        status: 'success'
      })
      dispatch(actions.clearAddPricingToolsImport())
    }
  }, [loading])

  useEffect(() => {
    if (error) {
      sendNotification({
        message: error,
        status: 'error'
      })
      dispatch(actions.clearAddPricingToolsImport())
    }
  }, [error])

  return { onSubmit }
}
