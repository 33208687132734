import { FiscalYearLayout } from '../../components/Layout'
import { FiscalYearDetailsList } from './components/FiscalYearDetailsList'

const fiscalYearDetails = () => {
  return (
    <FiscalYearLayout>
      <div className='fiscal-year-details-page'>
        <FiscalYearDetailsList />
      </div>
    </FiscalYearLayout>
  )
}

export default fiscalYearDetails
