import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DocumentWithImageCard } from '../../../../components/CardListItem/cards/DocumentWithImageCard'
import { SDKLoading } from '../../../../components/SDK/Loading'
import { SDKModal } from '../../../../components/SDK/Modal'
import { SDKRadioButton } from '../../../../components/SDK/RadioButton'
import { SDKText } from '../../../../components/SDK/Text'
import { useApp } from '../../../../context/app.context'
import { useProjectFromUrl } from '../../../../hooks/useProjectFromUrl'
import { LoadingStatus } from '../../../../shared/types/enums'
import { useAppDispatch } from '../../../../store'
import { getPBITemplates } from '../../../fiscalYearSetup/store/actions'
import { getPowerBITemplates } from '../../../fiscalYearSetup/store/selectors'
import { PowerBIAction } from '../../constants'
import { ETemplateType } from '../GCDPricing/const/enums'
import './index.scss'

interface IPowerBIActionModalProps {
  isVisible: boolean
  setIsVisible: (value: PowerBIAction | null) => void
  onSubmit: (
    templateId: number | null,
    setSelectedTemplate: (templateId: number | null) => void
  ) => void
  actionType: PowerBIAction
  templateType: ETemplateType
}
export const PowerBIActionModal = ({
  isVisible,
  setIsVisible,
  onSubmit,
  actionType,
  templateType
}: IPowerBIActionModalProps) => {
  const { t } = useTranslation('myprojects')
  const dispatch = useAppDispatch()
  const {
    appContext: {
      style: { isMobile }
    }
  } = useApp()
  const { data: powerBITemplateList, loading: powerBITemplateListLoading } =
    useSelector(getPowerBITemplates)
  const { project } = useProjectFromUrl()
  const [selectedTemplate, setSelectedTemplate] = useState<number | null>(null)

  useEffect(() => {
    if (project) {
      dispatch(getPBITemplates({ yearId: Number(project.yearId), templateType }))
    }
  }, [project, templateType])

  const getSelectedTemplateName = () => {
    return powerBITemplateList.find(template => template.templateId === selectedTemplate)?.name
  }

  const customFooterComponent = (
    <div className={classNames('ap-flex', isMobile && 'flex-column')}>
      <SDKText className='ap-mr-spacing-3'>{t('powerBIActionModal.selectedTemplate')!}</SDKText>
      <SDKText weight={3}>{getSelectedTemplateName() || ''}</SDKText>
    </div>
  )

  const submitLabel = t(
    actionType === PowerBIAction.Download
      ? 'powerBIActionModal.download.button'
      : 'powerBIActionModal.send.button'
  )
  const modalTitle = t(
    actionType === PowerBIAction.Download
      ? 'powerBIActionModal.download.title'
      : 'powerBIActionModal.send.title'
  )

  return (
    <div className='ap-mx-spacing-5'>
      <SDKModal
        aria-modal
        onCancel={() => setIsVisible(null)}
        onSubmit={() => onSubmit(selectedTemplate, setSelectedTemplate)}
        title={modalTitle}
        submitLabel={submitLabel}
        visible={isVisible}
        withRequired={false}
        customFooterComponent={customFooterComponent}
        footerClassName='ap-mt-spacing-7'
        className='default-modal power-bi-action-modal ap-mx-spacing-5'
        disabledSubmit={!selectedTemplate}
      >
        <div className='ap-pb-spacing-5'>
          <SDKText>{t('powerBIActionModal.description')!}</SDKText>
        </div>
        {powerBITemplateListLoading === LoadingStatus.Pending ? (
          <SDKLoading />
        ) : (
          <div className={classNames([isMobile && 'is-mobile', 'ap-container power-bi-content'])}>
            <div className='pbi-radio-button'>
              <SDKRadioButton
                customRenderList={powerBITemplateList}
                value={selectedTemplate}
                customRadioItem={(item: any, radioValue: number) => (
                  <DocumentWithImageCard
                    exportResultDesign
                    withRadio
                    asyncImage
                    selectedItem={selectedTemplate}
                    radioValue={radioValue}
                    document={{ ...item, thumbnail: item.thumbnailUrl }}
                    actions={[]}
                    onClick={() => setSelectedTemplate(radioValue)}
                  />
                )}
                withoutDesktopScroll={!isMobile}
                options={powerBITemplateList.map(item => ({
                  value: item.templateId,
                  label: item.name
                }))}
                onChange={(value: number) => setSelectedTemplate(value)}
                withFlex={isMobile}
              />
            </div>
          </div>
        )}
      </SDKModal>
    </div>
  )
}
