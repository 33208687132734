import classnames from 'classnames'
import type { ISDKText } from '../Text'

interface ISDKParagraph extends ISDKText {}

export const SDKParagraph = ({
  children,
  type = 'body',
  weight = 1,
  className = ''
}: ISDKParagraph) => {
  if (!children) {
    return null
  }

  return (
    <div
      className={classnames([`ap-typography-${type}`, `ap-font-weight-${weight}`, `${className}`])}
    >
      {children}
    </div>
  )
}
