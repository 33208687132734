import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyState } from '../../../../components/EmptyState'
import { ITabberItem, SDKTabber } from '../../../../components/SDK/Tabber'
import { EFeatureFlag, useFeatureFlags } from '../../../../hooks/useFeatureFlags'
import { EPricingToolMainTab, EPricingToolMainTabHashUrl } from '../../constants/tab'
import style from './index.module.scss'
import { GCDPricingInputs } from './tabs/Inputs'
import { GCDPricingResults } from './tabs/Results'

export const GCDPricing = () => {
  const { t } = useTranslation('pricing')
  const [activeIndex, setActiveIndex] = useState(0)
  const { isEnabled } = useFeatureFlags()

  const tabs: ITabberItem[] = [
    {
      label: t('tabs.inputs.tab.title'),
      value: EPricingToolMainTab.Input,
      hashUrl: EPricingToolMainTabHashUrl.Input
    },
    {
      label: t('tabs.results.tab.title'),
      value: EPricingToolMainTab.Results,
      hashUrl: EPricingToolMainTabHashUrl.Results
    }
  ]

  const shouldDisplayPricingTool = isEnabled(EFeatureFlag.Pricing)

  return (
    <div className={style.pricing}>
      <SDKTabber tabs={tabs} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
      <div className={style.content}>
        {shouldDisplayPricingTool ? (
          activeIndex === 1 ? (
            <GCDPricingResults />
          ) : (
            <GCDPricingInputs />
          )
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  )
}
