import { useTranslation } from 'react-i18next'
import { SDKButton } from '../../../../components/SDK/Button'
import { SDKIcon } from '../../../../components/SDK/Icon'
import { Tab } from '../../constants/tab'
import './index.scss'

interface IHeaderProps {
  activeIndex: Tab
  openAddCategoryModal: () => void
  openAddQuestionModal: () => void
}

export const Header = ({
  activeIndex,
  openAddCategoryModal,
  openAddQuestionModal
}: IHeaderProps) => {
  const { t } = useTranslation('countriesSnapshot')

  return (
    <div className='countries-snapshot-page-header ap-my-spacing-5'>
      <SDKButton kind='secondary' className='ap-mr-spacing-4 hidden'>
        <SDKIcon code='filter'></SDKIcon> {t('filter')}
      </SDKButton>
      {activeIndex === Tab.Categories && (
        <SDKButton className='add-btn' onClick={openAddCategoryModal}>
          <SDKIcon code='plus'></SDKIcon> {t('addCategory')}
        </SDKButton>
      )}
      {activeIndex === Tab.Questions && (
        <SDKButton className='add-btn' onClick={openAddQuestionModal}>
          <SDKIcon code='plus'></SDKIcon>
          {t('addQuestion')}
        </SDKButton>
      )}
    </div>
  )
}
