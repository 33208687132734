import { useTranslation } from 'react-i18next'
import { SDKPanel } from '../../../../../../components/SDK/Panel'
import { SearchResultType, type IRDPSearchResult } from '../../../../types/searchResults'
import { ResultsData } from '../ResultsData'

const RDPResult = ({ resultObject }: { resultObject: IRDPSearchResult }) => {
  const { t } = useTranslation('search')

  return (
    <SDKPanel className='ap-mb-spacing-2'>
      <div className='result-type ap-mb-spacing-4'>
        {resultObject.searchResultType == SearchResultType.RDPDescriptionResult
          ? t('matchType.descriptionMatch')
          : t('matchType.fieldNameMatch')}
      </div>

      <ResultsData
        data={[
          { label: t('fields.common.country'), value: resultObject.countryName },
          { label: t('fields.common.year'), value: resultObject.yearName }
        ]}
      />

      <ResultsData
        data={[{ label: t('fields.rdp.fieldName'), value: resultObject.taraFieldName }]}
      />

      <ResultsData
        data={[
          {
            label: t('fields.rdp.fieldDescription'),
            value: null,
            paragraph: resultObject.fieldDescription
          }
        ]}
      />
    </SDKPanel>
  )
}

export default RDPResult
