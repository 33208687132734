import type { LoadingProps } from '@appkit4/react-components/loading'
import { Loading } from '@appkit4/react-components/loading'

import './index.scss'

export const SDKLoading = ({ compact, className }: LoadingProps) => {
  return (
    <Loading loadingType='circular' indeterminate compact={compact} className={className}></Loading>
  )
}
