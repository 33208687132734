import type { ListProps } from '@appkit4/react-components/list'
import { List } from '@appkit4/react-components/list'

interface IListProps<T> extends ListProps<T> {}

export const SDKList = <T,>({ children, data, ...props }: IListProps<T>) => {
  return (
    <List data={data} {...props}>
      {children}
    </List>
  )
}
