import { Slider } from '@appkit4/react-components'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { SDKInputNumber } from '../../../../../../components/SDK/InputNumber'
import { SDKParagraph } from '../../../../../../components/SDK/Paragraph'
import { SDKText } from '../../../../../../components/SDK/Text'
import { usePricingTab } from '../../../../../../context/pricingTab.context'
import { useProjectFromUrl } from '../../../../../../hooks/useProjectFromUrl'
import { LoadingStatus } from '../../../../../../shared/types/enums'
import { useDebounce } from '../../../../../../shared/utils/useDebounce'
import { useAppDispatch } from '../../../../../../store'
import { useTransformNumberToMoney } from '../../../../utils/useTransformNumberToMoney'
import { EPricingTab } from '../../const/enums'
import { updateModuleMetadata } from '../../modals/store/actions'
import * as selectors from '../../modals/store/selectors'
import { IDiscountData } from '../../types/inputsTab'
import styles from './index.module.scss'

interface IDiscountPercentageView {
  data: IDiscountData
  discountPerFiscalYear?: number
  totalDiscount?: number
}

const MAX_PROGRESS_VALUE = 100
const MIN_PROGRESS_VALUE = 0
const STEP_VALUE = 1
const PROGRESS_DELAY = 200

export const DiscountPercentageView = ({
  data,
  totalDiscount = 0,
  discountPerFiscalYear = 0
}: IDiscountPercentageView) => {
  const { t } = useTranslation('pricing')
  const dispatch = useAppDispatch()
  const { project } = useProjectFromUrl()
  const [progress, setProgress] = useState<number | string>(discountPerFiscalYear)
  const previousProgressVal = useRef<number | string>(progress)
  const { loading } = useSelector(selectors.updateModuleMetadata)
  const { refreshData } = usePricingTab()
  const debouncedValue = useDebounce(progress, PROGRESS_DELAY)
  const transformNumberToMoney = useTransformNumberToMoney()

  useEffect(() => {
    if (project?.projectId && progress !== previousProgressVal.current) {
      dispatch(
        updateModuleMetadata({
          projectId: project.projectId,
          tabName: EPricingTab.Discount,
          params: {
            ...data.moduleMetadata,
            discount: Number(debouncedValue)
          }
        })
      )
    }
  }, [debouncedValue])

  useEffect(() => {
    if (loading === LoadingStatus.Failed) {
      refreshData()
    }
  }, [loading])

  return (
    <div className='row ap-border-radius-2 ap-bg-color-background-default ap-px-spacing-3 ap-py-spacing-5 ap-m-spacing-1'>
      <div className={classNames(styles['discount-progress-bar'], 'col col-12 col-sm-5')}>
        <div className='ap-flex justify-content-between'>
          <SDKText>{t('tabs.inputs.discount.progressTitle')}</SDKText>
          <SDKText>{`${progress}%`}</SDKText>
        </div>
        <Slider
          min={MIN_PROGRESS_VALUE}
          max={MAX_PROGRESS_VALUE}
          step={STEP_VALUE}
          value={Number(progress)}
          sliderId='singleSlider'
          onValueChange={(i: number) => setProgress(i)}
        ></Slider>
      </div>
      <div className={classNames(styles['input-number-wrapper'], 'col col-12 col-sm-2')}>
        <SDKInputNumber
          value={progress}
          onChange={(_value: number | string) => setProgress(_value)}
          max={MAX_PROGRESS_VALUE}
          title={t('tabs.inputs.discount.inputLabel')!}
        />
      </div>
      <div className='col col-12 col-sm-5 text-align-right'>
        <SDKText type='body' className='ap-text-neutral-14'>
          {t('tabs.inputs.discount.totalDiscountAmount')}
        </SDKText>
        <SDKParagraph type='heading' className='ap-text-color-text-primary ap-font-weight-2'>
          {transformNumberToMoney(totalDiscount, true)}
        </SDKParagraph>
      </div>
    </div>
  )
}
