import type { IUserBase } from '../../types/user'
import { getFullname } from '../../utils/user'
import { SDKParagraph } from '../SDK/Paragraph'
import UserAvatar from '../UserAvatar'

export const UserInfo = ({ user }: { user: IUserBase }) => (
  <div className='ap-flex flex-row'>
    <div className='ap-mr-spacing-4'>
      <UserAvatar user={user} />
    </div>
    <div className='ap-flex flex-column'>
      <SDKParagraph weight={2}>{getFullname(user)}</SDKParagraph>
      <SDKParagraph type='body-s'>{user.email}</SDKParagraph>
    </div>
  </div>
)
