import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'
import { generateFile } from '../../utils/generateFile'

export const exportResultsFlatRequest = async (projectId: number): Promise<File> => {
  const response = await api.post(
    `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/${ProjectsUrl.Export}/${ProjectsUrl.Flat}`,
    {},
    { responseType: 'blob' }
  )
  response.data.lastModified = new Date()
  generateFile(response)

  return response.data
}
