import { useEffect } from 'react'
import type { FieldValues, UseFormReset } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../context/app.context'
import type { IFormValues } from '../../../schemas/useEditFieldNameSchema'
import { LoadingStatus } from '../../../shared/types/enums'
import { useAppDispatch } from '../../../store'
import type { FiscalYearInfo } from '../../fiscalYearSetup/types'
import * as actions from '../store/actions'
import * as selectors from '../store/selectors'

export const useEditFieldName = (
  reset: UseFormReset<IFormValues>,
  setDefaultField: (field: null) => void,
  setVisible: (v: boolean) => void,
  year: FiscalYearInfo | null
) => {
  const { t } = useTranslation('common')
  const { loading, error } = useSelector(selectors.updateFormulaOverrideDescription)
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const cleanUp = () => {
    reset()
    dispatch(actions.clearUpdateFormulaOverrideDescription())
    dispatch(actions.getFormulaOverrideDescription())
  }

  const onClose = () => {
    setVisible(false)
    setDefaultField(null)
  }

  const onSubmit = async (data: FieldValues) => {
    const mappedData = {
      fieldName: data.name,
      description: data.description
    }
    await dispatch(
      actions.updateFormulaOverrideDescription({
        params: mappedData
      })
    )
    onClose()
  }

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t('successInfo'),
        status: 'success'
      })
      dispatch(actions.getFormulaOverrides(year?.yearId!))
      cleanUp()
    }

    if (error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
      cleanUp()
    }
  }, [loading, error])

  return { onClose, cleanUp, onSubmit }
}
