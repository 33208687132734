import { useEffect } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { LoadingStatus } from '../../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../../store'
import * as actions from '../../../../../store/actions'
import * as selectors from '../../../../../store/selectors'
import type { IAddProjectForm, IEditProjectForm, IProjectCard } from '../../../../../types'

export const useAddEditProjectModal = ({
  onClose,
  project,
  projectId
}: {
  onClose: (id?: number) => void
  project: IProjectCard | null
  projectId: number | null
}) => {
  const modal = useSelector(projectId ? selectors.updateProject : selectors.addProject)
  const dispatch = useAppDispatch()

  const onSubmit = (data: FieldValues) => {
    const convertedData: IAddProjectForm = {
      ...project,
      ...data,
      countryIds: data.countryIds.map((id: string | number) => Number(id)),
      outputCurrencyId: Number(data.outputCurrencyId),
      projectId: null,
      userIds: data.userIds.map((id: string | number) => Number(id)),
      yearId: Number(data.yearId)
    } as unknown as IAddProjectForm

    if (data.projectId) {
      const convertedDataEdit = { ...convertedData, projectId: data.projectId } as IEditProjectForm
      dispatch(actions.updateProject({ params: convertedDataEdit, projectId: data.projectId }))
    } else {
      dispatch(actions.addProject(convertedData))
    }
  }

  useEffect(() => {
    if (modal.loading === LoadingStatus.Succeeded) {
      onClose(modal.data?.projectId)
    }
  }, [modal.loading])

  return { modal, onSubmit }
}
