import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { DESKTOP_MIN_WIDTH } from '../components/Layout/const'
import { SDKNotification } from '../components/SDK/Notification'
import { Mode } from '../constants/mode'
import { Theme } from '../constants/theme'
import { ELocalStorageStyling } from '../constants/tokens'
import type { IUser } from '../types/user'
import { getValueOrDefault } from '../utils/enum'

interface INotification {
  message: string
  status: string
  duration?: number
}

interface IAppContext {
  app: {
    type: 'alpha' | 'beta' | 'rc'
  }
  isProjectMFE: boolean | null
  isCountrySnapshotMFE: boolean | null
  projectYearId: number | null
  style: {
    isMobile: boolean
    mode: Mode
    theme: Theme
  }
  user: IUser | null
}

enum URLParam {
  MFEMode = 'mode',
  MFEProject = 'projectId'
}

export enum AppMode {
  CountrySnapshotMFE = 'embedded_country_snapshot',
  ProjectMFE = 'embedded_project'
}

interface AppContextState {
  appContext: IAppContext
  notifications: INotification[]
  removeNotification: (_n: INotification) => void
  sendNotification: (_n: INotification) => void
  updateProjectModeContext: (_t: IAppContext['isProjectMFE']) => void
  updateCSModeContext: (_t: IAppContext['isCountrySnapshotMFE']) => void
  updateStyleContext: (_m: Partial<IAppContext['style']>) => void
  updateUserContext: (_t: IAppContext['user']) => void
  updateProjectYearIdContext: (_t: IAppContext['projectYearId']) => void
}

const defaultContext: IAppContext = {
  app: {
    type: 'rc'
  },
  isProjectMFE: null,
  isCountrySnapshotMFE: null,
  projectYearId: null,
  style: {
    isMobile: window.innerWidth < DESKTOP_MIN_WIDTH,
    mode: getValueOrDefault(localStorage.getItem(ELocalStorageStyling.Mode), Mode, 'Light'),
    theme: getValueOrDefault(localStorage.getItem(ELocalStorageStyling.Theme), Theme, 'Orange')
  },
  user: null
}

const defaultAppContext: AppContextState = {
  appContext: defaultContext,
  notifications: [],
  removeNotification: (_n: INotification) => {},
  sendNotification: (_n: INotification) => {},
  updateCSModeContext: (_t: IAppContext['isCountrySnapshotMFE']) => {},
  updateProjectModeContext: (_t: IAppContext['isProjectMFE']) => {},
  updateStyleContext: (_s: Partial<IAppContext['style']>) => {},
  updateUserContext: (_u: IAppContext['user']) => {},
  updateProjectYearIdContext: (_u: IAppContext['projectYearId']) => {}
}

export const useURLParams = () => {
  const { search } = useLocation()
  const params = useMemo(() => new URLSearchParams(search), [search])

  return {
    mode: params.get(URLParam.MFEMode),
    projectId: params.get(URLParam.MFEProject)
  }
}

const useAppContext = (props: IAppContext): AppContextState => {
  const [appContext, setAppContext] = useState(props)
  const [notifications, setNotifications] = useState<INotification[]>([])

  const removeNotification = (notification: INotification) => {
    setNotifications(current => current.filter(not => not !== notification))
  }

  const sendNotification = (notification: INotification) => {
    setNotifications(current => [...current, notification])
  }

  const updateStyleContext = useCallback((style: Partial<IAppContext['style']>): void => {
    Object.keys(style)?.map(key => {
      const styleKey = key as ELocalStorageStyling

      if (style[styleKey]) {
        localStorage.setItem(styleKey, String(style[styleKey]))
      }
    })

    setAppContext(prev => ({ ...prev, style: { ...prev.style, ...style } }))
  }, [])

  const updateUserContext = useCallback((user: IAppContext['user']): void => {
    setAppContext(prev => ({ ...prev, user }))
  }, [])

  const updateProjectModeContext = useCallback(
    (isProjectMFE: IAppContext['isProjectMFE']): void => {
      setAppContext(prev => ({ ...prev, isProjectMFE }))
    },
    []
  )

  const updateCSModeContext = useCallback(
    (isCountrySnapshotMFE: IAppContext['isCountrySnapshotMFE']): void => {
      setAppContext(prev => ({ ...prev, isCountrySnapshotMFE }))
    },
    []
  )

  const updateProjectYearIdContext = useCallback(
    (projectYearId: IAppContext['projectYearId']): void => {
      setAppContext(prev => ({ ...prev, projectYearId }))
    },
    []
  )

  return {
    appContext,
    notifications,
    removeNotification,
    sendNotification,
    updateCSModeContext,
    updateProjectModeContext,
    updateStyleContext,
    updateUserContext,
    updateProjectYearIdContext
  }
}

const AppContext = createContext<AppContextState>(defaultAppContext)

export const useApp = (): AppContextState => {
  return useContext(AppContext)
}

export const AppContextConsumer = AppContext.Consumer

export const AppContextProvider = ({ children }: { children: React.ReactElement }) => {
  const {
    appContext,
    notifications,
    removeNotification,
    sendNotification,
    updateCSModeContext,
    updateProjectModeContext,
    updateStyleContext,
    updateUserContext,
    updateProjectYearIdContext
  } = useAppContext(defaultContext)

  return (
    <AppContext.Provider
      value={{
        appContext,
        notifications,
        removeNotification,
        sendNotification,
        updateCSModeContext,
        updateProjectModeContext,
        updateStyleContext,
        updateUserContext,
        updateProjectYearIdContext
      }}
    >
      <div data-mode={appContext.style.mode} data-theme={appContext.style.theme}>
        {!!notifications.length &&
          notifications.map((notification, i) => (
            <SDKNotification
              key={`notification-${i}`}
              status={notification.status}
              message={notification.message}
              onClose={() => removeNotification(notification)}
              duration={notification.duration}
            />
          ))}
        {children}
      </div>
    </AppContext.Provider>
  )
}
