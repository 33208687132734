import type { AxiosResponse } from 'axios'
import { generateFileNameFromResponse, sanitizeFilename } from './generateFileName'

export const generateFile = (response: AxiosResponse<any, any>, name?: string) => {
  response.data.lastModified = new Date()
  const fileName = name ? sanitizeFilename(name) : generateFileNameFromResponse(response)

  if (fileName) {
    const blobAnchor: string = window.URL.createObjectURL(response.data)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = blobAnchor
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(blobAnchor)
  }
}
