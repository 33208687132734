import { SDKText } from '../../../../../../../../../components/SDK/Text'
import { useProject } from '../../../../../../../../../context/project.context'
import { useTransformNumberToMoney } from '../../../../../../../utils/useTransformNumberToMoney'
import type { IParentScopeYearTotal } from '../../../../../types/scope'
import { useFeesAmountCalculation } from '../../utils/useFeesAmountCalculation'

interface ISingleRowGrid {
  fees: IParentScopeYearTotal[]
}

export const SingleRowGrid = ({ fees }: ISingleRowGrid) => {
  const { project } = useProject()
  const yearsCount = project?.yearsCount ? project.yearsCount : 1
  const { modifiedFees } = useFeesAmountCalculation(yearsCount, fees)
  const transformNumberToMoney = useTransformNumberToMoney()

  return (
    <div className='row h-100'>
      <div className='ap-flex justify-content-around align-items-center'>
        {modifiedFees.map(({ totalValue }, index) => (
          <div key={`single-row-grid-${index}`}>
            <div className={`col col-sm-${12 / yearsCount} text-align-right ap-pr-spacing-4`}>
              <SDKText>{transformNumberToMoney(totalValue)}</SDKText>
            </div>
            {index !== modifiedFees.length - 1 && <div className='border-right h-100'></div>}
          </div>
        ))}
      </div>
    </div>
  )
}
