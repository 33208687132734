import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { EmptyState } from '../../../../../../components/EmptyState'
import { SDKModal } from '../../../../../../components/SDK/Modal'
import { SDKText } from '../../../../../../components/SDK/Text'
import { usePricingTab } from '../../../../../../context/pricingTab.context'
import { useAppDispatch } from '../../../../../../store'
import { DragLayer } from '../../components/DragLayer'
import { ScopeConfigurator } from '../../components/ScopeConfigurator'
import { ScopesWrapper } from '../../components/ScopesWrapper'
import { EPricingTab } from '../../const/enums'
import { getCustomModuleById } from '../../modals/store/selectors'
import { getCustomModules } from '../../store/actions'
import type { ITransformedData } from '../../types/dnd'
import type { ICustomModuleData } from '../../types/inputsTab'
import { transformData } from '../../utils/transformData'
import { AddEditCustomModuleModal } from '../components/ControllableTabs/components/AddEditCustomModuleModal'
import { useDeleteCustomModule } from './utils/useDeleteCustomModule'

const CustomModuleContent = ({ data }: { data: ICustomModuleData | null }) => {
  const { t, i18n } = useTranslation('pricing')
  const [transformedData, setTransformedData] = useState<ITransformedData | null>(null)
  const [isModalVisible, setModalVisible] = useState(false)
  const dispatch = useAppDispatch()
  const { customModuleId } = usePricingTab()
  const customModule = useSelector(getCustomModuleById(customModuleId))

  const refreshCustomModules = () => {
    if (data) {
      dispatch(getCustomModules(data.projectId))
    }
  }
  const { showDeleteModal, setShowDeleteModal, handleDelete, loading } = useDeleteCustomModule(
    data,
    refreshCustomModules
  )

  useEffect(() => {
    if (data) {
      const newData = transformData(
        data.parentScopes,
        data.scopeIncludes,
        data.scopeExcludes,
        data.scopeAssumes,
        { type: data.type, name: data.name, projectId: data.projectId }
      )
      setTransformedData(newData)
    }
  }, [data])

  return (
    <>
      <ScopeConfigurator
        name={EPricingTab.CustomModule}
        customName={data?.name}
        moduleMetadata={data?.moduleMetadata}
        withRadioBtn={false}
        withDelete={true}
        withEditName={true}
        customModuleId={data?.moduleId}
        onDelete={setShowDeleteModal}
        onEditName={setModalVisible}
      />
      {data ? (
        <ScopesWrapper totalFee={data.quotes?.scopeTotal} title={data.name}>
          <>
            {transformedData && (
              <DragLayer transformedData={transformedData} quotes={data.quotes} />
            )}
          </>
        </ScopesWrapper>
      ) : (
        <EmptyState />
      )}
      {showDeleteModal && (
        <SDKModal
          onCancel={() => setShowDeleteModal(false)}
          onSubmit={() => handleDelete()}
          title={t('tabs.customModule.delete.title', { name: data?.name })!}
          submitLabel={i18n.t('buttons.confirm')!}
          visible={showDeleteModal}
          withCancel={true}
          withRequired={false}
          loading={loading}
        >
          <SDKText>{t('tabs.customModule.delete.confirmText')}</SDKText>
        </SDKModal>
      )}
      <AddEditCustomModuleModal
        customModule={customModule || null}
        onCloseModal={() => {
          setModalVisible(false)
          refreshCustomModules()
        }}
        visible={isModalVisible}
      />
    </>
  )
}

const CustomModule = () => {
  const {
    tabContent: { selector }
  } = usePricingTab()
  const { data } = useSelector(selector)

  return <CustomModuleContent data={data as ICustomModuleData} />
}

export default CustomModule
