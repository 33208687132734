import { IAnswer } from '../../../../questionnaires/type'

export type TTableValue = {
  country: string
  item: string
  newValue: string | JSX.Element
  oldValue: string | JSX.Element
}
export type TAccordionValue = { [key: string]: TTableValue[] }

export enum EProjectChangeType {
  AnswerChanged = 0,
  FormulaChanged = 1,
  QuestionDeleted = 2,
  PricingToolVersionIdChanged = 3
}

export interface IChangeItem {
  newAnswer: IAnswer | null
  newFormula: string | null
  newFormulaIsActive: boolean
  newPricingToolVersionId: number | null
  oldPricingToolVersionId: number | null
  oldAnswer: IAnswer | null
  oldFormula: string | null
  oldFormulaIsActive: boolean
  projectChangeType: number
  questionType: number | null
}

export interface IProjectValues {
  countryName: string
  itemName: string
  changeItem: IChangeItem
}
