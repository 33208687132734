export function string2kebabCase(text?: string | null): string {
  if (!text) {
    return ''
  }

  return text
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase()
}
