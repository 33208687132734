import { useTranslation } from 'react-i18next'
import { ActionButton } from '../../../../../components/ActionButton'
import { EmptyState } from '../../../../../components/EmptyState'
import { SDKButton } from '../../../../../components/SDK/Button'
import { SDKIcon } from '../../../../../components/SDK/Icon'
import { SDKText } from '../../../../../components/SDK/Text'
import { TRegionsSectionElement } from '../../../types'

interface IRegionsListDetailsProps {
  regions: TRegionsSectionElement[]
  addRegion: () => void
  editRegion: (data: string, id: number) => void
  deleteRegion: (data: string, id: number) => void
  title: string
}

export const RegionsListDetails = ({
  regions,
  addRegion,
  editRegion,
  deleteRegion,
  title
}: IRegionsListDetailsProps) => {
  const { t } = useTranslation('regions')
  return (
    <div className='accordion-content ap-container default-modal ap-px-spacing-1  ap-py-spacing-5'>
      <SDKButton kind='secondary' onClick={addRegion} className='add-region-btn'>
        <SDKIcon code='plus' />
        {t('addRegion')}
      </SDKButton>
      {regions.length ? (
        regions.map((region, index) => (
          <div
            key={`regions-list-element-${index}`}
            className='row region-details-container ap-p-spacing-5 ap-m-spacing-1 ap-border-color-background-border'
          >
            <SDKText className='col ap-text-color-text-heading' weight={2}>
              {region.name}
            </SDKText>
            <div className='col ap-flex justify-content-end'>
              <div
                onClick={() => editRegion(title, region.regionId)}
                className='ap-text-color-text-body pointer ap-pr-spacing-3'
              >
                <SDKIcon code='edit' />
                <SDKText className='edit-txt'>{t('edit')}</SDKText>
              </div>
              <ActionButton
                icon='delete'
                content=''
                handleClick={() => deleteRegion(region.name, region.regionId)}
              />
            </div>
          </div>
        ))
      ) : (
        <EmptyState type='accordion-select' />
      )}
    </div>
  )
}
