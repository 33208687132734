import { CustomBadge } from '../../../../components/CustomBadge'
import { Divider } from '../../../../components/Divider'
import { SDKButton } from '../../../../components/SDK/Button'
import { SDKIcon } from '../../../../components/SDK/Icon'
import { SDKText } from '../../../../components/SDK/Text'

import './index.scss'

interface IDocumentListItem {
  name: string
  id: string
  idTitle: string
  onEdit: (id: string) => void
  onDelete?: (id: string) => void
}
export const DocumentListItem = ({ name, id, idTitle, onEdit, onDelete }: IDocumentListItem) => {
  return (
    <div className='document-list-item'>
      <SDKText type='subheading' className='ap-text-neutral-20'>
        {name}
      </SDKText>
      <Divider />
      <SDKText type='body-xs' className='ap-text-neutral-14 ap-mt-spacing-1'>
        {idTitle}
      </SDKText>
      <div className='wrapper'>
        <CustomBadge value={id} />
        <div className='flex'>
          <SDKButton
            onClick={() => onEdit(id)}
            kind='text'
            heightAuto
            className='p-0 ap-text-neutral-17'
          >
            <SDKIcon code='edit' className='pointer' />
          </SDKButton>
          {onDelete && (
            //TODO-remove disabled props when delete logic is done
            <SDKButton disabled onClick={() => onDelete(id)} kind='text' className='p-0 icon-btn'>
              <SDKIcon code='delete' className='pointer' />
            </SDKButton>
          )}
        </div>
      </div>
    </div>
  )
}
