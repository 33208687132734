import { IUserBase } from '../../../types/user'
import type { TCalendarQuestionOptions } from '../../countriesSnapshot/types'
import type { IProjectResult, ProjectAnswerByQuestion } from '../../project/types/projectResults'

export interface IQuestionnaireCountry {
  unansweredQuestionsCount: number
  isVisible?: boolean
  isPublished: boolean
  isVerified: boolean
  verifiedByUser: IUserBase
  lastVerifiedDate: Date
  name: string
  yearId: number
  countryId: number
}

export interface IQuestionLanguage {
  languageId: number
  name: string
}

export interface IAnswer extends ProjectAnswerByQuestion {
  isNotApplicable: boolean
}

export interface IQuestionnaireQuestion extends IProjectResult {
  isEnabled: boolean
  answer: IAnswer
  currencyCode: string | null
  calendarQuestionOptions: TCalendarQuestionOptions
  gateQuestionFormula: string | null
  isGateQuestion: boolean
  isAdminReviewRequired: boolean
}

export interface IAddAnswer {
  answerText?: string
  rate?: number
  dateActionPerformer?: number
  dateOffsetType?: number
  daysOffset?: number
  isGateQuestionFormulaCorrect?: boolean
  monthsOffset?: number
  openText?: string
  selectedAnswerId?: number
  selectedAnswerIds?: number[]
  selectedLanguageId?: number
  url: string
}

export enum EDateActionPerformer {
  localEntityOption = 1,
  ultimateParentEntityOption = 0
}

export enum EDateOffsetType {
  relativeToCalendarYearOption = 2,
  otherOption = 4,
  relativeToFilingTaxReturnOption = 0,
  relativeToFyeOption = 1,
  relativeToInquiryOrAuditOption = 3
}

export type TFilter = {
  states: (string | number)[]
  types: (string | number)[]
  subcategoryIds: number[]
}
