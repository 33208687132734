import { useTranslation } from 'react-i18next'
import { BlankPage } from '../../../components/BlankPage'
import { useErrorText } from '../utils/useErrorText'
import { ErrorTitle } from './components/ErrorTitle'

export const ErrorPage = ({
  children,
  errorText,
  errorTitle
}: {
  children?: JSX.Element
  errorText?: string
  errorTitle?: string
}) => {
  const { t } = useTranslation('errors')

  const renderErrorText = useErrorText(errorText)

  return (
    <BlankPage
      middleContent={
        <>
          <ErrorTitle text={errorTitle ?? t('title')} />

          {renderErrorText}
          {children}
        </>
      }
    ></BlankPage>
  )
}
