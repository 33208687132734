import type { UploadProps } from '@appkit4/react-components'
import type { Control, FieldValues, Path } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useApp } from '../../../context/app.context'
import {
  UNSUPPORTED_EXCEL_TYPES,
  UPLOADED_FILE_TYPE_ERROR,
  XLMX_EXTENSION
} from '../../../modules/project/constants'
import { UploadField } from '../../UploadField'

export const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10 MiB - ten mebibytes

interface IFormUploadInputProps<T extends FieldValues> extends UploadProps {
  label: string
  name: Path<T>
  control: Control<T, any>
  acceptFileType: string
  errorMessage?: string
  multiple?: boolean
  autoUpload?: boolean
  maxFileSize?: number
  onErrorFunction?: () => void
}

type Props<T extends FieldValues> = IFormUploadInputProps<T>

export const FormUploadInput = <T extends FieldValues>({
  label,
  control,
  name,
  acceptFileType,
  multiple = false,
  autoUpload = false,
  maxFileSize = MAX_FILE_SIZE,
  onErrorFunction,
  ...props
}: Props<T>) => {
  const { sendNotification } = useApp()
  return (
    <Controller
      name={name as Path<T>}
      control={control}
      render={({ field }) => (
        <UploadField
          options={{
            multiple,
            autoUpload,
            maxFileSize,
            acceptFileType,
            uploadInstruction: label
          }}
          {...field}
          onError={({ error, file }: { error: string; file: File }) => {
            const index = file.name.lastIndexOf('.')
            const extension = file.name.substring(index)
            const unsupportedType =
              acceptFileType === XLMX_EXTENSION && file.type === UNSUPPORTED_EXCEL_TYPES
            if (
              acceptFileType.toLowerCase().includes(extension.toLowerCase()) &&
              error === UPLOADED_FILE_TYPE_ERROR &&
              !unsupportedType
            ) {
              return
            } else {
              sendNotification({
                message: error,
                status: 'error'
              })
            }
            onErrorFunction?.()
          }}
          {...props}
        />
      )}
    />
  )
}
