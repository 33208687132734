import { useEffect, useState } from 'react'
import { SDKAccordion } from '../Accordion'
import type { IAccordionItemProps } from '../AccordionItem'
import { SDKAccordionItem } from '../AccordionItem'
import { SDKCheckbox } from '../Checkbox'
import { SDKText } from '../Text'
import './index.scss'

interface IAccordionProps extends IAccordionItemProps {
  title: string
  onChange: (c: boolean) => void
  defaultActivekeys?: string[]
  checked?: boolean
  disabled?: boolean
  indeterminate?: boolean
}
export const SDKAccordionWitCheckbox = ({
  title,
  checked,
  indeterminate,
  children,
  onChange,
  disabled,
  defaultActivekeys = [],
  ...props
}: IAccordionProps) => {
  const [activeKeys, setActiveKeys] = useState<string[]>([])

  useEffect(() => {
    setActiveKeys(defaultActivekeys)
  }, [defaultActivekeys])

  return (
    <SDKAccordion
      className='accordion-wit-checkbox'
      activeKeys={activeKeys}
      onClick={keys => setActiveKeys(keys)}
    >
      <SDKAccordionItem
        toggleFromHeaderIconOnly={true}
        templateHeader={() => (
          <>
            <SDKCheckbox
              checked={!!checked}
              indeterminate={!!indeterminate}
              onChange={onChange}
              disabled={disabled}
            />
            <SDKText weight={2} className='ap-pt-spacing-1'>
              {title}
            </SDKText>
          </>
        )}
        {...props}
      >
        {children}
      </SDKAccordionItem>
    </SDKAccordion>
  )
}
