export const getDuration = (createdDate: string, finishedDate: string) => {
  const finishedDateToTime = new Date(finishedDate).getTime()
  const createdDateToTime = new Date(createdDate).getTime()
  const difference = finishedDateToTime - createdDateToTime
  const date = new Date(difference)
  const seconds = date.getUTCSeconds()
  const minutes = date.getUTCMinutes()
  const hours = date.getUTCHours()
  return (
    '' +
    (hours > 0 ? `${hours} hours ` : '') +
    (minutes > 0 ? `${minutes} minutes ` : '') +
    (seconds > 0 ? `${seconds} seconds ` : '')
  )
}
