import { EPricingTab } from '../../../modules/project/components/GCDPricing/const/enums'
import type { IBenchmark } from '../../../modules/project/components/GCDPricing/types/inputsTab'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const getBenchmarkData = async (projectId: number): Promise<IBenchmark> => {
  return (
    await api.get(
      `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/${ProjectsUrl.Modules}/${EPricingTab.Benchmark}`
    )
  ).data
}
