import { yupResolver } from '@hookform/resolvers/yup'
import queryString from 'query-string'
import { useEffect, useState } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RHF_SET_VALUE_OPTIONS } from '../../../../components/Form/utils'
import { SDKModal } from '../../../../components/SDK/Modal'
import { CancelButton } from '../../../../components/SDK/Modal/components/FooterButtons/CancelButton'
import { SaveButton } from '../../../../components/SDK/Modal/components/FooterButtons/SaveButton'
import {
  EFilterProjectFields,
  IFormValues,
  useFilterProjectSchema
} from '../../../../schemas/useFilterProjectSchema'
import { FiscalYearBase } from '../../../fiscalYearSetup/types'
import { getMyProjects } from '../../store/selectors'
import { getYearsFromProjects } from '../ProjectList/utils/getYearsFromProjects'
import { ProjectFilter } from './components/ProjectFilter'
import { ProjectSorting } from './components/ProjectSorting'
import { ESortOption, ESortOptionValue } from './constants'
import { TFilter } from './types'
import { useSetQueryString } from './utils/useSetQueryString'

interface IFilterProjectsModalProps {
  onClose: () => void
}
const DEFAULT_FILTERS: TFilter = {
  yearIds: [],
  projectIds: [],
  [ESortOption.date]: ESortOptionValue.asc,
  [ESortOption.name]: null
}
const FilterProjectsModal = ({ onClose }: IFilterProjectsModalProps) => {
  const { t } = useTranslation('myprojects')
  const { data: projects } = useSelector(getMyProjects)
  const [years, setYears] = useState<FiscalYearBase[]>([])
  const [currentFilters, setCurrentFilters] = useState<TFilter>(DEFAULT_FILTERS)
  const { validationSchema, defaultValues } = useFilterProjectSchema({
    DEFAULT_FILTERS,
    query: queryString.parse(location.search),
    projects,
    years
  })

  useEffect(() => {
    if (projects) {
      setYears(getYearsFromProjects(projects))
    }
  }, [projects])

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isDirty }
  } = useForm<FieldValues, IFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    reset(defaultValues)
  }, [years, projects])

  useEffect(() => {
    setCurrentFilters(
      defaultValues[ESortOption.name]
        ? { ...defaultValues, [ESortOption.date]: null }
        : defaultValues
    )
  }, [years, projects])

  const onSubmit = () => {
    const query = useSetQueryString(currentFilters, projects)
    window.history.pushState(null, '', query)
    onClose()
  }

  const setSorting = (value: ESortOptionValue, key: ESortOption) => {
    switch (key) {
      case ESortOption.date:
        setCurrentFilters({
          ...currentFilters,
          [ESortOption.date]: value,
          [ESortOption.name]: null
        })
        setValue(EFilterProjectFields.name, null, RHF_SET_VALUE_OPTIONS)
        break
      case ESortOption.name:
        setCurrentFilters({
          ...currentFilters,
          [ESortOption.date]: null,
          [ESortOption.name]: value
        })
        setValue(EFilterProjectFields.date, null, RHF_SET_VALUE_OPTIONS)
        break

      default:
        break
    }
  }

  const onClear = () => {
    setCurrentFilters({
      ...currentFilters,
      yearIds: DEFAULT_FILTERS.yearIds,
      projectIds: DEFAULT_FILTERS.projectIds
    })
    setValue(
      EFilterProjectFields.projectIds,
      projects.map(p => String(p.projectId)) || DEFAULT_FILTERS.projectIds,
      RHF_SET_VALUE_OPTIONS
    )
    setValue(
      EFilterProjectFields.yearIds,
      years.map(y => String(y.yearId)) || DEFAULT_FILTERS.yearIds,
      RHF_SET_VALUE_OPTIONS
    )
  }

  const footer = (
    <>
      <CancelButton onCancel={onClear} cancelText={t('filter.clearFilter')!} />
      <SaveButton
        onSubmit={handleSubmit(onSubmit)}
        submitLabel={t('filter.submitButton')!}
        disabledSubmit={!isDirty}
      />
    </>
  )
  return (
    <SDKModal onCancel={onClose} title={t('filter.title')!} footer={footer} visible>
      <ProjectFilter
        setCurrentFilters={setCurrentFilters}
        currentFilters={currentFilters}
        years={years}
        control={control}
      />
      <ProjectSorting sortBy={ESortOption.date} control={control} setSorting={setSorting} />
      <ProjectSorting sortBy={ESortOption.name} control={control} setSorting={setSorting} />
    </SDKModal>
  )
}

export default FilterProjectsModal
