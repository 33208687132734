import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CardListItem } from '../../../../../../components/CardListItem'
import { EAction, ECardListItem } from '../../../../../../components/CardListItem/const'
import type { IActionCallback } from '../../../../../../components/CardListItem/type/index'
import { SDKModal } from '../../../../../../components/SDK/Modal'
import { SDKText } from '../../../../../../components/SDK/Text'
import { Url } from '../../../../../../constants/urls'
import { useAppDispatch } from '../../../../../../store'
import type { IDocument } from '../../../../../../types/document'
import { clearUpdateLegalDocument } from '../../../../store/actions'
import { getLegalDocuments } from '../../../../store/selectors'
import { useDeleteDocument } from './utils/useDeleteDocument'

export const LegalStatementDocumentsList = () => {
  const { t, i18n } = useTranslation('systemManagement')
  const navigation = useNavigate()
  const dispatch = useAppDispatch()
  const { data } = useSelector(getLegalDocuments)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState<{
    name: string
    documentId: number
  } | null>(null)
  const { onDelete } = useDeleteDocument(selectedDocument?.name)

  const list = data.map(
    item =>
      ({
        documentId: item.documentId,
        identifier: item.identifier,
        name: item.name,
        requiresApproval: item.requiresApproval
      } as IDocument)
  )

  const onActionClick = ({ action, id }: IActionCallback, document: IDocument) => {
    if (action === EAction.Edit) {
      navigation(`${location.pathname}${Url.EditLegalDocumentPage}/${id}`)
      dispatch(clearUpdateLegalDocument())
    }
    if (action === EAction.Delete) {
      setShowDeleteModal(true)
      setSelectedDocument({ name: document.name, documentId: Number(id) })
    }
  }

  return (
    <>
      <div className='row'>
        <div className='ap-grid mx-0'>
          {list?.map((document: IDocument) => (
            <div className='g-col-12 g-col-md-4' key={document.documentId}>
              <CardListItem
                actions={
                  document.requiresApproval ? [EAction.Edit, EAction.Delete] : [EAction.Edit]
                }
                handleClick={callback => onActionClick(callback, document)}
                cardListItem={ECardListItem.LegalDocumentCard}
                {...document}
              />
            </div>
          ))}
        </div>
      </div>
      <SDKModal
        onCancel={() => setShowDeleteModal(false)}
        onSubmit={() => onDelete(selectedDocument?.documentId!)}
        title={t('legalDocuments.deleteDocument.title', { name: selectedDocument?.name })!}
        submitLabel={i18n.t('buttons.confirm')!}
        visible={showDeleteModal}
        withCancel={true}
        withRequired={false}
      >
        <SDKText>{t('legalDocuments.deleteDocument.confirmText')}</SDKText>
      </SDKModal>
    </>
  )
}
