import { CSSProperties } from 'react'
import { SDKIcon } from '../SDK/Icon'
import { SDKTooltip } from '../SDK/Tooltip'

interface IIconWithTooltip {
  code: string
  content: string
  iconClassName?: string
  disabled?: boolean
  tooltipStyle?: CSSProperties
}

export const IconWithTooltip = ({
  code,
  iconClassName = '',
  content,
  disabled,
  tooltipStyle
}: IIconWithTooltip) => {
  return (
    <SDKTooltip content={content} disabled={disabled} style={tooltipStyle}>
      <div>
        <SDKIcon code={code} className={iconClassName} />
      </div>
    </SDKTooltip>
  )
}
