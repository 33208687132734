import classnames from 'classnames'
import { Component } from 'react'
import { LoadingStatus } from '../../shared/types/enums'
import { Resource } from '../../shared/utils/store'
import { EmptyState } from '../EmptyState'
import { SDKLoading } from '../SDK/Loading'

export const CardListWrapper = ({
  classNames,
  children,
  emptyStateComponent,
  ignoreEmptyState = false,
  resource
}: {
  classNames: string
  children: JSX.Element
  emptyStateComponent?: Component | JSX.Element
  ignoreEmptyState?: boolean
  resource: Resource<any>
}) => {
  const emptyState = emptyStateComponent || <EmptyState />

  return resource.loading === LoadingStatus.Pending ? (
    <SDKLoading />
  ) : resource.data.length === 0 && !ignoreEmptyState ? (
    <>{emptyState}</>
  ) : (
    <div className={classnames('mt-2', classNames)}>{children}</div>
  )
}
