import { IAnswer } from '../../project/types/projectResults'

export enum EOperator {
  AND = 0,
  OR = 1
}

export type TSubcategory = {
  subcategoryId: number
  categoryId: number
  name: string
}

export type TCategory = {
  categoryId: number
  name: string
  yearId: number
  subcategories: TSubcategory[]
}

export type TCategoryInQuestion = {
  questionCategoryId: number
  name: string
}

export type TSubcategoryInQuestion = {
  questionSubcategoryId: number
  name: string
}
export type TCalendarQuestionOptions = {
  localEntityOption: boolean
  relativeToFilingTaxReturnOption: boolean
  relativeToFyeOption: boolean
  relativeToCalendarYearOption: boolean
  relativeToInquiryOrAuditOption: boolean
  otherOption: boolean
  ultimateParentEntityOption: boolean
}

export type TQuestionConditions = {
  questionConditionId: number
  parentQuestionId: number
  possibleAnswerDefinitionId: number
  operator: EOperator
}

export type TQuestion = {
  calendarQuestionOptions: TCalendarQuestionOptions | null
  category: TCategoryInQuestion
  isDeletable: boolean
  possibleAnswers: IAnswer[]
  questionId: number
  questionText: string
  subcategory: TSubcategoryInQuestion
  type: string
  defaultNaText: string
  questionConditions: TQuestionConditions[]
}
