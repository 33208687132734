// Sorting an array of objects by any object's property
// Value is sorted case insensitive
export function objectSortByProperty(property: string): any {
  let sortOrder = 1
  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }
  return function (a: any, b: any) {
    const result =
      a[property].toLowerCase() < b[property].toLowerCase()
        ? -1
        : a[property].toLowerCase() > b[property].toLowerCase()
        ? 1
        : 0
    return result * sortOrder
  }
}
