import { useTranslation } from 'react-i18next'
import { SDKIcon } from '../../../../components/SDK/Icon'
import { SDKText } from '../../../../components/SDK/Text'
import { SDKTooltip } from '../../../../components/SDK/Tooltip'
import { TQuestion } from '../../types'
import './index.scss'

interface IQuestionItemProps {
  question: TQuestion
  index: number
  openEditQuestion: (qId: number) => void
  openDeleteQuestion: (qId: number) => void
  canMoveUp: boolean
  canMoveDown: boolean
  moveQuestionUp: (index: number) => void
  moveQuestionDown: (index: number) => void
}

export const QuestionItem = ({
  openEditQuestion,
  openDeleteQuestion,
  question,
  index,
  canMoveUp,
  canMoveDown,
  moveQuestionUp,
  moveQuestionDown
}: IQuestionItemProps) => {
  const { t } = useTranslation('countriesSnapshot')

  return (
    <div className='question-item'>
      <SDKText type='body'>
        <div className='ap-flex'>
          <div className='question-item-arrows'>
            <div onClick={() => canMoveUp && moveQuestionUp(index)}>
              <SDKIcon code='up-chevron' className='pointer' disabled={!canMoveUp}></SDKIcon>
            </div>
            <div onClick={() => canMoveDown && moveQuestionDown(index)}>
              <SDKIcon code='down-chevron' className='pointer' disabled={!canMoveDown}></SDKIcon>
            </div>
          </div>

          <div className='question-text'>{question.questionText}</div>
        </div>
      </SDKText>
      <div className='manage-question'>
        <div
          className='edit-question ap-pr-spacing-5'
          onClick={() => openEditQuestion(question.questionId)}
        >
          <SDKIcon code='edit'></SDKIcon>
          <SDKText weight={1} className='edit-text'>
            {t('edit')}
          </SDKText>
        </div>
        {question.isDeletable ? (
          <div onClick={() => openDeleteQuestion(question.questionId)}>
            <SDKIcon code='delete'></SDKIcon>
          </div>
        ) : (
          <SDKTooltip content={t('unableToDelete')!}>
            <div>
              <SDKIcon code='information'></SDKIcon>
            </div>
          </SDKTooltip>
        )}
      </div>
    </div>
  )
}
