import { useMemo } from 'react'
import { LoadingOverlay } from '../../../../../../components/SDK/LoadingOverlay'
import { LoadingStatus } from '../../../../../../shared/types/enums'
import { IProjectResultByCountry, IProjectResultByQuestion } from '../../../../types/projectResults'
import { ResultsByCountry } from '../../../ResultsByCountry'
import { ResultsByQuestion } from '../../../ResultsByQuestion'

interface ISortedResultsContainerProps {
  activeOption: number
  countries: IProjectResultByCountry[]
  loading: LoadingStatus
  questions: IProjectResultByQuestion[]
  yearId?: number
}

export const SortedResultsContainer = ({
  activeOption,
  countries,
  loading,
  questions,
  yearId
}: ISortedResultsContainerProps) => {
  const countryComponent = useMemo(() => {
    if (activeOption === 0) {
      return <ResultsByCountry activeOption={activeOption} countries={countries} yearId={yearId} />
    }

    return null
  }, [countries, activeOption])

  const questionsComponent = useMemo(() => {
    if (activeOption === 1) {
      return <ResultsByQuestion activeOption={activeOption} questions={questions} yearId={yearId} />
    }

    return null
  }, [questions, activeOption])

  return (
    <>
      {loading === LoadingStatus.Pending && <LoadingOverlay />}
      {loading === LoadingStatus.Succeeded && (
        <>
          {countryComponent}
          {questionsComponent}
        </>
      )}
    </>
  )
}
