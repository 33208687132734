import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Module } from '../../../constants/modules'
import { createFormulaOverride } from '../../../services/requests/taraFields/createFormulaOverride'
import { createFormulaValidation } from '../../../services/requests/taraFields/createFormulaValidation'
import { editFormulaOverride } from '../../../services/requests/taraFields/editFormulaOverride'
import { editFormulaOverrideDescription } from '../../../services/requests/taraFields/editFormulaOverrideDescription'
import { getDataFormulaOverrideByCountry } from '../../../services/requests/taraFields/getDataFormulaOverrideByCountry'
import { getDataFormulaOverrideDescription } from '../../../services/requests/taraFields/getDataFormulaOverrideDescription'
import { getDataFormulaOverrides } from '../../../services/requests/taraFields/getDataFormulaOverrides'
import { getGateQuestionsByCountryRequest } from '../../../services/requests/taraFields/getGateQuestionsByCountryRequest'
import { IFormulaCreate, IFormulaDescription, IFormulaUpdate, IFormulaValidation } from '../types'

const moduleName: string = String(Module.TaraFields)

export const getFormulaOverrides = createAsyncThunk(
  `${moduleName}/getFormulaOverrides`,
  (yearId: number) => getDataFormulaOverrides(yearId)
)
export const clearGetFormulaOverrides = createAction(`${moduleName}/clearGetFormulaOverrides`)

export const getGateQuestionsByCountry = createAsyncThunk(
  `${moduleName}/getGateQuestionsByCountry`,
  ({ countryId, yearId }: { countryId: number; yearId: number }) =>
    getGateQuestionsByCountryRequest({ countryId, yearId })
)
export const clearGetGateQuestionsByCountry = createAction(
  `${moduleName}/clearGetGateQuestionsByCountry`
)

export const getFormulaOverrideByCountry = createAsyncThunk(
  `${moduleName}/getFormulaOverrideByCountry`,
  (id: number) => getDataFormulaOverrideByCountry(id)
)
export const clearGetFormulaOverrideByCountry = createAction(
  `${moduleName}/clearGetFormulaOverrideByCountry`
)

export const getFormulaOverrideDescription = createAsyncThunk(
  `${moduleName}/getFormulaOverrideDescription`,
  () => getDataFormulaOverrideDescription()
)
export const clearGetFormulaOverrideDescription = createAction(
  `${moduleName}/clearGetFormulaOverrideDescription`
)

export const updateFormulaOverride = createAsyncThunk(
  `${moduleName}/updateFormulaOverride`,
  ({
    countryId,
    formulaId,
    params
  }: {
    countryId: number
    formulaId: number
    params: IFormulaUpdate
  }) => editFormulaOverride({ countryId, formulaId, params })
)
export const clearUpdateFormulaOverride = createAction(`${moduleName}/clearUpdateFormulaOverride`)

export const addFormulaOverride = createAsyncThunk(
  `${moduleName}/addFormulaOverride`,
  ({ countryId, params }: { countryId: number; params: IFormulaCreate }) =>
    createFormulaOverride({ countryId, params })
)
export const clearAddFormulaOverride = createAction(`${moduleName}/clearAddFormulaOverride`)

export const updateFormulaOverrideDescription = createAsyncThunk(
  `${moduleName}/updateFormulaOverrideDescription`,
  ({ params }: { params: IFormulaDescription }) => editFormulaOverrideDescription({ params })
)
export const clearUpdateFormulaOverrideDescription = createAction(
  `${moduleName}/clearUpdateFormulaOverrideDescription`
)

export const updateFormulaValidation = createAsyncThunk(
  `${moduleName}/updateFormulaValidation`,
  ({ params }: { params: IFormulaValidation }) => createFormulaValidation({ params })
)
export const clearUpdateFormulaValidation = createAction(
  `${moduleName}/clearUpdateFormulaValidation`
)
