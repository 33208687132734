import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '../../../../components/ActionButton'
import { SDKColumn } from '../../../../components/SDK/Column'
import { SDKTableSeparatedByLines } from '../../../../components/SDK/TableSeparatedByLines'
import { SDKText } from '../../../../components/SDK/Text'
import {
  renderAdditionalInformationCell,
  renderAnswerCell,
  renderBreadcrumbsWithTextCell,
  renderCell
} from '../../../../components/TableCells'
import { useApp } from '../../../../context/app.context'
import { useMyRole } from '../../../../context/hooks/useMyRole'
import { EAnswerTypeForEdit } from '../../../countriesSnapshot/pages/AddEditQuestion/constants/answerType'
import type { IProjectAnswerByCountry, ProjectAnswerByQuestion } from '../../types/projectResults'
import { CountryContactInformation } from '../ResultsByCountry/components/CountryContactInformation'
import './index.scss'

interface IResultsTabTableProps {
  activeOption: number
  answers: ProjectAnswerByQuestion[] | IProjectAnswerByCountry[]
  type?: EAnswerTypeForEdit
  id?: number
  yearId?: number
}

export const ResultsTabTable = ({
  activeOption,
  answers,
  id,
  type,
  yearId
}: IResultsTabTableProps) => {
  const { t } = useTranslation('myprojects')
  const [countryId, setCountryId] = useState<number | null>(null)
  const {
    appContext: { projectYearId }
  } = useApp()
  const initSelect = answers.map(item => ({
    ...item,
    selected: false
  }))
  const { amIExternal } = useMyRole()
  const isMoreIconHidden = amIExternal

  return (
    <>
      <div className='results-tab-table-container'>
        {activeOption === 0 && (
          <div className='results-tab-table-countries'>
            <SDKTableSeparatedByLines originalData={answers} hasTitle>
              <SDKColumn
                field='questionText'
                renderCell={row =>
                  renderBreadcrumbsWithTextCell(
                    [row.category.name, row.subcategory.name],
                    row.questionText
                  )
                }
              >
                {t('results.tables.question')}
              </SDKColumn>
              <SDKColumn
                field='answerText'
                renderCell={(row, field) =>
                  renderAnswerCell({
                    field,
                    answerText: row[field],
                    type: type || row.type,
                    calendarAnswerResponse: row.calendarAnswerResponse,
                    id: id || row.questionId,
                    selectedAnswers: row.selectedAnswers,
                    offset: {
                      days: row.calendarAnswerResponse?.daysOffset,
                      months: row.calendarAnswerResponse?.monthsOffset
                    },
                    isNotAnswered: row.isNotAnswered,
                    notAsweredText: t('na')
                  })
                }
              >
                {t('results.tables.answer')}
              </SDKColumn>
              <SDKColumn
                field='openText'
                renderCell={row => renderAdditionalInformationCell(row.openText, row.url)}
              >
                {t('results.tables.additionalInformation')}
              </SDKColumn>
            </SDKTableSeparatedByLines>
          </div>
        )}
        {activeOption === 1 && (
          <SDKTableSeparatedByLines originalData={initSelect} hasTitle>
            <SDKColumn
              field='countryName'
              renderCell={row => {
                return (
                  <div className='ap-text-neutral-20 ap-flex'>
                    <SDKText type='subheading'>{row.countryName}</SDKText>
                    {!isMoreIconHidden && (
                      <ActionButton
                        icon='circle-more'
                        kind='neutral'
                        content=''
                        handleClick={(e: React.MouseEvent) => {
                          e.stopPropagation()
                          setCountryId(row.countryId)
                        }}
                      />
                    )}
                  </div>
                )
              }}
            >
              {t('results.tables.country')}
            </SDKColumn>
            <SDKColumn
              field='answerText'
              renderCell={(row, field) =>
                renderAnswerCell({
                  field,
                  answerText: row[field],
                  type: type || row.type,
                  calendarAnswerResponse: row.calendarAnswerResponse,
                  id: id || row.questionId,
                  selectedAnswers: row.selectedAnswers,
                  offset: {
                    days: row.calendarAnswerResponse?.daysOffset,
                    months: row.calendarAnswerResponse?.monthsOffset
                  },
                  isNotAnswered: row.isNotAnswered,
                  notAsweredText: t('na')
                })
              }
            >
              {t('results.tables.answer')}
            </SDKColumn>
            <SDKColumn field='openText' renderCell={renderCell}>
              {t('results.tables.note')}
            </SDKColumn>
            <SDKColumn field='url' renderCell={renderCell}>
              {t('results.tables.link')}
            </SDKColumn>
          </SDKTableSeparatedByLines>
        )}
      </div>
      {countryId && (projectYearId || yearId) && (
        <CountryContactInformation
          countryId={countryId}
          isVisible={Boolean(countryId)}
          setIsVisible={setCountryId}
          yearId={(projectYearId || yearId)!}
        />
      )}
    </>
  )
}
