import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Layout } from '../../../../components/Layout'
import { SDKButton } from '../../../../components/SDK/Button'
import { Url } from '../../../../constants/urls'

export const PricingToolImport = () => {
  const { t } = useTranslation('systemManagement')
  const navigate = useNavigate()
  const btnIcon = 'icon-download-light-outline'

  return (
    <Layout>
      <div className='reports-page ap-my-spacing-5'>
        <SDKButton
          onClick={() => navigate(`${location.pathname}${Url.AddPricingToolImport}`)}
          kind='secondary'
          className='ap-mt-spacing-5'
          icon={btnIcon}
        >
          {t('pricingToolImport.import')}
        </SDKButton>
      </div>
    </Layout>
  )
}
