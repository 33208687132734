import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DeleteModal } from '../../../../components/DeleteModal'
import { useAppDispatch } from '../../../../store'
import * as actions from '../../store/actions'
import * as selectors from '../../store/selectors'

interface IDeleteProjectModalProps {
  setProjectIdToDelete: (id: number | null) => void
  projectId: number
}
export const DeleteProjectModal = ({
  setProjectIdToDelete,
  projectId
}: IDeleteProjectModalProps) => {
  const { t } = useTranslation('myprojects')
  const dispatch = useAppDispatch()
  const { loading, error } = useSelector(selectors.deleteProject)

  const onClose = () => {
    setProjectIdToDelete(null)
    dispatch(actions.clearDeleteProject())
  }

  const onSubmit = () => {
    dispatch(actions.deleteProject({ projectId }))
  }

  const displayTextFromTranslation = (text: string) => t(`projectCard.deleteProject.${text}`)!

  return (
    <DeleteModal
      onSubmit={onSubmit}
      displayTextFromTranslation={displayTextFromTranslation}
      onModalClose={onClose}
      onSuccess={() => dispatch(actions.getMyProjects())}
      error={error}
      loading={loading}
    />
  )
}
