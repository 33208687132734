import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { SDKButton } from '../../../../../../components/SDK/Button'
import { SDKIcon } from '../../../../../../components/SDK/Icon'
import { useApp } from '../../../../../../context/app.context'
import { useSightlineVisibility } from '../../../../../../context/hooks/useSightlineVisibility'
import { useProject } from '../../../../../../context/project.context'
import { LoadingStatus } from '../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../store'
import { ESightlineRedirectAction, SIGHTLINE_ACTION } from '../../../../constants'
import * as actions from '../../../../store/actions'
import * as selectors from '../../../../store/selectors'

export const useSteps = (
  taraCountryIds: number[],
  projectId: number,
  setShowUploadModal: (data: boolean) => void,
  setShowUploadBySightlineModal: (data: boolean) => void
) => {
  const { t } = useTranslation('myprojects')
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { project } = useProject()
  const { loading: downloadTemplateLoading, error: downloadTemplateError } = useSelector(
    selectors.getExportTemplate
  )
  const { loading: uploadTemplateLoading, error: uploadTemplateError } = useSelector(
    selectors.getUploadTemplateToSightline
  )
  const [showLoadingModal, setShowLoadingModal] = useState(false)
  const [isSendingToSightline, setIsSendingToSightline] = useState(false)
  const { visible } = useSightlineVisibility()

  const loading = isSendingToSightline ? uploadTemplateLoading : downloadTemplateLoading
  const error = isSendingToSightline ? uploadTemplateError : downloadTemplateError
  const clearAction = isSendingToSightline
    ? actions.clearUploadTemplateToSightline
    : actions.clearExportTemplate

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded) {
      setShowLoadingModal(false)
      if (isSendingToSightline) {
        sendNotification({
          message: t('projectDetail.projectTabs.tara.input.sendingSuccessInfo'),
          status: 'success'
        })
      }
      dispatch(clearAction())
    }
  }, [loading])

  useEffect(() => {
    if (error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
      setShowLoadingModal(false)
      dispatch(clearAction())
    }
  }, [error])

  const downloadTemplate = () => {
    if (project?.taraCountryIds?.length) {
      setShowLoadingModal(true)
      setIsSendingToSightline(false)
      dispatch(actions.exportTemplate({ countryIds: taraCountryIds, projectId }))
    } else {
      sendNotification({
        message: t('projectDetail.projectTabs.tara.input.downloadTemplateError', {
          name: project?.name
        }),
        status: 'error'
      })
    }
  }

  const sendToSightline = () => {
    setShowLoadingModal(true)
    setIsSendingToSightline(true)
    if (projectId) {
      dispatch(
        actions.uploadTemplateToSightline({
          countryIds: taraCountryIds,
          projectId: Number(projectId)
        })
      )
    }
  }

  useEffect(() => {
    const action = localStorage.getItem(SIGHTLINE_ACTION)
    if (action === ESightlineRedirectAction.UploadToSightline) {
      sendToSightline()
      localStorage.removeItem(SIGHTLINE_ACTION)
    } else if (action === ESightlineRedirectAction.GetSightlineFilesList) {
      setShowUploadBySightlineModal(true)
      localStorage.removeItem(SIGHTLINE_ACTION)
    }
  }, [])

  const steps = [
    {
      buttons: (
        <div className='ap-mt-spacing-5'>
          <SDKButton onClick={downloadTemplate} fullWidth>
            <SDKIcon code='download-light' />
            {t(`projectDetail.projectTabs.tara.input.downloadButton`)}
          </SDKButton>
          {visible && project?.tpEngagementId && (
            <SDKButton
              onClick={sendToSightline}
              className='ap-mt-spacing-4'
              kind='secondary'
              fullWidth
            >
              <SDKIcon code='paper-airplane' />
              {t(`projectDetail.projectTabs.tara.input.sendButton`)}
            </SDKButton>
          )}
        </div>
      )
    },
    {
      buttons: (
        <div className='ap-mt-spacing-5'>
          <SDKButton onClick={() => setShowUploadModal(true)} fullWidth>
            <SDKIcon code='upload-light' />
            {t(`projectDetail.projectTabs.tara.input.uploadButton`)}
          </SDKButton>
          {visible && project?.tpEngagementId && (
            <SDKButton
              className='ap-mt-spacing-4'
              kind='secondary'
              onClick={() => setShowUploadBySightlineModal(true)}
              fullWidth
            >
              <SDKIcon code='data' />
              {t(`projectDetail.projectTabs.tara.input.useDataButton`)}
            </SDKButton>
          )}
        </div>
      )
    }
  ]

  return { steps, loading, isSendingToSightline, setShowLoadingModal, showLoadingModal }
}
