import moment from 'moment'

export const DEFAULT_FORMAT = 'LL'
export const DATE_ISO_FORMAT = 'YYYY-MM-DD'
export const DATE_ISO_FORMAT_WITH_TIME = 'YYYY-MM-DD [at] h:mma'
export const NEWSFEED_DATE_FORMAT = 'YYYY-M-DD [at] h:mma'
export const VERIFICATION_COUNTRY_CONTACT_DATE_FORMAT = 'MMMM DD, YYYY [at] h:mma'
export const GMT_TIME = 'GMT'

/**
 * Function that formats to local date string from utc. Returns empty string in case date string is not valid.
 * @param dateStr
 * @param format
 */
export function formatLocalFromUtc(date: moment.MomentInput, format?: string): string {
  if (!moment(date).isValid()) return ''
  return moment
    .utc(date)
    .local()
    .format(format ?? DEFAULT_FORMAT)
}

/**
 * Function that formats utc. Returns empty string in case date string is not valid.
 * @param dateStr
 * @param format
 */
export function formatUtc(date: moment.MomentInput, format?: string): string {
  if (!moment(date).isValid()) return ''
  return moment.utc(date).format(format ?? DEFAULT_FORMAT)
}

/**
 * Function that formats to utc string from local string. Returns empty string in case date string is not valid.
 * @param dateStr
 * @param format
 */
export function formatUtcFromLocal(date: moment.MomentInput, format?: string): string {
  if (!moment(date).isValid()) return ''
  return moment(date)
    .utc()
    .format(format ?? DEFAULT_FORMAT)
}
