import { useEffect } from 'react'
import type { UseFormReset } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Url } from '../../../../../../../constants/urls'
import { IFormValues } from '../../../../../../../schemas/useEditEmailTemplateSchema'
import { LoadingStatus } from '../../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../../store'
import { EDITING_EMAIL_TEMPLATE } from '../../../../../constants'
import * as actions from '../../../../../store/actions'
import { getUpdateEmailTemplate } from '../../../../../store/selectors'

export const useEditEmailTemplate = (reset: UseFormReset<IFormValues>) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const { loading, error } = useSelector(getUpdateEmailTemplate)

  const onSubmit = async (data: IFormValues) => {
    await dispatch(actions.updateEmailTemplate({ templateId: id!, params: data }))
  }

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded) {
      reset()
      navigate(`${Url.SystemManagementPage}${Url.EmailTemplatesPage}`)
      localStorage.removeItem(EDITING_EMAIL_TEMPLATE)
    }
  }, [loading])

  return { onSubmit, error }
}
