import { IPricingResults } from '../../../modules/project/components/GCDPricing/types/resultsTab'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const getPricingResultsData = async (projectId: number): Promise<IPricingResults> => {
  return (
    await api.get(
      `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/${ProjectsUrl.Results}`
    )
  ).data
}
