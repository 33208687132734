import type { IAddEditCountry, ICountry } from '../../../modules/countries/types'
import api from '../../api'
import { CountryUrl, FiscalYearUrl } from '../../constants/urls'

export const updateCountryData = async (
  yearId: number,
  countryId: number,
  params: IAddEditCountry
): Promise<ICountry> => {
  return (
    await api.put(`${FiscalYearUrl.Years}/${yearId}/${CountryUrl.Countries}/${countryId}`, params)
  ).data
}
