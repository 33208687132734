import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { useNavigate } from 'react-router-dom'
import { useApp } from '../../../../context/app.context'
import { useLogout } from '../../../../context/hooks/useLogout'
import { useMyRole } from '../../../../context/hooks/useMyRole'
import type { SDKItemDataType } from '../../../SDK/DropdownButton'
import { SDKDropdownButton } from '../../../SDK/DropdownButton'
import { SDKIcon } from '../../../SDK/Icon'
import UserAvatar from '../../../UserAvatar'

enum EMenuItems {
  Help,
  Logout
}

export const HeaderAvatar = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    appContext: { user }
  } = useApp()
  const { isAuthenticated } = useAuth()
  const { logout } = useLogout()
  const { amIExternal } = useMyRole()

  const helpUrl = 'https://pwceur.sharepoint.com/sites/GBL-TAX-globaltptechnology/TPE'

  const allMenuItems = {
    [EMenuItems.Help]: {
      label: t('header.help'),
      iconName: 'help-question',
      action: () => window.open(helpUrl, '_blank', 'noreferrer')
    },
    [EMenuItems.Logout]: {
      label: t('header.logout'),
      iconName: 'lockclosed-locked',
      action: () => logout()
    }
  }

  const menuItems = amIExternal
    ? [allMenuItems[EMenuItems.Logout]]
    : [allMenuItems[EMenuItems.Help], allMenuItems[EMenuItems.Logout]]

  if (!isAuthenticated) {
    return <></>
  }

  return (
    <SDKDropdownButton
      customTriggerNode={true}
      customTriggerClassName='custom-node'
      data={menuItems}
      prefixTemplate={item => <SDKIcon code={item.iconName} />}
      onSelect={(_value: number | string, item: SDKItemDataType, _: React.SyntheticEvent) => {
        if (item?.url) {
          navigate(item.url)
        }

        if (item?.action) {
          item.action()
        }
      }}
    >
      <UserAvatar user={user} disabled={false} />
    </SDKDropdownButton>
  )
}
