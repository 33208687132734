import type { NavigationItem } from '@appkit4/react-components/navigation'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Url } from '../../../../../constants/urls'
import { useApp } from '../../../../../context/app.context'
import { EFeatureFlag, useFeatureFlags } from '../../../../../hooks/useFeatureFlags'
import { UserRole } from '../../../../../types/user'

type TNavigationWithUrl = {
  url?: string
  isHidden?: boolean
  children?: (NavigationItem & { url?: string })[]
}

export const useNavigationsItems = () => {
  const {
    appContext: { user }
  } = useApp()
  const { isEnabled } = useFeatureFlags()
  const { t } = useTranslation()

  const isSearchEnabled = isEnabled(EFeatureFlag.Search)
  const navigationsItems: Array<NavigationItem & TNavigationWithUrl> = useMemo(
    () => [
      {
        name: t('nav.myprojects'),
        prefixIcon: 'folder-closed',
        url: Url.MyProjectsPage,
        itemClassName: location.pathname === Url.MyProjectsPage ? 'selected-item' : ''
      },
      {
        name: t('nav.questionnaire'),
        prefixIcon: 'survey-checkmark',
        url: Url.QuestionnairesPage,
        isHidden: !user?.roles.some(role =>
          [UserRole.Admin, UserRole.CountryEditor].includes(role)
        ),
        itemClassName: location.pathname === Url.QuestionnairesPage ? 'selected-item' : ''
      },
      {
        name: t('nav.fiscalYearSetup'),
        prefixIcon: 'toggle',
        url: Url.FiscalYearPage,
        isHidden: !user?.roles.some(role => [UserRole.Admin].includes(role)),
        itemClassName: location.pathname === Url.FiscalYearPage ? 'selected-item' : ''
      },
      {
        name: t('nav.systemManagement'),
        prefixIcon: 'library',
        url: Url.SystemManagementPage,
        isHidden: !user?.roles.some(role => [UserRole.Admin].includes(role)),
        itemClassName: location.pathname === Url.SystemManagementPage ? 'selected-item' : ''
      },
      {
        name: t('nav.updates'),
        prefixIcon: 'transform',
        url: Url.Updates,
        itemClassName: location.pathname === Url.Updates ? 'selected-item' : ''
      },
      {
        name: t('nav.search'),
        prefixIcon: 'search',
        url: Url.Search,
        isHidden: !isSearchEnabled,
        itemClassName: location.pathname === Url.Search ? 'selected-item' : ''
      }
    ],
    [location.pathname, user, isSearchEnabled]
  )

  return navigationsItems
}
