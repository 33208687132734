import { createReducer } from '@reduxjs/toolkit'
import { IDeleteFiscalYearPayload } from '../../../services/requests/fiscalYear/deleteFiscalYear'
import {
  getDefaultResourceState,
  Resource,
  setResourceFullfilled,
  setResourcePending,
  setResourceRejected
} from '../../../shared/utils/store'
import type {
  IGateQuestion,
  IGateQuestionPending
} from '../../fiscalYearDetails/pages/GateQuestions/types'
import type { IPowerBITemplate } from '../../powerBITemplates/types'
import type { FiscalYearBase, FiscalYearInfo } from '../types'
import {
  addPBITemplate,
  addYear,
  clearAddPBITemplate,
  clearAddYear,
  clearDeletePBITemplate,
  clearDeleteYear,
  clearGetGateQuestions,
  clearGetGateQuestionsAnswers,
  clearGetGateQuestionsExists,
  clearGetGateQuestionsPending,
  clearGetPBITemplates,
  clearGetPublishedYears,
  clearGetYears,
  clearPublishGateQuestionDraft,
  clearRejectGateQuestionDraft,
  clearUpdatePBITemplate,
  clearUpdateYear,
  deletePBITemplate,
  deleteYear,
  getGateQuestions,
  getGateQuestionsExists,
  getGateQuestionsPending,
  getPBITemplates,
  getPublishedYears,
  getYears,
  publishGateQuestionDraft,
  rejectGateQuestionDraft,
  updatePBITemplate,
  updateYear
} from './actions'

export interface FiscalYearState {
  addPBITemplate: Resource<IPowerBITemplate | null>
  addYear: Resource<FiscalYearInfo | null>
  deleteYear: Resource<IDeleteFiscalYearPayload | null>
  deletePBITemplate: Resource<null>
  gateQuestions: Resource<IGateQuestion[]>
  gateQuestionsExists: Resource<boolean | null>
  gateQuestionsAnswers: Resource<IGateQuestionPending[]>
  gateQuestionsPending: Resource<IGateQuestionPending | null>
  pBITemplates: Resource<IPowerBITemplate[]>
  publishGateQuestion: Resource<boolean | null>
  publishedYears: Resource<FiscalYearBase[]>
  rejectGateQuestion: Resource<boolean | null>
  updatePBITemplate: Resource<IPowerBITemplate | null>
  updateYear: Resource<FiscalYearInfo | null>
  years: Resource<FiscalYearInfo[]>
}

const initialState: FiscalYearState = {
  addPBITemplate: getDefaultResourceState(null),
  addYear: getDefaultResourceState(null),
  deleteYear: getDefaultResourceState(null),
  deletePBITemplate: getDefaultResourceState(null),
  gateQuestions: getDefaultResourceState([]),
  gateQuestionsExists: getDefaultResourceState(null),
  gateQuestionsAnswers: getDefaultResourceState([]),
  gateQuestionsPending: getDefaultResourceState(null),
  pBITemplates: getDefaultResourceState([]),
  publishGateQuestion: getDefaultResourceState(null),
  publishedYears: getDefaultResourceState([]),
  rejectGateQuestion: getDefaultResourceState(null),
  updatePBITemplate: getDefaultResourceState(null),
  updateYear: getDefaultResourceState(null),
  years: getDefaultResourceState([])
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getYears.pending, state => {
      setResourcePending(state.years)
    })
    .addCase(getYears.rejected, (state, actions) => {
      setResourceRejected(state.years, actions)
    })
    .addCase(getYears.fulfilled, (state, actions) => {
      state.years.data = actions.payload
      setResourceFullfilled(state.years)
    })
    .addCase(clearGetYears, state => {
      state.years = initialState.years
    })

    .addCase(getPublishedYears.pending, state => {
      setResourcePending(state.publishedYears)
    })
    .addCase(getPublishedYears.rejected, (state, actions) => {
      setResourceRejected(state.publishedYears, actions)
    })
    .addCase(getPublishedYears.fulfilled, (state, actions) => {
      state.publishedYears.data = actions.payload
      setResourceFullfilled(state.publishedYears)
    })
    .addCase(clearGetPublishedYears, state => {
      state.publishedYears = initialState.publishedYears
    })

    .addCase(addYear.pending, state => {
      setResourcePending(state.addYear)
    })
    .addCase(addYear.rejected, (state, actions) => {
      setResourceRejected(state.addYear, actions)
    })
    .addCase(addYear.fulfilled, (state, actions) => {
      setResourceFullfilled(state.addYear)
      state.addYear.data = actions.payload
    })
    .addCase(clearAddYear, state => {
      state.addYear = initialState.addYear
    })

    .addCase(updateYear.pending, state => {
      setResourcePending(state.updateYear)
    })
    .addCase(updateYear.rejected, (state, actions) => {
      setResourceRejected(state.updateYear, actions)
    })
    .addCase(updateYear.fulfilled, (state, actions) => {
      setResourceFullfilled(state.updateYear)
      state.updateYear.data = actions.payload
    })
    .addCase(clearUpdateYear, state => {
      state.updateYear = initialState.updateYear
    })

    .addCase(deleteYear.pending, state => {
      setResourcePending(state.deleteYear)
    })
    .addCase(deleteYear.rejected, (state, actions) => {
      setResourceRejected(state.deleteYear, actions)
    })
    .addCase(deleteYear.fulfilled, (state, actions) => {
      setResourceFullfilled(state.deleteYear)
      state.deleteYear.data = actions.payload
    })
    .addCase(clearDeleteYear, state => {
      state.deleteYear = initialState.deleteYear
    })

    .addCase(getPBITemplates.pending, state => {
      setResourcePending(state.pBITemplates)
    })
    .addCase(getPBITemplates.rejected, (state, actions) => {
      setResourceRejected(state.pBITemplates, actions)
    })
    .addCase(getPBITemplates.fulfilled, (state, actions) => {
      state.pBITemplates.data = actions.payload
      setResourceFullfilled(state.pBITemplates)
    })
    .addCase(clearGetPBITemplates, state => {
      state.pBITemplates = initialState.pBITemplates
    })

    .addCase(addPBITemplate.pending, state => {
      setResourcePending(state.addPBITemplate)
    })
    .addCase(addPBITemplate.rejected, (state, actions) => {
      setResourceRejected(state.addPBITemplate, actions)
    })
    .addCase(addPBITemplate.fulfilled, (state, actions) => {
      setResourceFullfilled(state.addPBITemplate)
      state.addPBITemplate.data = actions.payload
    })
    .addCase(clearAddPBITemplate, state => {
      state.addPBITemplate = initialState.addPBITemplate
    })

    .addCase(updatePBITemplate.pending, state => {
      setResourcePending(state.updatePBITemplate)
    })
    .addCase(updatePBITemplate.rejected, (state, actions) => {
      setResourceRejected(state.updatePBITemplate, actions)
    })
    .addCase(updatePBITemplate.fulfilled, (state, actions) => {
      setResourceFullfilled(state.updatePBITemplate)
      state.updatePBITemplate.data = actions.payload
    })
    .addCase(clearUpdatePBITemplate, state => {
      state.updatePBITemplate = initialState.updatePBITemplate
    })

    .addCase(deletePBITemplate.pending, state => {
      setResourcePending(state.deletePBITemplate)
    })
    .addCase(deletePBITemplate.rejected, (state, actions) => {
      setResourceRejected(state.deletePBITemplate, actions)
    })
    .addCase(deletePBITemplate.fulfilled, (state, actions) => {
      setResourceFullfilled(state.deletePBITemplate)
      state.deletePBITemplate.data = actions.payload
    })
    .addCase(clearDeletePBITemplate, state => {
      state.deletePBITemplate = initialState.deletePBITemplate
    })

    .addCase(getGateQuestions.pending, state => {
      setResourcePending(state.gateQuestions)
    })
    .addCase(getGateQuestions.rejected, (state, actions) => {
      setResourceRejected(state.gateQuestions, actions)
    })
    .addCase(getGateQuestions.fulfilled, (state, actions) => {
      state.gateQuestions.data = actions.payload
      setResourceFullfilled(state.gateQuestions)
    })
    .addCase(clearGetGateQuestions, state => {
      state.gateQuestions = initialState.gateQuestions
    })

    .addCase(getGateQuestionsExists.pending, state => {
      setResourcePending(state.gateQuestionsExists)
    })
    .addCase(getGateQuestionsExists.rejected, (state, actions) => {
      setResourceRejected(state.gateQuestionsExists, actions)
    })
    .addCase(getGateQuestionsExists.fulfilled, (state, actions) => {
      state.gateQuestionsExists.data = actions.payload
      setResourceFullfilled(state.gateQuestionsExists)
    })
    .addCase(clearGetGateQuestionsExists, state => {
      state.gateQuestionsExists = initialState.gateQuestionsExists
    })

    .addCase(getGateQuestionsPending.pending, state => {
      setResourcePending(state.gateQuestionsPending)
    })
    .addCase(getGateQuestionsPending.rejected, (state, actions) => {
      setResourceRejected(state.gateQuestionsPending, actions)
    })
    .addCase(getGateQuestionsPending.fulfilled, (state, actions) => {
      state.gateQuestionsPending.data = actions.payload

      const existingValueIndex = state.gateQuestionsAnswers.data.findIndex(
        e => e.questionId === actions.payload.questionId
      )
      if (~existingValueIndex) {
        const newArray = [...state.gateQuestionsAnswers.data]
        newArray[existingValueIndex] = actions.payload
        state.gateQuestionsAnswers.data = newArray
      } else {
        state.gateQuestionsAnswers.data = [...state.gateQuestionsAnswers.data, actions.payload]
      }
      setResourceFullfilled(state.gateQuestionsPending)
    })
    .addCase(clearGetGateQuestionsPending, state => {
      state.gateQuestionsPending = initialState.gateQuestionsPending
    })

    .addCase(clearGetGateQuestionsAnswers, state => {
      state.gateQuestionsAnswers = initialState.gateQuestionsAnswers
    })

    .addCase(publishGateQuestionDraft.pending, state => {
      setResourcePending(state.publishGateQuestion)
    })
    .addCase(publishGateQuestionDraft.rejected, (state, actions) => {
      setResourceRejected(state.publishGateQuestion, actions)
    })
    .addCase(publishGateQuestionDraft.fulfilled, (state, actions) => {
      state.publishGateQuestion.data = actions.payload
      setResourceFullfilled(state.publishGateQuestion)
    })
    .addCase(clearPublishGateQuestionDraft, state => {
      state.publishGateQuestion = initialState.publishGateQuestion
    })

    .addCase(rejectGateQuestionDraft.pending, state => {
      setResourcePending(state.rejectGateQuestion)
    })
    .addCase(rejectGateQuestionDraft.rejected, (state, actions) => {
      setResourceRejected(state.rejectGateQuestion, actions)
    })
    .addCase(rejectGateQuestionDraft.fulfilled, (state, actions) => {
      state.rejectGateQuestion.data = actions.payload
      setResourceFullfilled(state.rejectGateQuestion)
    })
    .addCase(clearRejectGateQuestionDraft, state => {
      state.rejectGateQuestion = initialState.rejectGateQuestion
    })
)
