import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Url } from '../../../../../../../constants/urls'
import { useSightlineVisibility } from '../../../../../../../context/hooks/useSightlineVisibility'
import { EWizardStep } from '../const'

export interface IActiveStep {
  code: EWizardStep
  index: number
  title: string
}

const getStepNumber = (code: EWizardStep, wizardSteps: EWizardStep[]) => {
  const key = wizardSteps.indexOf(code)
  return key > 0 ? key : 0
}

const getWizardStepUrl = (
  projectId: number | string | null,
  wizardSteps: EWizardStep[],
  step = 0
) => {
  const baseUrl = projectId ? `/${projectId}${Url.EditProjectPage}` : Url.AddProjectPage

  return `${Url.MyProjectsPage}${baseUrl}/${wizardSteps[step]}`
}

export const useWizardStepsKeys = () => {
  const { visible } = useSightlineVisibility()

  const wizardStepsKeys = useMemo(() => {
    let keys = [EWizardStep.ProjectInfo, EWizardStep.ProjectCountries]

    if (visible) {
      keys.push(EWizardStep.ProjectUsers)
    }

    return keys
  }, [visible])

  return wizardStepsKeys
}

export const useWizardSteps = (
  projectId: number | null
): {
  activeStep: IActiveStep
  steps: { code: string; title: string }[]
  updateActiveStep: (step: number) => void
  wizardSteps: EWizardStep[]
} => {
  const wizardStepsKeys = useWizardStepsKeys()
  const { t } = useTranslation('myprojects')
  const [activeStep, setActiveStep] = useState(0)
  const { step: stepFromUrl } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    setActiveStep(getStepNumber(stepFromUrl as EWizardStep, wizardStepsKeys))
  }, [stepFromUrl])

  const updateActiveStep = (newStep: number) => {
    if (newStep > -1 && newStep < wizardStepsKeys.length) {
      if (newStep != activeStep) {
        navigate(getWizardStepUrl(projectId, wizardStepsKeys, newStep))
        setActiveStep(newStep)
      }
    }

    if (newStep < 0) {
      setActiveStep(newStep)
    }
  }

  return {
    activeStep: {
      code: wizardStepsKeys[activeStep],
      index: activeStep,
      title: t(`projectForm.${wizardStepsKeys[activeStep]}.title`)
    },
    steps: wizardStepsKeys.map(code => ({ code, title: t(`projectForm.${code}.title`) })),
    updateActiveStep,
    wizardSteps: wizardStepsKeys
  }
}
