import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ActionButton } from '../../components/ActionButton'
import { EmptyState } from '../../components/EmptyState'
import { InfoBox } from '../../components/InfoBox'
import { Layout } from '../../components/Layout'
import { SDKList } from '../../components/SDK/List'
import { SDKPagination } from '../../components/SDK/Pagination'
import { SDKSearch } from '../../components/SDK/Search'
import { SDKText } from '../../components/SDK/Text'
import { usePagination } from '../../shared/utils/usePagination'
import { Newsfeed } from './components/Newsfeed'
import styles from './index.module.scss'
import { getNewsfeed } from './store/selectors'
import { ESortType } from './types'
import { useUpdates } from './utils/useUpdates'

export const Updates = () => {
  const { t } = useTranslation('updates')
  const { data: newsfeed } = useSelector(getNewsfeed)
  const { currentPage, onPageChange, pagesAmount, offset } = usePagination(newsfeed?.totalCount)
  const { mappedNewsfeed, onChange, setSort, sort, debouncedSearchValue } = useUpdates(
    currentPage,
    offset,
    newsfeed?.newsfeedEntries
  )

  return (
    <Layout>
      <div className='ap-grid mx-0 ap-flex align-items-center ap-mb-spacing-8'>
        <SDKSearch
          className='g-col-md-4 g-col-12 p-0'
          onChange={onChange}
          placeholder={t('newsfeed.serchText')!}
        />
        <div
          className='ap-flex justify-content-end g-col-12 g-col-md-8'
          onClick={() => setSort(sort === ESortType.Desc ? ESortType.Asc : ESortType.Desc)}
        >
          <SDKText>{t('newsfeed.sortText')!}</SDKText>
          <ActionButton
            icon={sort === ESortType.Desc ? 'up-chevron' : 'down-chevron'}
            kind='neutral'
            content=''
            handleClick={() => setSort(sort === ESortType.Desc ? ESortType.Asc : ESortType.Desc)}
          />
        </div>
      </div>

      {mappedNewsfeed && mappedNewsfeed.length === 0 ? (
        <EmptyState />
      ) : (
        <>
          <SDKList
            itemKey='id'
            bordered={true}
            data={mappedNewsfeed!}
            renderItem={(item, index) => <Newsfeed index={index} {...item} />}
            className={styles['newsfeed-list']}
          />
          <SDKPagination
            className='ap-mt-spacing-5'
            total={pagesAmount}
            current={currentPage}
            onPageChange={onPageChange}
            resetPageNumber={debouncedSearchValue.length > 0}
          />
        </>
      )}
      <InfoBox
        className='border-1 ap-border-c olor-background-border'
        content={t('newsfeed.infoText')}
      />
    </Layout>
  )
}
