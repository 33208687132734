import { EPricingTab } from '../../../modules/project/components/GCDPricing/const/enums'
import { ILocalFile } from '../../../modules/project/components/GCDPricing/types/inputsTab'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const getLocalFileData = async (projectId: number): Promise<ILocalFile> => {
  return (
    await api.get(
      `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/${ProjectsUrl.Modules}/${EPricingTab.LocalFile}`
    )
  ).data
}
