import { useTranslation } from 'react-i18next'
import { ReactComponent as Icon } from '../../../../../../assets/svg/surveyAndAssessment.svg'
import { SDKText } from '../../../../../../components/SDK/Text'
import './index.scss'

const EmptyStateInQuestions = () => {
  const { t } = useTranslation('questionnaires')
  return (
    <div className='ap-bg-color-background-alt ap-border-radius-2 ap-flex flex-column align-items-center ap-p-spacing-5 empty-state-in-questions'>
      <SDKText className='text ap-pb-spacing-7'>{t('emptyState')}</SDKText>
      <Icon />
    </div>
  )
}

export default EmptyStateInQuestions
