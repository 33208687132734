import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Layout } from '../../components/Layout'
import { SDKTabber } from '../../components/SDK/Tabber'
import { Url } from '../../constants/urls'
import { useAppDispatch } from '../../store'
import { EmptyState } from '../fiscalYearDetails/pages/GateQuestions/components/EmptyState'
import * as yearActions from '../fiscalYearSetup/store/actions'
import { getYearByName } from '../fiscalYearSetup/store/selectors'
import { AddEditCategoryModal } from './components/AddEditCategoryModal'
import { CategoriesList } from './components/CategoriesList'
import { Header } from './components/Header'
import { QuestionsContainer } from './components/QuestionsContainer'
import { Tab } from './constants/tab'
import * as actions from './store/actions'

const CountriesSnapshot = () => {
  const dispatch = useAppDispatch()
  const [activeIndex, setActiveIndex] = useState(0)
  const [visibleCategoryModal, setVisibleCategoryModal] = useState(false)
  const [categoryId, setCategoryId] = useState<number | null>(null)
  const navigation = useNavigate()
  const { t } = useTranslation('countriesSnapshot')
  const { year: currentYear } = useParams()
  const year = useSelector(getYearByName(currentYear!))

  useEffect(() => {
    if (!year) {
      dispatch(yearActions.getYears())
    }
  }, [])

  const tabs = [
    {
      label: t('categories'),
      value: Tab.Categories,
      hashUrl: 'categories'
    },
    {
      label: t('questions'),
      value: Tab.Questions,
      hashUrl: 'questions'
    }
  ]

  const openAddEditQuestionModal = (questionId: number | null) => {
    dispatch(actions.clearAddQuestion())
    dispatch(actions.clearUpdateQuestion())
    if (questionId) {
      navigation(`${location.pathname}${Url.EditQuestionPage}/${questionId}`)
    } else {
      navigation(`${location.pathname}${Url.AddQuestionPage}`)
    }
  }

  const openAddEditCategoryModal = (catId: number | null) => {
    dispatch(actions.clearAddCategory())
    dispatch(actions.clearUpdateCategory())
    setCategoryId(catId)
    setVisibleCategoryModal(true)
  }

  return (
    <Layout>
      <div className='countries-snapshot-page ap-my-spacing-5'>
        <SDKTabber tabs={tabs} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
        <Header
          activeIndex={activeIndex}
          openAddCategoryModal={() => openAddEditCategoryModal(null)}
          openAddQuestionModal={() => openAddEditQuestionModal(null)}
        />
        {!year && <EmptyState />}
        {activeIndex === Tab.Categories && year && (
          <CategoriesList year={year.yearId} openEditCategoryModal={openAddEditCategoryModal} />
        )}
        {activeIndex === Tab.Questions && year && (
          <QuestionsContainer yearId={year.yearId} openEditQuestion={openAddEditQuestionModal} />
        )}
      </div>
      {visibleCategoryModal && (
        <AddEditCategoryModal
          visible={visibleCategoryModal}
          setVisible={setVisibleCategoryModal}
          yearId={year?.yearId}
          categoryId={categoryId}
        />
      )}
    </Layout>
  )
}

export default CountriesSnapshot
