import classnames from 'classnames'

export type TTextType =
  | 'body-xs'
  | 'body-s'
  | 'body'
  | 'subheading'
  | 'heading'
  | 'large-heading'
  | 'data'
export interface ISDKText {
  children: string | JSX.Element | null
  type?: TTextType
  weight?: 1 | 2 | 3
  className?: string
}

export const SDKText = ({ children, type = 'body', weight = 1, className = '' }: ISDKText) => {
  if (!children) {
    return null
  }
  return (
    <span
      className={classnames([`ap-typography-${type}`, `ap-font-weight-${weight}`, `${className}`])}
    >
      {children}
    </span>
  )
}
