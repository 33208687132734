import { t } from 'i18next'
import {
  ECalculationType,
  TCalculationType
} from '../../../../modules/project/types/projectCalculation'

const ERROR = { icon: 'warning', className: 'ap-text-states-error-05' }
const SUCCESS = { icon: 'circle-checkmark', className: 'ap-text-states-success-06' }
const PENDING = { icon: 'cogs', className: 'ap-text-states-warning-07' }
const CANCELLED = { icon: 'warning', className: 'ap-text-states-error-05' }

export const getHeader = (type: TCalculationType) => {
  switch (type) {
    case ECalculationType.Success:
      return { name: t('status.success'), ...SUCCESS }
    case ECalculationType.Failed:
      return { name: t('status.error'), ...ERROR }
    case ECalculationType.InProgress:
      return { name: t('status.pending'), ...PENDING }
    case ECalculationType.Canceled:
      return { name: t('status.cancelled'), ...CANCELLED }
    default:
      return { name: t('status.pending'), ...PENDING }
  }
}
