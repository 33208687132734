import { useTranslation } from 'react-i18next'
import { useApp } from '../../context/app.context'
import { UserRole } from '../../types/user'
import { SDKButton } from '../SDK/Button'
import { SDKIcon } from '../SDK/Icon'
import './index.scss'

interface IHeaderButtons {
  name: string
  icon: string
  onClick: () => void
  btnAllowedRoles?: UserRole[]
  withFilter?: boolean
  hasFiltersOn?: boolean
  showFilter?: () => void
  disabled?: boolean
  additionalButtonOnClick?: () => void
  withAdditionalButton?: boolean
  additionalButtonIcon?: string
  additionalButtonName?: string
}

export const HeaderButtons = ({
  name,
  icon,
  onClick,
  withFilter,
  hasFiltersOn,
  btnAllowedRoles,
  disabled,
  withAdditionalButton,
  additionalButtonIcon,
  additionalButtonName,
  showFilter,
  additionalButtonOnClick
}: IHeaderButtons) => {
  const { t } = useTranslation()
  const {
    appContext: { user }
  } = useApp()

  const hasPermission = btnAllowedRoles
    ? user?.roles.some(role => btnAllowedRoles.includes(role))
    : true

  return (
    <div className='page-header'>
      {withAdditionalButton && (
        <SDKButton kind='secondary' className='ap-mr-spacing-4' onClick={additionalButtonOnClick}>
          {additionalButtonIcon && <SDKIcon code={additionalButtonIcon}></SDKIcon>}
          {additionalButtonName}
        </SDKButton>
      )}
      {withFilter && (
        <SDKButton kind='secondary' className='ap-mr-spacing-4' onClick={showFilter}>
          <SDKIcon code='filter' withDot={hasFiltersOn}></SDKIcon> {t('buttons.filter')}
        </SDKButton>
      )}
      {hasPermission && (
        <SDKButton onClick={onClick} className='header-btn' disabled={disabled}>
          <SDKIcon code={icon}></SDKIcon>
          {name}
        </SDKButton>
      )}
    </div>
  )
}
