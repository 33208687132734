import type { IUserBase } from '../../../types/user'
import { getUrlQueries } from '../../../utils/getUrlQueries'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const getProjectAvailableUsersData = async (
  projectId: number | null,
  searchText: string
): Promise<IUserBase[]> => {
  const query = getUrlQueries({ searchText })

  const url = `${ProjectsUrl.Projects}/${projectId ? `${projectId}/` : ''}${ProjectsUrl.Users}/${
    ProjectsUrl.AllAvailable
  }`

  return (await api.get(`${url}${query}`)).data
}
