import { TSubcategory } from '../../../modules/countriesSnapshot/types'
import api from '../../api'
import { CountrySnapshotUrl, FiscalYearUrl } from '../../constants/urls'

export interface IUpdateSubcategoryPayload {
  name: string
}

export const updateSingleSubcategory = async (
  params: IUpdateSubcategoryPayload,
  yearId: number,
  categoryId: number,
  subcategoryId: number
): Promise<TSubcategory> => {
  return (
    await api.put(
      `${FiscalYearUrl.Years}/${yearId}/${CountrySnapshotUrl.Categories}/${categoryId}/${CountrySnapshotUrl.Subcategories}/${subcategoryId}`,
      params
    )
  ).data
}
