import { useTranslation } from 'react-i18next'
import { EmptyState } from '../../../../components/EmptyState'
import { HeaderButtons } from '../../../../components/HeaderButtons'
import { Layout } from '../../../../components/Layout'
import { LoadingSpinner } from '../../../../components/SDK/LoadingSpinner'
import { LoadingStatus } from '../../../../shared/types/enums'
import { LegalStatementDocumentsList } from './components/LegalStatementDocumentsList'
import { useLegalDocuments } from './utils/useLegalDocuments'

export const LegalDocuments = () => {
  const { t } = useTranslation('systemManagement')
  const { loading, data, onAddDocument } = useLegalDocuments()

  return (
    <Layout
      buttonComponent={
        <HeaderButtons icon='plus' name={t('legalDocuments.newDocument')} onClick={onAddDocument} />
      }
    >
      <div className='legal-statement-documents-page ap-my-spacing-5'>
        {loading === LoadingStatus.Pending ? (
          <LoadingSpinner />
        ) : data.length ? (
          <LegalStatementDocumentsList />
        ) : (
          <EmptyState />
        )}
      </div>
    </Layout>
  )
}
