import { useTranslation } from 'react-i18next'
import { SDKText } from '../../../SDK/Text'
import './index.scss'

interface IAdditionalInformationContainerProps {
  openText: string
  url: string
  classes?: string
  type?: 'body-s' | 'body-xs' | 'body' | 'subheading' | 'heading' | 'large-heading' | 'data'
}

export const AdditionalInformationContainer = ({
  openText,
  url,
  classes = '',
  type = 'body-s'
}: IAdditionalInformationContainerProps) => {
  const { t } = useTranslation('myprojects')
  return (
    <>
      {(!!openText || !!url) && (
        <div className={`additional-information-container ${classes}`}>
          {!!openText && (
            <SDKText type={type} className='ap-text-neutral-14'>
              <>
                {t('results.tables.note')}: {openText}
              </>
            </SDKText>
          )}
          {!!url && (
            <SDKText type={type} className='ap-text-neutral-14 url'>
              <>
                {t('results.tables.link')}: {url}
              </>
            </SDKText>
          )}
        </div>
      )}
    </>
  )
}
