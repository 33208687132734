import { useTranslation } from 'react-i18next'
import { HeaderButtons } from '../../../../components/HeaderButtons'

interface IProjectHeaderProps {
  onButtonClick: () => void
}

const PBITHeader = ({ onButtonClick }: IProjectHeaderProps) => {
  const { t } = useTranslation('documents')

  return (
    <HeaderButtons
      withFilter={false}
      onClick={() => onButtonClick()}
      name={t('powerBITemplates.newTemplateButton.label')!}
      icon='plus'
    />
  )
}

export default PBITHeader
