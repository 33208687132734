import { RegionContainer } from '../types/enums'

export const addDefaultSection = <T>(sections: [string, T[]][]) => {
  if (sections.length < 3) {
    const regionContainers = [
      RegionContainer.Tara,
      RegionContainer.GcdPricing,
      RegionContainer.BenchmarkAndIndustryAnalysis
    ]
    const tempSections = [...sections]
    regionContainers.forEach(
      region => !tempSections.some(item => item[0] === region) && tempSections.push([region, []])
    )
    return tempSections
  } else {
    return sections
  }
}
