import type { ItemDataType } from '@appkit4/react-components'
import type { DropdownButtonProps, ValueType } from '@appkit4/react-components/dropdown'
import { DropdownButton } from '@appkit4/react-components/dropdown'

export type SDKItemDataType = ItemDataType
export type SDKValueType = ValueType

export const SDKDropdownButton = (props: DropdownButtonProps) => {
  return <DropdownButton {...props} />
}
