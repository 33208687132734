import { RootState } from '../../../store'

export const getProjectQuestions = (state: RootState) => state.Project.projectQuestions
export const getUpdateProjectQuestions = (state: RootState) => state.Project.updateProjectQuestions
export const getProjectUsers = (state: RootState) => state.Project.projectUsers
export const getProjectResults = (state: RootState) => state.Project.projectResults
export const getExportProject = (state: RootState) => state.Project.exportProject
export const getExportTemplate = (state: RootState) => state.Project.exportTemplate
export const getUploadTemplateWithCalculation = (state: RootState) =>
  state.Project.uploadTemplateWithCalculation
export const uploadEntitiesFromTemplate = (state: RootState) =>
  state.Project.uploadEntitiesFromTemplate
export const getTemplateCalculationById = (state: RootState) =>
  state.Project.templateCalculationById
export const getUploadTemplateToSightline = (state: RootState) =>
  state.Project.uploadTemplateToSightline
export const updateCustomCountries = (state: RootState) => state.Project.updateCustomCountries
export const getCalculationResult = (state: RootState) => state.Project.calculationResult
export const getUploadBySightlineWithCalculation = (state: RootState) =>
  state.Project.uploadBySightlineWithCalculation
export const getSightlineFiles = (state: RootState) => state.Project.sightlineFilesList
export const getCalculationResultFromSightline = (state: RootState) =>
  state.Project.calculationResultFromSightline
export const getPBICalculationResultByTemplateId = (state: RootState) =>
  state.Project.powerBICalculationResultByTemplateId
export const getPBICalculationResultByTemplateIdFromSightline = (state: RootState) =>
  state.Project.powerBICalculationResultByTemplateIdFromSightline
export const exportProjectDataFromSightlineCS = (state: RootState) =>
  state.Project.exportProjectFromSightlineCS
export const createExportProject = (state: RootState) => state.Project.createExportProject
export const createExportProjectToSightline = (state: RootState) =>
  state.Project.createExportProjectToSightline
export const updateCalendar = (state: RootState) => state.Project.updateCalendar
