import { IProject } from '../../../modules/myprojects/types'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export interface ICustomCountriesPayload {
  type: number
  selectedCountryIds: number[]
}

export const updateCustomCountriesRequest = async (
  params: ICustomCountriesPayload,
  projectId: number
): Promise<IProject> => {
  return (
    await api.put(`${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.CustomCountries}`, params)
  ).data
}
