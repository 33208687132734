import classNames from 'classnames'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Url } from '../../../../constants/urls'
import { useApp } from '../../../../context/app.context'
import { kebabCase2pascalCase } from '../../../../formatters/kebabCase2pascalCase'
import { SDKBreadcrumb } from '../../../SDK/Breadcrumb'
import { SDKBreadcrumbItem } from '../../../SDK/BreadcrumbItem'
import { SDKIcon } from '../../../SDK/Icon'
import { SDKText } from '../../../SDK/Text'
import { useNavigationsItems } from '../AppNavigation/hooks/useNavigationsItems'
import './index.scss'

interface IBreadcrumbProps {
  breadcrumbsReplace?: { [x: string]: string }
  buttonComponent?: React.ReactNode
  mobileHeaderComponent?: React.ReactNode
  title?: string
  tab?: string
  onClick?: () => void
  blockBackNavigate?: boolean
  hideBreadcrumb?: boolean
}

export const Breadcrumb = ({
  blockBackNavigate,
  breadcrumbsReplace,
  buttonComponent,
  hideBreadcrumb,
  mobileHeaderComponent,
  onClick,
  tab = '',
  title
}: IBreadcrumbProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const routeParams = useParams()
  const params = Object.entries(routeParams)
  const { t, i18n } = useTranslation()
  const navigationsItems = useNavigationsItems()
  const {
    appContext: {
      style: { isMobile }
    }
  } = useApp()
  let breadcrumbItems = location.pathname.split('/').filter(Boolean)
  let pageTitle = null

  if ('id' in routeParams) {
    breadcrumbItems = breadcrumbItems.filter(i => i !== routeParams.id)
  }

  if ('step' in routeParams) {
    breadcrumbItems = breadcrumbItems.filter(i => i !== routeParams.step)
  }

  let namedBreadcrumbs: string[] = []
  let shouldSkip = false
  breadcrumbItems.forEach((breadcrumb, index) => {
    if (shouldSkip) {
      return
    }

    const isLast = breadcrumbItems.length - 1 === index
    const shouldDisplayTitle = !!(isLast && title)
    const codeFromUrl = kebabCase2pascalCase(breadcrumb, 'lower')

    if (isLast && i18n.exists(`breadcrumbsTitle.${codeFromUrl}`) && !shouldDisplayTitle) {
      pageTitle = `${t(`breadcrumbsTitle.${codeFromUrl}`)}`
    }

    if (shouldDisplayTitle) {
      // a last item with the specified title
      // e.g.:
      // title: "This is a specific title with some ${data}"
      // displays:
      // "This is a specific title with some ${data}"
      namedBreadcrumbs.push(title)
    } else {
      const breadcrumbParameter = params.find(param => param[1] === breadcrumb)

      if (breadcrumbParameter && breadcrumbsReplace) {
        // a specified item in the breadcrumbs value
        // e.g.:
        // breadcrumbs={{ project: project.name }}
        // displays:
        // project.name
        // note:
        // if project.name doesn't exists three dots are displayed

        if (breadcrumbsReplace[breadcrumbParameter[0]]) {
          namedBreadcrumbs.push(breadcrumbsReplace[breadcrumbParameter[0]])
        } else {
          namedBreadcrumbs.push(t('breadcrumbsPreloading'))
          shouldSkip = true
        }
      } else if (i18n.exists(`breadcrumbs.${codeFromUrl}`)) {
        // a specified item in translations
        // e.g.:
        // fiscal-year
        // displays:
        // Fiscal Year

        namedBreadcrumbs.push(t(`breadcrumbs.${codeFromUrl}`))
      } else {
        // a specified item displayed with the translation if exists
        // e.g.:
        // 2023
        // displays:
        // Fiscal Year 2023

        const keyWithTranslation = params.find(param => param[1] === breadcrumb)?.[0]

        namedBreadcrumbs.push(
          (keyWithTranslation &&
            i18n.exists(`breadcrumbs.${keyWithTranslation}`) &&
            `${t(`breadcrumbs.${keyWithTranslation}`)} `) + codeFromUrl
        )
      }
    }
  })

  const goToPreviousPage = () => {
    if (!blockBackNavigate) {
      navigate(`/${breadcrumbItems.slice(0, breadcrumbItems.length - 1).join('/')}${tab}`)
    }
    onClick?.()
  }

  const navigateToPage = (index: number) => {
    const url = `/${breadcrumbItems.slice(0, index + 1).join('/')}`
    if (index === breadcrumbItems.length - 1) {
      return
    } else if (index === breadcrumbItems.length - 2) {
      navigate(`${url}${tab}`)
    } else {
      navigate(url)
    }

    onClick?.()
  }

  const shouldHideBackButton = useMemo(
    () =>
      breadcrumbItems.length === 1 && breadcrumbItems[0] === Url.MyProjectsPage.replaceAll('/', ''),
    [location]
  )

  const getParentIcon = (breadcrumb: string) => {
    let icon = 'toggle'
    navigationsItems.forEach(navItem => {
      if (navItem.name === breadcrumb && navItem.prefixIcon) {
        icon = navItem.prefixIcon
      }
    })

    return icon
  }
  if (hideBreadcrumb) {
    return (
      <div className='ap-flex justify-content-end'>
        {isMobile ? mobileHeaderComponent : buttonComponent}
      </div>
    )
  }

  return (
    <div className='breadcrumb'>
      <div className='breadcrumb-mobile'>
        <div
          onClick={goToPreviousPage}
          className={classNames('pointer', shouldHideBackButton && 'not-visible')}
        >
          <SDKText>
            <SDKIcon code='left-chevron'></SDKIcon>
          </SDKText>
        </div>

        <SDKText type='subheading' weight={2}>
          {pageTitle || namedBreadcrumbs[namedBreadcrumbs.length - 1]}
        </SDKText>
        <div>{mobileHeaderComponent}</div>
      </div>
      <div className='button-component-mobile'> {buttonComponent}</div>
      <div className='breadcrumb-desktop ap-pb-spacing-7'>
        <SDKBreadcrumb>
          {namedBreadcrumbs.map((item, index) => (
            <SDKBreadcrumbItem key={`${item}-${index}`}>
              <div className='navigation' onClick={() => navigateToPage(index)}>
                {index === 0 && <SDKIcon code={getParentIcon(item)}></SDKIcon>}
                <SDKText weight={index !== namedBreadcrumbs.length - 1 ? 2 : 1}>{item}</SDKText>
              </div>
            </SDKBreadcrumbItem>
          ))}
        </SDKBreadcrumb>
        <div className='breadcrumb-desktop-title ap-mt-spacing-4'>
          <SDKText type='large-heading' weight={2}>
            {pageTitle || namedBreadcrumbs[namedBreadcrumbs.length - 1]}
          </SDKText>
          {buttonComponent}
        </div>
      </div>
    </div>
  )
}
