import type { SearchProps } from '@appkit4/react-components/esm/search'
import { Search } from '@appkit4/react-components/esm/search'

export const SDKSearch = (props: SearchProps) => {
  return (
    <Search
      className={props.className || 'g-col-12 p-0 mb-2'}
      searchType={'secondary'}
      {...props}
    />
  )
}
