import { Combobox } from '@appkit4/react-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FormCard } from '../../../../components/Form/FormCard'
import { FormInput } from '../../../../components/Form/FormInput'
import { FormSelect } from '../../../../components/Form/FormSelect'
import { FormSwitch } from '../../../../components/Form/FormSwitch'
import { FormTextArea } from '../../../../components/Form/FormTextArea'
import { RHF_SET_VALUE_OPTIONS } from '../../../../components/Form/utils'
import { HeaderButtons } from '../../../../components/HeaderButtons'
import { Layout } from '../../../../components/Layout'
import { SDKButton } from '../../../../components/SDK/Button'
import { SDKIcon } from '../../../../components/SDK/Icon'
import { SDKModal } from '../../../../components/SDK/Modal'
import { SDKText } from '../../../../components/SDK/Text'
import { SDKTooltip } from '../../../../components/SDK/Tooltip'
import { useApp } from '../../../../context/app.context'
import { useYearFromUrl } from '../../../../hooks/useYearFromUrl'
import {
  EAddEditCountryFieldOverrideFields,
  IFormValues,
  useAddEditCountryFieldOverrideSchema
} from '../../../../schemas/useAddEditCountryFieldOverrideSchema'
import { useAppDispatch } from '../../../../store'
import * as countryActions from '../../../countries/store/actions'
import { getCountries } from '../../../countries/store/selectors'
import * as actions from '../../store/actions'
import * as selectors from '../../store/selectors'
import type { IFormulaCountry, IFormulaValidation, IFormulaValidationVariable } from '../../types'
import { ValidationResult } from './component/ValidationResult'
import './index.scss'
import { useAddEditCountryFieldOverride } from './utils/useAddEditCountryFieldOverride'

export const AddEditCountryFieldOverride = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('taraFields')
  const { year } = useYearFromUrl()
  const { sendNotification } = useApp()
  const [field, setField] = useState<IFormulaCountry | null>(null)
  const [countryId, setCountryId] = useState<number | null>(null)
  const [parseError, setParseError] = useState<string | null>(null)
  const [showModal, setShowModal] = useState(false)
  const { year: currentYearName, id } = useParams()
  const { data: countries } = useSelector(getCountries)
  const { data: formulaOverrides } = useSelector(selectors.getFormulaOverrides)
  const { data: fieldsByCountry } = useSelector(selectors.getFormulaOverrideByCountry)
  const { data: gateQuestions } = useSelector(selectors.getGateQuestionsByCountry)
  const { error } = useSelector(selectors.addFormulaValidation)

  const isEditMode = !!id

  useEffect(() => {
    return () => {
      dispatch(actions.clearGetFormulaOverrides())
      dispatch(actions.clearUpdateFormulaOverride())
      dispatch(actions.clearAddFormulaOverride())
      dispatch(actions.clearUpdateFormulaValidation())
    }
  }, [])

  useEffect(() => {
    const shouldGetFormulas =
      (!formulaOverrides ||
        (formulaOverrides && !Object.keys(formulaOverrides).length) ||
        (id && !formulaOverrides)) &&
      year

    if (shouldGetFormulas) {
      dispatch(actions.getFormulaOverrides(year.yearId))
    }

    if (year?.yearId) {
      dispatch(countryActions.getCountries(year.yearId))
    }
  }, [year])

  useEffect(() => {
    if (year?.yearId && countryId) {
      dispatch(actions.getGateQuestionsByCountry({ yearId: year.yearId, countryId }))
    }
  }, [year, countryId])

  const { validationSchema, defaultValues } = useAddEditCountryFieldOverrideSchema(field)
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { isValid, isDirty }
  } = useForm<FieldValues, IFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange'
  })
  const { onSubmit, onClose } = useAddEditCountryFieldOverride(
    reset,
    currentYearName || '',
    countryId,
    Number(id)
  )
  const country = useWatch({
    control,
    name: EAddEditCountryFieldOverrideFields.country
  })

  const fieldName = useWatch({
    control,
    name: EAddEditCountryFieldOverrideFields.fieldName
  })

  useEffect(() => {
    if (formulaOverrides === null) {
      return
    }

    const values = Object.values(formulaOverrides).flat()

    if (isEditMode && values.length) {
      const editedValue = values.find(v => v?.countryFormulaId === Number(id))
      const currentCountryId = countries.find(c => c.name === editedValue?.countryName)?.countryId
      setCountryId(currentCountryId || editedValue?.countryId || null)
      setField(editedValue || null)
    }
  }, [formulaOverrides, id])

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues])

  useEffect(() => {
    if (formulaOverrides && formulaOverrides[fieldName]) {
      const currentField = formulaOverrides[fieldName].find(f => f.countryFormulaId === country)

      if (currentField) {
        setField(currentField || null)
      }
    }
  }, [fieldName, country])

  useEffect(() => {
    if (!isEditMode) {
      const active = fieldsByCountry.find(c => c.taraFieldName === fieldName)

      if (active) {
        setField({
          ...active,
          countryName: country,
          isActive: false,
          gateQuestion: getValues(EAddEditCountryFieldOverrideFields.gateQuestion)
        })
      } else {
        setField(null)
        setValue(EAddEditCountryFieldOverrideFields.country, country)
      }
    }
  }, [fieldName])

  useEffect(() => {
    if (error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
      dispatch(actions.clearUpdateFormulaValidation())
    }
  }, [error])

  useEffect(() => {
    const currentCountryId = countries.find(c => c.name === country)?.countryId
    if (currentCountryId && !isEditMode) {
      dispatch(actions.getFormulaOverrideByCountry(currentCountryId))
      setCountryId(currentCountryId)

      setField(null)
      setValue(EAddEditCountryFieldOverrideFields.isActive, false)
      setValue(EAddEditCountryFieldOverrideFields.fieldName, null)
      setValue(EAddEditCountryFieldOverrideFields.gateQuestion, null)
    }
  }, [country])

  const unquoteStrings = (input: string) => input.replace(/"([^"]+)"/g, '$1')

  const convertDataToVariablesFormat = (inputData: string) => {
    let cleaned = inputData.trim().substring(1, inputData.length - 1)
    let keyValuePair = cleaned.split(',')
    const variables: IFormulaValidationVariable[] = []
    let split
    for (let i = 0, l = keyValuePair.length; i < l; i++) {
      split = keyValuePair[i].split(':')
      const key = unquoteStrings(split[0].trim())
      const value = unquoteStrings(split[1].trim())

      variables.push({
        name: key,
        value: value
      })
    }
    return variables
  }

  const validate = () => {
    const inputData = getValues(EAddEditCountryFieldOverrideFields.inputData)
    try {
      const variables: IFormulaValidationVariable[] = convertDataToVariablesFormat(inputData.trim())
      const params: IFormulaValidation = {
        formula: getValues(EAddEditCountryFieldOverrideFields.newLogic),
        variables
      }
      setParseError(null)
      dispatch(actions.updateFormulaValidation({ params }))
    } catch {
      dispatch(actions.clearUpdateFormulaValidation())
      setParseError(t('addEditForm.overrideLogicError')!)
    }
  }

  return (
    <Layout
      buttonComponent={
        <HeaderButtons
          name={t('addEditForm.save')}
          additionalButtonName={t('addEditForm.cancel')!}
          additionalButtonOnClick={onClose}
          icon='save'
          onClick={() => setShowModal(true)}
          disabled={id ? !isValid || !isDirty : !isValid}
          withAdditionalButton
        />
      }
    >
      <div className='ap-mt-spacing-5'>
        <FormCard
          title={t('addEditForm.overrideSettings')!}
          tooltipText={t('addEditForm.overrideSettingsTooltip')!}
        >
          {!isEditMode ? (
            <Combobox
              className='ap-mb-spacing-5'
              data={countries.map(c => ({ label: c.name, value: c.name }))}
              defaultValue={country}
              disabled={isEditMode}
              labelKey={'label'}
              onSelect={val => {
                setValue(EAddEditCountryFieldOverrideFields.country, val, RHF_SET_VALUE_OPTIONS)
              }}
              placeholder={t('addEditForm.country')!}
              required={true}
              value={country}
              valueKey={'value'}
            />
          ) : (
            <FormInput
              name={EAddEditCountryFieldOverrideFields.country}
              type='text'
              label={t('addEditForm.country')!}
              required={true}
              className='ap-mb-spacing-5'
              control={control}
              disabled
            />
          )}
          <FormSelect
            name={EAddEditCountryFieldOverrideFields.gateQuestion}
            label={t('addEditForm.gateQuestion')!}
            required={false}
            className='ap-mb-spacing-5'
            data={gateQuestions.map(q => ({ label: q.questionText, value: q.questionId }))}
            control={control}
            disabled={false}
          />
          {!isEditMode ? (
            <Combobox
              className='ap-mb-spacing-5'
              data={fieldsByCountry.map(f => ({
                label: f.taraFieldName,
                value: f.taraFieldName
              }))}
              defaultValue={fieldName}
              disabled={isEditMode}
              labelKey={'label'}
              onSelect={val => {
                setValue(EAddEditCountryFieldOverrideFields.fieldName, val, RHF_SET_VALUE_OPTIONS)
              }}
              placeholder={t('addEditForm.fieldName')!}
              required={true}
              value={fieldName}
              valueKey={'value'}
            />
          ) : (
            <FormInput
              name={EAddEditCountryFieldOverrideFields.fieldName}
              type='text'
              label={t('addEditForm.fieldName')!}
              required={true}
              className='ap-mb-spacing-5'
              control={control}
              disabled
            />
          )}
          <div className='ap-border-radius-2 ap-mb-spacing-3 ap-p-spacing-3 ap-border-color-background-border border-1'>
            <SDKText weight={2}>{t('addEditForm.logics')!}</SDKText>
            <div className='ap-bg-color-background-default ap-border-radius-2 ap-my-spacing-3 ap-p-spacing-5'>
              <div className='ap-pb-spacing-3  ap-flex'>
                <SDKText weight={2}>{t('addEditForm.defaultLogic')!}</SDKText>
                <SDKTooltip
                  style={{ whiteSpace: 'pre-wrap' }}
                  content={t('addEditForm.defaultLogicTooltip')!}
                >
                  <div>
                    <SDKIcon code='information' />
                  </div>
                </SDKTooltip>
              </div>
              <SDKText>{field ? field.default : ''}</SDKText>
            </div>
            <FormTextArea
              name={EAddEditCountryFieldOverrideFields.newLogic}
              label={t('addEditForm.newLogic')!}
              className='ap-mb-spacing-5'
              required={false}
              control={control}
            />
            <FormSwitch
              name={EAddEditCountryFieldOverrideFields.isActive}
              control={control}
              className='ap-my-spacing-4'
            >
              {t('addEditForm.isActive')!}
            </FormSwitch>
          </div>
          <div className='ap-border-radius-2 ap-mb-spacing-3 ap-p-spacing-3 ap-border-color-background-border border-1'>
            <>
              <SDKText weight={2}>{t('addEditForm.overrideLogicTesting')!}</SDKText>
              <SDKTooltip
                content={t('addEditForm.overrideLogicTestingTooltip')!}
                className='tara-field-tooltip'
              >
                <div className='display-inline'>
                  <SDKIcon code='information' />
                </div>
              </SDKTooltip>
            </>
            <FormTextArea
              name={EAddEditCountryFieldOverrideFields.inputData}
              label={t('addEditForm.inputData')!}
              className='ap-my-spacing-5'
              required={false}
              control={control}
            />
            <SDKButton kind='secondary' className='w-100' onClick={validate}>
              <SDKIcon code='check-mark' />
              {t('addEditForm.testLogic')!}
            </SDKButton>
            <ValidationResult error={parseError} />
          </div>
        </FormCard>
        <SDKModal
          onCancel={() => setShowModal(false)}
          withCancel={true}
          onSubmit={handleSubmit(onSubmit)}
          submitLabel={t(`addEditForm.modal.confirm`)!}
          title=''
          visible={showModal}
          disabledSubmit={!isValid}
          withRequired={false}
        >
          {t('addEditForm.modal.text')!}
        </SDKModal>
      </div>
    </Layout>
  )
}
