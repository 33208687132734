import { Radio } from '@appkit4/react-components'
import classNames from 'classnames'
import type { IDocument } from '../../../types/document'
import { Divider } from '../../Divider'
import { Image } from '../../Image'
import { SDKPanel } from '../../SDK/Panel'
import { SDKText } from '../../SDK/Text'
import { CardActionButtonGroup } from '../components/CardActionButtonGroup'
import { EAction } from '../const'
import type { IActionCallback } from '../type'
import './DocumentWithImageCard.scss'

interface IDocumentCard {
  actions: EAction[]
  document: IDocument
  actionCallback?: (_callback: IActionCallback) => void
  withRadio?: boolean
  radioValue?: number
  asyncImage?: boolean
  selectedItem?: number | null
  exportResultDesign?: boolean
  onClick?: () => void
}

export const DocumentWithImageCard = ({
  actions,
  actionCallback,
  asyncImage,
  selectedItem,
  document,
  withRadio,
  radioValue,
  exportResultDesign = false,
  onClick
}: IDocumentCard) => {
  const cardContent = (
    <>
      {!exportResultDesign && <Divider />}
      <Image source={document.thumbnail} asyncImage={asyncImage} />
    </>
  )

  return (
    <SDKPanel
      className={classNames(
        'document-with-image-card-panel ',
        selectedItem === document.templateId && 'ap-border-color-background-primary',
        exportResultDesign && 'export-result'
      )}
      onClick={() => onClick?.()}
    >
      {withRadio ? (
        <div className='ap-flex justify-content-between align-items-center radio-wrapper'>
          <Radio value={radioValue} className='ap-ml-spacing-3'>
            <div className='text-ellipsis'>
              <SDKText type='heading' className='ap-text-neutral-20 ap-ml-spacing-3' weight={2}>
                {document.name}
              </SDKText>
            </div>
            {cardContent}
          </Radio>
        </div>
      ) : (
        <>
          <div className='ap-flex justify-content-between align-items-center'>
            <SDKText type='heading' className='ap-text-neutral-20' weight={2}>
              {document.name}
            </SDKText>
            <CardActionButtonGroup
              actions={actions}
              id={document.documentId}
              actionCallback={actionCallback}
            />
          </div>
          {cardContent}
        </>
      )}
    </SDKPanel>
  )
}
