import type { WithOptional } from '../../../types'
import { EAnswerTypeFromBE } from '../../countriesSnapshot/pages/AddEditQuestion/constants/answerType'
import { IAnswer, IQuestionnaireCountry, IQuestionnaireQuestion } from '../../questionnaires/type'

export interface IProjectFormHelper {
  countries: string | null
  users: string | null
}

export interface IChangedQuestion {
  subcategory?: string
  oldQuestionText: string
  newQuestionText: string
}

export interface IChangedAnswer {
  questionType: EAnswerTypeFromBE
  oldAnswer: IAnswer
  newAnswer: IAnswer
  questionText?: string
  countryName?: string
}

export type IAddProjectForm = WithOptional<IProject, EProjectFields.ProjectId> // & IProjectFormHelper

export type IEditProjectForm = IProject // & IProjectFormHelper

export type ICopyProjectForm = ICopyProjectFields

export enum EProjectFields {
  CountryIds = 'countryIds',
  CountrySnapshotCountryIds = 'countrySnapshotCountryIds',
  Description = 'description',
  HasChangedValues = 'hasChangedValues',
  HasDataToExport = 'hasDataToExport',
  Name = 'name',
  OutputCurrencyId = 'outputCurrencyId',
  OutputCurrencySymbol = 'outputCurrencySymbol',
  PricingCountryIds = 'pricingCountryIds',
  ProjectId = 'projectId',
  OriginalProjectId = 'originalProjectId',
  OriginalProjectName = 'originalProjectName',
  OriginalProjectYearId = 'originalProjectYearId',
  ShowRDPCopyWarning = 'showRDPCopyWarning',
  ShowPricingCopyWarning = 'showPricingCopyWarning',
  TaraCountryIds = 'taraCountryIds',
  TpEngagementId = 'tpEngagementId',
  TpEngagementName = 'tpEngagementName',
  UserIds = 'userIds',
  YearId = 'yearId',
  YearName = 'yearName',
  YearsCount = 'yearsCount'
}

export enum EProjectCardFields {
  CreatedDate = 'createdDate',
  UpdatedDate = 'updatedDate'
}

export interface ICopyProjectFields {
  [EProjectFields.Name]: string
  [EProjectFields.Description]: string
  [EProjectFields.YearId]: number | string
  originalProjectId: number
}

export interface IProjectCard extends IProject {
  [EProjectCardFields.CreatedDate]: Date
  [EProjectCardFields.UpdatedDate]: Date
}

export interface IProject {
  [EProjectFields.CountryIds]: string[]
  [EProjectFields.Description]: string
  [EProjectFields.Name]: string
  [EProjectFields.OutputCurrencyId]: number | string
  [EProjectFields.OutputCurrencySymbol]: string
  [EProjectFields.ProjectId]: number
  [EProjectFields.OriginalProjectId]?: number
  [EProjectFields.OriginalProjectName]?: string
  [EProjectFields.OriginalProjectYearId]?: number
  [EProjectFields.ShowRDPCopyWarning]?: boolean
  [EProjectFields.ShowPricingCopyWarning]?: boolean
  [EProjectFields.TpEngagementId]: number | null
  [EProjectFields.TpEngagementName]: string
  [EProjectFields.UserIds]: string[]
  [EProjectFields.YearId]: number | string
  [EProjectFields.YearName]: number
  [EProjectFields.CountrySnapshotCountryIds]?: number[]
  [EProjectFields.HasChangedValues]?: boolean
  [EProjectFields.HasDataToExport]?: boolean
  [EProjectFields.PricingCountryIds]?: number[]
  [EProjectFields.TaraCountryIds]?: number[]
  [EProjectFields.YearsCount]?: number | null
}

export interface IProjectCopy {
  projectResponse: IProject
  missingCountries: IQuestionnaireCountry[]
  missingQuestions: IQuestionnaireQuestion[]
  changedQuestions: IChangedQuestion[]
  changedAnswers: IChangedAnswer[]
}
