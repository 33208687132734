import queryString from 'query-string'
import { IProjectCard } from '../../../types'
import { getYearsFromProjects } from '../../ProjectList/utils/getYearsFromProjects'
import { ESortOption } from '../constants'
import { TFilter } from '../types'

export const useSetQueryString = (questionFilters: TFilter, projects: IProjectCard[]) => {
  const years = getYearsFromProjects(projects)
  const sorting = questionFilters.name
    ? { [ESortOption.name]: questionFilters.name }
    : questionFilters.date
    ? { [ESortOption.date]: questionFilters.date }
    : null

  const projectsToSet = projects.filter(
    p => !questionFilters.projectIds?.includes(String(p.projectId))
  ).length
    ? { projectIds: questionFilters.projectIds }
    : null

  const yearsToSet = years.filter(y => !questionFilters.yearIds?.includes(String(y.yearId))).length
    ? { yearIds: questionFilters.yearIds }
    : null

  const newQuery = queryString.stringify({
    ...sorting,
    ...projectsToSet,
    ...yearsToSet
  })
  if (newQuery.length) {
    return `?${newQuery}`
  }

  return ''
}
