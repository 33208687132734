import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormInput } from '../../../../components/Form/FormInput'
import { SDKModal } from '../../../../components/SDK/Modal'
import { SDKText } from '../../../../components/SDK/Text'
import { SDKTooltip } from '../../../../components/SDK/Tooltip'
import {
  EAddSubcategoryFields,
  useAddSubcategorySchema
} from '../../../../schemas/useAddSubcategorySchema'
import * as selectors from '../../store/selectors'
import './index.scss'
import { useAddEditSubcategory } from './utils/useAddEditSubcategory'

interface IAddSubcategoryModal {
  visible: boolean
  setVisible: (data: boolean) => void
  categoryId: number | null
  subcategoryId: number | null
  yearId?: number
  categoryName: string
}

export const AddEditSubcategoryModal = ({
  visible,
  setVisible,
  categoryId,
  subcategoryId,
  yearId,
  categoryName
}: IAddSubcategoryModal) => {
  const { t } = useTranslation('countriesSnapshot')
  const { error } = subcategoryId
    ? useSelector(selectors.getUpdateSubcategory)
    : useSelector(selectors.getAddSubcategory)
  const { validationSchema, defaultValues } = useAddSubcategorySchema(categoryId, subcategoryId)
  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid, isDirty },
    setError
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'all',
    reValidateMode: 'onChange'
  })
  const { onSubmit, onClose } = useAddEditSubcategory(
    setVisible,
    reset,
    categoryId,
    subcategoryId,
    yearId
  )

  useEffect(() => {
    if (error && error.includes('already exists')) {
      setError(EAddSubcategoryFields.subcategory, {
        message: `${t('newSubcategory.form.errors.subcategoryExist')}`
      })
    }
  }, [error])

  return (
    <form>
      <SDKModal
        visible={visible}
        onCancel={onClose}
        title={
          t(
            subcategoryId !== null
              ? 'newSubcategory.form.titleEdit'
              : 'newSubcategory.form.titleAdd'
          )!
        }
        disabledSubmit={subcategoryId ? !isDirty || !isValid : !isValid}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='ap-container add-fiscal-year-form'>
          <div className='form-container'>
            <div className='row ap-mt-spacing-2'>
              <div className='col'>
                <div className='category-name ap-mb-spacing-7'>
                  <SDKText type='body-s' className='ap-text-neutral-14'>
                    {t('newSubcategory.form.fields.category')!}
                  </SDKText>
                  <SDKText type='body' weight={2}>
                    {categoryName}
                  </SDKText>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <SDKTooltip disabled={isDirty} content={t('newSubcategory.form.tooltip')!}>
                  <FormInput
                    name={EAddSubcategoryFields.subcategory}
                    type='text'
                    label={t('newSubcategory.form.fields.subcategory')!}
                    required={true}
                    control={control}
                    suffixIcon
                  />
                </SDKTooltip>
              </div>
            </div>
          </div>
        </div>
      </SDKModal>
    </form>
  )
}
