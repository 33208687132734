import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { EmptyState } from '../../../../components/EmptyState'
import { LoadingModal } from '../../../../components/LoadingModal'
import { SDKDropdownButton } from '../../../../components/SDK/DropdownButton'
import { SDKIcon } from '../../../../components/SDK/Icon'
import { SDKLoading } from '../../../../components/SDK/Loading'
import { StatusPanel } from '../../../../components/SDK/StatusPanel'
import { LoadingStatus } from '../../../../shared/types/enums'
import i18n from '../../../../translations'
import { PowerBIAction } from '../../constants'
import { getTemplateCalculationById } from '../../store/selectors'
import { ECalculationType } from '../../types/projectCalculation'
import { CalendarFeedModal } from '../CalendarFeedModal'
import { ETemplateType } from '../GCDPricing/const/enums'
import { PowerBIActionModal } from '../PowerBIActionModal'
import styles from './indes.module.scss'
import { useCheckCalculationStatus } from './utils/useCheckCalculationStatus'
import { useDownloadPowerBI } from './utils/useDownloadPowerBI'
import { useSendPowerBIToSightline } from './utils/useSendPowerBIToSightline'
import { useTaraDropdownData } from './utils/useTaraDropdownData'

export const TaraResults = () => {
  const { t } = useTranslation('myprojects')
  const { data: calculatedData } = useSelector(getTemplateCalculationById)
  const [getCalendarFeedModalVisible, setGetCalendarFeedModalVisible] = useState(false)
  const [showSightlineCalculationResulLoadingModal, setShowSightlineCalculationResulLoadingModal] =
    useState(false)
  const [showDownloadExcelLoadingModal, setshowDownloadExcelLoadingModal] = useState(false)
  const [showDownloadInputDataLoadingModal, setShowDownloadInputDataLoadingModal] = useState(false)
  const [showPowerBIActionModal, setShowPowerBIActionModal] = useState<PowerBIAction | null>(null)

  const calculationLoading = useCheckCalculationStatus()
  const {
    dropdownData,
    defaultExportAction,
    disabledItemValuesIfError,
    disabledItemValuesIfSuccess
  } = useTaraDropdownData(
    setGetCalendarFeedModalVisible,
    setShowSightlineCalculationResulLoadingModal,
    setshowDownloadExcelLoadingModal,
    setShowDownloadInputDataLoadingModal,
    setShowPowerBIActionModal
  )

  const { downloadPowerBI } = useDownloadPowerBI(
    setShowPowerBIActionModal,
    calculatedData?.calculationId
  )
  const { sendPowerBIToSightline } = useSendPowerBIToSightline(
    setShowPowerBIActionModal,
    calculatedData?.calculationId
  )

  const isModalVisible =
    showSightlineCalculationResulLoadingModal ||
    showDownloadExcelLoadingModal ||
    showDownloadInputDataLoadingModal
  const loadingModalText = showSightlineCalculationResulLoadingModal
    ? t('projectDetail.projectTabs.tara.results.exportText')!
    : showDownloadExcelLoadingModal
    ? t('projectDetail.projectTabs.tara.results.dowloadExcelText')!
    : showDownloadInputDataLoadingModal
    ? t('projectDetail.projectTabs.tara.results.downloadInputText')!
    : ''

  return (
    <div className={classNames([styles['tara-results'], 'ap-mt-spacing-4'])}>
      {calculatedData ? (
        <>
          <SDKDropdownButton
            disabled={calculatedData.status === ECalculationType.InProgress}
            className='ap-flex justify-content-end ap-mb-spacing-3 w-100'
            splitButton={true}
            prefixTemplate={item => item.iconName && <SDKIcon code={item.iconName} />}
            compact
            onClick={() => defaultExportAction()}
            disabledItemValues={
              calculatedData.status === ECalculationType.Success
                ? disabledItemValuesIfSuccess
                : disabledItemValuesIfError
            }
            onSelect={(_, item) => item.onClick()}
            data={dropdownData}
            dropdownClassName={styles['tara-results-dropdown']}
          >
            {i18n.t('buttons.export')}
          </SDKDropdownButton>
          <StatusPanel
            type={calculatedData.status}
            data={calculatedData}
            initiatedBy={calculatedData.createdBy || 'N/A'}
          />
        </>
      ) : calculationLoading === LoadingStatus.Pending ? (
        <SDKLoading />
      ) : (
        <EmptyState customText={t('projectDetail.projectTabs.tara.results.emptyState')!} />
      )}
      {getCalendarFeedModalVisible && calculatedData && (
        <CalendarFeedModal
          isVisible={getCalendarFeedModalVisible}
          setIsVisible={setGetCalendarFeedModalVisible}
          calendarKey={calculatedData.calendarKey}
        />
      )}
      {isModalVisible && (
        <LoadingModal
          setVisible={setShowSightlineCalculationResulLoadingModal}
          text={loadingModalText}
        />
      )}
      {showPowerBIActionModal && (
        <PowerBIActionModal
          isVisible={!!showPowerBIActionModal}
          setIsVisible={setShowPowerBIActionModal}
          onSubmit={
            showPowerBIActionModal === PowerBIAction.Download
              ? downloadPowerBI
              : sendPowerBIToSightline
          }
          actionType={showPowerBIActionModal}
          templateType={
            calculatedData?.dateOnly ? ETemplateType.TaraCalendarOnly : ETemplateType.TaraFull
          }
        />
      )}
    </div>
  )
}
