import { Divider } from '../../../Divider'
import { EAction } from '../../const'
import type { IActionCallback } from '../../type'
import { CardActionButtonGroup } from './CardActionButtonGroup'
import { UserIcon } from './UserIcon'

interface IMobileFooterCard {
  actions: EAction[]
  actionCallback?: (_callback: IActionCallback) => void
  isUserItem: boolean
  id: string | number
  isEnabled: boolean
}

export const MobileFooter = ({
  actions,
  actionCallback,
  isUserItem,
  isEnabled,
  id
}: IMobileFooterCard) => {
  return (
    <>
      <Divider />
      <div className='ap-flex justify-content-end'>
        <div className='ap-px-spacing-5'>
          <UserIcon isUserItem={isUserItem} />
        </div>
        <CardActionButtonGroup
          actions={actions}
          actionCallback={actionCallback}
          hideLine={isUserItem}
          id={id}
          isEnabled={isEnabled}
        />
      </div>
    </>
  )
}
