import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { array, number, object, string } from 'yup'
import { EAnswerTypeForEdit } from '../modules/countriesSnapshot/pages/AddEditQuestion/constants/answerType'
import { IAnswer, IQuestionLanguage } from '../modules/questionnaires/type'
import { convertToTranslationCalendarName } from '../utils/convertToTranslationCalendarName'

export enum EAddAnswerFields {
  action = 'action',
  answer = 'answer',
  rate = 'rate',
  comment = 'comment',
  dates = 'dates',
  days = 'days',
  language = 'language',
  months = 'months',
  tara = 'tara',
  multipleChoice = 'multipleChoice',
  selectedLanguageId = 'selectedLanguageId',
  singleChoice = 'singleChoice',
  url = 'url'
}
export interface IFormValues {
  [EAddAnswerFields.action]: string
  [EAddAnswerFields.answer]: string
  [EAddAnswerFields.rate]: number | null
  [EAddAnswerFields.comment]: string
  [EAddAnswerFields.dates]: string
  [EAddAnswerFields.days]: number | null
  [EAddAnswerFields.language]: string
  [EAddAnswerFields.months]: number | null
  [EAddAnswerFields.multipleChoice]: number[]
  [EAddAnswerFields.selectedLanguageId]: number | null
  [EAddAnswerFields.singleChoice]: number | null
  [EAddAnswerFields.url]: string
}
interface IUseAddAnswerSchemaProps {
  type: EAnswerTypeForEdit
  isGateQuestion: boolean
  isGateQuestionEdit: boolean
  answer?: IAnswer
  languages?: IQuestionLanguage[]
}

export const useAddAnswerSchema = ({
  type,
  isGateQuestion,
  answer,
  isGateQuestionEdit,
  languages
}: IUseAddAnswerSchemaProps) => {
  const { t } = useTranslation('questionnaires')
  const getDefaultValues = (a?: IAnswer) => {
    let selectedLanguageId = null
    if (type === EAnswerTypeForEdit.Language && languages) {
      selectedLanguageId = languages.find(l => l.name === a?.answerText)?.languageId || null
    }
    const answers = a ? (a.selectedAnswers?.map(s => s.id) as number[]) : ([] as number[])
    const defaultAnswerText = a?.answerText && a?.answerText !== 'Not answered' ? a.answerText : ''

    return {
      [EAddAnswerFields.action]: a?.calendarAnswerResponse?.dateActionPerformer
        ? convertToTranslationCalendarName(a?.calendarAnswerResponse?.dateActionPerformer, true)
        : '',
      [EAddAnswerFields.answer]: defaultAnswerText,
      [EAddAnswerFields.rate]: a?.rate || null,
      [EAddAnswerFields.comment]: a?.openText || '',
      [EAddAnswerFields.dates]: a?.calendarAnswerResponse?.dateOffsetType
        ? convertToTranslationCalendarName(a?.calendarAnswerResponse?.dateOffsetType, true)
        : '',
      [EAddAnswerFields.days]: a?.calendarAnswerResponse
        ? a.calendarAnswerResponse.daysOffset
        : null,
      [EAddAnswerFields.language]: (selectedLanguageId && a?.answerText) || '',
      [EAddAnswerFields.months]: a?.calendarAnswerResponse
        ? a.calendarAnswerResponse.monthsOffset
        : null,
      [EAddAnswerFields.multipleChoice]: answers,
      [EAddAnswerFields.selectedLanguageId]: selectedLanguageId,
      [EAddAnswerFields.singleChoice]: answers.length ? answers[0] : null,
      [EAddAnswerFields.url]: a?.url || ''
    }
  }
  const defaultValues: IFormValues = useMemo(() => getDefaultValues(answer), [answer, languages])

  const taraValidation =
    isGateQuestion && !isGateQuestionEdit
      ? { [EAddAnswerFields.tara]: string().required() }
      : { [EAddAnswerFields.tara]: string() }

  switch (type) {
    case EAnswerTypeForEdit.TaxRate:
    case EAnswerTypeForEdit.CurrencyRate:
      return {
        validationSchema: object()
          .shape({
            ...taraValidation,
            [EAddAnswerFields.rate]: number()
              .required(t('answerForm.error')!)
              .typeError(t('answerForm.errorNumber')!),
            [EAddAnswerFields.comment]: string(),
            [EAddAnswerFields.url]: string()
          })
          .required(),
        defaultValues,
        getDefaultValues
      }
    case EAnswerTypeForEdit.Single:
      return {
        validationSchema: object()
          .shape({
            ...taraValidation,
            [EAddAnswerFields.singleChoice]: string().required(),
            [EAddAnswerFields.comment]: string(),
            [EAddAnswerFields.url]: string()
          })
          .required(),
        defaultValues,
        getDefaultValues
      }
    case EAnswerTypeForEdit.Multiple:
      return {
        validationSchema: object()
          .shape({
            ...taraValidation,
            [EAddAnswerFields.multipleChoice]: array().min(1),
            [EAddAnswerFields.comment]: string(),
            [EAddAnswerFields.url]: string()
          })
          .required(),
        defaultValues,
        getDefaultValues
      }
    case EAnswerTypeForEdit.Calendar:
      return {
        validationSchema: object()
          .shape({
            ...taraValidation,
            [EAddAnswerFields.action]: string().required(),
            [EAddAnswerFields.dates]: string().required(),
            [EAddAnswerFields.days]: number()
              .required(t('answerForm.error')!)
              .typeError(t('answerForm.errorNumber')!),
            [EAddAnswerFields.months]: number()
              .required(t('answerForm.error')!)
              .typeError(t('answerForm.errorNumber')!),
            [EAddAnswerFields.comment]: string(),
            [EAddAnswerFields.url]: string()
          })
          .required(),
        defaultValues,
        getDefaultValues
      }
    case EAnswerTypeForEdit.Language:
      return {
        validationSchema: object()
          .shape({
            ...taraValidation,
            [EAddAnswerFields.language]: string().required(t('answerForm.selectError')!),
            [EAddAnswerFields.comment]: string(),
            [EAddAnswerFields.url]: string()
          })
          .required(),
        defaultValues,
        getDefaultValues
      }
    default:
      return {
        validationSchema: object()
          .shape({
            ...taraValidation,
            [EAddAnswerFields.answer]: string().required(t('answerForm.error')!),
            [EAddAnswerFields.url]: string()
          })
          .required(),
        defaultValues,
        getDefaultValues
      }
  }
}
