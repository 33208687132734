import { useAuth } from 'react-oidc-context'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { Url } from '../../constants/urls'
import { useApp } from '../../context/app.context'
import AppLoading from '../../modules/loading'
import { UserRole } from '../../types/user'

interface IProtectedRouteProps {
  authenticationProcessInProgress: boolean
  allowedRoles?: UserRole[]
}

const ProtectedRoute = ({
  authenticationProcessInProgress,
  allowedRoles
}: IProtectedRouteProps) => {
  const {
    appContext: { user }
  } = useApp()
  const auth = useAuth()
  const location = useLocation()

  if (auth.isLoading || authenticationProcessInProgress) {
    return <AppLoading />
  }

  if (user) {
    const isAllowed =
      user?.roles.some(role => allowedRoles?.includes(role)) || user?.roles.includes(UserRole.Admin)

    if (isAllowed) {
      return <Outlet />
    }
    return <Navigate to={Url.IndexPage} state={{ from: location }} />
  }

  if (!auth.isAuthenticated) {
    return <Navigate to={Url.LoginPage} state={{ from: location }} />
  }

  return <AppLoading />
}

export default ProtectedRoute
