import { useCallback } from 'react'
import type { UseFormReset } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  BooleanOptions,
  EEditUserFields,
  type IFormValues
} from '../../../../../schemas/useAddEditUserSchema'
import { useAppDispatch } from '../../../../../store'
import type { IUser } from '../../../../../types/user'
import * as actions from '../../../store/actions'
import * as selectors from '../../../store/selectors'
import { EModalMode } from '../const'

export const useAddEditUser = (
  modalMode: EModalMode | null,
  onCloseModal: () => void,
  reset: UseFormReset<IFormValues>,
  user: IUser
) => {
  const dispatch = useAppDispatch()
  const { loading } = useSelector(
    modalMode === EModalMode.CreateServiceAccount
      ? selectors.createServiceAccount
      : selectors.getUserUpdate
  )

  const onClose = useCallback(() => {
    onCloseModal()
    reset()
  }, [reset])

  const onSubmit = (data: IFormValues) => {
    if (modalMode === EModalMode.CreateServiceAccount) {
      dispatch(actions.createServiceAccount(data[EEditUserFields.email]))
    } else {
      dispatch(
        actions.updateUser({
          ...user,
          isActive: !!(data.isActiveOption === BooleanOptions.Yes),
          isAdministrator: !!(data.isAdministratorOption === BooleanOptions.Yes),
          isServiceAccount: !!(data.isServiceAccountOption === BooleanOptions.Yes)
        })
      )
    }
  }

  return { onSubmit, onClose, loading }
}
