import { useNavigate } from 'react-router-dom'
import { CardListItem } from '../../../../components/CardListItem'
import { EAction, ECardListItem } from '../../../../components/CardListItem/const'
import { Url } from '../../../../constants/urls'
import type { IPowerBITemplate } from '../../types'
import { useDeletePBITemplate } from './utils/useDeletePBITemplate'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SDKModal } from '../../../../components/SDK/Modal'
import { SDKText } from '../../../../components/SDK/Text'
import './index.scss'

export const PowerBITemplatesList = ({
  yearId,
  list
}: {
  yearId?: number
  list: IPowerBITemplate[]
}) => {
  const { t, i18n } = useTranslation('documents')
  const navigate = useNavigate()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState<{
    name: string
    templateId: number
  } | null>(null)

  const { onDelete } = useDeletePBITemplate(yearId, selectedTemplate?.name)

  return (
    <>
      <div className='ap-grid mx-0 power-bi-templates-list'>
        {list?.map((template: IPowerBITemplate) => (
          <div
            className='power-bi-templates-list-item g-col-12 g-col-md-4'
            key={template.templateId}
          >
            <CardListItem
              actions={[EAction.Edit, EAction.Delete]}
              handleClick={callback => {
                if (callback.action === EAction.Edit) {
                  navigate(
                    `${location.pathname}${Url.PowerBITemplatesManagementPage}/${template.templateId}`
                  )
                }

                if (callback.action === EAction.Delete) {
                  setShowDeleteModal(true)
                  setSelectedTemplate({ name: template.name, templateId: template.templateId })
                }
              }}
              cardListItem={ECardListItem.PowerBITemplateCard}
              {...template}
              documentId={template.templateId}
              thumbnail={template.thumbnailUrl}
            />
          </div>
        ))}
      </div>
      <SDKModal
        onCancel={() => setShowDeleteModal(false)}
        onSubmit={() => onDelete(selectedTemplate?.templateId!)}
        title={t('powerBITemplates.deleteTemplate.title', { name: selectedTemplate?.name })!}
        submitLabel={i18n.t('buttons.confirm')!}
        visible={showDeleteModal}
        withCancel={true}
        withRequired={false}
      >
        <SDKText>{t('powerBITemplates.deleteTemplate.confirmText')}</SDKText>
      </SDKModal>
    </>
  )
}
