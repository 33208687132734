import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../context/app.context'
import { LoadingStatus } from '../../../shared/types/enums'
import { useAppDispatch } from '../../../store'
import * as actions from '../store/actions'
import * as selectors from '../store/selectors'

export const useAddEditUser = () => {
  const [selectedUserId, selectUserId] = useState<number | null>(null)
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { t } = useTranslation('users')
  const modal = useSelector(
    selectedUserId ? selectors.getUserUpdate : selectors.createServiceAccount
  )
  const {
    data: userSyncData,
    error: userSyncError,
    loading: userSyncLoading
  } = useSelector(selectors.getUserSync)

  const clearModal = () => {
    selectUserId(null)
    dispatch(actions.clearGetUser())
    dispatch(actions.clearCreateServiceAccount())
    dispatch(actions.clearUpdateUser())
  }

  const onAdd = () => {
    clearModal()
    selectUserId(null)
  }

  const onSyncUsers = () => {
    dispatch(actions.syncUsers())
  }

  const onSelectUser = (userId: number) => {
    clearModal()
    selectUserId(userId)
  }

  useEffect(() => {
    dispatch(actions.clearSyncUsers())

    return () => {
      clearModal()
    }
  }, [])

  useEffect(() => {
    if (userSyncError?.length) {
      sendNotification({
        message: userSyncError,
        status: 'error'
      })
    }
    if (userSyncLoading === LoadingStatus.Succeeded && userSyncData) {
      sendNotification({
        message: t(`syncUserSuccefull`)!,
        status: 'success'
      })
    }
  }, [userSyncError, userSyncLoading])

  return { clearModal, modal, onAdd, onSyncUsers, onSelectUser, selectedUserId, selectUserId }
}
