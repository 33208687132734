import { EPricingTab } from '../../../modules/project/components/GCDPricing/const/enums'
import type {
  IBusinessUnit,
  IProjectSetup
} from '../../../modules/project/components/GCDPricing/types/inputsTab'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export interface IUpdateBusinessUnitRequestParams {
  regionBusinessUnits: IBusinessUnit[]
}

export const updateBusinessUnitRequest = async (
  projectId: number,
  params: IUpdateBusinessUnitRequestParams
): Promise<IProjectSetup> => {
  return (
    await api.put(
      `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/${ProjectsUrl.Modules}/${EPricingTab.ProjectSetup}/${ProjectsUrl.BusinessUnits}`,
      params
    )
  ).data
}
