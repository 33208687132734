import type { Control, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormSelect } from '../../../../../../components/Form/FormSelect'
import { ISelectValue } from '../../../../../../components/SDK/Select'
import { SDKText } from '../../../../../../components/SDK/Text'
import { EFilterProjectFields, IFormValues } from '../../../../../../schemas/useFilterProjectSchema'
import { IOption } from '../../../../../../types/form'
import { FiscalYearBase } from '../../../../../fiscalYearSetup/types'
import * as selectors from '../../../../store/selectors'
import { TFilter } from '../../types'
import './index.scss'

interface IProjectFilterProps {
  currentFilters: TFilter
  setCurrentFilters: (f: TFilter) => void
  years: FiscalYearBase[]
  control: Control<FieldValues, IFormValues>
}

const Select = ({
  data,
  placeholder,
  onSelect,
  allSelectedLabel,
  value,
  control,
  name
}: {
  data: IOption[]
  placeholder: string
  onSelect: (ids: string) => void
  allSelectedLabel: string
  value: string | string[]
  control: Control<FieldValues, IFormValues>
  name: EFilterProjectFields
}) => {
  const allSelected = { label: allSelectedLabel, value: '-1' }
  const onDropdownSelect = (id: ISelectValue) => {
    if (id !== allSelected.value) {
      onSelect(String(id))
    }
  }

  return (
    <FormSelect
      data={[allSelected, ...data]}
      name={name}
      placeholder={placeholder}
      onSelect={onDropdownSelect}
      value={typeof value === 'string' ? value : allSelected.value}
      dropdownRenderMode='portal'
      className='ap-mt-spacing-4'
      showSelectAll={false}
      control={control}
      dropdownClassName='project-filters-dropdown'
      searchable
    />
  )
}

export const ProjectFilter = ({
  currentFilters,
  setCurrentFilters,
  control,
  years
}: IProjectFilterProps) => {
  const { t } = useTranslation('myprojects')
  const { data: projects } = useSelector(selectors.getMyProjects)

  const onYearSelect = (id: string) => {
    if (currentFilters.yearIds !== id) {
      setCurrentFilters({ ...currentFilters, yearIds: id })
    } else {
      setCurrentFilters({ ...currentFilters, yearIds: years.map(y => String(y.yearId)) })
    }
  }
  const onProjectSelect = (id: string) => {
    if (currentFilters.projectIds !== id) {
      setCurrentFilters({ ...currentFilters, projectIds: id })
    } else {
      setCurrentFilters({ ...currentFilters, projectIds: projects.map(p => String(p.projectId)) })
    }
  }

  return (
    <div className='ap-px-spacing-4'>
      <SDKText weight={2}>{t('filter.display')}</SDKText>
      <Select
        placeholder={t('filter.fiscalYear')!}
        name={EFilterProjectFields.yearIds}
        data={years.map(y => ({ label: String(y.name), value: String(y.yearId) }))}
        onSelect={onYearSelect}
        value={currentFilters.yearIds}
        allSelectedLabel={t('filter.allFiscalYears')}
        control={control}
      />
      <Select
        name={EFilterProjectFields.projectIds}
        data={projects.map(p => ({ label: p.name, value: String(p.projectId) }))}
        placeholder={t('filter.projectList')!}
        onSelect={onProjectSelect}
        value={currentFilters.projectIds}
        allSelectedLabel={t('filter.allProjects')}
        control={control}
      />
    </div>
  )
}
