import {
  EComplexity,
  EPricingTab,
  EScope
} from '../../../modules/project/components/GCDPricing/const/enums'
import { IPriceYear } from '../../../modules/project/components/GCDPricing/types/scope'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const getLocalFileScopePricingData = async (
  projectId: number,
  countryId: number,
  scopeLocationType: EScope,
  complexity: EComplexity,
  initialYear: number
): Promise<IPriceYear[]> => {
  const baseUrl = `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/${ProjectsUrl.Modules}/${EPricingTab.LocalFile}`

  return (
    await api.get(
      `${baseUrl}/PricesByCountry?countryId=${countryId}&scopeLocationType=${scopeLocationType}&complexity=${complexity}&initialYear=${initialYear}`
    )
  ).data
}
