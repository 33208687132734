import { Header } from '@appkit4/react-components/header'
import { useNavigate } from 'react-router-dom'
import { Url } from '../../../../constants/urls'
import { HeaderAvatar } from '../HeaderAvatar'
import { HeaderTitleTemplate } from '../HeaderTitleTemplate'

interface IAppHeaderProps {
  setSidebarVisibility: () => void
}

export const AppHeader = ({ setSidebarVisibility }: IAppHeaderProps) => {
  const navigate = useNavigate()

  return (
    <Header
      titleTemplate={() => <HeaderTitleTemplate setSidebarVisibility={setSidebarVisibility} />}
      onClickLogo={() => navigate(Url.MyProjectsPage)}
      className='ap-bg-color-background-alt ap-level-1'
      optionsTemplate={() => <HeaderAvatar />}
      onClickHamburger={setSidebarVisibility}
    ></Header>
  )
}
