import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { array, boolean, object, ObjectSchema, string } from 'yup'
import {
  EAnswerType,
  EAnswerTypeForEdit
} from '../modules/countriesSnapshot/pages/AddEditQuestion/constants/answerType'
import * as selectors from '../modules/countriesSnapshot/store/selectors'
import { EOperator, TQuestion } from '../modules/countriesSnapshot/types'
import { IAnswer } from '../modules/project/types/projectResults'

export enum EAddQuestionFields {
  answerType = 'answerType',
  category = 'category',
  editMode = 'editMode',
  localEntityOption = 'localEntityOption',
  option = 'option',
  otherOption = 'otherOption',
  possibleAnswers = 'possibleAnswers',
  question = 'question',
  defaultNaText = 'defaultNaText',
  questionDependencyOperator = 'questionDependencyOperator',
  parentQuestionId = 'parentQuestionId',
  parentQuestionAnswerIds = 'parentQuestionAnswerIds',
  relativeToCalendarYearOption = 'relativeToCalendarYearOption',
  relativeToFilingTaxReturnOption = 'relativeToFilingTaxReturnOption',
  relativeToFyeOption = 'relativeToFyeOption',
  relativeToInquiryOrAuditOption = 'relativeToInquiryOrAuditOption',
  subcategory = 'subcategory',
  ultimateParentEntityOption = 'ultimateParentEntityOption'
}

export const getQuestionDefaultValues = (
  questionType: EAnswerType | null,
  question?: TQuestion | null
) =>
  ({
    [EAddQuestionFields.answerType]: questionType || EAnswerType.Single,
    [EAddQuestionFields.category]: question?.category?.name || '',
    [EAddQuestionFields.editMode]: question?.questionId ? false : true,
    [EAddQuestionFields.localEntityOption]:
      question?.calendarQuestionOptions?.localEntityOption || false,
    [EAddQuestionFields.otherOption]: question?.calendarQuestionOptions?.otherOption || false,
    [EAddQuestionFields.question]: question?.questionText || '',
    [EAddQuestionFields.defaultNaText]: question?.defaultNaText || '',
    [EAddQuestionFields.relativeToCalendarYearOption]:
      question?.calendarQuestionOptions?.relativeToCalendarYearOption || false,
    [EAddQuestionFields.relativeToFilingTaxReturnOption]:
      question?.calendarQuestionOptions?.relativeToFilingTaxReturnOption || false,
    [EAddQuestionFields.relativeToFyeOption]:
      question?.calendarQuestionOptions?.relativeToFyeOption || false,
    [EAddQuestionFields.relativeToInquiryOrAuditOption]:
      question?.calendarQuestionOptions?.relativeToInquiryOrAuditOption || false,
    [EAddQuestionFields.subcategory]: question?.subcategory?.name || '',
    [EAddQuestionFields.ultimateParentEntityOption]:
      question?.calendarQuestionOptions?.ultimateParentEntityOption || false,
    [EAddQuestionFields.questionDependencyOperator]:
      question?.questionConditions[0]?.operator?.toString() || EOperator.AND.toString(),
    [EAddQuestionFields.parentQuestionId]: null,
    [EAddQuestionFields.parentQuestionAnswerIds]: [],
    [EAddQuestionFields.possibleAnswers]: question?.possibleAnswers
      ? question.possibleAnswers
      : [{ possibleAnswerText: '' }, { possibleAnswerText: '' }]
  } as IFormValues)

export interface IFormValues {
  [EAddQuestionFields.answerType]: EAnswerType
  [EAddQuestionFields.category]: string
  [EAddQuestionFields.editMode]?: boolean
  [EAddQuestionFields.localEntityOption]?: boolean
  [EAddQuestionFields.otherOption]?: boolean
  [EAddQuestionFields.possibleAnswers]?: IAnswer[]
  [EAddQuestionFields.question]: string
  [EAddQuestionFields.defaultNaText]: string
  [EAddQuestionFields.questionDependencyOperator]: string
  [EAddQuestionFields.parentQuestionId]: number | null
  [EAddQuestionFields.parentQuestionAnswerIds]?: number[]
  [EAddQuestionFields.relativeToCalendarYearOption]?: boolean
  [EAddQuestionFields.relativeToFilingTaxReturnOption]?: boolean
  [EAddQuestionFields.relativeToFyeOption]?: boolean
  [EAddQuestionFields.relativeToInquiryOrAuditOption]?: boolean
  [EAddQuestionFields.subcategory]: string
  [EAddQuestionFields.ultimateParentEntityOption]?: boolean
}

const getQuestionType = (type: string) => {
  return EAnswerType[
    Object.keys(EAnswerTypeForEdit).find(
      key => EAnswerTypeForEdit[key as keyof typeof EAnswerType] === type
    ) as keyof typeof EAnswerType
  ]
}

export const useAddQuestionSchema = (type: EAnswerType, questionId?: number) => {
  const { t } = useTranslation('countriesSnapshot')
  const question = questionId ? useSelector(selectors.getQuestionById(questionId)) : null
  const questionType = question?.type ? getQuestionType(question?.type || '') : null
  const defaultValues = getQuestionDefaultValues(questionType, question)
  const questionInfo = {
    [EAddQuestionFields.category]: string().required(t('newQuestion.form.errors.selectError')!),
    [EAddQuestionFields.question]: string()
      .required(t('newQuestion.form.errors.inputError')!)
      .trim(t('newQuestion.form.errors.inputError')!),
    [EAddQuestionFields.subcategory]: string().required(t('newQuestion.form.errors.selectError')!)
  }

  const validationSchema = object()
    .shape({ ...questionInfo, [EAddQuestionFields.editMode]: boolean().isTrue() })
    .required() as ObjectSchema<IFormValues>

  const validationSchemaCalendar = object()
    .shape({
      ...questionInfo,
      [EAddQuestionFields.editMode]: boolean().isTrue(),
      [EAddQuestionFields.localEntityOption]: boolean().when(
        EAddQuestionFields.ultimateParentEntityOption,
        {
          is: (ultimateParentEntityOption: boolean) => !ultimateParentEntityOption,
          then: () => boolean().isTrue()
        }
      ),
      [EAddQuestionFields.otherOption]: boolean().when(
        [
          EAddQuestionFields.relativeToCalendarYearOption,
          EAddQuestionFields.relativeToFilingTaxReturnOption,
          EAddQuestionFields.relativeToFyeOption,
          EAddQuestionFields.relativeToInquiryOrAuditOption
        ],
        {
          is: (
            relativeToCalendarYearOption: boolean,
            relativeToFilingTaxReturnOption: boolean,
            relativeToFyeOption: boolean,
            relativeToInquiryOrAuditOption: boolean
          ) =>
            !relativeToCalendarYearOption &&
            !relativeToFilingTaxReturnOption &&
            !relativeToFyeOption &&
            !relativeToInquiryOrAuditOption,
          then: () => boolean().isTrue()
        }
      ),
      [EAddQuestionFields.relativeToCalendarYearOption]: boolean(),
      [EAddQuestionFields.relativeToFilingTaxReturnOption]: boolean(),
      [EAddQuestionFields.relativeToFyeOption]: boolean(),
      [EAddQuestionFields.relativeToInquiryOrAuditOption]: boolean(),
      [EAddQuestionFields.ultimateParentEntityOption]: boolean()
    })
    .required() as ObjectSchema<IFormValues>

  const validationSchemaText = object()
    .shape({
      ...questionInfo,
      [EAddQuestionFields.possibleAnswers]: array()
        .of(
          object().shape({
            possibleAnswerText: string().required()
          })
        )
        .min(2)
    })
    .required() as ObjectSchema<IFormValues>

  switch (type) {
    case EAnswerType.Calendar:
      return { validationSchema: validationSchemaCalendar, defaultValues }

    case EAnswerType.Single:
    case EAnswerType.Multiple:
      return { validationSchema: validationSchemaText, defaultValues }

    default:
      return { validationSchema, defaultValues }
  }
}
