import { yupResolver } from '@hookform/resolvers/yup'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useApp } from '../../../../../../context/app.context'
import { useProject } from '../../../../../../context/project.context'
import type { IFormValues } from '../../../../../../schemas/useUploadTemplateWithCalculation'
import { useUploadTemplateWithCalculation } from '../../../../../../schemas/useUploadTemplateWithCalculation'
import { useAppDispatch } from '../../../../../../store'
import { ETARATab, ETARATabHashUrl } from '../../../../constants/tab'
import * as actions from '../../../../store/actions'
import { getUploadTemplateWithCalculation } from '../../../../store/selectors'
import { CalculationActionType } from '../../UploadModal'

export const useUploadTemplate = (
  setActiveIndex: Dispatch<SetStateAction<number>>,
  setActiveType: Dispatch<SetStateAction<CalculationActionType | null>>
) => {
  const dispatch = useAppDispatch()
  const navigation = useNavigate()
  const { setIsCalculationRunning } = useProject()
  const { sendNotification } = useApp()
  const { project: projectId } = useParams()
  const [showUploadModal, setShowUploadModal] = useState(false)
  const { validationSchema, defaultValues } = useUploadTemplateWithCalculation()
  const {
    data: uploadedTemplate,
    error,
    loading: uploadedTemplateLoading
  } = useSelector(getUploadTemplateWithCalculation)

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm<FieldValues, IFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    if (uploadedTemplate) {
      dispatch(actions.getTemplateCalculationById(Number(projectId)))
      setActiveIndex(ETARATab.Results)
      setActiveType(CalculationActionType.Basic)
      setIsCalculationRunning(true)
      navigation(`${location.pathname}#${ETARATabHashUrl.Results}`)
    }
  }, [uploadedTemplate])

  useEffect(() => {
    if (error && error?.length > 0) {
      sendNotification({
        message: error,
        status: 'error'
      })
    }
  }, [error])

  const onSubmit = (data: FieldValues) => {
    if (projectId) {
      dispatch(
        actions.uploadTemplateWithCalculation({
          inputFile: data.inputFile.originFile,
          dateOnly: data.dateOnly,
          projectId: Number(projectId)
        })
      )
      setShowUploadModal(false)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(actions.clearGetTemplateCalculationById())
    }
  }, [])

  return {
    uploadTemplate: handleSubmit(onSubmit),
    showUploadModal,
    setShowUploadModal,
    control,
    isValid,
    uploadedTemplateLoading
  }
}
