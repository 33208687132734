import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ActionButton } from '../../../../components/ActionButton'
import { CardListWrapper } from '../../../../components/CardListWrapper'
import { Layout } from '../../../../components/Layout'
import { SDKBadge } from '../../../../components/SDK/Badge'
import { SDKIcon } from '../../../../components/SDK/Icon'
import { SDKPanel } from '../../../../components/SDK/Panel'
import { SDKText } from '../../../../components/SDK/Text'
import { SDKTextButton } from '../../../../components/SDK/TextButton'
import { Url } from '../../../../constants/urls'
import { useApp } from '../../../../context/app.context'
import { LoadingStatus } from '../../../../shared/types/enums'
import { useAppDispatch } from '../../../../store'
import { CountryContactInformation } from '../../../project/components/ResultsByCountry/components/CountryContactInformation'
import * as actions from '../../store/actions'
import * as selectors from '../../store/selectors'
import type { IQuestionnaireCountry } from '../../type'
import './index.scss'

const QuestionnaireCountries = () => {
  const { t } = useTranslation('questionnaires')
  const dispatch = useAppDispatch()
  const { year: yearName } = useParams()
  const { loading } = useSelector(selectors.getQuestionnaireYears)
  const year = useSelector(selectors.getQuestionnaireYearByName(yearName!))
  const navigate = useNavigate()
  const pageTitle = `${t('year')} ${yearName}`
  const resource = useSelector(selectors.getQuestionnaireCountries)
  const {
    appContext: {
      style: { isMobile }
    },
    sendNotification
  } = useApp()
  const [activeCountry, setActiveCountry] = useState<IQuestionnaireCountry | null>(null)

  useEffect(() => {
    if (!year) {
      dispatch(actions.getQuestionnaireYears())
    }
  }, [])

  useEffect(() => {
    if (year?.yearId) {
      dispatch(actions.getQuestionnaireCountries(year.yearId))
    } else if (loading === LoadingStatus.Succeeded && !year) {
      navigate(Url.QuestionnairesPage)
    }
  }, [year, loading])

  useEffect(() => {
    if (resource.error?.length) {
      sendNotification({
        message: resource.error,
        status: 'error'
      })
    }
  }, [resource.error])

  const viewCountry = (name: string) => (
    <SDKTextButton onClick={() => navigate(`${location.pathname}/${name}`)} className='ap-link'>
      <>
        {!isMobile && <SDKText className='ap-text-color-data-orange'>{t('viewQuestions')}</SDKText>}
        <SDKIcon code='right-chevron'></SDKIcon>
      </>
    </SDKTextButton>
  )

  const status = (isVisible: boolean) => (
    <>
      <div className='px-0'>
        <SDKBadge
          value={t(isVisible ? 'status.visible' : 'status.hidden')}
          size={'sm'}
          type={isVisible ? 'success-outlined' : 'info-outlined'}
        />
      </div>
      <div className='label ap-text-neutral-14 ap-mx-spacing-3'>{t('status.label')}</div>
    </>
  )

  const unansweredQuestions = (unansweredQuestionsCount: number) => (
    <>
      <div className='border-left unanswered-questions'>
        <SDKText
          weight={2}
          className='ap-px-spacing-4 ap-flex ap-mx-spacing-3 justify-content-center'
        >
          {String(unansweredQuestionsCount)}
        </SDKText>
      </div>
      <div className='label ap-text-neutral-14'>{t('unansweredQuestionsCount')}</div>
    </>
  )

  const countryInfo = (country: IQuestionnaireCountry) => (
    <div className='ap-flex footer-container ap-ml-spacing-1'>
      <div className='ap-flex align-items-center'>
        {status(country.isPublished)}
        {unansweredQuestions(country.unansweredQuestionsCount)}
      </div>
      {isMobile && viewCountry(country.name)}
    </div>
  )

  return (
    <Layout title={pageTitle}>
      <CardListWrapper classNames='questionnaire-countries-page' resource={resource}>
        <>
          {resource.data?.map(country => (
            <SDKPanel
              key={country.countryId}
              className='ap-my-spacing-4'
              footer={isMobile && <div className='ap-mt-spacing-5'>{countryInfo(country)}</div>}
            >
              <div className='row'>
                <div className='col ap-flex align-items-center'>
                  <SDKText weight={2}>{country.name}</SDKText>
                  <ActionButton
                    icon='circle-more'
                    kind='neutral'
                    content=''
                    handleClick={(e: React.MouseEvent) => {
                      e.stopPropagation()
                      setActiveCountry(country)
                    }}
                  />
                </div>
                <div className='col ap-flex ap-flex align-items-center justify-content-end'>
                  {!isMobile && (
                    <>
                      <div className='ap-mr-spacing-3'>{countryInfo(country)}</div>
                      <div className='border-left ap-pl-spacing-3'>{viewCountry(country.name)}</div>
                    </>
                  )}
                </div>
              </div>
            </SDKPanel>
          ))}
        </>
      </CardListWrapper>
      {activeCountry && (
        <CountryContactInformation
          countryId={activeCountry.countryId}
          isVisible={Boolean(activeCountry)}
          setIsVisible={setActiveCountry}
          yearId={activeCountry.yearId}
        />
      )}
    </Layout>
  )
}
export default QuestionnaireCountries
