import { useEffect } from 'react'
import type {
  Control,
  FieldValues,
  UseFormResetField,
  UseFormSetValue,
  UseFormTrigger
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormSelect } from '../../../../../components/Form/FormSelect'
import { ISelectValue } from '../../../../../components/SDK/Select'
import { EAddQuestionFields, IFormValues } from '../../../../../schemas/useAddQuestionSchema'
import { IAnswer } from '../../../../project/types/projectResults'
import { EAnswerType } from '../constants/answerType'
import { CalendarTypeComponent } from './CalendarTypeComponent'
import { SingleOrMultipleChoiceComponent } from './SingleOrMultipleChoiceComponent'

interface IQuestionInfo {
  control: Control<FieldValues, IFormValues>
  resetField: UseFormResetField<IFormValues>
  setType: (t: EAnswerType) => void
  type: EAnswerType
  trigger: UseFormTrigger<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  defaultValues: IFormValues
  isEditMode: boolean
  possibleAnswers: IAnswer[]
}

export const AnswerTypeComponent = ({
  control,
  resetField,
  setType,
  type,
  trigger,
  setValue,
  defaultValues,
  isEditMode,
  possibleAnswers
}: IQuestionInfo) => {
  const { t } = useTranslation('countriesSnapshot')
  const allTypes = Object.values(EAnswerType)

  const onSelect = (selectedType: ISelectValue) => {
    if (selectedType === EAnswerType.Single || selectedType === EAnswerType.Multiple) {
      resetField(EAddQuestionFields.possibleAnswers)
    } else if (selectedType === EAnswerType.Calendar) {
      resetField(EAddQuestionFields.localEntityOption)
      resetField(EAddQuestionFields.ultimateParentEntityOption)
      resetField(EAddQuestionFields.possibleAnswers)
      resetField(EAddQuestionFields.relativeToCalendarYearOption)
      resetField(EAddQuestionFields.relativeToFilingTaxReturnOption)
      resetField(EAddQuestionFields.relativeToFyeOption)
      resetField(EAddQuestionFields.relativeToInquiryOrAuditOption)
    }
    setType(selectedType as EAnswerType)
  }

  useEffect(() => {
    setType(defaultValues.answerType)
  }, [])

  return (
    <>
      <FormSelect
        name={EAddQuestionFields.answerType}
        label={t('newQuestion.form.fields.answerType')!}
        required={true}
        className='ap-mb-spacing-5'
        data={
          isEditMode
            ? allTypes
            : allTypes.filter(
                singleType =>
                  singleType !== EAnswerType.TaxRate && singleType !== EAnswerType.CurrencyRate
              )
        }
        control={control}
        value={type}
        onSelect={onSelect}
        disabled={isEditMode}
      />
      {(type === EAnswerType.Single || type === EAnswerType.Multiple) && (
        <SingleOrMultipleChoiceComponent
          control={control}
          setValue={setValue}
          trigger={trigger}
          type={type}
          resetField={resetField}
          defaultValues={defaultValues}
          possibleAnswers={possibleAnswers}
        />
      )}
      {type === EAnswerType.Calendar && (
        <CalendarTypeComponent
          control={control}
          isEditMode={isEditMode}
          defaultValues={defaultValues}
        />
      )}
    </>
  )
}
