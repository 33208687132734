import type { SwitchProps } from '@appkit4/react-components'
import type { Control, FieldValues, Path } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { SDKSwitch } from '../../SDK/Switch'

interface IFormSwitchProps<T extends FieldValues> extends SwitchProps {
  name: Path<T>
  control: Control<T, any>
}

type Props<T extends FieldValues> = IFormSwitchProps<T>

export const FormSwitch = <T extends FieldValues>({ control, name, ...props }: Props<T>) => {
  return (
    <Controller
      name={name as Path<T>}
      control={control}
      render={({ field }) => <SDKSwitch checked={field.value} {...field} {...props} id={name} />}
    />
  )
}
