import classNames from 'classnames'
import moment from 'moment'
import type { Control, FieldValues, UseFormGetValues } from 'react-hook-form'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SDKText } from '../../../../../../components/SDK/Text'
import { EAddAnswerFields, IFormValues } from '../../../../../../schemas/useAddAnswerSchema'
import { IOption } from '../../../../../../types/form'
import { DATE_ISO_FORMAT } from '../../../../../../utils/dateFormatters'
import { EDateOffsetType, IQuestionnaireQuestion } from '../../../../type'

interface IAnswerTypeCalendarProps {
  radio: (n: EAddAnswerFields, o: IOption[], v?: string) => JSX.Element
  input: (n: EAddAnswerFields) => JSX.Element
  text: (n: EAddAnswerFields) => JSX.Element
  getValues: UseFormGetValues<FieldValues>
  control: Control<FieldValues, IFormValues>
  question: IQuestionnaireQuestion
  taraElement: () => void
  defaultValues: IFormValues
}

export const AnswerTypeCalendar = ({
  radio,
  input,
  text,
  control,
  question,
  taraElement,
  defaultValues
}: IAnswerTypeCalendarProps) => {
  const { t } = useTranslation('questionnaires')
  const START_DATE_NOT_TAX_RETURN_OR_FYE = '2023-12-31'
  const DEFAULT_START_DATE = '2023-06-30'

  const days = useWatch({
    control,
    name: 'days'
  })
  const months = useWatch({
    control,
    name: 'months'
  })
  const relativeDate = useWatch({
    control,
    name: 'dates'
  })
  const deadlineDate = (startDate: string) =>
    moment(startDate).add({ days, months }).format(DATE_ISO_FORMAT)
  const calendarQuestionOptions = Object.entries(question?.calendarQuestionOptions)
  const setSelectedOption = (options: string[]) =>
    calendarQuestionOptions
      .filter(option => !!options.find(o => o === option[0]) && option[1])
      .map(option => ({
        label: t(`answerForm.calendar.type.${option[0]}`),
        value: option[0]
      }))

  const selectedDateActionPerformerOptions = setSelectedOption([
    'localEntityOption',
    'ultimateParentEntityOption'
  ])
  const selectedDateOffsetTypeOptions = setSelectedOption([
    'relativeToFilingTaxReturnOption',
    'relativeToFyeOption',
    'relativeToCalendarYearOption',
    'relativeToInquiryOrAuditOption',
    'otherOption'
  ])

  const titleText = (title: string) => (
    <div className='ap-mb-spacing-3 ap-flex'>
      <SDKText className='ap-text-neutral-14'>{title}</SDKText>
      <div className='relative'>
        <div className='required-dot'></div>
      </div>
    </div>
  )

  const calendarExample = () => {
    switch (Number(EDateOffsetType[relativeDate])) {
      case EDateOffsetType.relativeToFilingTaxReturnOption:
      case EDateOffsetType.relativeToFyeOption:
        return (
          <div className='ap-m-spacing-3 ap-px-spacing-4'>
            <SDKText type='body-s' className='ap-text-neutral-14'>
              <>
                {t(`answerForm.calendar.calendarExample`)}
                {deadlineDate(DEFAULT_START_DATE)}
              </>
            </SDKText>
          </div>
        )
      case EDateOffsetType.relativeToCalendarYearOption:
        return (
          <div className='ap-m-spacing-3 ap-px-spacing-4'>
            <SDKText type='body-s' className='ap-text-neutral-14'>
              <>
                {t(`answerForm.calendar.calendarExampleNotTaxReturnOrFYE`)}
                {deadlineDate(START_DATE_NOT_TAX_RETURN_OR_FYE)}
              </>
            </SDKText>
          </div>
        )
      default:
        return <></>
    }
  }

  return (
    <>
      {titleText(t(`answerForm.calendar.label1`))}
      {radio(EAddAnswerFields.action, selectedDateActionPerformerOptions, defaultValues.action)}
      {titleText(t(`answerForm.calendar.label2`))}
      {radio(EAddAnswerFields.dates, selectedDateOffsetTypeOptions, defaultValues.dates)}
      <div className='ap-bg-color-background-default ap-mb-spacing-3 ap-py-spacing-5 ap-border-radius-2'>
        <div
          className={classNames(
            'row ap-m-spacing-3',
            Number(EDateOffsetType[relativeDate]) < 3 &&
              'border-bottom-1 ap-border-color-background-border'
          )}
        >
          <div className='col col-6'>{input(EAddAnswerFields.months)}</div>
          <div className='col col-6'>{input(EAddAnswerFields.days)}</div>
          <div className='ap-my-spacing-3'>
            <SDKText className='ap-text-color-text-body'>
              {t(`answerForm.calendar.calendarInfo`)}
            </SDKText>
          </div>
        </div>
        {calendarExample()}
      </div>
      {taraElement()}
      {text(EAddAnswerFields.comment)}
    </>
  )
}
