import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Divider } from '../../../../../../../../components/Divider'
import { AnswerText } from '../../../../../../../../components/QuestionWithAnswersCard/components/AnswerText'
import { SDKBadge } from '../../../../../../../../components/SDK/Badge'
import { SDKIcon } from '../../../../../../../../components/SDK/Icon'
import { SDKParagraph } from '../../../../../../../../components/SDK/Paragraph'
import { UserInfo } from '../../../../../../../../components/UserInfo'
import type { IUserBase } from '../../../../../../../../types/user'
import { EAnswerTypeForEdit } from '../../../../../../../countriesSnapshot/pages/AddEditQuestion/constants/answerType'
import type { TCalendarQuestionOptions } from '../../../../../../../countriesSnapshot/types'
import type { ICalendarAnswerResponse } from '../../../../../../../project/types/projectResults'
import type { IGateQuestionProhibitedAnswer } from '../../../../types'

import styles from './index.module.scss'

export enum EGateQuestionDraftAnswerType {
  NewAnswer = 'newAnswer',
  OldAnswer = 'oldAnswer'
}

export const GQQuestionItemAnswer = ({
  answer,
  answerType,
  calendarQuestionOptions,
  calendarAnswerResponse,
  id,
  onEditAnswer,
  submitter,
  type
}: {
  answer: IGateQuestionProhibitedAnswer
  answerType: EGateQuestionDraftAnswerType
  calendarQuestionOptions?: TCalendarQuestionOptions
  calendarAnswerResponse?: ICalendarAnswerResponse
  id: number
  onEditAnswer?: (answer: IGateQuestionProhibitedAnswer) => void
  submitter?: IUserBase
  type: EAnswerTypeForEdit
}) => {
  const { t } = useTranslation('fiscalYearDetails')

  return (
    <div
      className={classNames(
        'ap-p-spacing-4 ap-my-spacing-4 border-1 ap-border-radius-2 ap-border-color-background-border',
        styles.answer
      )}
    >
      <div className='ap-mb-spacing-4'>
        <div className='ap-flex justify-content-between'>
          <SDKBadge
            value={t(`gateQuestions.list.answers.${answerType}`)}
            type={answerType === EGateQuestionDraftAnswerType.OldAnswer ? 'info' : 'warning'}
          />
          {onEditAnswer !== undefined && (
            <span className='pointer' onClick={() => onEditAnswer(answer)}>
              <SDKIcon code={'edit'} className='ap-text-color-text-primary' />
            </span>
          )}
        </div>
      </div>
      <SDKParagraph weight={2} className='ap-mb-spacing-4'>
        <AnswerText
          answerText={answer?.answerText || String(answer?.rate || '')}
          type={type}
          calendarQuestionOptions={calendarQuestionOptions}
          calendarAnswerResponse={calendarAnswerResponse}
          daysOffset={answer?.calendarAnswerResponse?.daysOffset}
          monthsOffset={answer?.calendarAnswerResponse?.monthsOffset}
          id={id}
          isNotAnswered={answer?.isNotAnswered}
          selectedAnswers={answer?.selectedAnswers}
        />
      </SDKParagraph>

      <SDKParagraph className='ap-mb-spacing-4'>{answer.openText}</SDKParagraph>
      <SDKParagraph className='font-style-italic text-ellipsis'>{answer.url}</SDKParagraph>

      {submitter && (
        <>
          <Divider />
          <UserInfo user={submitter} />
        </>
      )}
    </div>
  )
}
