import { useTranslation } from 'react-i18next'
import { mixed, object } from 'yup'
import { MAX_XLSX_FILE_SIZE } from '../modules/project/constants'

export enum EUploadPricingToolImportFields {
  inputFile = 'inputFile'
}
export interface IFormValues {
  [EUploadPricingToolImportFields.inputFile]: File | null
}

export const useUploadPricingToolImport = () => {
  const { t } = useTranslation('myprojects')

  const defaultValues: IFormValues = {
    [EUploadPricingToolImportFields.inputFile]: null
  }
  const validationSchema = object()
    .shape({
      [EUploadPricingToolImportFields.inputFile]: mixed()
        .test('fileSize', t('projectDetail.projectTabs.tara.input.uploadFileTooLarge')!, value => {
          if (!value) return true
          return (value as File).size <= MAX_XLSX_FILE_SIZE
        })
        .required(t('projectDetail.projectTabs.tara.input.requiredField')!)
    })
    .required()

  return { validationSchema, defaultValues }
}
