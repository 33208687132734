import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { SDKText } from '../../../../../../../../../components/SDK/Text'
import { useCardGridColumnCalculation } from '../../../../../../../../../utils/useCardGridColumnCalculation'
import type { IParentScopeYearTotal } from '../../../../../types/scope'
import { useFeesAmountCalculation } from '../../utils/useFeesAmountCalculation'

interface IResultsContentWrapper {
  title: string
  fees?: IParentScopeYearTotal[]
  yearsCount: number
}

export const ResultsContentWrapper = ({
  children,
  title,
  fees,
  yearsCount
}: PropsWithChildren<IResultsContentWrapper>) => {
  const { t } = useTranslation('pricing')
  const { textCol, feeCol } = useCardGridColumnCalculation()
  const { modifiedFees } = useFeesAmountCalculation(yearsCount, fees)

  return (
    <div className='ap-bg-color-background-default ap-my-spacing-5'>
      <div className='ap-flex'>
        <div className='row ap-text-neutral-17 w-100'>
          <div className={`col col-${textCol} ap-pb-spacing-5`}>
            <SDKText type='heading' className='ap-text-neutral-20' weight={2}>
              {title}
            </SDKText>
          </div>

          <div className={`col col-sm-${feeCol} ap-pl-spacing-7 ap-pr-spacing-1`}>
            <div className={`ap-flex justify-content-around align-items-center w-100`}>
              {modifiedFees?.map((item, index) => (
                <div
                  key={`key-${item.totalValue}-${index}`}
                  className={`col col-sm-${12 / yearsCount} text-align-right`}
                >
                  <SDKText weight={2} type='heading' className='ap-text-neutral-17'>
                    {`${t(`tabs.inputs.fy`, { year: item.yearName })}`}
                  </SDKText>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div>{children}</div>
    </div>
  )
}
