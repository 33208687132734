import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from '../../../store'

export const getYears = (state: RootState) => state.fiscalYear.years
export const getYearById = (id: number | null) =>
  createSelector(getYears, state => state.data.find(({ yearId }) => id === yearId))
export const getYearByName = (yearName: string) =>
  createSelector(getYears, state => state.data.find(({ name }) => String(name) === yearName))
export const getPublishedYears = (state: RootState) => state.fiscalYear.publishedYears
export const getPublishedYearById = (id: number | null) =>
  createSelector(getPublishedYears, state => state.data.find(({ yearId }) => id === yearId))
export const getPublishedYearByName = (yearName: string) =>
  createSelector(getPublishedYears, state =>
    state.data.find(({ name }) => String(name) === yearName)
  )
export const getAddYear = (state: RootState) => state.fiscalYear.addYear
export const getUpdateYear = (state: RootState) => state.fiscalYear.updateYear
export const getDeleteYear = (state: RootState) => state.fiscalYear.deleteYear
export const getPowerBITemplates = (state: RootState) => state.fiscalYear.pBITemplates
export const getPowerBITemplate = (id: number | null) =>
  createSelector(getPowerBITemplates, state =>
    state.data.find(({ templateId }) => id === templateId)
  )
export const addPBITemplate = (state: RootState) => state.fiscalYear.addPBITemplate
export const updatePBITemplate = (state: RootState) => state.fiscalYear.updatePBITemplate
export const deletePBITemplate = (state: RootState) => state.fiscalYear.deletePBITemplate
export const getGateQuestions = (state: RootState) => state.fiscalYear.gateQuestions
export const getGateQuestionsExists = (state: RootState) => state.fiscalYear.gateQuestionsExists
export const getGateQuestionsPending = (state: RootState) => state.fiscalYear.gateQuestionsPending
export const getGateQuestionsAnswers = (state: RootState) => state.fiscalYear.gateQuestionsAnswers
export const publishGateQuestionDraft = (state: RootState) => state.fiscalYear.publishGateQuestion
export const rejectGateQuestionDraft = (state: RootState) => state.fiscalYear.rejectGateQuestion
