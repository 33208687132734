import React, { createContext, useContext, useEffect, useState } from 'react'
import { useYearFromUrl } from '../hooks/useYearFromUrl'
import { getPublishedYears } from '../modules/fiscalYearSetup/store/actions'
import type { FiscalYearInfo } from '../modules/fiscalYearSetup/types'
import { useAppDispatch } from '../store'

interface FiscalYearContextState {
  fiscalYearInfo: FiscalYearInfo | null
  fiscalYears: FiscalYearInfo[]
  refreshFiscalYear: (id: number) => void
}

const defaultContext: FiscalYearContextState = {
  fiscalYearInfo: null,
  fiscalYears: [],
  refreshFiscalYear: (_id: number) => {}
}

const useFiscalYearContext = (): FiscalYearContextState => {
  const [fiscalYearInfo, setFiscalYearInfo] = useState<FiscalYearInfo | null>(null)
  const dispatch = useAppDispatch()
  const { year, years: fiscalYears } = useYearFromUrl()

  const refreshFiscalYear = () => {
    dispatch(getPublishedYears())
  }

  useEffect(() => setFiscalYearInfo(() => year ?? null), [year])

  return {
    fiscalYearInfo,
    fiscalYears: fiscalYears ?? [],
    refreshFiscalYear
  }
}

const FiscalYearContext = createContext<FiscalYearContextState>(defaultContext)

export const useFiscalYear = (): FiscalYearContextState => {
  return useContext(FiscalYearContext)
}

export const FiscalYearContextConsumer = FiscalYearContext.Consumer

export const FiscalYearContextProvider = ({ children }: { children: React.ReactElement }) => {
  const { fiscalYearInfo, fiscalYears, refreshFiscalYear } = useFiscalYearContext()

  return (
    <FiscalYearContext.Provider
      value={{
        fiscalYearInfo,
        fiscalYears,
        refreshFiscalYear
      }}
    >
      {children}
    </FiscalYearContext.Provider>
  )
}
