import { useTranslation } from 'react-i18next'
import { IFormulaCountry } from '../../../modules/TaraFields/types'
import { SDKBadge } from '../../SDK/Badge'
import { SDKPanel } from '../../SDK/Panel'
import { SDKText } from '../../SDK/Text'
import { CardActionButtonGroup } from '../components/CardActionButtonGroup'
import { EAction } from '../const'
import { IActionCallback } from '../type'

export const TaraFieldsCard = ({
  actions,
  actionCallback,
  country
}: {
  actions: EAction[]
  actionCallback: (_callback: IActionCallback) => void
  country: IFormulaCountry
}) => {
  const { t } = useTranslation('taraFields')
  return (
    <SDKPanel className='border-1 ap-border-color-background-border ap-m-spacing-3'>
      <div className='ap-flex w-100 justify-content-between'>
        <div className='w-100'>
          <SDKText weight={2}>{country.countryName}</SDKText>
        </div>
        <div className='align-self-start ap-flex'>
          <SDKBadge
            value={t(country.isActive ? 'status.active' : 'status.inactive')}
            size={'sm'}
            type={country.isActive ? 'success-outlined' : 'info-outlined'}
            className='ap-mx-spacing-2'
          />
          <CardActionButtonGroup
            actions={actions}
            id={country.countryFormulaId}
            actionCallback={actionCallback}
          />
        </div>
      </div>
    </SDKPanel>
  )
}
