import { useCallback, useEffect } from 'react'
import type { UseFormReset } from 'react-hook-form'
import { useSelector } from 'react-redux'
import type { IFormValues } from '../../../../../schemas/useAddFiscalYearSchema'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../store'
import * as actions from '../../../store/actions'
import * as selectors from '../../../store/selectors'

export const useAddEditFiscalYear = (
  yearId: number | null,
  setVisible: (value: boolean) => void,
  reset: UseFormReset<IFormValues>
) => {
  const dispatch = useAppDispatch()
  const addYearData = useSelector(selectors.getAddYear)
  const updateYearData = useSelector(selectors.getUpdateYear)
  const loading: LoadingStatus = yearId ? updateYearData.loading : addYearData.loading

  const onClose = useCallback(() => {
    setVisible(false)
    reset()
  }, [setVisible, reset])

  const onSubmit = (data: IFormValues) => {
    const mappedData = {
      name: Number(data.year),
      isActive: data.status === 'true',
      fiscalYearData: Number(data.fiscalYearData)
    }
    if (yearId) {
      const updateData = { isDeleted: false, ...mappedData }
      dispatch(actions.updateYear({ params: updateData, yearId }))
    } else {
      dispatch(actions.addYear(mappedData))
    }
  }

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded) {
      dispatch(actions.getYears())
      onClose()
    }
  }, [updateYearData, addYearData])

  return { onSubmit, onClose, loading }
}
