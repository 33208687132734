import { PropsWithChildren } from 'react'
import { useCardGridColumnCalculation } from '../../../../../../../../../utils/useCardGridColumnCalculation'
import { EPricingTab } from '../../../../../const/enums'
import { ICardResult } from '../../../../../types/resultsTab'
import { MultipleRowsGrid } from '../MultipleRowsGrid'
import { ResultNameWrapper } from '../ResultNameWrapper'
import { ResultsList } from '../ResultsList'
import { SingleRowGrid } from '../SingleRowGrid'

interface IModuleCard {
  card: ICardResult
  index: number
  iconCode?: string
  iconOnClickHandler?: () => void
}

export const ModuleCard = ({
  card,
  index,
  iconCode,
  iconOnClickHandler
}: PropsWithChildren<IModuleCard>) => {
  const { textCol, feeCol } = useCardGridColumnCalculation()

  return (
    <ResultNameWrapper
      key={`${card.name}-${index}`}
      name={card.name as EPricingTab}
      iconCode={iconCode}
      iconOnClickHandler={iconOnClickHandler}
    >
      {card.rowsWithTotal &&
        card.rowsWithTotal.map((rowWithTotal, i) => (
          <div className='row g-0' key={`rowWithTotal-${i}`}>
            <div className={`col col-sm-${textCol}`}>
              <ResultsList
                data={rowWithTotal ? rowWithTotal?.texts : card.rows.map(item => item.text)}
              />
            </div>
            <div
              className={`col col-sm-${feeCol} ${
                rowWithTotal && rowWithTotal.texts?.length !== 1
              } ap-pl-spacing-6`}
            >
              {rowWithTotal ? (
                <SingleRowGrid fees={rowWithTotal.fees} />
              ) : card.rows ? (
                card.rows.map(row => (
                  <MultipleRowsGrid
                    key={row.id}
                    fees={row.fees}
                    isLastElement={index === (card as ICardResult).rows.length - 1}
                  />
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        ))}
    </ResultNameWrapper>
  )
}
