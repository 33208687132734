import { ECardDnDItem } from '../../../../../components/CardDnDWrapper/const'
import { ERole } from '../components/ScopeWrapper'
import { EBenchmarkType, TableType } from '../types/dnd'
import type { IChildScope, IParentScope, IScopeTabBase } from '../types/scope'

export const transformBenchmarkData = (
  globalBenchmarkingStudies: IParentScope[],
  regionalBenchmarkingStudies: IParentScope[],
  localBenchmarkingStudies: IParentScope[],
  scopeIncludes: IChildScope[],
  scopeExcludes: IChildScope[],
  scopeAssumes: IChildScope[],
  baseData: IScopeTabBase
) => {
  return {
    draggedItem: undefined,
    moduleId: baseData.type,
    projectId: baseData.projectId,
    name: baseData.name,
    tables: [
      {
        id: '0',
        tableName: TableType.Scope,
        acceptType: [TableType.Scope],
        benchmarkName: EBenchmarkType.GlobalBenchmarking,
        rows: globalBenchmarkingStudies.map(e => ({ ...e, id: String(e.parentScopeId) })),
        rowsType: ECardDnDItem.cardWithFee,
        role: ERole.Parent
      },
      {
        id: '4',
        tableName: TableType.Scope,
        acceptType: [TableType.Scope],
        benchmarkName: EBenchmarkType.RegionalBenchmarking,
        rows: regionalBenchmarkingStudies.map(e => ({ ...e, id: String(e.parentScopeId) })),
        rowsType: ECardDnDItem.cardWithFee,
        role: ERole.Parent
      },
      {
        id: '5',
        tableName: TableType.Scope,
        acceptType: [TableType.Scope],
        benchmarkName: EBenchmarkType.LocalBenchmarking,
        rows: localBenchmarkingStudies.map(e => ({ ...e, id: String(e.parentScopeId) })),
        rowsType: ECardDnDItem.cardWithFee,
        role: ERole.Parent
      },
      {
        id: '1',
        tableName: TableType.ScopeIncludes,
        rowsType: ECardDnDItem.defaultCard,
        acceptType: [TableType.ScopeIncludes, TableType.ScopeExcludes],
        rows: scopeIncludes.map(e => ({ ...e, id: String(e.childScopeId) })),
        role: ERole.Parent
      },
      {
        id: '2',
        tableName: TableType.ScopeExcludes,
        rowsType: ECardDnDItem.defaultCard,
        acceptType: [TableType.ScopeIncludes, TableType.ScopeExcludes],
        rows: scopeExcludes.map(e => ({ ...e, id: String(e.childScopeId) })),
        role: ERole.Child
      },
      {
        id: '3',
        tableName: TableType.ScopeAssumes,
        rowsType: ECardDnDItem.defaultCard,
        acceptType: [TableType.ScopeAssumes],
        rows: scopeAssumes.map(e => ({ ...e, id: String(e.childScopeId) })),
        role: ERole.Child
      }
    ]
  }
}
