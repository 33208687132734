import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { HeaderButtons } from '../../../../../../components/HeaderButtons'
import { Layout } from '../../../../../../components/Layout'
import { SDKModal } from '../../../../../../components/SDK/Modal'
import { useSubmitButton } from '../../../../../../hooks/useSubmitButton'
import { IFormValues, useAddAnswerSchema } from '../../../../../../schemas/useAddAnswerSchema'
import { useAppDispatch } from '../../../../../../store'
import { EAnswerTypeForEdit } from '../../../../../countriesSnapshot/pages/AddEditQuestion/constants/answerType'
import * as actions from '../../../../store/actions'
import * as selectors from '../../../../store/selectors'
import type { IAnswer, IQuestionnaireQuestion } from '../../../../type'
import { useAddAnswer } from '../../../../utils/useAddAnswer'
import AnswerForm from '../AnswerForm'

export const AnswerView = ({
  countryId,
  draftAnswer,
  onAnswerSave,
  query,
  question,
  onModalClose,
  yearId
}: {
  countryId?: number
  draftAnswer?: IAnswer
  onAnswerSave: () => void
  query: string | null
  question: IQuestionnaireQuestion | null
  onModalClose?: () => void
  yearId?: number
}) => {
  const { t } = useTranslation('questionnaires')
  const dispatch = useAppDispatch()
  const [showTaraInfoModal, setShowTaraInfoModal] = useState(false)

  const { data: languages } = useSelector(selectors.getAllLanguages)

  const { validationSchema, defaultValues } = useAddAnswerSchema({
    type: question?.type || EAnswerTypeForEdit.Single,
    isGateQuestion: !!question?.isGateQuestion,
    answer: draftAnswer || question?.answer,
    languages: languages,
    isGateQuestionEdit: !!draftAnswer
  })

  const { handleSubmit, control, reset, getValues, trigger, setValue, formState } = useForm<
    FieldValues,
    IFormValues
  >({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'all',
    reValidateMode: 'onChange'
  })

  const { setHoldNotification, onSubmit, onClose } = useAddAnswer(
    reset,
    onAnswerSave,
    setShowTaraInfoModal,
    yearId || 0,
    countryId || 0,
    question,
    query || '',
    draftAnswer
  )

  const onCloseTaraInfoModal = () => {
    setShowTaraInfoModal(false)
    setHoldNotification(false)
  }

  useEffect(() => {
    dispatch(actions.getAllLanguages())
  }, [])

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues])

  const { disabled } = useSubmitButton({ formState })

  useEffect(() => {
    trigger([])
  }, [formState.isValid, formState.isDirty])

  if (draftAnswer) {
    return (
      <SDKModal
        onCancel={() => {
          onModalClose?.()
        }}
        onSubmit={handleSubmit(d => onSubmit(d))}
        disabledSubmit={disabled}
        title={t('editAnswerFormTitle')!}
        className='p-0 ap-container default-modal'
        footerClassName='ap-pt-spacing-3'
        visible
      >
        <AnswerForm
          question={question}
          control={control}
          getValues={getValues}
          setValue={setValue}
          languages={languages}
          defaultValues={defaultValues}
          trigger={trigger}
          cardClassName='p-0'
          isEditing
        />
      </SDKModal>
    )
  }

  return (
    <Layout
      title={t('answerTitle')!}
      buttonComponent={
        <HeaderButtons
          name={t('buttons.save')}
          additionalButtonName={t('buttons.cancel')!}
          additionalButtonOnClick={onClose}
          icon='save'
          onClick={handleSubmit(d => onSubmit(d))}
          disabled={disabled}
          withAdditionalButton
        />
      }
      onClick={onAnswerSave}
      blockBackNavigate
    >
      <AnswerForm
        question={question}
        control={control}
        getValues={getValues}
        setValue={setValue}
        languages={languages}
        defaultValues={defaultValues}
        trigger={trigger}
      />
      <SDKModal
        visible={showTaraInfoModal}
        onCancel={() => onCloseTaraInfoModal()}
        title={t('modal.title')!}
        withRequired={false}
        onSubmit={handleSubmit(d => onSubmit(d, true))}
        submitLabel={t('modal.confirm')!}
        cancelText={t('modal.button')!}
        withCancel
      >
        {t('modal.content')}
      </SDKModal>
    </Layout>
  )
}
