import type { SelectProps } from '@appkit4/react-components'
import { useEffect, useState } from 'react'
import { SDKCheckbox } from '../SDK/Checkbox'
import { SDKSelect } from '../SDK/Select'
import './index.scss'

type TChild = { value: number; label: string; checked: boolean }

export type TData = {
  value: number
  label: string
  children: TChild[]
}

interface IDropdownWithAdditionalCheckboxesProps extends SelectProps {
  data: TData[]
  onSelectValue: (ids: number[]) => void
}

export const DropdownWithAdditionalCheckboxes = ({
  data,
  onSelectValue,
  ...props
}: IDropdownWithAdditionalCheckboxesProps) => {
  const [values, setValues] = useState<number[]>([])

  useEffect(() => {
    const defaultValue: number[] = []
    data.map(parent =>
      parent.children.forEach(child => {
        if (child.checked) defaultValue.push(child.value)
      })
    )
    setValues(defaultValue)
  }, [data])

  const onSelect = (newValues: number[]) => {
    setValues(newValues)
    onSelectValue(newValues)
  }

  const onChangeParent = (checked: boolean, parent: TData) => {
    const childrenValues = parent.children.map(child => child.value)
    const currentValues = [...values]
    if (!checked) {
      const newValues = currentValues.filter(
        currentValue => !childrenValues.find(v => v === currentValue)
      )
      setValues(newValues)
      onSelectValue(newValues)
    } else {
      const newValues = currentValues.filter(
        currentValue => !childrenValues.find(v => v === currentValue)
      )
      setValues([...childrenValues, ...newValues])
      onSelectValue([...childrenValues, ...newValues])
    }
  }

  const mappedData = data.map(parent => {
    const childrenValues = parent.children.filter(n => values.includes(n.value))
    const allValuesSelected = childrenValues.length === parent.children.length
    const notAllValuesSelected =
      childrenValues.length !== parent.children.length && !!childrenValues.length
    return {
      ...parent,
      type: 'group',
      label: (
        <SDKCheckbox
          className='ap-my-spacing-3'
          label={parent.label}
          checked={allValuesSelected}
          indeterminate={notAllValuesSelected}
          onChange={(c, _) => onChangeParent(c, parent)}
        />
      )
    }
  })

  return (
    <SDKSelect
      data={mappedData}
      dropdownRenderMode='portal'
      onSelect={onSelect}
      valueKey={'value'}
      labelKey={'label'}
      value={values}
      dropdownClassName='dropdown-with-additional-checkboxes'
      multiple
      {...props}
    ></SDKSelect>
  )
}
