import { EPricingTab } from '../../../modules/project/components/GCDPricing/const/enums'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const uploadLocalFileRequest = async (
  projectId: number,
  importFile: File
): Promise<File> => {
  const data = new FormData()
  data.append('importFile', importFile)

  return (
    await api.post(
      `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/${ProjectsUrl.Modules}/${EPricingTab.LocalFile}/${ProjectsUrl.Results}`,
      data,
      {
        ['Content-Type']: 'multipart/form-data'
      }
    )
  ).data
}
