import { useTranslation } from 'react-i18next'
import { ActionButton } from '../../../../../../components/ActionButton'
import { Divider } from '../../../../../../components/Divider'
import { SDKText } from '../../../../../../components/SDK/Text'
import { usePricingTab } from '../../../../../../context/pricingTab.context'
import { useCardGridColumnCalculation } from '../../../../../../utils/useCardGridColumnCalculation'
import style from '../../index.module.scss'
import { EParentScopeModalType } from '../../modals/ParentScopeModal/const'
import type { ITransactionalModule } from '../../types/inputsTab'
import type { IParentScopeYearTotal } from '../../types/scope'
import { FeesList } from '../FeesList'

export const TransactionList = ({
  transactionalModules,
  transactionalModulesTotals
}: {
  transactionalModules: ITransactionalModule[]
  transactionalModulesTotals?: IParentScopeYearTotal[]
}) => {
  const { t } = useTranslation('pricing')
  const { textCol } = useCardGridColumnCalculation()
  const { triggerParentScopeModal } = usePricingTab()

  const singleTransaction = (
    name: string,
    keyItem: number | string,
    fees: IParentScopeYearTotal[],
    sumUp?: boolean,
    isLastItem?: boolean
  ) => (
    <div key={`transaction-${keyItem}`}>
      <div className='ap-flex ap-pt-spacing-4'>
        <div className='row ap-text-neutral-17 w-100'>
          <div className={`col col-${textCol} ap-pb-spacing-5`}>
            <SDKText weight={sumUp ? 2 : 1}>{name}</SDKText>
          </div>
          <FeesList
            fees={fees}
            keyItem='transactional'
            shouldDisplayValues={true}
            weight={sumUp ? 2 : 1}
          />
        </div>
        <div className={style['action-single-edit-button-width']}></div>
        <ActionButton
          icon='edit'
          content=''
          className='ap-pr-spacing-3'
          handleClick={() => {
            triggerParentScopeModal(
              {
                localFeeCountryId: 0,
                name: name,
                parentScopeId: Number(keyItem),
                priceYears: []
              },
              EParentScopeModalType.Transaction
            )
          }}
        />
      </div>
      {!sumUp && !isLastItem && <Divider />}
    </div>
  )

  return (
    <>
      {!!transactionalModules.length && (
        <div className='ap-bg-color-background-default ap-p-spacing-4 ap-my-spacing-6 ap-border-radius-2'>
          <SDKText type='subheading' weight={2}>
            {t(`tabs.inputs.transactionalModules`)}
          </SDKText>

          <div className='ap-mt-spacing-4 '>
            {transactionalModules.map((item, index) =>
              singleTransaction(
                item.name,
                item.id,
                item.priceYears.map(y => ({ yearName: y.yearName, totalValue: y.totalFeeValue })),
                false,
                index + 1 === transactionalModules.length
              )
            )}

            {transactionalModulesTotals &&
              singleTransaction(
                t(`tabs.inputs.transactionalModulesTotal`),
                'transaction-total',
                transactionalModulesTotals,
                true
              )}
          </div>
        </div>
      )}
    </>
  )
}
