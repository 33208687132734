import classnames from 'classnames'
import { useForm, type FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormInput } from '../../../../components/Form/FormInput'
import { SDKIcon } from '../../../../components/SDK/Icon'
import { SDKTextButton } from '../../../../components/SDK/TextButton'

import style from './index.module.scss'

interface ISearchHeaderProps {
  onSearchButtonClick: (query: string) => void
}

const SearchHeader = ({ onSearchButtonClick }: ISearchHeaderProps) => {
  const { t } = useTranslation('search')
  const { control, handleSubmit } = useForm<FieldValues>({
    mode: 'onChange',
    reValidateMode: 'onChange'
  })
  const onSubmit = (data: FieldValues) => {
    onSearchButtonClick(data.searchInput)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classnames(style['search-row'])}>
        <div className={classnames(style['search-input'])}>
          <FormInput
            name='searchInput'
            type='text'
            label=''
            hideTitleOnInput={true}
            className='ap-mb-spacing-5'
            control={control}
          />
        </div>

        <div
          className={classnames(
            style['search-button'],
            'ap-pb-spacing-5 d-flex align-items-center justify-content-center'
          )}
        >
          <SDKTextButton
            key={t('header.buttonLabel')}
            onClick={handleSubmit(onSubmit)}
            className='expandable-button ap-button ap-button-primary pointer ap-mx-spacing-2'
          >
            <>
              <SDKIcon code='search' className='ap-mr-spacing-2'></SDKIcon>
              {t('header.buttonLabel')}
            </>
          </SDKTextButton>
        </div>
      </div>
    </form>
  )
}

export default SearchHeader
