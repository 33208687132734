import { ReactNode } from 'react'

import './CardFooter.scss'

export const CardFooter = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <div className='ap-container mx-0 gx-0 card-footer'>
      <div className='row w-100'>{children}</div>
    </div>
  )
}
