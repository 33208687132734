import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { usePricingTab } from '../../../../../context/pricingTab.context'
import { DragLayer } from '../components/DragLayer'
import { ScopeConfigurator } from '../components/ScopeConfigurator'
import { ScopesWrapper } from '../components/ScopesWrapper'
import { EPricingTab } from '../const/enums'
import type { ITransformedData } from '../types/dnd'
import type { IProjectManagementData } from '../types/inputsTab'
import { transformData } from '../utils/transformData'

const ProjectManagementContent = ({ data }: { data: IProjectManagementData | null }) => {
  const { t } = useTranslation('pricing')
  const [transformedData, setTransformedData] = useState<ITransformedData | null>(null)

  useEffect(() => {
    if (data) {
      const { type, name, projectId } = data
      const newData = transformData(
        data.parentScopes,
        data.scopeIncludes,
        data.scopeExcludes,
        data.scopeAssumes,
        { type, name, projectId },
        true,
        false,
        true
      )
      setTransformedData(newData)
    }
  }, [data])

  return (
    <>
      <ScopeConfigurator
        name={EPricingTab.ProjectManagement}
        moduleMetadata={data?.moduleMetadata}
        withOptionalSwitch={false}
        withRadioBtn={false}
      />
      {data && data.moduleMetadata.isEnabled && (
        <ScopesWrapper totalFee={data.quotes?.scopeTotal}>
          <>
            {transformedData && (
              <DragLayer
                transformedData={transformedData}
                quotes={data.quotes}
                customScopeWrapperTitle={t('tabs.inputs.projectManagement.scopeWrapperTitle')!}
              />
            )}
          </>
        </ScopesWrapper>
      )}
    </>
  )
}

const ProjectManagement = () => {
  const {
    tabContent: { selector }
  } = usePricingTab()
  const { data } = useSelector(selector)

  return <ProjectManagementContent data={data as IProjectManagementData} />
}

export default ProjectManagement
