import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { EmptyState } from '../../../../components/EmptyState'
import { Layout } from '../../../../components/Layout'
import { LoadingSpinner } from '../../../../components/SDK/LoadingSpinner'
import { useApp } from '../../../../context/app.context'
import { LoadingStatus } from '../../../../shared/types/enums'
import { useAppDispatch } from '../../../../store'
import { EDITING_EMAIL_TEMPLATE } from '../../constants'
import * as actions from '../../store/actions'
import { getEmailTemplates, getUpdateEmailTemplate } from '../../store/selectors'
import { EmailTemplatesList } from './components/EmailTemplatesList'

export const EmailTemplates = () => {
  const { t } = useTranslation('systemManagement')
  const dispatch = useAppDispatch()
  const { loading, error, data } = useSelector(getEmailTemplates)
  const {
    loading: updateLoading,
    error: updateError,
    data: updateData
  } = useSelector(getUpdateEmailTemplate)
  const { sendNotification } = useApp()

  useEffect(() => {
    dispatch(actions.getEmailTemplates())
  }, [])

  useEffect(() => {
    if (error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
    }
  }, [error])

  useEffect(() => {
    if (updateLoading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t('emailTemplates.updateSuccess', { name: updateData?.templateName }),
        status: 'success'
      })
    } else if (updateLoading === LoadingStatus.Failed && updateError?.length) {
      sendNotification({
        message: updateError,
        status: 'error'
      })
    }
    dispatch(actions.clearUpdateEmailTemplate())
  }, [updateLoading])

  return (
    <Layout onClick={() => localStorage.removeItem(EDITING_EMAIL_TEMPLATE)}>
      <div className='email-templates-page ap-my-spacing-5'>
        {loading === LoadingStatus.Pending ? (
          <LoadingSpinner />
        ) : data.length ? (
          <EmailTemplatesList />
        ) : (
          <EmptyState />
        )}
      </div>
    </Layout>
  )
}
