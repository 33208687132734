import { useTranslation } from 'react-i18next'
import { SDKAccordion } from '../../../../../../components/SDK/Accordion'
import { SDKAccordionItem } from '../../../../../../components/SDK/AccordionItem'
import { SDKPanel } from '../../../../../../components/SDK/Panel'
import { SDKParagraph } from '../../../../../../components/SDK/Paragraph'
import { type ICountrySnapshotSearchResult } from '../../../../types/searchResults'
import { ResultsData } from '../ResultsData'

const CountrySnapshotResult = ({
  resultObject
}: {
  resultObject: ICountrySnapshotSearchResult
}) => {
  const { t } = useTranslation('search')

  return (
    <SDKPanel className='ap-mb-spacing-6'>
      <ResultsData
        data={[
          { label: t('fields.common.country'), value: resultObject.countryName },
          { label: t('fields.common.year'), value: resultObject.yearName }
        ]}
      />

      <SDKAccordion>
        <SDKAccordionItem itemKey='q' title={resultObject.questionText}>
          <SDKParagraph className='white-space-prewrap'>{resultObject.answerText}</SDKParagraph>
        </SDKAccordionItem>
      </SDKAccordion>
    </SDKPanel>
  )
}

export default CountrySnapshotResult
