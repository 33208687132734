import { object } from 'yup'
import { ESortOptionValue } from '../modules/myprojects/components/FilterProjectsModal/constants'
import {
  ISetDefaultFiltersProps,
  setDefaultFilters
} from '../modules/myprojects/components/FilterProjectsModal/utils/setDefaultFilters'

export enum EFilterProjectFields {
  yearIds = 'yearIds',
  projectIds = 'projectIds',
  date = 'date',
  name = 'name'
}

export interface IFormValues {
  [EFilterProjectFields.yearIds]: string[] | string
  [EFilterProjectFields.projectIds]: string[] | string
  [EFilterProjectFields.date]: ESortOptionValue | null
  [EFilterProjectFields.name]: ESortOptionValue | null
}

export const useFilterProjectSchema = (props: ISetDefaultFiltersProps) => {
  const newFilters = setDefaultFilters(props)

  const defaultValues: IFormValues = {
    [EFilterProjectFields.yearIds]: newFilters.yearIds || [],
    [EFilterProjectFields.projectIds]: newFilters.projectIds || [],
    [EFilterProjectFields.date]: newFilters.name ? null : newFilters.date,
    [EFilterProjectFields.name]: newFilters.name || null
  }
  const validationSchema = object().shape({}).required()

  return {
    defaultValues,
    validationSchema
  }
}
