import { IProjectQuestion } from '../../../modules/project/types/projectQuestions'
import api from '../../api'
import { CountrySnapshotUrl, FiscalYearUrl, ProjectsUrl } from '../../constants/urls'

export const getProjectQuestionsData = async (
  yearId: number,
  projectId: number
): Promise<IProjectQuestion[]> => {
  const url = `${FiscalYearUrl.Years}/${yearId}/${ProjectsUrl.Projects}/${projectId}/${CountrySnapshotUrl.CountrySnapshot}/${CountrySnapshotUrl.Questions}`
  return (await api.get(url)).data
}
