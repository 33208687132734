import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Url } from '../../../../../constants/urls'
import { usePricingTab } from '../../../../../context/pricingTab.context'
import { useProjectFromUrl } from '../../../../../hooks/useProjectFromUrl'

export const usePermittedTab = (isPermitted: boolean) => {
  const { project } = useProjectFromUrl()
  const navigate = useNavigate()
  const { setCurrentTabIndex } = usePricingTab()

  useEffect(() => {
    if (project && !isPermitted) {
      setCurrentTabIndex?.(0)
      navigate(`${Url.MyProjectsPage}/${project.projectId}#pricing-scope-definition`)
    }
  }, [isPermitted, project?.projectId])
}
