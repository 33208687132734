import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Layout } from '../../components/Layout'
import { PageBackground } from '../../components/PageBackground'
import { SDKLoading } from '../../components/SDK/Loading'
import { Url } from '../../constants/urls'
import { useApp } from '../../context/app.context'
import { ProjectContextProvider } from '../../context/project.context'
import { useProjectFromUrl } from '../../hooks/useProjectFromUrl'
import AppLoading from '../loading'
import ProjectHeader from './components/ProjectHeader'
import ProjectTools from './components/ProjectTools'

const ProjectContent = () => {
  const { t } = useTranslation('myprojects')
  const {
    appContext: {
      style: { isMobile },
      isProjectMFE
    }
  } = useApp()
  const { project } = useProjectFromUrl()
  const navigate = useNavigate()

  const content = (
    <div className='project-detail-page'>
      <ProjectTools />
    </div>
  )

  if (!project) {
    return <AppLoading />
  }

  if (isProjectMFE) {
    return <PageBackground>{content}</PageBackground>
  }

  const headerComponent = (
    <ProjectHeader
      hasChangedValues={!!project?.hasChangedValues}
      onSettingsButtonClick={() =>
        navigate(`${Url.MyProjectsPage}/${project?.projectId}${Url.ProjectSettingsPage}`)
      }
      onChangedValuesButtonClick={() =>
        navigate(`${Url.MyProjectsPage}/${project?.projectId}${Url.ChangedValuesPage}`)
      }
    />
  )
  const pageTitle = `${t('projectDetail.title')} ${project?.name}`

  return (
    <Layout
      mobileHeaderComponent={isMobile && headerComponent}
      buttonComponent={!isMobile && headerComponent}
      title={pageTitle}
      hideBreadcrumb={!project}
    >
      {project ? content : <SDKLoading />}
    </Layout>
  )
}

const Project = () => (
  <ProjectContextProvider>
    <ProjectContent />
  </ProjectContextProvider>
)

export default Project
