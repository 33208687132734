import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { SDKRadioButton } from '../../../../components/SDK/RadioButton'
import { LoadingStatus } from '../../../../shared/types/enums'
import { useAppDispatch } from '../../../../store'
import * as actions from '../../../fiscalYearSetup/store/actions'
import { getPublishedYearByName } from '../../../fiscalYearSetup/store/selectors'
import { SortedResultsContainer } from '../../../project/components/ResultsTab/components/SortedResultsContainer'
import {
  IProjectResultByCountry,
  IProjectResultByQuestion
} from '../../../project/types/projectResults'
import './index.scss'

interface ICountrySnapshotMFEContentProps {
  countries: IProjectResultByCountry[]
  loading: LoadingStatus
  questions: IProjectResultByQuestion[]
  yearName: string
}

const CountrySnapshotMFEContent = ({
  countries,
  loading,
  questions,
  yearName
}: ICountrySnapshotMFEContentProps) => {
  const [activeOption, setActiveOption] = useState(0)
  const { t } = useTranslation('myprojects')
  const dispatch = useAppDispatch()
  const year = useSelector(getPublishedYearByName(yearName!))
  const options = [
    { value: 0, label: t('results.showByCountry') },
    { value: 1, label: t('results.showByQuestion') }
  ]

  useEffect(() => {
    dispatch(actions.getPublishedYears())
  }, [])

  return (
    <div className='country-snapshot-mfe-content ap-container p-0 m-0'>
      <div className='results-tab-header'>
        <SDKRadioButton
          defaultValue={0}
          options={options}
          onChange={(value: number) => setActiveOption(value)}
          withFlex
        />
      </div>
      <SortedResultsContainer
        activeOption={activeOption}
        countries={countries}
        loading={loading}
        questions={questions}
        yearId={year?.yearId}
      />
    </div>
  )
}

export default CountrySnapshotMFEContent
