import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { IconWithTooltip } from '../../../../../../components/IconWithTooltip'
import { SDKButton } from '../../../../../../components/SDK/Button'
import { SDKList } from '../../../../../../components/SDK/List'
import { SDKModal } from '../../../../../../components/SDK/Modal'
import { SDKText } from '../../../../../../components/SDK/Text'
import { useCopyBtn } from '../../../../../../shared/utils/useCopyBtn'

interface ILocalFileCountriesSkippedModal {
  setVisible: Dispatch<SetStateAction<string[] | null>>
  data: string[]
}

const LocalFileCountriesSkippedModal = ({ setVisible, data }: ILocalFileCountriesSkippedModal) => {
  const { t, i18n } = useTranslation('pricing')
  const { onCopy } = useCopyBtn()

  const copyData = () => {
    const dataString = data.join(', ')

    onCopy(dataString)
  }

  const renderItem = (item: string, _: number) => {
    return (
      <div key={item} className='ap-flex ap-py-spacing-5'>
        <SDKText>{item}</SDKText>
      </div>
    )
  }

  return (
    <SDKModal
      className='upload-modal'
      visible={true}
      onCancel={() => setVisible(null)}
      title={`${t(`tabs.inputs.localFile.entitiesUpload.title`)}`}
      withRequired={false}
      footer={
        <SDKButton type='button' onClick={() => setVisible(null)}>
          {i18n.t('nav.close')}
        </SDKButton>
      }
    >
      <div className='ap-flex' style={{ justifyContent: 'space-between' }}>
        <div>
          <div>
            <SDKText type='body-s' className='ap-pt-spacing-3'>
              {t('tabs.inputs.localFile.entitiesUpload.subtitle')}
            </SDKText>
            <SDKText type='body-s' className='ap-pt-spacing-3'>
              <a
                target='_blank'
                href={'mailto:' + t('tabs.inputs.localFile.entitiesUpload.mailto')}
              >
                {t('tabs.inputs.localFile.entitiesUpload.mailto')}
              </a>
            </SDKText>
          </div>
        </div>
        <div className='ap-pl-spacing-5 ap-py-spacing-2 pointer' onClick={() => copyData()}>
          <SDKText type='heading'>
            <IconWithTooltip
              code='copy'
              content={t('tabs.inputs.localFile.entitiesUpload.copyBtnTooltip')}
            />
          </SDKText>
        </div>
      </div>

      <SDKList data={data} itemKey={'countryName'} renderItem={renderItem}></SDKList>
    </SDKModal>
  )
}

export default LocalFileCountriesSkippedModal
