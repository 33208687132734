export enum EPowerBITemplateFields {
  Identifier = 'identifier',
  Name = 'name',
  TemplateFile = 'templateFile',
  TemplateFileName = 'templateFileName',
  TemplateId = 'templateId',
  TemplateType = 'templateType',
  ThumbnailFile = 'thumbnailFile',
  ThumbnailFileName = 'thumbnailFileName'
}

export interface IPowerBITemplateForm {
  [EPowerBITemplateFields.Identifier]: string
  [EPowerBITemplateFields.Name]: string
  [EPowerBITemplateFields.TemplateType]: String
  [EPowerBITemplateFields.TemplateFile]: File | null
  [EPowerBITemplateFields.TemplateFileName]: string
  [EPowerBITemplateFields.TemplateId]: number
  [EPowerBITemplateFields.ThumbnailFile]: File | null
  [EPowerBITemplateFields.ThumbnailFileName]: string
}

export interface IPowerBITemplate {
  identifier: string
  name: string
  templateFileName: string
  templateId: number
  templateType: String
  templateUrl: string
  thumbnailFileName: string
  thumbnailUrl: string
  yearId: number
}
