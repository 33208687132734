import { ESightlineRedirectAction } from '../../../modules/project/constants'
import { ResponseCode } from '../../../shared/types/enums'
import { authorizeSightline } from '../../../shared/utils/authorizeSightline'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const exportResultsWithSightlineRequest = async (
  projectId: number,
  rejectWithValue: any
): Promise<string> => {
  try {
    const response = await api.post(
      `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/${ProjectsUrl.Export}/${ProjectsUrl.Sightline}`,
      {}
    )
    return response.data
  } catch (error: any) {
    if (error.code === ResponseCode.Unauthorized) {
      authorizeSightline(error.message, ESightlineRedirectAction.ExportPricingResultsWithSightline)
      return ''
    } else {
      return rejectWithValue(error.message)
    }
  }
}
