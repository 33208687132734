import { SDKBreadcrumb } from '../Breadcrumb'
import { SDKBreadcrumbItem } from '../BreadcrumbItem'
import type { ISDKText } from '../Text'
import { SDKText } from '../Text'
import './index.scss'

interface ISDKBreadcrumbsWithTextProps {
  breadcrumbs: string[]
  text: ISDKText
}

export const SDKBreadcrumbsWithText = ({ breadcrumbs, text }: ISDKBreadcrumbsWithTextProps) => {
  return (
    <div className='breadcrumbs-with-text'>
      <SDKBreadcrumb>
        {breadcrumbs.map((breadcrumb, index) => (
          <SDKBreadcrumbItem
            className='ap-text-neutral-14'
            key={`breadcrumbs-with-text-${breadcrumb}-${index}`}
          >
            <SDKText type='body-s'>{breadcrumb}</SDKText>
          </SDKBreadcrumbItem>
        ))}
      </SDKBreadcrumb>
      <SDKText {...text}>{text.children}</SDKText>
    </div>
  )
}
