import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Module } from '../../../constants/modules'
import { addNewCountry } from '../../../services/requests/countries/addNewCountry'
import { getCountriesData } from '../../../services/requests/countries/getCountriesData'
import { getCountryByIdData } from '../../../services/requests/countries/getCountryByIdData'
import { getPublishedCountriesData } from '../../../services/requests/countries/getPublishedCountriesData'
import { updateCountryData } from '../../../services/requests/countries/updateCountryData'
import { updateCountryVisibilityData } from '../../../services/requests/countries/updateCountryVisibilityData'
import { IAddEditCountry } from '../types'

const moduleName: string = String(Module.Countries)

export const getCountries = createAsyncThunk(`${moduleName}/getCountries`, (id: number) =>
  getCountriesData(id)
)
export const clearCountries = createAction(`${moduleName}/clearCountries`)
export const getPublishedCountries = createAsyncThunk(
  `${moduleName}/getPublishedCountries`,
  (id: number) => getPublishedCountriesData(id)
)
export const clearPublishedCountries = createAction(`${moduleName}/clearPublishedCountries`)
export const getCountryById = createAsyncThunk(
  `${moduleName}/getCountryById`,
  ({ yearId, countryId }: { yearId: number; countryId: number }) =>
    getCountryByIdData(yearId, countryId)
)
export const clearGetCountryById = createAction(`${moduleName}/clearGetCountryById`)
export const addCountry = createAsyncThunk(
  `${moduleName}/addCountry`,
  ({ yearId, params }: { yearId: number; params: IAddEditCountry }) => addNewCountry(yearId, params)
)
export const clearAddCountry = createAction(`${moduleName}/clearAddCountry`)
export const updateCountry = createAsyncThunk(
  `${moduleName}/updateCountry`,
  ({ yearId, countryId, params }: { yearId: number; countryId: number; params: IAddEditCountry }) =>
    updateCountryData(yearId, countryId, params)
)
export const clearUpdateCountry = createAction(`${moduleName}/clearUpdateCountry`)
export const updateCountryVisibility = createAsyncThunk(
  `${moduleName}/updateCountryVisibility`,
  ({ yearId, countryId }: { yearId: number; countryId: number }) =>
    updateCountryVisibilityData(yearId, countryId)
)
export const clearUpdateCountryVisibility = createAction(
  `${moduleName}/clearUpdateCountryVisibility`
)
