import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { HeaderButtons } from '../../../../components/HeaderButtons'
import { Url } from '../../../../constants/urls'
import { useAppDispatch } from '../../../../store'
import * as actions from '../../store/actions'

export const CountriesHeader = () => {
  const { t } = useTranslation('countries')
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const onAddCountry = () => {
    dispatch(actions.clearAddCountry())
    dispatch(actions.clearUpdateCountry())
    navigate(`${location.pathname}${Url.AddCountryPage}`)
  }

  return (
    <HeaderButtons name={t('addCountry')} icon='plus' onClick={onAddCountry} withFilter={false} />
  )
}
