import { useTranslation } from 'react-i18next'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { SDKButton } from '../../../Button'
import type { FooterButton, IWizardFooter } from '../../types'
import type { SaveButtonProps } from '../FooterButtons/SaveButton'
import { SaveButton } from '../FooterButtons/SaveButton'

export const WizardFooter = ({
  step,
  previousButton,
  nextButton,
  saveButtonProps,
  onCancel
}: IWizardFooter & { saveButtonProps: SaveButtonProps }) => {
  const { t } = useTranslation()

  const button = (btn?: FooterButton) => {
    if (!btn) {
      return <></>
    }

    return (
      <SDKButton
        className={btn.className}
        disabled={btn.disabled}
        kind={btn.kind || 'primary'}
        loading={btn.loading === LoadingStatus.Pending}
        onClick={btn.onClick}
        type={btn.type || 'submit'}
      >
        {btn.text}
      </SDKButton>
    )
  }

  const isFirstStep = step.current === 0
  const isLastStep = step.current + 1 === step.count

  return (
    <>
      {isFirstStep && button({
        ...previousButton,
        className: 'ap-mr-spacing-4',
        kind: 'text',
        onClick: onCancel,
        text: t('buttons.cancel')
      })
      }
      {!isFirstStep && button({
        ...previousButton,
        className: 'ap-mr-spacing-4',
        kind: 'text',
        onClick: () => step.setStep(step.current - 1),
        text: previousButton?.text ?? t('buttons.back')
      })}
      {isLastStep ? (
        <SaveButton
          {...saveButtonProps}
          onSubmit={() => saveButtonProps && saveButtonProps.onSubmit && saveButtonProps.onSubmit()}
        />
      ) : (
        button({
          ...nextButton,
          onClick: () => step.setStep(step.current + 1),
          text: nextButton?.text ?? t('buttons.continue')
        })
      )}
    </>
  )
}
