import { useEffect, useState } from 'react'
import { useApp } from '../context/app.context'

export enum EFeatureFlag {
  FYGateQuestions = 'fiscalYearGateQuestions',
  Pricing = 'pricing',
  Search = 'search'
}

// const TEAM_EMAIL_HASHES = {
//   client: [
//     // 'c83b2b1845768d891b43a299f944b84682ac67be5106f7c01001763c9126851d', // Igor
//     // '57939837fead341ac3b3f2a8c5b9ce07e4794658013d9910794472502f7268e2' // Igor
//   ],
//   tl: [
//     // '99e632974d1e91fa7c05942701c8b6cb36db12125b6b71e9692848d9a826b352', // Piotr
//     // '67372672cf08a524e747eb946f3d30ee069e6691fb5e6a16d81e3848e0cfe52f' // Piotr
//   ]
// }

// const eligibleEmails = [...TEAM_EMAIL_HASHES.client, ...TEAM_EMAIL_HASHES.tl]

async function getHash(text: string): Promise<string> {
  const msgUint8 = new TextEncoder().encode(text)
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}

export const useFeatureFlags = () => {
  const [, /*hashedEmail*/ setHashedEmail] = useState('')
  const {
    appContext: { user }
  } = useApp()

  useEffect(() => {
    if (user?.email) {
      getHash(user.email).then((res: string) => {
        setHashedEmail(() => res)
      })
    }
  }, [user])

  // const allowedUsers = (emails: string[]) => {
  //   return emails.includes(hashedEmail)
  // }

  const featureFlags = {
    [EFeatureFlag.FYGateQuestions]: true,
    [EFeatureFlag.Pricing]: true,
    [EFeatureFlag.Search]: true
  }

  const isEnabled = (ff: EFeatureFlag) => featureFlags[ff] ?? false

  return { isEnabled }
}
