import classnames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generateRandomHash } from '../../utils/generateRandomHash'
import { LoadingSpinner } from '../SDK/LoadingSpinner'
import { DEFAULT_IMAGE_HEIGHT } from './const'
import styles from './index.module.scss'
import { renderAsyncImage } from './utils/renderAsyncImage'

export const Image = ({
  asyncImage,
  height = DEFAULT_IMAGE_HEIGHT,
  source
}: {
  asyncImage?: boolean
  height?: number
  source?: string
}) => {
  const { t } = useTranslation()
  const id = generateRandomHash()
  const [showImage, setShowImage] = useState(true)

  useEffect(() => {
    var promise = renderAsyncImage(id, source, height)
    promise.then(result => setShowImage(result))
  }, [])

  const imgStyle = [styles.image, 'ap-panel ap-panel-bordered mt-2']

  const image = (
    <div className={classnames(imgStyle)} id={id}>
      {asyncImage ? <LoadingSpinner /> : <img loading='lazy' height={height} src={source} />}
    </div>
  )

  return (
    <div className={styles.panel}>
      {source && showImage ? (
        image
      ) : (
        <div className={classnames(imgStyle)}>
          <svg
            className={styles.svg}
            height={height}
            width='100%'
            xmlns='http://www.w3.org/2000/svg'
            viewBox={`0 0 100% ${height}`}
          >
            <text text-anchor='middle' x='50%' y='50%' font-size='medium'>
              {t('image.missingThubnail')}
            </text>
          </svg>
        </div>
      )}
    </div>
  )
}
