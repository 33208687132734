import { EmptyState } from '../../../../components/EmptyState'
import type { FiscalYearInfo } from '../../types'
import { FiscalYearCard } from './components/FiscalYearCard'
import './index.scss'

export const FiscalYearsList = ({
  list,
  onEdit,
  onDelete
}: {
  list: FiscalYearInfo[]
  onEdit: (value: number) => void
  onDelete: (value: number) => void
}) => {
  if (list?.length === 0) {
    return <EmptyState />
  } else {
    return (
      <div className='ap-grid mx-0 ap-my-spacing-5 fiscal-years-list'>
        {list?.map((fiscalYear: FiscalYearInfo) => (
          <div className='fiscal-years-list g-col-12' key={fiscalYear.yearId}>
            <FiscalYearCard fiscalYear={fiscalYear} onEdit={onEdit} onDelete={onDelete} />
          </div>
        ))}
      </div>
    )
  }
}
