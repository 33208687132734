import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Module } from '../../../constants/modules'
import { getSearchResultsData } from '../../../services/requests/search/getSearchResults'

const moduleName: string = String(Module.Search)

export const getSearchResults = createAsyncThunk(
  `${moduleName}/getSearchResults`,
  (query: string) => getSearchResultsData(query)
)
export const clearGetSearchResults = createAction(`${moduleName}/clearGetSearchResults`)
