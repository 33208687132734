import { useTranslation } from 'react-i18next'
import { EAnswerTypeForEdit } from '../../../modules/countriesSnapshot/pages/AddEditQuestion/constants/answerType'
import { IQuestionnaireQuestion } from '../../../modules/questionnaires/type'
import { IconWithTooltip } from '../../IconWithTooltip'
import { QuestionWithAnswersCard } from '../../QuestionWithAnswersCard'
import { SDKPanel } from '../../SDK/Panel'
import { SDKTextButton } from '../../SDK/TextButton'
import { EAction } from '../const'
import { IActionCallback } from '../type'

export const QuestionnaireQuestionCard = ({
  actionCallback,
  question
}: {
  actions: EAction[]
  actionCallback: (_callback: IActionCallback) => void
  question: IQuestionnaireQuestion
}) => {
  const { t } = useTranslation('questionnaires')
  const showButtons = !question.answer?.isNotApplicable

  return (
    <SDKPanel className='border-1 ap-border-color-background-border ap-my-spacing-3'>
      <div className='ap-flex w-100 justify-content-between'>
        <div className='w-100'>
          <QuestionWithAnswersCard
            answer={question.answer}
            title={question.questionText}
            type={question.answer?.isNotApplicable ? EAnswerTypeForEdit.PlainText : question.type}
            id={question.questionId}
            viewWithBreadcrumbs={true}
            breadcrumbs={[question.category.name, question.subcategory.name]}
            border={false}
            isEnabled={question.isEnabled}
            calendarAnswerResponse={question.answer?.calendarAnswerResponse}
          />
        </div>
        <div className='align-self-start'>
          <>
            {question.isAdminReviewRequired ? (
              <IconWithTooltip
                code='leader'
                iconClassName='ap-text-color-text-warning'
                content={t('table.adminReviewRequiredTooltip')!}
              />
            ) : (
              <>
                {showButtons ? (
                  <SDKTextButton
                    onClick={() =>
                      actionCallback({ action: EAction.Edit, id: question.questionId })
                    }
                    className='ap-text-color-text-primary'
                  >
                    <IconWithTooltip code='edit' content={t('table.editTooltip')!} />
                  </SDKTextButton>
                ) : (
                  <IconWithTooltip
                    code='confirmed'
                    iconClassName='ap-text-color-text-success'
                    content={t('table.systemAnsweredQuestionTooltip')!}
                  />
                )}
              </>
            )}
          </>
        </div>
      </div>
    </SDKPanel>
  )
}
