import type { ICountryListItem } from '../../../modules/countries/types'
import { ESightlineRedirectAction } from '../../../modules/project/constants'
import { ResponseCode } from '../../../shared/types/enums'
import { authorizeSightline } from '../../../shared/utils/authorizeSightline'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const syncWithSightlineRequest = async (
  projectId: number | null,
  rejectWithValue: any
): Promise<ICountryListItem[]> => {
  if (!projectId) {
    return Promise.resolve([])
  }
  const url = `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.SyncWithSightline}`
  try {
    return (await api.get(url)).data
  } catch (error: any) {
    if (error.code === ResponseCode.Unauthorized) {
      authorizeSightline(error.message, ESightlineRedirectAction.SyncWithSightline)
      return []
    } else {
      return rejectWithValue(error.message)
    }
  }
}
