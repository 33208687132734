import { useState } from 'react'
import { ActionButton } from '../../../../components/ActionButton'
import { EmptyState } from '../../../../components/EmptyState'
import { SDKAccordion } from '../../../../components/SDK/Accordion'
import { SDKAccordionItem } from '../../../../components/SDK/AccordionItem'
import { SDKText } from '../../../../components/SDK/Text'
import { useApp } from '../../../../context/app.context'
import { useMyRole } from '../../../../context/hooks/useMyRole'
import type { IProjectResultByCountry } from '../../types/projectResults'
import { ResultTabAccordionContent } from '../ResultTabAccordionContent'
import { CountryContactInformation } from './components/CountryContactInformation'

interface IResultsByCountryProps {
  activeOption: number
  countries: IProjectResultByCountry[]
  yearId?: number
}

export const ResultsByCountry = ({ activeOption, countries, yearId }: IResultsByCountryProps) => {
  const [countryId, setCountryId] = useState<number | null>(null)
  const {
    appContext: { projectYearId }
  } = useApp()
  const { amIExternal } = useMyRole()
  const isMoreIconHidden = amIExternal

  return (
    <>
      {!!countries.length &&
        countries.map((country, index) => (
          <SDKAccordion
            className='project-results-accordion ap-my-spacing-3 results-by-country'
            multiple={true}
            key={`results-tab-by-country-list-element-${index}`}
          >
            <SDKAccordionItem
              templateHeader={() => (
                <>
                  <SDKText type='subheading'>{country.countryName}</SDKText>
                  {!isMoreIconHidden && (
                    <ActionButton
                      icon='circle-more'
                      kind='neutral'
                      content=''
                      handleClick={(e: React.MouseEvent) => {
                        e.stopPropagation()
                        setCountryId(country.countryId)
                      }}
                    />
                  )}
                </>
              )}
              itemKey={String(index)}
            >
              {country.answers.length ? (
                <ResultTabAccordionContent answers={country.answers} activeOption={activeOption} />
              ) : (
                <EmptyState />
              )}
            </SDKAccordionItem>
          </SDKAccordion>
        ))}
      {!countries.length && <EmptyState />}
      {countryId && (projectYearId || yearId) && (
        <CountryContactInformation
          countryId={countryId}
          isVisible={Boolean(countryId)}
          setIsVisible={setCountryId}
          yearId={(projectYearId || yearId)!}
        />
      )}
    </>
  )
}
