import queryString from 'query-string'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Layout } from '../../../../components/Layout'
import { SDKLoading } from '../../../../components/SDK/Loading'
import { Url } from '../../../../constants/urls'
import { LoadingStatus } from '../../../../shared/types/enums'
import { useAppDispatch } from '../../../../store'
import { getQuestionnaireCountryByName } from '../../../questionnaires/store/selectors'
import * as selectors from '../../store/selectors'
import type { IQuestionnaireQuestion, TFilter } from '../../type'
import { setQueryString } from '../../utils/setQueryString'
import * as actions from './../../store/actions'
import { AnswerView } from './components/AnswerView'
import ListHeaderButtons from './components/ListHeaderButtons'
import { EQuestionStates, EQuestionTypes } from './components/ListHeaderButtons/constants'
import QuestionsList from './components/QuestionsList'

const QuestionnaireQuestions = () => {
  const { t } = useTranslation('questionnaires')
  const { year: yearName, country } = useParams()
  const defaultFilters: TFilter = {
    states: [],
    types: [],
    subcategoryIds: []
  }
  const dispatch = useAppDispatch()
  const [questionFilters, setQuestionFilters] = useState<TFilter>(defaultFilters)
  const [hasUnansweredQuestions, setHasUnansweredQuestions] = useState(false)
  const [questionsView, setQuestionsView] = useState(true)
  const [shouldShowLoading, setShouldShowLoading] = useState(true)
  const [questions, setQuestions] = useState<IQuestionnaireQuestion[]>([])
  const [question, setQuestion] = useState<IQuestionnaireQuestion | null>(null)
  const [query, setQuery] = useState<string | null>(null)
  const { data, loading, error } = useSelector(selectors.getQuestionnaireQuestions)
  const currentCountry = useSelector(getQuestionnaireCountryByName(country!))
  const currentYear = useSelector(selectors.getQuestionnaireYearByName(yearName!))
  const { loading: questionnaireCountriesLoading } = useSelector(
    selectors.getQuestionnaireCountries
  )
  const { loading: questionnaireYearsLoading } = useSelector(selectors.getQuestionnaireYears)

  const navigationStrategy = useRef({
    reload: false
  })
  const navigate = useNavigate()
  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = () => {
    setScrollPosition(window.scrollY)
  }

  useEffect(() => {
    if (!currentYear) {
      dispatch(actions.getQuestionnaireYears())
    }

    if (!currentCountry && currentYear?.yearId) {
      dispatch(actions.getQuestionnaireCountries(currentYear.yearId))
    }
  }, [currentYear, currentCountry])

  useEffect(() => {
    if (questionnaireYearsLoading !== LoadingStatus.Succeeded) {
      return
    }

    if (!currentYear) {
      navigate(Url.QuestionnairesPage)
    }
    if (questionnaireCountriesLoading !== LoadingStatus.Succeeded) {
      return
    }
    if (!currentCountry) {
      navigate(Url.QuestionnairesPage)
    }
  }, [
    questionnaireCountriesLoading,
    questionnaireYearsLoading,
    yearName,
    currentYear,
    country,
    currentCountry
  ])

  const getFiltersFromQuery = (needAnswer: boolean) => {
    if (location.search) {
      const currentQueryString = queryString.parse(location.search)
      const states = Object.keys(EQuestionStates).filter(s =>
        Object.keys(currentQueryString).some(e => s === e)
      )
      const types = Object.keys(EQuestionTypes).filter(s =>
        Object.keys(currentQueryString).some(e => s === e)
      )
      let subcategoryIds: number[] = []
      if (currentQueryString.subcategoryIds) {
        subcategoryIds =
          typeof currentQueryString.subcategoryIds !== 'string'
            ? currentQueryString.subcategoryIds.map(id => Number(id))
            : [Number(currentQueryString.subcategoryIds)]
      }
      return {
        states,
        types,
        subcategoryIds,
        needAnswer: !!currentQueryString.UnansweredOnly || needAnswer
      }
    }
    return { ...defaultFilters, needAnswer }
  }

  const onApply = (filters: TFilter, needAnswer: boolean) => {
    if (!currentCountry) {
      return
    }

    const newQuery = setQueryString(filters, needAnswer)

    dispatch(
      actions.getQuestionnaireQuestions({
        yearId: currentCountry.yearId,
        countryId: currentCountry.countryId,
        query: newQuery
      })
    )
    setQuery(newQuery)
    setQuestionFilters(filters)
  }

  useEffect(() => {
    ;(async () => {
      const isReload = navigationStrategy.current.reload
      if (isReload) {
        const filtersFromQuery = getFiltersFromQuery(false)
        setHasUnansweredQuestions(filtersFromQuery.needAnswer)
        onApply(filtersFromQuery, filtersFromQuery.needAnswer)
        setQuestionFilters(filtersFromQuery)
      } else {
        const filtersFromQuery = getFiltersFromQuery(true)
        if (currentCountry) {
          const responseFromBE = await dispatch(
            actions.getQuestionnaireQuestions({
              yearId: currentCountry.yearId,
              countryId: currentCountry.countryId,
              query: setQueryString(filtersFromQuery, filtersFromQuery.needAnswer)
            })
          )

          const allQuestionsAnswered =
            Array.isArray(responseFromBE.payload) && !responseFromBE.payload.length

          setHasUnansweredQuestions(!allQuestionsAnswered)
          onApply(filtersFromQuery, !allQuestionsAnswered)
          setQuestionFilters(filtersFromQuery)
        }
      }
      setShouldShowLoading(false)
    })()
  }, [currentCountry])

  const pageWasReloaded = () => {
    sessionStorage.setItem('enterType', 'reload')
  }

  useEffect(() => {
    window.addEventListener('beforeunload', pageWasReloaded)
    if (sessionStorage.getItem('enterType') === 'reload') {
      sessionStorage.removeItem('enterType')
      navigationStrategy.current.reload = true
    }

    return () => {
      window.removeEventListener('beforeunload', pageWasReloaded)
    }
  }, [])

  useEffect(() => {
    if (location.search !== query && query != null) {
      window.history.pushState(null, '', query?.length ? query : '?')
    }
  }, [query])

  useEffect(() => {
    setQuestions(data)
  }, [data])

  const onBackButton = (e: Event) => {
    e.preventDefault()
    setQuestionsView(true)
  }

  useEffect(() => {
    if (!questionsView) {
      window.removeEventListener('scroll', handleScroll)
      window.addEventListener('popstate', onBackButton)
    } else {
      window.removeEventListener('popstate', onBackButton)
      window.addEventListener('scroll', handleScroll, { passive: true })
      window.scrollTo(0, scrollPosition)
    }
    return () => {
      window.removeEventListener('popstate', onBackButton)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [questionsView])

  useEffect(() => {
    if (questionsView) {
      window.scrollTo(0, scrollPosition)
    }
  }, [loading])

  const onHasUnansweredQuestionsChange = (state: boolean) => {
    setHasUnansweredQuestions(state)
    const filtersFromQuery = getFiltersFromQuery(true)
    onApply(filtersFromQuery, state)
  }

  const onEdit = (id: number) => {
    setQuestionsView(false)
    window.history.pushState(null, '', query)
    const selectedQuestion = data.find(q => q.questionId === id)
    if (selectedQuestion) {
      setQuestion(selectedQuestion)
    }
  }

  if (questionsView) {
    return (
      <Layout
        title={`${t('year')} ${yearName} ${country}`}
        buttonComponent={
          <ListHeaderButtons
            country={currentCountry}
            questionFilters={questionFilters}
            onApply={filters => onApply(filters, hasUnansweredQuestions)}
          />
        }
      >
        {shouldShowLoading ? (
          <SDKLoading />
        ) : (
          <QuestionsList
            onEdit={onEdit}
            questions={questions}
            loading={loading}
            error={error}
            hasUnansweredQuestions={hasUnansweredQuestions}
            onHasUnansweredQuestionsChange={onHasUnansweredQuestionsChange}
          />
        )}
      </Layout>
    )
  } else {
    return (
      <AnswerView
        countryId={currentCountry?.countryId}
        onAnswerSave={() => setQuestionsView(true)}
        query={query}
        question={question}
        yearId={currentCountry?.yearId}
      />
    )
  }
}

export default QuestionnaireQuestions
