import { generateFileFromUrl } from '../../../shared/utils/generateFileFromUrl'
import { CalculationUrl } from '../../constants/urls'

export const getPowerBICalculationResultByTemplateId = async (
  calculationId: number,
  templateId: number
): Promise<File> => {
  const url = `${CalculationUrl.Calculation}/${calculationId}/${CalculationUrl.Reports}/${CalculationUrl.PowerBI}/${templateId}`
  return generateFileFromUrl(url)
}
