import api from '../../api'
import { DataExportUrl, ProjectsUrl } from '../../constants/urls'
import { generateFile } from '../../utils/generateFile'

const FILE_NAME = 'RDP_Input_Template'
const FILE_EXTENSION = 'xlsx'

export const getDataExportInputTemplate = async (
  countryIds: number[],
  projectId: number
): Promise<File> => {
  const response = await api.post(
    `${DataExportUrl.DataExport}/${ProjectsUrl.Template}/${projectId}`,
    { countries: countryIds },
    { responseType: 'blob' }
  )
  response.data.lastModified = new Date()
  generateFile(response, `${FILE_NAME}.${FILE_EXTENSION}`)

  return response.data
}
