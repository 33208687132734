import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Module } from '../../../constants/modules'
import type { IAddFiscalYearPayload } from '../../../services/requests/fiscalYear/addFiscalYear'
import { addFiscalYear } from '../../../services/requests/fiscalYear/addFiscalYear'
import { deleteFiscalYear } from '../../../services/requests/fiscalYear/deleteFiscalYear'
import { deletePowerBITemplate } from '../../../services/requests/fiscalYear/deletePowerBITemplate'
import { getGateQuestionsData } from '../../../services/requests/fiscalYear/getGateQuestionsData'
import { getGateQuestionsExistsData } from '../../../services/requests/fiscalYear/getGateQuestionsExistsData'
import { getGateQuestionsPendingData } from '../../../services/requests/fiscalYear/getGateQuestionsPendingData'
import {
  getPBITemplateFileData,
  type IPBITemplateFileData
} from '../../../services/requests/fiscalYear/getPBITemplateData'
import { getPBITemplatesData } from '../../../services/requests/fiscalYear/getPBITemplatesData'
import { getPublishedYearsData } from '../../../services/requests/fiscalYear/getPublishedYearsData'
import { getYearsData } from '../../../services/requests/fiscalYear/getYearsData'
import { publishGateQuestionDraftRequest } from '../../../services/requests/fiscalYear/publishGateQuestionDraftRequest'
import { rejectGateQuestionDraftRequest } from '../../../services/requests/fiscalYear/rejectGateQuestionDraftRequest'
import type { IGateQuestionsDraftUrl } from '../../../services/requests/fiscalYear/types'
import type { IUpdateFiscalYearPayload } from '../../../services/requests/fiscalYear/updateFiscalYear'
import { updateFiscalYear } from '../../../services/requests/fiscalYear/updateFiscalYear'
import {
  updatePBITemplateRequest,
  type IEditPBITemplateUploadPayload
} from '../../../services/requests/fiscalYear/updatePBITemplate'
import { ETemplateType } from '../../project/components/GCDPricing/const/enums'
import {
  addPBITemplateRequest,
  type IAddPBITemplateUploadPayload
} from './../../../services/requests/fiscalYear/addPBITemplate'

const moduleName: string = String(Module.FiscalYear)

export const getYears = createAsyncThunk(`${moduleName}/getYears`, () => getYearsData())
export const clearGetYears = createAction(`${moduleName}/clearGetYears`)
export const getPublishedYears = createAsyncThunk(`${moduleName}/getPublishedYears`, () =>
  getPublishedYearsData()
)
export const clearGetPublishedYears = createAction(`${moduleName}/clearGetPublishedYears`)
export const addYear = createAsyncThunk(`${moduleName}/addYear`, (params: IAddFiscalYearPayload) =>
  addFiscalYear(params)
)
export const clearAddYear = createAction(`${moduleName}/clearAddYear`)
export const updateYear = createAsyncThunk(
  `${moduleName}/updateYear`,
  ({ params, yearId }: { params: IUpdateFiscalYearPayload; yearId: number }) =>
    updateFiscalYear(params, yearId)
)
export const clearUpdateYear = createAction(`${moduleName}/clearUpdateYear`)

export const deleteYear = createAsyncThunk(
  `${moduleName}/deleteYear`,
  ({ yearId }: { yearId: number }) => deleteFiscalYear(yearId)
)
export const clearDeleteYear = createAction(`${moduleName}/clearDeleteYear`)

export const addPBITemplate = createAsyncThunk(
  `${moduleName}/addPBITemplate`,
  ({
    identifier,
    name,
    templateType,
    templateFile,
    thumbnailFile,
    yearId
  }: IAddPBITemplateUploadPayload) =>
    addPBITemplateRequest({ identifier, name, templateType, templateFile, thumbnailFile, yearId })
)
export const clearAddPBITemplate = createAction(`${moduleName}/clearAddPBITemplate`)
export const getPBITemplates = createAsyncThunk(
  `${moduleName}/getPBITemplates`,
  ({ yearId, templateType }: { yearId: number; templateType?: ETemplateType }) =>
    getPBITemplatesData(yearId, templateType)
)
export const clearGetPBITemplates = createAction(`${moduleName}/clearGetPBITemplates`)
export const getPBITemplate = createAsyncThunk(
  `${moduleName}/getPBITemplate`,
  ({ fileName, templateId, yearId }: IPBITemplateFileData) =>
    getPBITemplateFileData({ fileName, templateId, yearId })
)
export const clearGetPBITemplate = createAction(`${moduleName}/clearGetPBITemplate`)
export const updatePBITemplate = createAsyncThunk(
  `${moduleName}/updatePBITemplate`,
  ({
    identifier,
    name,
    templateType,
    templateFile,
    templateId,
    thumbnailFile,
    yearId
  }: IEditPBITemplateUploadPayload) =>
    updatePBITemplateRequest({
      identifier,
      name,
      templateType,
      templateFile,
      templateId,
      thumbnailFile,
      yearId
    })
)
export const clearUpdatePBITemplate = createAction(`${moduleName}/clearUpdatePBITemplate`)
export const deletePBITemplate = createAsyncThunk(
  `${moduleName}/deletePBITemplate`,
  ({ templateId, yearId }: { templateId: number; yearId: number }) =>
    deletePowerBITemplate({ templateId, yearId })
)
export const clearDeletePBITemplate = createAction(`${moduleName}/clearDeletePBITemplate`)

export const getGateQuestions = createAsyncThunk(
  `${moduleName}/getGateQuestions`,
  (yearId: number) => getGateQuestionsData(yearId)
)
export const clearGetGateQuestions = createAction(`${moduleName}/clearGetGateQuestions`)
export const getGateQuestionsExists = createAsyncThunk(
  `${moduleName}/getGateQuestionsExists`,
  (yearId: number) => getGateQuestionsExistsData(yearId)
)
export const clearGetGateQuestionsExists = createAction(`${moduleName}/clearGetGateQuestionsExists`)
export const getGateQuestionsPending = createAsyncThunk(
  `${moduleName}/getGateQuestionsPending`,
  (props: IGateQuestionsDraftUrl) => getGateQuestionsPendingData(props)
)
export const clearGetGateQuestionsPending = createAction(
  `${moduleName}/clearGetGateQuestionsPending`
)
export const clearGetGateQuestionsAnswers = createAction(
  `${moduleName}/clearGetGateQuestionsAnswers`
)
export const publishGateQuestionDraft = createAsyncThunk(
  `${moduleName}/publishGateQuestion`,
  (props: IGateQuestionsDraftUrl) => publishGateQuestionDraftRequest(props)
)
export const clearPublishGateQuestionDraft = createAction(`${moduleName}/clearPublishGateQuestion`)
export const rejectGateQuestionDraft = createAsyncThunk(
  `${moduleName}/rejectGateQuestion`,
  (props: IGateQuestionsDraftUrl) => rejectGateQuestionDraftRequest(props)
)
export const clearRejectGateQuestionDraft = createAction(`${moduleName}/clearRejectGateQuestion`)
