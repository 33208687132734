export interface INewsfeed {
  id: number
  eventDescription: string
  eventDate: string
}

export interface INewsfeedResponse {
  totalCount: number
  newsfeedEntries: INewsfeed[]
}

export enum ESortType {
  Asc = 1,
  Desc = 0
}
