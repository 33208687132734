import { yupResolver } from '@hookform/resolvers/yup'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useApp } from '../../../../../../context/app.context'
import type { IFormValues } from '../../../../../../schemas/useUploadBySightlineWithCalculation'
import { useUploadBySightlineWithCalculation } from '../../../../../../schemas/useUploadBySightlineWithCalculation'
import { useAppDispatch } from '../../../../../../store'
import { ETARATab, ETARATabHashUrl } from '../../../../constants/tab'
import * as actions from '../../../../store/actions'
import { getUploadBySightlineWithCalculation } from '../../../../store/selectors'
import { CalculationActionType } from '../../UploadModal'

export const useUploadBySightline = (
  setActiveIndex: Dispatch<SetStateAction<number>>,
  setActiveType: Dispatch<SetStateAction<CalculationActionType | null>>
) => {
  const dispatch = useAppDispatch()
  const navigation = useNavigate()
  const { sendNotification } = useApp()
  const { project: projectId } = useParams()
  const [showUploadBySightlineModal, setShowUploadBySightlineModal] = useState(false)
  const { validationSchema, defaultValues } = useUploadBySightlineWithCalculation()
  const {
    data: uploadedTemplate,
    error,
    loading
  } = useSelector(getUploadBySightlineWithCalculation)

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm<FieldValues, IFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    if (uploadedTemplate) {
      dispatch(actions.getTemplateCalculationById(Number(projectId)))
      setActiveIndex(ETARATab.Results)
      setActiveType(CalculationActionType.Sightline)
      navigation(`${location.pathname}#${ETARATabHashUrl.Results}`, {
        state: { projectId }
      })
    }
  }, [uploadedTemplate])

  useEffect(() => {
    if (error && error?.length > 0) {
      sendNotification({
        message: error,
        status: 'error'
      })
    }
  }, [error])

  const onSubmit = (data: FieldValues) => {
    if (projectId) {
      dispatch(
        actions.uploadBySightlineWithCalculation({
          sightlineInputFileId: data.sightlineInputFileId,
          dateOnly: data.dateOnly,
          projectId: Number(projectId)
        })
      )
      setShowUploadBySightlineModal(false)
    }
  }

  return {
    uploadBySightline: handleSubmit(onSubmit),
    showUploadBySightlineModal,
    setShowUploadBySightlineModal,
    control,
    isValid,
    loading
  }
}
