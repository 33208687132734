import { EPricingTab } from '../../../modules/project/components/GCDPricing/const/enums'
import type { IUpdateChildScope } from '../../../modules/project/components/GCDPricing/types/scope'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export interface IChildScopeOrderParams {
  childScopeId: number
  customModuleId?: number | null
  order: number
  scopeType: number
}

export const updateChildScopeOrderRequest = async (
  projectId: number,
  childScopeId: number,
  tabName: EPricingTab,
  params: IChildScopeOrderParams
): Promise<IUpdateChildScope> => {
  let url = `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/`

  if (params.customModuleId) {
    url += `${ProjectsUrl.Modules}/${ProjectsUrl.CustomModules}/${params.customModuleId}/${ProjectsUrl.ChildScopes}/${childScopeId}`
  } else {
    url += `${ProjectsUrl.Modules}/${tabName}/${ProjectsUrl.ChildScopes}/${childScopeId}`
  }

  return (await api.patch(url, params)).data
}
