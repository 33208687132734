import { EPricingTab } from '../../../modules/project/components/GCDPricing/const/enums'
import { IProjectManagementData } from '../../../modules/project/components/GCDPricing/types/inputsTab'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const getProjectManagementData = async (
  projectId: number
): Promise<IProjectManagementData> => {
  return (
    await api.get(
      `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/${ProjectsUrl.Modules}/${EPricingTab.ProjectManagement}`
    )
  ).data
}
