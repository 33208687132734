import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { SDKButton } from '../../../../../../components/SDK/Button'
import { SDKIcon } from '../../../../../../components/SDK/Icon'
import { SDKModal } from '../../../../../../components/SDK/Modal'
import { useApp } from '../../../../../../context/app.context'
import type { IGateQuestionsDraftUrl } from '../../../../../../services/requests/fiscalYear/types'
import { LoadingStatus } from '../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../store'
import * as actions from '../../../../../fiscalYearSetup/store/actions'
import * as selectors from '../../../../../fiscalYearSetup/store/selectors'
import styles from './index.module.scss'

export const GateQuestionsButtons = ({
  countryId,
  draftAnswerId,
  gateQuestionId,
  yearId,
  editMode
}: IGateQuestionsDraftUrl & { editMode: boolean }) => {
  const { t } = useTranslation('fiscalYearDetails')
  const dispatch = useAppDispatch()
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [showPublishModal, setShowPublishModal] = useState(false)
  const { sendNotification } = useApp()
  const publish = useSelector(selectors.publishGateQuestionDraft)
  const reject = useSelector(selectors.rejectGateQuestionDraft)

  useEffect(() => {
    if (publish.error) {
      sendNotification({ message: publish.error, status: 'error' })
    }

    if (publish.loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t('gateQuestions.list.confirmationModals.publish.success'),
        status: 'success'
      })
      dispatch(actions.clearPublishGateQuestionDraft())
    }
  }, [publish])

  useEffect(() => {
    if (reject.error) {
      sendNotification({ message: reject.error, status: 'error' })
    }

    if (reject.loading === LoadingStatus.Succeeded && !editMode) {
      sendNotification({
        message: t('gateQuestions.list.confirmationModals.reject.success'),
        status: 'success'
      })
      dispatch(actions.clearRejectGateQuestionDraft())
    }
  }, [reject])

  return (
    <div className={styles['button-group']}>
      <SDKButton kind='secondary' onClick={() => setShowRejectModal(true)}>
        <SDKIcon code={'circle-delete'}></SDKIcon>
        {t('gateQuestions.list.buttons.reject')}
      </SDKButton>

      <SDKButton onClick={() => setShowPublishModal(true)}>
        <SDKIcon code={'circle-checkmark'}></SDKIcon>
        {t('gateQuestions.list.buttons.publish')}
      </SDKButton>

      <SDKModal
        visible={showRejectModal}
        onCancel={() => setShowRejectModal(false)}
        title={t('gateQuestions.list.confirmationModals.reject.title')!}
        withRequired={false}
        withCancel={true}
        onSubmit={() => {
          dispatch(
            actions.rejectGateQuestionDraft({
              yearId,
              countryId,
              gateQuestionId,
              draftAnswerId
            })
          ).then(() => {
            dispatch(actions.clearGetGateQuestions())
            dispatch(actions.getGateQuestions(yearId))
          })
          setShowRejectModal(false)
        }}
        submitLabel={t('gateQuestions.list.confirmationModals.reject.button')!}
      >
        {t('gateQuestions.list.confirmationModals.reject.content')}
      </SDKModal>

      <SDKModal
        visible={showPublishModal}
        onCancel={() => setShowPublishModal(false)}
        title={t('gateQuestions.list.confirmationModals.publish.title')!}
        withRequired={false}
        withCancel={true}
        onSubmit={() => {
          dispatch(
            actions.publishGateQuestionDraft({
              yearId,
              countryId,
              gateQuestionId,
              draftAnswerId
            })
          ).then(() => {
            dispatch(actions.clearGetGateQuestions())
            dispatch(actions.getGateQuestions(yearId))
          })

          setShowPublishModal(false)
        }}
        submitLabel={t('gateQuestions.list.confirmationModals.publish.button')!}
      >
        {t('gateQuestions.list.confirmationModals.publish.content')}
      </SDKModal>
    </div>
  )
}
