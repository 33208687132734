import { EPricingTab } from '../../../modules/project/components/GCDPricing/const/enums'
import { EParentScopeModalType } from '../../../modules/project/components/GCDPricing/modals/ParentScopeModal/const'
import {
  TParentScope,
  TParentScopePayload
} from '../../../modules/project/components/GCDPricing/types/scope'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const createParentScopeRequest = async (
  projectId: number,
  tab: EPricingTab,
  params: Partial<TParentScopePayload>
): Promise<TParentScope> => {
  let url = `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/`

  if (params.type === EParentScopeModalType.CustomModule) {
    url += `${ProjectsUrl.Modules}/${ProjectsUrl.CustomModules}/${params.customModuleId}/${ProjectsUrl.ParentScopes}`
  } else {
    url += `${ProjectsUrl.Modules}/${tab}/${ProjectsUrl.ParentScopes}`
  }

  return (await api.post(url, params)).data
}
