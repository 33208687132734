import classNames from 'classnames'
import type { IUserBase } from '../../../types/user'
import { getFullname } from '../../../utils/user'
import { SDKPanel } from '../../SDK/Panel'
import { SDKParagraph } from '../../SDK/Paragraph'
import UserAvatar from '../../UserAvatar'
import { CardActionButtonGroup } from '../components/CardActionButtonGroup'
import { EAction } from '../const'
import type { IActionCallback } from '../type'
import styles from './UserCard.module.scss'

export const UserCard = ({
  actions,
  actionCallback,
  user
}: {
  actions: EAction[]
  actionCallback: (_callback: IActionCallback) => void
  user: IUserBase
  className?: string
}) => {
  return (
    <SDKPanel className='user-card'>
      <div className='ap-flex w-100 justify-content-between'>
        <div className='ap-flex flex-row'>
          <div className='ap-mr-spacing-4'>
            <UserAvatar user={user} />
          </div>
          <div className={classNames(styles['word-break'], 'ap-flex flex-column')}>
            <SDKParagraph weight={2}>{getFullname(user)}</SDKParagraph>
            <SDKParagraph type='body-s'>{user.email}</SDKParagraph>
          </div>
        </div>

        <CardActionButtonGroup actions={actions} id={user.userId} actionCallback={actionCallback} />
      </div>
    </SDKPanel>
  )
}
