import { useEffect, useState } from 'react'
import type { Control, UseFormClearErrors } from 'react-hook-form'
import { useController } from 'react-hook-form'
import {
  EAddEditCountryFields,
  IFormValues
} from '../../../../../../../../../schemas/useAddEditCountrySchema'

export const useNoticeVisble = (
  fieldName: EAddEditCountryFields,
  control: Control<IFormValues>,
  clearErrors: UseFormClearErrors<IFormValues>
) => {
  const {
    formState: { errors, isSubmitted },
    field: { value },
    fieldState: { isDirty }
  } = useController({ name: fieldName, control })
  const [isNoticeVisible, setNoticeVisible] = useState(false)

  useEffect(() => {
    const defaultNoticeStatus = Boolean(
      errors[EAddEditCountryFields.regionTara] ||
        errors[EAddEditCountryFields.regionGCD] ||
        errors[EAddEditCountryFields.regionBenchmark]
    )

    if (defaultNoticeStatus) {
      setNoticeVisible(true)
    }
    if (defaultNoticeStatus && isSubmitted && isDirty) {
      clearErrors(fieldName)
    }
    if (
      !errors[EAddEditCountryFields.regionTara] &&
      !errors[EAddEditCountryFields.regionGCD] &&
      !errors[EAddEditCountryFields.regionBenchmark]
    ) {
      setNoticeVisible(false)
    }
  }, [errors, fieldName, value, isDirty])

  return isNoticeVisible
}
