import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { SDKColumn } from '../../../../../../components/SDK/Column'
import { SDKTableSeparatedByLines } from '../../../../../../components/SDK/TableSeparatedByLines'
import { SDKText } from '../../../../../../components/SDK/Text'
import { useApp } from '../../../../../../context/app.context'
import { TTableValue } from '../../types'

export const ChangedValuesTable = ({ originalData }: { originalData: TTableValue[] }) => {
  const { t } = useTranslation('myprojects')
  const {
    appContext: {
      style: { isMobile }
    }
  } = useApp()

  const COLUMNS = [
    {
      key: 'item',
      withMargin: true,
      renderCell: (row: TTableValue) => <SDKText weight={2}>{row.item}</SDKText>
    },
    { key: 'oldValue', withMargin: true },
    { key: 'newValue', withMargin: false }
  ]

  const mobileColumn = (element: TTableValue) =>
    COLUMNS.map(column => (
      <div
        className={classNames(
          'ap-flex flex-column break-word',
          column.withMargin && 'ap-mb-spacing-3'
        )}
        key={`mobile-column-${column.key}`}
      >
        <SDKText className='ap-text-neutral-14'>{t(`changedValues.table.${column.key}`)}</SDKText>
        <SDKText weight={2}>{element[column.key as keyof TTableValue]}</SDKText>
      </div>
    ))

  const desktopColumn = () =>
    COLUMNS.map(column => (
      <SDKColumn
        field={column.key}
        renderCell={column.renderCell}
        key={`desktop-column-${column.key}`}
      >
        <SDKText className='ap-text-neutral-14'>{t(`changedValues.table.${column.key}`)}</SDKText>
      </SDKColumn>
    ))

  return (
    <>
      {isMobile ? (
        originalData.map(element => (
          <div
            className='border-1 ap-border-color-background-border ap-my-spacing-4 ap-p-spacing-4 ap-border-radius-2'
            key={`originalData-${element.item}`}
          >
            {mobileColumn(element)}
          </div>
        ))
      ) : (
        <SDKTableSeparatedByLines originalData={originalData} hasTitle>
          {desktopColumn()}
        </SDKTableSeparatedByLines>
      )}
    </>
  )
}
