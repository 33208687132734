import { RootState } from '../../../../../store'

export const getCustomModules = (state: RootState) => state.Pricing.customModules
export const createCustomModule = (state: RootState) => state.Pricing.createCustomModule
export const getScopeDefinitionData = (state: RootState) => state.Pricing.scopeDefinition
export const getProjectSetup = (state: RootState) => state.Pricing.projectSetup
export const getMasterFile = (state: RootState) => state.Pricing.masterFile
export const getLocalFile = (state: RootState) => state.Pricing.localFile
export const getBenchmark = (state: RootState) => state.Pricing.benchmark
export const getTPForms = (state: RootState) => state.Pricing.tpForms
export const getCbCr = (state: RootState) => state.Pricing.cbCr
export const getDataCollection = (state: RootState) => state.Pricing.dataCollection
export const getScopeAssumptions = (state: RootState) => state.Pricing.scopeAssumptions
export const getProjectManagement = (state: RootState) => state.Pricing.projectManagement
export const getDiscount = (state: RootState) => state.Pricing.discount
export const getCustomModule = (state: RootState) => state.Pricing.customModule
export const getPricingResults = (state: RootState) => state.Pricing.pricingResults
export const exportPricingResultsFlat = (state: RootState) => state.Pricing.pricingResultsExportFlat
export const exportPricingResultsFull = (state: RootState) => state.Pricing.pricingResultsExportFull
export const exportPricingResultsWithSightline = (state: RootState) =>
  state.Pricing.pricingResultsExportWithSightline
