import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { HeaderButtons } from '../../components/HeaderButtons'
import { Layout } from '../../components/Layout'
import { LoadingOverlay } from '../../components/SDK/LoadingOverlay'
import { useApp } from '../../context/app.context'
import { LoadingStatus } from '../../shared/types/enums'
import { useAppDispatch } from '../../store'
import { AddEditUserModal } from './components/AddEditUserModal'
import { EModalMode } from './components/AddEditUserModal/const'
import { UserList } from './components/UserList'
import { MINIMAL_SEARCH_VALUE } from './const'
import * as actions from './store/actions'
import * as selectors from './store/selectors'
import { useAddEditUser } from './utils/useAddEditUser'

const Users = () => {
  const { t } = useTranslation('users')
  const [userSearch, setUserSearch] = useState<string>('')
  const [modalMode, setModalMode] = useState<EModalMode | null>(null)
  const resource = useSelector(selectors.getUsers)
  const { data: userData, loading: userLoading, error: userError } = useSelector(selectors.getUser)
  const dispatch = useAppDispatch()
  const { clearModal, modal, onAdd, onSyncUsers, onSelectUser, selectedUserId } = useAddEditUser()
  const { sendNotification } = useApp()

  useEffect(() => {
    if (userSearch.length >= MINIMAL_SEARCH_VALUE) {
      dispatch(actions.getUsers(userSearch))
    } else {
      dispatch(actions.clearGetUsers())
    }
  }, [userSearch])

  useEffect(() => {
    if (!!userData?.userId) {
      setModalMode(userData.isServiceAccount ? EModalMode.EditServiceAccount : EModalMode.EditUser)
    }
  }, [userData])

  useEffect(() => {
    if (selectedUserId) {
      dispatch(actions.getUser(selectedUserId))
    } else {
      dispatch(actions.clearGetUser())
    }
  }, [selectedUserId])

  useEffect(() => {
    if (resource.error?.length) {
      sendNotification({
        message: resource.error,
        status: 'error'
      })
    }
  }, [resource.error])

  useEffect(() => {
    if (userError?.length) {
      sendNotification({
        message: userError,
        status: 'error'
      })
      clearModal()
    }
  }, [userError])

  useEffect(() => {
    if (modal.error?.length) {
      sendNotification({
        message: modal.error,
        status: 'error'
      })
      clearModal()
    }
  }, [modal.error])

  useEffect(() => {
    if (modal.loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t(
          `userForm.${selectedUserId ? 'updateSuccessInfo' : 'createServiceAccountSuccessInfo'}`
        ),
        status: 'success'
      })
      setModalMode(null)
      clearModal()
    }
  }, [modal])

  const isLoading = useMemo(
    () => modal.loading === LoadingStatus.Pending || userLoading === LoadingStatus.Pending,
    [modal.loading, userLoading]
  )

  return (
    <Layout
      buttonComponent={
        <>
          <HeaderButtons
            icon='plus'
            name={t('newUserButton')}
            withAdditionalButton={true}
            additionalButtonIcon='transform'
            additionalButtonName={t('syncUserButton')!}
            additionalButtonOnClick={() => {
              onSyncUsers()
            }}
            onClick={() => {
              setModalMode(EModalMode.CreateServiceAccount)
              onAdd()
            }}
          />
        </>
      }
    >
      <UserList
        list={resource.data}
        loading={resource.loading}
        onSelectUser={selectedId => {
          onSelectUser(selectedId)
        }}
        onUserSearch={setUserSearch}
        userSearch={userSearch}
      />

      <AddEditUserModal
        modalMode={modalMode}
        onCloseModal={() => {
          setModalMode(null)
          clearModal()
        }}
        user={userData}
      />

      {isLoading && <LoadingOverlay />}
    </Layout>
  )
}

export default Users
