import classNames from 'classnames'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { SDKText } from '../../../../../../components/SDK/Text'
import { useCardGridColumnCalculation } from '../../../../../../utils/useCardGridColumnCalculation'
import style from '../../index.module.scss'
import { EBenchmarkType, TableType } from '../../types/dnd'
import type { IBenchmarkQuotes, ITransactionalModule } from '../../types/inputsTab'
import type { IParentScopeYearTotal } from '../../types/scope'
import { FeeQuote } from '../FeeQuote'
import { FeesList } from '../FeesList'
import { TransactionList } from './TransactionList'

export enum ERole {
  Child = 'child',
  Parent = 'parent'
}

interface IScopesWrapperProps {
  children: JSX.Element | ReactNode
  classes: string
  role: ERole
  title: string
  benchmarkFees?: IBenchmarkQuotes
  benchmarkName?: EBenchmarkType
  fees?: IParentScopeYearTotal[]
  showTransactionList?: boolean
  transactionalModules?: ITransactionalModule[]
  transactionalModulesTotals?: IParentScopeYearTotal[]
  countryHeader?: boolean
  customName?: string
  withoutFeesResult?: boolean
  withoutEnableSwitch?: boolean
  withoutButtons?: boolean
  gridValues?: number[]
  textClass?: string
  flexStyles?: string
  discountModule?: boolean
}
export const ScopeWrapper = ({
  children,
  title,
  fees,
  benchmarkFees,
  benchmarkName,
  customName,
  role,
  classes,
  countryHeader,
  transactionalModules,
  transactionalModulesTotals,
  withoutFeesResult = false,
  withoutEnableSwitch = false,
  withoutButtons = false,
  gridValues,
  textClass,
  flexStyles,
  discountModule
}: IScopesWrapperProps) => {
  const { textCol } = useCardGridColumnCalculation()
  const { t } = useTranslation('pricing')

  const countryHeaderComponent = (
    <div className='row w-100'>
      <div className='col col-4 ap-pl-spacing-5'>
        <SDKText type='subheading' weight={2}>
          {t(`tabs.inputs.country`)}
        </SDKText>
      </div>
      <div className='col col-8'>
        <SDKText type='subheading' weight={2}>
          {t(`tabs.inputs.tpFormRule`)}
        </SDKText>
      </div>
    </div>
  )

  const benchmarkTypeFees =
    benchmarkName === EBenchmarkType.GlobalBenchmarking
      ? benchmarkFees?.globalScopeYearTotals
      : benchmarkName === EBenchmarkType.LocalBenchmarking
      ? benchmarkFees?.localScopeYearTotals
      : benchmarkFees?.regionalScopeYearTotals

  const currentFees = benchmarkFees ? benchmarkTypeFees : fees
  const scopeExcludesStyles = `${
    title === TableType.ScopeExcludes
      ? 'ap-bg-color-background-default ap-pt-spacing-5 ap-pl-spacing-5'
      : ''
  } `

  return (
    <div
      className={classNames(
        classes,
        role === ERole.Child ? 'ap-bg-color-background-default' : 'ap-bg-color-background-alt',
        title === TableType.ScopeExcludes ? 'ap-px-spacing-4 ap-pb-spacing-4' : 'ap-p-spacing-4',
        'ap-border-radius-2 border-1 ap-border-color-background-border'
      )}
    >
      <div className={`${scopeExcludesStyles} ap-flex`}>
        <div className='row ap-text-neutral-17 w-100'>
          <div className={`col col-${gridValues ? gridValues[0] : textCol} ap-pb-spacing-5`}>
            {countryHeader ? (
              countryHeaderComponent
            ) : (
              <SDKText type='subheading' weight={2}>
                {title === TableType.Scope && customName ? customName : benchmarkName || title}
              </SDKText>
            )}
          </div>
          {currentFees && (
            <FeesList
              fees={currentFees}
              keyItem='title'
              weight={2}
              gridValues={gridValues}
              flexStyles={flexStyles}
              textClassNames={textClass}
              discountModule={discountModule}
            />
          )}
        </div>

        <div
          className={
            style[
              withoutButtons
                ? 'no-buttons'
                : withoutEnableSwitch
                ? 'action-single-button-width'
                : 'action-buttons-width'
            ]
          }
        ></div>
      </div>
      <div>{children}</div>
      {transactionalModules && title === TableType.Scope && (
        <TransactionList
          transactionalModules={transactionalModules}
          transactionalModulesTotals={transactionalModulesTotals}
        />
      )}
      {fees && !withoutFeesResult && <FeeQuote fees={fees} withoutButtons={withoutButtons} />}
      {benchmarkTypeFees && <FeeQuote fees={benchmarkTypeFees} />}
    </div>
  )
}
