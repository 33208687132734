import { useCallback, useEffect } from 'react'
import type { UseFormReset, UseFormSetError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { EAddEditRegionFields, IFormValues } from '../../../../../schemas/useAddEditRegionSchema'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../store'
import { getYearByName } from '../../../../fiscalYearSetup/store/selectors'
import * as actions from '../../../store/actions'
import { getAddRegion, getEditRegion } from '../../../store/selectors'

export const useAddEditRegion = (
  regionContainer: string,
  setVisible: (value: boolean) => void,
  reset: UseFormReset<IFormValues>,
  regionId: number | null,
  setError: UseFormSetError<{
    region: string
  }>
) => {
  const { t } = useTranslation('regions')
  const dispatch = useAppDispatch()
  const { year: yearName } = useParams()
  const year = useSelector(getYearByName(yearName!))
  const { loading: addLoading, error: addError } = useSelector(getAddRegion)
  const { loading: editLoading, error: editError } = useSelector(getEditRegion)
  const loading = regionId ? editLoading : addLoading
  const error = regionId ? editError : addError

  const onClose = useCallback(() => {
    setVisible(false)
    reset()
  }, [setVisible, reset])

  const onSubmit = (data: IFormValues) => {
    const mappedData = {
      name: data.region,
      type: regionContainer,
      yearId: year?.yearId ? Number(year.yearId) : 0
    }
    if (regionId) {
      dispatch(
        actions.editRegion({
          yearId: mappedData.yearId,
          regionId,
          params: { name: mappedData.name }
        })
      )
    } else {
      dispatch(actions.addRegion(mappedData))
    }
  }

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded && year) {
      dispatch(actions.getRegions(year.yearId))
      onClose()
    }
  }, [loading])

  useEffect(() => {
    if (error?.length && error.includes('already exist')) {
      setError(EAddEditRegionFields.region, { message: t('newRegion.form.errors.regionExist')! })
    }
  }, [error])

  return { loading, onSubmit, onClose }
}
