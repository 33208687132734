import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { object, string } from 'yup'
import * as selectors from '../modules/countriesSnapshot/store/selectors'

export enum EAddCategoryFields {
  category = 'category'
}
export interface IFormValues {
  [EAddCategoryFields.category]: string
}

export const useAddCategorySchema = (categoryId: number | null) => {
  const { t } = useTranslation('countriesSnapshot')
  const category = useSelector(selectors.getCategoryById(categoryId))

  const defaultValues = {
    [EAddCategoryFields.category]: category?.name || ''
  }
  const validationSchema = object()
    .shape({
      [EAddCategoryFields.category]: string().required(t('newCategory.form.errors.category')!)
    })
    .required()

  return { validationSchema, defaultValues }
}
