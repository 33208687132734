import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { NavigationTileGrid } from '../../../../components/NavigationTileGrid'
import { LoadingSpinner } from '../../../../components/SDK/LoadingSpinner'
import { TileAdditionalInfo } from '../../../../components/TileAdditionalInfo'
import { Url } from '../../../../constants/urls'
import { useFiscalYear } from '../../../../context/fiscalYear.context'
import { LoadingStatus } from '../../../../shared/types/enums'
import { useAppDispatch } from '../../../../store'
import * as actions from '../../../fiscalYearSetup/store/actions'
import * as selectors from '../../../fiscalYearSetup/store/selectors'

export const FiscalYearDetailsList = () => {
  const { t } = useTranslation('fiscalYearDetails')
  const location = useLocation()
  const { fiscalYearInfo } = useFiscalYear()
  const dispatch = useAppDispatch()
  const existsRequest = useSelector(selectors.getGateQuestionsExists)

  useEffect(() => {
    if (fiscalYearInfo) {
      dispatch(actions.getGateQuestionsExists(fiscalYearInfo.yearId))
    }
  }, [fiscalYearInfo])

  let list = [
    { icon: 'globe-map', name: t('regions'), url: `${location.pathname}${Url.RegionsPage}` },
    { icon: 'globe', name: t('countries'), url: `${location.pathname}${Url.CountriesPage}` },
    {
      icon: 'survey',
      name: t('countrySnapshot'),
      url: `${location.pathname}${Url.CountriesSnapshotPage}`
    },
    {
      icon: 'table-data',
      name: t('tara'),
      url: `${location.pathname}${Url.TaraFieldsPage}`
    },
    {
      additionalInfo:
        existsRequest.loading !== LoadingStatus.Succeeded ? (
          <>
            <LoadingSpinner />
          </>
        ) : !!existsRequest.data ? (
          <TileAdditionalInfo
            icon='survey-checkmark'
            text={t('gateQuestions.tile.additionalInfo')}
          />
        ) : undefined,
      icon: 'help-question',
      name: t('gateQuestions.tile.title'),
      url: `${location.pathname}${Url.GateQuestions}`
    },
    {
      icon: 'bar-chart',
      name: t('powerBITemplates'),
      url: `${location.pathname}${Url.PowerBITemplatesPage}`
    }
  ]

  return <NavigationTileGrid list={list} />
}
