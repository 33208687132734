import classNames from 'classnames'
import { ReactNode, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { useApp } from '../../context/app.context'
import { FiscalYearContextProvider } from '../../context/fiscalYear.context'
import { ProjectContextProvider } from '../../context/project.context'
import { useDetectIsMobile } from '../../utils/useDetectIsMobile'
import { AppFooter } from './components/AppFooter'
import { AppHeader } from './components/AppHeader'
import { AppNavigation } from './components/AppNavigation'
import { Breadcrumb } from './components/Breadcrumb'
import { CookiesPolicyApprovalModal } from './components/CookiesPolicyApprovalModal'
import { LegalStatementsApprovalModal } from './components/LegalStatementsApprovalModal'
import './index.scss'

interface ILayout {
  children: ReactNode
  blockBackNavigate?: boolean
  breadcrumbs?: { [x: string]: string }
  buttonComponent?: React.ReactNode
  hideBreadcrumb?: boolean
  mobileHeaderComponent?: React.ReactNode
  onClick?: () => void
  tab?: string
  title?: string
  type?: 'page' | 'page-form'
}

export const Layout = ({
  children,
  blockBackNavigate,
  breadcrumbs,
  buttonComponent,
  hideBreadcrumb,
  mobileHeaderComponent,
  onClick,
  tab,
  title,
  type = 'page'
}: ILayout) => {
  const {
    appContext: {
      style: { isMobile },
      user
    }
  } = useApp()
  const { isAuthenticated } = useAuth()
  const [sidebarVisibility, setSidebarVisibility] = useState(false)
  const [showApprovalModal, setShowApprovalModal] = useState(true)
  const [showCookiesPolicyApprovalModal, setShowCookiesPolicyApprovalModal] = useState(true)
  const isCookiesPolicyApprovalNeeded = !!user && !user.cookieSettings

  useDetectIsMobile()

  const navigation: ReactNode = (
    <div
      className={classNames({
        'nav-open ap-modal-wrapper show': isMobile && sidebarVisibility,
        mobile: isMobile
      })}
    >
      <AppNavigation
        setSidebarVisibility={() => isMobile && setSidebarVisibility(prevState => !prevState)}
        sidebarVisibility={sidebarVisibility}
      />
    </div>
  )

  return (
    <>
      <AppHeader setSidebarVisibility={() => setSidebarVisibility(prevState => !prevState)} />
      <div className='layout'>
        {isAuthenticated && navigation}

        <div className='layout-content-wrapper ap-text-color-background-alt-inverse ap-bg-color-background-default ap-container'>
          <div className='layout-content'>
            {isCookiesPolicyApprovalNeeded && (
              <CookiesPolicyApprovalModal
                visible={showCookiesPolicyApprovalModal}
                setVisible={setShowCookiesPolicyApprovalModal}
              />
            )}
            {isAuthenticated && user && !user.acceptedAllLegalDocuments ? (
              <LegalStatementsApprovalModal
                visible={showApprovalModal}
                setVisible={setShowApprovalModal}
              />
            ) : (
              <>
                {isAuthenticated && (
                  <Breadcrumb
                    blockBackNavigate={blockBackNavigate}
                    breadcrumbsReplace={breadcrumbs}
                    buttonComponent={buttonComponent}
                    hideBreadcrumb={hideBreadcrumb}
                    mobileHeaderComponent={mobileHeaderComponent}
                    onClick={onClick}
                    tab={tab}
                    title={title}
                  />
                )}
                <div className={`layout__${type}`}>{children}</div>
              </>
            )}
          </div>
          <AppFooter />
        </div>
      </div>
    </>
  )
}

export const FiscalYearLayout = (props: ILayout) => {
  return (
    <FiscalYearContextProvider>
      <Layout {...props} />
    </FiscalYearContextProvider>
  )
}

export const ProjectLayout = (props: ILayout) => {
  return (
    <ProjectContextProvider>
      <Layout {...props} />
    </ProjectContextProvider>
  )
}
