import queryString from 'query-string'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { HeaderButtons } from '../../../../components/HeaderButtons'
import { Url } from '../../../../constants/urls'
import { FiscalYearBase } from '../../../fiscalYearSetup/types'
import { getMyProjects } from '../../store/selectors'
import { getYearsFromProjects } from '../ProjectList/utils/getYearsFromProjects'

interface IProjectHeaderProps {
  onButtonClick: () => void
  showFilter?: () => void
}

const ProjectHeader = ({ onButtonClick, showFilter }: IProjectHeaderProps) => {
  const { t } = useTranslation('myprojects')
  const filters = queryString.parse(location.search)
  const [years, setYears] = useState<FiscalYearBase[]>([])
  const { data: projects } = useSelector(getMyProjects)

  useEffect(() => {
    if (!location.search || location.pathname !== Url.MyProjectsPage) {
      window.history.pushState(null, '', localStorage.getItem('projectFilters'))
    }
  }, [location.pathname])

  useEffect(() => {
    if (location.pathname === Url.MyProjectsPage) {
      localStorage.setItem('projectFilters', location.search)
    }
  }, [location.search])

  useEffect(() => {
    if (projects) {
      setYears(getYearsFromProjects(projects))
    }
  }, [projects])

  const isYearsFilterOn = useMemo(() => !!filters.yearIds, [years, location.search])
  const isProjectFilterOn = useMemo(() => !!filters.projectIds, [projects, location.search])

  return (
    <HeaderButtons
      withFilter={true}
      hasFiltersOn={isYearsFilterOn || isProjectFilterOn}
      showFilter={() => showFilter?.()}
      onClick={() => onButtonClick()}
      name={t('newProjectButton')!}
      icon='plus'
    />
  )
}

export default ProjectHeader
