import { useTranslation } from 'react-i18next'
import { LoadingSpinner } from '../SDK/LoadingSpinner'
import { SDKModal } from '../SDK/Modal'
import { SDKText } from '../SDK/Text'

import './index.scss'

interface ILoadingModalProps {
  setVisible: (isVisible: boolean) => void
  text?: string
}
export const LoadingModal = ({ setVisible, text }: ILoadingModalProps) => {
  const { t } = useTranslation()

  return (
    <SDKModal
      className='loading-modal'
      visible={true}
      onCancel={() => setVisible(false)}
      title=''
      withRequired={false}
      footer={<></>}
    >
      <div className='ap-flex flex-column w-100'>
        <LoadingSpinner />
        <SDKText type='subheading' className='ap-pt-spacing-3'>
          {text ?? t('loading')!}
        </SDKText>
      </div>
    </SDKModal>
  )
}
