import { useEffect, useMemo } from 'react'
import type { FieldErrors, UseFormGetValues, UseFormReset, UseFormSetError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Url } from '../../../../../constants/urls'
import { useApp } from '../../../../../context/app.context'
import { EAddEditCountryFields, IFormValues } from '../../../../../schemas/useAddEditCountrySchema'
import { LoadingStatus, RegionContainer } from '../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../store'
import { getYearByName } from '../../../../fiscalYearSetup/store/selectors'
import * as countryActions from '../../../store/actions'
import { getAddCountry, getUpdateCountry } from '../../../store/selectors'

export const useAddEditCountry = (
  reset: UseFormReset<IFormValues>,
  setError: UseFormSetError<IFormValues>,
  getValues: UseFormGetValues<IFormValues>
) => {
  const { t } = useTranslation('countries')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { sendNotification } = useApp()
  const { year: currentYearName, id: currentCountry } = useParams()
  const year = useSelector(getYearByName(String(currentYearName)))
  const countryId = useMemo(() => Number(currentCountry) || null, [currentCountry])
  const yearId = useMemo(() => Number(year?.yearId), [year])
  const { loading: addLoading, error: addError } = useSelector(getAddCountry)
  const { loading: updateLoading, error: updateError } = useSelector(getUpdateCountry)
  const loading = countryId ? updateLoading : addLoading
  const error = countryId ? updateError : addError

  const onSubmit = async (data: IFormValues) => {
    const mappedData = {
      code2: data.code2,
      code3: data.code3,
      currency: data.currency,
      name: data.name,
      regionIdsByType: {
        [RegionContainer.Tara]: Number(data.Tara),
        [RegionContainer.GcdPricing]: Number(data.GcdPricing),
        [RegionContainer.BenchmarkAndIndustryAnalysis]: Number(data.BenchmarkAndIndustryAnalysis)
      },
      countryLeaderIds: data.countryLeaders.map(item => item.userId),
      countryEditorIds: data.countryEditors.map(item => item.userId)
    }
    if (countryId) {
      await dispatch(
        countryActions.updateCountry({
          yearId,
          countryId,
          params: mappedData
        })
      )
      dispatch(countryActions.getCountryById({ yearId, countryId }))
    } else {
      await dispatch(countryActions.addCountry({ yearId, params: mappedData }))
    }
    dispatch(countryActions.getCountries(yearId))
  }

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded && year) {
      reset()
      navigate(`${Url.FiscalYearPage}/${year.name}${Url.CountriesPage}`)
      sendNotification({
        message: t('addEditCountry.form.successInfo'),
        status: 'success'
      })
    }
  }, [loading])

  const shouldDisplayError = (errors: FieldErrors<IFormValues> | string, text?: string[]) => {
    if (
      typeof errors === 'string' &&
      text &&
      errors?.length &&
      errors.includes(text[0]) &&
      errors.includes(text[1])
    ) {
      return true
    }
    if (errors && Object.keys(errors).length > 0) {
      if (
        Object.entries(errors)[0][1].type === 'required' ||
        Object.entries(errors)[0][1].type === 'max' ||
        Object.entries(errors)[0][1].type === 'min'
      ) {
        return true
      }
    }
    return false
  }

  const cleanUp = () => {
    dispatch(countryActions.clearCountries())
    dispatch(countryActions.clearGetCountryById())
    dispatch(countryActions.clearAddCountry())
    dispatch(countryActions.clearUpdateCountry())
    reset()
  }

  useEffect(() => {
    if (error?.length && shouldDisplayError(error, ['name', 'already exist'])) {
      dispatch(countryActions.clearAddCountry())
      setError(EAddEditCountryFields.name, {
        type: 'server',
        message: t('addEditCountry.form.errors.internationalNameExists')!
      })
    } else if (error?.length && shouldDisplayError(error, ['code', 'already exist'])) {
      dispatch(countryActions.clearAddCountry())
      if (error.includes(getValues(EAddEditCountryFields.code3))) {
        setError(EAddEditCountryFields.code3, {
          message: t('addEditCountry.form.errors.countryCodeExists')!
        })
      } else {
        setError(EAddEditCountryFields.code2, {
          message: t('addEditCountry.form.errors.countryCodeExists')!
        })
      }
    }
  }, [error])

  return { cleanUp, error, onSubmit, shouldDisplayError }
}
