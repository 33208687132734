import type { Control, FieldValues } from 'react-hook-form'
import { FormSwitch } from '../../../../../components/Form/FormSwitch'
import { SDKText } from '../../../../../components/SDK/Text'
import { IFormValues } from '../../../../../schemas/useAddQuestionSchema'

type TOption = {
  name: string
  text: string
}
interface ICalendarTypeElement {
  control: Control<FieldValues, IFormValues>
  title: string
  options: TOption[]
  isEditMode: boolean
  defaultValues: IFormValues
}

export const CalendarTypeElement = ({
  control,
  title,
  options,
  isEditMode,
  defaultValues
}: ICalendarTypeElement) => {
  return (
    <>
      <div className='relative'>
        <SDKText type='body-s' className='ap-text-neutral-14 ap-mr-spacing-2'>
          {title}
        </SDKText>
        <span className='required-dot'></span>
      </div>

      {options.map((option, index) => (
        <FormSwitch
          key={`option-${option.name}-${index}`}
          name={option.name}
          control={control}
          disabled={isEditMode && !!defaultValues[option.name as keyof IFormValues]}
          className='ap-my-spacing-4'
        >
          {option.text}
        </FormSwitch>
      ))}
    </>
  )
}
