import { EAnswerTypeFromBE } from '../../../modules/countriesSnapshot/pages/AddEditQuestion/constants/answerType'
import type {
  TCalendarQuestionOptions,
  TQuestion,
  TQuestionConditions
} from '../../../modules/countriesSnapshot/types'
import api from '../../api'
import { CountrySnapshotUrl, FiscalYearUrl } from '../../constants/urls'

export interface IAddQuestionPayload {
  questionText: string
  defaultNaText?: string
  possibleAnswers?: string[]
  options?: TCalendarQuestionOptions
  questionConditions?: TQuestionConditions[]
}

export const addNewQuestion = async (
  params: IAddQuestionPayload,
  yearId: number,
  categoryId: number,
  subcategoryId: number,
  questionType: EAnswerTypeFromBE
): Promise<TQuestion> => {
  return (
    await api.post(
      `${FiscalYearUrl.Years}/${yearId}/${CountrySnapshotUrl.Categories}/${categoryId}/${CountrySnapshotUrl.Subcategories}/${subcategoryId}/${CountrySnapshotUrl.Questions}/${CountrySnapshotUrl.Type}/${questionType}`,
      params
    )
  ).data
}
