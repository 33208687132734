import { EPricingTab } from '../../../modules/project/components/GCDPricing/const/enums'
import { EParentScopeModalType } from '../../../modules/project/components/GCDPricing/modals/ParentScopeModal/const'
import type {
  TParentScope,
  TParentScopePayload
} from '../../../modules/project/components/GCDPricing/types/scope'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const updateParentScopeRequest = async (
  projectId: number,
  tab: EPricingTab,
  params: TParentScopePayload
): Promise<TParentScope> => {
  let url = `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/`

  if (params.type === EParentScopeModalType.CustomModule) {
    url += `${ProjectsUrl.Modules}/${ProjectsUrl.CustomModules}/${params.customModuleId}/${
      ProjectsUrl.ParentScopes
    }/${params.parentScopeId!}`
  } else if (params.type === EParentScopeModalType.Transaction) {
    url += `${ProjectsUrl.Modules}/${tab}/${ProjectsUrl.Transactions}/${params.id}`
  } else {
    url += `${ProjectsUrl.Modules}/${tab}/${ProjectsUrl.ParentScopes}/${params.parentScopeId}`
  }

  return (await api.put(url, params)).data
}
