import classNames from 'classnames'
import { SDKPanel } from '../../../../../../../components/SDK/Panel'
import { SDKText } from '../../../../../../../components/SDK/Text'
import { InfoPanel } from '../../../../InfoPanel'

interface IFormWrapper {
  children: JSX.Element
  panelText: string
  title: string
  withLine?: boolean
}

export const FormWrapper = ({ children, panelText, title, withLine }: IFormWrapper) => {
  return (
    <div className={classNames('col col-6 ap-my-spacing-5', { 'border-left': withLine })}>
      <SDKPanel className='ap-bg-color-background-default'>
        <SDKText type='subheading' weight={2} className='ap-text-color-text-heading'>
          {title}
        </SDKText>
        {children}
        <InfoPanel text={panelText} />
      </SDKPanel>
    </div>
  )
}
