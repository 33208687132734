import type { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import i18n from '../../translations'
import { LoadingStatus } from '../types/enums'

export const DEFAULT_ERROR = i18n.t('form.errors.defaultError')

export interface SideEffects {
  error?: string | null
  loading: LoadingStatus
}

export const defaultSideEffect: SideEffects = {
  loading: LoadingStatus.Idle,
  error: null
}

export interface Resource<T> extends SideEffects {
  data: T
}

export const setResourceIdle = (resource: SideEffects) => {
  resource.loading = LoadingStatus.Idle
  resource.error = null
}

export const setResourcePending = (resource: SideEffects) => {
  resource.loading = LoadingStatus.Pending
  resource.error = null
}

export const setResourceFullfilled = (resource: SideEffects) => {
  resource.loading = LoadingStatus.Succeeded
  resource.error = null
}

export const setResourceRejected = <P, T extends string, M>(
  resource: SideEffects,
  action: PayloadAction<P, T, M, SerializedError>
) => {
  resource.loading = LoadingStatus.Failed
  const isRejectedError =
    action.error.message === 'Rejected' && typeof action.payload === 'string'
      ? action.payload
      : action.error.message
      ? action.error.message
      : DEFAULT_ERROR

  resource.error = action.error ? isRejectedError : DEFAULT_ERROR
}

export const getDefaultResourceState = <T>(initialData: T): Resource<T> => ({
  ...defaultSideEffect,
  data: initialData
})
