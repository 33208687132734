import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../../../../../context/app.context'
import { LoadingStatus } from '../../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../../store'
import {
  clearDeleteLegalDocument,
  deleteLegalDocument,
  getLegalDocuments
} from '../../../../../store/actions'
import * as selectors from '../../../../../store/selectors'

export const useDeleteDocument = (documentName?: string) => {
  const { t } = useTranslation('systemManagement')
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()

  const { error, loading } = useSelector(selectors.getDeleteLegalDocument)

  const onDelete = (documentId?: number) => {
    if (documentId) {
      dispatch(deleteLegalDocument({ documentId }))
    } else {
      sendNotification({
        message: t('legalDocuments.deleteDocument.error', { name: documentName }),
        status: 'error'
      })
    }
  }

  useEffect(() => {
    if (loading === LoadingStatus.Failed && error?.length) {
      sendNotification({
        message: error || t('legalDocuments.deleteDocument.error', { name: documentName }),
        status: 'error'
      })
    } else if (loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t('legalDocuments.deleteDocument.success', { name: documentName }),
        status: 'success'
      })
      dispatch(getLegalDocuments())
    }
    dispatch(clearDeleteLegalDocument())
  }, [loading])

  return { onDelete, loading }
}
