import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CardListWrapper } from '../../../../components/CardListWrapper'
import { ListWrapper } from '../../../../components/ListWrapper'
import { EAction, EListItem } from '../../../../components/ListWrapper/const'
import type { IActionCallback } from '../../../../components/ListWrapper/type'
import { SDKIcon } from '../../../../components/SDK/Icon'
import { LoadingOverlay } from '../../../../components/SDK/LoadingOverlay'
import { SDKModal } from '../../../../components/SDK/Modal'
import { SDKText } from '../../../../components/SDK/Text'
import { SDKTextButton } from '../../../../components/SDK/TextButton'
import { useApp } from '../../../../context/app.context'
import { useProject } from '../../../../context/project.context'
import { LoadingStatus } from '../../../../shared/types/enums'
import { useAppDispatch } from '../../../../store'
import * as countriesActions from '../../../countries/store/actions'
import { getPublishedCountries } from '../../../countries/store/selectors'
import type { ICountryListItem } from '../../../countries/types'
import { EProjectToolsTABSTab } from '../../constants/tab'
import * as actions from '../../store/actions'
import * as selectors from '../../store/selectors'
import './index.scss'

interface ICustomCountriesModalProps {
  setShowModal: (showModal: boolean) => void
  type: EProjectToolsTABSTab
}

const CustomCountriesModal = ({ setShowModal, type }: ICustomCountriesModalProps) => {
  const { t } = useTranslation('myprojects')
  const { sendNotification } = useApp()
  const dispatch = useAppDispatch()
  const [countries, setCountries] = useState<ICountryListItem[]>([])
  const [selectedCountryIds, setSelectedCountryIds] = useState<number[]>([])
  const countriesResource = useSelector(getPublishedCountries)
  const { error, loading } = useSelector(selectors.updateCustomCountries)
  const { project, refreshProject } = useProject()

  const setDefaultValues = () => {
    if (!project) {
      setSelectedCountryIds([])
      return
    }

    switch (type) {
      case EProjectToolsTABSTab.CS:
        setSelectedCountryIds(project.countrySnapshotCountryIds || [])
        break
      case EProjectToolsTABSTab.TARA:
        setSelectedCountryIds(project.taraCountryIds || [])
        break
      case EProjectToolsTABSTab.Pricing:
        setSelectedCountryIds(project.pricingCountryIds || [])
        break
      default:
        setSelectedCountryIds([])
        break
    }
  }

  useEffect(() => {
    if (project) {
      dispatch(countriesActions.getPublishedCountries(Number(project.yearId)))
    }
  }, [project])

  useEffect(() => {
    return () => {
      dispatch(actions.clearUpdateCustomCountries)
    }
  }, [])

  useEffect(() => {
    setDefaultValues()
    if (project) {
      const projectCountries = countriesResource.data.filter(c =>
        project.countryIds.some((countryId: string) => Number(countryId) === c.countryId)
      )
      setCountries(projectCountries)
    }
  }, [countriesResource.data, project, type])

  const onCancel = () => {
    setShowModal(false)
    dispatch(actions.clearUpdateCustomCountries())
  }

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t('successInfo'),
        status: 'success'
      })
      onCancel()
    }
    if (error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
      onCancel()
    }
  }, [loading, error])

  const getFreshData = () => {
    refreshProject()

    if (!project) return

    if (type === EProjectToolsTABSTab.CS) {
      dispatch(
        actions.getProjectResults({ yearId: Number(project.yearId), projectId: project.projectId })
      )
    }
  }

  const onSubmit = () => {
    if (project) {
      dispatch(
        actions.updateCustomCountries({
          params: { type, selectedCountryIds },
          projectId: project.projectId
        })
      ).then(() => getFreshData())
    }
  }

  const reset = () => {
    if (project) {
      setSelectedCountryIds(project.countryIds.map(id => Number(id)))
    }
  }

  const onChange = (id: number) => {
    if (selectedCountryIds.some(i => i === id)) {
      const newIds = selectedCountryIds.filter(i => i !== id)
      setSelectedCountryIds(newIds)
    } else {
      setSelectedCountryIds([...selectedCountryIds, id])
    }
  }

  const customFooterComponent = (
    <SDKTextButton className='ap-text-color-text-primary ap-mr-spacing-4' onClick={reset}>
      <SDKText weight={2}>
        <>
          <SDKIcon code='refresh' />
          {t('projectDetail.customCountries.modal.reset')!}
        </>
      </SDKText>
    </SDKTextButton>
  )
  return (
    <SDKModal
      title={t(`projectDetail.customCountries.modal.title`)!}
      submitLabel={t(`projectDetail.customCountries.modal.apply`)!}
      className='custom-countries-modal'
      onSubmit={onSubmit}
      onCancel={onCancel}
      withRequired={false}
      withCancel={false}
      customFooterComponent={customFooterComponent}
      visible
    >
      {loading === LoadingStatus.Pending && <LoadingOverlay type='modal' />}
      <CardListWrapper classNames='countries-list' resource={countriesResource}>
        <ListWrapper
          listItem={EListItem.CustomCountryItem}
          data={countries}
          actions={[EAction.Change]}
          handleClick={(callback: IActionCallback) => onChange(Number(callback.id))}
          itemKey={'countryId'}
          listClassName='p-0'
          selectedCountryIds={selectedCountryIds}
        ></ListWrapper>
      </CardListWrapper>
    </SDKModal>
  )
}

export default CustomCountriesModal
