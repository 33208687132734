import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Layout } from '../../../../components/Layout'
import { SDKLoading } from '../../../../components/SDK/Loading'
import { useApp } from '../../../../context/app.context'
import { useSubmitButton } from '../../../../hooks/useSubmitButton'
import {
  EAddEditCountryFields,
  useAddEditCountrySchema
} from '../../../../schemas/useAddEditCountrySchema'
import { LoadingStatus } from '../../../../shared/types/enums'
import { useAppDispatch } from '../../../../store'
import * as countryActions from '../../store/actions'
import * as countrySelectors from '../../store/selectors'
import { getAddCountry } from '../../store/selectors'
import { AddEditCountryForm } from './components/AddEditCountryForm'
import { AddEditCountryHeader } from './components/AddEditCountryHeader'
import { useAddEditCountry } from './utils/useAddEditCountry'

export const AddEditCountry = () => {
  const [isEditing, setEditing] = useState<boolean | null>(null)
  const { year: yearId, id: countryId } = useParams()
  const { error: addEditError } = useSelector(getAddCountry)
  const { data: country, loading, error } = useSelector(countrySelectors.getCountry)
  const dispatch = useAppDispatch()
  const { validationSchema, defaultValues, getDefaultValues } = useAddEditCountrySchema(country)
  const { sendNotification } = useApp()
  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    reset,
    formState,
    getValues,
    setValue,
    trigger
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange'
  })
  const { cleanUp, onSubmit } = useAddEditCountry(reset, setError, getValues)

  useEffect(() => {
    setEditing(!!countryId)
    cleanUp()

    if (!countryId) {
      dispatch(countryActions.clearGetCountryById())
    }
  }, [])

  useEffect(() => {
    if (addEditError) {
      sendNotification({ message: addEditError, status: 'error' })
    }
  }, [addEditError])

  useEffect(() => {
    if (error) {
      sendNotification({ message: error, status: 'error' })
    }
  }, [error])

  useEffect(() => {
    if (countryId) {
      dispatch(
        countryActions.getCountryById({ yearId: Number(yearId), countryId: Number(countryId) })
      )
    }
  }, [countryId, yearId])

  const displayForm = useMemo(() => {
    return !!((isEditing && defaultValues.name === country?.name) || isEditing === false)
  }, [country, countryId, isEditing])

  useEffect(() => {
    if (displayForm) {
      const newValues = getDefaultValues(country)
      Object.keys(defaultValues).map((key: string) =>
        setValue(key as EAddEditCountryFields, newValues[key as EAddEditCountryFields])
      )
    } else {
      cleanUp()
    }
  }, [displayForm, country])

  const { disabled } = useSubmitButton({ formState })

  return (
    <Layout
      buttonComponent={
        <AddEditCountryHeader disabledSubmit={disabled} onSave={handleSubmit(onSubmit)} />
      }
      onClick={() => cleanUp()}
    >
      <div className='add-edit-country-page ap-my-spacing-5'>
        {/* implemented due to 182075 story and commented because of bug 187648 */}
        {/* {shouldDisplayError(formState.errors) && (
          <SDKNotification status='error' message={t('addEditCountry.form.errors.required')!} />
        )} */}

        {loading === LoadingStatus.Pending && <SDKLoading />}
        {displayForm && (
          <form>
            <AddEditCountryForm
              control={control}
              isDirty={formState.isDirty}
              clearErrors={clearErrors}
              setValue={setValue}
              getValues={getValues}
              trigger={trigger}
            />
          </form>
        )}
      </div>
    </Layout>
  )
}
