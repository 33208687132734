import type { IProjectCard } from '../../../modules/myprojects/types'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const getProjectsRequest = async (
  onlyMyProjects: boolean = false
): Promise<IProjectCard[]> => {
  const url = `${ProjectsUrl.Projects}?onlyOwnOrAssignedProjects=${onlyMyProjects}`

  return (await api.get(url)).data
}
