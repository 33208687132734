import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DeleteModal } from '../../components/DeleteModal'
import { Layout } from '../../components/Layout'
import { LoadingOverlay } from '../../components/SDK/LoadingOverlay'
import { LoadingSpinner } from '../../components/SDK/LoadingSpinner'
import { LoadingStatus } from '../../shared/types/enums'
import { useAppDispatch } from '../../store'
import { AddEditFiscalYearModal } from './components/AddEditFiscalYearModal'
import FiscalYearSetupHeader from './components/FiscalYearSetupHeader'
import { FiscalYearsList } from './components/FiscalYearsList'
import { getYears } from './store/actions'
import * as selectors from './store/selectors'
import { useAddEditFiscalYearModal } from './utils/useAddEditFiscalYearModal'
import { useDeleteFiscalYear } from './utils/useDeleteFiscalYear'

const FiscalYearSetup = () => {
  const { t } = useTranslation('fiscalYearSetup')
  const dispatch = useAppDispatch()
  const { data: listData, loading: listLoading } = useSelector(selectors.getYears)
  const [visible, setVisible] = useState(false)
  const [yearId, setYearId] = useState<number | null>(null)
  const { modal, onEdit, onAdd } = useAddEditFiscalYearModal(yearId, setVisible, setYearId)
  const { onDelete, showDeleteModal, year, onSubmitDelete, error, loading, onClose } =
    useDeleteFiscalYear()

  useEffect(() => {
    if (!listData.length) dispatch(getYears())
  }, [])

  return (
    <Layout buttonComponent={<FiscalYearSetupHeader onButtonClick={onAdd} />}>
      <div className='fiscal-year-setup-page'>
        {listLoading === LoadingStatus.Idle ||
          (listLoading === LoadingStatus.Pending && <LoadingSpinner />)}
        {listLoading === LoadingStatus.Succeeded && (
          <FiscalYearsList list={listData} onEdit={onEdit} onDelete={onDelete} />
        )}
      </div>

      {visible && (
        <AddEditFiscalYearModal visible={visible} setVisible={setVisible} yearId={yearId} />
      )}
      {showDeleteModal && (
        <DeleteModal
          onSubmit={onSubmitDelete}
          displayTextFromTranslation={(text: string) =>
            t(`deleteFiscalYear.${text}`, { name: year?.name })!
          }
          onModalClose={onClose}
          onSuccess={() => dispatch(getYears())}
          error={error}
          loading={loading}
        />
      )}

      {(loading === LoadingStatus.Pending || modal.loading) === LoadingStatus.Pending && (
        <LoadingOverlay />
      )}
    </Layout>
  )
}

export default FiscalYearSetup
