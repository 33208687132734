import { useTranslation } from 'react-i18next'
import { IconWithTooltip } from '../../../IconWithTooltip'

interface IUserIcon {
  isUserItem: boolean
}

export const UserIcon = ({ isUserItem }: IUserIcon) => {
  const { t } = useTranslation('pricing')

  if (isUserItem) {
    return <IconWithTooltip code={'avatar'} content={t('tabs.inputs.isUserItem.tooltip')} />
  }

  return <></>
}
