import type { IPowerBITemplate } from '../../../modules/powerBITemplates/types'
import { getUrlQueries } from '../../../utils/getUrlQueries'
import api from '../../api'
import { FiscalYearUrl, PowerBITemplateUrl } from '../../constants/urls'

export interface IAddPBITemplateUploadPayload {
  identifier: string
  name: string
  templateType: string
  templateFile: File
  thumbnailFile: File
  yearId: number
}

export const addPBITemplateRequest = async ({
  identifier,
  name,
  templateType,
  templateFile,
  thumbnailFile,
  yearId
}: IAddPBITemplateUploadPayload): Promise<IPowerBITemplate> => {
  const data = new FormData()
  data.append('templateFile', templateFile)
  data.append('thumbnailFile', thumbnailFile)

  const queries = getUrlQueries({ identifier, name, templateType })

  return (
    await api.post(
      `${FiscalYearUrl.Years}/${yearId}/${PowerBITemplateUrl.PowerBITemplates}${queries}`,
      data,
      {
        ['Content-Type']: 'multipart/form-data'
      }
    )
  ).data
}
