import { RootState } from '../../../store'

export const getProjects = (state: RootState) => state.myProjects.projects
export const getMyProjects = (state: RootState) => state.myProjects.myProjects
export const getProject = (state: RootState) => state.myProjects.project
export const addProject = (state: RootState) => state.myProjects.addProject
export const updateProject = (state: RootState) => state.myProjects.updateProject
export const copyProject = (state: RootState) => state.myProjects.copyProject
export const deleteProject = (state: RootState) => state.myProjects.deleteProject
export const getCurrencies = (state: RootState) => state.myProjects.currencies
export const getAssignedProjectUsers = (state: RootState) => state.myProjects.assignedProjectUsers
export const getAvailableProjectUsers = (state: RootState) => state.myProjects.availableProjectUsers
export const getProjectChanges = (state: RootState) => state.myProjects.projectChanges
export const updateProjectChanges = (state: RootState) => state.myProjects.updateProjectChanges
export const syncWithSightline = (state: RootState) => state.myProjects.syncWithSightline
