import type { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Divider } from '../../../../../../components/Divider'
import { FormCard } from '../../../../../../components/Form/FormCard'
import { FormInput } from '../../../../../../components/Form/FormInput'
import { FormRichTextEditor } from '../../../../../../components/Form/FormRichTextEditor'
import { SDKButton } from '../../../../../../components/SDK/Button'
import { SDKParagraph } from '../../../../../../components/SDK/Paragraph'
import { SDKText } from '../../../../../../components/SDK/Text'
import { SDKTooltip } from '../../../../../../components/SDK/Tooltip'
import {
  EEditEmailTemplateFields,
  IFormValues
} from '../../../../../../schemas/useEditEmailTemplateSchema'
import { emailTemplateVariableList } from '../../../../constants'
import './index.scss'

interface IEditTemplateForm {
  control: Control<IFormValues, any>
  isDirty: boolean
}

export const EditEmailTemplateForm = ({ control, isDirty }: IEditTemplateForm) => {
  const { t } = useTranslation('systemManagement')
  const { id: templateId } = useParams()

  return (
    <div className='edit-email-template-form'>
      <div className='ap-mt-spacing-4'>
        <FormCard withRequired title={t('emailTemplates.form.templateInfo')}>
          <div className='row'>
            <div className='col-md-8'>
              <SDKTooltip
                disabled={isDirty}
                content={t('emailTemplates.form.fields.emailTemplateNameTooltip')!}
              >
                <FormInput
                  name={EEditEmailTemplateFields.templateName}
                  type='text'
                  label={t('emailTemplates.form.fields.emailTemplateName')!}
                  required={true}
                  control={control}
                  suffixIcon
                />
              </SDKTooltip>
            </div>
            <div className='col-md-4'>
              <div className='wrapper'>
                <SDKText type='body-s' className='ap-text-neutral-14'>
                  {t('emailTemplates.form.fields.templateID')}
                </SDKText>
                <SDKParagraph>{templateId || ''}</SDKParagraph>
              </div>
            </div>
          </div>
        </FormCard>
      </div>
      <div className='ap-mt-spacing-4'>
        <FormCard title={t('emailTemplates.form.templateStructure')}>
          <SDKTooltip
            disabled={isDirty}
            content={t('emailTemplates.form.fields.emailSubjectTooltip')!}
          >
            <FormInput
              name={EEditEmailTemplateFields.subject}
              type='text'
              label={t('emailTemplates.form.fields.emailSubject')!}
              required={true}
              control={control}
              suffixIcon
            />
          </SDKTooltip>
          <div className='ap-my-spacing-4'>
            <FormRichTextEditor
              control={control}
              name={EEditEmailTemplateFields.message}
              label={EEditEmailTemplateFields.message}
            />
          </div>
          <SDKText type='body-s' className='ap-text-neutral-14'>
            {t('emailTemplates.form.fields.variables')}
          </SDKText>
          {emailTemplateVariableList.map(({ id, value, title }) => (
            <SDKParagraph
              key={`email-template-variable-${id}`}
            >{`{${value}} - ${title}`}</SDKParagraph>
          ))}
          <Divider />
          <SDKButton
            disabled
            className='show-more-btn hidden' //remove hidden class if there is more variables than 5
            kind='text'
            icon='icon-down-chevron-outline'
          >
            {t('emailTemplates.form.showMore')}
          </SDKButton>
        </FormCard>
      </div>
    </div>
  )
}
