import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../../../context/app.context'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { DEFAULT_ERROR } from '../../../../../shared/utils/store'
import { useAppDispatch } from '../../../../../store'
import { PowerBIAction } from '../../../constants'
import {
  clearGetPBICalculationResultByTemplateId,
  getPBICalculationResultByTemplateId
} from '../../../store/actions'
import * as selectors from '../../../store/selectors'

export const useDownloadPowerBI = (
  setShowDownloadPowerBIModal: (value: PowerBIAction | null) => void,
  calculationId?: number
) => {
  const { t } = useTranslation('myprojects')
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { error, loading } = useSelector(selectors.getPBICalculationResultByTemplateId)

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded) {
      setShowDownloadPowerBIModal(null)
      sendNotification({
        message: error || t('powerBIActionModal.download.success'),
        status: 'success'
      })
    } else if (loading === LoadingStatus.Failed && error?.length) {
      sendNotification({
        message: error === DEFAULT_ERROR ? t('powerBIActionModal.download.error') : error,
        status: 'error'
      })
    }
    dispatch(clearGetPBICalculationResultByTemplateId())
  }, [loading])

  const downloadPowerBI = (
    templateId: number | null,
    setTemplateId: (value: number | null) => void
  ) => {
    if (calculationId && templateId) {
      dispatch(getPBICalculationResultByTemplateId({ calculationId, templateId }))
      setTemplateId(null)
    }
  }

  return { downloadPowerBI }
}
