import type { ParsedQuery } from 'query-string'
import type { FiscalYearBase } from '../../../../fiscalYearSetup/types'
import type { IProjectCard } from '../../../types'
import type { TFilter } from '../types'

export interface ISetDefaultFiltersProps {
  DEFAULT_FILTERS: TFilter
  query: ParsedQuery<string>
  projects: IProjectCard[]
  years: FiscalYearBase[]
}
export const setDefaultFilters = ({
  DEFAULT_FILTERS,
  query,
  projects,
  years
}: ISetDefaultFiltersProps) => {
  let newFilters: TFilter = {
    ...DEFAULT_FILTERS,
    ...query
  }
  if (!newFilters.projectIds.length) {
    newFilters = { ...newFilters, projectIds: projects.map(p => String(p.projectId)) }
  }
  if (!newFilters.yearIds.length) {
    newFilters = {
      ...newFilters,
      yearIds: years.map(y => String(y.yearId))
    }
  }
  return newFilters
}
