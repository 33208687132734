import classNames from 'classnames'
import { useApp } from '../../../../context/app.context'
import { IQuote } from '../../../../modules/project/components/GCDPricing/types/inputsTab'
import type { IParentScope } from '../../../../modules/project/components/GCDPricing/types/scope'
import { useCardGridColumnCalculation } from '../../../../utils/useCardGridColumnCalculation'
import { SDKText } from '../../../SDK/Text'
import { EAction } from '../../const'
import type { IActionCallback } from '../../type'
import { CardActionButtonGroup } from '../cardItems/CardActionButtonGroup'
import { CardFees } from '../cardItems/CardFees'
import { CardMobile } from '../cardItems/CardMobile'
import { CardTextWithIcon } from '../cardItems/CardTextWithIcon'
import { DnDIcon } from '../cardItems/DnDIcon'

interface ICardWithFeeCard {
  actions: EAction[]
  data: IParentScope
  actionCallback?: (_callback: IActionCallback) => void
  greyBackground?: boolean
  withoutDnD?: boolean
  quotes?: IQuote
  country?: string
}

export const CardWithFee = ({
  actions,
  actionCallback,
  data,
  country,
  quotes,
  withoutDnD,
  greyBackground
}: ICardWithFeeCard) => {
  const { textCol } = useCardGridColumnCalculation()
  const {
    appContext: {
      style: { isMobile }
    }
  } = useApp()

  const withCountry = (
    <div className='row w-100'>
      <div className='col col-4 ap-pl-spacing-5 ap-flex align-items-center'>
        {!withoutDnD && <DnDIcon />}
        <SDKText weight={2}>{country!}</SDKText>
      </div>
      <div className='col col-8'>
        <CardTextWithIcon
          isUserItem={data.isUserItem}
          text={data.name}
          isMobile={isMobile}
          withoutDnD={true}
        />
      </div>
    </div>
  )
  const desktopView = (
    <div className='ap-text-neutral-17 ap-flex justify-content-between'>
      <div className='row w-100'>
        <div className={`col col-${textCol}`}>
          {country ? (
            withCountry
          ) : (
            <CardTextWithIcon
              isUserItem={data.isUserItem}
              text={data.name}
              isMobile={isMobile}
              withoutDnD={withoutDnD}
            />
          )}
        </div>
        {data.priceYears && (
          <CardFees
            fees={data.priceYears}
            quotes={quotes}
            isMobile={isMobile}
            id={data.parentScopeId}
          />
        )}
      </div>
      <CardActionButtonGroup
        actions={actions}
        actionCallback={actionCallback}
        id={data.parentScopeId}
        isEnabled={data.isEnabled}
        isUserItem={data.isUserItem}
        isParentScope={data.hasOwnProperty('priceYears')}
        hasUserEditsDisabled={data.hasUserEditsDisabled}
        isToggleDisabled={data.isToggleDisabled}
      />
    </div>
  )

  return (
    <div
      className={classNames(
        'dnd-card ap-py-spacing-5',
        greyBackground && 'ap-bg-color-background-default'
      )}
    >
      {isMobile ? (
        <CardMobile
          actions={actions}
          actionCallback={actionCallback}
          data={data}
          isMobile={isMobile}
          fees={data.priceYears}
          id={data.parentScopeId}
        />
      ) : (
        desktopView
      )}
    </div>
  )
}
