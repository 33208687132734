import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { IconWithTooltip } from '../../../../components/IconWithTooltip'
import { SDKInput } from '../../../../components/SDK/Input'
import { SDKModal } from '../../../../components/SDK/Modal'
import { SDKText } from '../../../../components/SDK/Text'
import { SDKTextButton } from '../../../../components/SDK/TextButton'
import { useApp } from '../../../../context/app.context'
import { useProjectFromUrl } from '../../../../hooks/useProjectFromUrl'
import { LoadingStatus } from '../../../../shared/types/enums'
import { useCopyBtn } from '../../../../shared/utils/useCopyBtn'
import { useAppDispatch } from '../../../../store'
import {
  clearUpdateCalendar,
  getTemplateCalculationById,
  updateCalendar
} from '../../store/actions'
import { updateCalendar as updateCalendarSelector } from '../../store/selectors'
import './index.scss'

interface ICalendarFeedModalProps {
  isVisible: boolean
  setIsVisible: (v: boolean) => void
  calendarKey: string
}

export const CalendarFeedModal = ({
  isVisible,
  setIsVisible,
  calendarKey
}: ICalendarFeedModalProps) => {
  const { t } = useTranslation('myprojects')
  const { onCopy, copied } = useCopyBtn()
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { error, loading } = useSelector(updateCalendarSelector)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const calendarLink = `${window.location.origin}/Calendar?accessKey=${calendarKey}`
  const { project } = useProjectFromUrl()

  useEffect(() => {
    if (error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
      dispatch(clearUpdateCalendar())
    }
    if (loading === LoadingStatus.Succeeded && project) {
      sendNotification({
        message: t('calendarFeedModal.successInfo'),
        status: 'success'
      })
      dispatch(clearUpdateCalendar())
      dispatch(getTemplateCalculationById(project.projectId))
    }
  }, [error, loading])

  const onSubmit = () => {
    setIsVisible(false)
  }

  const reset = () => {
    dispatch(updateCalendar({ projectId: project?.projectId! }))
    setShowConfirmationModal(false)
  }

  const calendarInput = () => (
    <div className='ap-py-spacing-3 ap-flex'>
      <div className='calendar-input'>
        <SDKInput
          type='text'
          title={t(`calendarFeedModal.inputLabel`)!}
          value={calendarLink}
          disabled
        ></SDKInput>
      </div>
      <div className='ap-pl-spacing-3 ap-py-spacing-2 pointer' onClick={() => onCopy(calendarLink)}>
        <SDKText type='heading'>
          <IconWithTooltip code='copy' disabled={!copied.status} content={t('copyBtnTooltip')} />
        </SDKText>
      </div>
    </div>
  )
  const resetUrl = (
    <div className='ap-flex'>
      <IconWithTooltip code='information' content={t('calendarFeedModal.resetUrlTooltip')!} />
      <SDKTextButton onClick={() => setShowConfirmationModal(true)}>
        <SDKText weight={2} className='ap-text-color-text-primary'>
          {t('calendarFeedModal.resetUrl')}
        </SDKText>
      </SDKTextButton>
    </div>
  )
  return (
    <>
      <SDKModal
        onCancel={() => setIsVisible(false)}
        onSubmit={onSubmit}
        title={`${t('calendarFeedModal.modalTitle')}`}
        submitLabel={t('calendarFeedModal.submitLabel')!}
        visible={isVisible}
        withRequired={false}
        customFooterComponent={resetUrl}
        className='default-modal calendar-feed-modal'
      >
        <div className='ap-pb-spacing-5'>
          <SDKText>
            <span dangerouslySetInnerHTML={{ __html: t('calendarFeedModal.contentText')! }}></span>
          </SDKText>
        </div>
        {calendarInput()}
      </SDKModal>
      {showConfirmationModal && (
        <SDKModal
          onCancel={() => setShowConfirmationModal(false)}
          onSubmit={reset}
          submitLabel={t('calendarFeedModal.confirmLabel')!}
          visible
          withCancel
          withRequired={false}
        >
          <SDKText>{t('calendarFeedModal.resetUrlConfirmation')!}</SDKText>
        </SDKModal>
      )}
    </>
  )
}
