import { IFormulaCountry, IFormulaUpdate } from '../../../modules/TaraFields/types'
import api from '../../api'
import { TaraFieldsUrl } from '../../constants/urls'

export const editFormulaOverride = async ({
  formulaId,
  countryId,
  params
}: {
  formulaId: number
  countryId: number
  params: IFormulaUpdate
}): Promise<IFormulaCountry> => {
  return (
    await api.put(
      `${TaraFieldsUrl.Country}/${countryId}/${TaraFieldsUrl.Formulas}/${formulaId}`,
      params
    )
  ).data
}
