export enum ECalculationType {
  Created = 'Created',
  Failed = 'Failed',
  InProgress = 'InProgress',
  Success = 'Success',
  Canceled = 'Canceled'
}

export type TCalculationType = 'Created' | 'Failed' | 'InProgress' | 'Success' | 'Canceled'

export enum EFileType {
  Json = 0,
  Excel = 1,
  Calendar = 2,
  InputData = 3
}

export type TFileType = 0 | 1 | 2 | 3
export interface ITemplateCalculation {
  calculationId: number
  createdDate: string
  createdById: number
  createdBy: string
  status: TCalculationType
  dateOnly: boolean
}

export interface ITemplateCalculationData extends ITemplateCalculation {
  finishedDate: string
  errors: string[] | null
  hasCalendar: boolean
  calendarKey: string
}

export interface ISightlineFile {
  id: string
  name: string
}

export interface ICalculationResultByFormat {
  blob: File
  fileType: EFileType
}
