import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../../../../../../context/app.context'
import { useSightlineVisibility } from '../../../../../../../../context/hooks/useSightlineVisibility'
import { useProjectFromUrl } from '../../../../../../../../hooks/useProjectFromUrl'
import { LoadingStatus } from '../../../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../../../store'
import { ESightlineRedirectAction, SIGHTLINE_ACTION } from '../../../../../../constants'
import {
  clearExportResultsFlat,
  clearExportResultsFull,
  clearExportResultsWithSightline,
  exportResultsFlat,
  exportResultsFull,
  exportResultsWithSightline
} from '../../../../store/actions'
import {
  exportPricingResultsFlat,
  exportPricingResultsFull,
  exportPricingResultsWithSightline
} from '../../../../store/selectors'

export enum EResultsExportAction {
  FullExport = 'fullExport',
  FlatExport = 'flatExport',
  SightlineExport = 'sightlineExport'
}

export const useExportResults = () => {
  const { t } = useTranslation('pricing')
  const dispatch = useAppDispatch()
  const { project } = useProjectFromUrl()
  const { sendNotification } = useApp()
  const { visible } = useSightlineVisibility()
  const [currentExportType, setCurrentExportType] = useState<EResultsExportAction | null>(null)
  const { loading: fullExportLoading, error: fullExportError } =
    useSelector(exportPricingResultsFull)
  const { loading: flatExportLoading, error: flatExportError } =
    useSelector(exportPricingResultsFlat)
  const { loading: sightlineExportLoading, error: sightlineExportError } = useSelector(
    exportPricingResultsWithSightline
  )

  const getSelectorValues = (type: EResultsExportAction | null) => {
    switch (type) {
      case EResultsExportAction.FlatExport:
        return {
          loading: flatExportLoading,
          error: flatExportError,
          clearAction: clearExportResultsFlat
        }
      case EResultsExportAction.FullExport:
        return {
          loading: fullExportLoading,
          error: fullExportError,
          clearAction: clearExportResultsFull
        }
      case EResultsExportAction.SightlineExport:
        return {
          loading: sightlineExportLoading,
          error: sightlineExportError,
          clearAction: clearExportResultsWithSightline
        }
      default:
        return null
    }
  }

  const loading = getSelectorValues(currentExportType)?.loading
  const error = getSelectorValues(currentExportType)?.error
  const clearAction = getSelectorValues(currentExportType)?.clearAction

  useEffect(() => {
    if (loading === LoadingStatus.Failed && error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
    } else if (
      loading === LoadingStatus.Succeeded &&
      currentExportType === EResultsExportAction.SightlineExport
    ) {
      sendNotification({
        message: t('tabs.results.export.sightlineSuccess'),
        status: 'success'
      })
    }
  }, [loading])

  useEffect(() => {
    return () => {
      if (clearAction) {
        dispatch(clearAction())
      }
    }
  }, [])

  const onFullExport = () => {
    setCurrentExportType(EResultsExportAction.FullExport)
    dispatch(exportResultsFull({ projectId: project?.projectId! }))
  }

  const onFlatExport = () => {
    setCurrentExportType(EResultsExportAction.FlatExport)
    dispatch(exportResultsFlat({ projectId: project?.projectId! }))
  }

  const onSightlineExport = () => {
    setCurrentExportType(EResultsExportAction.SightlineExport)
    dispatch(exportResultsWithSightline({ projectId: project?.projectId! }))
  }

  useEffect(() => {
    const action = localStorage.getItem(SIGHTLINE_ACTION)
    if (
      action === ESightlineRedirectAction.ExportPricingResultsWithSightline &&
      project?.projectId
    ) {
      onSightlineExport()
      localStorage.removeItem(SIGHTLINE_ACTION)
    }
  }, [project?.projectId])

  let menuItems = [
    {
      value: EResultsExportAction.FullExport,
      label: t('tabs.results.export.full'),
      iconName: 'xls',
      onClick: onFullExport
    },
    {
      value: EResultsExportAction.FlatExport,
      label: t('tabs.results.export.flat'),
      iconName: 'xls',
      onClick: onFlatExport
    },
    {
      value: EResultsExportAction.SightlineExport,
      label: t('tabs.results.export.sightline'),
      iconName: 'paper-airplane',
      onClick: onSightlineExport
    }
  ]

  if (!visible || !project?.tpEngagementId) {
    menuItems = menuItems.filter(item => item.value !== EResultsExportAction.SightlineExport)
  }

  return { menuItems, loading, onFullExport }
}
