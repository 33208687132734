import { useTranslation } from 'react-i18next'
import i18n from '../../../translations'
import { ErrorDescription } from '../error/components/ErrorDescription'

export const useErrorText = (errorText?: string) => {
  const { t } = useTranslation('errors')

  if (!errorText) {
    return null
  }

  return (
    <ErrorDescription
      text={
        i18n.exists(`errors:serverResponses.${errorText}`)
          ? t(`serverResponses.${errorText}`)
          : errorText
      }
    />
  )
}
