import { useMemo, useState } from 'react'
import type { UseFormGetValues, UseFormSetValue, UseFormTrigger } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CardListItem } from '../../../../../../../../components/CardListItem'
import { EAction, ECardListItem } from '../../../../../../../../components/CardListItem/const'
import type { IActionCallback } from '../../../../../../../../components/CardListItem/type'
import { RHF_SET_VALUE_OPTIONS } from '../../../../../../../../components/Form/utils'
import { SDKButton } from '../../../../../../../../components/SDK/Button'
import { SDKText } from '../../../../../../../../components/SDK/Text'
import type { IFormValues } from '../../../../../../../../schemas/useAddEditCountrySchema'
import { EAddEditCountryFields } from '../../../../../../../../schemas/useAddEditCountrySchema'
import type { IUserBase } from '../../../../../../../../types/user'
import { objectSortByProperty } from '../../../../../../../../utils/objectSortByProperty'
import type { ICountryLeader } from '../../../../../../types'
import { AddLeaderEditorModal } from '../AddLeaderEditorModal'

import './index.scss'

interface IContactSection {
  setValue: UseFormSetValue<IFormValues>
  getValues: UseFormGetValues<IFormValues>
  trigger: UseFormTrigger<IFormValues>
}
export type ModalType = EAddEditCountryFields.countryLeaders | EAddEditCountryFields.countryEditors

export const ContactSection = ({ setValue, getValues, trigger }: IContactSection) => {
  const { t } = useTranslation('countries')
  const [visibleModal, setVisibleModal] = useState(false)
  const [modalType, setModalType] = useState<ModalType>(EAddEditCountryFields.countryLeaders)
  const title =
    modalType === EAddEditCountryFields.countryLeaders
      ? t('addLeaderEditor.addLeader')
      : t('addLeaderEditor.addEditor')
  const country = getValues()

  const onDelete = (
    type: EAddEditCountryFields.countryLeaders | EAddEditCountryFields.countryEditors,
    userId: number
  ) => {
    setValue(
      type,
      (type === EAddEditCountryFields.countryLeaders
        ? country.countryLeaders
        : country.countryEditors
      ).filter(item => item.userId !== userId),
      RHF_SET_VALUE_OPTIONS
    )
    trigger(type)
  }

  const onAdd = (type: ModalType) => {
    setModalType(type)
    setVisibleModal(true)
  }

  const countryLeadersList = useMemo(() => {
    return country?.countryLeaders?.sort(objectSortByProperty('lastName')) || []
  }, [country])
  const countryEditorsList = useMemo(() => {
    return country?.countryEditors?.sort(objectSortByProperty('lastName')) || []
  }, [country])

  const isAddBtnVisible = !country?.countryLeaders?.some(item => item?.isImportedFromTpi)

  const updateUserList = ({
    type,
    users
  }:
    | { type: EAddEditCountryFields.countryLeaders; users: ICountryLeader[] }
    | { type: EAddEditCountryFields.countryEditors; users: IUserBase[] }) => {
    trigger(type)
    return users?.sort(objectSortByProperty('lastName'))
  }

  return (
    <>
      <div className='row contact-info'>
        <div className='wrapper ap-mb-spacing-4'>
          <SDKText type='body-s' className='ap-text-neutral-14'>
            {t('addEditCountry.form.countryLeader')}
          </SDKText>
          {isAddBtnVisible && (
            <SDKButton
              kind='text'
              heightAuto
              className='add-btn'
              icon='icon-plus-outline'
              onClick={() => onAdd(EAddEditCountryFields.countryLeaders)}
            >
              {t('add')}
            </SDKButton>
          )}
        </div>
        {countryLeadersList?.map((user: IUserBase) => (
          <div className='country-leaders-list g-col-12' key={user.userId}>
            <CardListItem
              actions={[EAction.Delete]}
              cardListItem={ECardListItem.UserCard}
              handleClick={(callback: IActionCallback) =>
                onDelete(EAddEditCountryFields.countryLeaders, Number(callback.id))
              }
              {...user}
            />
          </div>
        ))}

        <div className='ap-my-spacing-5'>
          <div className='wrapper ap-mb-spacing-4'>
            <SDKText type='body-s' className='ap-text-neutral-14'>
              {t('addEditCountry.form.countryEditor')}
            </SDKText>
            {
              <SDKButton
                kind='text'
                heightAuto
                className='add-btn'
                icon='icon-plus-outline'
                onClick={() => onAdd(EAddEditCountryFields.countryEditors)}
              >
                {t('add')}
              </SDKButton>
            }
          </div>
          {countryEditorsList.map((user: IUserBase) => (
            <div className='country-editors-list g-col-12' key={user.userId}>
              <CardListItem
                actions={[EAction.Delete]}
                cardListItem={ECardListItem.UserCard}
                handleClick={(callback: IActionCallback) =>
                  onDelete(EAddEditCountryFields.countryEditors, Number(callback.id))
                }
                {...user}
              />
            </div>
          ))}
        </div>
      </div>

      {visibleModal && (
        <AddLeaderEditorModal
          visible={visibleModal}
          setVisible={setVisibleModal}
          modalTitle={title}
          type={modalType}
          countryLeaders={country.countryLeaders.map(item => item.userId)}
          countryEditors={country.countryEditors.map(item => item.userId)}
          onSubmit={(data: IUserBase[] | ICountryLeader[]) =>
            modalType === EAddEditCountryFields.countryLeaders
              ? setValue(
                  EAddEditCountryFields.countryLeaders,
                  updateUserList({
                    type: EAddEditCountryFields.countryLeaders,
                    users: [
                      ...(country.countryLeaders as ICountryLeader[]),
                      ...(data as ICountryLeader[])
                    ] as ICountryLeader[]
                  }),
                  RHF_SET_VALUE_OPTIONS
                )
              : setValue(
                  EAddEditCountryFields.countryEditors,
                  updateUserList({
                    type: EAddEditCountryFields.countryEditors,
                    users: [...country.countryEditors, ...data]
                  }),
                  RHF_SET_VALUE_OPTIONS
                )
          }
        />
      )}
    </>
  )
}
