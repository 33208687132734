import type { TextEditorProps } from '@appkit4/react-text-editor'
import { TextEditor } from '@appkit4/react-text-editor'
import '@appkit4/react-text-editor/dist/appkit4-react-texteditor.min.css'
import { Ref, forwardRef } from 'react'

class Base64UploadAdapter {
  public loader: any

  public reader: any

  constructor(loader: any) {
    // The file loader instance to use during the upload.
    this.loader = loader
    this.reader = new window.FileReader()
  }

  // Starts the upload process
  upload() {
    return new Promise((resolve, reject) => {
      const reader = this.reader

      reader.addEventListener('load', () => {
        resolve({ default: reader.result })
      })

      reader.addEventListener('error', (err: any) => {
        reject(err)
      })

      reader.addEventListener('abort', () => {
        reject()
      })

      this.loader.file.then((file: any) => {
        reader.readAsDataURL(file)
      })
    })
  }

  abort() {
    this.reader.abort()
  }
}

interface ISDKRichTextEditor extends TextEditorProps {
  value: string
  onChange: (_: any, __: any, data: HTMLParagraphElement) => void
}

export const SDKRichTextEditor = forwardRef(
  ({ value, onChange, ...props }: Partial<ISDKRichTextEditor>, ref: Ref<HTMLDivElement>) => {
    const sampleConfig = {
      toolbar: ['bold', 'bulletedList', 'link']
    }

    const onDocEditorReady = (editor: any) => {
      editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
        return new Base64UploadAdapter(loader)
      }
    }

    return (
      <div ref={ref}>
        <div className='simple-editor-container'>
          <TextEditor
            className='rich-text-editor'
            data={value}
            config={sampleConfig}
            onReady={onDocEditorReady}
            onChange={onChange}
            {...props}
          />
        </div>
      </div>
    )
  }
)
