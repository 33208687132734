import type { SelectValue } from '@appkit4/react-components/esm/select/Select'
import type { SelectProps } from '@appkit4/react-components/select'
import { Select } from '@appkit4/react-components/select'
import type { Ref } from 'react'
import { forwardRef } from 'react'

interface ISDKSelect extends SelectProps {
  onSelect: (value: any) => void
  fieldValue: SelectValue
}

export type ISelectValue = SelectValue
export const SDKSelect = forwardRef(
  ({ onSelect, fieldValue, ...props }: Partial<ISDKSelect>, ref: Ref<HTMLDivElement>) => {
    return (
      <div ref={ref}>
        <Select
          suffixTemplate={item => {
            return item.descValue && <span>{item.descValue}</span>
          }}
          onSelect={onSelect}
          value={fieldValue}
          {...props}
        />
      </div>
    )
  }
)
