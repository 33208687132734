import { IFormulaDescription } from '../../../modules/TaraFields/types'
import api from '../../api'
import { TaraFieldsUrl } from '../../constants/urls'

export const editFormulaOverrideDescription = async ({
  params
}: {
  params: IFormulaDescription
}): Promise<IFormulaDescription> => {
  return (await api.put(`${TaraFieldsUrl.Formulas}/${TaraFieldsUrl.Description}`, params)).data
}
