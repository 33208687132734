import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { useNavigate } from 'react-router-dom'
import { Url } from '../../constants/urls'
import { SDKButton } from '../SDK/Button'

export enum EButtonsForUnlogged {
  GoToIndex,
  Login,
  Dashboard
}

const GRID_COLUMN_COUNT = 12

export const ButtonsForUnlogged = ({
  buttons = [EButtonsForUnlogged.GoToIndex, EButtonsForUnlogged.Login]
}: {
  buttons?: EButtonsForUnlogged[]
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const auth = useAuth()

  const AVAILABLE_BUTTONS = {
    [EButtonsForUnlogged.GoToIndex]: {
      buttonText: 'signOut.indexPageButton.button',
      onClick: () => navigate(Url.IndexPage)
    },
    [EButtonsForUnlogged.Login]: {
      buttonText: 'login.button',
      onClick: () => auth.signinRedirect()
    },
    [EButtonsForUnlogged.Dashboard]: {
      buttonText: 'errors:exceptions.exception404Redirect',
      onClick: () => navigate(Url.MyProjectsPage)
    }
  }

  const widthSM = GRID_COLUMN_COUNT / buttons.length
  const widthMD = Math.ceil(GRID_COLUMN_COUNT / buttons.length / 2)

  return (
    <div className='row ap-container ap-mt-spacing-6'>
      <div className='row justify-content-center'>
        {buttons.map((button, index) => (
          <div
            className={classnames([
              `col-sm-${widthSM}`,
              `col-md-${widthMD}`,
              'ap-flex',
              'ap-mb-spacing-2'
            ])}
            key={index}
          >
            <SDKButton fullWidth onClick={AVAILABLE_BUTTONS[button].onClick}>
              {t(AVAILABLE_BUTTONS[button].buttonText)}
            </SDKButton>
          </div>
        ))}
      </div>
    </div>
  )
}
