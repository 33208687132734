import type { CheckboxProps } from '@appkit4/react-components/checkbox'
import { Checkbox } from '@appkit4/react-components/checkbox'
import { Ref, forwardRef } from 'react'

interface ICheckboxProps extends CheckboxProps {
  label: string
}

export const SDKCheckbox = forwardRef(
  ({ label, ...props }: Partial<ICheckboxProps>, ref: Ref<HTMLDivElement>) => {
    return (
      <Checkbox ref={ref} {...props}>
        {label}
      </Checkbox>
    )
  }
)
