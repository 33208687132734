import classNames from 'classnames'
import { useApp } from '../../../../context/app.context'
import type { IChildScope } from '../../../../modules/project/components/GCDPricing/types/scope'
import { SDKPanel } from '../../../SDK/Panel'
import { EAction } from '../../const'
import type { IActionCallback } from '../../type'
import { CardActionButtonGroup } from '../cardItems/CardActionButtonGroup'
import { CardMobile } from '../cardItems/CardMobile'
import { CardTextWithIcon } from '../cardItems/CardTextWithIcon'
import styles from './cards.module.scss'

interface IDefaultCard {
  actionCallback?: (_callback: IActionCallback) => void
  actions: EAction[]
  data: IChildScope
  greyBackground?: boolean
}

export const DefaultCard = ({ actions, actionCallback, data, greyBackground }: IDefaultCard) => {
  const {
    appContext: {
      style: { isMobile }
    }
  } = useApp()

  const desktopView = (
    <div className='ap-flex ap-text-neutral-17 justify-content-between'>
      <div className='ap-pr-spacing-3 w-100'>
        <CardTextWithIcon isUserItem={data.isUserItem} text={data.name} isMobile={isMobile} />
      </div>
      <CardActionButtonGroup
        actions={actions}
        actionCallback={actionCallback}
        data={data}
        id={data.childScopeId}
        isEnabled={data.isEnabled}
        isUserItem={data.isUserItem}
        isToggleDisabled={data.isToggleDisabled}
      />
    </div>
  )

  return (
    <SDKPanel
      className={classNames(
        styles['default-card-panel'],
        'dnd-card border-1 ap-my-spacing-3 ap-border-color-background-border',
        greyBackground && 'ap-bg-color-background-default'
      )}
    >
      {isMobile ? (
        <CardMobile
          actions={actions}
          actionCallback={actionCallback}
          data={data}
          isMobile={isMobile}
          id={data.childScopeId}
        />
      ) : (
        desktopView
      )}
    </SDKPanel>
  )
}
