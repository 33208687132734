import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Divider } from '../../../../../../../../components/Divider'
import { EmptyState } from '../../../../../../../../components/EmptyState'
import { LoadingSpinner } from '../../../../../../../../components/SDK/LoadingSpinner'
import { Url } from '../../../../../../../../constants/urls'
import { useApp } from '../../../../../../../../context/app.context'
import { useProject } from '../../../../../../../../context/project.context'
import { string2kebabCase } from '../../../../../../../../formatters/string2kebabCase'
import { LoadingStatus } from '../../../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../../../store'
import { EPricingToolMainTabHashUrl } from '../../../../../../constants/tab'
import { clearGetPricingResults, getPricingResults } from '../../../../store/actions'
import * as selectors from '../../../../store/selectors'
import { EFeeType } from '../../../../types/resultsTab'
import { ModuleCard } from '../components/ModuleCard'
import { OptionalScopes } from '../components/OptionalScopes'
import { ResultsContentWrapper } from '../components/ResultsContentWrapper'
import { TotalCard } from '../components/TotalCard'

export const ResultsContent = () => {
  const { t } = useTranslation('pricing')
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { project, refreshProject } = useProject()
  const { data, loading, error } = useSelector(selectors.getPricingResults)

  useEffect(() => {
    if (error && error?.length > 0) {
      sendNotification({
        message: error,
        status: 'error'
      })
    }
  }, [error])

  useEffect(() => {
    refreshProject(false)
    dispatch(clearGetPricingResults())

    if (project) {
      dispatch(getPricingResults({ projectId: project?.projectId }))
    }
  }, [])

  const feesForYearName = useMemo(() => {
    if (data?.cards) {
      return data?.cards
        .map(card => {
          if (card?.rows && card.rows.length) {
            return card.rows.map(el => el.fees[0])
          } else if (card.rowsWithTotal) {
            return card.rowsWithTotal[0].fees
          } else {
            return []
          }
        })
        .filter(item => item.length)[0]
    } else return []
  }, [data?.cards])

  const navigate = useNavigate()
  const iconOnClickHandler = (cardName: string) => () => {
    const baseUri = `${Url.MyProjectsPage}/${project?.projectId}#`
    const inputTabName = string2kebabCase(EPricingToolMainTabHashUrl.Input)
    const tabName = string2kebabCase(`pricing-${cardName}`)

    if (tabName !== inputTabName) {
      navigate(`${baseUri}${inputTabName}`)
    }
    setTimeout(() => {
      navigate(`${baseUri}${tabName}`)
    }, 1)
  }
  const navigateToModuleIconCode = 'edit'

  return loading === LoadingStatus.Pending ? (
    <LoadingSpinner />
  ) : data && project?.yearsCount ? (
    <ResultsContentWrapper
      fees={feesForYearName}
      title={t('tabs.results.name')}
      yearsCount={project.yearsCount}
    >
      {data.cards.map((card, index) => (
        <ModuleCard
          card={card}
          index={index}
          key={index}
          iconCode={navigateToModuleIconCode}
          iconOnClickHandler={iconOnClickHandler(card.name)}
        />
      ))}
      {[
        { subtotal: data.totalBeforeDiscountRow, showQuotesWithFractionDigits: false },
        { subtotal: data.totalDiscountRow, showQuotesWithFractionDigits: true },
        { subtotal: data.totalAfterDiscountRow, showQuotesWithFractionDigits: true }
      ].map((item, index) => (
        <div key={index}>
          {item.subtotal && (
            <TotalCard
              fees={item.subtotal?.fees}
              sum={item.subtotal.sum}
              type={item.subtotal.type}
              discountPercentage={item.subtotal.discountPercentage}
              showQuotesWithFractionDigits={item.showQuotesWithFractionDigits}
            />
          )}
        </div>
      ))}

      {!!data?.optionals?.cards && data?.optionals?.cards?.length > 0 && (
        <OptionalScopes
          data={data.optionals.cards}
          iconCode={navigateToModuleIconCode}
          iconOnClickHandler={iconOnClickHandler}
        />
      )}
      {data.totalOptionalsRow && (
        <TotalCard
          fees={data.totalOptionalsRow?.fees}
          sum={data.totalOptionalsRow.sum}
          type={data.totalOptionalsRow.type}
          discountPercentage={data.totalOptionalsRow.discountPercentage}
          showQuotesWithFractionDigits={false}
        />
      )}
      <div className='ap-mt-spacing-7 ap-py-spacing-3'>
        <Divider />
      </div>
      <TotalCard
        fees={data.totalRow.fees}
        sum={data.totalRow.sum}
        type={EFeeType.Total}
        showQuotesWithFractionDigits
      />
    </ResultsContentWrapper>
  ) : (
    <EmptyState />
  )
}
