import type { TSubcategory } from '../../../modules/countriesSnapshot/types'
import api from '../../api'
import { CountrySnapshotUrl, FiscalYearUrl } from '../../constants/urls'

export interface IAddSubcategoryPayload {
  name: string
}

export const addNewSubcategory = async (
  params: IAddSubcategoryPayload,
  yearId: number,
  categoryId: number
): Promise<TSubcategory> => {
  return (
    await api.post(
      `${FiscalYearUrl.Years}/${yearId}/${CountrySnapshotUrl.Categories}/${categoryId}/${CountrySnapshotUrl.Subcategories}`,
      params
    )
  ).data
}
