import { array, object } from 'yup'

export enum ELegalStatementsApprovalFields {
  approvedDocumentIds = 'approvedDocumentIds'
}
export interface IFormValues {
  [ELegalStatementsApprovalFields.approvedDocumentIds]: number[]
}

export const useLegalStatementsApprovalSchema = (count: number) => {
  const defaultValues: IFormValues = {
    [ELegalStatementsApprovalFields.approvedDocumentIds]: []
  }

  const validationSchema = object()
    .shape({
      [ELegalStatementsApprovalFields.approvedDocumentIds]: array().min(count).max(count)
    })
    .required()

  return { validationSchema, defaultValues }
}
