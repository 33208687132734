import type { FiscalYearInfo } from '../../../modules/fiscalYearSetup/types'
import api from '../../api'
import { FiscalYearUrl } from '../../constants/urls'

export interface IAddFiscalYearPayload {
  isActive: boolean
  name: number
  fiscalYearData: number
}

export const addFiscalYear = async (params: IAddFiscalYearPayload): Promise<FiscalYearInfo> => {
  return (await api.post(FiscalYearUrl.Years, params)).data
}
