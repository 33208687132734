import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RegionsAccordion } from '../../../../components/RegionsAccordion'
import { SDKLoading } from '../../../../components/SDK/Loading'
import { useApp } from '../../../../context/app.context'
import { LoadingStatus } from '../../../../shared/types/enums'
import { getYears } from '../../../fiscalYearSetup/store/selectors'
import { getRegions } from '../../store/selectors'
import { mapRegionsDataToSections } from '../../utils/mapRegionsDataToSections'
import { RegionsListDetails } from './components/RegionsListDetails'

import './index.scss'

interface IRegionsListProps {
  addRegion: (data: string) => void
  editRegion: (data: string, id: number) => void
  deleteRegion: (data: string, id: number) => void
}

export const RegionsList = ({ addRegion, editRegion, deleteRegion }: IRegionsListProps) => {
  const { error: regionsError, loading } = useSelector(getRegions)
  const { error: yearsModalError } = useSelector(getYears)
  const { sendNotification } = useApp()

  useEffect(() => {
    if (regionsError?.length) {
      sendNotification({
        message: regionsError,
        status: 'error'
      })
    }
  }, [regionsError])

  useEffect(() => {
    if (yearsModalError?.length) {
      sendNotification({
        message: yearsModalError,
        status: 'error'
      })
    }
  }, [yearsModalError])

  return (
    <div className='regions-list'>
      {loading === LoadingStatus.Pending && <SDKLoading />}
      <RegionsAccordion mapRegions={mapRegionsDataToSections}>
        {({ title, sectionRegions }) => (
          <RegionsListDetails
            regions={sectionRegions}
            addRegion={() => addRegion(title)}
            editRegion={editRegion}
            deleteRegion={deleteRegion}
            title={title}
          />
        )}
      </RegionsAccordion>
    </div>
  )
}
