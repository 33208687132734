import { MyProjectsContent } from '../..'
import { Layout } from '../../../../components/Layout'
import { ProjectContextProvider } from '../../../../context/project.context'
import { useProjectFromUrl } from '../../../../hooks/useProjectFromUrl'
import { ProjectSettingsContent } from '../../pages/projectSettings'
import type { IProjectCard } from '../../types'
import { MyProjectsHeaderButton } from '../MyProjectsHeaderButton'
import { FormMode } from './FormMode'
import { AddEditProjectModal } from './components/AddEditProjectModal'
import { useAddEditProject } from './utils/useAddEditProject'

interface IContentWrapperProps {
  mode: FormMode
}
const ContentWrapper = ({
  children,
  project,
  mode
}: { project: IProjectCard | null } & IContentWrapperProps & React.PropsWithChildren) => {
  return (
    <Layout
      breadcrumbs={{ project: project?.name! }}
      buttonComponent={!project && mode == FormMode.Add && <MyProjectsHeaderButton />}
    >
      {mode == FormMode.Add && <MyProjectsContent />}
      {mode == FormMode.Edit && project && <ProjectSettingsContent /> }
      {children}
    </Layout>
  )
}

interface IAddEditProjectContentProps {
  mode: FormMode
}

const AddEditProjectContent = ({mode}: IAddEditProjectContentProps) => {
  const { project } = useProjectFromUrl()
  const { cleanUp, onClose } = useAddEditProject(project?.projectId || null)

  return (
    <ContentWrapper project={project} mode={mode}>
      <AddEditProjectModal
        cleanUp={cleanUp}
        onClose={onClose}
        mode={mode}
        projectId={project?.projectId || null}
      />
    </ContentWrapper>
  )
}

interface IAddEditProjectProps {
  mode: FormMode
}

const AddEditProject = ({
  mode
}: IAddEditProjectProps) => (
  <ProjectContextProvider>
    <AddEditProjectContent mode={mode} />
  </ProjectContextProvider>
)

export default AddEditProject
