import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LoadingOverlay } from '../../../../../../components/SDK/LoadingOverlay'
import { SDKModal } from '../../../../../../components/SDK/Modal'
import { usePricingTab } from '../../../../../../context/pricingTab.context'
import { useProjectFromUrl } from '../../../../../../hooks/useProjectFromUrl'
import {
  EEScopeFields,
  useEditParentScopeSchema
} from '../../../../../../schemas/useEditParentScopeSchema'
import { useAppDispatch } from '../../../../../../store'
import type { IParentComplexScopeFull, IPriceYear } from '../../types/scope'
import * as actions from '../store/actions'
import { ParentScopeForm } from './components/ParentScopeForm'
import { EParentScopeModalType, getArrayOfYears, scopePriceYearsModel } from './const'
import { useParentScope } from './utils/useParentScope'

export const ParentScopeModal = ({
  onCloseModal,
  scope,
  visible
}: {
  onCloseModal: (params?: { refreshData: boolean }) => void
  scope: Partial<IParentComplexScopeFull>
  visible: boolean
}) => {
  const { t } = useTranslation('pricing')
  const [priceYears, setPriceYears] = useState<IPriceYear[]>([])
  const dispatch = useAppDispatch()
  const { project } = useProjectFromUrl()
  const [isPriceYearsFieldsValid, setPriceYearsFieldsValidity] = useState(false)
  const [isButtonDisabled, setButtonDisabled] = useState(false)
  const { priceYears: priceYearsList, scopeDefinitionLoading } = usePricingTab()
  const clearAction = scope?.parentScopeId
    ? actions.clearUpdateParentScope
    : actions.clearCreateParentScope

  const { complexitySelector, defaultValues, scopeLocationSelector, validationSchema } =
    useEditParentScopeSchema(scope)
  const { handleSubmit, setValue, getValues, control, reset, formState } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange'
  })
  function cleanUp(params?: { refreshData: boolean }) {
    dispatch(actions.clearGetParentScope())
    dispatch(clearAction())
    reset()
    setPriceYears([])
    onCloseModal(params)
  }
  const { onSubmit } = useParentScope(scope, cleanUp)

  useEffect(() => {
    if (project) {
      const newPriceYears = scopePriceYearsModel(
        getArrayOfYears(project.yearName, priceYearsList.length)
      )
      setPriceYears(() => newPriceYears)
    }
  }, [priceYearsList, project])

  useEffect(() => {
    let vals = getValues()
    let shouldBeDisabled =
      // 1a. common rule: the form must be dirty
      !formState.isDirty ||
      // 1b. common rule: the form must be valid
      !formState.isValid ||
      // 1c. common rule: the name is required
      vals[EEScopeFields.name].length === 0 ||
      // 2. scopePriceYearsFields
      !isPriceYearsFieldsValid ||
      // 3. local file
      (scope.type === EParentScopeModalType.LocalFile &&
        (vals[EEScopeFields.complexity] === '' || vals[EEScopeFields.scopeLocationType] === ''))

    if (!formState.isValidating) {
      setButtonDisabled(shouldBeDisabled)
    }
  }, [getValues()])

  return (
    <form>
      <SDKModal
        disabledSubmit={isButtonDisabled}
        onCancel={() => cleanUp()}
        onSubmit={handleSubmit(onSubmit)}
        title={
          t(
            `tabs.inputs.parentScopeModal.title.${scope?.parentScopeId ? 'edit' : 'add'}.${
              scope.type
            }`
          )!
        }
        visible={visible}
      >
        {scopeDefinitionLoading && <LoadingOverlay type='modal' />}

        <ParentScopeForm
          complexitySelector={complexitySelector}
          control={control}
          scopeLocationSelector={scopeLocationSelector}
          getValues={getValues}
          onPriceYearsFieldsValidity={is => setPriceYearsFieldsValidity(is)}
          priceYears={priceYears}
          reset={reset}
          scope={scope}
          setValue={setValue}
          type={EParentScopeModalType.ParentScope}
        />
      </SDKModal>
    </form>
  )
}
