import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Module } from '../../../../../../constants/modules'
import { createChildScopeRequest } from '../../../../../../services/requests/pricing/createChildScope'
import { createParentScopeRequest } from '../../../../../../services/requests/pricing/createParentScope'
import { deleteCustomModalRequest } from '../../../../../../services/requests/pricing/deleteCustomModalRequest'
import { getLocalFileResultsRequest } from '../../../../../../services/requests/pricing/getLocalFileResultsRequest'
import { getLocalFileScopePricingData } from '../../../../../../services/requests/pricing/getLocalFileScopePricingData'
import { getParentScopeRequest } from '../../../../../../services/requests/pricing/getParentScope'
import { getScopeDefinitionData } from '../../../../../../services/requests/pricing/getScopeDefinitionData'
import {
  updateBusinessUnitRequest,
  type IUpdateBusinessUnitRequestParams
} from '../../../../../../services/requests/pricing/updateBusinessUnitRequest'
import { updateChildScopeRequest } from '../../../../../../services/requests/pricing/updateChildScope'
import type { IChildScopeOrderParams } from '../../../../../../services/requests/pricing/updateChildScopeOrderRequest'
import { updateChildScopeOrderRequest } from '../../../../../../services/requests/pricing/updateChildScopeOrderRequest'
import { updateChildScopeToggleRequest } from '../../../../../../services/requests/pricing/updateChildScopeToggleRequest'
import type { IMetadataParams } from '../../../../../../services/requests/pricing/updateModuleMetadataRequest'
import { updateModuleMetadataRequest } from '../../../../../../services/requests/pricing/updateModuleMetadataRequest'
import { updateParentScopeRequest } from '../../../../../../services/requests/pricing/updateParentScope'
import { updateParentScopeOrderRequest } from '../../../../../../services/requests/pricing/updateParentScopeOrderRequest'
import { updateParentScopeToggleRequest } from '../../../../../../services/requests/pricing/updateParentScopeToggleRequest'
import {
  updateTransactionsRequest,
  type IUpdateTransactionsRequestParams
} from '../../../../../../services/requests/pricing/updateTransactionsRequest'
import { uploadLocalFileRequest } from '../../../../../../services/requests/pricing/uploadLocalFileRequest'
import { EComplexity, EPricingTab, EScope } from '../../const/enums'
import type { IChildScope, TParentScopePayload } from '../../types/scope'
import { EParentScopeModalType } from '../ParentScopeModal/const'

const moduleName: string = String(Module.PricingHelper)

export const getScopeMetadata = createAsyncThunk(
  `${moduleName}/getScopeMetadata`,
  ({ projectId }: { projectId: number }) => getScopeDefinitionData(projectId)
)
export const clearGetScopeMetadata = createAction(`${moduleName}/clearGetScopeMetadata`)
export const createChildScope = createAsyncThunk(
  `${moduleName}/createChildScope`,
  ({
    projectId,
    scope,
    tab
  }: {
    scope: Partial<IChildScope>
    projectId: number
    tab: EPricingTab
  }) => createChildScopeRequest(projectId, tab, scope)
)
export const clearCreateChildScope = createAction(`${moduleName}/clearCreateChildScope`)
export const updateChildScope = createAsyncThunk(
  `${moduleName}/updateChildScope`,
  ({ projectId, scope, tab }: { projectId: number; scope: IChildScope; tab: EPricingTab }) =>
    updateChildScopeRequest(projectId, tab, scope)
)
export const clearUpdateChildScope = createAction(`${moduleName}/clearUpdateChildScope`)

export const createParentScope = createAsyncThunk(
  `${moduleName}/createParentScope`,
  ({
    projectId,
    scope,
    tab
  }: {
    projectId: number
    scope: Partial<TParentScopePayload>
    tab: EPricingTab
  }) => createParentScopeRequest(projectId, tab, scope)
)
export const clearCreateParentScope = createAction(`${moduleName}/clearCreateParentScope`)

export const getParentScope = createAsyncThunk(
  `${moduleName}/getParentScope`,
  ({
    projectId,
    parentScopeId,
    tab,
    type,
    customModuleId
  }: {
    projectId: number
    parentScopeId: number | string
    tab: EPricingTab
    type: EParentScopeModalType
    customModuleId?: number | null
  }) => getParentScopeRequest(projectId, tab, parentScopeId, type, customModuleId)
)
export const clearGetParentScope = createAction(`${moduleName}/clearGetParentScope`)

export const updateParentScope = createAsyncThunk(
  `${moduleName}/updateParentScope`,
  ({
    projectId,
    scope,
    tab
  }: {
    projectId: number
    scope: TParentScopePayload
    tab: EPricingTab
  }) => updateParentScopeRequest(projectId, tab, scope)
)
export const clearUpdateParentScope = createAction(`${moduleName}/clearUpdateParentScope`)

export const updateChildScopeToggle = createAsyncThunk(
  `${moduleName}/updateChildScopeToggle`,
  ({
    projectId,
    childScopeId,
    tabName,
    customModuleId
  }: {
    projectId: number
    childScopeId: number
    tabName: EPricingTab
    customModuleId?: number | null
  }) => updateChildScopeToggleRequest(projectId, childScopeId, tabName, customModuleId)
)
export const clearUpdateChildScopeToggle = createAction(`${moduleName}/clearUpdateChildScopeToggle`)

export const updateParentScopeToggle = createAsyncThunk(
  `${moduleName}/updateParentScopeToggle`,
  ({
    projectId,
    parentScopeId,
    tabName,
    customModuleId
  }: {
    projectId: number
    parentScopeId: number
    tabName: EPricingTab
    customModuleId?: number | null
  }) => updateParentScopeToggleRequest(projectId, parentScopeId, tabName, customModuleId)
)
export const clearUpdateParentScopeToggle = createAction(
  `${moduleName}/clearUpdateParentScopeToggle`
)

export const updateChildScopeOrder = createAsyncThunk(
  `${moduleName}/updateChildScopeOrder`,
  ({
    projectId,
    childScopeId,
    tabName,
    params
  }: {
    projectId: number
    childScopeId: number
    tabName: EPricingTab
    params: IChildScopeOrderParams
  }) => updateChildScopeOrderRequest(projectId, childScopeId, tabName, params)
)
export const clearUpdateChildScopeOrder = createAction(`${moduleName}/clearUpdateChildScopeOrder`)

export const updateParentScopeOrder = createAsyncThunk(
  `${moduleName}/updateParentScopeOrder`,
  ({
    customModuleId,
    projectId,
    parentScopeId,
    tabName,
    order
  }: {
    customModuleId?: number | null
    projectId: number
    parentScopeId: number
    tabName: EPricingTab
    order: number
  }) => updateParentScopeOrderRequest(projectId, parentScopeId, tabName, order, customModuleId)
)
export const clearUpdateParentScopeOrder = createAction(`${moduleName}/clearUpdateParentScopeOrder`)

export const updateModuleMetadata = createAsyncThunk(
  `${moduleName}/updateModuleMetadata`,
  ({
    projectId,
    tabName,
    customModuleId,
    params
  }: {
    projectId: number
    tabName: EPricingTab
    params: IMetadataParams
    customModuleId?: number
  }) => updateModuleMetadataRequest(projectId, tabName, params, customModuleId)
)
export const clearUpdateModuleMetadata = createAction(`${moduleName}/clearUpdateModuleMetadata`)

export const updateBusinessUnit = createAsyncThunk(
  `${moduleName}/updateBusinessUnit`,
  ({ projectId, params }: { projectId: number; params: IUpdateBusinessUnitRequestParams }) =>
    updateBusinessUnitRequest(projectId, params)
)
export const clearUpdateBusinessUnit = createAction(`${moduleName}/clearUpdateBusinessUnit`)

export const updateTransactions = createAsyncThunk(
  `${moduleName}/updateTransactions`,
  ({ projectId, params }: { projectId: number; params: IUpdateTransactionsRequestParams }) =>
    updateTransactionsRequest(projectId, params)
)
export const clearUpdateTransactions = createAction(`${moduleName}/clearUpdateTransactions`)

export const uploadLocalFile = createAsyncThunk(
  `${moduleName}/uploadLocalFile`,
  ({ projectId, importFile }: { projectId: number; importFile: File }) =>
    uploadLocalFileRequest(projectId, importFile)
)
export const clearUploadLocalFile = createAction(`${moduleName}/clearUploadLocalFile`)

export const getLocalFileResults = createAsyncThunk(
  `${moduleName}/getLocalFileResults`,
  ({ projectId }: { projectId: number }) => getLocalFileResultsRequest(projectId)
)
export const clearGetLocalFileResults = createAction(`${moduleName}/clearGetLocalFileResults`)

export const getLocalFileScopePricing = createAsyncThunk(
  `${moduleName}/getLocalFileScopePricing`,
  ({
    projectId,
    countryId,
    scopeLocationType,
    complexity,
    initialYear
  }: {
    projectId: number
    countryId: number
    scopeLocationType: EScope
    complexity: EComplexity
    initialYear: number
  }) => {
    if (!countryId || !scopeLocationType || !complexity || !initialYear) {
      return null
    }

    return getLocalFileScopePricingData(
      projectId,
      countryId,
      scopeLocationType,
      complexity,
      initialYear
    )
  }
)

export const getLocalFileScopeTranslationPricing = createAsyncThunk(
  `${moduleName}/getLocalFileScopeTranslationPricing`,
  ({
    projectId,
    countryId,
    scopeLocationType,
    complexity,
    initialYear
  }: {
    projectId: number
    countryId: number
    scopeLocationType: EScope
    complexity: EComplexity
    initialYear: number
  }) => {
    if (!countryId || !scopeLocationType || !complexity || !initialYear) {
      return null
    }

    return getLocalFileScopePricingData(
      projectId,
      countryId,
      scopeLocationType,
      complexity,
      initialYear
    )
  }
)

export const clearGetLocalFileScopePricing = createAction(
  `${moduleName}/clearGetLocalFileScopePricing`
)

export const clearGetLocalFileScopeTranslationPricing = createAction(
  `${moduleName}/clearGetLocalFileScopeTranslationPricing`
)

export const deleteCustomModal = createAsyncThunk(
  `${moduleName}/deleteCustomModal`,
  ({ projectId, customModuleId }: { projectId: number; customModuleId: number }) =>
    deleteCustomModalRequest(projectId, customModuleId)
)
export const clearDeleteCustomModal = createAction(`${moduleName}/clearDeleteCustomModal`)
