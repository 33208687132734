import type { IFormValues } from '../../../../../../../schemas/useEditParentScopeSchema'
import { EBenchmarkingType, EComplexity, EPricingTab, EScope } from '../../../const/enums'
import type {
  IParentComplexScopeFull,
  IParentScope,
  IPriceYear,
  TParentScope,
  TParentScopePayload
} from '../../../types/scope'

export enum EPriceYearsColumns {
  CentralFeeValue = 'centralFeeValue',
  LocalFeeCountryId = 'localFeeCountryId',
  LocalFeeValue = 'localFeeValue',
  TotalFeeValue = 'totalFeeValue'
}

export const PARENT_SCOPE_COLUMNS = [
  EPriceYearsColumns.TotalFeeValue,
  EPriceYearsColumns.CentralFeeValue,
  EPriceYearsColumns.LocalFeeValue
]

export enum EParentScopeModalType {
  Benchmark = 'benchmark',
  CustomModule = 'customModule',
  LocalFile = 'localfile',
  ParentScope = 'parentScope',
  TPForms = 'tPForms',
  Transaction = 'transaction'
}

export const parentScopeModel = (scope: Partial<IParentScope>): Partial<IParentScope> => {
  const defaultParentScopeModel: Partial<IParentScope> = {
    parentScopeId: undefined,
    priceYears: [],
    countryName: '',
    order: 1,
    name: '',
    isEnabled: true,
    isUserItem: true
  }

  return { ...defaultParentScopeModel, ...scope }
}

export const findLocalFeeCountryId = (scope: Partial<TParentScope>, translations = false) => {
  let list: IPriceYear[] = []

  if (translations && scope.priceYearsTranslations) {
    list = scope.priceYearsTranslations
  } else {
    list = scope?.priceYears || []
  }

  if (list.length === 0) {
    return 0
  }

  return list[0].localFeeCountryId
}

export const parentScopeCircumscribedModel = (
  scope: Partial<TParentScope>,
  type: EParentScopeModalType
): Partial<IParentComplexScopeFull> => {
  switch (type) {
    case EParentScopeModalType.Benchmark:
      return {
        benchmarkingType: EBenchmarkingType.Global,
        localFeeCountryId: findLocalFeeCountryId(scope),
        name: '',
        priceYears: [],
        ...scope,
        type
      }

    case EParentScopeModalType.CustomModule:
      return {
        localFeeCountryId: findLocalFeeCountryId(scope),
        name: '',
        priceYears: [],
        ...scope,
        type
      }

    case EParentScopeModalType.LocalFile:
      return {
        complexity: EComplexity.Medium,
        localFeeCountryId: findLocalFeeCountryId(scope),
        localFeeCountryIdTranslations: findLocalFeeCountryId(scope, true),
        name: '',
        priceYears: [],
        scopeLocationType: undefined,
        translation: scope.translation || false,
        ...scope,
        priceYearsTranslations: scope.priceYearsTranslations,
        type
      }

    case EParentScopeModalType.TPForms:
      return {
        countryId: 0,
        name: '',
        priceYears: [],
        ...scope,
        type
      }

    case EParentScopeModalType.Transaction:
      return {
        localFeeCountryId: findLocalFeeCountryId(scope),
        name: '',
        priceYears: [],
        ...scope,
        type
      }

    default:
      return {
        localFeeCountryId: findLocalFeeCountryId(scope),
        name: '',
        priceYears: [],
        ...scope,
        type: EParentScopeModalType.ParentScope
      }
  }
}

export const transformPriceYearsForPayload = (schema: IPriceYear[], data: IFormValues) => {
  const localFeeCountryId = Number(data.localFeeCountryId)
  const priceYearData = data.priceYears

  return schema.map((priceYear, priceYearIndex) => ({
    centralFeeValue: priceYearData[priceYearIndex].centralFeeValue
      ? Number(priceYearData[priceYearIndex].centralFeeValue)
      : 0,
    localFeeCountryId,
    localFeeValue: priceYearData[priceYearIndex].localFeeValue
      ? Number(priceYearData[priceYearIndex].localFeeValue)
      : 0,
    totalFeeValue: priceYearData[priceYearIndex].totalFeeValue
      ? Number(priceYearData[priceYearIndex].totalFeeValue)
      : 0,
    yearName: priceYear.yearName
  }))
}

export const transformPriceYearsTranslationsForPayload = (
  data: IFormValues,
  translations: boolean
) => {
  if (!translations) {
    return []
  }

  const localFeeCountryId = Number(data.localFeeCountryIdTranslations)
  const priceYearData = data.priceYearsTranslations

  return priceYearData.map(priceYear => ({
    centralFeeValue: priceYear.centralFeeValue ? Number(priceYear.centralFeeValue) : 0,
    localFeeCountryId,
    localFeeValue: priceYear.localFeeValue ? Number(priceYear.localFeeValue) : 0,
    totalFeeValue: priceYear.totalFeeValue ? Number(priceYear.totalFeeValue) : 0,
    yearName: priceYear.yearName
  }))
}

export const parentScopePayloadModel = (
  scope: IFormValues,
  originalScope: Partial<IParentComplexScopeFull>
): TParentScopePayload => {
  switch (originalScope.type) {
    case EParentScopeModalType.Benchmark:
      return {
        benchmarkingType: originalScope.benchmarkingType!,
        name: scope.name ?? '',
        parentScopeId: originalScope.parentScopeId || undefined,
        priceYears: transformPriceYearsForPayload(originalScope.priceYears!, scope),
        type: EParentScopeModalType.Benchmark
      }

    case EParentScopeModalType.CustomModule:
      return {
        customModuleId: originalScope.customModuleId!,
        name: scope.name ?? '',
        parentScopeId: originalScope.parentScopeId || undefined,
        priceYears: transformPriceYearsForPayload(originalScope.priceYears!, scope),
        type: EParentScopeModalType.CustomModule
      }

    case EParentScopeModalType.LocalFile:
      return {
        complexity: Number(scope?.complexity) as EComplexity,
        countryId: Number(originalScope.countryId),
        name: originalScope.name ?? '',
        parentScopeId: originalScope.parentScopeId || undefined,
        priceYears: transformPriceYearsForPayload(originalScope.priceYears!, scope),
        priceYearsTranslations: transformPriceYearsTranslationsForPayload(scope, scope.translation),
        scopeLocationType: Number(scope?.scopeLocationType) as EScope,
        translation: scope?.translation || false,
        type: EParentScopeModalType.LocalFile
      }

    case EParentScopeModalType.TPForms:
      return {
        countryId: Number(scope.countryId),
        name: scope.name ?? '',
        parentScopeId: originalScope.parentScopeId || undefined,
        priceYears: transformPriceYearsForPayload(originalScope.priceYears!, scope),
        type: EParentScopeModalType.TPForms
      }

    case EParentScopeModalType.Transaction:
      return {
        id: originalScope.parentScopeId || undefined,
        priceYears: transformPriceYearsForPayload(originalScope.priceYears!, scope),
        type: EParentScopeModalType.Transaction
      }

    default:
      return {
        name: scope.name ?? '',
        parentScopeId: originalScope.parentScopeId || undefined,
        priceYears: transformPriceYearsForPayload(originalScope.priceYears!, scope),
        type: EParentScopeModalType.ParentScope
      }
  }
}

export const scopePriceYearsModel = (
  years: (string | number)[],
  data?: IPriceYear[]
): IPriceYear[] => {
  return years.map(yearName => {
    const defaultYearData = {
      yearName: Number(yearName),
      centralFeeValue: 0,
      localFeeValue: 0,
      totalFeeValue: 0
    } as IPriceYear
    const yearData = data?.find(year => Number(year.yearName) === Number(yearName))

    return { ...defaultYearData, ...yearData }
  })
}

export const getArrayOfYears = (startYear: number, yearsCount: number): number[] =>
  Array.from(Array(yearsCount).keys()).map(y => y + startYear)

export const getParentScopeModalTab = (tab: EPricingTab) => {
  switch (tab) {
    case EPricingTab.Benchmark:
      return EParentScopeModalType.Benchmark

    case EPricingTab.CustomModule:
      return EParentScopeModalType.CustomModule

    case EPricingTab.LocalFile:
      return EParentScopeModalType.LocalFile

    case EPricingTab.TPForms:
      return EParentScopeModalType.TPForms

    default:
      return EParentScopeModalType.ParentScope
  }
}
