import { IAddEditLegalDocument } from '../../../modules/systemManagement/types'
import api from '../../api'
import { SystemManagementUrl } from '../../constants/urls'

export interface IUpdateLegalDocumentPayload {
  documentId: number
  name: string
  content: string
  requiresApproval: boolean
}

export const updateLegalDocumentData = async (
  params: IUpdateLegalDocumentPayload
): Promise<IAddEditLegalDocument> => {
  return (await api.put(`${SystemManagementUrl.LegalDocuments}`, params)).data
}
