import type { IChildScope } from '../../../types/scope'

export const childScopeModel = (scope: Partial<IChildScope>): Partial<IChildScope> => {
  const defaultChildScopeModel: Partial<IChildScope> = {
    childScopeId: undefined,
    scopeType: 1,
    order: 1,
    name: '',
    isEnabled: true,
    isUserItem: true
  }

  return { ...defaultChildScopeModel, ...scope }
}
