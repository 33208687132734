import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { SDKList } from '../../../../components/SDK/List'
import { SDKLoading } from '../../../../components/SDK/Loading'
import { useApp } from '../../../../context/app.context'
import { LoadingStatus } from '../../../../shared/types/enums'
import { useAppDispatch } from '../../../../store'
import { EQuestionToDelete } from '../../../questionnaires/pages/Questions/components/ListHeaderButtons/constants'
import * as actions from '../../store/actions'
import * as selectors from '../../store/selectors'
import { TQuestion } from '../../types'
import { DeleteQuestionModal } from '../DeleteQuestionModal'
import { QuestionItem } from '../QuestionItem'
import './index.scss'

interface IQuestionsListProps {
  yearId: number
  questions: TQuestion[]
  openEditQuestion: (qId: number) => void
}

export const QuestionsList = ({ questions, yearId, openEditQuestion }: IQuestionsListProps) => {
  const { sendNotification } = useApp()
  const { t } = useTranslation('countriesSnapshot')
  const dispatch = useAppDispatch()
  const { error, loading } = useSelector(selectors.swapQuestionsOrder)

  const [questionToDelete, setQuestionToDelete] = useState<TQuestion | null>(null)
  const [currentQuestions, setCurrentQuestions] = useState<TQuestion[]>([])

  useEffect(() => {
    return () => {
      dispatch(actions.clearSwapQuestionsOrder())
    }
  }, [])

  useEffect(() => {
    setCurrentQuestions(questions)
  }, [questions])

  useEffect(() => {
    if (error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
    }
    if (loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t('swapQuestionsOrderSuccessful'),
        status: 'success'
      })
    }
  }, [error, loading])

  const openDeleteQuestion = (id: number) => {
    const currentQuestion = questions.find(q => q.questionId === id)
    if (currentQuestion) {
      setQuestionToDelete(currentQuestion)
    }
  }

  const moveQuestionUp = (index: number) => {
    if (index <= 0) {
      return
    }

    const previousQuestion = currentQuestions[index - 1]
    const nextQuestion = currentQuestions[index]

    const newQuestions = [...currentQuestions]
    newQuestions[index - 1] = nextQuestion
    newQuestions[index] = previousQuestion

    setCurrentQuestions(newQuestions)

    dispatch(
      actions.swapQuestionsOrder({
        previousQuestionId: previousQuestion.questionId,
        nextQuestionId: nextQuestion.questionId,
        yearId
      })
    )
  }

  const moveQuestionDown = (index: number) => {
    if (index >= questions?.length) {
      return
    }

    const previousQuestion = currentQuestions[index]
    const nextQuestion = currentQuestions[index + 1]

    const newQuestions = [...currentQuestions]
    newQuestions[index] = nextQuestion
    newQuestions[index + 1] = previousQuestion

    setCurrentQuestions(newQuestions)

    dispatch(
      actions.swapQuestionsOrder({
        previousQuestionId: previousQuestion.questionId,
        nextQuestionId: nextQuestion.questionId,
        yearId
      })
    )
  }

  const renderItem = (item: TQuestion, index: number) => {
    return (
      <div
        key={`question-id-${item.questionId}`}
        className='questions-list-item ap-my-spacing-2 ap-p-spacing-4'
      >
        <QuestionItem
          question={{ ...item, possibleAnswers: [] }}
          openEditQuestion={openEditQuestion}
          openDeleteQuestion={openDeleteQuestion}
          index={index}
          canMoveUp={index > 0}
          canMoveDown={index + 1 < questions?.length}
          moveQuestionUp={moveQuestionUp}
          moveQuestionDown={moveQuestionDown}
        />
      </div>
    )
  }
  return (
    <>
      {loading === LoadingStatus.Pending && <SDKLoading />}
      {loading !== LoadingStatus.Pending && (
        <SDKList
          data={currentQuestions}
          itemKey={'questionId'}
          renderItem={renderItem}
          className='questions-list'
        ></SDKList>
      )}
      {questionToDelete !== null && (
        <DeleteQuestionModal
          yearId={yearId}
          questionId={questionToDelete.questionId}
          categoryId={questionToDelete.category.questionCategoryId}
          subcategoryId={questionToDelete.subcategory.questionSubcategoryId}
          questionType={EQuestionToDelete[questionToDelete.type as keyof typeof EQuestionToDelete]}
          setQuestionToDelete={setQuestionToDelete}
        />
      )}
    </>
  )
}
