import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SDKText } from '../../../../../../components/SDK/Text'
import { useAppDispatch } from '../../../../../../store'
import { EPricingTab } from '../../const/enums'
import { clearGetProjectSetup } from '../../store/actions'
import { ScopeForm } from './ScopeForm'

export const ScopeDefinitionForm = () => {
  const { t } = useTranslation('pricing')
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(clearGetProjectSetup())
    }
  }, [])

  return (
    <div className='ap-bg-color-background-alt border-1 ap-border-radius-3 ap-border-color-background-border ap-p-spacing-5 ap-mt-spacing-3 ap-mb-spacing-5 ap-border-radius-2 ap-flex flex-column'>
      <SDKText type='heading' weight={2} className='ap-text-color-text-heading'>
        {t(`inputs.tabs.${EPricingTab.ScopeDefinition}.tabTitle`)}
      </SDKText>

      <ScopeForm />
    </div>
  )
}
