import { useEffect } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FormCheckbox } from '../../../../../components/Form/FormCheckbox'
import { FormSelect } from '../../../../../components/Form/FormSelect'
import { FormUploadInput } from '../../../../../components/Form/FormUploadInput'
import { SDKButton } from '../../../../../components/SDK/Button'
import { SDKIcon } from '../../../../../components/SDK/Icon'
import { LoadingSpinner } from '../../../../../components/SDK/LoadingSpinner'
import { SDKModal } from '../../../../../components/SDK/Modal'
import { SDKTooltip } from '../../../../../components/SDK/Tooltip'
import { useApp } from '../../../../../context/app.context'
import { EUploadBySightlineWithCalculationFields } from '../../../../../schemas/useUploadBySightlineWithCalculation'
import { EUploadTemplateWithCalculationFields } from '../../../../../schemas/useUploadTemplateWithCalculation'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../store'
import { MAX_XLSX_FILE_SIZE } from '../../../constants'
import * as actions from '../../../store/actions'
import { getSightlineFiles } from '../../../store/selectors'

export enum CalculationActionType {
  Basic = 'basic',
  Sightline = 'sightline'
}
interface ILoadingModalProps<T> {
  setVisible: (isVisible: boolean) => void
  onSubmit: () => Promise<void>
  title: string
  type: CalculationActionType
  isBtnDisabled: boolean
  control: Control<FieldValues, T>
}

export const UploadModal = <T,>({
  setVisible,
  title,
  isBtnDisabled,
  type,
  onSubmit,
  control
}: ILoadingModalProps<T>) => {
  const { t } = useTranslation('myprojects')
  const dispatch = useAppDispatch()
  const { project: projectId } = useParams()
  const { sendNotification } = useApp()

  const checkboxName =
    type === CalculationActionType.Basic
      ? EUploadTemplateWithCalculationFields.dateOnly
      : EUploadBySightlineWithCalculationFields.dateOnly

  const getContent = () => {
    switch (type) {
      case CalculationActionType.Basic:
        return (
          <FormUploadInput
            name={EUploadTemplateWithCalculationFields.inputFile}
            control={control}
            label={t('projectDetail.projectTabs.tara.input.uploadInstruction')!}
            acceptFileType='.xlsx'
            maxFileSize={MAX_XLSX_FILE_SIZE}
          />
        )
      case CalculationActionType.Sightline:
        const {
          data: filesList,
          error: filesListError,
          loading: filesListLoading
        } = useSelector(getSightlineFiles)
        const mappedFilesList = filesList.map(file => ({ label: file.name, value: file.id }))
        useEffect(() => {
          if (type === CalculationActionType.Sightline && projectId) {
            dispatch(actions.getSightlineFiles(Number(projectId)))
          }
        }, [])

        useEffect(() => {
          if (filesListError?.length) {
            sendNotification({
              message: String(filesListError),
              status: 'error'
            })
          }
        }, [filesListError])

        return (
          <div className='ap-mt-spacing-5'>
            <FormSelect
              name={EUploadBySightlineWithCalculationFields.sightlineInputFileId}
              control={control}
              label={t('projectDetail.projectTabs.tara.input.selectLabel')!}
              data={mappedFilesList}
              searchable={true}
              portalMode
              noResultFound={
                filesListLoading === LoadingStatus.Pending ? (
                  <LoadingSpinner message={t('common:loading')!} />
                ) : (
                  t('projectDetail.projectTabs.tara.input.selectNoFile')!
                )
              }
            />
          </div>
        )
    }
  }

  return (
    <SDKModal
      className='upload-modal'
      visible={true}
      onCancel={() => setVisible(false)}
      title={title}
      withRequired={false}
      footer={
        <SDKButton disabled={isBtnDisabled} type='submit' onClick={() => onSubmit()}>
          {t('projectDetail.projectTabs.tara.input.continueButton')}
        </SDKButton>
      }
    >
      <div className='ap-flex flex-column w-100 ap-mt-spacing-3 ap-mb-spacing-7'>
        <FormCheckbox
          control={control}
          name={checkboxName}
          label={t('projectDetail.projectTabs.tara.input.checkboxDesc')!}
        />
        <SDKTooltip content={t('projectDetail.projectTabs.tara.input.uploadTooltipText')!}>
          <div className='pointer'>
            <SDKIcon code='information' className='ap-ml-spacing-2' />
          </div>
        </SDKTooltip>
      </div>
      {getContent()}
    </SDKModal>
  )
}
