import { useMemo } from 'react'
import { boolean, object, string } from 'yup'
import { IFormulaCountry } from '../modules/TaraFields/types'

export enum EAddEditCountryFieldOverrideFields {
  country = 'country',
  gateQuestion = 'gateQuestion',
  fieldName = 'fieldName',
  newLogic = 'newLogic',
  inputData = 'inputData',
  isActive = 'isActive'
}

export interface IFormValues {
  [EAddEditCountryFieldOverrideFields.country]: string
  [EAddEditCountryFieldOverrideFields.gateQuestion]: number
  [EAddEditCountryFieldOverrideFields.fieldName]: string
  [EAddEditCountryFieldOverrideFields.newLogic]: string
  [EAddEditCountryFieldOverrideFields.inputData]: string
  [EAddEditCountryFieldOverrideFields.isActive]: boolean
}

export const useAddEditCountryFieldOverrideSchema = (data: IFormulaCountry | null) => {
  const getDefaultValues = (obj: IFormulaCountry | null) => {
    return {
      [EAddEditCountryFieldOverrideFields.country]: obj?.countryName || '',
      [EAddEditCountryFieldOverrideFields.gateQuestion]: obj?.gateQuestion || null,
      [EAddEditCountryFieldOverrideFields.fieldName]: obj?.taraFieldName || '',
      [EAddEditCountryFieldOverrideFields.newLogic]: obj?.extended || '',
      [EAddEditCountryFieldOverrideFields.inputData]: '{\n\n\n}',
      [EAddEditCountryFieldOverrideFields.isActive]: obj?.isActive || false
    }
  }

  const defaultValues = useMemo(() => getDefaultValues(data), [data])

  const validationSchema = object()
    .shape({
      [EAddEditCountryFieldOverrideFields.country]: string().required(),
      [EAddEditCountryFieldOverrideFields.fieldName]: string().required(),
      [EAddEditCountryFieldOverrideFields.newLogic]: string(),
      [EAddEditCountryFieldOverrideFields.inputData]: string(),
      [EAddEditCountryFieldOverrideFields.isActive]: boolean()
    })
    .required()

  return { validationSchema, defaultValues, getDefaultValues }
}
