import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo, useState } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormCard } from '../../../../../../components/Form/FormCard'
import { Layout } from '../../../../../../components/Layout'
import {
  EAddQuestionFields,
  getQuestionDefaultValues,
  IFormValues,
  useAddQuestionSchema
} from '../../../../../../schemas/useAddQuestionSchema'
import { useAppDispatch } from '../../../../../../store'
import { IAnswer } from '../../../../../project/types/projectResults'
import * as actions from '../../../../store/actions'
import * as selectors from '../../../../store/selectors'
import { TCategory, TQuestion, TSubcategory } from '../../../../types'
import { AnswerTypeComponent } from '../../components/AnswerTypeComponent'
import { QuestionInfoComponent } from '../../components/QuestionInfo'
import { EAnswerType, EAnswerTypeForEdit } from '../../constants/answerType'
import { useAddEditQuestion } from '../../utils/useAddEditQuestion'
import { QuestionDependenciesComponent } from '../QuestionDependencies'
import QuestionHeader from '../QuestionHeader'
import './index.scss'

interface IAddEditQuestionFormProps {
  yearId: number
  questionId?: number
}

export const AddEditQuestionForm = ({ yearId, questionId }: IAddEditQuestionFormProps) => {
  const dispatch = useAppDispatch()
  const question = questionId && useSelector(selectors.getQuestionById(questionId))
  const { t } = useTranslation('countriesSnapshot')
  const [type, setType] = useState(EAnswerType.Single)
  const { validationSchema, defaultValues } = useAddQuestionSchema(type, questionId)
  const [category, setCategory] = useState<TCategory | null>(null)
  const [subcategory, setSubcategory] = useState<TSubcategory | null>(null)
  const [possibleAnswers, setPossibleAnswers] = useState<IAnswer[]>([])
  const [parentQuestion, setParentQuestion] = useState<TQuestion | null>(null)

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    trigger,
    getValues,
    formState: { isValid, isDirty },
    resetField
  } = useForm<FieldValues, IFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'all',
    reValidateMode: 'onChange'
  })
  const setValuesAfterRefresh = (currentQuestion: TQuestion) => {
    const currentType = Object.keys(EAnswerTypeForEdit).find(
      key => EAnswerTypeForEdit[key as keyof typeof EAnswerTypeForEdit] === currentQuestion.type
    ) as keyof typeof EAnswerType
    setType(EAnswerType[currentType])
    setPossibleAnswers(currentQuestion.possibleAnswers)
    const values = getQuestionDefaultValues(EAnswerType[currentType], currentQuestion)
    Object.entries(values).forEach(([name, value]) => setValue(name, value))
  }

  useEffect(() => {
    reset(defaultValues)
  }, [])

  useEffect(() => {
    if (!question) {
      dispatch(actions.getQuestions(yearId))
    }
    if (questionId && !isDirty && !getValues(EAddQuestionFields.question).length && question) {
      setValuesAfterRefresh(question)
    }
  }, [questionId, yearId, question])

  const isSaveEnabled = useMemo(() => {
    if (isDirty) {
      setValue(EAddQuestionFields.editMode, true)
    }
    if (questionId === undefined || isDirty) return validationSchema.isValidSync(getValues())
    else return false
  }, [validationSchema, isValid, isDirty, type])

  const { onSubmit } = question
    ? useAddEditQuestion(
        reset,
        question.category?.questionCategoryId,
        question.subcategory?.questionSubcategoryId,
        yearId,
        questionId,
        question.questionConditions
      )
    : useAddEditQuestion(
        reset,
        category?.categoryId,
        subcategory?.subcategoryId,
        yearId,
        questionId,
        []
      )

  return (
    <>
      <Layout
        buttonComponent={
          <QuestionHeader onButtonClick={handleSubmit(onSubmit)} isValid={isSaveEnabled} />
        }
        tab='#questions'
      >
        <div className='add-question-form'>
          <div className='form-container'>
            <div className='row ap-my-spacing-2 add-question-container'>
              <div className='col ap-my-spacing-5'>
                <FormCard withRequired title={t('newQuestion.form.questionInfo')}>
                  <QuestionInfoComponent
                    control={control}
                    yearId={yearId}
                    resetField={resetField}
                    category={category}
                    setCategory={setCategory}
                    setSubcategory={setSubcategory}
                    isEditMode={!!questionId}
                    defaultValues={defaultValues}
                  />
                </FormCard>
              </div>
              <div className='col ap-my-spacing-5'>
                <FormCard withRequired title={t('newQuestion.form.answerType')}>
                  <AnswerTypeComponent
                    defaultValues={defaultValues}
                    control={control}
                    resetField={resetField}
                    type={type}
                    setType={setType}
                    setValue={setValue}
                    trigger={trigger}
                    isEditMode={!!questionId}
                    possibleAnswers={possibleAnswers}
                  />
                </FormCard>
              </div>
              <div className='col ap-my-spacing-5'>
                <FormCard
                  withRequired={!!parentQuestion}
                  title={t('newQuestion.form.questionDependencies')}
                >
                  <QuestionDependenciesComponent
                    control={control}
                    question={!!questionId ? (question as TQuestion) : null}
                    parentQuestion={parentQuestion}
                    setParentQuestion={setParentQuestion}
                    resetField={resetField}
                    setValue={setValue}
                    category={category}
                    subcategory={subcategory}
                    isEditMode={!!questionId}
                  />
                </FormCard>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
