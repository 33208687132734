import classNames from 'classnames'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ActionButton } from '../../../../../../components/ActionButton'
import { CardListItem } from '../../../../../../components/CardListItem'
import { ECardListItem } from '../../../../../../components/CardListItem/const'
import { Divider } from '../../../../../../components/Divider'
import { EmptyState } from '../../../../../../components/EmptyState'
import { SDKLoading } from '../../../../../../components/SDK/Loading'
import { SDKModal } from '../../../../../../components/SDK/Modal'
import { SDKText } from '../../../../../../components/SDK/Text'
import { useApp } from '../../../../../../context/app.context'
import { LoadingStatus } from '../../../../../../shared/types/enums'
import { useCopyBtn } from '../../../../../../shared/utils/useCopyBtn'
import { useAppDispatch } from '../../../../../../store'
import { IUserBase } from '../../../../../../types/user'
import {
  formatUtc,
  GMT_TIME,
  VERIFICATION_COUNTRY_CONTACT_DATE_FORMAT
} from '../../../../../../utils/dateFormatters'
import { getCountryById } from '../../../../../countries/store/actions'
import { getCountry } from '../../../../../countries/store/selectors'
import styles from './index.module.scss'

enum EListType {
  CountryLeader = 'countryLeader',
  CountryEditor = 'countryEditor'
}
interface ICountryContacModalProps {
  setIsVisible: (value: null) => void
  isVisible: boolean
  countryId: number
  yearId: number
}

export const CountryContactInformation = ({
  setIsVisible,
  isVisible,
  countryId,
  yearId
}: ICountryContacModalProps) => {
  const { t } = useTranslation('myprojects')
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { onCopy, copied } = useCopyBtn()
  const { data: countryData, loading, error } = useSelector(getCountry)

  useEffect(() => {
    if (countryData?.countryId !== countryId) {
      dispatch(getCountryById({ yearId, countryId }))
    }
  }, [countryId])

  useEffect(() => {
    if (error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
      setIsVisible(null)
    }
  }, [error])

  const onCopyEmails = (type: EListType) => {
    if (countryData) {
      let data =
        type === EListType.CountryEditor 
        ? countryData.countryEditors 
        : countryData.countryLeaders
      if (type === EListType.CountryEditor) {
        const techSupportUser: IUserBase = {
          email: 'us_tp.tech.support@pwc.com',
          firstName: '',
          lastName: '',
          guid: '',
          hasAvatar: false,
          userId: 0
        } as IUserBase
        data = data.concat(techSupportUser)
      }
      const emails = data.map(({ email }) => email).join(';')
      onCopy(emails, type)
    }
  }

  const isCopied = (type: EListType) => copied.status && copied.name === type
  const getTooltipContent = (type: EListType) => (isCopied(type) ? t('copyBtnTooltip') : '')

  return (
    <SDKModal
      onCancel={() => setIsVisible(null)}
      onSubmit={() => setIsVisible(null)}
      visible={isVisible}
      title={`${t('countryContactInfo.modal.title', {
        country: countryData?.name
      })}`}
      submitLabel={t('countryContactInfo.modal.button')!}
      withRequired={false}
      className={styles['country-contact-info-modal']}
    >
      {loading === LoadingStatus.Pending ? (
        <SDKLoading />
      ) : (
        <div className={classNames([styles['country-snapshot-contact'], 'ap-container'])}>
          <SDKText type='body' weight={3}>
            {t('countryContactInfo.modal.contactInfo')!}
          </SDKText>
          <div className='row'>
            <div className='ap-my-spacing-2 ap-flex align-items-center'>
              <SDKText type='body-s' className='ap-text-neutral-14'>
                {t('countryContactInfo.modal.countryLeader')}
              </SDKText>
              {countryData?.countryLeaders.length ? (
                <ActionButton
                  kind='neutral'
                  content={getTooltipContent(EListType.CountryLeader)}
                  icon='copy'
                  className={classNames({ 'icon-not-allowed': isCopied(EListType.CountryLeader) })}
                  handleClick={() => onCopyEmails(EListType.CountryLeader)}
                />
              ) : null}
            </div>
            {countryData?.countryLeaders.length ? (
              countryData?.countryLeaders.map((user: IUserBase) => (
                <div className={classNames(styles['user-list-item'], 'g-col-12')} key={user.userId}>
                  <CardListItem
                    actions={[]}
                    cardListItem={ECardListItem.UserCard}
                    handleClick={() => {}}
                    {...user}
                  />
                </div>
              ))
            ) : (
              <EmptyState />
            )}
            <>
              <div className='ap-my-spacing-2 ap-flex align-items-center'>
                <SDKText type='body-s' className='ap-text-neutral-14'>
                  {t('countryContactInfo.modal.countryEditor')}
                </SDKText>
                {countryData?.countryEditors.length ? (
                  <ActionButton
                    kind='neutral'
                    content={getTooltipContent(EListType.CountryEditor)}
                    icon='copy'
                    className={classNames({
                      'icon-not-allowed': isCopied(EListType.CountryEditor)
                    })}
                    handleClick={() => onCopyEmails(EListType.CountryEditor)}
                  />
                ) : null}
              </div>
              {countryData?.countryEditors.length ? (
                countryData?.countryEditors.map((user: IUserBase) => (
                  <div className='g-col-12' key={user.userId}>
                    <CardListItem
                      actions={[]}
                      cardListItem={ECardListItem.UserCard}
                      handleClick={() => {}}
                      {...user}
                    />
                  </div>
                ))
              ) : (
                <EmptyState />
              )}
            </>
          </div>
          <Divider />
          <SDKText type='body' weight={3}>
            {t('countryContactInfo.modal.verificationInfo')!}
          </SDKText>
          {countryData?.lastVerifiedDate ? (
            <div className='row ap-my-spacing-2'>
              <SDKText type='body-s' className='ap-text-neutral-14'>
                {t('countryContactInfo.modal.lastVerified')}
              </SDKText>
              <SDKText>
                {`${formatUtc(
                  countryData?.lastVerifiedDate || '',
                  VERIFICATION_COUNTRY_CONTACT_DATE_FORMAT
                )} ${GMT_TIME}`}
              </SDKText>
            </div>
          ) : (
            <EmptyState />
          )}
        </div>
      )}
    </SDKModal>
  )
}
