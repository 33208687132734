import type { RadioGroupProps } from '@appkit4/react-components/radio'
import { Radio, RadioGroup } from '@appkit4/react-components/radio'
import classNames from 'classnames'
import type { Ref } from 'react'
import { forwardRef } from 'react'
import type { Path } from 'react-hook-form'
import { IOption } from '../../../types/form'
import { SDKIcon } from '../Icon'
import { SDKTooltip } from '../Tooltip'
import './index.scss'

interface SDKRadioButtonProps<T> extends RadioGroupProps {
  name: Path<T>
  title: string
  options: IOption[]
  fieldValue: string
  withFlex?: boolean
  suffix?: JSX.Element
  withDivider?: boolean
  customRadioItem?: (value: any, radioValue: string) => JSX.Element
  customRenderList?: any[]
  withoutDesktopScroll?: boolean
}

export const SDKRadioButton = forwardRef(
  <T,>(
    {
      options,
      withFlex,
      fieldValue,
      onClick,
      defaultValue,
      withDivider,
      className,
      customRadioItem,
      customRenderList,
      withoutDesktopScroll,
      ...props
    }: SDKRadioButtonProps<T>,
    ref: Ref<HTMLButtonElement>
  ) => {
    return (
      <RadioGroup
        ref={ref}
        defaultValue={defaultValue}
        onChange={onClick}
        className={className}
        {...props}
        value={defaultValue ? undefined : fieldValue}
      >
        <div className={classNames(withFlex && 'flex', withoutDesktopScroll && 'row', 'sdk-radio')}>
          {options?.map(({ value, label, tooltip, onClick: onClickOption }, index) => (
            <div
              onClick={onClickOption}
              key={`${value}-radio`}
              className={classNames([
                withFlex && index % 2 && 'ap-pl-spacing-4',
                withDivider && 'with-divider',
                withoutDesktopScroll && 'col-3'
              ])}
            >
              {customRadioItem && customRenderList ? (
                customRadioItem(customRenderList[index], value)
              ) : (
                <Radio value={value}>
                  {label}
                  {tooltip && (
                    <SDKTooltip content={tooltip}>
                      <div className='display-inline'>
                        <SDKIcon code='information' />
                      </div>
                    </SDKTooltip>
                  )}
                </Radio>
              )}
            </div>
          ))}
        </div>
      </RadioGroup>
    )
  }
)
