import { createReducer } from '@reduxjs/toolkit'
import {
  getDefaultResourceState,
  Resource,
  setResourceFullfilled,
  setResourcePending,
  setResourceRejected
} from '../../../shared/utils/store'
import {
  IFormula,
  IFormulaCountry,
  IFormulaDescription,
  IFormulaValidationResponse,
  IGateQuestionsByCountry
} from '../types'
import {
  addFormulaOverride,
  clearAddFormulaOverride,
  clearGetFormulaOverrideByCountry,
  clearGetFormulaOverrideDescription,
  clearGetFormulaOverrides,
  clearGetGateQuestionsByCountry,
  clearUpdateFormulaOverride,
  clearUpdateFormulaOverrideDescription,
  clearUpdateFormulaValidation,
  getFormulaOverrideByCountry,
  getFormulaOverrideDescription,
  getFormulaOverrides,
  getGateQuestionsByCountry,
  updateFormulaOverride,
  updateFormulaOverrideDescription,
  updateFormulaValidation
} from './actions'

export interface TaraFieldsState {
  formulaOverrides: Resource<IFormula | null>
  formulaOverrideByCountry: Resource<IFormulaCountry[]>
  updateFormulaOverride: Resource<IFormulaCountry | null>
  addFormulaOverride: Resource<IFormulaCountry | null>
  gateQuestions: Resource<IGateQuestionsByCountry[]>
  updateFormulaOverrideDescription: Resource<IFormulaDescription | null>
  formulaOverrideDescription: Resource<IFormulaDescription[]>
  formulaValidation: Resource<IFormulaValidationResponse | null>
}
const initialState: TaraFieldsState = {
  formulaOverrides: getDefaultResourceState(null),
  formulaOverrideByCountry: getDefaultResourceState([]),
  gateQuestions: getDefaultResourceState([]),
  addFormulaOverride: getDefaultResourceState(null),
  updateFormulaOverride: getDefaultResourceState(null),
  updateFormulaOverrideDescription: getDefaultResourceState(null),
  formulaOverrideDescription: getDefaultResourceState([]),
  formulaValidation: getDefaultResourceState(null)
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getFormulaOverrides.pending, state => {
      setResourcePending(state.formulaOverrides)
    })
    .addCase(getFormulaOverrides.rejected, (state, actions) => {
      setResourceRejected(state.formulaOverrides, actions)
    })
    .addCase(getFormulaOverrides.fulfilled, (state, actions) => {
      state.formulaOverrides.data = actions.payload
      setResourceFullfilled(state.formulaOverrides)
    })
    .addCase(clearGetFormulaOverrides, state => {
      state.formulaOverrides = initialState.formulaOverrides
    })

    .addCase(getGateQuestionsByCountry.pending, state => {
      setResourcePending(state.gateQuestions)
    })
    .addCase(getGateQuestionsByCountry.rejected, (state, actions) => {
      setResourceRejected(state.gateQuestions, actions)
    })
    .addCase(getGateQuestionsByCountry.fulfilled, (state, actions) => {
      state.gateQuestions.data = actions.payload
      setResourceFullfilled(state.gateQuestions)
    })
    .addCase(clearGetGateQuestionsByCountry, state => {
      state.gateQuestions = initialState.gateQuestions
    })

    .addCase(getFormulaOverrideDescription.pending, state => {
      setResourcePending(state.formulaOverrideDescription)
    })
    .addCase(getFormulaOverrideDescription.rejected, (state, actions) => {
      setResourceRejected(state.formulaOverrideDescription, actions)
    })
    .addCase(getFormulaOverrideDescription.fulfilled, (state, actions) => {
      state.formulaOverrideDescription.data = actions.payload
      setResourceFullfilled(state.formulaOverrideDescription)
    })
    .addCase(clearGetFormulaOverrideDescription, state => {
      state.formulaOverrideDescription = initialState.formulaOverrideDescription
    })
    .addCase(getFormulaOverrideByCountry.pending, state => {
      setResourcePending(state.formulaOverrideByCountry)
    })
    .addCase(getFormulaOverrideByCountry.rejected, (state, actions) => {
      setResourceRejected(state.formulaOverrideByCountry, actions)
    })
    .addCase(getFormulaOverrideByCountry.fulfilled, (state, actions) => {
      state.formulaOverrideByCountry.data = actions.payload
      setResourceFullfilled(state.formulaOverrideByCountry)
    })
    .addCase(clearGetFormulaOverrideByCountry, state => {
      state.formulaOverrideByCountry = initialState.formulaOverrideByCountry
    })
    .addCase(updateFormulaOverride.pending, state => {
      setResourcePending(state.updateFormulaOverride)
    })
    .addCase(updateFormulaOverride.rejected, (state, actions) => {
      setResourceRejected(state.updateFormulaOverride, actions)
    })
    .addCase(updateFormulaOverride.fulfilled, (state, actions) => {
      state.updateFormulaOverride.data = actions.payload
      setResourceFullfilled(state.updateFormulaOverride)
    })
    .addCase(clearUpdateFormulaOverride, state => {
      state.updateFormulaOverride = initialState.updateFormulaOverride
    })
    .addCase(addFormulaOverride.pending, state => {
      setResourcePending(state.addFormulaOverride)
    })
    .addCase(addFormulaOverride.rejected, (state, actions) => {
      setResourceRejected(state.addFormulaOverride, actions)
    })
    .addCase(addFormulaOverride.fulfilled, (state, actions) => {
      state.addFormulaOverride.data = actions.payload
      setResourceFullfilled(state.addFormulaOverride)
    })
    .addCase(clearAddFormulaOverride, state => {
      state.addFormulaOverride = initialState.addFormulaOverride
    })
    .addCase(updateFormulaOverrideDescription.pending, state => {
      setResourcePending(state.updateFormulaOverrideDescription)
    })
    .addCase(updateFormulaOverrideDescription.rejected, (state, actions) => {
      setResourceRejected(state.updateFormulaOverrideDescription, actions)
    })
    .addCase(updateFormulaOverrideDescription.fulfilled, (state, actions) => {
      state.updateFormulaOverrideDescription.data = actions.payload
      setResourceFullfilled(state.updateFormulaOverrideDescription)
    })
    .addCase(clearUpdateFormulaOverrideDescription, state => {
      state.updateFormulaOverrideDescription = initialState.updateFormulaOverrideDescription
    })
    .addCase(updateFormulaValidation.pending, state => {
      setResourcePending(state.formulaValidation)
    })
    .addCase(updateFormulaValidation.rejected, (state, actions) => {
      setResourceRejected(state.formulaValidation, actions)
    })
    .addCase(updateFormulaValidation.fulfilled, (state, actions) => {
      state.formulaValidation.data = actions.payload
      setResourceFullfilled(state.formulaValidation)
    })
    .addCase(clearUpdateFormulaValidation, state => {
      state.formulaValidation = initialState.formulaValidation
    })
)
