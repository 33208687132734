import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo, useState } from 'react'
import { useForm, type FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { FormInput } from '../../../../components/Form/FormInput'
import { FormRadioButton } from '../../../../components/Form/FormRadioButton'
import { FormUploadInput } from '../../../../components/Form/FormUploadInput'
import { RequiredLabel } from '../../../../components/Form/RequiredLabel'
import { RHF_SET_VALUE_OPTIONS } from '../../../../components/Form/utils'
import { HeaderButtons } from '../../../../components/HeaderButtons'
import { Layout } from '../../../../components/Layout'
import { SDKPanel } from '../../../../components/SDK/Panel'
import { SDKParagraph } from '../../../../components/SDK/Paragraph'
import { SDKTooltip } from '../../../../components/SDK/Tooltip'
import { Url } from '../../../../constants/urls'
import { useApp } from '../../../../context/app.context'
import { string2snakeCase } from '../../../../formatters/string2snakeCase'
import { useSubmitButton } from '../../../../hooks/useSubmitButton'
import {
  useAddEditPowerBITemplateSchema,
  type IFormValues
} from '../../../../schemas/useAddEditPowerBITemplateSchema'
import { LoadingStatus } from '../../../../shared/types/enums'
import { useAppDispatch } from '../../../../store'
import * as actions from '../../../fiscalYearSetup/store/actions'
import * as selectors from '../../../fiscalYearSetup/store/selectors'
import { EPowerBITemplateFields } from '../../types'
import { FilenameField } from './components/FilenameField'
import { ACCEPTED_FILE_TYPE, ACCEPTED_THUMBNAIL_TYPE } from './constants'
import { useAddEditPowerBITemplate } from './hooks/useAddEditPowerBITemplate'
import { TPBITemplateFileTypes } from './types'

const PowerBITemplatesManagement = () => {
  const { t } = useTranslation('documents')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { sendNotification } = useApp()
  const { id, year: currentYearName } = useParams()
  const year = useSelector(selectors.getYearByName(String(currentYearName)))
  const yearId = useMemo(() => Number(year?.yearId), [year])
  const template = useSelector(selectors.getPowerBITemplate(id ? Number(id) : null))
  const { defaultValues, validationSchema, templateTypeRadioList } =
    useAddEditPowerBITemplateSchema(template)
  const [toBeReplaced, setToBeReplaced] = useState<TPBITemplateFileTypes | null>()

  const { control, formState, getValues, handleSubmit, setValue } = useForm<
    FieldValues,
    IFormValues
  >({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange'
  })
  const { cleanUp, onSubmit, request } = useAddEditPowerBITemplate(Number(id))

  useEffect(() => {
    dispatch(actions.getPublishedYears())
  }, [])

  useEffect(() => {
    if (yearId) {
      dispatch(actions.getPBITemplates({ yearId }))
    }
  }, [yearId])

  useEffect(() => {
    Object.keys(defaultValues).map((key: string) =>
      setValue(key as EPowerBITemplateFields, defaultValues[key as EPowerBITemplateFields])
    )
  }, [template])

  useEffect(() => {
    if (request.error?.length) {
      sendNotification({ message: request.error, status: 'error' })
    }
  }, [request.error])

  useEffect(() => {
    if (request.loading === LoadingStatus.Succeeded) {
      const message = t(`powerBITemplates.form.notification.${id ? 'updateSuccess' : 'success'}`, {
        name: request.data?.name ?? ''
      })
      sendNotification({ message, status: 'success' })
      cleanUp()

      navigate(`${Url.FiscalYearPage}/${year?.name}${Url.PowerBITemplatesPage}`)
    }
  }, [request.loading])

  const { disabled } = useSubmitButton({ formState })

  return (
    <Layout
      buttonComponent={
        <HeaderButtons
          onClick={handleSubmit(onSubmit)}
          disabled={disabled}
          name={t('powerBITemplates.form.saveTemplateButton.label')!}
          icon='save'
        />
      }
      title={id ? t('common:breadcrumbs.editPowerBiTemplatesManagement')! : undefined}
      type='page-form'
    >
      <form>
        <div className='power-bi-templates-management-page mt-2'>
          <SDKPanel title={t('powerBITemplates.form.title')!} className='ap-mb-spacing-6'>
            <div className='ap-container'>
              <div className='row'>
                <div className='col-12 col-sm-8'>
                  <SDKTooltip
                    disabled={formState.isDirty}
                    content={t('powerBITemplates.form.name.tooltip')!}
                  >
                    <FormInput
                      name={EPowerBITemplateFields.Name}
                      type='text'
                      label={t('powerBITemplates.form.name.title')!}
                      required={true}
                      className='ap-mb-spacing-5'
                      control={control}
                      suffixIcon
                      onChange={value => {
                        setValue(EPowerBITemplateFields.Name, value, RHF_SET_VALUE_OPTIONS)
                        if (!id) {
                          setValue(
                            EPowerBITemplateFields.Identifier,
                            string2snakeCase(getValues()[EPowerBITemplateFields.Name])
                          )
                        }
                      }}
                    />
                  </SDKTooltip>
                </div>
                <div className='col-12 col-sm-4'>
                  <FormInput
                    name={EPowerBITemplateFields.Identifier}
                    type='text'
                    label={t('powerBITemplates.form.identifier.title')!}
                    disabled
                    className='ap-mb-spacing-5'
                    control={control}
                  />
                </div>
                <div className='col-12 col-sm-4 ap-mb-spacing-5'>
                  <SDKParagraph className='ap-mb-spacing-3' type='body' weight={2}>
                    <span className='relative'>
                      {t('powerBITemplates.form.templateType.title')}
                      <span className='required-dot ap-ml-spacing-1'></span>
                    </span>
                  </SDKParagraph>
                  <FormRadioButton
                    name={EPowerBITemplateFields.TemplateType}
                    required={true}
                    options={templateTypeRadioList}
                    withFlex
                    control={control}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col'>
                  <RequiredLabel />
                </div>
              </div>
            </div>
          </SDKPanel>

          <SDKPanel
            title={t('powerBITemplates.form.templateFile.title')!}
            className='ap-mb-spacing-6'
          >
            <FilenameField
              defaultValues={defaultValues}
              fileType={EPowerBITemplateFields.TemplateFile}
              getValues={getValues}
              id={id}
              setValue={setValue}
              setToBeReplaced={setToBeReplaced}
              template={template}
              toBeReplaced={toBeReplaced}
            >
              <FormUploadInput
                name={EPowerBITemplateFields.TemplateFile}
                control={control}
                label={t('powerBITemplates.form.templateFile.uploadInstruction')!}
                acceptFileType={ACCEPTED_FILE_TYPE}
              />
            </FilenameField>
          </SDKPanel>

          <SDKPanel title={t('powerBITemplates.form.thumbnailFile.title')!}>
            <FilenameField
              defaultValues={defaultValues}
              fileType={EPowerBITemplateFields.ThumbnailFile}
              getValues={getValues}
              id={id}
              setValue={setValue}
              setToBeReplaced={setToBeReplaced}
              template={template}
              toBeReplaced={toBeReplaced}
            >
              <FormUploadInput
                name={EPowerBITemplateFields.ThumbnailFile}
                control={control}
                label={t('powerBITemplates.form.thumbnailFile.uploadInstruction')!}
                acceptFileType={ACCEPTED_THUMBNAIL_TYPE}
              />
            </FilenameField>
          </SDKPanel>
        </div>
      </form>
    </Layout>
  )
}

export default PowerBITemplatesManagement
