import { useEffect } from 'react'
import type { FieldValues, UseFormReset } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Url } from '../../../../../constants/urls'
import { useApp } from '../../../../../context/app.context'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../store'
import * as actions from '../../../store/actions'
import * as selectors from '../../../store/selectors'

export const useAddEditCountryFieldOverride = (
  reset: UseFormReset<FieldValues>,
  currentYearName: string,
  countryId: number | null,
  formulaId?: number
) => {
  const { t } = useTranslation('common')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { sendNotification } = useApp()
  const { loading, error } = formulaId
    ? useSelector(selectors.updateFormulaOverride)
    : useSelector(selectors.addFormulaOverride)

  const onClose = () => {
    dispatch(actions.clearUpdateFormulaValidation())
    dispatch(actions.clearAddFormulaOverride())
    dispatch(actions.clearUpdateFormulaOverride())
    navigate(`${Url.FiscalYearPage}/${currentYearName}${Url.TaraFieldsPage}`)
    reset()
  }

  const onSubmit = async (data: FieldValues) => {
    if (countryId === null) return
    if (formulaId) {
      await dispatch(
        actions.updateFormulaOverride({
          formulaId,
          countryId,
          params: {
            formula: data.newLogic,
            isActive: data.isActive,
            gateQuestionId: data.gateQuestion || null
          }
        })
      )
    } else {
      await dispatch(
        actions.addFormulaOverride({
          countryId,
          params: {
            countryId,
            fieldName: data.fieldName,
            formula: data.newLogic,
            isActive: data.isActive,
            gateQuestionId: data.gateQuestion || null
          }
        })
      )
    }
  }

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t('successInfo'),
        status: 'success'
      })
      onClose()
    }
    if (error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
      onClose()
    }
  }, [loading, error])

  return { onClose, onSubmit }
}
