import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { IconWithTooltip } from '../../../../../../components/IconWithTooltip'
import { SDKButton } from '../../../../../../components/SDK/Button'
import { SDKList } from '../../../../../../components/SDK/List'
import { SDKModal } from '../../../../../../components/SDK/Modal'
import { SDKText } from '../../../../../../components/SDK/Text'
import { useCopyBtn } from '../../../../../../shared/utils/useCopyBtn'
import { ILocalFileParseError } from '../../types/scope'

interface ILocalFileParseErrorModal {
  setVisible: Dispatch<SetStateAction<ILocalFileParseError[] | null>>
  data: ILocalFileParseError[]
}

const LocalFileParseErrorModal = ({ setVisible, data }: ILocalFileParseErrorModal) => {
  const { t, i18n } = useTranslation('pricing')
  const { onCopy } = useCopyBtn()

  const copyData = () => {
    const dataString = data
      .map(x => {
        return `${t(
          `tabs.inputs.localFile.validation.row`
        )} ${x.rowNumber.toString()} | ${x.errorMessages
          .map(e => t(`tabs.inputs.localFile.validation.error.${e}`))
          .join('; ')}`
      })
      .join('\r\n')

    onCopy(dataString)
  }

  const renderItem = (item: ILocalFileParseError, _: number) => {
    return (
      <div
        key={`rowNumber-${item.rowNumber}`}
        className='ap-flex ap-py-spacing-5'
        style={{ justifyContent: 'space-between' }}
      >
        <div className='ap-px-spacing-3' style={{ minWidth: '80px' }}>
          <SDKText>{`${t(
            `tabs.inputs.localFile.validation.row`
          )} ${item.rowNumber.toString()}`}</SDKText>
        </div>
        <div className='ap-px-spacing-3'>
          <ul>
            {item.errorMessages &&
              item.errorMessages.map((errorText, errorIndex) => (
                <li key={`rowNumber-${item.rowNumber}-error-${errorIndex}`}>
                  <SDKText>{t(`tabs.inputs.localFile.validation.error.${errorText}`)}</SDKText>
                </li>
              ))}
          </ul>
        </div>
      </div>
    )
  }

  return (
    <SDKModal
      className='upload-modal'
      visible={true}
      onCancel={() => setVisible(null)}
      title={`${t(`tabs.inputs.localFile.validation.title`)}`}
      withRequired={false}
      footer={
        <SDKButton type='button' onClick={() => setVisible(null)}>
          {i18n.t('nav.close')}
        </SDKButton>
      }
    >
      <div className='ap-flex' style={{ justifyContent: 'space-between' }}>
        <SDKText type='body-s' className='ap-pt-spacing-3'>
          {t('tabs.inputs.localFile.validation.subtitle')}
        </SDKText>
        <div className='ap-pl-spacing-5 ap-py-spacing-2 pointer' onClick={() => copyData()}>
          <SDKText type='heading'>
            <IconWithTooltip
              code='copy'
              content={t('tabs.inputs.localFile.validation.copyBtnTooltip')}
            />
          </SDKText>
        </div>
      </div>

      <SDKList data={data} itemKey={'rowNumber'} renderItem={renderItem}></SDKList>
    </SDKModal>
  )
}

export default LocalFileParseErrorModal
