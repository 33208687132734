import type { IRegion } from '../../../modules/regions/types'
import api from '../../api'
import { FiscalYearUrl, RegionUrl } from '../../constants/urls'

export interface IAddRegionPayload {
  name: string
  type: string
  yearId: number
}

export const addNewRegion = async (params: IAddRegionPayload): Promise<IRegion> => {
  return (await api.post(`${FiscalYearUrl.Years}/${params.yearId}/${RegionUrl.Regions}`, params))
    .data
}
