import type { ICustomModule } from '../../../modules/project/components/GCDPricing/types/inputsTab'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const createCustomModuleRequest = async (
  params: Partial<ICustomModule>,
  projectId: number
): Promise<ICustomModule> => {
  return (
    await api.post(
      `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/${ProjectsUrl.Modules}/${ProjectsUrl.CustomModules}`,
      params
    )
  ).data
}
