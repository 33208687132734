import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { object, string } from 'yup'
import * as selectors from '../modules/countriesSnapshot/store/selectors'

export enum EAddSubcategoryFields {
  subcategory = 'subcategory'
}
export interface IFormValues {
  [EAddSubcategoryFields.subcategory]: string
}

export const useAddSubcategorySchema = (
  categoryId: number | null,
  subcategoryId: number | null
) => {
  const { t } = useTranslation('countriesSnapshot')
  const subcategory = useSelector(selectors.getSubcategoryById(categoryId, subcategoryId))
  const defaultValues = {
    [EAddSubcategoryFields.subcategory]: subcategory?.name || ''
  }
  const validationSchema = object()
    .shape({
      [EAddSubcategoryFields.subcategory]: string().required(
        t('newSubcategory.form.errors.subcategory')!
      )
    })
    .required()

  return { validationSchema, defaultValues }
}
