import type { IUserBase } from '../types/user'
import { UserRole } from '../types/user'

export function getInitials(user: IUserBase | null) {
  if (!user) {
    return ''
  }

  return (user.firstName[0] + user.lastName[0]).trim()
}

export function getFullname(user: IUserBase | null, reverse = false) {
  if (user) {
    return (
      reverse ? `${user.lastName} ${user.firstName}` : `${user.firstName} ${user.lastName}`
    ).trim()
  } else {
    return ''
  }
}

export const isUserAdmin = (roles?: UserRole[]): boolean => {
  return roles?.includes(UserRole.Admin) || false
}

export const isUserCountryEditor = (roles?: UserRole[]): boolean => {
  return roles?.includes(UserRole.CountryEditor) || false
}
