import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SDKModal } from '../../../../../../components/SDK/Modal'
import { useSubmitButton } from '../../../../../../hooks/useSubmitButton'
import { useAddEditScopeSchema } from '../../../../../../schemas/useAddEditScopeSchema'
import type { IChildScope } from '../../types/scope'
import { ChildScopeForm } from './components/ChildScopeForm'
import { useChildScope } from './utils/useChildScope'

export const ChildScopeModal = ({
  onCloseModal,
  scope,
  visible
}: {
  onCloseModal: (params?: { refreshData: boolean }) => void
  scope: IChildScope | null
  visible: boolean
}) => {
  const { t } = useTranslation('pricing')
  const { defaultValues, getDefaultValues, validationSchema } = useAddEditScopeSchema(scope)
  const { handleSubmit, setValue, control, reset, formState } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange'
  })
  const { onSubmit, onClose } = useChildScope(scope, onCloseModal, reset)

  const { disabled } = useSubmitButton({ formState })

  useEffect(() => {
    const updatedDefaultValues = getDefaultValues(scope)
    setValue('name', updatedDefaultValues.name)
  }, [scope])

  return (
    <form>
      <SDKModal
        disabledSubmit={disabled}
        onCancel={() => onClose()}
        onSubmit={handleSubmit(onSubmit)}
        title={t(`tabs.inputs.childScopeModal.title.${scope?.childScopeId ? 'edit' : 'add'}`)!}
        visible={visible}
      >
        <ChildScopeForm control={control} />
      </SDKModal>
    </form>
  )
}
