import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'
import type { IOption } from '../types/form'
import type { IUser } from '../types/user'
import { getFullname } from '../utils/user'

export enum BooleanOptions {
  Yes = '1',
  No = '0'
}

export enum EEditUserFields {
  email = 'email',
  fullName = 'fullName',
  isActiveOption = 'isActiveOption',
  isAdministratorOption = 'isAdministratorOption',
  isServiceAccountOption = 'isServiceAccountOption'
}

export interface IFormValues {
  [EEditUserFields.email]: string
  [EEditUserFields.fullName]: string
  [EEditUserFields.isActiveOption]: IOption['value']
  [EEditUserFields.isAdministratorOption]: IOption['value']
  [EEditUserFields.isServiceAccountOption]: IOption['value']
}

export const useAddEditUserSchema = (user: IUser | null) => {
  const { t } = useTranslation()

  const isAdministratorRadioList: IOption[] = [
    { value: BooleanOptions.Yes, label: t('radioList.yes') },
    { value: BooleanOptions.No, label: t('radioList.no') }
  ]

  const isServiceAccountRadioList: IOption[] = [
    { value: BooleanOptions.Yes, label: t('radioList.yes') },
    { value: BooleanOptions.No, label: t('radioList.no') }
  ]

  const isActiveRadioList: IOption[] = [
    { value: BooleanOptions.Yes, label: t('radioList.yes') },
    { value: BooleanOptions.No, label: t('radioList.no') }
  ]

  const getDefaultValues = (userData: IUser | null) => ({
    [EEditUserFields.email]: userData?.email || '',
    [EEditUserFields.fullName]: getFullname(userData) || '',
    [EEditUserFields.isActiveOption]:
      String(userData?.isActive ? BooleanOptions.Yes : BooleanOptions.No) ||
      isActiveRadioList[1].value,
    [EEditUserFields.isAdministratorOption]:
      String(userData?.isAdministrator ? BooleanOptions.Yes : BooleanOptions.No) ||
      isAdministratorRadioList[1].value,
    [EEditUserFields.isServiceAccountOption]:
      String(userData?.isServiceAccount ? BooleanOptions.Yes : BooleanOptions.No) ||
      isServiceAccountRadioList[1].value
  })

  const defaultValues = getDefaultValues(user)

  const validationSchema = object()
    .shape({
      [EEditUserFields.email]: string().required()
    })
    .required()

  return {
    defaultValues,
    getDefaultValues,
    isActiveRadioList,
    isAdministratorRadioList,
    isServiceAccountRadioList,
    validationSchema
  }
}
