import { useTranslation } from 'react-i18next'
import { ISDKModal } from '../..'
import { SDKButton } from '../../../Button'

export const CancelButton = ({
  onCancel,
  cancelText
}: Pick<ISDKModal, 'onCancel' | 'cancelText'>) => {
  const { t } = useTranslation()

  return (
    <SDKButton className='ap-mr-spacing-5' kind='secondary' onClick={onCancel}>
      {cancelText || t('buttons.cancel')}
    </SDKButton>
  )
}
