import { ITemplateCalculationData } from '../../../modules/project/types/projectCalculation'
import api from '../../api'
import { CalculationUrl, ProjectsUrl } from '../../constants/urls'

export const updateCalendarRequest = async (
  projectId: number
): Promise<ITemplateCalculationData> => {
  return (
    await api.patch(`${CalculationUrl.Calculation}/${projectId}/${ProjectsUrl.Calendar}`, false)
  ).data
}
