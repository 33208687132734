import { Dispatch, SetStateAction, useState } from 'react'
import { ITable, ITransformedData } from '../types/dnd'
import { findItemIndexById } from '../utils/findItemIndexById'

export const useDragLayer = (
  content: ITransformedData,
  setContent: Dispatch<SetStateAction<ITransformedData>>
) => {
  const [hoveredItemIndex, setHoveredItemIndex] = useState<number | null>(null)
  const [hoveredTableIndex, setHoveredTableIndex] = useState<number | null>(null)

  const moveRowHandler = (
    itemId: string,
    hoverIndex: number,
    sourceTableId: string,
    targetTableId: string
  ) => {
    const sourceLaneIndex = findItemIndexById(content.tables, sourceTableId)
    const targetLaneIndex = findItemIndexById(content.tables, targetTableId)
    const copyArray: ITable[] = structuredClone(content.tables)

    const item = copyArray[sourceLaneIndex].rows.find(({ id }) => id === itemId)
    const itemIndexOf = item ? copyArray[sourceLaneIndex].rows.indexOf(item) : -1

    setHoveredItemIndex(hoverIndex)
    setHoveredTableIndex(targetLaneIndex)

    if (item && itemIndexOf > -1) {
      copyArray[sourceLaneIndex].rows.splice(itemIndexOf, 1)
      copyArray[targetLaneIndex].rows.splice(hoverIndex, 0, item)
    }

    setContent({ ...content, tables: copyArray })
  }
  return { moveRowHandler, hoveredItemIndex, setHoveredItemIndex, hoveredTableIndex }
}
