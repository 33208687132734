/**
 * Returning value from enum based on source string or returning default value from enum itself
 * @param {string|null} source
 * @param {T} type
 * @param {keyofT} defaultValue returned value when source is not valid
 * @returns {any}
 */
export const getValueOrDefault = <T extends {}>(
  source: string | null,
  type: T,
  defaultValue: keyof T
) => {
  const entry = Object.entries(type).find(e => e[1] === source) as [keyof T, string] | undefined

  if (source && entry) {
    return type[entry[0]]
  }
  return type[defaultValue]
}
