import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../../../../../context/app.context'
import { usePricingTab } from '../../../../../../../context/pricingTab.context'
import { useProjectFromUrl } from '../../../../../../../hooks/useProjectFromUrl'
import { LoadingStatus } from '../../../../../../../shared/types/enums'
import { DEFAULT_ERROR } from '../../../../../../../shared/utils/store'
import { useAppDispatch } from '../../../../../../../store'
import {
  clearUploadEntitiesFromTemplate,
  uploadEntitiesFromTemplate
} from '../../../../../store/actions'
import * as projectSelectors from '../../../../../store/selectors'
import { clearUploadLocalFile, uploadLocalFile } from '../../../modals/store/actions'
import * as selectors from '../../../modals/store/selectors'
import { ELocalFile } from '../../../types'
import { ILocalFileParseError } from '../../../types/scope'

export const useLocalFileUpload = (
  uploadModal: ELocalFile | null,
  setUploadModal: Dispatch<SetStateAction<ELocalFile | null>>,
  setLocalFileParseErrorModal: Dispatch<SetStateAction<ILocalFileParseError[] | null>>,
  setLocalFileCountriesSkippedModal: Dispatch<SetStateAction<string[] | null>>,
  setSelectedFile: Dispatch<SetStateAction<File | null>>
) => {
  const { t } = useTranslation('pricing')
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { project } = useProjectFromUrl()
  const { refreshData } = usePricingTab()
  const [successInfo, setSuccessInfo] = useState('')
  const {
    loading: uploadResultsLoading,
    error: uploadResultsError,
    data: uploadResultsResponse
  } = useSelector(selectors.uploadLocalFile)
  const {
    loading: uploadEntitiesLoading,
    error: uploadEntitiesError,
    data: uploadEntitiesResponse
  } = useSelector(projectSelectors.uploadEntitiesFromTemplate)

  const loading = uploadModal === ELocalFile.UserData ? uploadEntitiesLoading : uploadResultsLoading
  const error = uploadModal === ELocalFile.UserData ? uploadEntitiesError : uploadResultsError
  const clearAction =
    uploadModal === ELocalFile.UserData ? clearUploadEntitiesFromTemplate : clearUploadLocalFile

  const handleUpload = async (file: FieldValues) => {
    if (project?.projectId) {
      if (uploadModal === ELocalFile.UserData) {
        dispatch(
          uploadEntitiesFromTemplate({
            inputFile: file.inputFile.originFile,
            projectId: project.projectId
          })
        )
        setSelectedFile(file.inputFile)
        setSuccessInfo(t('tabs.inputs.moduleMetadata.successInfo')!)
      } else if (uploadModal === ELocalFile.Results) {
        dispatch(
          uploadLocalFile({ projectId: project.projectId, importFile: file.inputFile.originFile })
        )
        setSuccessInfo(t('tabs.inputs.localFile.table.upload.successInfo')!)
      }
    } else {
      sendNotification({
        message: DEFAULT_ERROR,
        status: 'error'
      })
    }
  }

  useEffect(() => {
    if (loading === LoadingStatus.Failed && error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })

      setUploadModal(null)
    } else if (loading === LoadingStatus.Succeeded && successInfo.length) {
      if (
        !!uploadResultsResponse &&
        (<any>uploadResultsResponse)?.name !== 'Local File' &&
        (<any>uploadResultsResponse).some((x: any) => x.errorMessages?.length > 0)
      ) {
        setLocalFileParseErrorModal(<ILocalFileParseError[]>uploadResultsResponse)
      } else if (!!uploadEntitiesResponse && uploadEntitiesResponse.countriesSkipped?.length > 0) {
        setLocalFileCountriesSkippedModal(uploadEntitiesResponse.countriesSkipped)
      } else {
        sendNotification({
          message: successInfo,
          status: 'success'
        })
      }
      setUploadModal(null)
      refreshData()
    }
  }, [loading])

  useEffect(() => {
    return () => {
      dispatch(clearAction())
      setSuccessInfo('')
    }
  }, [uploadModal])

  const modalTitle = t(
    uploadModal === ELocalFile.Results
      ? 'tabs.inputs.localFile.table.upload.title'
      : 'tabs.inputs.localFile.basic.btn'
  )!

  return {
    handleUpload,
    modalTitle,
    loading
  }
}
