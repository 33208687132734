import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardListItem } from '../../../../components/CardListItem'
import { EAction, ECardListItem } from '../../../../components/CardListItem/const'
import type { IActionCallback } from '../../../../components/CardListItem/type'
import { EmptyState } from '../../../../components/EmptyState'
import { SDKLoading } from '../../../../components/SDK/Loading'
import { SDKParagraph } from '../../../../components/SDK/Paragraph'
import { SDKSearch } from '../../../../components/SDK/Search'
import { LoadingStatus } from '../../../../shared/types/enums'
import { useDebounce } from '../../../../shared/utils/useDebounce'
import type { IUserBase } from '../../../../types/user'
import { MINIMAL_SEARCH_VALUE, USER_INPUT_SEARCH_DELAY } from '../../const'

import './index.scss'

export const UserList = ({
  list,
  loading,
  onSelectUser,
  onUserSearch,
  userSearch
}: {
  list: IUserBase[]
  loading: LoadingStatus
  onSelectUser: (value: number) => void
  onUserSearch: (value: string) => void
  userSearch: string
}) => {
  const { t } = useTranslation('users')
  const [userInput, setUserInput] = useState('')
  const delayedUserSearch = useDebounce<string>(userInput, USER_INPUT_SEARCH_DELAY)

  useEffect(() => {
    onUserSearch(delayedUserSearch)
  }, [delayedUserSearch])

  const shouldDisplayNotice = userSearch.length < MINIMAL_SEARCH_VALUE

  return (
    <div className='mx-0 users-page'>
      <div className='user-list-page mt-2'>
        <SDKSearch
          defaultValue={userSearch}
          onChange={value => setUserInput(value.trimStart().replace(/  +/g, ' '))}
          placeholder={t('filter.searchUsers.placeholder')!}
        />

        {loading === LoadingStatus.Pending ? (
          <SDKLoading />
        ) : shouldDisplayNotice ? (
          <SDKParagraph type='body-s'>{t('filter.searchUsers.minimalSearchLength')!}</SDKParagraph>
        ) : list?.length === 0 ? (
          <EmptyState />
        ) : (
          <div className='ap-grid mx-0 user-list'>
            {list?.map((user: IUserBase) => (
              <div className='user-list-item g-col-12' key={user.userId}>
                <CardListItem
                  actions={[EAction.Edit]}
                  cardListItem={ECardListItem.UserCard}
                  handleClick={(callback: IActionCallback) => onSelectUser(Number(callback.id))}
                  {...user}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
