import classNames from 'classnames'
import { SDKIcon } from '../SDK/Icon'
import { SDKText } from '../SDK/Text'

import './index.scss'

interface IInfoBox {
  children?: JSX.Element
  content?: string | null
  trans?: React.ReactElement
  className?: string
  margin?: string
}

export const InfoBox = ({ children, content, trans, className, margin = 'my-2' }: IInfoBox) => {
  return (
    <div className={classNames([className, 'info-box ap-bg-neutral-02 flex', margin])}>
      {children ? (
        children
      ) : (
        <>
          <span>
            <SDKIcon code='information' />
          </span>
          <SDKText className='ap-text-neutral-17 ap-ml-spacing-2'>{trans ?? content ?? ''}</SDKText>
        </>
      )}
    </div>
  )
}
