import { createReducer } from '@reduxjs/toolkit'
import {
  getDefaultResourceState,
  Resource,
  setResourceFullfilled,
  setResourcePending,
  setResourceRejected
} from '../../../../../shared/utils/store'
import type {
  IBasicPricingData,
  IBenchmark,
  ICustomModule,
  ICustomModuleData,
  IDiscountData,
  ILocalFile,
  IProjectManagementData,
  IProjectSetup,
  IScopeAssumptionsData,
  IScopeDefinition,
  ITPForm
} from '../types/inputsTab'
import { IPricingResults } from '../types/resultsTab'
import {
  clearCreateCustomModule,
  clearCustomModules,
  clearExportResultsFlat,
  clearExportResultsFull,
  clearExportResultsWithSightline,
  clearGetBenchmark,
  clearGetCbCr,
  clearGetCustomModule,
  clearGetDataCollection,
  clearGetDiscount,
  clearGetLocalFile,
  clearGetMasterFile,
  clearGetPricingResults,
  clearGetProjectManagement,
  clearGetProjectSetup,
  clearGetScopeAssumptions,
  clearGetScopeDefinition,
  clearGetTPForms,
  createCustomModule,
  exportResultsFlat,
  exportResultsFull,
  exportResultsWithSightline,
  getBenchmark,
  getCbCr,
  getCustomModule,
  getCustomModules,
  getDataCollection,
  getDiscount,
  getLocalFile,
  getMasterFile,
  getPricingResults,
  getProjectManagement,
  getProjectSetup,
  getScopeAssumptions,
  getScopeDefinition,
  getTPForms
} from './actions'

export interface PricingState {
  createCustomModule: Resource<ICustomModule | null>
  customModules: Resource<ICustomModule[] | null>
  scopeDefinition: Resource<IScopeDefinition | null>
  projectSetup: Resource<IProjectSetup | null>
  masterFile: Resource<IBasicPricingData | null>
  localFile: Resource<ILocalFile | null>
  benchmark: Resource<IBenchmark | null>
  tpForms: Resource<ITPForm | null>
  cbCr: Resource<IBasicPricingData | null>
  dataCollection: Resource<IBasicPricingData | null>
  scopeAssumptions: Resource<IScopeAssumptionsData | null>
  projectManagement: Resource<IProjectManagementData | null>
  discount: Resource<IDiscountData | null>
  customModule: Resource<ICustomModuleData | null>
  pricingResults: Resource<IPricingResults | null>
  pricingResultsExportFlat: Resource<File | null>
  pricingResultsExportFull: Resource<File | null>
  pricingResultsExportWithSightline: Resource<string | null>
}

const initialState: PricingState = {
  createCustomModule: getDefaultResourceState(null),
  customModules: getDefaultResourceState([]),
  scopeDefinition: getDefaultResourceState(null),
  projectSetup: getDefaultResourceState(null),
  benchmark: getDefaultResourceState(null),
  tpForms: getDefaultResourceState(null),
  masterFile: getDefaultResourceState(null),
  localFile: getDefaultResourceState(null),
  cbCr: getDefaultResourceState(null),
  dataCollection: getDefaultResourceState(null),
  scopeAssumptions: getDefaultResourceState(null),
  projectManagement: getDefaultResourceState(null),
  discount: getDefaultResourceState(null),
  customModule: getDefaultResourceState(null),
  pricingResults: getDefaultResourceState(null),
  pricingResultsExportFlat: getDefaultResourceState(null),
  pricingResultsExportFull: getDefaultResourceState(null),
  pricingResultsExportWithSightline: getDefaultResourceState(null)
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getCustomModules.pending, state => {
      setResourcePending(state.customModules)
    })
    .addCase(getCustomModules.rejected, (state, actions) => {
      setResourceRejected(state.customModules, actions)
    })
    .addCase(getCustomModules.fulfilled, (state, actions) => {
      state.customModules.data = actions.payload
      setResourceFullfilled(state.customModules)
    })
    .addCase(clearCustomModules, state => {
      state.customModules = initialState.customModules
    })

    .addCase(createCustomModule.pending, state => {
      setResourcePending(state.createCustomModule)
    })
    .addCase(createCustomModule.rejected, (state, actions) => {
      setResourceRejected(state.createCustomModule, actions)
    })
    .addCase(createCustomModule.fulfilled, (state, actions) => {
      state.createCustomModule.data = actions.payload
      setResourceFullfilled(state.createCustomModule)
    })
    .addCase(clearCreateCustomModule, state => {
      state.createCustomModule = initialState.createCustomModule
    })

    .addCase(getScopeDefinition.pending, state => {
      setResourcePending(state.scopeDefinition)
    })
    .addCase(getScopeDefinition.rejected, (state, actions) => {
      setResourceRejected(state.scopeDefinition, actions)
    })
    .addCase(getScopeDefinition.fulfilled, (state, actions) => {
      state.scopeDefinition.data = actions.payload
      setResourceFullfilled(state.scopeDefinition)
    })
    .addCase(clearGetScopeDefinition, state => {
      state.scopeDefinition = initialState.scopeDefinition
    })

    .addCase(getProjectSetup.pending, state => {
      setResourcePending(state.projectSetup)
    })
    .addCase(getProjectSetup.rejected, (state, actions) => {
      setResourceRejected(state.projectSetup, actions)
    })
    .addCase(getProjectSetup.fulfilled, (state, actions) => {
      state.projectSetup.data = actions.payload
      setResourceFullfilled(state.projectSetup)
    })
    .addCase(clearGetProjectSetup, state => {
      state.projectSetup = initialState.projectSetup
    })

    .addCase(getBenchmark.pending, state => {
      setResourcePending(state.benchmark)
    })
    .addCase(getBenchmark.rejected, (state, actions) => {
      setResourceRejected(state.benchmark, actions)
    })
    .addCase(getBenchmark.fulfilled, (state, actions) => {
      state.benchmark.data = actions.payload
      setResourceFullfilled(state.benchmark)
    })
    .addCase(clearGetBenchmark, state => {
      state.benchmark = initialState.benchmark
    })

    .addCase(getTPForms.pending, state => {
      setResourcePending(state.tpForms)
    })
    .addCase(getTPForms.rejected, (state, actions) => {
      setResourceRejected(state.tpForms, actions)
    })
    .addCase(getTPForms.fulfilled, (state, actions) => {
      state.tpForms.data = actions.payload
      setResourceFullfilled(state.tpForms)
    })
    .addCase(clearGetTPForms, state => {
      state.tpForms = initialState.tpForms
    })

    .addCase(getMasterFile.pending, state => {
      setResourcePending(state.masterFile)
    })
    .addCase(getMasterFile.rejected, (state, actions) => {
      setResourceRejected(state.masterFile, actions)
    })
    .addCase(getMasterFile.fulfilled, (state, actions) => {
      state.masterFile.data = actions.payload
      setResourceFullfilled(state.masterFile)
    })
    .addCase(clearGetMasterFile, state => {
      state.masterFile = initialState.masterFile
    })

    .addCase(getLocalFile.pending, state => {
      setResourcePending(state.localFile)
    })
    .addCase(getLocalFile.rejected, (state, actions) => {
      setResourceRejected(state.localFile, actions)
    })
    .addCase(getLocalFile.fulfilled, (state, actions) => {
      state.localFile.data = actions.payload
      setResourceFullfilled(state.localFile)
    })
    .addCase(clearGetLocalFile, state => {
      state.localFile = initialState.localFile
    })

    .addCase(getCbCr.pending, state => {
      setResourcePending(state.cbCr)
    })
    .addCase(getCbCr.rejected, (state, actions) => {
      setResourceRejected(state.cbCr, actions)
    })
    .addCase(getCbCr.fulfilled, (state, actions) => {
      state.cbCr.data = actions.payload
      setResourceFullfilled(state.cbCr)
    })
    .addCase(clearGetCbCr, state => {
      state.cbCr = initialState.cbCr
    })

    .addCase(getDataCollection.pending, state => {
      setResourcePending(state.dataCollection)
    })
    .addCase(getDataCollection.rejected, (state, actions) => {
      setResourceRejected(state.dataCollection, actions)
    })
    .addCase(getDataCollection.fulfilled, (state, actions) => {
      state.dataCollection.data = actions.payload
      setResourceFullfilled(state.dataCollection)
    })
    .addCase(clearGetDataCollection, state => {
      state.dataCollection = initialState.dataCollection
    })

    .addCase(getScopeAssumptions.pending, state => {
      setResourcePending(state.scopeAssumptions)
    })
    .addCase(getScopeAssumptions.rejected, (state, actions) => {
      setResourceRejected(state.scopeAssumptions, actions)
    })
    .addCase(getScopeAssumptions.fulfilled, (state, actions) => {
      state.scopeAssumptions.data = actions.payload
      setResourceFullfilled(state.scopeAssumptions)
    })
    .addCase(clearGetScopeAssumptions, state => {
      state.scopeAssumptions = initialState.scopeAssumptions
    })

    .addCase(getProjectManagement.pending, state => {
      setResourcePending(state.projectManagement)
    })
    .addCase(getProjectManagement.rejected, (state, actions) => {
      setResourceRejected(state.projectManagement, actions)
    })
    .addCase(getProjectManagement.fulfilled, (state, actions) => {
      state.projectManagement.data = actions.payload
      setResourceFullfilled(state.projectManagement)
    })
    .addCase(clearGetProjectManagement, state => {
      state.projectManagement = initialState.projectManagement
    })

    .addCase(getDiscount.pending, state => {
      setResourcePending(state.discount)
    })
    .addCase(getDiscount.rejected, (state, actions) => {
      setResourceRejected(state.discount, actions)
    })
    .addCase(getDiscount.fulfilled, (state, actions) => {
      state.discount.data = actions.payload
      setResourceFullfilled(state.discount)
    })
    .addCase(clearGetDiscount, state => {
      state.discount = initialState.discount
    })

    .addCase(getCustomModule.pending, state => {
      setResourcePending(state.customModule)
    })
    .addCase(getCustomModule.rejected, (state, actions) => {
      setResourceRejected(state.customModule, actions)
    })
    .addCase(getCustomModule.fulfilled, (state, actions) => {
      state.customModule.data = actions.payload
      setResourceFullfilled(state.customModule)
    })
    .addCase(clearGetCustomModule, state => {
      state.customModule = initialState.customModule
    })

    .addCase(getPricingResults.pending, state => {
      setResourcePending(state.pricingResults)
    })
    .addCase(getPricingResults.rejected, (state, actions) => {
      setResourceRejected(state.pricingResults, actions)
    })
    .addCase(getPricingResults.fulfilled, (state, actions) => {
      state.pricingResults.data = actions.payload
      setResourceFullfilled(state.pricingResults)
    })
    .addCase(clearGetPricingResults, state => {
      state.pricingResults = initialState.pricingResults
    })

    .addCase(exportResultsFlat.pending, state => {
      setResourcePending(state.pricingResultsExportFlat)
    })
    .addCase(exportResultsFlat.rejected, (state, actions) => {
      setResourceRejected(state.pricingResultsExportFlat, actions)
    })
    .addCase(exportResultsFlat.fulfilled, (state, actions) => {
      state.pricingResultsExportFlat.data = actions.payload
      setResourceFullfilled(state.pricingResultsExportFlat)
    })
    .addCase(clearExportResultsFlat, state => {
      state.pricingResultsExportFlat = initialState.pricingResultsExportFlat
    })

    .addCase(exportResultsFull.pending, state => {
      setResourcePending(state.pricingResultsExportFull)
    })
    .addCase(exportResultsFull.rejected, (state, actions) => {
      setResourceRejected(state.pricingResultsExportFull, actions)
    })
    .addCase(exportResultsFull.fulfilled, (state, actions) => {
      state.pricingResultsExportFull.data = actions.payload
      setResourceFullfilled(state.pricingResultsExportFull)
    })
    .addCase(clearExportResultsFull, state => {
      state.pricingResultsExportFull = initialState.pricingResultsExportFull
    })

    .addCase(exportResultsWithSightline.pending, state => {
      setResourcePending(state.pricingResultsExportWithSightline)
    })
    .addCase(exportResultsWithSightline.rejected, (state, actions) => {
      setResourceRejected(state.pricingResultsExportWithSightline, actions)
    })
    .addCase(exportResultsWithSightline.fulfilled, (state, actions) => {
      state.pricingResultsExportWithSightline.data = actions.payload
      setResourceFullfilled(state.pricingResultsExportWithSightline)
    })
    .addCase(clearExportResultsWithSightline, state => {
      state.pricingResultsExportWithSightline = initialState.pricingResultsExportWithSightline
    })
)
