import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { usePricingTab } from '../../../../../context/pricingTab.context'
import { DragLayer } from '../components/DragLayer'
import { ProjectSetupForms } from '../components/ProjectSetupForms'
import { ScopesWrapper } from '../components/ScopesWrapper'
import type { ITransformedData } from '../types/dnd'
import type { IProjectSetup } from '../types/inputsTab'
import { transformData } from '../utils/transformData'

const ProjectSetupContent = ({ data }: { data: IProjectSetup | null }) => {
  const [transformedData, setTransformedData] = useState<ITransformedData | null>(null)

  useEffect(() => {
    if (data) {
      const newData = transformData(
        data.parentScopes,
        data.scopeIncludes,
        data.scopeExcludes,
        data.scopeAssumes,
        { type: data.type, name: data.name, projectId: data.projectId }
      )
      setTransformedData(newData)
    }
  }, [data])

  return (
    <>
      {data && (
        <>
          <ProjectSetupForms
            businessUnits={data.regionBusinessUnits}
            transactions={data.transactions}
            isPermitted={data.moduleMetadata.isPermitted}
          />

          {data.transactions && (
            <ScopesWrapper totalFee={data.quotes?.scopeTotal}>
              <>
                {transformedData && (
                  <DragLayer
                    transformedData={transformedData}
                    quotes={data.quotes}
                    transactionalModules={data.transactionModule}
                  />
                )}
              </>
            </ScopesWrapper>
          )}
        </>
      )}
    </>
  )
}

const ProjectSetup = () => {
  const {
    tabContent: { selector }
  } = usePricingTab()
  const { data } = useSelector(selector)

  return <ProjectSetupContent data={data as IProjectSetup} />
}

export default ProjectSetup
