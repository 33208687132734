import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Url } from '../../../../constants/urls'
import { useApp } from '../../../../context/app.context'
import * as actions from '../../../../modules/users/store/actions'
import { useAppDispatch } from '../../../../store'
import { CookieSettings } from '../../../../types/user'
import { SDKButton } from '../../../SDK/Button'
import { SDKModal } from '../../../SDK/Modal'
import { SDKParagraph } from '../../../SDK/Paragraph'
import styles from './index.module.scss'

interface ICookiesPolicyApprovalModal {
  setVisible: (data: boolean) => void
  visible: boolean
}

export const CookiesPolicyApprovalModal = ({
  visible,
  setVisible
}: ICookiesPolicyApprovalModal) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {
    appContext: {
      style: { isMobile },
      user
    }
  } = useApp()
  const onDeclineOptionalCookies = () => {
    if (!!user) {
      dispatch(
        actions.updateUserCookieSettings({
          ...user,
          cookieSettings: CookieSettings.DeclineOptional
        })
      )
      user.cookieSettings = CookieSettings.DeclineOptional
      setVisible(false)
    }
  }
  const onAcceptAllCookies = () => {
    if (!!user) {
      dispatch(
        actions.updateUserCookieSettings({
          ...user,
          cookieSettings: CookieSettings.AcceptAll
        })
      )
      user.cookieSettings = CookieSettings.AcceptAll
      setVisible(false)
    }
  }
  const onNavigateToCookiesPolicyPage = () => {
    setVisible(false)
    navigate(Url.CookiesPolicy)
  }
  return (
    <SDKModal
      onCancel={() => onDeclineOptionalCookies()}
      onSubmit={() => {}}
      submitLabel={t(`cookiePolicyApproval.accept`)!}
      title={t('cookiePolicyApproval.title')!}
      visible={visible}
      withRequired={false}
      className={classNames(
        isMobile ? styles['cookie-policy-approval-modal'] : '',
        'ap-mx-spacing-5'
      )}
      footer={
        <>
          <SDKButton
            onClick={() => onDeclineOptionalCookies()}
            className={isMobile ? 'ap-mb-spacing-5' : 'ap-mr-spacing-5'}
          >
            {t(`cookiePolicyApproval.decline`)}
          </SDKButton>
          <SDKButton onClick={() => onAcceptAllCookies()}>
            {t(`cookiePolicyApproval.accept`)}
          </SDKButton>
        </>
      }
    >
      <SDKParagraph className='ap-mb-spacing-5'>
        {t('cookiePolicyApproval.contentPart1')}
      </SDKParagraph>
      <SDKParagraph className='ap-mb-spacing-5'>
        {t('cookiePolicyApproval.contentPart2')}
      </SDKParagraph>
      <SDKParagraph className='ap-mb-spacing-5'>
        {t('cookiePolicyApproval.contentPart3')}
      </SDKParagraph>
      <SDKButton
        className='ap-px-spacing-1'
        onClick={onNavigateToCookiesPolicyPage}
        kind='text'
        compact
      >
        {t('cookiePolicyApproval.moreInfo')}
      </SDKButton>
    </SDKModal>
  )
}
