import { useTranslation } from 'react-i18next'
import type { IDocument } from '../../../types/document'
import { CustomBadge } from '../../CustomBadge'
import { Divider } from '../../Divider'
import { SDKPanel } from '../../SDK/Panel'
import { SDKText } from '../../SDK/Text'
import { CardActionButtonGroup } from '../components/CardActionButtonGroup'
import { EAction } from '../const'
import type { IActionCallback } from '../type'

interface IDocumentCard {
  actions: EAction[]
  actionCallback: (_callback: IActionCallback) => void
  document: IDocument
}

export const DocumentCard = ({ actions, actionCallback, document }: IDocumentCard) => {
  const { t } = useTranslation('documents')

  return (
    <SDKPanel>
      <SDKText type='subheading' className='ap-text-neutral-20' weight={2}>
        {document.name}
      </SDKText>
      <Divider />

      <div className='ap-flex justify-content-between align-items-center'>
        <div className='ap-flex flex-column'>
          <SDKText type='body-xs' className='ap-text-neutral-14 ap-mt-spacing-1'>
            {t('documentCard.identifier')}
          </SDKText>

          <CustomBadge value={document.identifier} />
        </div>

        <div>
          <CardActionButtonGroup
            actions={actions}
            id={document.documentId}
            actionCallback={actionCallback}
          />
        </div>
      </div>
    </SDKPanel>
  )
}
