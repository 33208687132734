import { FiscalYearUrl } from '../../constants/urls'
import { IGateQuestionsDraftUrl } from './types'

export const gateQuestionsDraftUrl = ({
  yearId,
  countryId,
  gateQuestionId,
  draftAnswerId
}: IGateQuestionsDraftUrl) =>
  `${FiscalYearUrl.Years}/${yearId}/${FiscalYearUrl.Countries}/${countryId}/${
    FiscalYearUrl.GateQuestins
  }/${gateQuestionId}${draftAnswerId ? `/${FiscalYearUrl.Answers}/${draftAnswerId}` : ''}`
