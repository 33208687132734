import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { NavigationTileGrid } from '../../../../../../components/NavigationTileGrid'
import { Url } from '../../../../../../constants/urls'
import { useProject } from '../../../../../../context/project.context'
import { useAppDispatch } from '../../../../../../store'
import { EWizardStep } from '../../../../components/AddEditProject/components/AddEditProjectModal/const'
import { useWizardStepsKeys } from '../../../../components/AddEditProject/components/AddEditProjectModal/hooks/useWizardSteps'
import * as actions from '../../../../store/actions'

export const NavigationTiles = () => {
  const { t } = useTranslation('myprojects')
  const navigation = useNavigate()
  const dispatch = useAppDispatch()
  const { project } = useProject()
  const wizardSteps = useWizardStepsKeys()

  const onProjectEdit = (step: EWizardStep) => {
    dispatch(actions.clearAddProject())
    dispatch(actions.clearUpdateProject())
    const url = `${Url.MyProjectsPage}/${project?.projectId}${Url.EditProjectPage}/${step}`
    navigation(url)
  }

  let list = [
    {
      action: () => onProjectEdit(wizardSteps[0]),
      active: wizardSteps.includes(wizardSteps[0]),
      icon: 'notebook',
      name: t('projectSettings.tiles.projectInformation.label')
    },
    {
      action: () => onProjectEdit(wizardSteps[1]),
      active: wizardSteps.includes(wizardSteps[1]),
      icon: 'globe',
      name: t('projectSettings.tiles.projectCountries.label')
    },
    {
      action: () => onProjectEdit(wizardSteps[2]),
      active: wizardSteps.includes(wizardSteps[2]),
      icon: 'audience',
      name: t('projectSettings.tiles.projectUsers.label')
    }
  ]

  return <NavigationTileGrid list={list} />
}
