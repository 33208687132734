import { EPricingTab } from '../../../modules/project/components/GCDPricing/const/enums'
import type { IProjectSetup } from '../../../modules/project/components/GCDPricing/types/inputsTab'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const getProjectSetupData = async (projectId: number): Promise<IProjectSetup> => {
  return (
    await api.get(
      `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/${ProjectsUrl.Modules}/${EPricingTab.ProjectSetup}`
    )
  ).data
}
