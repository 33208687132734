import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Module } from '../../../constants/modules'
import type { IAddCategoryPayload } from '../../../services/requests/countriesSnapshot/addNewCategory'
import { addNewCategory } from '../../../services/requests/countriesSnapshot/addNewCategory'
import {
  addNewQuestion,
  IAddQuestionPayload
} from '../../../services/requests/countriesSnapshot/addNewQuestion'
import {
  addNewSubcategory,
  IAddSubcategoryPayload
} from '../../../services/requests/countriesSnapshot/addNewSubcategory'
import { deleteCategoryRequest } from '../../../services/requests/countriesSnapshot/deleteCategory'
import { deleteQuestionRequest } from '../../../services/requests/countriesSnapshot/deleteQuestion'
import { deleteSubcategoryRequest } from '../../../services/requests/countriesSnapshot/deleteSubcategory'
import { getCategoriesData } from '../../../services/requests/countriesSnapshot/getCategoriesData'
import { getQuestionsData } from '../../../services/requests/countriesSnapshot/getQuestionsData'
import { swapQuestionsOrderRequest } from '../../../services/requests/countriesSnapshot/swapQuestionOrder'
import type { IUpdateCategoryPayload } from '../../../services/requests/countriesSnapshot/updateCategory'
import { updateSingleCategory } from '../../../services/requests/countriesSnapshot/updateCategory'
import { updateQuestion } from '../../../services/requests/countriesSnapshot/updateQuestion'
import { updateSingleSubcategory } from '../../../services/requests/countriesSnapshot/updateSubcategory'
import { EQuestionToDelete } from '../../questionnaires/pages/Questions/components/ListHeaderButtons/constants'
import { EAnswerTypeFromBE } from '../pages/AddEditQuestion/constants/answerType'

const moduleName: string = String(Module.CountriesSnapshot)

export const getCategories = createAsyncThunk(`${moduleName}/getCategories`, (id: number) =>
  getCategoriesData(id)
)
export const clearCategories = createAction(`${moduleName}/clearCategories`)

export const addCategory = createAsyncThunk(
  `${moduleName}/addCategory`,
  ({ params, yearId }: { params: IAddCategoryPayload; yearId: number }) =>
    addNewCategory(params, yearId)
)
export const clearAddCategory = createAction(`${moduleName}/clearAddCategory`)

export const updateCategory = createAsyncThunk(
  `${moduleName}/updateCategory`,
  ({
    params,
    yearId,
    categoryId
  }: {
    params: IUpdateCategoryPayload
    yearId: number
    categoryId: number
  }) => updateSingleCategory(params, yearId, categoryId)
)
export const clearUpdateCategory = createAction(`${moduleName}/clearUpdateCategory`)

export const deleteCategory = createAsyncThunk(
  `${moduleName}/deleteCategory`,
  ({ yearId, categoryId }: { yearId: number; categoryId: number }) =>
    deleteCategoryRequest(yearId, categoryId)
)
export const clearDeleteCategory = createAction(`${moduleName}/clearDeleteCategory`)

export const addSubcategory = createAsyncThunk(
  `${moduleName}/addSubcategory`,
  ({
    params,
    yearId,
    categoryId
  }: {
    params: IAddSubcategoryPayload
    yearId: number
    categoryId: number
  }) => addNewSubcategory(params, yearId, categoryId)
)
export const clearAddSubcategory = createAction(`${moduleName}/clearAddSubcategory`)

export const updateSubcategory = createAsyncThunk(
  `${moduleName}/updateSubcategory`,
  ({
    params,
    yearId,
    categoryId,
    subcategoryId
  }: {
    params: IUpdateCategoryPayload
    yearId: number
    categoryId: number
    subcategoryId: number
  }) => updateSingleSubcategory(params, yearId, categoryId, subcategoryId)
)
export const clearUpdateSubcategory = createAction(`${moduleName}/clearUpdateSubcategory`)

export const deleteSubcategory = createAsyncThunk(
  `${moduleName}/deleteSubcategory`,
  ({
    yearId,
    categoryId,
    subcategoryId
  }: {
    yearId: number
    categoryId: number
    subcategoryId: number
  }) => deleteSubcategoryRequest(yearId, categoryId, subcategoryId)
)
export const clearDeleteSubcategory = createAction(`${moduleName}/clearDeleteSubcategory`)

export const getQuestions = createAsyncThunk(`${moduleName}/getQuestions`, (id: number) =>
  getQuestionsData(id)
)
export const clearQuestions = createAction(`${moduleName}/clearQuestions`)
export const deleteQuestion = createAsyncThunk(
  `${moduleName}/deleteQuestion`,
  ({
    questionId,
    yearId,
    categoryId,
    subcategoryId,
    questionType
  }: {
    questionId: number
    yearId: number
    categoryId: number
    subcategoryId: number
    questionType: EQuestionToDelete
  }) => deleteQuestionRequest(questionId, yearId, categoryId, subcategoryId, questionType)
)
export const clearDeleteQuestion = createAction(`${moduleName}/clearDeleteQuestion`)

export const addQuestion = createAsyncThunk(
  `${moduleName}/addQuestion`,
  ({
    params,
    yearId,
    categoryId,
    subcategoryId,
    questionType
  }: {
    params: IAddQuestionPayload
    yearId: number
    categoryId: number
    subcategoryId: number
    questionType: EAnswerTypeFromBE
  }) => addNewQuestion(params, yearId, categoryId, subcategoryId, questionType)
)
export const clearAddQuestion = createAction(`${moduleName}/clearAddQuestion`)

export const updateSingleQuestion = createAsyncThunk(
  `${moduleName}/updateQuestion`,
  ({
    params,
    yearId,
    categoryId,
    subcategoryId,
    questionType,
    questionId
  }: {
    params: IAddQuestionPayload
    yearId: number
    categoryId: number
    subcategoryId: number
    questionType: EAnswerTypeFromBE
    questionId: number
  }) => updateQuestion(params, yearId, categoryId, subcategoryId, questionType, questionId)
)
export const clearUpdateQuestion = createAction(`${moduleName}/clearUpdateQuestion`)

export const swapQuestionsOrder = createAsyncThunk(
  `${moduleName}/swapQuestionsOrder`,
  ({
    previousQuestionId,
    nextQuestionId,
    yearId
  }: {
    previousQuestionId: number
    nextQuestionId: number
    yearId: number
  }) => swapQuestionsOrderRequest(previousQuestionId, nextQuestionId, yearId)
)
export const clearSwapQuestionsOrder = createAction(`${moduleName}/swapQuestionsOrder`)
