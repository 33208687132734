import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DeleteModal } from '../../../../components/DeleteModal'
import { useAppDispatch } from '../../../../store'
import { EQuestionToDelete } from '../../../questionnaires/pages/Questions/components/ListHeaderButtons/constants'
import * as actions from '../../store/actions'
import * as selectors from '../../store/selectors'
import { TQuestion } from '../../types'

interface IDeleteQuestionModalProps {
  setQuestionToDelete: (q: TQuestion | null) => void
  yearId: number
  questionId: number
  categoryId: number
  subcategoryId: number
  questionType: EQuestionToDelete
}
export const DeleteQuestionModal = ({
  setQuestionToDelete,
  yearId,
  questionId,
  categoryId,
  subcategoryId,
  questionType
}: IDeleteQuestionModalProps) => {
  const { t } = useTranslation('countriesSnapshot')
  const dispatch = useAppDispatch()
  const { error, loading } = useSelector(selectors.deleteQuestion)

  const onClose = () => {
    setQuestionToDelete(null)
    dispatch(actions.clearDeleteQuestion())
  }

  const onSubmit = () => {
    dispatch(
      actions.deleteQuestion({ questionId, yearId, categoryId, subcategoryId, questionType })
    )
  }

  const displayTextFromTranslation = (text: string) => t(`deleteQuestion.${text}`)!

  return (
    <DeleteModal
      onSubmit={onSubmit}
      displayTextFromTranslation={displayTextFromTranslation}
      onModalClose={onClose}
      onSuccess={() => dispatch(actions.getQuestions(yearId))}
      error={error}
      loading={loading}
    />
  )
}
