import type { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormInput } from '../../../../../../../components/Form/FormInput'
import type { IFormValues } from '../../../../../../../schemas/useAddEditScopeSchema'

export const ChildScopeForm = ({ control }: { control: Control<IFormValues, any> }) => {
  const { t } = useTranslation('pricing')

  return (
    <div className='ap-container add-edit-custom-module-form'>
      <div className='form-container'>
        <div className='row'>
          <div className='col'>
            <FormInput
              name={'name'}
              type='text'
              label={t('tabs.inputs.childScopeModal.form.name.label')!}
              required={true}
              className='ap-mb-spacing-5'
              control={control}
              errorMessage={t('tabs.inputs.childScopeModal.form.name.error')!}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
