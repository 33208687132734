import { createReducer } from '@reduxjs/toolkit'
import {
  Resource,
  getDefaultResourceState,
  setResourceFullfilled,
  setResourcePending,
  setResourceRejected
} from '../../../shared/utils/store'
import { INewsfeedResponse } from '../types'
import { clearGetNewsfeed, getNewsfeed } from './actions'

export interface UpdatesState {
  newsfeed: Resource<INewsfeedResponse | null>
}

const initialState: UpdatesState = {
  newsfeed: getDefaultResourceState(null)
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getNewsfeed.pending, state => {
      setResourcePending(state.newsfeed)
    })
    .addCase(getNewsfeed.rejected, (state, actions) => {
      setResourceRejected(state.newsfeed, actions)
    })
    .addCase(getNewsfeed.fulfilled, (state, actions) => {
      state.newsfeed.data = actions.payload
      setResourceFullfilled(state.newsfeed)
    })
    .addCase(clearGetNewsfeed, state => {
      state.newsfeed = initialState.newsfeed
    })
)
