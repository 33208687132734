import * as selectors from '../store/selectors'
import Benchmark from '../tabs/Benchmark'
import CbCR from '../tabs/CbCR'
import DataCollection from '../tabs/DataCollection'
import Discount from '../tabs/Discount'
import LocalFile from '../tabs/LocalFile/LocalFile'
import MasterFile from '../tabs/MasterFile'
import ProjectManagement from '../tabs/ProjectManagement'
import ProjectSetup from '../tabs/ProjectSetup'
import ScopeAssumptions from '../tabs/ScopeAssumptions'
import ScopeDefinition from '../tabs/ScopeDefinition'
import TPForms from '../tabs/TPForms'
import { EPricingTab, EPricingTabId } from './enums'

export const PRICING_TABS_KEYS: EPricingTab[] = [
  EPricingTab.ScopeDefinition,
  EPricingTab.ProjectSetup,
  EPricingTab.MasterFile,
  EPricingTab.LocalFile,
  EPricingTab.Benchmark,
  EPricingTab.TPForms,
  EPricingTab.CbCR,
  EPricingTab.DataCollection,
  EPricingTab.ProjectManagement,
  EPricingTab.Discount,
  EPricingTab.ScopeAssumptions
]

export const PRICING_TABS_COMPONENTS: { [x: string]: () => JSX.Element } = {
  [EPricingTab.ScopeDefinition]: ScopeDefinition,
  [EPricingTab.ProjectSetup]: ProjectSetup,
  [EPricingTab.MasterFile]: MasterFile,
  [EPricingTab.LocalFile]: LocalFile,
  [EPricingTab.Benchmark]: Benchmark,
  [EPricingTab.TPForms]: TPForms,
  [EPricingTab.CbCR]: CbCR,
  [EPricingTab.DataCollection]: DataCollection,
  [EPricingTab.ProjectManagement]: ProjectManagement,
  [EPricingTab.Discount]: Discount,
  [EPricingTab.ScopeAssumptions]: ScopeAssumptions
}

export const PRICING_TAB_DATA_SELECTORS = {
  [EPricingTabId.ScopeDefinition]: selectors.getScopeDefinitionData,
  [EPricingTabId.ProjectSetup]: selectors.getProjectSetup,
  [EPricingTabId.MasterFile]: selectors.getMasterFile,
  [EPricingTabId.LocalFile]: selectors.getLocalFile,
  [EPricingTabId.Benchmark]: selectors.getBenchmark,
  [EPricingTabId.TPForms]: selectors.getTPForms,
  [EPricingTabId.CbCR]: selectors.getCbCr,
  [EPricingTabId.DataCollection]: selectors.getDataCollection,
  [EPricingTabId.ProjectManagement]: selectors.getProjectManagement,
  [EPricingTabId.Discount]: selectors.getDiscount,
  [EPricingTabId.ScopeAssumptions]: selectors.getScopeAssumptions,
  [EPricingTabId.CustomModule]: selectors.getCustomModule
}
