import api from '../../api'
import { SystemManagementUrl } from '../../constants/urls'

export const addPricingImport = async (importFile: File): Promise<string> => {
  const data = new FormData()
  data.append('importFile', importFile)

  return (
    await api.post(`${SystemManagementUrl.DataImport}/${SystemManagementUrl.PricingTool}`, data, {
      ['Content-Type']: 'multipart/form-data'
    })
  ).data
}
