import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'
import { EBenchmarkingType } from '../modules/project/components/GCDPricing/const/enums'
import { findLocalFeeCountryId } from '../modules/project/components/GCDPricing/modals/ParentScopeModal/const'
import type {
  IParentComplexScopeFull,
  IPriceYear,
  TParentScope
} from '../modules/project/components/GCDPricing/types/scope'
import type { IOption } from '../types/form'

export enum EEScopeFields {
  benchmarkingType = 'benchmarkingType',
  complexity = 'complexity',
  countryId = 'countryId',
  localFeeCountryId = 'localFeeCountryId',
  localFeeCountryIdTranslations = 'localFeeCountryIdTranslations',
  name = 'name',
  priceYears = 'priceYears',
  priceYearsTranslations = 'priceYearsTranslations',
  scopeLocationType = 'scopeLocationType',
  translation = 'translation'
}
export interface IFormValues {
  [EEScopeFields.benchmarkingType]: EBenchmarkingType
  [EEScopeFields.complexity]: String
  [EEScopeFields.countryId]: string
  [EEScopeFields.localFeeCountryId]: string
  [EEScopeFields.localFeeCountryIdTranslations]: string
  [EEScopeFields.name]: string
  [EEScopeFields.priceYears]: IPriceYearForm[]
  [EEScopeFields.priceYearsTranslations]: IPriceYearForm[]
  [EEScopeFields.scopeLocationType]: string
  [EEScopeFields.translation]: boolean
}

export interface IPriceYearForm {
  centralFeeValue: string
  localFeeCountryId: string
  localFeeValue: string
  totalFeeValue: string
  yearName: number
}

export const transformPriceYears2Form = (
  priceYears: IPriceYear[] | undefined
): IPriceYearForm[] => {
  if (priceYears) {
    return priceYears.map(priceYear => {
      return {
        centralFeeValue: String(priceYear.centralFeeValue),
        localFeeCountryId: String(priceYear.localFeeCountryId),
        localFeeValue: String(priceYear.localFeeValue),
        totalFeeValue: String(priceYear.totalFeeValue),
        yearName: priceYear.yearName
      } as IPriceYearForm
    })
  } else {
    return []
  }
}

export const transformPriceYearsTranslations2Form = (
  priceYears: IPriceYear[] | undefined,
  priceYearsTranslations: IPriceYear[] | undefined
): IPriceYearForm[] => {
  if (priceYears) {
    return priceYears.map(priceYear => {
      const priceYearsTranslation = priceYearsTranslations?.find(
        translation => translation.yearName == priceYear.yearName
      )

      return {
        centralFeeValue: String(priceYearsTranslation?.centralFeeValue || 0),
        localFeeCountryId: String(priceYearsTranslation?.localFeeCountryId || 0),
        localFeeValue: String(priceYearsTranslation?.localFeeValue || 0),
        totalFeeValue: String(priceYearsTranslation?.totalFeeValue || 0),
        yearName: priceYear.yearName
      } as IPriceYearForm
    })
  } else {
    return []
  }
}

export const useEditParentScopeSchema = (scope: Partial<IParentComplexScopeFull> | null) => {
  const { t } = useTranslation()

  const getDefaultValues = (scopeData: Partial<IParentComplexScopeFull> | null) => {
    const priceYears: IPriceYearForm[] = transformPriceYears2Form(scopeData?.priceYears)
    const priceYearsTranslations: IPriceYearForm[] = transformPriceYearsTranslations2Form(
      scopeData?.priceYears,
      scopeData?.priceYearsTranslations
    )

    return {
      [EEScopeFields.benchmarkingType]: scopeData?.benchmarkingType || 0,
      [EEScopeFields.complexity]: String(scopeData?.complexity) || '0',
      [EEScopeFields.countryId]: String(scopeData?.countryId),
      [EEScopeFields.localFeeCountryId]: String(
        findLocalFeeCountryId({ ...scopeData } as Partial<TParentScope>)
      ),
      [EEScopeFields.localFeeCountryIdTranslations]: String(
        findLocalFeeCountryId({ ...scopeData } as Partial<TParentScope>, true)
      ),
      [EEScopeFields.name]: scopeData?.name || '',
      [EEScopeFields.scopeLocationType]: String(scopeData?.scopeLocationType) || '0',
      [EEScopeFields.priceYears]: priceYears,
      [EEScopeFields.priceYearsTranslations]: priceYearsTranslations,
      [EEScopeFields.translation]: scope?.translation || false
    }
  }

  const complexitySelector: IOption[] = Array.from(Array(3).keys()).map(item => ({
    label: t(`pricing:tabs.inputs.localFile.table.complexityValue.${item + 1}`),
    value: String(item + 1)
  }))

  const scopeLocationSelector: IOption[] = Array.from(Array(6).keys())
    .slice(1)
    .map(item => ({
      label: t(`pricing:tabs.inputs.localFile.table.scopeValue.${item}`),
      value: String(item)
    }))

  const defaultValues = getDefaultValues(scope)

  const validationSchema = object()
    .shape({
      [EEScopeFields.name]: string().required(t('form.requiredFields')!)
    })
    .required()

  return {
    complexitySelector,
    defaultValues,
    getDefaultValues,
    scopeLocationSelector,
    validationSchema
  }
}
