import classNames from 'classnames'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CardField } from '../../components/CardListItem/components/CardField'
import { CardListWrapper } from '../../components/CardListWrapper'
import { Label } from '../../components/Label'
import { Layout } from '../../components/Layout'
import { NavigationTileGrid } from '../../components/NavigationTileGrid'
import { SDKIcon } from '../../components/SDK/Icon'
import { SDKPanel } from '../../components/SDK/Panel'
import { SDKText } from '../../components/SDK/Text'
import { SDKTextButton } from '../../components/SDK/TextButton'
import { Url } from '../../constants/urls'
import { useApp } from '../../context/app.context'
import * as actions from '../../modules/questionnaires/store/actions'
import * as selectors from '../../modules/questionnaires/store/selectors'
import { useAppDispatch } from '../../store'
import type { FiscalYearBase, FiscalYearWithQuestionCount } from '../fiscalYearSetup/types'

const Questionnaires = () => {
  const { t } = useTranslation('questionnaires')
  const dispatch = useAppDispatch()
  const resource = useSelector(selectors.getQuestionnaireYears)
  const navigate = useNavigate()
  const {
    appContext: {
      style: { isMobile }
    }
  } = useApp()

  useEffect(() => {
    dispatch(actions.getQuestionnaireYears())
  }, [])

  const card = (year: FiscalYearBase) => (
    <div className='col ap-flex flex-column ap-pl-spacing-3'>
      <CardField label={t('year')} value={String(year.name)} />
    </div>
  )

  const unansweredCountries = () => (
    <div className={classNames('col ap-flex', isMobile ? 'flex-column' : 'flex-row-reverse')}>
      <Label>{t('missingAnswers')!}</Label>
      <SDKIcon code='survey-checkmark' className='ap-text-states-warning-07' />
    </div>
  )

  const viewCountries = (name: string) => (
    <SDKTextButton onClick={() => navigate(`${location.pathname}/${name}`)} className='ap-link'>
      <>
        <SDKText className='ap-text-color-data-orange'>{t('viewDetails')}</SDKText>
        <SDKIcon code='right-chevron'></SDKIcon>
      </>
    </SDKTextButton>
  )
  const questionnaireDetails = (year: FiscalYearWithQuestionCount) => (
    <div className='col ap-flex align-items-center justify-content-end'>
      {year.unansweredQuestionsCount ? (
        <div className='ap-mr-spacing-3'>{unansweredCountries()}</div>
      ) : (
        <></>
      )}
      <div
        className={classNames('ap-pl-spacing-3', year.unansweredQuestionsCount && 'border-left')}
        onClick={() => navigate(`${Url.QuestionnairesPage}/${year.name}`)}
      >
        {viewCountries(year.name)}
      </div>
    </div>
  )

  const panel = (year: FiscalYearWithQuestionCount) => (
    <SDKPanel key={year.yearId} className='ap-my-spacing-4'>
      <div className='row'>
        <div className='col ap-flex align-items-center'>
          <SDKText type='large-heading'>
            <SDKIcon className='ap-text-color-text-link-primary ap-mr-spacing-4' code='calendar' />
          </SDKText>

          {card(year)}
        </div>
        {questionnaireDetails(year)}
      </div>
    </SDKPanel>
  )
  const yearsList = resource.data.map(year => ({
    icon: 'calendar',
    name: String(year.name),
    component: card(year),
    action: () => {
      dispatch(actions.clearQuestionnaireCountries())
      navigate(`${Url.QuestionnairesPage}/${year.name}`)
    },
    middleSection: year.unansweredQuestionsCount ? unansweredCountries() : <></>
  }))

  return (
    <Layout>
      <CardListWrapper classNames='questionnaires-page' resource={resource}>
        <>
          {isMobile ? (
            <NavigationTileGrid list={yearsList} />
          ) : (
            resource.data.map(year => panel(year))
          )}
        </>
      </CardListWrapper>
    </Layout>
  )
}

export default Questionnaires
