import type {
  IChildScope,
  IParentScope
} from '../../../../modules/project/components/GCDPricing/types/scope'
import { EAction } from '../../const'
import { IActionCallback } from '../../type'
import { CardActionButton } from './CardActionButton'

export const CardActionButtonGroup = ({
  actions,
  actionCallback,
  data,
  id,
  isEnabled,
  hideLine,
  isUserItem = false,
  isParentScope = false,
  hasUserEditsDisabled = false,
  isToggleDisabled = false
}: {
  actions: EAction[]
  actionCallback?: (_callback: IActionCallback) => void
  data?: IChildScope | IParentScope
  id: number | string
  isEnabled?: boolean
  hideLine?: boolean
  isUserItem?: boolean
  isParentScope?: boolean
  hasUserEditsDisabled?: boolean
  isToggleDisabled?: boolean
}) => {
  return (
    <div className='ap-flex align-items-center justify-content-end'>
      {actions.map(action => (
        <CardActionButton
          handleClick={() => actionCallback?.({ action, id, item: data })}
          key={action}
          type={action}
          checked={isEnabled}
          hideLine={hideLine}
          isEditButtonVisible={isUserItem || (isParentScope && !hasUserEditsDisabled)}
          isToggleDisabled={isToggleDisabled}
        />
      ))}
    </div>
  )
}
