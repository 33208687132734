import queryString from 'query-string'
import { TFilter } from '../type'

export const setQueryString = (questionFilters: TFilter, unansweredOnly: boolean) => {
  let statesAndTypes = [...questionFilters.states, ...questionFilters.types].reduce(
    (acc, state) => {
      return {
        ...acc,
        [state]: true
      }
    },
    {}
  )

  if (unansweredOnly) {
    statesAndTypes = { ...statesAndTypes, UnansweredOnly: true }
  }
  const newQuery = queryString.stringify({
    ...statesAndTypes,
    subcategoryIds: questionFilters.subcategoryIds
  })
  if (newQuery.length) {
    return `?${newQuery}`
  }
  return ''
}
