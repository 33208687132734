import type { AvatarProps } from '@appkit4/react-components/esm/avatar'
import { AVATAR_URL } from '../../constants/urls'
import type { IUser, IUserBase } from '../../types/user'
import { getFullname, getInitials } from '../../utils/user'
import { SDKAvatar } from '../SDK/Avatar'

import './index.scss'

type TUserAvatarProps = { user: IUserBase | IUser | null } & AvatarProps

const UserAvatar = (props: TUserAvatarProps) => {
  const user = props.user
  const avatarProps = { ...props, user: undefined } as AvatarProps

  if (user?.hasAvatar && user?.guid) {
    return (
      <SDKAvatar
        className='user-avatar'
        imageAlt={getFullname(user)}
        src={`${AVATAR_URL}${user.guid}`}
        {...avatarProps}
      />
    )
  } else {
    return <SDKAvatar label={getInitials(user)} {...avatarProps} />
  }
}

export default UserAvatar
