import { createReducer } from '@reduxjs/toolkit'
import {
  getDefaultResourceState,
  Resource,
  setResourceFullfilled,
  setResourcePending,
  setResourceRejected
} from '../../../shared/utils/store'
import type { IUserBase } from '../../../types/user'
import { IProject } from '../../myprojects/types'
import type {
  ICalculationResultByFormat,
  ISightlineFile,
  ITemplateCalculation,
  ITemplateCalculationData
} from '../types/projectCalculation'
import { IProjectQuestion } from '../types/projectQuestions'
import { IProjectResultByQuestion } from '../types/projectResults'
import {
  clearCreateExportProject,
  clearCreateExportProjectToSightline,
  clearExportProject,
  clearExportProjectFromSightlineCS,
  clearExportTemplate,
  clearGetCalculationResult,
  clearGetCalculationResultFromSightline,
  clearGetPBICalculationResultByTemplateId,
  clearGetPBICalculationResultByTemplateIdFromSightline,
  clearGetProjectUsers,
  clearGetSightlineFiles,
  clearGetTemplateCalculationById,
  clearProjectQuestions,
  clearProjectResults,
  clearUpdateCalendar,
  clearUpdateCustomCountries,
  clearUpdateProjectQuestions,
  clearUploadBySightlineWithCalculation,
  clearUploadEntitiesFromTemplate,
  clearUploadTemplateToSightline,
  clearUploadTemplateWithCalculation,
  createExportProject,
  createExportProjectToSightline,
  exportProject,
  exportProjectFromSightlineCS,
  exportTemplate,
  getCalculationResult,
  getCalculationResultFromSightline,
  getPBICalculationResultByTemplateId,
  getPBICalculationResultByTemplateIdFromSightline,
  getProjectQuestions,
  getProjectResults,
  getProjectUsers,
  getSightlineFiles,
  getTemplateCalculationById,
  updateCalendar,
  updateCustomCountries,
  updateProjectQuestions,
  uploadBySightlineWithCalculation,
  uploadEntitiesFromTemplate,
  uploadTemplateToSightline,
  uploadTemplateWithCalculation
} from './actions'

export interface ProjectState {
  calculationResult: Resource<ICalculationResultByFormat | null>
  calculationResultFromSightline: Resource<string>
  createExportProject: Resource<File | null>
  createExportProjectToSightline: Resource<string | null>
  exportProject: Resource<File | null>
  exportProjectFromSightlineCS: Resource<string>
  exportTemplate: Resource<File | null>
  powerBICalculationResultByTemplateId: Resource<File | null>
  powerBICalculationResultByTemplateIdFromSightline: Resource<string>
  projectQuestions: Resource<IProjectQuestion[]>
  projectResults: Resource<IProjectResultByQuestion[]>
  projectUsers: Resource<IUserBase[]>
  sightlineFilesList: Resource<ISightlineFile[]>
  templateCalculationById: Resource<ITemplateCalculationData | null>
  updateCalendar: Resource<ITemplateCalculationData | null>
  updateCustomCountries: Resource<IProject | null>
  updateProjectQuestions: Resource<IProjectQuestion | null>
  uploadBySightlineWithCalculation: Resource<ITemplateCalculation | null>
  uploadEntitiesFromTemplate: Resource<{
    module: ITemplateCalculation
    countriesSkipped: string[]
  } | null>
  uploadTemplateToSightline: Resource<string | null>
  uploadTemplateWithCalculation: Resource<ITemplateCalculation | null>
}

const initialState: ProjectState = {
  calculationResult: getDefaultResourceState(null),
  calculationResultFromSightline: getDefaultResourceState(''),
  createExportProject: getDefaultResourceState(null),
  createExportProjectToSightline: getDefaultResourceState(null),
  exportProject: getDefaultResourceState(null),
  exportProjectFromSightlineCS: getDefaultResourceState(''),
  exportTemplate: getDefaultResourceState(null),
  powerBICalculationResultByTemplateId: getDefaultResourceState(null),
  powerBICalculationResultByTemplateIdFromSightline: getDefaultResourceState(''),
  projectQuestions: getDefaultResourceState([]),
  projectResults: getDefaultResourceState([]),
  projectUsers: getDefaultResourceState([]),
  sightlineFilesList: getDefaultResourceState([]),
  templateCalculationById: getDefaultResourceState(null),
  updateCalendar: getDefaultResourceState(null),
  updateCustomCountries: getDefaultResourceState(null),
  updateProjectQuestions: getDefaultResourceState(null),
  uploadBySightlineWithCalculation: getDefaultResourceState(null),
  uploadEntitiesFromTemplate: getDefaultResourceState(null),
  uploadTemplateToSightline: getDefaultResourceState(null),
  uploadTemplateWithCalculation: getDefaultResourceState(null)
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getProjectQuestions.pending, state => {
      setResourcePending(state.projectQuestions)
    })
    .addCase(getProjectQuestions.rejected, (state, actions) => {
      setResourceRejected(state.projectQuestions, actions)
    })
    .addCase(getProjectQuestions.fulfilled, (state, actions) => {
      state.projectQuestions.data = actions.payload
      setResourceFullfilled(state.projectQuestions)
    })
    .addCase(clearProjectQuestions, state => {
      state.projectQuestions = initialState.projectQuestions
    })
    .addCase(updateProjectQuestions.pending, state => {
      setResourcePending(state.updateProjectQuestions)
    })
    .addCase(updateProjectQuestions.rejected, (state, actions) => {
      setResourceRejected(state.updateProjectQuestions, actions)
    })
    .addCase(updateProjectQuestions.fulfilled, (state, actions) => {
      setResourceFullfilled(state.updateProjectQuestions)
      state.updateProjectQuestions.data = actions.payload
    })
    .addCase(clearUpdateProjectQuestions, state => {
      state.updateProjectQuestions = initialState.updateProjectQuestions
    })
    .addCase(getProjectUsers.pending, state => {
      setResourcePending(state.projectUsers)
    })
    .addCase(getProjectUsers.rejected, (state, actions) => {
      setResourceRejected(state.projectUsers, actions)
    })
    .addCase(getProjectUsers.fulfilled, (state, actions) => {
      state.projectUsers.data = actions.payload
      setResourceFullfilled(state.projectUsers)
    })
    .addCase(clearGetProjectUsers, state => {
      state.projectUsers = initialState.projectUsers
    })
    .addCase(getProjectResults.pending, state => {
      setResourcePending(state.projectResults)
    })
    .addCase(getProjectResults.rejected, (state, actions) => {
      setResourceRejected(state.projectResults, actions)
    })
    .addCase(getProjectResults.fulfilled, (state, actions) => {
      state.projectResults.data = actions.payload
      setResourceFullfilled(state.projectResults)
    })
    .addCase(clearProjectResults, state => {
      state.projectResults = initialState.projectResults
    })
    .addCase(exportProject.pending, state => {
      setResourcePending(state.exportProject)
    })
    .addCase(exportProject.rejected, (state, actions) => {
      setResourceRejected(state.exportProject, actions)
    })
    .addCase(exportProject.fulfilled, (state, actions) => {
      state.exportProject.data = actions.payload
      setResourceFullfilled(state.exportProject)
    })
    .addCase(clearExportProject, state => {
      state.exportProject = initialState.exportProject
    })
    .addCase(exportTemplate.pending, state => {
      setResourcePending(state.exportTemplate)
    })
    .addCase(exportTemplate.rejected, (state, actions) => {
      setResourceRejected(state.exportTemplate, actions)
    })
    .addCase(exportTemplate.fulfilled, (state, actions) => {
      state.exportTemplate.data = actions.payload
      setResourceFullfilled(state.exportTemplate)
    })
    .addCase(clearExportTemplate, state => {
      state.exportTemplate = initialState.exportTemplate
    })

    .addCase(uploadTemplateWithCalculation.pending, state => {
      setResourcePending(state.uploadTemplateWithCalculation)
    })
    .addCase(uploadTemplateWithCalculation.rejected, (state, actions) => {
      setResourceRejected(state.uploadTemplateWithCalculation, actions)
    })
    .addCase(uploadTemplateWithCalculation.fulfilled, (state, actions) => {
      state.uploadTemplateWithCalculation.data = actions.payload
      setResourceFullfilled(state.uploadTemplateWithCalculation)
    })
    .addCase(clearUploadTemplateWithCalculation, state => {
      state.uploadTemplateWithCalculation = initialState.uploadTemplateWithCalculation
    })

    .addCase(uploadEntitiesFromTemplate.pending, state => {
      setResourcePending(state.uploadEntitiesFromTemplate)
    })
    .addCase(uploadEntitiesFromTemplate.rejected, (state, actions) => {
      setResourceRejected(state.uploadEntitiesFromTemplate, actions)
    })
    .addCase(uploadEntitiesFromTemplate.fulfilled, (state, actions) => {
      state.uploadEntitiesFromTemplate.data = actions.payload
      setResourceFullfilled(state.uploadEntitiesFromTemplate)
    })
    .addCase(clearUploadEntitiesFromTemplate, state => {
      state.uploadEntitiesFromTemplate = initialState.uploadEntitiesFromTemplate
    })

    .addCase(getTemplateCalculationById.pending, state => {
      setResourcePending(state.templateCalculationById)
    })
    .addCase(getTemplateCalculationById.rejected, (state, actions) => {
      setResourceRejected(state.templateCalculationById, actions)
    })
    .addCase(getTemplateCalculationById.fulfilled, (state, actions) => {
      state.templateCalculationById.data = actions.payload
      setResourceFullfilled(state.templateCalculationById)
    })
    .addCase(clearGetTemplateCalculationById, state => {
      state.templateCalculationById = initialState.templateCalculationById
    })
    .addCase(uploadBySightlineWithCalculation.pending, state => {
      setResourcePending(state.uploadBySightlineWithCalculation)
    })
    .addCase(uploadBySightlineWithCalculation.rejected, (state, actions) => {
      setResourceRejected(state.uploadBySightlineWithCalculation, actions)
    })
    .addCase(uploadBySightlineWithCalculation.fulfilled, (state, actions) => {
      state.uploadBySightlineWithCalculation.data = actions.payload
      setResourceFullfilled(state.uploadBySightlineWithCalculation)
    })
    .addCase(clearUploadBySightlineWithCalculation, state => {
      state.uploadBySightlineWithCalculation = initialState.uploadBySightlineWithCalculation
    })
    .addCase(uploadTemplateToSightline.pending, state => {
      setResourcePending(state.uploadTemplateToSightline)
    })
    .addCase(uploadTemplateToSightline.rejected, (state, actions) => {
      setResourceRejected(state.uploadTemplateToSightline, actions)
    })
    .addCase(uploadTemplateToSightline.fulfilled, (state, actions) => {
      state.uploadTemplateToSightline.data = actions.payload
      setResourceFullfilled(state.uploadTemplateToSightline)
    })
    .addCase(clearUploadTemplateToSightline, state => {
      state.uploadTemplateToSightline = initialState.uploadTemplateToSightline
    })
    .addCase(getCalculationResult.pending, state => {
      setResourcePending(state.calculationResult)
    })
    .addCase(getCalculationResult.rejected, (state, actions) => {
      setResourceRejected(state.calculationResult, actions)
    })
    .addCase(getCalculationResult.fulfilled, (state, actions) => {
      state.calculationResult.data = actions.payload
      setResourceFullfilled(state.calculationResult)
    })
    .addCase(clearGetCalculationResult, state => {
      state.calculationResult = initialState.calculationResult
    })
    .addCase(getSightlineFiles.pending, state => {
      setResourcePending(state.sightlineFilesList)
    })
    .addCase(getSightlineFiles.rejected, (state, actions) => {
      setResourceRejected(state.sightlineFilesList, actions)
    })
    .addCase(getSightlineFiles.fulfilled, (state, actions) => {
      state.sightlineFilesList.data = actions.payload
      setResourceFullfilled(state.sightlineFilesList)
    })
    .addCase(clearGetSightlineFiles, state => {
      state.sightlineFilesList = initialState.sightlineFilesList
    })
    .addCase(getCalculationResultFromSightline.pending, state => {
      setResourcePending(state.calculationResultFromSightline)
    })
    .addCase(getCalculationResultFromSightline.rejected, (state, actions) => {
      setResourceRejected(state.calculationResultFromSightline, actions)
    })
    .addCase(getCalculationResultFromSightline.fulfilled, (state, actions) => {
      state.calculationResultFromSightline.data = actions.payload
      setResourceFullfilled(state.calculationResultFromSightline)
    })
    .addCase(clearGetCalculationResultFromSightline, state => {
      state.calculationResultFromSightline = initialState.calculationResultFromSightline
    })
    .addCase(getPBICalculationResultByTemplateId.pending, state => {
      setResourcePending(state.powerBICalculationResultByTemplateId)
    })
    .addCase(getPBICalculationResultByTemplateId.rejected, (state, actions) => {
      setResourceRejected(state.powerBICalculationResultByTemplateId, actions)
    })
    .addCase(getPBICalculationResultByTemplateId.fulfilled, (state, actions) => {
      state.powerBICalculationResultByTemplateId.data = actions.payload
      setResourceFullfilled(state.powerBICalculationResultByTemplateId)
    })
    .addCase(clearGetPBICalculationResultByTemplateId, state => {
      state.powerBICalculationResultByTemplateId = initialState.powerBICalculationResultByTemplateId
    })
    .addCase(getPBICalculationResultByTemplateIdFromSightline.pending, state => {
      setResourcePending(state.powerBICalculationResultByTemplateIdFromSightline)
    })
    .addCase(getPBICalculationResultByTemplateIdFromSightline.rejected, (state, actions) => {
      setResourceRejected(state.powerBICalculationResultByTemplateIdFromSightline, actions)
    })
    .addCase(getPBICalculationResultByTemplateIdFromSightline.fulfilled, (state, actions) => {
      state.powerBICalculationResultByTemplateIdFromSightline.data = actions.payload
      setResourceFullfilled(state.powerBICalculationResultByTemplateIdFromSightline)
    })
    .addCase(clearGetPBICalculationResultByTemplateIdFromSightline, state => {
      state.powerBICalculationResultByTemplateIdFromSightline =
        initialState.powerBICalculationResultByTemplateIdFromSightline
    })
    .addCase(updateCustomCountries.pending, state => {
      setResourcePending(state.updateCustomCountries)
    })
    .addCase(updateCustomCountries.rejected, (state, actions) => {
      setResourceRejected(state.updateCustomCountries, actions)
    })
    .addCase(updateCustomCountries.fulfilled, (state, actions) => {
      state.updateCustomCountries.data = actions.payload
      setResourceFullfilled(state.updateCustomCountries)
    })
    .addCase(clearUpdateCustomCountries, state => {
      state.updateCustomCountries = initialState.updateCustomCountries
    })
    .addCase(exportProjectFromSightlineCS.pending, state => {
      setResourcePending(state.exportProjectFromSightlineCS)
    })
    .addCase(exportProjectFromSightlineCS.rejected, (state, actions) => {
      setResourceRejected(state.exportProjectFromSightlineCS, actions)
    })
    .addCase(exportProjectFromSightlineCS.fulfilled, (state, actions) => {
      state.exportProjectFromSightlineCS.data = actions.payload
      setResourceFullfilled(state.exportProjectFromSightlineCS)
    })
    .addCase(clearExportProjectFromSightlineCS, state => {
      state.exportProjectFromSightlineCS = initialState.exportProjectFromSightlineCS
    })

    .addCase(createExportProject.pending, state => {
      setResourcePending(state.createExportProject)
    })
    .addCase(createExportProject.rejected, (state, actions) => {
      setResourceRejected(state.createExportProject, actions)
    })
    .addCase(createExportProject.fulfilled, (state, actions) => {
      state.createExportProject.data = actions.payload
      setResourceFullfilled(state.createExportProject)
    })
    .addCase(clearCreateExportProject, state => {
      state.createExportProject = initialState.createExportProject
    })

    .addCase(createExportProjectToSightline.pending, state => {
      setResourcePending(state.createExportProjectToSightline)
    })
    .addCase(createExportProjectToSightline.rejected, (state, actions) => {
      setResourceRejected(state.createExportProjectToSightline, actions)
    })
    .addCase(createExportProjectToSightline.fulfilled, (state, actions) => {
      state.createExportProjectToSightline.data = actions.payload
      setResourceFullfilled(state.createExportProjectToSightline)
    })
    .addCase(clearCreateExportProjectToSightline, state => {
      state.createExportProjectToSightline = initialState.createExportProjectToSightline
    })

    .addCase(updateCalendar.pending, state => {
      setResourcePending(state.updateCalendar)
    })
    .addCase(updateCalendar.rejected, (state, actions) => {
      setResourceRejected(state.updateCalendar, actions)
    })
    .addCase(updateCalendar.fulfilled, (state, actions) => {
      state.updateCalendar.data = actions.payload
      setResourceFullfilled(state.updateCalendar)
    })
    .addCase(clearUpdateCalendar, state => {
      state.updateCalendar = initialState.updateCalendar
    })
)
