import type { ComboboxProps } from '@appkit4/react-components/esm/combobox'
import { Combobox } from '@appkit4/react-components/esm/combobox'
import type { SelectValue } from '@appkit4/react-components/esm/combobox/Combobox'
import classNames from 'classnames'
import { useEffect, useState } from 'react'

export type TSelectValue = SelectValue

export const SDKCombobox = ({
  className,
  data,
  disabled,
  multiple,
  noResultFound,
  onSearch,
  onSelect,
  showSelectAll,
  placeholder,
  ...props
}: Partial<ComboboxProps> & {
  clearOnChange?: boolean
  multiple?: boolean
  showSelectAll?: boolean
}) => {
  const [value] = useState('')
  const [items, setItems] = useState<any[] | undefined>(data as any[])

  useEffect(() => {
    if (items !== undefined) setItems(data as any[])
  }, [items])

  useEffect(() => {
    setItems(data as any[])
  }, [data])

  return (
    <>
      {items !== undefined && (
        <Combobox
          className={classNames('sdk-combobox', 'ap-mb-spacing-5', className)}
          data={items}
          disabled={disabled}
          dropdownRenderMode={'portal'}
          labelKey={'label'}
          noResultFound={noResultFound}
          onSelect={selectedValue => {
            if (onSelect) {
              onSelect(selectedValue)
            }
            // Used to enfore hiding/showing the component as toherwise we are not able to get rid of the selected value
            // due to Appkit 4 limitation
            setItems(undefined)
          }}
          onSearch={onSearch}
          placeholder={placeholder}
          showClear={true}
          multiple={multiple}
          showSelectAll={showSelectAll}
          value={value}
          valueKey={'value'}
          {...props}
        />
      )}
    </>
  )
}
