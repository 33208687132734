import { InfoBox } from '../InfoBox'
import { SDKParagraph } from '../SDK/Paragraph'

export const FieldBox = ({ label, value }: { label: string; tooltip?: string; value: string }) => {
  return (
    <InfoBox>
      <div>
        <SDKParagraph weight={2}>{label}</SDKParagraph>
        <SDKParagraph>{value}</SDKParagraph>
      </div>
    </InfoBox>
  )
}
