import { useTranslation } from 'react-i18next'
import { boolean, object, string } from 'yup'

export enum EUploadBySightlineWithCalculationFields {
  dateOnly = 'dateOnly',
  sightlineInputFileId = 'sightlineInputFileId'
}
export interface IFormValues {
  [EUploadBySightlineWithCalculationFields.dateOnly]: boolean
  [EUploadBySightlineWithCalculationFields.sightlineInputFileId]: string
}

export const useUploadBySightlineWithCalculation = () => {
  const { t } = useTranslation('myprojects')

  const defaultValues: IFormValues = {
    [EUploadBySightlineWithCalculationFields.dateOnly]: false,
    [EUploadBySightlineWithCalculationFields.sightlineInputFileId]: ''
  }
  const validationSchema = object()
    .shape({
      [EUploadBySightlineWithCalculationFields.dateOnly]: boolean().required(
        t('projectDetail.projectTabs.tara.input.requiredField')!
      ),
      [EUploadBySightlineWithCalculationFields.sightlineInputFileId]: string().required(
        t('projectDetail.projectTabs.tara.input.requiredField')!
      )
    })
    .required()

  return { validationSchema, defaultValues }
}
