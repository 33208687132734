import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { HeaderButtons } from '../../../../components/HeaderButtons'
import { Layout } from '../../../../components/Layout'
import { useEditEmailTemplateSchema } from '../../../../schemas/useEditEmailTemplateSchema'
import { EDITING_EMAIL_TEMPLATE } from '../../constants'
import { EditEmailTemplateForm } from './components/EditEmailTemplateForm'
import { useEditEmailTemplate } from './components/EditEmailTemplateForm/utils/useEditEmailTemplate'
import { EmailTestSection } from './components/EmailTestSection'

export const EditEmailTemplate = () => {
  const { t } = useTranslation()
  const { validationSchema, defaultValues } = useEditEmailTemplateSchema()

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  })

  const { onSubmit } = useEditEmailTemplate(reset)

  return (
    <Layout
      onClick={() => localStorage.removeItem(EDITING_EMAIL_TEMPLATE)}
      buttonComponent={
        <HeaderButtons
          name={t('buttons.save')}
          icon='save'
          onClick={handleSubmit(onSubmit)}
          disabled={!isDirty}
        />
      }
    >
      <form>
        <EditEmailTemplateForm control={control} isDirty={isDirty} />
      </form>
      <EmailTestSection />
    </Layout>
  )
}
