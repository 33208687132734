import { useSelector } from 'react-redux'
import { usePricingTab } from '../../../../../context/pricingTab.context'
import { DiscountResult } from '../components/DiscountResult'
import { ScopeConfigurator } from '../components/ScopeConfigurator'
import { EPricingTab } from '../const/enums'
import type { IDiscountData } from '../types/inputsTab'

const DiscountContent = ({ data }: { data: IDiscountData | null }) => {
  return (
    <>
      <ScopeConfigurator
        name={EPricingTab.Discount}
        withOptionalSwitch={false}
        withRadioBtn={false}
        moduleMetadata={{
          ...data?.moduleMetadata,
          discount: data?.discounts.discountPerFiscalYear
        }}
      />
      {data && data.moduleMetadata.isEnabled && <DiscountResult data={data} />}
    </>
  )
}

const Discount = () => {
  const {
    tabContent: { selector }
  } = usePricingTab()
  const { data } = useSelector(selector)

  return <DiscountContent data={data as IDiscountData} />
}

export default Discount
