import { EAction } from '../const'
import type { IActionCallback } from '../type'
import { CardActionButton } from './CardActionButton'

export const CardActionButtonGroup = ({
  actions,
  actionCallback,
  id
}: {
  actions: EAction[]
  actionCallback?: (_callback: IActionCallback) => void
  id: number | string
}) => {
  return (
    <div className='buttons-wrapper ap-flex align-self-center'>
      {actions.map(action => (
        <CardActionButton
          handleClick={() => actionCallback?.({ action, id })}
          key={action}
          type={action}
        />
      ))}
    </div>
  )
}
