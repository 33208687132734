import { useTranslation } from 'react-i18next'
import { HeaderButtons } from '../../../../../../components/HeaderButtons'
interface IAddEditCountryHeader {
  onSave: () => void
  disabledSubmit: boolean
}
export const AddEditCountryHeader = ({ onSave, disabledSubmit }: IAddEditCountryHeader) => {
  const { t } = useTranslation()
  return (
    <HeaderButtons
      onClick={onSave}
      icon='save'
      name={t('buttons.save')}
      disabled={disabledSubmit}
    />
  )
}
