import { useEffect, useState } from 'react'
import type {
  Control,
  FieldValues,
  UseFormResetField,
  UseFormSetValue,
  UseFormTrigger
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormInput } from '../../../../../../components/Form/FormInput'
import { RHF_SET_VALUE_SHOULD_DIRTY_ONLY } from '../../../../../../components/Form/utils'
import { SDKButton } from '../../../../../../components/SDK/Button'
import { SDKIcon } from '../../../../../../components/SDK/Icon'
import { SDKText } from '../../../../../../components/SDK/Text'
import { EAddQuestionFields, IFormValues } from '../../../../../../schemas/useAddQuestionSchema'
import { IAnswer } from '../../../../../project/types/projectResults'
import { EAnswerType } from '../../constants/answerType'
import './index.scss'

interface ISingleOrMultipleChoiceComponent {
  control: Control<FieldValues, IFormValues>
  setValue: UseFormSetValue<FieldValues>
  trigger: UseFormTrigger<FieldValues>
  type: EAnswerType
  resetField: UseFormResetField<IFormValues>
  defaultValues: IFormValues
  possibleAnswers: IAnswer[]
}

export const SingleOrMultipleChoiceComponent = ({
  control,
  setValue,
  trigger,
  type,
  resetField,
  defaultValues,
  possibleAnswers
}: ISingleOrMultipleChoiceComponent) => {
  const { t } = useTranslation('countriesSnapshot')
  const defaultEmptyValue = [{ possibleAnswerText: '' }, { possibleAnswerText: '' }]
  const [fields, setFields] = useState<IAnswer[]>(defaultEmptyValue)
  const [shouldReset, setShouldReset] = useState(true)

  useEffect(() => {
    const answers = possibleAnswers.length ? possibleAnswers : defaultValues.possibleAnswers

    setFields(answers || defaultEmptyValue)
  }, [type, possibleAnswers])

  useEffect(() => {
    if (shouldReset) {
      resetField(EAddQuestionFields.possibleAnswers)
      setShouldReset(false)
    }
    setValue(EAddQuestionFields.possibleAnswers, fields, RHF_SET_VALUE_SHOULD_DIRTY_ONLY)
  }, [fields])

  const onChange = (value: string, index: number) => {
    const newFields = [...fields]
    newFields[index] = { ...newFields[index], possibleAnswerText: value }
    setFields(newFields)
    trigger([`${EAddQuestionFields.possibleAnswers}[${index}]`])
  }

  const remove = (index: number) => {
    setShouldReset(true)
    setFields(prevState => prevState.filter((_, i) => i !== index))
    trigger([`${EAddQuestionFields.possibleAnswers}[${index}]`])
  }

  const addNewField = () => {
    setFields([...fields, { possibleAnswerText: '' }])
  }

  return (
    <div className='single-or-multiple-choice-container'>
      <SDKText type='body-s' className='ap-text-neutral-14'>
        {t('newQuestion.form.fields.answerOptions')}
      </SDKText>
      {fields.map((field, i) => (
        <div className='row ap-my-spacing-4' key={`single-or-multiple-choice-${i}`}>
          <FormInput
            name={`${EAddQuestionFields.possibleAnswers}[${i}]`}
            type='text'
            value={field.possibleAnswerText}
            errorMessage={t('newQuestion.form.errors.inputError')!}
            label={`${t('newQuestion.form.fields.option')} ${i + 1} ${t(
              'newQuestion.form.fields.label'
            )}`}
            className='single-or-multiple-choice-input'
            control={control}
            required={true}
            onChange={(v: string) => onChange(v, i)}
          />
          {field.id === undefined && (
            <SDKButton
              kind='text'
              className='single-or-multiple-choice-delete-btn'
              onClick={() => remove(i)}
            >
              <SDKIcon code='circle-delete' />
            </SDKButton>
          )}
        </div>
      ))}

      <SDKButton kind='text' className='add-option-btn' onClick={addNewField}>
        <SDKText weight={2}>
          <>
            <SDKIcon code='plus' /> {t('newQuestion.form.fields.addOption')}
          </>
        </SDKText>
      </SDKButton>
    </div>
  )
}
