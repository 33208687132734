import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../context/app.context'
import { LoadingStatus } from '../../../shared/types/enums'
import { useAppDispatch } from '../../../store'
import * as actions from '../store/actions'
import * as selectors from '../store/selectors'

export const useAddEditRegionModal = (regionId: number | null) => {
  const { t } = useTranslation('regions')
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { loading: addLoading, error: addError } = useSelector(selectors.getAddRegion)
  const { loading: editLoading, error: editError } = useSelector(selectors.getEditRegion)
  const error = regionId ? editError : addError
  const loading = regionId ? editLoading : addLoading
  const clearAction = regionId ? actions.clearEditRegion() : actions.clearAddRegion()

  useEffect(() => {
    return () => {
      dispatch(clearAction)
    }
  }, [])

  useEffect(() => {
    if (error?.length) {
      sendNotification({
        message: error.includes('already exist') ? t('newRegion.form.errors.regionExist') : error,
        status: 'error'
      })
    }
  }, [error])

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t('newRegion.form.successInfo'),
        status: 'success'
      })
    }
  }, [loading])

  return { error, loading }
}
