import type { IParentScopeYearTotal } from './scope'

interface IRow {
  id: number
  fees: IParentScopeYearTotal[]
  text: string
}

export interface ICardResult extends ICardResultBase {
  rows: IRow[]
  rowsWithTotal: [
    {
      texts: string[]
      fees: IParentScopeYearTotal[]
    }
  ]
}
export interface ICardResultBase {
  name: string
}

export enum EFeeType {
  WithoutDiscount = 0,
  Discount = 1,
  WithDiscount = 2,
  Optional = 3,
  Total = 4
}

export interface IPricingResultsTotal {
  fees: IParentScopeYearTotal[]
  sum: number
  type: EFeeType
  discountPercentage?: number
}

export interface IPricingResultsOptionals {
  id: number
  name: string
  rows: IRow[]
}

export interface IOptionals {
  optionalScopes: IPricingResultsOptionals[]
  sum: number
  total: IRow
}

export interface IPricingResults {
  cards: ICardResult[]
  optionals: {
    cards: ICardResult[]
  }
  totalBeforeDiscountRow: IPricingResultsTotal
  totalDiscountRow: IPricingResultsTotal
  totalOptionalsRow: IPricingResultsTotal
  totalAfterDiscountRow: IPricingResultsTotal
  totalRow: IPricingResultsTotal
}

export enum EGridType {
  SingleRow = 'singleRow',
  MultipleRow = 'multipleRow',
  OptionalScopeRow = 'optionalScopeRow'
}
