import queryString from 'query-string'
import { IProjectCard } from '../../../types'
import { ESortOption, ESortOptionValue } from '../../FilterProjectsModal/constants'

interface ISetFiltersAndSortingProps {
  list: IProjectCard[]
  filters: queryString.ParsedQuery<string>
}

const sortByDate = (a: IProjectCard, b: IProjectCard, order: ESortOptionValue) => {
  var dateA = new Date(a.updatedDate).getTime()
  var dateB = new Date(b.updatedDate).getTime()
  if (order === ESortOptionValue.desc) return dateA > dateB ? 1 : -1
  return dateA > dateB ? -1 : 1
}

const sortByName = (a: IProjectCard, b: IProjectCard, order: ESortOptionValue) => {
  if (a.name.toUpperCase() > b.name.toUpperCase()) {
    if (order === ESortOptionValue.asc) return 1
    return -1
  }
  if (b.name.toUpperCase() > a.name.toUpperCase()) {
    if (order === ESortOptionValue.asc) return -1
    return 1
  }
  return 0
}

export const setFiltersAndSorting = ({ list, filters }: ISetFiltersAndSortingProps) => {
  let newFilteredList = list

  if (filters?.projectIds) {
    newFilteredList = newFilteredList.filter(element => {
      if (Array.isArray(filters.projectIds))
        return filters.projectIds?.find(id => id === String(element.projectId))
      return filters.projectIds === String(element.projectId)
    })
  }
  if (filters?.yearIds) {
    newFilteredList = newFilteredList.filter(element => {
      if (Array.isArray(filters.yearIds))
        return filters.yearIds?.find(id => id === String(element.yearId))
      return filters.yearIds === String(element.yearId)
    })
  }
  if (filters?.[ESortOption.date] === ESortOptionValue.desc) {
    newFilteredList = [...newFilteredList].sort((a, b) => sortByDate(a, b, ESortOptionValue.desc))
  } else if (filters?.[ESortOption.name] === ESortOptionValue.desc) {
    newFilteredList = [...newFilteredList].sort((a, b) => sortByName(a, b, ESortOptionValue.desc))
  } else if (filters?.[ESortOption.name] === ESortOptionValue.asc) {
    newFilteredList = [...newFilteredList].sort((a, b) => sortByName(a, b, ESortOptionValue.asc))
  } else {
    newFilteredList = [...newFilteredList].sort((a, b) => sortByDate(a, b, ESortOptionValue.asc))
  }
  return newFilteredList
}
