import { TCategory } from '../../../modules/countriesSnapshot/types'
import api from '../../api'
import { CountrySnapshotUrl, FiscalYearUrl } from '../../constants/urls'

export interface IUpdateCategoryPayload {
  name: string
}

export const updateSingleCategory = async (
  params: IUpdateCategoryPayload,
  yearId: number,
  categoryId: number
): Promise<TCategory> => {
  return (
    await api.put(
      `${FiscalYearUrl.Years}/${yearId}/${CountrySnapshotUrl.Categories}/${categoryId}`,
      params
    )
  ).data
}
