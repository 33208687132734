import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SDKButton } from '../../../../../../../components/SDK/Button'
import { SDKIcon } from '../../../../../../../components/SDK/Icon'
import { SDKTabber, type ITabberItem } from '../../../../../../../components/SDK/Tabber'
import { useProject } from '../../../../../../../context/project.context'
import { useAppDispatch } from '../../../../../../../store'
import * as actions from '../../../store/actions'
import { AddEditCustomModuleModal } from './components/AddEditCustomModuleModal'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Url } from '../../../../../../../constants/urls'
import { usePricingTab } from '../../../../../../../context/pricingTab.context'
import { string2kebabCase } from '../../../../../../../formatters/string2kebabCase'
import { createCustomModule, getCustomModules } from '../../../store/selectors'
import style from './index.module.scss'

export const ControllableTabs = ({
  activeIndex,
  setActiveIndex,
  tabs
}: {
  activeIndex: number
  setActiveIndex: Dispatch<SetStateAction<number>>
  tabs: ITabberItem[]
}) => {
  const { t } = useTranslation('pricing')
  const [isModalVisible, setModalVisible] = useState(false)
  const dispatch = useAppDispatch()
  const { project } = useProject()
  const navigate = useNavigate()
  const { setCurrentTabIndex, setCustomModuleId } = usePricingTab()
  const { data: createData } = useSelector(createCustomModule)
  const { data: customModulesData } = useSelector(getCustomModules)

  const refreshCustomModules = () => {
    if (project) {
      dispatch(actions.getCustomModules(project.projectId))
    }
  }

  useEffect(() => {
    if (createData && customModulesData) {
      if (project?.projectId) {
        setCustomModuleId(createData.moduleId)
        setCurrentTabIndex?.(tabs.length)
        navigate(
          `${Url.MyProjectsPage}/${project.projectId}#${string2kebabCase(
            'pricing-' + createData.name || ''
          )}`
        )
      }
    }
  }, [customModulesData, createData])

  return (
    <div className={style.tabs}>
      <SDKTabber
        className={style.tabber}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        tabs={tabs}
        type='filled'
      />

      <SDKButton kind='secondary' className={style.button} onClick={() => setModalVisible(true)}>
        <SDKIcon code='plus'></SDKIcon>
        {t('tabs.addModule.addModuleButton')}
      </SDKButton>

      <AddEditCustomModuleModal
        customModule={null}
        onCloseModal={() => {
          setModalVisible(false)
          refreshCustomModules()
        }}
        visible={isModalVisible}
      />
    </div>
  )
}
