import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { object, string } from 'yup'
import { getRegionById } from '../modules/regions/store/selectors'

export enum EAddEditRegionFields {
  region = 'region'
}
export interface IFormValues {
  [EAddEditRegionFields.region]: string
}

export const useAddEditRegionSchema = (regionId: number | null) => {
  const { t } = useTranslation('regions')
  const region = useSelector(getRegionById(regionId))

  const defaultValues = {
    [EAddEditRegionFields.region]: regionId && region ? region.name : ''
  }

  const validationSchema = object()
    .shape({
      [EAddEditRegionFields.region]: string().required(t('newRegion.form.errors.region')!)
    })
    .required()

  return { validationSchema, defaultValues }
}
