import classNames from 'classnames'
import { useDetectIsMobile } from '../../utils/useDetectIsMobile'
import styles from './index.module.scss'

export const PageBackground = ({
  className,
  children
}: {
  className?: string
  children: JSX.Element
}) => {
  useDetectIsMobile()
  return <div className={classNames(styles.page, className)}>{children}</div>
}
