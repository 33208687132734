import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import type { IMenuItem } from '../../../../components/CardListItem/components/DropdownMenu'
import { DropdownMenu } from '../../../../components/CardListItem/components/DropdownMenu'
import { LoadingModal } from '../../../../components/LoadingModal'
import { SDKIcon } from '../../../../components/SDK/Icon'
import { SDKTextButton } from '../../../../components/SDK/TextButton'
import { useApp } from '../../../../context/app.context'
import { useProjectFromUrl } from '../../../../hooks/useProjectFromUrl'
import { LoadingStatus } from '../../../../shared/types/enums'
import { useAppDispatch } from '../../../../store'
import { ESightlineRedirectAction, SIGHTLINE_ACTION } from '../../constants'
import {
  clearCreateExportProject,
  clearCreateExportProjectToSightline,
  createExportProject,
  createExportProjectToSightline
} from '../../store/actions'
import * as selectors from '../../store/selectors'
import './index.scss'

interface IProjectHeaderProps {
  onSettingsButtonClick: () => void
  onChangedValuesButtonClick: () => void
  hasChangedValues: boolean
}

const ProjectHeader = ({
  onSettingsButtonClick,
  onChangedValuesButtonClick,
  hasChangedValues
}: IProjectHeaderProps) => {
  const { t } = useTranslation('myprojects')
  const dispatch = useAppDispatch()
  const { project } = useProjectFromUrl()
  const {
    sendNotification,
    appContext: {
      style: { isMobile }
    }
  } = useApp()
  const { error: createExportProjectError, loading: createExportProjectLoading } = useSelector(
    selectors.createExportProject
  )
  const {
    error: createExportProjectToSightlineError,
    loading: createExportProjectToSightlineLoading
  } = useSelector(selectors.createExportProjectToSightline)
  const [showLoadingModal, setShowLoadingModal] = useState(false)

  useEffect(() => {
    const action = localStorage.getItem(SIGHTLINE_ACTION)
    if (action === ESightlineRedirectAction.DataExportProjectToSightline && project) {
      dispatch(createExportProjectToSightline({ projectId: project.projectId }))
      localStorage.removeItem(SIGHTLINE_ACTION)
    }
  }, [])

  useEffect(() => {
    if (createExportProjectError?.length || createExportProjectToSightlineError?.length) {
      sendNotification({
        message: createExportProjectError?.length
          ? t('exportProject.error')
          : t('exportProjectToSightline.error'),
        status: 'error'
      })
      dispatch(clearCreateExportProject())
      dispatch(clearCreateExportProjectToSightline())
    }
  }, [createExportProjectError, createExportProjectToSightlineError])

  useEffect(() => {
    if (createExportProjectLoading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t('exportProject.success'),
        status: 'success'
      })
      dispatch(clearCreateExportProject())
    }

    if (createExportProjectLoading !== LoadingStatus.Pending) {
      setShowLoadingModal(false)
    }
  }, [createExportProjectLoading])

  useEffect(() => {
    if (createExportProjectToSightlineLoading !== LoadingStatus.Pending) {
      setShowLoadingModal(false)
    }

    if (createExportProjectToSightlineLoading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t('exportProjectToSightline.success'),
        status: 'success'
      })
      dispatch(clearCreateExportProjectToSightline())
    }
  }, [createExportProjectToSightlineLoading])

  const exportProject = () => {
    if (project && project.countrySnapshotCountryIds?.length) {
      setShowLoadingModal(true)
      dispatch(createExportProject({ projectId: project.projectId }))
    } else {
      sendNotification({
        message: t('exportProject.errorNoCountries'),
        status: 'error'
      })
    }
  }

  const exportProjectToSightline = () => {
    setShowLoadingModal(true)
    if (project) {
      dispatch(createExportProjectToSightline({ projectId: project.projectId }))
    }
  }

  const BUTTONS = [
    {
      iconName: 'alert',
      iconClassName: 'ap-text-color-text-warning',
      onClick: () => onChangedValuesButtonClick(),
      hidden: !hasChangedValues,
      label: t('changedValuesButton'),
      noOutline: true
    },
    {
      iconName: 'setting',
      onClick: () => onSettingsButtonClick(),
      label: t('projectSettingsButton')
    },
    {
      iconName: 'paper-airplane',
      hidden: project?.tpEngagementId === null,
      onClick: () => exportProjectToSightline(),
      label: t('exportProjectToSightline.button')
    },
    {
      iconName: 'download-light',
      onClick: () => exportProject(),
      label: t('exportProject.button'),
      disabled: !project?.hasDataToExport
    }
  ]
  if (!project) return <></>

  return (
    <div className='project-header ap-flex'>
      {isMobile ? (
        <DropdownMenu
          menuItems={BUTTONS.reverse().filter(button => !button.hidden) as IMenuItem[]}
          withDot={hasChangedValues}
          dotColor='color-background-warning'
        />
      ) : (
        BUTTONS.map(
          button =>
            !button.hidden && (
              <SDKTextButton
                key={button.label}
                onClick={button.onClick}
                className='expandable-button pointer ap-mx-spacing-2'
                disabled={button.disabled}
              >
                <>
                  <SDKIcon
                    code={button.iconName}
                    outline={!button.noOutline}
                    className={`ap-mr-spacing-2 ${button.iconClassName || ''}`}
                  ></SDKIcon>
                  {button.label}
                </>
              </SDKTextButton>
            )
        )
      )}
      {showLoadingModal && (
        <LoadingModal
          setVisible={setShowLoadingModal}
          text={
            t(
              `${
                createExportProjectLoading === LoadingStatus.Pending
                  ? 'exportProject'
                  : 'exportProjectToSightline'
              }.loading`
            )!
          }
        />
      )}
    </div>
  )
}

export default ProjectHeader
