import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useApp } from '../../../../../context/app.context'
import { useProject } from '../../../../../context/project.context'
import { useAppDispatch } from '../../../../../store'
import { useInterval } from '../../../../../utils/useInterval'
import * as actions from '../../../store/actions'
import { getTemplateCalculationById } from '../../../store/selectors'
import { ECalculationType, TCalculationType } from '../../../types/projectCalculation'

export const EXECUTION_INTERVAL = 5000
export const NO_CALCULATION_ERROR = 'Could not find calculation for project'

export const useCheckCalculationStatus = () => {
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { project: projectId } = useParams()
  const {
    data: calculatedData,
    loading: calculationLoading,
    error: calculationError
  } = useSelector(getTemplateCalculationById)
  const { isCalculationRunning, setIsCalculationRunning } = useProject()

  const [delay, setDelay] = useState<null | number>(null)

  useEffect(() => {
    if (isCalculationRunning) {
      setDelay(EXECUTION_INTERVAL)
    } else {
      dispatch(actions.getTemplateCalculationById(Number(projectId)))
    }
    return () => {
      setIsCalculationRunning(false)
      dispatch(actions.clearGetTemplateCalculationById())
    }
  }, [])

  useInterval(
    (status?: TCalculationType) => {
      try {
        switch (status) {
          case ECalculationType.Failed:
            return setDelay(null)
          case ECalculationType.InProgress:
            setDelay(EXECUTION_INTERVAL)
            break
          case ECalculationType.Success:
            return setDelay(null)
          case ECalculationType.Canceled:
            return setDelay(null)
          default:
            return setDelay(EXECUTION_INTERVAL)
        }
        dispatch(actions.getTemplateCalculationById(Number(projectId)))
      } catch (error) {
        setDelay(null)
        sendNotification({
          message: String(error),
          status: 'error'
        })
      }
    },
    delay,
    calculatedData?.status
  )

  useEffect(() => {
    if (calculationError?.length && !calculationError.includes(NO_CALCULATION_ERROR)) {
      sendNotification({
        message: calculationError,
        status: 'error'
      })
    }
  }, [calculationError])

  return calculationLoading
}
