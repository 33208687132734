import { SDKIcon } from '../../../../components/SDK/Icon'
import { SDKPanel } from '../../../../components/SDK/Panel'
import { SDKText } from '../../../../components/SDK/Text'

interface IInfoPanelProps {
  text: string
}

export const InfoPanel = ({ text }: IInfoPanelProps) => {
  return (
    <SDKPanel className='ap-mb-spacing-4 border-1 ap-border-color-background-border ap-bg-color-background-default'>
      <div className='ap-flex'>
        <SDKIcon code='information' className='ap-pr-spacing-3' />
        <SDKText className='ap-text-neutral-20'>{text}</SDKText>
      </div>
    </SDKPanel>
  )
}
