import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'

export enum ETransactionFields {
  transactions = 'transactions'
}
export interface IFormValues {
  [ETransactionFields.transactions]: string
}

export const useAddTransactionSchema = (transactions: string) => {
  const defaultValues = {
    [ETransactionFields.transactions]: transactions
  }
  const { t } = useTranslation('pricing')

  const validationSchema = object()
    .shape({
      [ETransactionFields.transactions]: string().required(
        t('tabs.projectSetup.transactionValidationError')!
      )
    })
    .required()

  return { validationSchema, defaultValues }
}
