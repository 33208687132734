import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { SDKIcon } from '../SDK/Icon'
import { SDKText } from '../SDK/Text'

import './index.scss'

interface IEmptyState {
  type?: 'page' | 'inline' | 'accordion-select'
  customText?: string
}

export const EmptyState = ({ type = 'page', customText }: IEmptyState) => {
  const { t } = useTranslation()
  const displayedText = <SDKText type='subheading'>{t('emptyState')}</SDKText>

  switch (type) {
    case 'page':
    case 'accordion-select':
      return (
        <div className={classNames('empty-state', type)}>
          <SDKIcon code='box-open' className='ap-pr-spacing-4' />
          {customText ? customText : displayedText}
        </div>
      )

    default:
      return displayedText
  }
}
