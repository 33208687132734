import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { EmptyState } from '../../../../components/EmptyState'
import { SDKAccordion } from '../../../../components/SDK/Accordion'
import { SDKAccordionItem } from '../../../../components/SDK/AccordionItem'
import { SDKLoading } from '../../../../components/SDK/Loading'
import { useApp } from '../../../../context/app.context'
import { LoadingStatus } from '../../../../shared/types/enums'
import { useAppDispatch } from '../../../../store'
import * as actions from '../../store/actions'
import {
  deleteCategory,
  deleteSubcategory,
  getAddCategory,
  getAddSubcategory,
  getCategories,
  getUpdateCategory,
  getUpdateSubcategory
} from '../../store/selectors'
import { AddEditSubcategoryModal } from '../AddEditSubcategoryModal'
import { EListType, ListItem } from '../ListItem'
import { SubcategoriesList } from '../SubcategoriesList'
import './index.scss'

interface ICategoriesListProps {
  year: number
  openEditCategoryModal: (x: number) => void
}

export const CategoriesList = ({ year, openEditCategoryModal }: ICategoriesListProps) => {
  const { t } = useTranslation('countriesSnapshot')
  const [visibleSubcategoryModal, setVisibleSubcategoryModal] = useState(false)
  const [categoryId, setCategoryId] = useState<number | null>(null)
  const [subcategoryId, setSubcategoryId] = useState<number | null>(null)
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { data, error, loading } = useSelector(getCategories)
  const { loading: addCatLoading } = useSelector(getAddCategory)
  const { loading: editCatLoading } = useSelector(getUpdateCategory)
  const { loading: addSubcatLoading } = useSelector(getAddSubcategory)
  const { loading: editSubcatLoading } = useSelector(getUpdateSubcategory)
  const { error: errorCategory, loading: loadingCategory } = useSelector(deleteCategory)
  const { error: errorSubcategory, loading: loadingSubcategory } = useSelector(deleteSubcategory)

  useEffect(() => {
    if (year) {
      dispatch(actions.getCategories(year))
    }
  }, [year])

  const onClose = () => {
    dispatch(actions.clearDeleteCategory())
    dispatch(actions.clearDeleteSubcategory())
  }

  useEffect(() => {
    return () => {
      dispatch(actions.clearAddSubcategory())
      dispatch(actions.clearUpdateSubcategory())
      dispatch(actions.clearAddCategory())
      dispatch(actions.clearUpdateCategory())
      onClose()
    }
  }, [])

  useEffect(() => {
    if (error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
    }
  }, [error])

  useEffect(() => {
    if (errorSubcategory?.length) {
      sendNotification({
        message: errorSubcategory,
        status: 'error'
      })
      onClose()
    }
    if (errorCategory?.length) {
      sendNotification({
        message: errorCategory,
        status: 'error'
      })
      onClose()
    }
  }, [errorCategory, errorSubcategory])

  useEffect(() => {
    if (loadingSubcategory === LoadingStatus.Succeeded) {
      sendNotification({
        message: t(`deleteSubcategory.successInfo`),
        status: 'success'
      })
      onClose()
      dispatch(actions.getCategories(year))
    }
    if (loadingCategory === LoadingStatus.Succeeded) {
      sendNotification({
        message: t(`deleteCategory.successInfo`),
        status: 'success'
      })
      onClose()
      dispatch(actions.getCategories(year))
    }
  }, [loadingCategory, loadingSubcategory])

  useEffect(() => {
    if (addCatLoading === LoadingStatus.Succeeded || editCatLoading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t('newCategory.form.successInfo'),
        status: 'success'
      })
      dispatch(actions.clearAddCategory())
      dispatch(actions.clearUpdateCategory())
    }
    if (
      addSubcatLoading === LoadingStatus.Succeeded ||
      editSubcatLoading === LoadingStatus.Succeeded
    ) {
      sendNotification({
        message: t('newSubcategory.form.successInfo'),
        status: 'success'
      })
      dispatch(actions.clearAddSubcategory())
      dispatch(actions.clearUpdateSubcategory())
    }
  }, [addCatLoading, editCatLoading, addSubcatLoading, editSubcatLoading])

  const openAddEditSubcategoryModal = (subcatId: number | null, catId?: number | null) => {
    setCategoryId(catId || 0)
    setSubcategoryId(subcatId)
    dispatch(actions.clearAddSubcategory())
    dispatch(actions.clearUpdateSubcategory())
    setVisibleSubcategoryModal(true)
  }

  return (
    <>
      {loading === LoadingStatus.Pending && <SDKLoading />}
      {!data?.length && <EmptyState />}
      {data && (
        <SDKAccordion className='categories-list' multiple={true}>
          {data?.map((category, i) => (
            <SDKAccordionItem
              toggleFromHeaderIconOnly={true}
              templateHeader={() => (
                <ListItem
                  data={category}
                  type={EListType.Category}
                  yearId={category.yearId}
                  openEditModal={openEditCategoryModal}
                  id={category.categoryId}
                />
              )}
              itemKey={String(i)}
              key={`categories-accordion-item-${i}`}
            >
              <SubcategoriesList
                subcategories={category.subcategories || []}
                categoryId={category.categoryId}
                yearId={category.yearId}
                openModal={openAddEditSubcategoryModal}
              />
            </SDKAccordionItem>
          ))}
        </SDKAccordion>
      )}
      {visibleSubcategoryModal && (
        <AddEditSubcategoryModal
          visible={visibleSubcategoryModal}
          setVisible={setVisibleSubcategoryModal}
          yearId={year}
          subcategoryId={subcategoryId}
          categoryId={categoryId}
          categoryName={data?.find(category => category.categoryId === categoryId)?.name || ''}
        />
      )}
    </>
  )
}
