import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormInput } from '../../../../../../../components/Form/FormInput'
import { FormSelect } from '../../../../../../../components/Form/FormSelect'
import { RHF_SET_VALUE_OPTIONS } from '../../../../../../../components/Form/utils'
import { SDKText } from '../../../../../../../components/SDK/Text'
import { EEScopeFields } from '../../../../../../../schemas/useEditParentScopeSchema'
import type { IOption } from '../../../../../../../types/form'
import { isNumber } from '../../../../../../../utils/isNumber'
import { renderDecimalNumber } from '../../../../../utils/useTransformNumberToMoney'
import type { IPriceYear } from '../../../types/scope'
import { EPriceYearsColumns, PARENT_SCOPE_COLUMNS } from '../const'

export const ScopePriceYearsFields = ({
  control,
  countries,
  getValues,
  localFeeCountryIdKey,
  onValidChange,
  priceYears,
  priceYearsKey,
  readonly,
  setValue
}: {
  control: any // Control<IFormValues>
  countries: IOption[]
  getValues: any // UseFormGetValues<IFormValues>
  localFeeCountryIdKey: EEScopeFields
  onValidChange: (_i: boolean) => void
  priceYears: IPriceYear[]
  priceYearsKey: EEScopeFields
  readonly: boolean
  setValue: any // UseFormSetValue<IFormValues>
}) => {
  const { t } = useTranslation('pricing')
  const [isLocalFeeCountryIdRequired, setLocalFeeCountryIdRequired] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const convertNumber = (data: string | number): number => (isNumber(data) ? Number(data) : 0)

  const validation = () => {
    let vals = getValues()
    let isValidVal =
      (isLocalFeeCountryIdRequired && vals[localFeeCountryIdKey] > 0) ||
      !isLocalFeeCountryIdRequired
    setIsValid(isValidVal)
  }

  const getTotal = (a: string | number, b: string | number) =>
    renderDecimalNumber(convertNumber(a) + convertNumber(b))

  const checkLocalFeeCountryIdRequired = () => {
    let sum = 0
    let vals = getValues()

    priceYears?.forEach((_scopePriceYear, yearIndex) => {
      let localFeeVal = vals[priceYearsKey][yearIndex][EPriceYearsColumns.LocalFeeValue]
      sum += Number(localFeeVal ?? 0)
    })

    let shouldBeRequired = sum > 0
    setLocalFeeCountryIdRequired(shouldBeRequired)
    validation()
  }

  useEffect(() => checkLocalFeeCountryIdRequired(), [])

  useEffect(() => {
    validation()
  }, [isLocalFeeCountryIdRequired])

  useEffect(() => {
    onValidChange(isValid)
  }, [isValid])

  return (
    <>
      <div className='row ap-mt-spacing-4'>
        {PARENT_SCOPE_COLUMNS.map(column => (
          <div className='col' key={`scope-price-year-label-${column}`}>
            <SDKText type='subheading'>
              {t(`tabs.inputs.parentScopeModal.form.scopePriceYears.columns.${column}`)!}
            </SDKText>
          </div>
        ))}
      </div>

      {priceYears?.map((scopePriceYear, yearIndex) => (
        <div className='row ap-mt-spacing-4' key={`scope-price-year-${scopePriceYear.yearName}`}>
          {PARENT_SCOPE_COLUMNS.map(column => (
            <div className='col' key={`scope-price-year-${scopePriceYear.yearName}-${column}`}>
              <FormInput
                name={`${priceYearsKey}.${yearIndex}.${column}`}
                type='text'
                label={
                  t('tabs.inputs.parentScopeModal.form.scopePriceYears.label', {
                    year: scopePriceYear.yearName
                  })!
                }
                disabled={column === EPriceYearsColumns.TotalFeeValue || readonly}
                control={control}
                errorMessage={t('tabs.inputs.parentScopeModal.form.scopePriceYears.error')!}
                onChange={value => {
                  const valid = value === '' || isNumber(value)
                  if (valid) {
                    const currentValues = [...getValues()[priceYearsKey]]
                    currentValues[yearIndex][column] = Number(value)
                    currentValues[yearIndex][EPriceYearsColumns.TotalFeeValue] = Number(
                      getTotal(
                        currentValues[yearIndex][EPriceYearsColumns.CentralFeeValue],
                        currentValues[yearIndex][EPriceYearsColumns.LocalFeeValue]
                      )
                    )
                    setValue(priceYearsKey, currentValues, RHF_SET_VALUE_OPTIONS)
                    checkLocalFeeCountryIdRequired()
                  }
                }}
              />

              {priceYears?.length === yearIndex + 1 &&
                column === EPriceYearsColumns.LocalFeeValue && (
                  <div className='ap-my-spacing-4'>
                    <FormSelect
                      data={countries}
                      name={localFeeCountryIdKey}
                      control={control}
                      placeholder={t('tabs.inputs.parentScopeModal.form.countryName.label')!}
                      required={isLocalFeeCountryIdRequired}
                      onSelect={value => {
                        setValue(localFeeCountryIdKey, value, RHF_SET_VALUE_OPTIONS)
                        checkLocalFeeCountryIdRequired()
                      }}
                      disabled={readonly}
                      portalMode
                    />
                  </div>
                )}
            </div>
          ))}
        </div>
      ))}
    </>
  )
}
