import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { LoadingOverlay } from '../../../../../components/SDK/LoadingOverlay'
import { useApp } from '../../../../../context/app.context'
import { usePricingTab } from '../../../../../context/pricingTab.context'
import { useProjectFromUrl } from '../../../../../hooks/useProjectFromUrl'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../store'
import { DragLayer } from '../components/DragLayer'
import { ScopeDefinitionForm } from '../components/ScopeDefinitionForm'
import { ScopesWrapper } from '../components/ScopesWrapper'
import { clearUpdateModuleMetadata } from '../modals/store/actions'
import { updateModuleMetadata } from '../modals/store/selectors'
import type { ITransformedData } from '../types/dnd'
import type { IScopeDefinition } from '../types/inputsTab'
import { transformData } from '../utils/transformData'

const ScopeDefinitionContent = ({ data }: { data: IScopeDefinition }) => {
  const [transformedData, setTransformedData] = useState<ITransformedData | null>(null)
  const { project } = useProjectFromUrl()
  const { clientName, country, priceYears } = usePricingTab()

  useEffect(() => {
    if (data) {
      const newData = transformData(
        data.parentScopes,
        data.scopeIncludes,
        data.scopeExcludes,
        data.scopeAssumes,
        { type: data.type, name: data.name, projectId: data.projectId }
      )
      setTransformedData(newData)
    }
  }, [data])

  const shouldDisplayResults = clientName.length > 0 && !!country?.countryId && !!priceYears.length

  const isSelectedCountryAvailable = !!project?.pricingCountryIds?.includes(
    data.scopeQuestions.countryId
  )

  return (
    <>
      <ScopeDefinitionForm />

      {shouldDisplayResults && isSelectedCountryAvailable ? (
        <ScopesWrapper totalFee={data.quotes?.scopeTotal}>
          <>
            {transformedData && (
              <DragLayer transformedData={transformedData} quotes={data.quotes} />
            )}
          </>
        </ScopesWrapper>
      ) : null}
    </>
  )
}

const ScopeDefinition = () => {
  const { t } = useTranslation('pricing')
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { loading, error } = useSelector(updateModuleMetadata)
  const {
    refreshData,
    tabContent: { selector }
  } = usePricingTab()
  const { data } = useSelector(selector)

  useEffect(() => {
    if (loading === LoadingStatus.Failed && error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
      refreshData()
    } else if (loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t(`tabs.inputs.moduleMetadata.successInfo`),
        status: 'success'
      })
    }
  }, [loading])

  useEffect(() => {
    return () => {
      dispatch(clearUpdateModuleMetadata())
    }
  }, [])

  return (
    <>
      {loading === LoadingStatus.Pending && <LoadingOverlay />}

      <ScopeDefinitionContent data={data as IScopeDefinition} />
    </>
  )
}

export default ScopeDefinition
