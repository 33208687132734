import { useTranslation } from 'react-i18next'
import { EFeeType } from '../../../../types/resultsTab'

export const useGetTotalTitleByType = (type: EFeeType) => {
  const { t } = useTranslation('pricing')

  const getTotalTitleByType = () => {
    switch (type) {
      case EFeeType.Discount:
        return t('tabs.results.totals.discount')
      case EFeeType.WithDiscount:
        return t('tabs.results.totals.annualAfter')
      case EFeeType.WithoutDiscount:
        return t('tabs.results.totals.annualBefore')
      case EFeeType.Total:
        return t('tabs.results.totals.total')
      case EFeeType.Optional:
        return t('tabs.results.totals.optionalAnnual')
    }
  }
  return { totalTitle: getTotalTitleByType() }
}
