import type { Control, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { EAddQuestionFields, IFormValues } from '../../../../../schemas/useAddQuestionSchema'
import { CalendarTypeElement } from './CalendarTypeElement'

interface ICalendarTypeComponent {
  control: Control<FieldValues, IFormValues>
  isEditMode: boolean
  defaultValues: IFormValues
}

export const CalendarTypeComponent = ({
  control,
  isEditMode,
  defaultValues
}: ICalendarTypeComponent) => {
  const { t } = useTranslation('countriesSnapshot')

  const option1 = [
    {
      text: t('newQuestion.form.fields.calendar.ultimateParentEntity'),
      name: EAddQuestionFields.ultimateParentEntityOption
    },
    {
      text: t('newQuestion.form.fields.calendar.localEntity'),
      name: EAddQuestionFields.localEntityOption
    }
  ]
  const option2 = [
    {
      text: t('newQuestion.form.fields.calendar.relativeToFilingTaxReturn'),
      name: EAddQuestionFields.relativeToFilingTaxReturnOption
    },
    {
      text: t('newQuestion.form.fields.calendar.relativeToFYE'),
      name: EAddQuestionFields.relativeToFyeOption
    },
    {
      text: t('newQuestion.form.fields.calendar.notTaxReturnOnFYE'),
      name: EAddQuestionFields.relativeToCalendarYearOption
    },
    {
      text: t('newQuestion.form.fields.calendar.relativeToInquiryOrAudit'),
      name: EAddQuestionFields.relativeToInquiryOrAuditOption
    },
    { text: t('newQuestion.form.fields.calendar.other'), name: EAddQuestionFields.otherOption }
  ]

  return (
    <>
      <CalendarTypeElement
        control={control}
        title={t('newQuestion.form.fields.calendar.title1')}
        options={option1}
        isEditMode={isEditMode}
        defaultValues={defaultValues}
      />
      <CalendarTypeElement
        control={control}
        title={t('newQuestion.form.fields.calendar.title2')}
        options={option2}
        isEditMode={isEditMode}
        defaultValues={defaultValues}
      />
    </>
  )
}
