export enum EAnswerTypeForEdit {
  Calendar = 'Calendar',
  Language = 'Language',
  Multiple = 'MultipleChoice',
  PlainText = 'PlainText',
  Single = 'SingleChoice',
  TaxRate = 'TaxRate',
  CurrencyRate = 'CurrencyRate'
}

export enum EAnswerType {
  Calendar = 'Calendar',
  CurrencyRate = 'Exchange Rate',
  Language = 'Language',
  Multiple = 'Multiple choice',
  PlainText = 'Plain Text',
  Single = 'Single choice',
  TaxRate = 'Corporate Tax Rate'
}

export enum EAnswerTypeFromBE {
  Calendar = 3,
  CurrencyRate = 7,
  Language = 4,
  Multiple = 1,
  PlainText = 2,
  Single = 0,
  TaxRate = 8
}
