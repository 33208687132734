import type { NavigationItem } from '@appkit4/react-components/navigation'
import { Navigation } from '@appkit4/react-components/navigation'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useApp } from '../../../../context/app.context'
import { NavigationMobileHeader } from './components/NavigationMobileHeader'
import { useNavigationsItems } from './hooks/useNavigationsItems'
import { useOutsideNavigation } from './utils/useOutsideNavigation'

interface IAppNavigationProps {
  setSidebarVisibility: () => void
  sidebarVisibility: boolean
}

export const AppNavigation = ({ setSidebarVisibility, sidebarVisibility }: IAppNavigationProps) => {
  const {
    appContext: {
      style: { isMobile }
    }
  } = useApp()
  const [fiscalYearSetupOpen, setFiscalYearSetupOpen] = useState(true)
  const [collapsed, setCollapsed] = useState(true)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const navigationRef = useRef(null)

  useOutsideNavigation(navigationRef, setSidebarVisibility, isMobile, sidebarVisibility)

  const navigationsItems = useNavigationsItems()

  useEffect(() => {
    if (isMobile) {
      setCollapsed(false)
    }
  }, [isMobile])

  const redirect = (
    _: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    item?: NavigationItem & { url?: string }
  ) => {
    if (item?.name === t('nav.fiscalYearSetup')) {
      setFiscalYearSetupOpen(!fiscalYearSetupOpen)
    }

    if (item?.url) {
      navigate(item.url)
      setSidebarVisibility()
    }
  }

  const onCollapse = (c: boolean) => {
    if (!isMobile) {
      setCollapsed(c)
    }
  }

  return (
    <div
      ref={navigationRef}
      onMouseOver={() => onCollapse(false)}
      onMouseOut={() => onCollapse(true)}
    >
      <Navigation
        className='ap-bg-color-background-alt'
        hasHeader={true}
        width={280}
        titleTemplate={() => <NavigationMobileHeader setSidebarVisibility={setSidebarVisibility} />}
        selectedIndex={0}
        hasFooter={false}
        navList={navigationsItems.filter(e => !e.isHidden)}
        hasLogo={false}
        collapsed={collapsed}
        onClickItem={redirect}
        onClickSubItem={redirect}
      />
    </div>
  )
}
