import api from '../../api'
import { CountrySnapshotUrl, DataExportUrl, ProjectsUrl } from '../../constants/urls'
import { generateFile } from '../../utils/generateFile'

export const exportProjectData = async (projectId: string): Promise<File> => {
  const response = await api.post(
    `${DataExportUrl.DataExport}/${ProjectsUrl.Projects}/${projectId}/${CountrySnapshotUrl.CountrySnapshot}`,
    {},
    { responseType: 'blob' }
  )

  response.data.lastModified = new Date()
  generateFile(response)

  return response.data
}
