import { useTranslation } from 'react-i18next'
import { ActionButton } from '../../ActionButton'
import { EAction } from '../const'

export interface ICardActionParameter {
  icon: string
  tooltip: string
}

export const CardActionButton = ({
  handleClick,
  type
}: {
  handleClick: () => void
  type: EAction
}) => {
  const { t } = useTranslation()

  const parameters = new Map<EAction, ICardActionParameter>([
    [
      EAction.Edit,
      {
        icon: 'edit',
        tooltip: 'buttons.edit'
      }
    ],
    [
      EAction.Delete,
      {
        icon: 'delete',
        tooltip: 'buttons.delete'
      }
    ]
  ])

  const parameter = parameters.get(type)

  if (parameter) {
    return (
      <ActionButton
        icon={parameter.icon}
        content={t(parameter.tooltip)!}
        handleClick={handleClick}
      />
    )
  } else {
    return <></>
  }
}
