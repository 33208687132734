import classNames from 'classnames'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingModal } from '../../../../../components/LoadingModal'
import { SDKPanel } from '../../../../../components/SDK/Panel'
import { SDKText } from '../../../../../components/SDK/Text'
import { useApp } from '../../../../../context/app.context'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { CalculationActionType, UploadModal } from '../UploadModal'
import { useSteps } from './utils/useSteps'
import { useUploadBySightline } from './utils/useUploadBySightline'
import { useUploadTemplate } from './utils/useUploadTemplate'

import { useProjectFromUrl } from '../../../../../hooks/useProjectFromUrl'
import './index.scss'

interface IStepsProps {
  setActiveIndex: Dispatch<SetStateAction<number>>
  setActiveType: Dispatch<SetStateAction<CalculationActionType | null>>
}

export const Steps = ({ setActiveIndex, setActiveType }: IStepsProps) => {
  const { t } = useTranslation('myprojects')
  const { project } = useProjectFromUrl()

  const {
    appContext: {
      style: { isMobile }
    }
  } = useApp()

  const {
    uploadTemplate,
    showUploadModal,
    setShowUploadModal,
    isValid,
    control,
    uploadedTemplateLoading
  } = useUploadTemplate(setActiveIndex, setActiveType)

  const {
    uploadBySightline,
    showUploadBySightlineModal,
    setShowUploadBySightlineModal,
    isValid: isFormValid,
    control: formControl,
    loading: uploadedBySightlineLoading
  } = useUploadBySightline(setActiveIndex, setActiveType)

  const { steps, isSendingToSightline, setShowLoadingModal, showLoadingModal } = useSteps(
    project?.taraCountryIds!,
    Number(project?.projectId),
    setShowUploadModal,
    setShowUploadBySightlineModal
  )
  const isUploadingTemplate = uploadedTemplateLoading === LoadingStatus.Pending
  const modalText = t(
    isSendingToSightline
      ? 'projectDetail.projectTabs.tara.input.sendingText'
      : isUploadingTemplate
      ? 'projectDetail.projectTabs.tara.input.uploadTemplateLoadingText'
      : `projectDetail.projectTabs.tara.input.downloadingText`
  )!

  return (
    <div className={classNames('tara-input-steps', !isMobile && 'row w-100 m-0')}>
      {steps.map((step, index) => (
        <div
          key={`input-step-${index}`}
          className={classNames(
            !isMobile ? 'col col-6' : 'ap-py-spacing-3',
            !isMobile && (index % 2 === 0 ? 'ap-pr-spacing-3 pl-0' : 'ap-pl-spacing-3 pr-0')
          )}
        >
          <SDKPanel>
            <SDKText type='subheading' className='ap-text-neutral-20' weight={2}>
              {t(`projectDetail.projectTabs.tara.input.step${index + 1}title`)}
            </SDKText>
            <div className='ap-mt-spacing-3'>
              <div className={classNames(!isMobile && 'ap-flex')}>
                <SDKText
                  className={classNames(
                    !isMobile && 'ap-pt-spacing-4 ap-pr-spacing-4',
                    'ap-text-neutral-20'
                  )}
                >
                  {`${t(`projectDetail.projectTabs.tara.input.step${index + 1}text`)}
                  ${
                    project?.tpEngagementId
                      ? t(`projectDetail.projectTabs.tara.input.step${index + 1}textSightline`)!
                      : ''
                  }`}
                </SDKText>
                <div className='buttons'>{step.buttons}</div>
              </div>
            </div>
          </SDKPanel>
        </div>
      ))}
      {(showLoadingModal || isUploadingTemplate) && (
        <LoadingModal setVisible={setShowLoadingModal} text={modalText} />
      )}
      {uploadedBySightlineLoading === LoadingStatus.Pending && (
        <LoadingModal
          setVisible={setShowLoadingModal}
          text={t('projectDetail.projectTabs.tara.input.uploadSightline')!}
        />
      )}
      {showUploadModal && (
        <form>
          <UploadModal
            control={control}
            type={CalculationActionType.Basic}
            setVisible={setShowUploadModal}
            onSubmit={uploadTemplate}
            title={t('projectDetail.projectTabs.tara.input.uploadModalTitle')}
            isBtnDisabled={!isValid}
          />
        </form>
      )}
      {showUploadBySightlineModal && (
        <form>
          <UploadModal
            control={formControl}
            type={CalculationActionType.Sightline}
            setVisible={setShowUploadBySightlineModal}
            onSubmit={uploadBySightline}
            title={t('projectDetail.projectTabs.tara.input.useDataModalTitle')}
            isBtnDisabled={!isFormValid}
          />
        </form>
      )}
    </div>
  )
}
