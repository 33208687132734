import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../../../context/app.context'
import { usePricingTab } from '../../../../../context/pricingTab.context'
import { useProject } from '../../../../../context/project.context'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { DEFAULT_ERROR } from '../../../../../shared/utils/store'
import { useAppDispatch } from '../../../../../store'
import {
  clearUpdateChildScopeOrder,
  clearUpdateParentScopeOrder,
  updateChildScopeOrder,
  updateParentScopeOrder
} from '../modals/store/actions'
import * as selectors from '../modals/store/selectors'
import { getScopeDefinition } from '../store/actions'

export const useDragOrderUpdate = () => {
  const { t } = useTranslation('pricing')
  const { sendNotification } = useApp()
  const dispatch = useAppDispatch()
  const { customModuleId, tabContent } = usePricingTab()
  const { project } = useProject()
  const tabName = tabContent.tab

  const { loading: childLoading, error: childError } = useSelector(selectors.updateChildScopeOrder)
  const { loading: parentLoading, error: parentError } = useSelector(
    selectors.updateParentScopeOrder
  )

  const [isParentScopeTable, setIsParentScopeTable] = useState<boolean | null>(null)

  const loading = isParentScopeTable ? parentLoading : childLoading
  const error = isParentScopeTable ? parentError : childError
  const clearAction = isParentScopeTable ? clearUpdateParentScopeOrder : clearUpdateChildScopeOrder

  useEffect(() => {
    if (loading === LoadingStatus.Failed && error?.length && project?.projectId) {
      sendNotification({
        message: error,
        status: 'error'
      })
      dispatch(getScopeDefinition({ projectId: project.projectId }))
    } else if (loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t(
          `tabs.inputs.${isParentScopeTable ? 'parentScopeToggle' : 'childScopeToggle'}.successInfo`
        ),
        status: 'success'
      })
    }
  }, [loading])

  useEffect(() => {
    return () => {
      dispatch(clearAction())
    }
  }, [])

  const onUpdateParentScopeOrder = (parentScopeId: number, order: number) => {
    setIsParentScopeTable(true)
    if (project?.projectId) {
      dispatch(
        updateParentScopeOrder({
          customModuleId,
          projectId: project.projectId,
          tabName,
          parentScopeId,
          order
        })
      )
    } else {
      sendNotification({
        message: DEFAULT_ERROR,
        status: 'error'
      })
    }
  }

  const onUpdateChildScopeOrder = (childScopeId: number, scopeType: number, order: number) => {
    setIsParentScopeTable(false)
    const params = {
      customModuleId,
      scopeType,
      childScopeId,
      order
    }
    if (project?.projectId) {
      dispatch(
        updateChildScopeOrder({ projectId: project.projectId, tabName, childScopeId, params })
      )
    } else {
      sendNotification({
        message: DEFAULT_ERROR,
        status: 'error'
      })
    }
  }

  return { onUpdateParentScopeOrder, onUpdateChildScopeOrder }
}
