import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { HeaderButtons } from '../../../../components/HeaderButtons'
import { Layout } from '../../../../components/Layout'
import { useSubmitButton } from '../../../../hooks/useSubmitButton'
import {
  IFormValues,
  useUploadPricingToolImport
} from '../../../../schemas/useUploadPricingToolImportSchema'
import { useAppDispatch } from '../../../../store'
import * as actions from '../../store/actions'
import { getPricingToolsImportInfo } from '../../store/selectors'
import { AddImportForm } from './components/AddImportForm'
import { useAddPricingImport } from './utils/useAddPricingImport'

export const AddPricingToolImport = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { data: pricingToolsImportInfo } = useSelector(getPricingToolsImportInfo)
  const { validationSchema, defaultValues } = useUploadPricingToolImport()

  useEffect(() => {
    dispatch(actions.getPricingToolsImportInfo())
  }, [])

  const { handleSubmit, control, formState, trigger, setValue } = useForm<FieldValues, IFormValues>(
    {
      resolver: yupResolver(validationSchema),
      defaultValues,
      mode: 'onChange',
      reValidateMode: 'onChange'
    }
  )

  const { onSubmit } = useAddPricingImport()
  const { disabled } = useSubmitButton({ formState })

  useEffect(() => {
    trigger([])
  }, [formState.isDirty])

  return (
    <Layout
      buttonComponent={
        <HeaderButtons
          icon='save'
          name={t('buttons.import')}
          onClick={handleSubmit(onSubmit)}
          disabled={disabled}
        />
      }
    >
      <form>
        <AddImportForm
          control={control}
          pricingToolsImportInfo={pricingToolsImportInfo}
          setValue={setValue}
        />
      </form>
    </Layout>
  )
}
