import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SDKTabber } from '../../../../components/SDK/Tabber'
import { useAppDispatch } from '../../../../store'
import { ETARATab, ETARATabHashUrl } from '../../constants/tab'
import {
  clearUploadBySightlineWithCalculation,
  clearUploadTemplateWithCalculation
} from '../../store/actions'
import { TaraInput } from '../TaraInput'
import { CalculationActionType } from '../TaraInput/UploadModal'
import { TaraResults } from '../TaraResults'

export const TaraTabs = () => {
  const { t } = useTranslation('myprojects')
  const dispatch = useAppDispatch()
  const [activeIndex, setActiveIndex] = useState(0)
  const [activeType, setActiveType] = useState<CalculationActionType | null>(null)

  const clearAction =
    activeType === CalculationActionType.Basic
      ? clearUploadTemplateWithCalculation
      : clearUploadBySightlineWithCalculation

  const tabs = [
    {
      label: t('projectDetail.input'),
      value: ETARATab.Input,
      hashUrl: ETARATabHashUrl.Input,
      component: <TaraInput setActiveIndex={setActiveIndex} setActiveType={setActiveType} />
    },
    {
      label: t('projectDetail.results'),
      value: ETARATab.Results,
      hashUrl: ETARATabHashUrl.Results,
      component: <TaraResults />
    }
  ]
  return (
    <div className='ap-mt-spacing-4'>
      <SDKTabber
        tabs={tabs}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        additionalChange={() => dispatch(clearAction())}
      />
      {tabs[activeIndex].component}
    </div>
  )
}
