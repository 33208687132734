import { createReducer } from '@reduxjs/toolkit'
import {
  getDefaultResourceState,
  Resource,
  setResourceFullfilled,
  setResourcePending,
  setResourceRejected
} from '../../../shared/utils/store'
import { IProjectResultByQuestion } from '../../project/types/projectResults'
import { clearGetCountrySnapshotMfeResults, getCountrySnapshotMfeResults } from './actions'

export interface CountrySnapshotState {
  countrySnapshotMfeResults: Resource<IProjectResultByQuestion[]>
}

const initialState: CountrySnapshotState = {
  countrySnapshotMfeResults: getDefaultResourceState([])
}

export default createReducer(initialState, builder =>
  builder

    .addCase(getCountrySnapshotMfeResults.pending, state => {
      setResourcePending(state.countrySnapshotMfeResults)
    })
    .addCase(getCountrySnapshotMfeResults.rejected, (state, actions) => {
      setResourceRejected(state.countrySnapshotMfeResults, actions)
    })
    .addCase(getCountrySnapshotMfeResults.fulfilled, (state, actions) => {
      state.countrySnapshotMfeResults.data = actions.payload
      setResourceFullfilled(state.countrySnapshotMfeResults)
    })
    .addCase(clearGetCountrySnapshotMfeResults, state => {
      state.countrySnapshotMfeResults = initialState.countrySnapshotMfeResults
    })
)
