import { useController } from 'react-hook-form'
import { SDKLoading } from '../../../../../../../../components/SDK/Loading'
import { EProjectFields } from '../../../../../../../myprojects/types'
import { ProjectCountriesStepForm } from './components/StepForms/ProjectCountriesStepForm'
import { ProjectInfoStepForm } from './components/StepForms/ProjectInfoStepForm'
import { ProjectUsersStepForm } from './components/StepForms/ProjectUsersStepForm'
import type { IAddEditProjectForm } from './types'

import './index.scss'

export const AddEditProjectForm = ({
  activeStep,
  allPublishedCountries,
  control,
  countriesLoading,
  hasChangedValues,
  isLoading,
  onClickShowSightline,
  projectId,
  originalProjectId,
  originalProjectName,
  resetField,
  setValue,
  trigger,
  values
}: {
  originalProjectId: number | null
  originalProjectName: string | null
} & IAddEditProjectForm) => {
  const {
    formState: { isDirty }
  } = useController({ control, name: EProjectFields.Name })

  const renderStep = () => {
    switch (activeStep.index) {
      case 1:
        return (
          <ProjectCountriesStepForm
            {...{
              allPublishedCountries,
              projectId,
              resetField,
              setValue,
              trigger,
              values,
              hasChangedValues
            }}
          />
        )

      case 2:
        return <ProjectUsersStepForm {...{ projectId, resetField, setValue, values, trigger }} />

      default:
        return (
          <ProjectInfoStepForm
            {...{
              allPublishedCountries,
              control,
              countriesLoading,
              isDirty,
              onClickShowSightline,
              projectId,
              originalProjectId,
              originalProjectName,
              setValue,
              trigger,
              values
            }}
          />
        )
    }
  }

  return (
    <div className='ap-container add-edit-project-form'>
      <div className='form-container mt-2'>{isLoading ? <SDKLoading /> : renderStep()}</div>
    </div>
  )
}
