import { IFormulaCountry, IFormulaCreate } from '../../../modules/TaraFields/types'
import api from '../../api'
import { TaraFieldsUrl } from '../../constants/urls'

export const createFormulaOverride = async ({
  countryId,
  params
}: {
  countryId: number
  params: IFormulaCreate
}): Promise<IFormulaCountry> => {
  return (await api.post(`${TaraFieldsUrl.Country}/${countryId}/${TaraFieldsUrl.Formulas}`, params))
    .data
}
