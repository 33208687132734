import type { UploadProps } from '@appkit4/react-components'
import { Upload } from '@appkit4/react-components'
import { forwardRef } from 'react'
import { SDKPanel } from '../SDK/Panel'
import './index.scss'

interface IUploadFieldProps extends UploadProps {
  options: UploadProps
  uploadFiles?: (fileList: any) => void
}

export const UploadField = forwardRef(
  ({ options, onChange, uploadFiles, ...props }: IUploadFieldProps) => {
    return (
      <SDKPanel className='upload-wrapper'>
        <Upload onChange={onChange} onUpload={uploadFiles} {...options} {...props} />
      </SDKPanel>
    )
  }
)
