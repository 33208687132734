import classNames from 'classnames'
import { useState } from 'react'
import { useApp } from '../../context/app.context'
import { useMyRole } from '../../context/hooks/useMyRole'
import { EAnswerTypeForEdit } from '../../modules/countriesSnapshot/pages/AddEditQuestion/constants/answerType'
import { TCalendarQuestionOptions } from '../../modules/countriesSnapshot/types'
import { CountryContactInformation } from '../../modules/project/components/ResultsByCountry/components/CountryContactInformation'
import type {
  ICalendarAnswerResponse,
  IProjectAnswerByCountry,
  ProjectAnswerByQuestion
} from '../../modules/project/types/projectResults'
import { ActionButton } from '../ActionButton'
import { SDKBreadcrumbsWithText } from '../SDK/BreadcrumbsWithText'
import { SDKText } from '../SDK/Text'
import { AdditionalInformationContainer } from './components/AdditionalInformationContainer'
import { AnswerText } from './components/AnswerText'

interface IQuestionWithAnswersCardProps {
  answer: IProjectAnswerByCountry | ProjectAnswerByQuestion
  title: string
  type: EAnswerTypeForEdit
  id: number
  calendarQuestionOptions?: TCalendarQuestionOptions
  calendarAnswerResponse?: ICalendarAnswerResponse
  viewWithBreadcrumbs?: boolean
  viewWithTooltip?: boolean
  border?: boolean
  breadcrumbs?: string[]
  isEnabled?: boolean
  yearId?: number
}

export const QuestionWithAnswersCard = ({
  answer,
  type,
  title,
  breadcrumbs,
  viewWithBreadcrumbs,
  viewWithTooltip,
  id,
  isEnabled,
  calendarQuestionOptions,
  calendarAnswerResponse,
  yearId,
  border = true
}: IQuestionWithAnswersCardProps) => {
  const [countryId, setCountryId] = useState<number | null>(null)
  const {
    appContext: { projectYearId }
  } = useApp()
  const { amIExternal } = useMyRole()
  const isMoreIconHidden = amIExternal

  return (
    <>
      <div
        className={classNames(
          border &&
            'border-1 ap-flex flex-column ap-border-color-background-border ap-mt-spacing-3 ap-p-spacing-3',
          'ap-border-radius-2'
        )}
      >
        {viewWithBreadcrumbs && (
          <SDKBreadcrumbsWithText
            breadcrumbs={breadcrumbs || []}
            text={{ children: title, weight: 2, className: 'ap-text-neutral-20' }}
          />
        )}
        {viewWithTooltip && (
          <div className='ap-text-neutral-20 d-flex'>
            <SDKText type='subheading'>{title}</SDKText>
            {!isMoreIconHidden && (
              <ActionButton
                icon='circle-more'
                kind='neutral'
                content=''
                handleClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                  setCountryId(id)
                }}
              />
            )}
          </div>
        )}
        <SDKText className='ap-pt-spacing-3 ap-text-neutral-20'>
          <AnswerText
            answerText={answer?.answerText || String(answer?.rate || '')}
            type={type}
            calendarQuestionOptions={calendarQuestionOptions}
            calendarAnswerResponse={calendarAnswerResponse}
            daysOffset={answer?.calendarAnswerResponse?.daysOffset}
            monthsOffset={answer?.calendarAnswerResponse?.monthsOffset}
            id={id}
            isNotAnswered={answer?.isNotAnswered}
            selectedAnswers={answer?.selectedAnswers}
            isEnabled={isEnabled}
          />
        </SDKText>
        <AdditionalInformationContainer
          openText={answer?.openText}
          url={answer?.url}
          classes='ap-mt-spacing-3'
        />
      </div>
      {countryId && (projectYearId || yearId) && (
        <CountryContactInformation
          countryId={countryId}
          isVisible={Boolean(countryId)}
          setIsVisible={setCountryId}
          yearId={(projectYearId || yearId)!}
        />
      )}
    </>
  )
}
