import { IGateQuestionsByCountry } from '../../../modules/TaraFields/types'
import api from '../../api'
import { FiscalYearUrl, TaraFieldsUrl } from '../../constants/urls'

export const getGateQuestionsByCountryRequest = async ({
  countryId,
  yearId
}: {
  countryId: number
  yearId: number
}): Promise<IGateQuestionsByCountry[]> => {
  return (
    await api.get(
      `${FiscalYearUrl.Years}/${yearId}/${FiscalYearUrl.GateQuestins}/${TaraFieldsUrl.Country}/${countryId}`
    )
  ).data
}
