import type { IAddAnswer, IQuestionnaireQuestion } from '../../../modules/questionnaires/type'
import api from '../../api'
import { CountrySnapshotUrl, CountryUrl, FiscalYearUrl } from '../../constants/urls'

export const addNewAnswer = async (
  yearId: number,
  countryId: number,
  questionId: number,
  questionType: number,
  params: IAddAnswer
): Promise<IQuestionnaireQuestion> => {
  const url = `${FiscalYearUrl.Years}/${yearId}/${CountryUrl.Countries}/${countryId}/${CountrySnapshotUrl.Questions}/${questionId}/${CountrySnapshotUrl.Type}/${questionType}/${CountrySnapshotUrl.Answers}`
  return (await api.post(url, params)).data
}
