import { IFormulaValidation, IFormulaValidationResponse } from '../../../modules/TaraFields/types'
import api from '../../api'
import { TaraFieldsUrl } from '../../constants/urls'

export const createFormulaValidation = async ({
  params
}: {
  params: IFormulaValidation
}): Promise<IFormulaValidationResponse> => {
  return (await api.post(`${TaraFieldsUrl.Formula}/${TaraFieldsUrl.Validation}`, params)).data
}
