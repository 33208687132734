import { SDKAccordionWitCheckbox } from '../../../../components/SDK/AccordionWitCheckbox'
import { SDKCheckbox } from '../../../../components/SDK/Checkbox'
import { SDKText } from '../../../../components/SDK/Text'
import { useProjectFromUrl } from '../../../../hooks/useProjectFromUrl'
import { IMappedProjectCategory, IProjectQuestion } from '../../types/projectQuestions'

interface IProjectCategoryItemPops {
  category: IMappedProjectCategory
  categoryIndex: number
  setSelectedQuestionIds: (q: number[]) => void
  questions: IProjectQuestion[]
  selectedIds: number[]
}
export const ProjectCategoryItem = ({
  category,
  setSelectedQuestionIds,
  questions,
  selectedIds
}: IProjectCategoryItemPops) => {
  const { project } = useProjectFromUrl()
  const onChangeQuestion = (checked: boolean, ids: number[]) => {
    if (checked) {
      const newIds = ids.filter(id => !selectedIds.find(selectedId => selectedId === id))
      setSelectedQuestionIds([...selectedIds, ...newIds])
    } else {
      const newSelectedIds = selectedIds.filter(selectedId => !ids.find(id => id === selectedId))
      setSelectedQuestionIds(newSelectedIds)
    }
  }
  const onChangeSubcategory = (checked: boolean, id: number) => {
    const subcategoryQuestionsIds = questions
      .filter(q => q.subcategory.questionSubcategoryId === id)
      .map(q => q.questionId)
    onChangeQuestion(checked, subcategoryQuestionsIds)
  }

  const onChangeCategory = (checked: boolean, id: number) => {
    const subcategoryQuestionsIds = questions
      .filter(q => q.category.questionCategoryId === id)
      .map(q => q.questionId)
    onChangeQuestion(checked, subcategoryQuestionsIds)
  }

  return (
    <SDKAccordionWitCheckbox
      itemKey={String(category.questionCategoryId)}
      title={category.name}
      checked={category.checked}
      disabled={project?.hasChangedValues}
      defaultActivekeys={[String(category.questionCategoryId)]}
      indeterminate={category.indeterminate}
      onChange={checked => onChangeCategory(checked, category.questionCategoryId)}
    >
      {category.subcategories.map(subcategory => (
        <div
          className='project-subcategories-list-container'
          key={String(subcategory.questionSubcategoryId)}
        >
          <SDKAccordionWitCheckbox
            itemKey={String(subcategory.questionSubcategoryId)}
            title={subcategory.name}
            checked={subcategory.checked}
            defaultActivekeys={
              subcategory.checked || subcategory.indeterminate
                ? [String(subcategory.questionSubcategoryId)]
                : []
            }
            indeterminate={subcategory.indeterminate}
            disabled={project?.hasChangedValues}
            onChange={checked => onChangeSubcategory(checked, subcategory.questionSubcategoryId)}
          >
            {subcategory.questions.map((question, questionIndex) => (
              <div
                key={`questions-list-element-${questionIndex}`}
                className='project-question ap-my-spacing-3 ap-p-spacing-3 ap-border-radius-2'
              >
                <SDKCheckbox
                  checked={question.isSelected}
                  onChange={checked => onChangeQuestion(checked, [question.questionId])}
                  disabled={project?.hasChangedValues}
                />
                <SDKText type='body-s' className='ap-pt-spacing-1 ap-text-neutral-20'>
                  {question.questionText}
                </SDKText>
              </div>
            ))}
          </SDKAccordionWitCheckbox>
        </div>
      ))}
    </SDKAccordionWitCheckbox>
  )
}
