import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { SDKIcon } from '../../../../components/SDK/Icon'
import { useApp } from '../../../../context/app.context'

interface IHeaderTitleTemplateProps {
  setSidebarVisibility: () => void
}

export const HeaderTitleTemplate = ({ setSidebarVisibility }: IHeaderTitleTemplateProps) => {
  const { t } = useTranslation()
  const {
    appContext: {
      app: { type },
      style: { isMobile }
    }
  } = useApp()
  const { isAuthenticated } = useAuth()

  return (
    <>
      {!(isMobile && !isAuthenticated) && (
        <>
          <span className='icon pointer' onClick={setSidebarVisibility}>
            <SDKIcon code='menu-view'></SDKIcon>
          </span>
          <span className='title'>{t('header.title')!}</span>
        </>
      )}
      <span className='app-type'>{t(`header.type.${type}`)!}</span>
    </>
  )
}
