export enum ECSTab {
  Questions = 0,
  Results = 1
}

export enum ETARATab {
  Input = 0,
  Results = 1
}

export enum EPricingToolMainTab {
  Input = 0,
  Results = 1
}

export enum ECSTabHashUrl {
  Questions = 'CS-questions',
  Results = 'CS-results'
}

export enum ETARATabHashUrl {
  Input = 'rdp-input',
  Results = 'rdp-results'
}

export enum EPricingToolMainTabHashUrl {
  Input = 'pricing-scope-definition',
  Results = 'pricing-results'
}

export enum EProjectToolsTABSTab {
  CS = 0,
  TARA = 1,
  Pricing = 2
}
