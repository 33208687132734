import type { InputProps } from '@appkit4/react-components/field'
import { Input } from '@appkit4/react-components/field'
import { Ref, forwardRef, useEffect, useRef } from 'react'
import type { Path } from 'react-hook-form'

interface SDKInputProps<T> extends InputProps {
  suffix: JSX.Element
  id: Path<T>
  title: string
}

export const SDKInput = forwardRef(<T,>(props: SDKInputProps<T>, ref: Ref<HTMLInputElement>) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef && inputRef.current) {
      const input = inputRef.current.getElementsByClassName('ap-field-input')[0]
      if (input) {
        input.setAttribute('autoComplete', 'off')
      }
    }
  }, [])

  return <Input ref={inputRef || ref} {...props} />
})
