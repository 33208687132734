import type { PaginationProps, PaginationRef } from '@appkit4/react-components/pagination'
import { Pagination } from '@appkit4/react-components/pagination'
import { Ref, useEffect, useRef } from 'react'

interface ISDKPagination extends PaginationProps {
  ref?: Ref<PaginationRef>
  resetPageNumber?: boolean
}

export const SDKPagination = ({ resetPageNumber, ...props }: ISDKPagination) => {
  const paginationRef = useRef<PaginationRef | null>(null)

  useEffect(() => {
    if (paginationRef.current && resetPageNumber) {
      paginationRef.current?.setPageNumber(1)
    }
  }, [paginationRef, resetPageNumber])

  return <Pagination ref={paginationRef} {...props} />
}
