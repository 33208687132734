import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CardField } from '../../../../../../../components/CardListItem/components/CardField'
import { FormInput } from '../../../../../../../components/Form/FormInput'
import { FormSelect } from '../../../../../../../components/Form/FormSelect'
import { RHF_SET_VALUE_OPTIONS } from '../../../../../../../components/Form/utils'
import { Label } from '../../../../../../../components/Label'
import { LoadingOverlay } from '../../../../../../../components/SDK/LoadingOverlay'
import { SDKParagraph } from '../../../../../../../components/SDK/Paragraph'
import { SDKSwitch } from '../../../../../../../components/SDK/Switch'
import { usePricingTab } from '../../../../../../../context/pricingTab.context'
import { useProjectFromUrl } from '../../../../../../../hooks/useProjectFromUrl'
import { EEScopeFields } from '../../../../../../../schemas/useEditParentScopeSchema'
import { LoadingStatus } from '../../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../../store'
import type { IOption } from '../../../../../../../types/form'
import { EProjectFields } from '../../../../../../myprojects/types'
import { EComplexity, EPriceType, EScope } from '../../../const/enums'
import type { IParentComplexScopeFull, IPriceYear } from '../../../types/scope'
import * as actions from '../../store/actions'
import * as selectors from '../../store/selectors'
import { EParentScopeModalType } from '../const'
import { ScopePriceYearsFields } from './ScopePriceYearsFields'

interface IScopePriceYearsFieldsValidity {
  priceYears: boolean
  priceYearsTranslations: boolean
}

const defaultValidity: IScopePriceYearsFieldsValidity = {
  priceYears: false,
  priceYearsTranslations: false
}

export const ParentScopeForm = ({
  complexitySelector,
  control,
  onPriceYearsFieldsValidity,
  priceYears,
  scope,
  scopeLocationSelector,
  setValue,
  getValues
}: {
  complexitySelector: IOption[]
  control: any // Control<IFormValues>
  getValues: any // UseFormGetValues<IFormValues>
  onPriceYearsFieldsValidity: (_i: boolean) => void
  priceYears: IPriceYear[]
  readonly?: boolean
  reset: any // UseFormReset<IFormValues>
  scope?: Partial<IParentComplexScopeFull> | null
  scopeLocationSelector: IOption[]
  setValue: any // UseFormSetValue<IFormValues>
  type: EParentScopeModalType
}) => {
  const { t } = useTranslation('pricing')
  const { countriesSelector } = usePricingTab()
  const dispatch = useAppDispatch()
  const { project } = useProjectFromUrl()
  const [isPriceYearsFieldsValid, setPriceYearsFieldsValidity] =
    useState<IScopePriceYearsFieldsValidity>(defaultValidity)
  const [isComplexityApplicable, setComplexityApplicable] = useState<boolean>(true)
  const [initialScopeLocationType] = useState<EScope | null | undefined>(scope?.scopeLocationType)
  const [initialComplexity] = useState<EComplexity | undefined>(scope?.complexity)
  const [initialTranslation] = useState<boolean | undefined>(scope?.translation)

  const { loading: loadingPrices, data: priceData } = useSelector(
    selectors.getLocalFileScopePricingResults
  )
  const { loading: loadingTranslation, data: translationData } = useSelector(
    selectors.getLocalFileScopeTranslationPricingResults
  )

  useEffect(() => {
    const values = getValues()
    onPriceYearsFieldsValidity(
      !!(
        isPriceYearsFieldsValid.priceYears &&
        (!values[EEScopeFields.translation] ||
          (values[EEScopeFields.translation] && isPriceYearsFieldsValid.priceYearsTranslations))
      )
    )
  }, [isPriceYearsFieldsValid, getValues()[EEScopeFields.translation]])

  useEffect(() => {
    if (scope?.type !== EParentScopeModalType.LocalFile) {
      return
    }

    const values = getValues()
    const projectId = project !== null ? project[EProjectFields.ProjectId] : 0
    const countryId = values[EEScopeFields.countryId]
    const currentScopeLocationType = values[EEScopeFields.scopeLocationType]
    const currentComplexity = values[EEScopeFields.complexity]

    setComplexityApplicable(
      currentScopeLocationType == EScope.CentralPreparation ||
        currentScopeLocationType == EScope.LocalPreparationBasedOnCentralModules
    )

    if (
      initialScopeLocationType != currentScopeLocationType ||
      initialComplexity != currentComplexity
    ) {
      dispatch(
        actions.getLocalFileScopePricing({
          projectId: projectId,
          countryId: countryId,
          scopeLocationType: currentScopeLocationType,
          complexity: currentComplexity,
          initialYear: priceYears[0].yearName
        })
      )
    }
  }, [getValues()[EEScopeFields.scopeLocationType], getValues()[EEScopeFields.complexity]])

  useEffect(() => {
    if (scope?.type !== EParentScopeModalType.LocalFile) {
      return
    }

    const values = getValues()
    const projectId = project !== null ? project[EProjectFields.ProjectId] : 0
    const countryId = values[EEScopeFields.countryId]
    const currentScopeLocationType = values[EEScopeFields.scopeLocationType]
    const currentComplexity = values[EEScopeFields.complexity]
    const currentTranslation = values[EEScopeFields.translation]

    if (!currentTranslation || (currentTranslation && initialTranslation)) {
      return
    }

    dispatch(
      actions.getLocalFileScopeTranslationPricing({
        projectId: projectId,
        countryId: countryId,
        scopeLocationType: currentScopeLocationType,
        complexity: currentComplexity,
        initialYear: priceYears[0].yearName
      })
    )
  }, [getValues()[EEScopeFields.translation]])

  useEffect(() => {
    if (scope?.type !== EParentScopeModalType.LocalFile) {
      return
    }

    if (loadingPrices === LoadingStatus.Succeeded) {
      const values = getValues()
      const currentScopeLocationType = values[EEScopeFields.scopeLocationType]
      const currentComplexity = values[EEScopeFields.complexity]

      if (
        initialScopeLocationType == currentScopeLocationType &&
        initialComplexity == currentComplexity
      ) {
        return
      }

      const prices = priceData?.filter((x: any) => x.scopePriceType === EPriceType.Fee)
      const localFeeCountryId = values[EEScopeFields.localFeeCountryId]
      setValue(EEScopeFields.priceYears, prices, RHF_SET_VALUE_OPTIONS)
      setValue(EEScopeFields.localFeeCountryId, localFeeCountryId, RHF_SET_VALUE_OPTIONS)

      dispatch(actions.clearGetLocalFileScopePricing())
    }
  }, [loadingPrices])

  useEffect(() => {
    if (scope?.type !== EParentScopeModalType.LocalFile) {
      return
    }

    if (loadingTranslation === LoadingStatus.Succeeded) {
      const values = getValues()

      const translationPrices = translationData?.filter(
        (x: any) => x.scopePriceType === EPriceType.Translation
      )
      const localFeeCountryIdTranslations = translationPrices
        ? translationPrices[0]?.localFeeCountryId
        : values[EEScopeFields.localFeeCountryId]
      setValue(EEScopeFields.priceYearsTranslations, translationPrices, RHF_SET_VALUE_OPTIONS)
      setValue(
        EEScopeFields.localFeeCountryIdTranslations,
        localFeeCountryIdTranslations.toString(),
        RHF_SET_VALUE_OPTIONS
      )

      dispatch(actions.clearGetLocalFileScopeTranslationPricing())
    }
  }, [loadingTranslation])

  const nameLabelKey = useMemo(() => {
    switch (scope?.type) {
      case EParentScopeModalType.TPForms:
        return 'tpFormRule'

      case EParentScopeModalType.LocalFile:
        return 'entityName'

      default:
        return 'name'
    }
  }, [scope])

  const countryName =
    countriesSelector.find(
      selectedCountry => selectedCountry.value === getValues()[EEScopeFields.countryId]
    )?.label || ''

  return (
    <div className='ap-container add-edit-custom-module-form'>
      <div className='form-container'>
        {scope?.type === EParentScopeModalType.LocalFile &&
          (loadingPrices === LoadingStatus.Pending ||
            loadingTranslation === LoadingStatus.Pending) && <LoadingOverlay type='modal' />}
        {scope?.type === EParentScopeModalType.TPForms && (
          <>
            <div className='ap-my-spacing-4'>
              {scope?.parentScopeId ? (
                <>
                  <Label>{t('tabs.inputs.parentScopeModal.form.country.label')}</Label>
                  <SDKParagraph weight={1}>
                    <>{countryName}</>
                  </SDKParagraph>{' '}
                </>
              ) : (
                <div className='row'>
                  <div className='col'>
                    <FormSelect
                      data={countriesSelector}
                      name={EEScopeFields.countryId}
                      control={control}
                      placeholder={t('tabs.inputs.parentScopeModal.form.countryName.label')!}
                      required
                      portalMode
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        {scope?.type === EParentScopeModalType.LocalFile ? (
          <CardField
            className='ap-mb-spacing-5'
            label={t(`tabs.inputs.parentScopeModal.form.${nameLabelKey}.label`)!}
            value={getValues()[EEScopeFields.name]}
          />
        ) : (
          <div className='row'>
            <div className='col'>
              <FormInput
                name={EEScopeFields.name}
                type='text'
                label={t(`tabs.inputs.parentScopeModal.form.${nameLabelKey}.label`)!}
                required={true}
                className='ap-mb-spacing-5'
                control={control}
                errorMessage={t('tabs.inputs.parentScopeModal.form.name.error')!}
                disabled={!!scope && !scope.isUserItem}
              />
            </div>
          </div>
        )}

        {scope?.type === EParentScopeModalType.LocalFile && (
          <>
            <div className='row'>
              <div className='col'>
                <CardField
                  label={t('tabs.inputs.parentScopeModal.form.countryName.label')}
                  value={
                    countriesSelector.find(c => c.value === getValues()[EEScopeFields.countryId])
                      ?.label || ''
                  }
                />
              </div>
            </div>

            <div className='row ap-mt-spacing-4'>
              <div className='col'>
                <FormSelect
                  data={scopeLocationSelector}
                  name={EEScopeFields.scopeLocationType}
                  control={control}
                  placeholder={t('tabs.inputs.parentScopeModal.form.scopeLocationType.label')!}
                  required
                  portalMode
                  onChange={value =>
                    setValue(EEScopeFields.scopeLocationType, value, RHF_SET_VALUE_OPTIONS)
                  }
                />
              </div>
            </div>

            <div className='row ap-mt-spacing-4'>
              <div className='col'>
                <FormSelect
                  data={complexitySelector}
                  name={EEScopeFields.complexity}
                  control={control}
                  placeholder={t('tabs.inputs.parentScopeModal.form.complexity.label')!}
                  required={isComplexityApplicable}
                  disabled={!isComplexityApplicable}
                  portalMode
                  onChange={value =>
                    setValue(EEScopeFields.complexity, value, RHF_SET_VALUE_OPTIONS)
                  }
                />
              </div>
            </div>
          </>
        )}
        <div className='ap-mt-spacing-4'>
          <ScopePriceYearsFields
            countries={countriesSelector}
            control={control}
            getValues={getValues}
            localFeeCountryIdKey={EEScopeFields.localFeeCountryId}
            onValidChange={valid =>
              setPriceYearsFieldsValidity(current => ({ ...current, priceYears: valid }))
            }
            priceYears={priceYears}
            priceYearsKey={EEScopeFields.priceYears}
            setValue={setValue}
            readonly={false}
          />
        </div>
        {scope?.type === EParentScopeModalType.LocalFile && (
          <>
            <div className='ap-mt-spacing-4'>
              <Label>{t('tabs.inputs.parentScopeModal.form.translation.label')}</Label>

              <SDKSwitch
                onChange={value => {
                  setValue(EEScopeFields.translation, value, RHF_SET_VALUE_OPTIONS)
                }}
                className='ap-mr-spacing-5'
                checked={!!getValues()[EEScopeFields.translation]}
              >
                {
                  t(
                    `tabs.inputs.parentScopeModal.form.translation.${
                      getValues()[EEScopeFields.translation] ? 'enabled' : 'disabled'
                    }`
                  )!
                }
              </SDKSwitch>
            </div>

            <ScopePriceYearsFields
              countries={countriesSelector}
              control={control}
              getValues={getValues}
              localFeeCountryIdKey={EEScopeFields.localFeeCountryIdTranslations}
              onValidChange={valid =>
                setPriceYearsFieldsValidity(current => ({
                  ...current,
                  priceYearsTranslations: valid
                }))
              }
              priceYears={priceYears}
              priceYearsKey={EEScopeFields.priceYearsTranslations}
              setValue={setValue}
              readonly={!getValues()[EEScopeFields.translation]}
            />
          </>
        )}
      </div>
    </div>
  )
}
