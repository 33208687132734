import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../../../context/app.context'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../store'
import { CALCULATION_ID, ESightlineRedirectAction, SIGHTLINE_ACTION } from '../../../constants'
import * as actions from '../../../store/actions'
import {
  getCalculationResultFromSightline,
  getTemplateCalculationById
} from '../../../store/selectors'
import { EFileType } from '../../../types/projectCalculation'

export const useSightlineCalculationResult = (setShowLoadingModal: (value: boolean) => void) => {
  const { t } = useTranslation('myprojects')
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { data: calculatedData } = useSelector(getTemplateCalculationById)
  const { loading, error } = useSelector(getCalculationResultFromSightline)

  const sendExcelToSightline = (calculationId?: number) => {
    const id = calculationId ? calculationId : calculatedData?.calculationId
    if (id) {
      setShowLoadingModal(true)
      dispatch(
        actions.getCalculationResultFromSightline({
          calculationId: id,
          fileType: EFileType.Excel
        })
      )
    } else {
      sendNotification({
        message: t('projectDetail.projectTabs.tara.results.exportErrorText'),
        status: 'error'
      })
    }
  }

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded) {
      setShowLoadingModal(false)
      sendNotification({
        message: t('projectDetail.projectTabs.tara.results.exportSuccessText'),
        status: 'success'
      })
    } else if (loading === LoadingStatus.Failed) {
      setShowLoadingModal(false)
      sendNotification({
        message: error || t('projectDetail.projectTabs.tara.results.exportErrorText'),
        status: 'error'
      })
    }
    dispatch(actions.clearGetCalculationResultFromSightline())
  }, [loading])

  useEffect(() => {
    const action = localStorage.getItem(SIGHTLINE_ACTION)
    const calculationId = localStorage.getItem(CALCULATION_ID)
    if (
      action === ESightlineRedirectAction.GetCalculationResultByFormatFromSightline &&
      calculationId
    ) {
      sendExcelToSightline(Number(calculationId))
      localStorage.removeItem(SIGHTLINE_ACTION)
      localStorage.removeItem(CALCULATION_ID)
    }
  }, [])

  return { sendExcelToSightline }
}
