import { EPricingTab } from '../../../modules/project/components/GCDPricing/const/enums'
import type { IChildScope } from '../../../modules/project/components/GCDPricing/types/scope'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const updateChildScopeRequest = async (
  projectId: number,
  tab: EPricingTab,
  params: Partial<IChildScope>
): Promise<IChildScope> => {
  let url = `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/`

  if (params.customModuleId) {
    url += `${ProjectsUrl.Modules}/${ProjectsUrl.CustomModules}/${params.customModuleId}/${ProjectsUrl.ChildScopes}/${params.childScopeId}`
  } else {
    url += `${ProjectsUrl.Modules}/${tab}/${ProjectsUrl.ChildScopes}/${params.childScopeId}`
  }

  return (await api.put(url, params)).data
}
