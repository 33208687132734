import { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CardListItem } from '../../../../../../../../../../components/CardListItem'
import { EAction, ECardListItem } from '../../../../../../../../../../components/CardListItem/const'
import type { IActionCallback } from '../../../../../../../../../../components/CardListItem/type'
import { RHF_SET_VALUE_OPTIONS } from '../../../../../../../../../../components/Form/utils'
import { InfoBox } from '../../../../../../../../../../components/InfoBox'
import { SDKItemDataType } from '../../../../../../../../../../components/SDK/DropdownButton'
import { SDKLoading } from '../../../../../../../../../../components/SDK/Loading'
import UserAvatar from '../../../../../../../../../../components/UserAvatar'
import { UserSearchBox } from '../../../../../../../../../../components/UserSearchBox'
import { useApp } from '../../../../../../../../../../context/app.context'
import { useMyRole } from '../../../../../../../../../../context/hooks/useMyRole'
import { LoadingStatus } from '../../../../../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../../../../../store'
import type { IOption } from '../../../../../../../../../../types/form'
import type { IUserBase } from '../../../../../../../../../../types/user'
import { getFullname } from '../../../../../../../../../../utils/user'
import { MINIMAL_SEARCH_VALUE } from '../../../../../../../../../users/const'
import * as projectsActions from '../../../../../../../../store/actions'
import * as projectsSelectors from '../../../../../../../../store/selectors'
import { EProjectFields } from '../../../../../../../../types'
import type { IAddEditProjectForm } from '../../types'

export const ProjectUsersStepForm = ({
  projectId,
  resetField,
  setValue,
  values,
  trigger
}: Pick<IAddEditProjectForm, 'projectId' | 'resetField' | 'setValue' | 'values' | 'trigger'>) => {
  const { t } = useTranslation('myprojects')
  const [searchValue, setSearchValue] = useState('')
  const [assignedUsers, setAssignedUsers] = useState<IUserBase[]>([])
  const dispatch = useAppDispatch()
  const {
    appContext: { user: currentUser }
  } = useApp()
  const { amIExternal } = useMyRole()
  const { data: currentlyAssignedUsers, loading: currentlyAssignedUsersLoading } = useSelector(
    projectsSelectors.getAssignedProjectUsers
  )
  const { data: availableUsers, loading: availableUsersLoading } = useSelector(
    projectsSelectors.getAvailableProjectUsers
  )

  useEffect(() => {
    if (currentlyAssignedUsers) {
      setAssignedUsers(currentlyAssignedUsers)
    }
  }, [currentlyAssignedUsers])

  useEffect(() => {
    if (projectId) {
      dispatch(projectsActions.getProjectAssignedUsers(projectId))
    }
  }, [projectId])

  useEffect(() => {
    if (!amIExternal && searchValue.length >= MINIMAL_SEARCH_VALUE) {
      dispatch(projectsActions.getProjectAvailableUsers({ projectId, searchText: searchValue }))
    } else {
      dispatch(projectsActions.clearGetProjectAvailableUsers())
    }
  }, [searchValue])

  const userList: IOption[] = useMemo(
    () =>
      availableUsers?.map(user => ({
        value: String(user.userId),
        label: getFullname(user)
      })) || [],
    [availableUsers]
  )

  const offeredUsers = useMemo(
    () =>
      userList
        .filter(({ value }: IOption) => !values.userIds.includes(String(value)))
        .filter(({ value }: IOption) => value !== String(currentUser?.userId)),
    [userList, values.userIds]
  )

  const displayedUsers = useMemo(
    () => assignedUsers.filter(({ userId }: IUserBase) => userId !== currentUser?.userId),
    [assignedUsers, values.userIds]
  )

  const onDeleteUser = (userId: number) => {
    setAssignedUsers(assignedUsers.filter(user => userId !== user.userId))
    setValue(
      EProjectFields.UserIds,
      values[EProjectFields.UserIds].filter((id: string) => id !== String(userId)),
      RHF_SET_VALUE_OPTIONS
    )
    trigger()
  }

  const renderUserAvatar = (options: SDKItemDataType) => {
    const user = availableUsers.find(u => u.userId === Number(options.value))
    return user ? <UserAvatar user={user} /> : <></>
  }

  return (
    <>
      <div className='row'>
        <div className='col'>
          <UserSearchBox
            data={offeredUsers}
            loading={availableUsersLoading}
            placeholder={t('projectForm.projectUsers.userIds.label')!}
            onSearchChange={value => setSearchValue(value)}
            prefixTemplate={options => renderUserAvatar(options)}
            onSelect={value => {
              const selectedUser = availableUsers.find(user => Number(value) === user.userId)
              if (selectedUser) {
                setAssignedUsers(cur => [...cur, selectedUser])
              }

              setValue(
                EProjectFields.UserIds,
                [...values[EProjectFields.UserIds], value],
                RHF_SET_VALUE_OPTIONS
              )
              resetField('users')
              trigger()
            }}
          />
        </div>
      </div>

      {currentlyAssignedUsersLoading === LoadingStatus.Pending ? (
        <SDKLoading />
      ) : (
        displayedUsers.map(user => (
          <div className='country-editor-leader-list g-col-12' key={user.userId}>
            <CardListItem
              actions={[EAction.Delete]}
              cardListItem={ECardListItem.UserCard}
              handleClick={(callback: IActionCallback) => onDeleteUser(Number(callback.id))}
              {...user}
            />
          </div>
        ))
      )}

      <div className='row'>
        <div className='col'>
          <InfoBox
            content={t('projectForm.projectUsers.userIds.label')!}
            trans={
              <>
                <Trans i18nKey='projectForm.projectInfo.engagementCenter.infoText'>
                  Users added to this project will have the same rights as the project creator.
                  Users must have a profile in{' '}
                  <Link
                    to={`${process.env.REACT_APP_AVATAR_URL}Person/Lookup?q=all&fq=business_unit_ss:TP`}
                    target='_blank'
                  >
                    TPI
                  </Link>{' '}
                  and be part of the Transfer Pricing practice in order to be shown in this list.
                  <br />
                  <br />
                  Users can be added and removed at anytime.
                </Trans>
              </>
            }
          />
        </div>
      </div>
    </>
  )
}
