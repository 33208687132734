import { EmptyState } from '../../../../components/EmptyState'
import { SDKAccordion } from '../../../../components/SDK/Accordion'
import { SDKAccordionItem } from '../../../../components/SDK/AccordionItem'
import { SDKBreadcrumbsWithText } from '../../../../components/SDK/BreadcrumbsWithText'
import type { IProjectResultByQuestion } from '../../types/projectResults'
import { ResultTabAccordionContent } from '../ResultTabAccordionContent'
import './index.scss'

interface IResultsByQuestionProps {
  activeOption: number
  questions: IProjectResultByQuestion[]
  yearId?: number
}
export const ResultsByQuestion = ({ activeOption, questions, yearId }: IResultsByQuestionProps) => {
  return (
    <>
      {questions &&
        questions.map((question, index) => (
          <SDKAccordion
            className='project-results-accordion w-100 ap-my-spacing-3 results-by-question'
            multiple={true}
            key={`results-tab-list-by-question-element-${question.questionId}`}
          >
            <SDKAccordionItem
              templateHeader={() => (
                <SDKBreadcrumbsWithText
                  breadcrumbs={[question.category?.name, question.subcategory?.name]}
                  text={{
                    children: question.questionText,
                    weight: 2,
                    className: 'ap-text-neutral-20'
                  }}
                />
              )}
              itemKey={String(index)}
            >
              {question.answers.length ? (
                <ResultTabAccordionContent
                  answers={question.answers}
                  activeOption={activeOption}
                  id={question.questionId}
                  type={question.type}
                  yearId={yearId}
                />
              ) : (
                <EmptyState />
              )}
            </SDKAccordionItem>
          </SDKAccordion>
        ))}
      {!questions.length && <EmptyState />}
    </>
  )
}
