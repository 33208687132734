import { SDKText } from '../../../SDK/Text'
import { DnDIcon } from './DnDIcon'
import { UserIcon } from './UserIcon'

interface ICardTextWithIconCard {
  isMobile: boolean
  text: string
  isUserItem: boolean
  withoutDnD?: boolean
}

export const CardTextWithIcon = ({
  isUserItem,
  text,
  isMobile,
  withoutDnD = false
}: ICardTextWithIconCard) => {
  const textElement = () => (
    <div className='ap-px-spacing-3'>
      <SDKText>{text}</SDKText>
    </div>
  )

  return (
    <div className='ap-flex justify-content-between'>
      <div className='ap-flex'>
        {!isMobile && !withoutDnD && <DnDIcon />}
        {textElement()}
      </div>
      {!isMobile && (
        <div className='ap-flex align-items-center'>
          <UserIcon isUserItem={isUserItem} />
        </div>
      )}
    </div>
  )
}
