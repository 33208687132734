import { IMappedProjectCategory, IProjectQuestion } from '../types/projectQuestions'

export const groupQuestionsToCategoriesList = (questionsList: IProjectQuestion[]) =>
  questionsList.reduce((acc: IMappedProjectCategory[], item) => {
    const category = acc.findIndex(a => a.questionCategoryId === item.category.questionCategoryId)
    if (!~category) {
      acc.push({
        ...item.category,
        subcategories: [{ ...item.subcategory, questions: [item] }]
      })
    } else {
      const subcategory = acc[category].subcategories.findIndex(
        a => a.questionSubcategoryId === item.subcategory.questionSubcategoryId
      )
      if (!~subcategory) {
        acc[category].subcategories = [
          ...acc[category].subcategories,
          { ...item.subcategory, questions: [item] }
        ]
      } else {
        acc[category].subcategories[subcategory].questions = [
          ...acc[category].subcategories[subcategory].questions,
          item
        ]
      }
    }
    return acc
  }, [])

export const normalizeCategoriesList = (questionsList: IProjectQuestion[]) => {
  const grouppedCategories = groupQuestionsToCategoriesList(questionsList)
  return grouppedCategories.map(category => {
    const subcategories = category.subcategories.map(subcategory => {
      const checked = !subcategory.questions.find(q => !q.isSelected)
      return {
        ...subcategory,
        checked,
        indeterminate: checked ? false : subcategory.questions.find(q => !!q.isSelected)
      }
    })
    const checked = !subcategories.find(s => !s.checked)
    const indeterminate = checked ? false : !!subcategories.find(s => s.checked || s.indeterminate)
    return { ...category, subcategories, checked, indeterminate }
  }) as IMappedProjectCategory[]
}
