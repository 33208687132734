import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Module } from '../../../constants/modules'
import { IAddRegionPayload, addNewRegion } from '../../../services/requests/regions/addNewRegion'
import { deleteRegionData } from '../../../services/requests/regions/deleteRegionData'
import type { IEditRegionPayload } from '../../../services/requests/regions/editRegionData'
import { editRegionData } from '../../../services/requests/regions/editRegionData'
import { getRegionsData } from '../../../services/requests/regions/getRegionsData'

const moduleName: string = String(Module.Regions)

export const getRegions = createAsyncThunk(`${moduleName}/getRegions`, (id: number) =>
  getRegionsData(id)
)
export const clearRegions = createAction(`${moduleName}/clearRegions`)
export const addRegion = createAsyncThunk(`${moduleName}/addRegion`, (params: IAddRegionPayload) =>
  addNewRegion(params)
)
export const clearAddRegion = createAction(`${moduleName}/clearAddRegion`)
export const editRegion = createAsyncThunk(
  `${moduleName}/editRegion`,
  ({
    yearId,
    regionId,
    params
  }: {
    yearId: number
    regionId: number
    params: IEditRegionPayload
  }) => editRegionData(yearId, regionId, params)
)

export const clearEditRegion = createAction(`${moduleName}/clearEditRegion`)

export const deleteRegion = createAsyncThunk(
  `${moduleName}/deleteRegion`,
  ({ yearId, regionId }: { yearId: number; regionId: number }) => deleteRegionData(yearId, regionId)
)

export const clearDeleteRegion = createAction(`${moduleName}/clearDeleteRegion`)
