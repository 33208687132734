import { IProjectQuestion } from '../../../modules/project/types/projectQuestions'
import api from '../../api'
import { CountrySnapshotUrl, FiscalYearUrl, ProjectsUrl } from '../../constants/urls'

export interface IUpdateProjectQuestionsPayload {
  selectedQuestionIds: number[]
}

export const updateProjectSelectedQuestions = async (
  params: IUpdateProjectQuestionsPayload,
  yearId: number,
  projectId: number
): Promise<IProjectQuestion> => {
  const url = `${FiscalYearUrl.Years}/${yearId}/${ProjectsUrl.Projects}/${projectId}/${CountrySnapshotUrl.CountrySnapshot}/${CountrySnapshotUrl.Questions}`
  return (await api.put(url, params)).data
}
