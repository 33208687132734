import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'

export enum EEEmailTestFields {
  emailAddres = 'emailAddres'
}
export interface IFormValues {
  [EEEmailTestFields.emailAddres]: string
}

export const useEmailTestSchema = () => {
  const { t } = useTranslation('systemManagement')

  const defaultValues = {
    [EEEmailTestFields.emailAddres]: ''
  }

  const validationSchema = object()
    .shape({
      [EEEmailTestFields.emailAddres]: string().required(t('emailTemplates.form.errors.invalid')!)
    })
    .required()

  return { validationSchema, defaultValues }
}
