import { useTranslation } from 'react-i18next'
import { boolean, mixed, object } from 'yup'
import { MAX_XLSX_FILE_SIZE } from '../modules/project/constants'

export enum EUploadTemplateWithCalculationFields {
  dateOnly = 'dateOnly',
  inputFile = 'inputFile'
}
export interface IFormValues {
  [EUploadTemplateWithCalculationFields.dateOnly]: boolean
  [EUploadTemplateWithCalculationFields.inputFile]: File | null
}

export const useUploadTemplateWithCalculation = () => {
  const { t } = useTranslation('myprojects')

  const defaultValues: IFormValues = {
    [EUploadTemplateWithCalculationFields.dateOnly]: false,
    [EUploadTemplateWithCalculationFields.inputFile]: null
  }
  const validationSchema = object()
    .shape({
      [EUploadTemplateWithCalculationFields.dateOnly]: boolean().required(
        t('projectDetail.projectTabs.tara.input.requiredField')!
      ),
      [EUploadTemplateWithCalculationFields.inputFile]: mixed()
        .test('fileSize', t('projectDetail.projectTabs.tara.input.uploadFileTooLarge')!, value => {
          if (!value) return true
          return (value as File).size <= MAX_XLSX_FILE_SIZE
        })
        .required(t('projectDetail.projectTabs.tara.input.requiredField')!)
    })
    .required()

  return { validationSchema, defaultValues }
}
