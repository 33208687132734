import { createReducer } from '@reduxjs/toolkit'
import {
  Resource,
  getDefaultResourceState,
  setResourceFullfilled,
  setResourcePending,
  setResourceRejected
} from '../../../shared/utils/store'
import type { IUser, IUserBase } from '../../../types/user'
import {
  clearCreateServiceAccount,
  clearGetUser,
  clearGetUsers,
  clearSyncUsers,
  clearUpdateUser,
  clearUpdateUserRole,
  createServiceAccount,
  getUser,
  getUsers,
  syncUsers,
  updateUser,
  updateUserRole
} from './actions'

export interface UserState {
  createServiceAccount: Resource<Pick<IUser, 'email'> | null>
  updateUser: Resource<IUser | null>
  updateUserRole: Resource<IUser | null>
  user: Resource<IUser>
  users: Resource<IUserBase[]>
  userSync: Resource<boolean>
}

const initialState: UserState = {
  createServiceAccount: getDefaultResourceState(null),
  updateUser: getDefaultResourceState(null),
  updateUserRole: getDefaultResourceState(null),
  user: getDefaultResourceState({} as IUser),
  users: getDefaultResourceState([]),
  userSync: getDefaultResourceState(false)
}

export default createReducer(initialState, builder =>
  builder

    .addCase(getUser.pending, state => {
      setResourcePending(state.user)
    })
    .addCase(getUser.rejected, (state, actions) => {
      setResourceRejected(state.user, actions)
    })
    .addCase(getUser.fulfilled, (state, actions) => {
      state.user.data = actions.payload
      setResourceFullfilled(state.user)
    })
    .addCase(clearGetUser, state => {
      state.user = initialState.user
    })

    .addCase(getUsers.pending, state => {
      setResourcePending(state.users)
    })
    .addCase(getUsers.rejected, (state, actions) => {
      setResourceRejected(state.users, actions)
    })
    .addCase(getUsers.fulfilled, (state, actions) => {
      state.users.data = actions.payload
      setResourceFullfilled(state.users)
    })
    .addCase(clearGetUsers, state => {
      state.users = initialState.users
    })

    .addCase(updateUser.pending, state => {
      setResourcePending(state.updateUser)
    })
    .addCase(updateUser.rejected, (state, actions) => {
      setResourceRejected(state.updateUser, actions)
    })
    .addCase(updateUser.fulfilled, (state, actions) => {
      state.updateUser.data = actions.payload
      setResourceFullfilled(state.updateUser)
    })
    .addCase(clearUpdateUser, state => {
      state.updateUser = initialState.updateUser
    })

    .addCase(updateUserRole.pending, state => {
      setResourcePending(state.updateUserRole)
    })
    .addCase(updateUserRole.rejected, (state, actions) => {
      setResourceRejected(state.updateUserRole, actions)
    })
    .addCase(updateUserRole.fulfilled, (state, actions) => {
      state.updateUserRole.data = actions.payload
      setResourceFullfilled(state.updateUserRole)
    })
    .addCase(clearUpdateUserRole, state => {
      state.updateUserRole = initialState.updateUserRole
    })

    .addCase(createServiceAccount.pending, state => {
      setResourcePending(state.createServiceAccount)
    })
    .addCase(createServiceAccount.rejected, (state, actions) => {
      setResourceRejected(state.createServiceAccount, actions)
    })
    .addCase(createServiceAccount.fulfilled, (state, actions) => {
      state.createServiceAccount.data = actions.payload
      setResourceFullfilled(state.createServiceAccount)
    })
    .addCase(clearCreateServiceAccount, state => {
      state.createServiceAccount = initialState.createServiceAccount
    })

    .addCase(syncUsers.pending, state => {
      setResourcePending(state.userSync)
    })
    .addCase(syncUsers.rejected, (state, actions) => {
      setResourceRejected(state.userSync, actions)
    })
    .addCase(syncUsers.fulfilled, state => {
      state.userSync = getDefaultResourceState(true)
      setResourceFullfilled(state.userSync)
    })
    .addCase(clearSyncUsers, state => {
      state.userSync = initialState.userSync
    })
)
