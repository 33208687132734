import { Dispatch, SetStateAction } from 'react'
import type { FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Filename } from '../../../../../../components/Filename'
import { RHF_SET_VALUE_OPTIONS } from '../../../../../../components/Form/utils'
import { Image } from '../../../../../../components/Image'
import { SDKTextButton } from '../../../../../../components/SDK/TextButton'
import { EPowerBITemplateFields, IPowerBITemplate, IPowerBITemplateForm } from '../../../../types'
import { TPBITemplateFileTypes } from '../../types'

export const FilenameField = ({
  children,
  defaultValues,
  fileType,
  getValues,
  id,
  setToBeReplaced,
  setValue,
  template,
  toBeReplaced
}: {
  children: JSX.Element
  defaultValues: IPowerBITemplateForm
  fileType: TPBITemplateFileTypes
  getValues: UseFormGetValues<FieldValues>
  id: string | undefined
  setToBeReplaced: Dispatch<SetStateAction<TPBITemplateFileTypes | null | undefined>>
  setValue: UseFormSetValue<FieldValues>
  template: IPowerBITemplate | undefined
  toBeReplaced: TPBITemplateFileTypes | null | undefined
}) => {
  const { t } = useTranslation('documents')
  const valueKey = `${fileType}Name`
  const value = getValues()[template ? valueKey : fileType]
  const uploadedValue = getValues()[fileType]
  const isEditing = !!id

  const removeFile = (type: TPBITemplateFileTypes) => setValue(type, null, RHF_SET_VALUE_OPTIONS)

  const renderFilename = (
    <Filename value={value && typeof value === 'object' ? value?.name : value} />
  )

  if (!isEditing) {
    return <>{children}</>
  } else {
    const shouldDisplayThumbnail =
      fileType === EPowerBITemplateFields.ThumbnailFile &&
      defaultValues.thumbnailFileName === template?.thumbnailFileName
    const shouldDisplayInputField = toBeReplaced === fileType || uploadedValue
    const shouldDisplayReplaceButton = toBeReplaced !== fileType && !uploadedValue

    return (
      <>
        {shouldDisplayThumbnail && <Image source={template?.thumbnailUrl} />}

        {renderFilename}

        {shouldDisplayInputField && children}

        {shouldDisplayReplaceButton && (
          <div className='ap-my-spacing-4 ap-text-color-text-link-primary'>
            <SDKTextButton
              className='py-2'
              children={t('powerBITemplates.form.replaceButton.label')}
              onClick={() => {
                removeFile(fileType)
                setToBeReplaced(fileType)
              }}
            />
          </div>
        )}
      </>
    )
  }
}
