import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { SDKButton } from '../../../components/SDK/Button'
import { ErrorPage } from '../error'
import { ErrorDescription } from '../error/components/ErrorDescription'

export const ExpiredTokenPage = () => {
  const { t } = useTranslation('errors')
  const auth = useAuth()
  const signIn = () => {
    let signinRedirectArgs = {
      "state": location.pathname
    }
    auth.signinRedirect(signinRedirectArgs)
  }

  return (
    <ErrorPage>
      <>
        <ErrorDescription text={t('expiredTokenPage.description')} />

        <SDKButton onClick={() => signIn()}>{t('expiredTokenPage.button')}</SDKButton>
      </>
    </ErrorPage>
  )
}
