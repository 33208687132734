export const MAX_XLSX_FILE_SIZE = 10000000
export const UNSUPPORTED_EXCEL_TYPES = 'application/vnd.ms-excel'
export const XLMX_EXTENSION = '.xlsx'
export const UPLOADED_FILE_TYPE_ERROR = 'File type is not allowed!'
export const SIGHTLINE_ACTION = 'SIGHTLINE_ACTION'
export const TEMPLATE_ID = 'TEMPLATE_ID'
export const CALCULATION_ID = 'CALCULATION_ID'

export enum ESightlineRedirectAction {
  ExportProjectDataFromSightlineCS = 'exportProjectDataFromSightlineCS',
  ExportPricingResultsWithSightline = 'exportPricingResultsWithSightline',
  DataExportProjectToSightline = 'dataExportProjectToSightline',
  GetCalculationResultByFormatFromSightline = 'getCalculationResultByFormatFromSightline',
  GetPowerBICalculationResultByTemplateIdFromSightline = 'getPowerBICalculationResultByTemplateIdFromSightline',
  GetSightlineFilesList = 'getSightlineFilesList',
  SyncWithSightline = 'syncWithSightline',
  UploadToSightline = 'uploadToSightline'
}

export enum PowerBIAction {
  Send = 'Send',
  Download = 'download'
}

export enum EExportAction {
  DownloadExcel = 'downloadExcel',
  DownloadInputData = 'downloadInputData',
  DownloadPowerBI = 'downloadPowerBI',
  GetCalendarFeed = 'getCalendarFeed',
  SendExcelToSightline = 'sendExcelToSightline',
  SendPBIToSightline = 'sendPBIToSightline'
}

// for non-external only
export const SIGHTLINE_ACTIONS = [
  EExportAction.SendPBIToSightline,
  EExportAction.SendExcelToSightline
]
