import type { SelectValue } from '@appkit4/react-components/esm/combobox/Combobox'
import type { IOption } from '../../types/form'
import { SDKCombobox } from '../SDK/Combobox'
import { SDKItemDataType } from '../SDK/DropdownButton'

export const SearchBox = ({
  className,
  data,
  onSearch,
  onSelect,
  noResultFound,
  placeholder,
  prefixTemplate
}: {
  className?: string
  data: IOption[]
  onSearch?: (value: string) => void
  onSelect: (value: SelectValue) => void
  noResultFound: string
  placeholder: string
  prefixTemplate?: (item: SDKItemDataType) => React.ReactNode
}) => {
  return (
    <SDKCombobox
      className={className}
      data={data}
      noResultFound={noResultFound}
      onSelect={onSelect}
      onSearch={onSearch}
      placeholder={placeholder}
      prefixTemplate={prefixTemplate}
    />
  )
}
