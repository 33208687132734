import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Layout } from '../../components/Layout'
import { SDKLoading } from '../../components/SDK/Loading'
import { useApp } from '../../context/app.context'
import { LoadingStatus } from '../../shared/types/enums'
import { useAppDispatch } from '../../store'
import SearchHeader from './components/SearchHeader'
import SearchResults from './components/SearchResults'
import { clearGetSearchResults, getSearchResults } from './store/actions'
import { getSearchResults as getSearchResultsSelector } from './store/selectors'

const Search = () => {
  const { t } = useTranslation('search')
  const {
    appContext: {}
  } = useApp()
  const dispatch = useAppDispatch()
  const [searchQuery, setSearchQuery] = useState<string>('')

  const { loading: searchLoading, data: searchResultResponse } =
    useSelector(getSearchResultsSelector)

  const cleanUp = () => {
    dispatch(clearGetSearchResults())
  }

  const executeQuery = (query: string) => {
    if (query) {
      dispatch(getSearchResults(query))
    } else {
      cleanUp()
    }
    setSearchQuery(query)
  }

  useEffect(() => {
    cleanUp()
  }, [])

  return (
    <Layout title={t('header.title')!}>
      <SearchHeader onSearchButtonClick={executeQuery}></SearchHeader>
      {searchLoading == LoadingStatus.Pending ? (
        <SDKLoading />
      ) : (
        <>
          {searchResultResponse ? (
            <SearchResults searchResults={searchResultResponse} searchQuery={searchQuery} />
          ) : null}
        </>
      )}
    </Layout>
  )
}

export default Search
