import { IQuestionnaireQuestion } from '../../../modules/questionnaires/type'
import api from '../../api'
import {
  CountrySnapshotUrl,
  CountryUrl,
  FiscalYearUrl,
  QuestionnaireUrl
} from '../../constants/urls'

export const getQuestionnaireQuestionsData = async (
  yearId: number,
  countryId: number,
  query?: string
): Promise<IQuestionnaireQuestion[]> => {
  return (
    await api.get(
      `${QuestionnaireUrl.Questionnaire}/${FiscalYearUrl.Years}/${yearId}/${
        CountryUrl.Countries
      }/${countryId}/${CountrySnapshotUrl.Results}${query || ''}`
    )
  ).data
}
