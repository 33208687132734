import { useEffect, useMemo, useState } from 'react'
import type { UseFormSetValue, UseFormTrigger } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { RHF_SET_VALUE_OPTIONS } from '../../../../../../../../../components/Form/utils'
import {
  EAddLeaderEditorFields,
  type IFormValues
} from '../../../../../../../../../schemas/useAddLeaderEditorSchema'
import { useAppDispatch } from '../../../../../../../../../store'
import type { IOption } from '../../../../../../../../../types/form'
import type { IUserBase } from '../../../../../../../../../types/user'
import { getFullname } from '../../../../../../../../../utils/user'
import { getUsers } from '../../../../../../../../users/store/actions'
import * as selectors from '../../../../../../../../users/store/selectors'

export const useAddLeaderEditorModal = (
  searchValue: string,
  setValue: UseFormSetValue<IFormValues>,
  trigger: UseFormTrigger<IFormValues>,
  values: IFormValues
) => {
  const { data: searchedUsers, loading: searchedUsersLoading } = useSelector(selectors.getUsers)
  const dispatch = useAppDispatch()
  const [selectedUsers, setSelectedUsers] = useState<IUserBase[]>([])

  useEffect(() => {
    if (searchValue) {
      dispatch(getUsers(searchValue))
    }
  }, [searchValue])

  const userList: IOption[] =
    searchedUsers?.map(user => ({
      value: String(user.userId),
      label: getFullname(user)
    })) || []

  const offeredUsers = useMemo(
    () => userList.filter(({ value }: IOption) => !values.userIds.includes(Number(value))),
    [userList, values.userIds]
  )

  useEffect(() => {
    const searchedUsersCopy = [...searchedUsers]
    searchedUsersCopy.push(...selectedUsers)
    const currentSelectedUsers = searchedUsersCopy.filter(({ userId }: IUserBase) =>
      values.userIds.includes(userId)
    )
    const uniqueSelectedUsers = currentSelectedUsers.reduce(
      (prev: IUserBase[], cur) => [...prev.filter(obj => obj.userId !== cur.userId), cur],
      []
    )
    setSelectedUsers(uniqueSelectedUsers)
  }, [values[EAddLeaderEditorFields.userIds]])

  const onDelete = (userId: number) => {
    setSelectedUsers(selectedUsers.filter(user => userId !== user.userId))
    setValue(
      EAddLeaderEditorFields.userIds,
      values[EAddLeaderEditorFields.userIds].filter((id: number) => id !== userId),
      RHF_SET_VALUE_OPTIONS
    )
    trigger()
  }

  return { searchedUsersLoading, offeredUsers, selectedUsers, onDelete, searchedUsers }
}
