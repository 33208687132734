import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormInput } from '../../../../../../components/Form/FormInput'
import { FormSelect } from '../../../../../../components/Form/FormSelect'
import { SDKButton } from '../../../../../../components/SDK/Button'
import type { ISelectValue } from '../../../../../../components/SDK/Select'
import { SDKText } from '../../../../../../components/SDK/Text'
import { usePricingTab } from '../../../../../../context/pricingTab.context'
import { useProjectFromUrl } from '../../../../../../hooks/useProjectFromUrl'
import {
  EEScopeFields,
  useScopeDefinitionSchema
} from '../../../../../../schemas/useScopeDefinitionSchema'
import { LoadingStatus } from '../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../store'
import type { IOption } from '../../../../../../types/form'
import { EPricingTab } from '../../const/enums'
import { usePricingTabs } from '../../hooks/usePricingTabs'
import { updateModuleMetadata } from '../../modals/store/actions'
import * as selectors from '../../modals/store/selectors'
import type { IScopeQuestions } from '../../types/scope'
import { years } from './constants'

const enum EKey {
  Label = 'label',
  Content = 'content'
}

export const ScopeForm = () => {
  const { t } = useTranslation('pricing')
  const { project } = useProjectFromUrl()
  const dispatch = useAppDispatch()
  const { loading } = useSelector(selectors.updateModuleMetadata)
  const [scopeQuestions, setScopeQuestions] = useState<IScopeQuestions>({} as IScopeQuestions)
  const [tempClientName, setTempName] = useState<string>('')
  const {
    countriesSelector,
    refreshData,
    clientName: defaultClientName,
    country,
    priceYears,
    tabContent: { tabId }
  } = usePricingTab()
  const { refreshIsPermittedTabs, isScopeDefinitionCompleted, areTransactionsProvided } =
    usePricingTabs(tabId)

  useEffect(() => {
    setScopeQuestions(
      () =>
        ({
          clientName: defaultClientName,
          countryId: country?.countryId,
          yearsCount: priceYears?.length || 0
        } as IScopeQuestions)
    )
  }, [defaultClientName, country, priceYears])

  const { validationSchema, defaultValues } = useScopeDefinitionSchema(scopeQuestions)

  useEffect(() => {
    if (isScopeDefinitionCompleted) {
      refreshIsPermittedTabs()
    }
  }, [isScopeDefinitionCompleted, areTransactionsProvided])

  const { control, reset, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const client = useWatch({
    control,
    name: EEScopeFields.clientName
  })

  const cleanUpTempName = () => setTempName('')

  useEffect(() => {
    return cleanUpTempName()
  }, [])

  useEffect(() => {
    cleanUpTempName()
  }, [project?.projectId])

  useEffect(() => {
    setTempName(client)
  }, [client])

  useEffect(() => {
    if (scopeQuestions) {
      const shouldRefresh =
        scopeQuestions?.clientName?.length > 0 &&
        !!scopeQuestions?.countryId &&
        scopeQuestions?.yearsCount > 0

      if (loading === LoadingStatus.Succeeded && shouldRefresh) {
        refreshData()
      }
    }
  }, [loading])

  const onSubmit = () => {
    const params = {
      ...scopeQuestions,
      clientName: client
    }
    setScopeQuestions(() => params)
    cleanUpTempName()
    dispatch(
      updateModuleMetadata({
        projectId: project?.projectId!,
        tabName: EPricingTab.ScopeDefinition,
        params
      })
    )
  }

  const submitClientName = (
    <SDKButton
      disabled={
        (client.length === 0 || !control.getFieldState(EEScopeFields.clientName).isDirty) &&
        (tempClientName === '' || tempClientName === defaultClientName)
      }
      onClick={onSubmit}
    >
      {t(`tabs.inputs.apply`)!}
    </SDKButton>
  )

  useEffect(() => {
    reset()
    setValue(
      EEScopeFields.clientName,
      tempClientName !== '' ? tempClientName : scopeQuestions?.clientName ?? ''
    )
    setValue(EEScopeFields.yearsCount, String(scopeQuestions?.yearsCount || '0'))
    setValue(EEScopeFields.countryId, String(scopeQuestions?.countryId || '0'))
  }, [scopeQuestions])

  useEffect(() => {
    if (countriesSelector.length === 1) {
      setValue(EEScopeFields.countryId, countriesSelector[0].value)
    }
  }, [countriesSelector])

  const resetDropdownValue = (visible: boolean) => {
    if (visible) {
      return
    }

    reset()
    setValue(
      EEScopeFields.clientName,
      tempClientName !== '' ? tempClientName : scopeQuestions?.clientName ?? ''
    )
    setValue(EEScopeFields.yearsCount, String(scopeQuestions?.yearsCount || '0'))
    setValue(EEScopeFields.countryId, String(scopeQuestions?.countryId || '0'))
  }

  const select = (
    selectData: IOption[],
    onSelect: (v: ISelectValue) => void,
    name: EEScopeFields
  ) => (
    <FormSelect
      data={selectData || []}
      name={name}
      control={control}
      placeholder={t(`tabs.scopeDefinition.${name}.placeholder`)!}
      onSelect={onSelect}
      searchable={name == EEScopeFields.countryId}
      onVisibleChange={resetDropdownValue}
      required
    />
  )

  const input = (name: EEScopeFields) => (
    <FormInput
      suffixComponent={submitClientName}
      name={name}
      type='text'
      label={t(`tabs.scopeDefinition.clientName.placeholder`)!}
      control={control}
      errorMessage={t(`tabs.scopeDefinition.clientName.error`)!}
      required
    />
  )

  const selectCountry = (id: ISelectValue) => {
    const params = {
      ...scopeQuestions,
      countryId: Number(id)
    }
    setScopeQuestions(() => params)
    dispatch(
      updateModuleMetadata({
        projectId: project?.projectId!,
        tabName: EPricingTab.ScopeDefinition,
        params
      })
    )
  }

  const selectYearCount = (count: ISelectValue) => {
    const params = {
      ...scopeQuestions,
      yearsCount: Number(count)
    }
    setScopeQuestions(() => params)
    dispatch(
      updateModuleMetadata({
        projectId: project?.projectId!,
        tabName: EPricingTab.ScopeDefinition,
        params
      })
    )
  }

  const formStructure = [
    {
      title: t(`tabs.scopeDefinition.clientName.label`),
      component: input(EEScopeFields.clientName)
    },
    {
      title: t(`tabs.scopeDefinition.yearsCount.label`),
      component: select(years, selectYearCount, EEScopeFields.yearsCount)
    },
    {
      title: t(`tabs.scopeDefinition.countryId.label`),
      component: select(countriesSelector, selectCountry, EEScopeFields.countryId)
    }
  ]

  const formElement = (key: EKey) => (
    <div className='row'>
      {formStructure.map((element, index) => (
        <div className='col col-4 ap-py-spacing-2' key={`form-${key}-${index}`}>
          {key === EKey.Label ? <SDKText>{element.title}</SDKText> : element.component}
        </div>
      ))}
    </div>
  )

  return (
    <div className='ap-py-spacing-5'>
      <form>
        {formElement(EKey.Label)}
        {formElement(EKey.Content)}
      </form>
    </div>
  )
}
