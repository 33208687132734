import { useEffect } from 'react'
import { CardListItem } from '../../../../../../components/CardListItem'
import { EAction, ECardListItem } from '../../../../../../components/CardListItem/const'
import type { IActionCallback } from '../../../../../../components/CardListItem/type'
import { SDKLoading } from '../../../../../../components/SDK/Loading'
import { useApp } from '../../../../../../context/app.context'
import { LoadingStatus } from '../../../../../../shared/types/enums'
import type { IQuestionnaireQuestion } from '../../../../type'
import EmptyStateInQuestions from '../EmptyStateInQuestions'
import ListTitle from '../ListTitle'
import QuestionsTable from '../QuestionsTable'

interface IQuestionsListProps {
  onEdit: (id: number) => void
  questions: IQuestionnaireQuestion[]
  loading: LoadingStatus
  error: string | null | undefined
  hasUnansweredQuestions: boolean
  onHasUnansweredQuestionsChange: (v: boolean) => void
}

const QuestionsList = ({
  onEdit,
  questions,
  loading,
  error,
  hasUnansweredQuestions,
  onHasUnansweredQuestionsChange
}: IQuestionsListProps) => {
  const {
    appContext: {
      style: { isMobile }
    },
    sendNotification
  } = useApp()

  useEffect(() => {
    if (error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
    }
  }, [error])

  return (
    <div className='questionnaire-questions-page'>
      <ListTitle
        hasUnansweredQuestions={hasUnansweredQuestions}
        onHasUnansweredQuestionsChange={onHasUnansweredQuestionsChange}
      />
      {loading === LoadingStatus.Pending && <SDKLoading />}
      {!questions.length && loading === LoadingStatus.Succeeded && <EmptyStateInQuestions />}
      {loading === LoadingStatus.Succeeded &&
        !!questions.length &&
        (isMobile ? (
          questions?.map(question => (
            <div key={question.questionId}>
              <CardListItem
                cardListItem={ECardListItem.QuestionnaireQuestionCard}
                actions={[EAction.Edit]}
                handleClick={(callback: IActionCallback) => onEdit(Number(callback.id))}
                {...question}
              />
            </div>
          ))
        ) : (
          <QuestionsTable questions={questions} onEdit={onEdit} />
        ))}
    </div>
  )
}

export default QuestionsList
