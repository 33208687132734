import { createReducer } from '@reduxjs/toolkit'
import {
  getDefaultResourceState,
  Resource,
  setResourceFullfilled,
  setResourcePending,
  setResourceRejected
} from '../../../shared/utils/store'
import type { ICountry, ICountryListItem } from '../types'
import {
  addCountry,
  clearAddCountry,
  clearCountries,
  clearGetCountryById,
  clearPublishedCountries,
  clearUpdateCountry,
  clearUpdateCountryVisibility,
  getCountries,
  getCountryById,
  getPublishedCountries,
  updateCountry,
  updateCountryVisibility
} from './actions'

export interface CountriesState {
  addCountry: Resource<ICountry | null>
  countries: Resource<ICountryListItem[]>
  country: Resource<ICountry | null>
  publishedCountries: Resource<ICountryListItem[]>
  updateCountry: Resource<ICountry | null>
  updateCountryVisibility: Resource<ICountry | null>
}

const initialState: CountriesState = {
  addCountry: getDefaultResourceState(null),
  countries: getDefaultResourceState([]),
  country: getDefaultResourceState(null),
  publishedCountries: getDefaultResourceState([]),
  updateCountry: getDefaultResourceState(null),
  updateCountryVisibility: getDefaultResourceState(null)
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getCountries.pending, state => {
      setResourcePending(state.countries)
    })
    .addCase(getCountries.rejected, (state, actions) => {
      setResourceRejected(state.countries, actions)
    })
    .addCase(getCountries.fulfilled, (state, actions) => {
      state.countries.data = actions.payload
      setResourceFullfilled(state.countries)
    })
    .addCase(clearCountries, state => {
      state.countries = initialState.countries
    })
    .addCase(getPublishedCountries.pending, state => {
      setResourcePending(state.publishedCountries)
    })
    .addCase(getPublishedCountries.rejected, (state, actions) => {
      setResourceRejected(state.publishedCountries, actions)
    })
    .addCase(getPublishedCountries.fulfilled, (state, actions) => {
      state.publishedCountries.data = actions.payload
      setResourceFullfilled(state.publishedCountries)
    })
    .addCase(clearPublishedCountries, state => {
      state.publishedCountries = initialState.publishedCountries
    })
    .addCase(getCountryById.pending, state => {
      setResourcePending(state.country)
    })
    .addCase(getCountryById.rejected, (state, actions) => {
      setResourceRejected(state.country, actions)
    })
    .addCase(getCountryById.fulfilled, (state, actions) => {
      state.country.data = actions.payload
      setResourceFullfilled(state.country)
    })
    .addCase(clearGetCountryById, state => {
      state.country = initialState.country
    })
    .addCase(addCountry.pending, state => {
      setResourcePending(state.addCountry)
    })
    .addCase(addCountry.rejected, (state, actions) => {
      setResourceRejected(state.addCountry, actions)
    })
    .addCase(addCountry.fulfilled, (state, actions) => {
      setResourceFullfilled(state.addCountry)
      state.addCountry.data = actions.payload
    })
    .addCase(clearAddCountry, state => {
      state.addCountry = initialState.addCountry
    })
    .addCase(updateCountry.pending, state => {
      setResourcePending(state.updateCountry)
    })
    .addCase(updateCountry.rejected, (state, actions) => {
      setResourceRejected(state.updateCountry, actions)
    })
    .addCase(updateCountry.fulfilled, (state, actions) => {
      setResourceFullfilled(state.updateCountry)
      state.updateCountry.data = actions.payload
    })
    .addCase(clearUpdateCountry, state => {
      state.updateCountry = initialState.updateCountry
    })
    .addCase(updateCountryVisibility.pending, state => {
      setResourcePending(state.updateCountryVisibility)
    })
    .addCase(updateCountryVisibility.rejected, (state, actions) => {
      setResourceRejected(state.updateCountryVisibility, actions)
    })
    .addCase(updateCountryVisibility.fulfilled, (state, actions) => {
      setResourceFullfilled(state.updateCountryVisibility)
      state.updateCountry.data = actions.payload
    })
    .addCase(clearUpdateCountryVisibility, state => {
      state.updateCountryVisibility = initialState.updateCountryVisibility
    })
)
