import {
  IProjectResultByCountry,
  IProjectResultByQuestion,
  ProjectAnswerByQuestion
} from '../types/projectResults'

const setSingleAnswer = (answer: ProjectAnswerByQuestion, question: IProjectResultByQuestion) => ({
  ...answer,
  category: question.category,
  subcategory: question.subcategory,
  questionText: question.questionText,
  questionId: question.questionId,
  type: question.type,
  calendarQuestionOptions: question.calendarQuestionOptions,
  possibleAnswers: question.possibleAnswers
})

export const mapQuestionsToCountries = (questions: IProjectResultByQuestion[]) => {
  return questions.reduce((acc: IProjectResultByCountry[], question) => {
    question.answers.forEach(answer => {
      const countryIndex = acc.findIndex(country => country.countryId === answer.countryId)
      if (!!~countryIndex) {
        acc[countryIndex].answers.push(setSingleAnswer(answer, question))
      } else {
        acc.push({
          countryName: answer.countryName,
          countryId: answer.countryId,
          answers: [setSingleAnswer(answer, question)]
        })
      }
    })
    return acc
  }, [])
}
