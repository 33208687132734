import { ECardDnDItem } from '../../../../../components/CardDnDWrapper/const'
import { ERole } from '../components/ScopeWrapper'
import { TableType, TableTypeId } from '../types/dnd'
import type { IChildScope, IParentScope, IScopeTabBase, TTransformedScope } from '../types/scope'

const sortByOrder = (a: TTransformedScope, b: TTransformedScope) => {
  if (a.order < b.order) {
    return -1
  }
  if (a.order > b.order) {
    return 1
  }
  return 0
}

export const transformData = (
  parentScopes: IParentScope[],
  scopeIncludes: IChildScope[],
  scopeExcludes: IChildScope[],
  scopeAssumes: IChildScope[],
  baseData: IScopeTabBase,
  withoutAddButton?: boolean,
  countryHeader?: boolean,
  withoutDnD?: boolean
) => {
  return {
    draggedItem: undefined,
    moduleId: baseData.type,
    projectId: baseData.projectId,
    name: baseData.name,
    tables: [
      {
        id: TableTypeId.Scope,
        tableName: TableType.Scope,
        acceptType: withoutDnD ? [TableType.ScopeWithoutDnD] : [TableType.Scope],
        rows: parentScopes
          .map(e => ({ ...e, id: String(e.parentScopeId) }))
          .sort((a, b) => sortByOrder(a, b)),
        rowsType: ECardDnDItem.cardWithFee,
        role: ERole.Parent,
        withoutAddButton,
        countryHeader,
        withoutDnD
      },
      {
        id: TableTypeId.ScopeIncludes,
        tableName: TableType.ScopeIncludes,
        acceptType: [TableType.ScopeIncludes, TableType.ScopeExcludes],
        rowsType: ECardDnDItem.defaultCard,
        rows: scopeIncludes
          .map(e => ({ ...e, id: String(e.childScopeId) }))
          .sort((a, b) => sortByOrder(a, b)),
        role: ERole.Parent
      },
      {
        id: TableTypeId.ScopeExcludes,
        tableName: TableType.ScopeExcludes,
        acceptType: [TableType.ScopeIncludes, TableType.ScopeExcludes],
        rowsType: ECardDnDItem.defaultCard,
        rows: scopeExcludes
          .map(e => ({ ...e, id: String(e.childScopeId) }))
          .sort((a, b) => sortByOrder(a, b)),
        role: ERole.Child
      },
      {
        id: TableTypeId.ScopeAssumes,
        tableName: TableType.ScopeAssumes,
        acceptType: [TableType.ScopeAssumes],
        rowsType: ECardDnDItem.defaultCard,
        rows: scopeAssumes
          .map(e => ({ ...e, id: String(e.childScopeId) }))
          .sort((a, b) => sortByOrder(a, b)),
        role: ERole.Child
      }
    ]
  }
}
