import { EPricingTab } from '../../../modules/project/components/GCDPricing/const/enums'
import type { IProjectSetup } from '../../../modules/project/components/GCDPricing/types/inputsTab'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export interface IUpdateTransactionsRequestParams {
  transactions: string
}

export const updateTransactionsRequest = async (
  projectId: number,
  params: IUpdateTransactionsRequestParams
): Promise<IProjectSetup> => {
  return (
    await api.put(
      `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/${ProjectsUrl.Modules}/${EPricingTab.ProjectSetup}/${ProjectsUrl.Transactions}`,
      params
    )
  ).data
}
