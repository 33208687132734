import moment from 'moment'
import { useTranslation } from 'react-i18next'
import {
  ECalculationType,
  ITemplateCalculationData,
  TCalculationType
} from '../../../modules/project/types/projectCalculation'
import { Divider } from '../../Divider'
import { SDKButton } from '../Button'
import { SDKIcon } from '../Icon'
import { SDKPanel } from '../Panel'
import { SDKParagraph } from '../Paragraph'
import { SDKText } from '../Text'
import { getDuration } from './utils/getDuration'
import { getHeader } from './utils/getHeader'

interface IStatusPanelProps {
  type: TCalculationType
  initiatedBy: string
  data: ITemplateCalculationData
}

const ColumnData = ({ title, data }: { title: string; data: string }) => (
  <div className='ap-flex flex-column ap-mr-spacing-7'>
    <SDKText type='body-s' className='ap-text-neutral-14'>
      {title}
    </SDKText>
    <SDKText type='body' className='ap-text-neutral-17'>
      {data}
    </SDKText>
  </div>
)

export const StatusPanel = ({ type, data, initiatedBy }: IStatusPanelProps) => {
  const { t } = useTranslation()
  const { errors, createdDate, finishedDate } = data
  const header = getHeader(type)

  return (
    <>
      <SDKPanel bordered>
        <div className='ap-flex align-items-center'>
          <SDKIcon code={header.icon} outline={false} className={header.className}></SDKIcon>
          <SDKText type='heading' className={header.className}>
            {header.name}
          </SDKText>
        </div>
        {(type === ECalculationType.Created || type === ECalculationType.InProgress) && (
          <SDKParagraph type='subheading' className={header.className}>
            {t('status.pendingText')}
          </SDKParagraph>
        )}
        <Divider />
        <div className='ap-flex'>
          {type === ECalculationType.Canceled ? (
            <SDKParagraph type='body' className='ap-text-states-error-05'>
              {t('status.cancelledText')}
            </SDKParagraph>
          ) : (
            <>
              <ColumnData
                title={t(
                  type !== ECalculationType.Created && type !== ECalculationType.InProgress
                    ? 'status.results.ranOn'
                    : 'status.results.startedOn'
                )}
                data={moment(createdDate).format('MMM DD, YYYY')}
              />
              {type !== ECalculationType.Created && type !== ECalculationType.InProgress && (
                <ColumnData
                  title={t('status.results.duration')}
                  data={getDuration(createdDate, finishedDate) || '0'}
                />
              )}
              <ColumnData title={t('status.results.initiatedBy')} data={initiatedBy} />
            </>
          )}
        </div>
      </SDKPanel>
      {errors && errors.length > 0 && (
        <SDKPanel bordered className='ap-mt-spacing-4'>
          <div className='ap-flex justify-content-between ap-mb-spacing-3'>
            <SDKText type='heading' className='ap-text-neutral-20'>
              {t('status.results.errorLog')}
            </SDKText>
            <SDKButton
              kind='text'
              icon='icon-copy-outline'
              className='ap-typography-body-s align-items-center'
              compact
              onClick={() => navigator.clipboard.writeText(errors.join('\n'))}
            >
              {t('status.results.copyAllErrors')}
            </SDKButton>
          </div>

          <ul className='ap-pl-spacing-7'>
            {errors.map(
              (err, index) =>
                err.length > 0 && (
                  <li key={`calculation-err-${index}`} className='ap-text-neutral-14'>
                    <SDKParagraph type='body' className='ap-text-neutral-14'>
                      {err}
                    </SDKParagraph>
                  </li>
                )
            )}
          </ul>
        </SDKPanel>
      )}
    </>
  )
}
