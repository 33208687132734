import { ECardDnDItem } from '../../../../../components/CardDnDWrapper/const'
import { ERole } from '../components/ScopeWrapper'
import { EPricingTabId } from '../const/enums'
import type { TTransformedScope } from './scope'

export enum TableType {
  Scope = 'Scope',
  ScopeIncludes = 'Scope Includes',
  ScopeExcludes = 'Scope Excludes',
  ScopeAssumes = 'Scope Assumes',
  ScopeWithoutDnD = 'Scope Without DnD'
}

export enum TableTypeId {
  Scope = '0',
  ScopeIncludes = '1',
  ScopeExcludes = '2',
  ScopeAssumes = '3'
}

export enum EBenchmarkType {
  LocalBenchmarking = 'Local benchmarking studies',
  RegionalBenchmarking = 'Regional benchmarking studies',
  GlobalBenchmarking = 'Global benchmarking studies'
}

export interface ITable {
  id: string
  tableName: TableType
  rows: TTransformedScope[]
  rowsType: ECardDnDItem
  role: ERole
  benchmarkName?: EBenchmarkType
  withoutAddButton?: boolean
  countryHeader?: boolean
  withoutDnD?: boolean
  acceptType: TableType[]
}

export interface IDragItem {
  id: string
  index: number
  tableId: string
}

export interface ITransformedData {
  tables: ITable[]
  moduleId: EPricingTabId
  name: string
  projectId: number
  draggedItem?: IDragItem
}
