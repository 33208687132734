import { RegionContainer } from '../../../shared/types/enums'
import { addDefaultSection } from '../../../shared/utils/addDefaultSections'
import { IRegion, TRegionsSection } from '../types'

export const mapRegionsDataToSections = (regions: IRegion[]) => {
  const sections = Object.entries(
    regions.reduce((acc: TRegionsSection, region) => {
      if (!(region.type in RegionContainer)) {
        return acc
      }
      return Object.assign(acc, {
        [region.type]: (acc[region.type] || []).concat({
          name: region.name,
          yearId: region.yearId,
          regionId: region.regionId
        })
      })
    }, {})
  )
  return addDefaultSection(sections)
}
