import { useTranslation } from 'react-i18next'
import { SDKDropdownButton } from '../../../../../components/SDK/DropdownButton'
import { SDKIcon } from '../../../../../components/SDK/Icon'
import { LoadingOverlay } from '../../../../../components/SDK/LoadingOverlay'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { ResultsContent } from './components/ResultsTab/ResultsContent'
import { useExportResults } from './components/ResultsTab/utils/useExportResults'
import styles from './tabs.module.scss'

export const GCDPricingResults = () => {
  const { t } = useTranslation()
  const { menuItems, loading, onFullExport } = useExportResults()

  return (
    <>
      {loading === LoadingStatus.Pending && <LoadingOverlay />}
      <SDKDropdownButton
        className='ap-flex justify-content-end ap-mb-spacing-5'
        prefixTemplate={item => item.iconName && <SDKIcon code={item.iconName} />}
        splitButton={true}
        compact
        onClick={() => onFullExport()}
        onSelect={(_, item) => item.onClick()}
        data={menuItems}
        dropdownClassName={styles['reports-dropdown']}
      >
        {t('buttons.export')}
      </SDKDropdownButton>
      <ResultsContent />
    </>
  )
}
