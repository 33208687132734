import type { IGateQuestionPending } from '../../../modules/fiscalYearDetails/pages/GateQuestions/types'
import api from '../../api'
import type { IGateQuestionsDraftUrl } from './types'
import { gateQuestionsDraftUrl } from './urls'

export const getGateQuestionsPendingData = async (
  props: IGateQuestionsDraftUrl
): Promise<IGateQuestionPending> => {
  return (await api.get(gateQuestionsDraftUrl(props))).data
}
