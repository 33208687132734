import { Layout } from '../../../../components/Layout'
import { ProjectContextProvider } from '../../../../context/project.context'
import { useProjectFromUrl } from '../../../../hooks/useProjectFromUrl'
import { NavigationTiles } from './components/NavigationTiles'

export const ProjectSettingsContent = () => (
  <div className='project-settings-page'>
    <NavigationTiles />
  </div>
)

const ProjectSettingsPage = () => {
  const { project } = useProjectFromUrl()

  return (
    <Layout breadcrumbs={{ project: project?.name! }}>
      <ProjectSettingsContent />
    </Layout>
  )
}

const ProjectSettings = () => {
  return (
    <ProjectContextProvider>
      <ProjectSettingsPage />
    </ProjectContextProvider>
  )
}

export default ProjectSettings
