import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../../store'

export const getEmailTemplates = (state: RootState) => state.systemManagement.emailTemplates
export const getEmailTemplateById = (id?: string) =>
  createSelector(getEmailTemplates, state => state.data.find(({ templateId }) => id === templateId))
export const getUpdateEmailTemplate = (state: RootState) =>
  state.systemManagement.updateEmailTemplate
export const getLegalDocuments = (state: RootState) => state.systemManagement.legalDocuments
export const getLegalDocumentsPublic = (state: RootState) =>
  state.systemManagement.legalDocumentsPublic
export const getLegalDocumentById = (id?: number) =>
  createSelector(getLegalDocuments, state => state.data.find(({ documentId }) => id === documentId))
export const getUnapprovedLegalDocuments = (state: RootState) =>
  state.systemManagement.unapprovedLegalDocuments
export const getAddLegalDocument = (state: RootState) => state.systemManagement.addLegalDocument
export const addApproveLegalDocument = (state: RootState) =>
  state.systemManagement.addApproveLegalDocument
export const getUpdateLegalDocument = (state: RootState) =>
  state.systemManagement.updateLegalDocument
export const getDeleteLegalDocument = (state: RootState) =>
  state.systemManagement.deleteLegalDocument
export const createLegalDocumentTest = (state: RootState) =>
  state.systemManagement.createLegalDocumentTest
export const getDownloadProjectsReport = (state: RootState) => state.systemManagement.projectsReport
export const getDownloadQuestionsAndAnswersReport = (state: RootState) =>
  state.systemManagement.questionsAndAnswersReport
export const getDownloadRDPFormulasReport = (state: RootState) =>
  state.systemManagement.rdpFormulasReport
export const getUsersReport = (state: RootState) => state.systemManagement.usersReport
export const getPricingToolsImportInfo = (state: RootState) =>
  state.systemManagement.pricingToolsImportInfo
export const addPricingToolsImport = (state: RootState) =>
  state.systemManagement.addPricingToolsImport
export const downloadPricingToolsImport = (state: RootState) =>
  state.systemManagement.pricingToolsImportTemplate
