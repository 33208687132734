import type { AxiosResponse } from 'axios'

export function sanitizeFilename(str: string) {
  // only A-Z, a-z, 0-9, underscore, hash and dots are allowed
  const pattern = /[^(\w).-]/g

  return str.replace(pattern, '')
}

export const generateFileNameFromResponse = (response: AxiosResponse<any, any>) => {
  const fileNameFromResponse = response.headers['content-disposition']
    .split(';')
    .find((n: string) => n.includes('filename='))
    .replace('filename=', '')
    .trim()

  if (!fileNameFromResponse) {
    return null
  }

  return sanitizeFilename(fileNameFromResponse)
}
