import { useCallback, useEffect } from 'react'
import type { UseFormReset } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { IFormValues } from '../../../../../schemas/useAddSubcategorySchema'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../store'
import * as actions from '../../../store/actions'
import * as selectors from '../../../store/selectors'

export const useAddEditSubcategory = (
  setVisible: (value: boolean) => void,
  reset: UseFormReset<IFormValues>,
  categoryId: number | null,
  subcategoryId: number | null,
  yearId?: number
) => {
  const dispatch = useAppDispatch()
  const addSubcategoryData = useSelector(selectors.getAddSubcategory)
  const updateSubcategoryData = useSelector(selectors.getUpdateSubcategory)
  const loading: LoadingStatus = subcategoryId
    ? updateSubcategoryData.loading
    : addSubcategoryData.loading

  const onClose = useCallback(() => {
    setVisible(false)
    reset()
  }, [setVisible, reset])

  const onSubmit = (data: IFormValues) => {
    const mappedData = {
      name: data.subcategory
    }

    if (categoryId === undefined || yearId === undefined) {
      return
    }

    if (subcategoryId !== null) {
      dispatch(
        actions.updateSubcategory({
          params: mappedData,
          yearId,
          categoryId: categoryId || 0,
          subcategoryId: subcategoryId || 0
        })
      )
    } else {
      dispatch(actions.addSubcategory({ params: mappedData, yearId, categoryId: categoryId || 0 }))
    }
  }

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded && yearId) {
      dispatch(actions.getCategories(yearId))
      onClose()
    }
  }, [loading])

  return { loading, onSubmit, onClose }
}
