import { getUrlQueries } from '../../../utils/getUrlQueries'
import api from '../../api'
import { FiscalYearUrl, PowerBITemplateUrl } from '../../constants/urls'

export interface IPBITemplateFileData {
  fileName: string
  templateId: number
  yearId: number
}

export const getPBITemplateFileData = async ({
  fileName,
  templateId,
  yearId
}: IPBITemplateFileData): Promise<string> => {
  const queries = getUrlQueries({ fileName })

  return (
    await api.get(
      `${FiscalYearUrl.Years}/${yearId}/${PowerBITemplateUrl.PowerBITemplates}/${templateId}${queries}`
    )
  ).data
}
