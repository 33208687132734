export const SearchResultType = {
  CountrySnapshotResultQuestion: 0,
  CountrySnapshotResultAnswer: 1,
  RDPDescriptionResult: 2,
  RDPTaraFieldResult: 3,
  PricingToolResult: 4
} as const

export type SearchResultTypeT = (typeof SearchResultType)[keyof typeof SearchResultType]

export interface SearchResultsResponse {
  countrySnapshotResults: ICountrySnapshotSearchResult[]
  rdpResults: IRDPSearchResult[]
  pricingToolResults: IPricingToolSearchResult[]
}

interface IBaseSearchResult {
  searchResultType: SearchResultTypeT
  snippetText: string
  order: number
}

export interface ICountrySnapshotSearchResult extends IBaseSearchResult {
  questionText: string
  answerText: string
  yearName: string
  countryName: string
}

export interface IRDPSearchResult extends IBaseSearchResult {
  yearName: string
  countryName: string
  taraFieldName: string
  fieldDescription: string
  fieldLocation: string
}

export interface IPricingToolSearchResult extends IBaseSearchResult {
  projectName: string
  moduleName: string
  scopeName: string
}
