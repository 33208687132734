import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Url } from '../../../../../constants/urls'
import { useApp } from '../../../../../context/app.context'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../store'
import * as actions from '../../../store/actions'
import { getLegalDocuments } from '../../../store/selectors'

export const useLegalDocuments = () => {
  const dispatch = useAppDispatch()
  const navigation = useNavigate()
  const { sendNotification } = useApp()
  const { loading, error, data } = useSelector(getLegalDocuments)

  const onAddDocument = () => {
    navigation(`${location.pathname}${Url.AddLegalDocumentPage}`)
    dispatch(actions.clearAddLegalDocument())
  }

  useEffect(() => {
    dispatch(actions.getLegalDocuments())
    return () => {
      dispatch(actions.clearUpdateLegalDocument())
    }
  }, [])

  useEffect(() => {
    if (loading === LoadingStatus.Failed && error) {
      sendNotification({
        message: error,
        status: 'error'
      })
    }
  }, [loading])

  return { loading, data, onAddDocument }
}
