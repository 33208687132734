import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Module } from '../../../constants/modules'
import { addNewAnswer } from '../../../services/requests/questionnaire/addNewAnswer'
import { getLanguages } from '../../../services/requests/questionnaire/getLanguages'
import { getQuestionnaireCountriesData } from '../../../services/requests/questionnaire/getQuestionnaireCountriesData'
import { getQuestionnaireQuestionsData } from '../../../services/requests/questionnaire/getQuestionnaireQuestionsData'
import { getQuestionnaireYearsData } from '../../../services/requests/questionnaire/getQuestionnaireYearsData'
import { updatePublishCountry } from '../../../services/requests/questionnaire/updatePublishCountry'
import { updateVerifyCountry } from '../../../services/requests/questionnaire/updateVerifyCountry'
import type { IAddAnswer } from '../type'

const moduleName: string = String(Module.Questionnaire)

export interface IAddNewAnswer {
  yearId: number
  countryId: number
  questionId: number
  questionType: number
  params: IAddAnswer
}

export const getQuestionnaireCountries = createAsyncThunk(
  `${moduleName}/getQuestionnaireCountries`,
  (yearId: number) => getQuestionnaireCountriesData(yearId)
)
export const clearQuestionnaireCountries = createAction(`${moduleName}/clearQuestionnaireCountries`)
export const getQuestionnaireQuestions = createAsyncThunk(
  `${moduleName}/getQuestionnaireQuestions`,
  ({ yearId, countryId, query }: { yearId: number; countryId: number; query?: string }) =>
    getQuestionnaireQuestionsData(yearId, countryId, query)
)
export const clearQuestionnaireQuestions = createAction(`${moduleName}/clearQuestionnaireQuestions`)
export const getQuestionnaireYears = createAsyncThunk(`${moduleName}/getQuestionnaireYears`, () =>
  getQuestionnaireYearsData()
)
export const clearGetQuestionnaireYears = createAction(`${moduleName}/clearGetQuestionnaireYears`)
export const addAnswer = createAsyncThunk(
  `${moduleName}/addAnswer`,
  ({ yearId, countryId, questionId, questionType, params }: IAddNewAnswer) =>
    addNewAnswer(yearId, countryId, questionId, questionType, params)
)
export const clearAddAnswer = createAction(`${moduleName}/clearAddAnswer`)

export const updateCountryPublish = createAsyncThunk(
  `${moduleName}/updateCountryPublish`,
  ({ yearId, countryId }: { yearId: number; countryId: number }) =>
    updatePublishCountry({ yearId, countryId })
)
export const clearUpdateCountryPublish = createAction(`${moduleName}/clearUpdateCountryPublish`)

export const updateCountryVerify = createAsyncThunk(
  `${moduleName}/updateCountryVerify`,
  ({ yearId, countryId }: { yearId: number; countryId: number }) =>
    updateVerifyCountry({ yearId, countryId })
)
export const clearUpdateCountryVerify = createAction(`${moduleName}/clearUpdateCountryVerify`)

export const getAllLanguages = createAsyncThunk(`${moduleName}/getAllLanguages`, () =>
  getLanguages()
)
export const clearGetAllLanguages = createAction(`${moduleName}/clearGetAllLanguages`)
