import { useTranslation } from 'react-i18next'
import { BlankPage } from '../../components/BlankPage'
import { LoadingSpinner } from '../../components/SDK/LoadingSpinner'

import './index.scss'

const AppLoading = ({ textKey = 'loading' }: { textKey?: string }) => {
  const { t } = useTranslation()

  return <BlankPage middleContent={<LoadingSpinner message={t(textKey)!} className='col' />} />
}

export default AppLoading
