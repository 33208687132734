import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Divider } from '../../../../components/Divider'
import { EmptyState } from '../../../../components/EmptyState'
import { IconWithTooltip } from '../../../../components/IconWithTooltip'
import { SDKIcon } from '../../../../components/SDK/Icon'
import { SDKPanel } from '../../../../components/SDK/Panel'
import { SDKTabber } from '../../../../components/SDK/Tabber'
import { SDKText } from '../../../../components/SDK/Text'
import { SDKTextButton } from '../../../../components/SDK/TextButton'
import { useApp } from '../../../../context/app.context'
import { useMyRole } from '../../../../context/hooks/useMyRole'
import { useProject } from '../../../../context/project.context'
import { EFeatureFlag, useFeatureFlags } from '../../../../hooks/useFeatureFlags'
import { EProjectFields } from '../../../myprojects/types'
import { ESightlineRedirectAction, SIGHTLINE_ACTION } from '../../constants'
import {
  ECSTabHashUrl,
  EPricingToolMainTabHashUrl,
  EProjectToolsTABSTab,
  ETARATabHashUrl
} from '../../constants/tab'
import CSTabs from '../CSTabs'
import CustomCountriesModal from '../CustomCountriesModal'
import { GCDPricing } from '../GCDPricing'
import { usePricingTabs } from '../GCDPricing/hooks/usePricingTabs'
import { TaraTabs } from '../TaraTabs'
import './index.scss'

const ProjectTools = () => {
  const { t } = useTranslation('myprojects')
  const navigation = useNavigate()
  const { project } = useProject()
  const [activeIndex, setActiveIndex] = useState(0)
  const { amIExternal } = useMyRole()
  const [showCountriesModal, setShowCountriesModal] = useState(false)
  const [showCopyWarning, setShowCopyWarning] = useState(false)
  const {
    appContext: { isProjectMFE }
  } = useApp()
  const { isEnabled } = useFeatureFlags()

  const componentWrapper = (component: JSX.Element) => (
    <>{project?.yearId && project?.projectId && project?.countryIds ? component : <EmptyState />}</>
  )

  // an external user can see only Country Snapshots
  let PROJECT_TOOLS_TABS: any[] = [
    {
      label: t(`projectDetail.projectTabs.countrySnapshot.label`),
      value: EProjectToolsTABSTab.CS,
      hashUrl: Object.values(ECSTabHashUrl),
      component: componentWrapper(<CSTabs />)
    }
  ]

  if (!amIExternal) {
    const rdpTab = {
      label: t(`projectDetail.projectTabs.tara.label`),
      value: EProjectToolsTABSTab.TARA,
      hashUrl: Object.values(ETARATabHashUrl),
      component: componentWrapper(<TaraTabs />)
    }

    const { tabs: pricingTabs } = usePricingTabs(0)
    const pricingTabHashUrls = pricingTabs.map(pricingTabItem => pricingTabItem.hashUrl)

    const currentHash = window.location.hash.slice(1)
    if (currentHash === EPricingToolMainTabHashUrl.Results) {
      pricingTabHashUrls.push(EPricingToolMainTabHashUrl.Results)
    }

    const pricingTab = {
      label: t(`projectDetail.projectTabs.pricingTools.label`),
      value: EProjectToolsTABSTab.Pricing,
      hashUrl: pricingTabHashUrls,
      component: componentWrapper(<GCDPricing />),
      isPermitted: !(
        project?.[EProjectFields.OriginalProjectYearId] &&
        project?.[EProjectFields.OriginalProjectYearId] != project?.[EProjectFields.YearId]
      )
    }

    PROJECT_TOOLS_TABS.push(rdpTab)

    const isPricingEnabled = isEnabled(EFeatureFlag.Pricing)
    if (isPricingEnabled) {
      PROJECT_TOOLS_TABS.push(pricingTab)

      if (
        project?.[EProjectFields.OriginalProjectYearId] &&
        project?.[EProjectFields.OriginalProjectYearId] != project?.[EProjectFields.YearId]
      ) {
        const pricingTabWarningTab = {
          label: (
            <IconWithTooltip
              code='information'
              content={t('projectDetail.showPricingRollForwardWarning')!}
              iconClassName='ap-text-color-text-primary'
            />
          ),
          value: EProjectToolsTABSTab.Pricing,
          hashUrl: pricingTabHashUrls,
          component: componentWrapper(<GCDPricing />)
        }

        PROJECT_TOOLS_TABS.push(pricingTabWarningTab)
      }
    }
  }

  useEffect(() => {
    const action = localStorage.getItem(SIGHTLINE_ACTION)
    if (action === ESightlineRedirectAction.UploadToSightline) {
      navigation(`${location.pathname}#${ETARATabHashUrl.Input}`)
      setActiveIndex(EProjectToolsTABSTab.TARA)
    } else if (action === ESightlineRedirectAction.GetCalculationResultByFormatFromSightline) {
      navigation(`${location.pathname}#${ETARATabHashUrl.Results}`)
      setActiveIndex(EProjectToolsTABSTab.TARA)
    } else if (action === ESightlineRedirectAction.GetSightlineFilesList) {
      navigation(`${location.pathname}#${ETARATabHashUrl.Input}`)
      setActiveIndex(EProjectToolsTABSTab.TARA)
    } else if (
      action === ESightlineRedirectAction.GetPowerBICalculationResultByTemplateIdFromSightline
    ) {
      navigation(`${location.pathname}#${ETARATabHashUrl.Results}`)
      setActiveIndex(EProjectToolsTABSTab.TARA)
    } else if (action === ESightlineRedirectAction.ExportProjectDataFromSightlineCS) {
      navigation(`${location.pathname}#${ECSTabHashUrl.Results}`)
      setActiveIndex(EProjectToolsTABSTab.CS)
    } else {
      const currentHash = window.location.hash.slice(1)
      if (currentHash.startsWith('pricing-')) {
        const pricingTab = PROJECT_TOOLS_TABS.find(tab => tab.value == EProjectToolsTABSTab.Pricing)
        if (pricingTab && pricingTab.isPermitted) {
          setActiveIndex(EProjectToolsTABSTab.Pricing)
        } else {
          navigation(`${location.pathname}#${ECSTabHashUrl.Results}`)
          setActiveIndex(EProjectToolsTABSTab.CS)
        }
      }
    }
  }, [])

  useEffect(() => {
    switch (activeIndex) {
      case EProjectToolsTABSTab.TARA:
        setShowCopyWarning(!!project?.[EProjectFields.ShowRDPCopyWarning])
        break
      case EProjectToolsTABSTab.Pricing:
        setShowCopyWarning(!!project?.[EProjectFields.ShowPricingCopyWarning])
        break
      default:
        setShowCopyWarning(false)
        break
    }
  }, [activeIndex])

  const countOfCountries = () => {
    let countriesNumber = 0
    const allCountries = project?.countryIds.length || 0
    switch (activeIndex) {
      case EProjectToolsTABSTab.CS:
        countriesNumber = project?.countrySnapshotCountryIds?.length || 0
        break
      case EProjectToolsTABSTab.TARA:
        countriesNumber = project?.taraCountryIds?.length || 0
        break
      case EProjectToolsTABSTab.Pricing:
        countriesNumber = project?.pricingCountryIds?.length || 0
        break
    }
    if (countriesNumber === allCountries) {
      return `${t(`projectDetail.all`)} ${allCountries}`
    } else {
      return (
        <>
          <SDKText weight={3} className='ap-text-color-text-error'>
            {String(countriesNumber)}
          </SDKText>
          {` ${t(`projectDetail.outOf`)} ${allCountries}`}
        </>
      )
    }
  }

  const showCopyWarningText = (): string => {
    const isRollForward =
      project?.[EProjectFields.OriginalProjectYearId] &&
      project?.[EProjectFields.OriginalProjectYearId] != project?.[EProjectFields.YearId]

    switch (activeIndex) {
      case EProjectToolsTABSTab.TARA:
        return (
          (isRollForward
            ? t('projectDetail.showRDPRollForwardWarning')
            : t('projectDetail.showRDPCopyWarning')) || ''
        )
      case EProjectToolsTABSTab.Pricing:
        return (
          (isRollForward
            ? t('projectDetail.showPricingRollForwardWarning')
            : t('projectDetail.showPricingCopyWarning')) || ''
        )
      default:
        return ''
    }
  }

  return (
    <div className={classNames(isProjectMFE && 'project-tools-container-mfe ap-container p-0')}>
      <SDKPanel
        className='project-tools'
        footer={
          <>
            <div className='row'>
              <div className='col-8 ap-flex align-items-center'>
                <IconWithTooltip
                  code='information'
                  content={t('projectDetail.projectCountriesTooltip')!}
                />
                <SDKText>
                  <>
                    <SDKText weight={2}>{countOfCountries()}</SDKText>
                    {t(`projectDetail.projectCountriesIncluded`)}
                  </>
                </SDKText>
              </div>
              <div className='col-4 ap-flex justify-content-end ap-py-spacing-4'>
                <SDKTextButton onClick={() => setShowCountriesModal(true)}>
                  <SDKText weight={2}>
                    <SDKIcon code='edit' />
                  </SDKText>
                </SDKTextButton>
              </div>
            </div>
            {showCopyWarning && (
              <>
                <Divider />
                <div className='row warning-top-panel'>
                  <div className='ap-flex align-items-center'>
                    <IconWithTooltip
                      code='circle-warning'
                      content={showCopyWarningText()}
                      iconClassName='ap-text-color-text-primary'
                    />
                    <SDKText className='ap-text-color-text-primary'>
                      <>{showCopyWarningText()}</>
                    </SDKText>
                  </div>
                </div>
              </>
            )}
          </>
        }
      >
        <SDKTabber
          tabs={PROJECT_TOOLS_TABS}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          type='filled'
        />
        {showCountriesModal && project?.projectId && (
          <CustomCountriesModal setShowModal={setShowCountriesModal} type={activeIndex} />
        )}
        <Divider />
      </SDKPanel>
      {PROJECT_TOOLS_TABS[activeIndex]?.component}
    </div>
  )
}

export default ProjectTools
