import { EQuestionToDelete } from '../../../modules/questionnaires/pages/Questions/components/ListHeaderButtons/constants'
import api from '../../api'
import { CountrySnapshotUrl, FiscalYearUrl } from '../../constants/urls'

export const deleteQuestionRequest = async (
  questionId: number,
  yearId: number,
  categoryId: number,
  subcategoryId: number,
  questionType: EQuestionToDelete
): Promise<string> => {
  return (
    await api.delete(
      `${FiscalYearUrl.Years}/${yearId}/${CountrySnapshotUrl.Categories}/${categoryId}/${CountrySnapshotUrl.Subcategories}/${subcategoryId}/${CountrySnapshotUrl.Questions}/${CountrySnapshotUrl.Type}/${questionType}/${questionId}`
    )
  ).data
}
