import { ESightlineRedirectAction, TEMPLATE_ID } from '../../../modules/project/constants'
import { ResponseCode } from '../../../shared/types/enums'
import { authorizeSightline } from '../../../shared/utils/authorizeSightline'
import api from '../../api'
import { CalculationUrl } from '../../constants/urls'

export const getPowerBICalculationResultByTemplateIdFromSightline = async (
  calculationId: number,
  templateId: number,
  rejectWithValue: any
): Promise<string> => {
  try {
    const response = await api.get(
      `${CalculationUrl.Calculation}/${calculationId}/${CalculationUrl.Reports}/${CalculationUrl.PowerBI}/${templateId}/${CalculationUrl.Sightline}`
    )
    return response.data
  } catch (error: any) {
    if (error.code === ResponseCode.Unauthorized) {
      authorizeSightline(
        error.message,
        ESightlineRedirectAction.GetPowerBICalculationResultByTemplateIdFromSightline
      )
      localStorage.setItem(TEMPLATE_ID, String(templateId))
      return ''
    } else {
      return rejectWithValue(error.message)
    }
  }
}
