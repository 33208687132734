import type { Control, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormRadioButton } from '../../../../../../components/Form/FormRadioButton'
import { SDKText } from '../../../../../../components/SDK/Text'
import { EFilterProjectFields, IFormValues } from '../../../../../../schemas/useFilterProjectSchema'
import { ESortOption, ESortOptionValue } from '../../constants'

interface IProjectSortingProps {
  sortBy: ESortOption
  control: Control<FieldValues, IFormValues>
  setSorting: (option: ESortOptionValue, value: ESortOption) => void
}

export const ProjectSorting = ({ sortBy, control, setSorting }: IProjectSortingProps) => {
  const { t } = useTranslation('myprojects')
  const OPTIONS = [
    {
      value: ESortOptionValue.asc,
      label: t(`filter.sortBy.${sortBy}.asc`),
      onClick: () => setSorting(ESortOptionValue.asc, sortBy)
    },
    {
      value: ESortOptionValue.desc,
      label: t(`filter.sortBy.${sortBy}.desc`),
      onClick: () => setSorting(ESortOptionValue.desc, sortBy)
    }
  ]

  return (
    <>
      <div className='ap-p-spacing-4'>
        <SDKText weight={2}>{t(`filter.sortBy.${sortBy}.title`)}</SDKText>
      </div>
      <FormRadioButton
        options={OPTIONS}
        control={control}
        name={EFilterProjectFields[sortBy]}
        withFlex
        required={false}
      />
    </>
  )
}
