import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { LoadingOverlay } from '../../../../../../components/SDK/LoadingOverlay'
import { useApp } from '../../../../../../context/app.context'
import { usePricingTab } from '../../../../../../context/pricingTab.context'
import { LoadingStatus } from '../../../../../../shared/types/enums'
import { ChildScopeModal } from '../../modals/ChildScopeModal'
import { ParentScopeModal } from '../../modals/ParentScopeModal'
import { updateChildScopeToggle, updateParentScopeToggle } from '../../modals/store/selectors'

const TabWrapper = ({ setGroundTotal }: { setGroundTotal: (g: number) => void }) => {
  const { sendNotification } = useApp()
  const {
    childScope,
    isChildScopeModalVisible,
    isParentScopeModalVisible,
    scopeDefinitionLoading,
    parentScope,
    refreshData,
    setChildScopeModalVisible,
    setParentScopeModalVisible,
    tabContent
  } = usePricingTab()
  const resource = useSelector(tabContent.selector)
  const Component = tabContent.tabComponent
  const { loading: childLoading } = useSelector(updateChildScopeToggle)
  const { loading: parentLoading } = useSelector(updateParentScopeToggle)

  useEffect(() => {
    if (resource?.error && resource.error?.length > 0) {
      sendNotification({
        message: resource.error,
        status: 'error'
      })
    }
  }, [resource?.error])

  const isLoading =
    !resource ||
    (resource.loading === LoadingStatus.Pending &&
      childLoading === LoadingStatus.Idle &&
      parentLoading === LoadingStatus.Idle) ||
    scopeDefinitionLoading

  useEffect(() => {
    if (resource.loading === LoadingStatus.Succeeded) {
      setGroundTotal(resource.data?.quotes?.grandTotal || 0)
    } else {
      setGroundTotal(0)
    }
  }, [resource.loading])

  return (
    <>
      {isLoading && <LoadingOverlay />}
      {resource.data && (
        <>
          <Component />

          {isChildScopeModalVisible && (
            <ChildScopeModal
              onCloseModal={(params?: { refreshData: boolean }) => {
                setChildScopeModalVisible(false)
                if (params?.refreshData) {
                  refreshData()
                }
              }}
              scope={childScope}
              visible={isChildScopeModalVisible}
            />
          )}

          {isParentScopeModalVisible && (
            <ParentScopeModal
              onCloseModal={(params?: { refreshData: boolean }) => {
                setParentScopeModalVisible(false)

                if (params?.refreshData) {
                  refreshData()
                }
              }}
              scope={parentScope}
              visible={isParentScopeModalVisible}
            />
          )}
        </>
      )}
    </>
  )
}

export default TabWrapper
