import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useApp } from '../../../../../context/app.context'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../store'
import { ESightlineRedirectAction, SIGHTLINE_ACTION } from '../../../constants'
import {
  clearExportProjectFromSightlineCS,
  exportProjectFromSightlineCS
} from '../../../store/actions'
import { exportProjectDataFromSightlineCS } from '../../../store/selectors'

export const useExportProjectFromSightline = (setShowLoadingModal: (value: boolean) => void) => {
  const { t } = useTranslation('myprojects')
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { loading, error } = useSelector(exportProjectDataFromSightlineCS)
  const { project } = useParams()

  const onExportProjectFromSightline = () => {
    setShowLoadingModal(true)
    if (project) {
      dispatch(exportProjectFromSightlineCS({ projectId: Number(project) }))
    } else {
      sendNotification({
        message: t('projectDetail.projectTabs.tara.results.exportErrorText'),
        status: 'error'
      })
    }
  }

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded) {
      setShowLoadingModal(false)
      sendNotification({
        message: t('projectDetail.projectTabs.tara.results.exportSuccessText'),
        status: 'success'
      })
    } else if (loading === LoadingStatus.Failed) {
      setShowLoadingModal(false)
      sendNotification({
        message: error || t('projectDetail.projectTabs.tara.results.exportErrorText'),
        status: 'error'
      })
    }
    dispatch(clearExportProjectFromSightlineCS())
  }, [loading])

  useEffect(() => {
    const action = localStorage.getItem(SIGHTLINE_ACTION)
    if (action === ESightlineRedirectAction.ExportProjectDataFromSightlineCS) {
      if (project) {
        onExportProjectFromSightline()
      }
      localStorage.removeItem(SIGHTLINE_ACTION)
    }
  }, [])

  return { onExportProjectFromSightline }
}
