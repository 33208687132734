import { useTranslation } from 'react-i18next'
import { Divider } from '../../../../../../components/Divider'
import type { IDiscountData } from '../../types/inputsTab'
import type { IParentScopeYearTotal } from '../../types/scope'
import { DiscountPercentageView } from '../DiscountPercentageView'
import { FeeQuote } from '../FeeQuote'
import { ERole, ScopeWrapper } from '../ScopeWrapper'
import { ScopesWrapper } from '../ScopesWrapper'
import styles from './index.module.scss'

interface IDiscountResult {
  data: IDiscountData
}

const FEE_QUOTE_PROPS = {
  withoutButtons: true,
  gridValues: [8, 4],
  flexStyles: 'justify-content-between'
}

export const DiscountResult = ({ data }: IDiscountResult) => {
  const { t } = useTranslation('pricing')
  const totalFee: IParentScopeYearTotal[] | undefined = data?.discounts.discountsPerYear.map(
    el => ({ yearName: el.yearName, totalValue: el.afterDiscount })
  )
  const discountAmountPerFY: IParentScopeYearTotal[] | undefined =
    data?.discounts.discountsPerYear.map(el => ({
      yearName: el.yearName,
      totalValue: el.discountAmount
    }))
  const amountBeforeDiscount: IParentScopeYearTotal[] | undefined =
    data?.discounts.discountsPerYear.map(el => ({
      yearName: el.yearName,
      totalValue: el.beforeDiscount
    }))

  return (
    <div>
      <ScopesWrapper>
        <ScopeWrapper
          classes='ap-pt-spacing-5 ap-mt-spacing-3 ap-border-color-background-white'
          title=''
          fees={amountBeforeDiscount}
          role={ERole.Parent}
          withoutFeesResult={true}
          {...FEE_QUOTE_PROPS}
          discountModule
        >
          <>
            {amountBeforeDiscount && (
              <FeeQuote
                customTitle={t('tabs.inputs.discount.totalFeeBeforeDiscount')!}
                fees={amountBeforeDiscount}
                textType={'body'}
                textWeight={1}
                textClass='ap-text-neutral-14'
                {...FEE_QUOTE_PROPS}
                discountModule
              />
            )}
            <DiscountPercentageView
              data={data}
              totalDiscount={data?.discounts.totalDiscount}
              discountPerFiscalYear={data?.discounts.discountPerFiscalYear}
            />
            {discountAmountPerFY && (
              <FeeQuote
                customTitle={t('tabs.inputs.discount.discountAmount')!}
                fees={discountAmountPerFY}
                textType='body'
                textWeight={1}
                textClass={`${styles['discount-amount-text']} ap-text-color-text-primary`}
                {...FEE_QUOTE_PROPS}
                discountModule
                showQuotesWithFractionDigits
              />
            )}
            {totalFee && (
              <>
                <Divider />
                <FeeQuote
                  fees={totalFee}
                  customTitle={t('tabs.inputs.discount.totalFeeAfterDiscount')!}
                  {...FEE_QUOTE_PROPS}
                  discountModule
                  showQuotesWithFractionDigits
                />
              </>
            )}
          </>
        </ScopeWrapper>
      </ScopesWrapper>
    </div>
  )
}
