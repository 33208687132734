import { EAnswerTypeForEdit } from '../../modules/countriesSnapshot/pages/AddEditQuestion/constants/answerType'
import { TCalendarQuestionOptions } from '../../modules/countriesSnapshot/types'
import { IAnswer, ICalendarAnswerResponse } from '../../modules/project/types/projectResults'
import { IconWithTooltip } from '../IconWithTooltip'
import { AdditionalInformationContainer } from '../QuestionWithAnswersCard/components/AdditionalInformationContainer'
import { AnswerText } from '../QuestionWithAnswersCard/components/AnswerText'
import { SDKBreadcrumbsWithText } from '../SDK/BreadcrumbsWithText'
import { SDKText } from '../SDK/Text'
import { SDKTextButton } from '../SDK/TextButton'

export const renderBreadcrumbsWithTextCell = (breadcrumbs: string[], text: string) => {
  if (!text) return '-'
  return (
    <SDKBreadcrumbsWithText
      breadcrumbs={breadcrumbs}
      text={{
        children: text,
        weight: 2,
        className: 'ap-text-neutral-20'
      }}
    />
  )
}
export const renderCell = <T extends {}>(row: T, field: string) => {
  if (!row[field as keyof T]) return <SDKText className={field.toLocaleLowerCase()}>-</SDKText>
  return <SDKText className={field.toLocaleLowerCase()}>{String(row[field as keyof T])}</SDKText>
}

export const renderAdditionalInformationCell = (openText: string, url: string) => {
  if (!openText && !url) return <SDKText>-</SDKText>
  else return <AdditionalInformationContainer openText={openText} url={url} type='body' />
}
type TRenderAnswerCell = {
  field: string
  answerText: string | null
  type: EAnswerTypeForEdit
  calendarQuestionOptions?: TCalendarQuestionOptions
  calendarAnswerResponse?: ICalendarAnswerResponse
  id: number
  selectedAnswers: IAnswer[]
  offset: { days: number; months: number }
  isNotAnswered: boolean
  notAsweredText: string
  isEnabled?: boolean
}
export const renderAnswerCell = ({
  field,
  answerText,
  type,
  calendarQuestionOptions,
  calendarAnswerResponse,
  id,
  selectedAnswers,
  offset,
  isNotAnswered,
  notAsweredText,
  isEnabled
}: TRenderAnswerCell) => {
  if (!answerText && !selectedAnswers && type !== EAnswerTypeForEdit.Calendar)
    return <SDKText className={field}>{notAsweredText}</SDKText>
  else {
    return (
      <SDKText className={field}>
        <AnswerText
          answerText={answerText}
          type={type}
          calendarQuestionOptions={calendarQuestionOptions}
          calendarAnswerResponse={calendarAnswerResponse}
          daysOffset={offset.days}
          monthsOffset={offset.months}
          id={id}
          isNotAnswered={isNotAnswered}
          selectedAnswers={selectedAnswers}
          isEnabled={isEnabled}
        />
      </SDKText>
    )
  }
}

export const renderEditCell = (
  onEdit: () => void,
  isEditable: boolean,
  isAdminReviewRequired: boolean,
  adminReviewRequiredTooltip: string,
  systemAnsweredQuestionTooltip: string,
  editTooltip: string
) => {
  return (
    <>
      {isAdminReviewRequired ? (
        <IconWithTooltip
          code='leader'
          iconClassName='ap-text-color-text-warning'
          content={adminReviewRequiredTooltip}
        />
      ) : (
        <>
          {isEditable ? (
            <SDKTextButton onClick={onEdit} className='ap-text-color-text-primary'>
              <IconWithTooltip code='edit' content={editTooltip} />
            </SDKTextButton>
          ) : (
            <IconWithTooltip
              code='confirmed'
              iconClassName='ap-text-color-text-success'
              content={systemAnsweredQuestionTooltip}
            />
          )}
        </>
      )}
    </>
  )
}
