import { useTranslation } from 'react-i18next'
import { BlankPage } from '../../components/BlankPage'
import { ButtonsForUnlogged } from '../../components/ButtonsForUnlogged'
import { SDKParagraph } from '../../components/SDK/Paragraph'

const SignOut = () => {
  const { t } = useTranslation()

  return (
    <BlankPage
      middleContent={
        <>
          <div className='row'>
            <SDKParagraph className='text-align-center ap-mb-spacing-4' type='heading'>
              {t('signOut.infoText')}
            </SDKParagraph>
          </div>

          <ButtonsForUnlogged />
        </>
      }
    ></BlankPage>
  )
}

export default SignOut
