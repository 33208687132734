import type { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormInput } from '../../../../../../components/Form/FormInput'
import { FormRadioButton } from '../../../../../../components/Form/FormRadioButton'
import { SDKParagraph } from '../../../../../../components/SDK/Paragraph'
import { EEditUserFields, IFormValues } from '../../../../../../schemas/useAddEditUserSchema'
import type { IOption } from '../../../../../../types/form'
import { EModalMode } from '../../const'

import './index.scss'

interface IAddEditUserForm {
  control: Control<IFormValues, any>
  isActiveRadioList: IOption[]
  isAdministratorRadioList: IOption[]
  isServiceAccountRadioList: IOption[]
  modalMode: EModalMode
}

export const AddEditUserForm = ({
  control,
  isActiveRadioList,
  isAdministratorRadioList,
  isServiceAccountRadioList,
  modalMode
}: IAddEditUserForm) => {
  const { t } = useTranslation('users')

  const emailInputField = (
    <div className='row'>
      <div className='col'>
        <FormInput
          name={EEditUserFields.email}
          type='text'
          label={t('userForm.email.label')!}
          required={true}
          className='ap-mb-spacing-5'
          control={control}
          disabled={modalMode !== EModalMode.CreateServiceAccount}
        />
      </div>
    </div>
  )

  return (
    <div className='ap-container add-edit-user-form'>
      <div className='form-container'>
        {
          // Edit
        }
        {(modalMode === EModalMode.EditServiceAccount || modalMode === EModalMode.EditUser) && (
          <>
            <div className='row'>
              <div className='col'>
                <FormInput
                  name={EEditUserFields.fullName}
                  type='text'
                  label={t('userForm.fullName.label')!}
                  required={true}
                  className='ap-mb-spacing-5'
                  control={control}
                  disabled={true}
                />
              </div>
            </div>

            {emailInputField}

            <div className='row'>
              <div className='col'>
                <SDKParagraph className='ap-mb-spacing-3' type='body' weight={2}>
                  {t('userForm.isAdministrator.label')}
                </SDKParagraph>

                <FormRadioButton
                  name={EEditUserFields.isAdministratorOption}
                  required={true}
                  className='ap-mb-spacing-5'
                  options={isAdministratorRadioList}
                  withFlex
                  control={control}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                <SDKParagraph className='ap-mb-spacing-3' type='body' weight={2}>
                  {t('userForm.isServiceAccount.label')}
                </SDKParagraph>

                <FormRadioButton
                  name={EEditUserFields.isServiceAccountOption}
                  required={true}
                  className='ap-mb-spacing-5'
                  options={isServiceAccountRadioList}
                  withFlex
                  control={control}
                  disabled
                />
              </div>

              <div className='row'>
                <div className='col'>
                  <SDKParagraph className='ap-mb-spacing-3' type='body' weight={2}>
                    {t('userForm.isActive.label')}
                  </SDKParagraph>

                  <FormRadioButton
                    name={EEditUserFields.isActiveOption}
                    required={true}
                    className='ap-mb-spacing-5'
                    options={isActiveRadioList}
                    withFlex
                    control={control}
                    disabled={modalMode === EModalMode.EditUser}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {
          // CreateServiceAccount
        }
        {modalMode === EModalMode.CreateServiceAccount && emailInputField}
      </div>
    </div>
  )
}
