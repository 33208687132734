import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Url } from '../../../../../constants/urls'
import { useProject } from '../../../../../context/project.context'
import { useAppDispatch } from '../../../../../store'
import * as actions from '../../../store/actions'
import * as selectors from '../../../store/selectors'

export const useAddEditProject = (projectId: number | null) => {
  const navigate = useNavigate()
  const { refreshProject, refreshProjects } = useProject()
  const dispatch = useAppDispatch()
  const modal = useSelector(projectId ? selectors.updateProject : selectors.addProject)

  const cleanUp = () => {
    dispatch(projectId ? actions.clearUpdateProject() : actions.clearAddProject())
    dispatch(actions.clearGetProjectAssignedUsers())
    dispatch(actions.clearGetProjectAvailableUsers())

    if (projectId) {
      refreshProject()
    } else {
      refreshProjects()
    }
  }

  const onClose = (id?: number) => {
    cleanUp()

    navigate(
      projectId
        ? `${Url.MyProjectsPage}/${projectId}${Url.ProjectSettingsPage}`
        : id
        ? `${Url.MyProjectsPage}/${id}#CS-questions`
        : Url.MyProjectsPage
    )
  }

  return { cleanUp, modal, onClose }
}
