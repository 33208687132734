import type { NoticeProps } from '@appkit4/react-components/notification'
import { Notice } from '@appkit4/react-components/notification'
import { useState } from 'react'
import './index.scss'

export const SDKNotice = ({ message, title, status, duration }: NoticeProps) => {
  const [visible, setVisible] = useState(true)

  return (
    <>
      {visible ? (
        <Notice
          className='sdk-notice'
          title={title}
          message={message}
          status={status}
          duration={duration}
          onClose={() => setVisible(false)}
        ></Notice>
      ) : null}
    </>
  )
}
