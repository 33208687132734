import { QuestionWithAnswersCard } from '../../../../components/QuestionWithAnswersCard'
import { useApp } from '../../../../context/app.context'
import { EAnswerTypeForEdit } from '../../../countriesSnapshot/pages/AddEditQuestion/constants/answerType'
import type { IProjectAnswerByCountry, ProjectAnswerByQuestion } from '../../types/projectResults'
import { ResultsTabTable } from '../ResultsTabTable'
import './index.scss'

interface IAccordionBodyProps {
  answers: IProjectAnswerByCountry[] | ProjectAnswerByQuestion[]
  activeOption: number
  id?: number
  type?: EAnswerTypeForEdit
  yearId?: number
}

export const ResultTabAccordionContent = ({
  answers,
  activeOption,
  id,
  type,
  yearId
}: IAccordionBodyProps) => {
  const {
    appContext: {
      style: { isMobile }
    }
  } = useApp()

  const setTitle = (answer: IProjectAnswerByCountry | ProjectAnswerByQuestion) =>
    activeOption === 0 ? (answer as IProjectAnswerByCountry).questionText : answer.countryName

  return (
    <>
      {isMobile ? (
        answers.map((answer, answerIndex) => (
          <QuestionWithAnswersCard
            key={`result-answer-container-${answer.answerId || answerIndex}`}
            answer={answer}
            yearId={yearId}
            id={id || (answer as IProjectAnswerByCountry).questionId}
            title={setTitle(answer)}
            type={type || (answer as IProjectAnswerByCountry).type}
            viewWithBreadcrumbs={activeOption === 0}
            viewWithTooltip={activeOption === 1}
            calendarAnswerResponse={answer.calendarAnswerResponse}
            breadcrumbs={
              activeOption === 0
                ? [
                    (answer as IProjectAnswerByCountry).category.name,
                    (answer as IProjectAnswerByCountry).subcategory.name
                  ]
                : []
            }
          />
        ))
      ) : (
        <ResultsTabTable
          activeOption={activeOption}
          answers={answers}
          type={type}
          id={id}
          yearId={yearId}
        />
      )}
    </>
  )
}
