import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { SDKIcon } from '../SDK/Icon'
import { SDKPanel } from '../SDK/Panel'
import { SDKText } from '../SDK/Text'
import { SDKTextButton } from '../SDK/TextButton'
import './index.scss'

interface INavigationTileItem {
  action?: () => void
  active?: boolean
  additionalInfo?: JSX.Element
  component?: JSX.Element
  icon: string
  middleSection?: JSX.Element
  name: string
  url?: string
}

interface INavigationTileGrid {
  list: INavigationTileItem[]
}

export const NavigationTileGrid = ({ list }: INavigationTileGrid) => {
  const navigate = useNavigate()

  const chevronIcon = (
    <SDKTextButton>
      <SDKIcon code='right-chevron'></SDKIcon>
    </SDKTextButton>
  )

  const tileIcon = (icon: string) => (
    <div className='col col-icon ap-text-color-text-link-primary ap-px-spacing-4 '>
      <SDKIcon code={icon} />
    </div>
  )

  const name = (section: INavigationTileItem) => (
    <>
      {section.component ? (
        section.component
      ) : (
        <div className='col col-informations'>
          <SDKText weight={2}>{section.name}</SDKText>
        </div>
      )}
    </>
  )

  const renderContent = (section: INavigationTileItem) => {
    if (section.additionalInfo || section.middleSection) {
      return (
        <div className='tile-with-additional-info'>
          <div
            className={classNames(
              'icon-and-name',
              section.middleSection && 'row navigation-tile-section'
            )}
          >
            {tileIcon(section.icon)}

            {name(section)}
          </div>
          {section.middleSection && <div className='col-info'>{section.middleSection}</div>}
          <div className='info-and-chevron'>
            {section.additionalInfo && <div className='col-info'>{section.additionalInfo}</div>}
            <div className='col-chevron'>{chevronIcon}</div>
          </div>
        </div>
      )
    } else {
      return (
        <>
          {tileIcon(section.icon)}

          <div className='row col'>
            {name(section)}
            <div className='col col-button ap-px-spacing-1'>{chevronIcon}</div>
          </div>
        </>
      )
    }
  }

  return (
    <div className='ap-grid ap-mx-spacing-1 ap-mt-spacing-7 navigation-tile-grid'>
      {list?.length &&
        list.map(
          (section, i) =>
            section.active !== false && (
              <SDKPanel
                key={`navigation-tile-${i}`}
                onClick={() => {
                  if (section.url) {
                    navigate(section.url)
                  }

                  if (section.action) {
                    section.action()
                  }
                }}
                className='single-navigation-tile-card pointer'
              >
                <div className='navigation-tile-sections g-col-12'>
                  <div className='ap-container ap-px-spacing-1 ap-mx-spacing-1 gx-0'>
                    <div className='row navigation-tile-section'>{renderContent(section)}</div>
                  </div>
                </div>
              </SDKPanel>
            )
        )}
    </div>
  )
}
