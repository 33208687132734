import { useTranslation } from 'react-i18next'
import { ReactComponent as Picture } from '../../../../../../assets/svg/surveyAndAssessment.svg'
import { SDKPanel } from '../../../../../../components/SDK/Panel'
import { SDKParagraph } from '../../../../../../components/SDK/Paragraph'

import styles from './index.module.scss'

export const EmptyState = () => {
  const { t } = useTranslation('fiscalYearDetails')

  return (
    <SDKPanel>
      <div className={styles['empty-state']}>
        <SDKParagraph className='ap-mb-spacing-4'>
          {t('gateQuestions.list.emptyState.noQuestions')}
        </SDKParagraph>
        <Picture />
      </div>
    </SDKPanel>
  )
}
