import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../../store'
import { getYears } from '../../../../fiscalYearSetup/store/actions'
import * as selectors from '../../../../fiscalYearSetup/store/selectors'
import { FiscalYearBase } from '../../../../fiscalYearSetup/types'

export const useReports = () => {
  const dispatch = useAppDispatch()
  const { data: years } = useSelector(selectors.getYears)
  const mappedYears = years?.map((y: FiscalYearBase) => ({
    value: String(y.yearId),
    label: y.name
  }))

  useEffect(() => {
    if (!years.length) {
      dispatch(getYears())
    }
  }, [])

  return { mappedYears }
}
