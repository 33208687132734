import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SDKIcon } from '../../../../components/SDK/Icon'
import { SDKModal } from '../../../../components/SDK/Modal'
import { SDKText } from '../../../../components/SDK/Text'
import { SDKTextButton } from '../../../../components/SDK/TextButton'
import { useAppDispatch } from '../../../../store'
import { deleteCategory, deleteSubcategory } from '../../store/actions'
import { TCategory, TSubcategory } from '../../types'
import './index.scss'

export enum EListType {
  Category = 'Category',
  Subcategory = 'Subcategory'
}

interface IListItemProps {
  data: TCategory | TSubcategory
  id: number
  type: EListType
  yearId: number
  openEditModal: (i: number, o?: number) => void
  optionalId?: number
}

export const ListItem = ({ data, id, yearId, openEditModal, optionalId, type }: IListItemProps) => {
  const { t } = useTranslation('countriesSnapshot')
  const dispatch = useAppDispatch()
  const [showModal, setShowModal] = useState(false)

  const submitDelete = () => {
    setShowModal(false)
    if (type === EListType.Category) dispatch(deleteCategory({ yearId, categoryId: id }))
    if (type === EListType.Subcategory && optionalId)
      dispatch(deleteSubcategory({ yearId, categoryId: optionalId, subcategoryId: id }))
  }

  return (
    <div className='list-item'>
      <SDKText weight={2}>{data.name}</SDKText>
      <div className='icons ap-text-color-text-body'>
        <div className='edit pointer' onClick={() => openEditModal(id, optionalId)}>
          <SDKIcon code='edit' />
          <SDKText className='edit-txt'>{t('edit')}</SDKText>
        </div>
        <SDKTextButton onClick={() => setShowModal(true)}>
          <SDKIcon code='delete' className='ap-text-color-text-error ap-button p-0' />
        </SDKTextButton>
      </div>
      <SDKModal
        onCancel={() => setShowModal(false)}
        onSubmit={submitDelete}
        submitLabel={t(`delete${type}.submit`)!}
        title={t(`delete${type}.title`)!}
        visible={showModal}
        withRequired={false}
        withCancel
      >
        {t(`delete${type}.text`)!}
      </SDKModal>
    </div>
  )
}
