import { useTranslation } from 'react-i18next'
import { array, number, object, string } from 'yup'
import { useProjectFromUrl } from '../hooks/useProjectFromUrl'
import { useWizardStepsKeys } from '../modules/myprojects/components/AddEditProject/components/AddEditProjectModal/hooks/useWizardSteps'
import type { IAddProjectForm } from '../modules/myprojects/types'
import { EProjectFields } from '../modules/myprojects/types'

export type IFormValues = IAddProjectForm

export const useAddEditProjectSchema = () => {
  const { t } = useTranslation('myprojects')
  const wizardSteps = useWizardStepsKeys()
  const { project } = useProjectFromUrl()

  const defaultValues: IFormValues = {
    [EProjectFields.CountryIds]: project?.countryIds.map(countryId => String(countryId)) || [],
    [EProjectFields.Description]: project?.description || '',
    [EProjectFields.Name]: project?.name || '',
    [EProjectFields.OutputCurrencyId]: String(project?.outputCurrencyId) || 0,
    [EProjectFields.OutputCurrencySymbol]: project?.outputCurrencySymbol || '',
    [EProjectFields.ProjectId]: project?.projectId || undefined,
    [EProjectFields.UserIds]: project?.userIds.map(userId => String(userId)) || [],
    [EProjectFields.TpEngagementId]: project?.tpEngagementId || null,
    [EProjectFields.TpEngagementName]: project?.tpEngagementName || '',
    [EProjectFields.YearId]: String(project?.yearId) || '0',
    [EProjectFields.YearName]: project?.yearName || 0
  }

  const validationSchemas = {
    [wizardSteps[0]]: object()
      .shape({
        [EProjectFields.Name]: string().required(t('projectForm.errors.name')!),
        [EProjectFields.OutputCurrencyId]: number()
          .moreThan(0)
          .required(t('projectForm.errors.outputCurrency')!),
        [EProjectFields.YearId]: number().moreThan(0).required(t('projectForm.errors.year')!)
      })
      .required(),

    [wizardSteps[1]]: object()
      .shape({
        [EProjectFields.CountryIds]: array().min(1).required(t('projectForm.errors.country')!)
      })
      .required()
  }

  return {
    defaultValues,
    validationSchemas
  }
}
