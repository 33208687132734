import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Layout } from '../../../../components/Layout'
import { SDKLoading } from '../../../../components/SDK/Loading'
import { useApp } from '../../../../context/app.context'
import { LoadingStatus } from '../../../../shared/types/enums'
import { useAppDispatch } from '../../../../store'
import * as actions from '../../store/actions'
import { getLegalDocumentsPublic } from '../../store/selectors'

export const CookiePolicy = () => {
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { data, loading, error } = useSelector(getLegalDocumentsPublic)

  useEffect(() => {
    dispatch(actions.getLegalDocumentsPublic())
  }, [])

  useEffect(() => {
    if (loading === LoadingStatus.Failed && error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
    }
  }, [error])

  return (
    <Layout>
      <div className='cookie-policy-page ap-m-spacing-5'>
        {loading === LoadingStatus.Pending ? (
          <SDKLoading />
        ) : (
          data.map(doc => (
            <div key={doc.identifier} dangerouslySetInnerHTML={{ __html: doc.content }}></div>
          ))
        )}
      </div>
    </Layout>
  )
}
