import { useEffect, useMemo, useState } from 'react'
import type { FormState } from 'react-hook-form'
import type { IFormValues as ICountryFormValues } from '../schemas/useAddEditCountrySchema'
import type { IFormValues as IProjectFormValues } from '../schemas/useAddEditProjectSchema'
import type { IFormValues as IUserFormValues } from '../schemas/useAddEditUserSchema'
import type { IFormValues as IParentScopeFormValues } from '../schemas/useScopeDefinitionSchema'

type IFormValues =
  | ICountryFormValues
  | IProjectFormValues
  | IUserFormValues
  | IParentScopeFormValues

interface ISubmitButtonConditions {
  mustBeDirty?: boolean
  mustBeValid?: boolean
}

const DEFAULT_CONDITIONS: ISubmitButtonConditions = {
  mustBeDirty: true,
  mustBeValid: true
}

export const useSubmitButton = ({
  formState,
  conditions
}: {
  conditions?: ISubmitButtonConditions
  formState: FormState<IFormValues>
}) => {
  const [disabled, setDisabled] = useState(true)

  const submitConditions: ISubmitButtonConditions = useMemo(
    () => ({ ...DEFAULT_CONDITIONS, ...conditions }),
    [conditions]
  )

  useEffect(() => {
    setDisabled(
      (submitConditions.mustBeValid ? !formState.isValid : false) ||
        (submitConditions.mustBeDirty ? !formState.isDirty : false)
    )
  }, [formState.isDirty, formState.isValid])

  return { disabled }
}
