import type { InputProps } from '@appkit4/react-components/field'
import classNames from 'classnames'
import type { Control, FieldValues, Path } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { SDKIcon } from '../../SDK/Icon'
import { SDKInput } from '../../SDK/Input'
import { SDKTooltip } from '../../SDK/Tooltip'
import './index.scss'

interface IFormInputProps<T extends FieldValues> extends InputProps {
  label: string
  name: Path<T>
  control: Control<T, any>
  suffixComponent?: JSX.Element
  suffixTooltip?: string
  suffixIcon?: boolean
  errorMessage?: string
}

type Props<T extends FieldValues> = IFormInputProps<T>

export const FormInput = <T extends FieldValues>({
  label,
  control,
  name,
  suffixComponent,
  suffixTooltip,
  suffixIcon,
  errorMessage,
  className,
  ...props
}: Props<T>) => {
  const suffixWithIcon = <SDKIcon code='information' />
  const suffixWithTooltip = suffixTooltip ? (
    <SDKTooltip hasSwitch={true} distance={4} content={suffixTooltip}>
      <button
        data-tooltip='true'
        tabIndex={0}
        role='button'
        aria-label='tooltip'
        className='ap-field-icon-btn'
        aria-describedby='field-tooltip'
      >
        {suffixIcon}
      </button>
    </SDKTooltip>
  ) : (
    <></>
  )

  return (
    <Controller
      name={name as Path<T>}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <SDKInput
          className={classNames([suffixIcon && 'suffix-icon', className])}
          title={label}
          errorNode={
            <div className='ap-field-email-validation-error'>
              {error && errorMessage ? errorMessage : error?.message}
            </div>
          }
          error={!!error}
          {...field}
          {...props}
          id={name}
          suffix={suffixComponent || (suffixIcon ? suffixWithIcon : suffixWithTooltip)}
        />
      )}
    />
  )
}
