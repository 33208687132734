import { ITemplateCalculation } from '../../../modules/project/types/projectCalculation'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const uploadEntitiesFromTemplateRequest = async (
  inputFile: File,
  projectId: number
): Promise<{ module: ITemplateCalculation; countriesSkipped: string[] }> => {
  const data = new FormData()
  data.append('importFile', inputFile)
  return (
    await api.post(
      `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/${ProjectsUrl.Modules}/${ProjectsUrl.LocalFile}/${ProjectsUrl.Entities}`,
      data,
      {
        ['Content-Type']: 'multipart/form-data'
      }
    )
  ).data
}
