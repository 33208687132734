import { ITemplateCalculation } from '../../../modules/project/types/projectCalculation'
import api from '../../api'
import { CalculationUrl } from '../../constants/urls'

export const uploadTemplateDataWithCalculation = async (
  dateOnly: boolean,
  inputFile: File,
  projectId: number
): Promise<ITemplateCalculation> => {
  const data = new FormData()
  data.append('inputFile', inputFile)
  return (
    await api.post(`${CalculationUrl.Calculation}/${projectId}?dateOnly=${dateOnly}`, data, {
      ['Content-Type']: 'multipart/form-data'
    })
  ).data
}
