import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Layout } from '../../components/Layout'
import { SDKModal } from '../../components/SDK/Modal'
import { useAppDispatch } from '../../store'
import { AddEditRegionModal } from './components/AddEditRegionModal'
import { RegionsList } from './components/RegionsList'
import * as actions from './store/actions'
import { useAddEditRegionModal } from './utils/useAddEditRegionModal'
import { useDeleteRegion } from './utils/useDeleteRegion'

const Regions = () => {
  const { t } = useTranslation('regions')
  const dispatch = useAppDispatch()
  const [visibleRegionModal, setVisibleRegionModal] = useState(false)
  const [regionId, setRegionId] = useState<number | null>(null)
  const [regionContainer, setRegionContainer] = useState('')
  const {
    onDeleteRegion,
    onSubmitDelete,
    visibleDeleteRegionModal,
    setVisibleDeleteRegionModal,
    regionName
  } = useDeleteRegion()
  useAddEditRegionModal(regionId)

  const onAddRegion = (region: string) => {
    dispatch(actions.clearAddRegion())
    setRegionContainer(region)
    setVisibleRegionModal(true)
    setRegionId(null)
  }

  const onEditRegion = (region: string, id: number) => {
    dispatch(actions.clearEditRegion())
    setRegionContainer(region)
    setVisibleRegionModal(true)
    setRegionId(id)
  }

  return (
    <Layout>
      <div className='regions-page'>
        <RegionsList
          addRegion={onAddRegion}
          editRegion={onEditRegion}
          deleteRegion={onDeleteRegion}
        />
      </div>
      {visibleRegionModal && (
        <AddEditRegionModal
          visible={visibleRegionModal}
          setVisible={setVisibleRegionModal}
          regionContainer={regionContainer}
          regionId={regionId}
        />
      )}
      {visibleDeleteRegionModal && (
        <SDKModal
          onCancel={() => setVisibleDeleteRegionModal(false)}
          onSubmit={onSubmitDelete}
          submitLabel={t(`deleteRegion.submit`)!}
          title={t(`deleteRegion.title`, { name: regionName })!}
          visible={visibleDeleteRegionModal}
          withRequired={false}
          withCancel
        >
          {t(`deleteRegion.text`)!}
        </SDKModal>
      )}
    </Layout>
  )
}

export default Regions
