import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import i18n from '../../translations'
import { PageBackground } from '../PageBackground'
import { SDKParagraph } from '../SDK/Paragraph'
import style from './index.module.scss'

export const BlankPage = ({
  children,
  middleContent,
  middleText
}: {
  children?: JSX.Element
  middleContent?: JSX.Element
  middleText?: string
}) => {
  const { t } = useTranslation()
  const renderMiddleText =
    middleText && i18n.exists(middleText) ? <SDKParagraph>{t(middleText)}</SDKParagraph> : null

  return (
    <PageBackground
      className={classNames({
        [style.middle]: !!renderMiddleText || !!middleContent
      })}
    >
      <>
        {renderMiddleText}
        {middleContent}
        {children}
      </>
    </PageBackground>
  )
}
