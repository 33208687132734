import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useApp } from '../../../../../../../context/app.context'
import { useProjectFromUrl } from '../../../../../../../hooks/useProjectFromUrl'
import { LoadingStatus } from '../../../../../../../shared/types/enums'
import { DEFAULT_ERROR } from '../../../../../../../shared/utils/store'
import { useAppDispatch } from '../../../../../../../store'
import { clearGetLocalFileResults, getLocalFileResults } from '../../../modals/store/actions'
import * as selectors from '../../../modals/store/selectors'

export const useDownloadResults = () => {
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { project } = useProjectFromUrl()
  const { loading, error } = useSelector(selectors.getLocalFileResults)

  const handleDownloadResults = () => {
    if (project?.projectId) {
      dispatch(getLocalFileResults({ projectId: project.projectId }))
    } else {
      sendNotification({
        message: DEFAULT_ERROR,
        status: 'error'
      })
    }
  }

  useEffect(() => {
    if (loading === LoadingStatus.Failed && error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
    }
  }, [loading])

  useEffect(() => {
    return () => {
      dispatch(clearGetLocalFileResults())
    }
  }, [])

  return { handleDownloadResults, loading }
}
