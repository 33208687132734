import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  DropdownWithAdditionalCheckboxes,
  TData
} from '../../../../../../../components/DropdownWithAdditionalCheckboxes'
import { SDKCheckbox } from '../../../../../../../components/SDK/Checkbox'
import { SDKCheckboxGroup } from '../../../../../../../components/SDK/CheckboxGroup'
import { SDKModal } from '../../../../../../../components/SDK/Modal'
import { SDKText } from '../../../../../../../components/SDK/Text'
import { useAppDispatch } from '../../../../../../../store'
import * as actions from '../../../../../../countriesSnapshot/store/actions'
import { getCategories } from '../../../../../../countriesSnapshot/store/selectors'
import { TFilter } from '../../../../../type'
import { EQuestionStates, EQuestionTypes } from '../constants'

interface IFilterModalProps {
  yearId: number
  isFilterModalVisible: boolean
  setIsFilterModalVisible: (isVisible: boolean) => void
  questionFilters: TFilter
  onApply: (f: TFilter) => void
}

const FilterModal = ({
  yearId,
  isFilterModalVisible,
  setIsFilterModalVisible,
  onApply,
  questionFilters
}: IFilterModalProps) => {
  const { t } = useTranslation('questionnaires')
  const [dropdownData, setDropdownData] = useState<TData[]>([])
  const [tempQuestionFilters, setQuestionFilters] = useState<TFilter>(questionFilters)
  const dispatch = useAppDispatch()
  const { data: categories } = useSelector(getCategories)

  useEffect(() => {
    if (yearId) {
      dispatch(actions.getCategories(yearId))
    }
  }, [yearId])

  useEffect(() => {
    if (categories) {
      const data = categories.map(category => ({
        value: category.categoryId,
        label: category.name,
        children: category.subcategories.map(subcategory => ({
          value: subcategory.subcategoryId,
          label: subcategory.name,
          checked: tempQuestionFilters.subcategoryIds.some(id => id === subcategory.subcategoryId)
        }))
      }))
      setDropdownData(data)
    }
  }, [categories])

  const onSubmit = () => {
    setIsFilterModalVisible(false)
    onApply(tempQuestionFilters)
  }

  const onChange = (values: (string | number)[], section = 1 | 2) => {
    let filters = { ...tempQuestionFilters }
    if (section === 1) {
      filters = { ...filters, types: values }
    } else {
      filters = { ...filters, states: values }
    }
    setQuestionFilters(filters)
  }

  const checkboxContainer = (title: string, section = 1 | 2) => (
    <div className='col col-6 ap-py-spacing-5'>
      <SDKText weight={2}>{t(`filterModal.${title}`)}</SDKText>
      <SDKCheckboxGroup
        defaultValue={section === 1 ? tempQuestionFilters.types : tempQuestionFilters.states}
        onChange={values => onChange(values, section)}
      >
        {Object.entries(section === 1 ? EQuestionTypes : EQuestionStates).map((type, i) => (
          <div className='ap-flex ap-pt-spacing-4' key={`filter-checkbox-${i}-${type[0]}`}>
            <SDKCheckbox
              value={type[0]}
              checked={
                section === 1
                  ? tempQuestionFilters.types.some(questionType => questionType === type[0])
                  : tempQuestionFilters.states.some(questionState => questionState === type[0])
              }
            />
            <SDKText className='ap-text-color-background-tertiary'>{type[1]}</SDKText>
          </div>
        ))}
      </SDKCheckboxGroup>
    </div>
  )

  return (
    <SDKModal
      visible={isFilterModalVisible}
      onCancel={() => {
        setIsFilterModalVisible(false)
      }}
      title={t('filter')!}
      withRequired={false}
      onSubmit={onSubmit}
      submitLabel={t('filterModal.apply')!}
    >
      <div className='ap-container ap-px-spacing-1'>
        <SDKText weight={2}>{t('filterModal.subtitle')!}</SDKText>
        <DropdownWithAdditionalCheckboxes
          data={dropdownData}
          onSelectValue={(ids: number[]) => {
            setQuestionFilters({ ...tempQuestionFilters, subcategoryIds: ids })
          }}
          showSelectAll={false}
          placeholder={t('filterModal.subtitle')!}
          className='ap-py-spacing-3'
        />
        <div className='row'>
          {checkboxContainer('questionTypes', 1)}
          {checkboxContainer('questionStates', 2)}
        </div>
      </div>
    </SDKModal>
  )
}
export default FilterModal
