import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { SDKButton } from '../../../../../../components/SDK/Button'
import { SDKIcon } from '../../../../../../components/SDK/Icon'
import { SDKModal } from '../../../../../../components/SDK/Modal'
import { SDKText } from '../../../../../../components/SDK/Text'
import { useApp } from '../../../../../../context/app.context'
import { LoadingStatus } from '../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../store'
import {
  DATE_ISO_FORMAT_WITH_TIME,
  GMT_TIME,
  formatUtc
} from '../../../../../../utils/dateFormatters'
import { getFullname } from '../../../../../../utils/user'
import * as actions from '../../../../store/actions'
import * as selectors from '../../../../store/selectors'
import type { IQuestionnaireCountry, TFilter } from '../../../../type'
import FilterModal from './FilterModal'

interface IListHeaderButtonsProps {
  country?: IQuestionnaireCountry
  questionFilters: TFilter
  onApply: (q: TFilter) => void
}

const ListHeaderButtons = ({ country, questionFilters, onApply }: IListHeaderButtonsProps) => {
  const { t } = useTranslation('questionnaires')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false)
  const [currentMode, setCurrentMode] = useState<'publish' | 'verify'>('verify')
  const [className, setClassName] = useState('')
  const dispatch = useAppDispatch()
  const { error: publishError, loading: publishLoading } = useSelector(
    selectors.updateCountryPublish
  )
  const { error: verifyError, loading: verifyLoading } = useSelector(selectors.updateCountryVerify)
  const date = country?.lastVerifiedDate
    ? `${formatUtc(country.lastVerifiedDate, DATE_ISO_FORMAT_WITH_TIME)} ${GMT_TIME}`
    : ''
  const {
    appContext: {
      style: { isMobile }
    },
    sendNotification
  } = useApp()

  useEffect(() => {
    if (!country?.isPublished) {
      setCurrentMode('publish')
      setClassName('')
    } else {
      setCurrentMode('verify')
      setClassName(`ap-text-color-text-${country.isVerified ? 'success' : 'error'}`)
    }
  }, [country])

  useEffect(() => {
    if (
      (verifyLoading === LoadingStatus.Succeeded || publishLoading === LoadingStatus.Succeeded) &&
      country
    ) {
      dispatch(actions.getQuestionnaireCountries(country.yearId))
      sendNotification({
        message: t(`success`),
        status: 'success'
      })
      dispatch(actions.clearUpdateCountryPublish())
      dispatch(actions.clearUpdateCountryVerify())
    }
  }, [publishLoading, verifyLoading])

  useEffect(() => {
    if (publishError?.length) {
      sendNotification({
        message: publishError,
        status: 'error'
      })
      dispatch(actions.clearUpdateCountryPublish())
    }
    if (verifyError?.length) {
      sendNotification({
        message: verifyError,
        status: 'error'
      })
      dispatch(actions.clearUpdateCountryVerify())
    }
  }, [publishError, verifyError])

  const onSubmit = () => {
    setIsModalVisible(false)
    if (!country) return
    if (currentMode === 'publish') {
      dispatch(
        actions.updateCountryPublish({ yearId: country.yearId, countryId: country.countryId })
      )
    }
    if (currentMode === 'verify') {
      dispatch(
        actions.updateCountryVerify({ yearId: country.yearId, countryId: country.countryId })
      )
    }
  }
  const publisher = country?.verifiedByUser
    ? ` ${t(`by`)} ${getFullname(country.verifiedByUser)}`
    : ''
  const label = (
    <>
      <SDKText className={`ap-pt-spacing-3 ${className}`}>
        <>
          {currentMode === 'verify' &&
            country?.isVerified &&
            `${t(`verifyLabel`)} ${date} ${publisher}`}
          {currentMode === 'publish' && t(`publishLabel`)}
        </>
      </SDKText>
    </>
  )
  return (
    <div>
      <div className={classNames(['ap-flex', isMobile ? 'flex-column' : 'flex-row-reverse'])}>
        <div
          className={classNames([
            isMobile
              ? 'ap-flex flex-column border-1 ap-border-color-background-border ap-mb-spacing-5 ap-p-spacing-3 ap-border-radius-2'
              : 'ap-pl-spacing-3'
          ])}
        >
          <SDKButton onClick={() => setIsModalVisible(true)}>{t(`${currentMode}`)}</SDKButton>
          {isMobile && label}
        </div>
        <SDKButton kind='secondary' onClick={() => setIsFilterModalVisible(true)}>
          <SDKIcon
            withDot={!Object.values(questionFilters).every(array => !array.length)}
            code='filter'
          ></SDKIcon>
          {t('filter')}
        </SDKButton>
      </div>
      {!isMobile && <div className='ap-mt-spacing-3'>{label}</div>}
      <SDKModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        title={`${t(`${currentMode}`)!} ${country?.name} ${t('questions')!}`}
        withRequired={false}
        onSubmit={onSubmit}
        submitLabel={t('confirm')!}
        withCancel
      >
        {t(`modal.${currentMode}.content`)}
      </SDKModal>
      {isFilterModalVisible && country && (
        <FilterModal
          yearId={country.yearId}
          questionFilters={questionFilters}
          onApply={onApply}
          isFilterModalVisible={isFilterModalVisible}
          setIsFilterModalVisible={setIsFilterModalVisible}
        />
      )}
    </div>
  )
}
export default ListHeaderButtons
