import type { TextEditorProps } from '@appkit4/react-text-editor'
import classNames from 'classnames'
import type { Control, FieldValues, Path } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { SDKRichTextEditor } from '../../SDK/RichTextEditor'
import './index.scss'

interface IFormRichTextEditorProps<T extends FieldValues> extends TextEditorProps {
  label: string
  name: Path<T>
  control: Control<T, any>
}

type Props<T extends FieldValues> = IFormRichTextEditorProps<T>

export const FormRichTextEditor = <T extends FieldValues>({
  control,
  name,
  className,
  ...props
}: Props<T>) => {
  return (
    <Controller
      name={name as Path<T>}
      control={control}
      render={({ field }) => (
        <SDKRichTextEditor
          className={classNames(['rich-text-editor', className])}
          {...field}
          {...props}
          onChange={(_: any, __: any, data: HTMLParagraphElement) => field.onChange(String(data))}
          value={field.value}
        />
      )}
    />
  )
}
