import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormTextArea } from '../../../../../../../components/Form/FormTextArea'
import { SDKButton } from '../../../../../../../components/SDK/Button'
import { SDKIcon } from '../../../../../../../components/SDK/Icon'
import { SDKPanel } from '../../../../../../../components/SDK/Panel'
import { SDKText } from '../../../../../../../components/SDK/Text'
import { useApp } from '../../../../../../../context/app.context'
import { usePricingTab } from '../../../../../../../context/pricingTab.context'
import { useProject } from '../../../../../../../context/project.context'
import {
  EBusinessUnitFields,
  IFormValues,
  useAddBusinessUnitSchema
} from '../../../../../../../schemas/useAddBusinessUnitSchema'
import { LoadingStatus } from '../../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../../store'
import * as actions from '../../../modals/store/actions'
import * as selectors from '../../../modals/store/selectors'
import { IBusinessUnit } from '../../../types/inputsTab'

interface IBusinessUnitForm {
  businessUnits: IBusinessUnit[]
}

export const BusinessUnitForm = ({ businessUnits }: IBusinessUnitForm) => {
  const { t } = useTranslation('pricing')
  const { project } = useProject()
  const dispatch = useAppDispatch()
  const { refreshData } = usePricingTab()
  const { sendNotification } = useApp()
  const { loading, error } = useSelector(selectors.updateBusinessUnit)

  useEffect(() => {
    if (error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
      dispatch(actions.clearUpdateBusinessUnit())
    }
    if (loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t('tabs.projectSetup.businessUnitSuccessInfo'),
        status: 'success'
      })
      refreshData()
      dispatch(actions.clearUpdateBusinessUnit())
    }
  }, [error, loading])

  const { validationSchema, defaultValues } = useAddBusinessUnitSchema(businessUnits || [])
  const { handleSubmit, control, formState, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const onSubmit = (params: IFormValues) => {
    if (project) {
      dispatch(
        actions.updateBusinessUnit({
          projectId: Number(project.projectId),
          params
        })
      )

      reset(params)
    }
  }

  return (
    <form>
      {businessUnits.map((unit, i) => (
        <SDKPanel key={`business-unit-${unit.regionId}`} className='ap-my-spacing-5'>
          <SDKText weight={2} className='ap-text-color-text-heading'>
            {unit.regionName}
          </SDKText>
          <FormTextArea
            name={`${EBusinessUnitFields.regionBusinessUnits}.${i}.businessUnits`}
            label={t(`tabs.projectSetup.businessUnit`)}
            placeholder={t(`tabs.projectSetup.businessUnitGuidance`)!}
            className='ap-mt-spacing-3'
            control={control}
            disabledSpellcheck={true}
          />
        </SDKPanel>
      ))}
      {!!businessUnits.length && (
        <SDKButton
          kind='secondary'
          className='ap-mb-spacing-5'
          disabled={!formState.isValid || !formState.isDirty}
          onClick={handleSubmit(onSubmit)}
        >
          <SDKIcon code='check-mark' /> {t(`tabs.projectSetup.apply`)}
        </SDKButton>
      )}
    </form>
  )
}
