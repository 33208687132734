import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../../../../../context/app.context'
import { useProjectFromUrl } from '../../../../../../../hooks/useProjectFromUrl'
import { LoadingStatus } from '../../../../../../../shared/types/enums'
import { DEFAULT_ERROR } from '../../../../../../../shared/utils/store'
import { useAppDispatch } from '../../../../../../../store'
import * as actions from '../../../../../../myprojects/store/actions'
import * as myProjectSelectors from '../../../../../../myprojects/store/selectors'
import { clearExportTemplate, exportTemplate } from '../../../../../store/actions'
import * as projectSelectors from '../../../../../store/selectors'
import { ELocalFileCardType } from '../../../const/enums'

export const useLocalFileCard = (type: ELocalFileCardType) => {
  const { t, i18n } = useTranslation('myprojects')
  const dispatch = useAppDispatch()
  const { project } = useProjectFromUrl()
  const { sendNotification } = useApp()

  const { loading: SILoading, error: SIError } = useSelector(myProjectSelectors.syncWithSightline)
  const { loading: downloadLoading, error: downloadError } = useSelector(
    projectSelectors.getExportTemplate
  )

  const loading = type === ELocalFileCardType.Sightline ? SILoading : downloadLoading
  const error = type === ELocalFileCardType.Sightline ? SIError : downloadError
  const clearAction =
    type === ELocalFileCardType.Sightline ? actions.clearSyncWithSightline : clearExportTemplate
  const successInfo =
    type === ELocalFileCardType.Sightline ? i18n.t('syncWithSightlineSuccessInfo') : null

  const syncWithSightline = () => {
    if (project?.projectId) {
      dispatch(actions.syncWithSightline({ projectId: project.projectId }))
    } else {
      sendNotification({
        message: DEFAULT_ERROR,
        status: 'error'
      })
    }
  }

  const onDownloadTemplate = () => {
    if (project?.projectId) {
      if (project.taraCountryIds?.length) {
        dispatch(
          exportTemplate({ countryIds: project.taraCountryIds, projectId: project.projectId })
        )
      } else {
        sendNotification({
          message: t('projectDetail.projectTabs.tara.input.downloadTemplateError', {
            name: project?.name
          }),
          status: 'error'
        })
      }
    } else {
      sendNotification({
        message: DEFAULT_ERROR,
        status: 'error'
      })
    }
  }

  useEffect(() => {
    if (loading === LoadingStatus.Failed && error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
    } else if (loading === LoadingStatus.Succeeded && successInfo) {
      sendNotification({
        message: successInfo,
        status: 'success'
      })
    }
  }, [loading])

  useEffect(() => {
    return () => {
      dispatch(clearAction())
    }
  }, [])

  return { syncWithSightline, onDownloadTemplate, loading }
}
