import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useApp } from '../../../context/app.context'
import { LoadingStatus } from '../../../shared/types/enums'
import { useAppDispatch } from '../../../store'
import { getYearByName } from '../../fiscalYearSetup/store/selectors'
import { clearDeleteRegion, deleteRegion, getRegions } from '../store/actions'
import { getDeleteRegion } from '../store/selectors'

export const useDeleteRegion = () => {
  const { t } = useTranslation('regions')
  const dispatch = useAppDispatch()
  const { year: yearName } = useParams()
  const year = useSelector(getYearByName(yearName!))
  const { sendNotification } = useApp()
  const { loading, error } = useSelector(getDeleteRegion)
  const [visibleDeleteRegionModal, setVisibleDeleteRegionModal] = useState(false)
  const [regionId, setRegionId] = useState<number | null>(null)
  const [regionName, setRegionName] = useState<string>('')

  const onDeleteRegion = (name: string, id: number) => {
    dispatch(clearDeleteRegion())
    setVisibleDeleteRegionModal(true)
    setRegionName(name)
    setRegionId(id)
  }

  const onSubmitDelete = () => {
    if (year && regionId) {
      dispatch(deleteRegion({ yearId: Number(year.yearId), regionId: regionId }))
    } else {
      sendNotification({
        message: t('deleteRegion.error', { name: regionName }),
        status: 'error'
      })
    }
  }

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded && year) {
      sendNotification({
        message: t('deleteRegion.successInfo', { name: regionName }),
        status: 'success'
      })
      dispatch(getRegions(year.yearId))
      setVisibleDeleteRegionModal(false)
    } else if (loading === LoadingStatus.Failed && error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
      setVisibleDeleteRegionModal(false)
    }
    dispatch(clearDeleteRegion())
  }, [loading])

  return {
    onDeleteRegion,
    onSubmitDelete,
    visibleDeleteRegionModal,
    setVisibleDeleteRegionModal,
    regionName
  }
}
