import { CALCULATION_ID, ESightlineRedirectAction } from '../../../modules/project/constants'
import { TFileType } from '../../../modules/project/types/projectCalculation'
import { ResponseCode } from '../../../shared/types/enums'
import { authorizeSightline } from '../../../shared/utils/authorizeSightline'
import api from '../../api'
import { CalculationUrl } from '../../constants/urls'

export const getCalculationResultByFormatFromSightline = async (
  calculationId: number,
  fileType: TFileType,
  rejectWithValue: any
): Promise<string> => {
  try {
    const response = await api.get(
      `${CalculationUrl.Calculation}/${calculationId}/${fileType}/${CalculationUrl.Sightline}`
    )
    return response.data
  } catch (error: any) {
    if (error.code === ResponseCode.Unauthorized) {
      authorizeSightline(
        error.message,
        ESightlineRedirectAction.GetCalculationResultByFormatFromSightline
      )
      localStorage.setItem(CALCULATION_ID, String(calculationId))
      return ''
    } else {
      return rejectWithValue(error.message)
    }
  }
}
