import queryString from 'query-string'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PageBackground } from '../../components/PageBackground'
import { Url } from '../../constants/urls'
import { useApp } from '../../context/app.context'
import { useAppDispatch } from '../../store'
import { mapQuestionsToCountries } from '../project/utils/mapQuestionsToCountries'
import CountrySnapshotMFEContent from './components/CountrySnapshotMFEContent'
import { clearGetCountrySnapshotMfeResults, getCountrySnapshotMfeResults } from './store/actions'
import * as selectors from './store/selectors'

const CountrySnapshotMFE = () => {
  const {
    appContext: { isCountrySnapshotMFE }
  } = useApp()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { error, data: questions, loading } = useSelector(selectors.getCountrySnapshotMfeResults)
  const { countries, questionUniqueIds, year } = queryString.parse(location.search)
  const mappedCountries = useMemo(() => mapQuestionsToCountries(questions), [questions])

  useEffect(() => {
    if (countries && year) {
      dispatch(
        getCountrySnapshotMfeResults({
          yearName: year as string,
          params: {
            countryCodes: Array.isArray(countries) ? (countries as string[]) : [countries],
            questionOrderNumbers: questionUniqueIds
              ? Array.isArray(questionUniqueIds)
                ? questionUniqueIds.map(id => Number(id))
                : [Number(questionUniqueIds)]
              : []
          }
        })
      )
    }
  }, [])

  useEffect(() => {
    if (error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
      dispatch(clearGetCountrySnapshotMfeResults())
    }
  }, [error])

  if (isCountrySnapshotMFE) {
    return (
      <PageBackground>
        <CountrySnapshotMFEContent
          countries={mappedCountries}
          loading={loading}
          questions={questions}
          yearName={year as string}
        />
      </PageBackground>
    )
  } else {
    navigate(Url.NotFound)
    return <></>
  }
}

export default CountrySnapshotMFE
