import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider } from '../../../../components/Divider'
import { Layout } from '../../../../components/Layout'
import { LoadingModal } from '../../../../components/LoadingModal'
import { SDKButton } from '../../../../components/SDK/Button'
import { SDKSelect } from '../../../../components/SDK/Select'
import styles from './index.module.scss'
import { useDownloadActions } from './utils/useDownloadActions'
import { useReports } from './utils/useReports'

export const Reports = () => {
  const { t } = useTranslation('systemManagement')
  const { mappedYears } = useReports()
  const [year, setYear] = useState<number | null>(null)

  const {
    downloadProjectsReportExcel,
    downloadQuestionsAndAnswersReportExcel,
    downloadRDPFormulasReportExcel,
    downloadUsersReportExcel,
    showLoadingModal,
    setShowLoadingModal
  } = useDownloadActions(year)

  const disabledBtn = year === null
  const btnIcon = 'icon-download-light-outline'

  return (
    <Layout>
      <div className='reports-page ap-my-spacing-5'>
        <div className='row'>
          <SDKSelect
            placeholder={t('reports.selectPlaceholder')!}
            onSelect={(data: number) => setYear(data)}
            className='col col-sm-6 col-md-4 ap-mb-spacing-5'
            data={mappedYears}
            value={year || ''}
            dropdownRenderMode='portal'
          />
        </div>
        <div className={classNames(styles['btn-wrapper'])}>
          <SDKButton
            onClick={downloadProjectsReportExcel}
            kind='secondary'
            className='ap-mr-spacing-5 ap-mb-spacing-3'
            disabled={disabledBtn}
            icon={btnIcon}
          >
            {t('reports.projectBtn')}
          </SDKButton>

          <SDKButton
            onClick={downloadQuestionsAndAnswersReportExcel}
            kind='secondary'
            className='ap-mr-spacing-5 ap-mb-spacing-3'
            disabled={disabledBtn}
            icon={btnIcon}
          >
            {t('reports.q&aBtn')}
          </SDKButton>
          <SDKButton
            onClick={downloadRDPFormulasReportExcel}
            kind='secondary'
            disabled={disabledBtn}
            className='ap-mb-spacing-3'
            icon={btnIcon}
          >
            {t('reports.formulasBtn')}
          </SDKButton>
        </div>
        <Divider />
        <SDKButton
          onClick={downloadUsersReportExcel}
          kind='secondary'
          className='ap-mt-spacing-5'
          icon={btnIcon}
        >
          {t('reports.users')}
        </SDKButton>
      </div>
      {showLoadingModal && (
        <LoadingModal setVisible={setShowLoadingModal} text={t('reports.loadingText')!} />
      )}
    </Layout>
  )
}
