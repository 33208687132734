import { ESightlineRedirectAction } from '../../../modules/project/constants'
import { ISightlineFile } from '../../../modules/project/types/projectCalculation'
import { ResponseCode } from '../../../shared/types/enums'
import { authorizeSightline } from '../../../shared/utils/authorizeSightline'
import api from '../../api'
import { CalculationUrl } from '../../constants/urls'

export const getSightlineFilesList = async (
  projectId: number,
  rejectWithValue: any
): Promise<ISightlineFile[]> => {
  try {
    return (
      await api.get(
        `${CalculationUrl.Calculation}/${projectId}/${CalculationUrl.Sightline}/${CalculationUrl.Files}`
      )
    ).data
  } catch (error: any) {
    if (error.code === ResponseCode.Unauthorized) {
      authorizeSightline(error.message, ESightlineRedirectAction.GetSightlineFilesList)
      return []
    } else {
      return rejectWithValue(error.message)
    }
  }
}
