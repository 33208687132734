import type { ModalProps } from '@appkit4/react-components/modal'
import { Modal } from '@appkit4/react-components/modal'
import classNames from 'classnames'
import { useEffect } from 'react'
import { useApp } from '../../../context/app.context'
import { LoadingStatus } from '../../../shared/types/enums'
import { CancelButton } from './components/FooterButtons/CancelButton'
import type { SaveButtonProps } from './components/FooterButtons/SaveButton'
import { SaveButton } from './components/FooterButtons/SaveButton'
import { ModalFooter } from './components/ModalFooter'
import { WizardFooter } from './components/WizardFooter'
import './index.scss'
import type { IWizardFooter } from './types'

export interface ISDKModal extends ModalProps {
  disabledSubmit?: boolean
  loading?: LoadingStatus
  submitLabel?: string
  withCancel?: boolean
  withRequired?: boolean
  wizardFooter?: IWizardFooter
  onCancel: () => void
  onSubmit?: () => void
  customFooterComponent?: JSX.Element
  footerClassName?: string
  cancelText?: string
}

export const SDKModal = ({
  disabledSubmit,
  footer,
  loading,
  submitLabel,
  title,
  withCancel,
  wizardFooter,
  withRequired = true,
  onCancel,
  customFooterComponent,
  onSubmit,
  footerClassName,
  cancelText,
  ...props
}: ISDKModal) => {
  const {
    appContext: {
      style: { theme, mode }
    }
  } = useApp()

  useEffect(() => {
    document.body.setAttribute('data-theme', theme)
    document.body.setAttribute('data-mode', mode)
  }, [mode, theme])

  const saveButtonProps: SaveButtonProps = { disabledSubmit, loading, onSubmit, submitLabel }

  const defaultFooter = (
    <>
      {withCancel && <CancelButton onCancel={onCancel} cancelText={cancelText} />}
      {<SaveButton {...saveButtonProps} />}
    </>
  )

  const footerItem =
    (wizardFooter && <WizardFooter {...wizardFooter} saveButtonProps={saveButtonProps} onCancel={onCancel} />) ||
    footer ||
    defaultFooter

  return (
    <Modal
      ariaLabel={`modal-${title}`}
      className='default-modal ap-mx-spacing-5'
      footer={
        <ModalFooter
          className={classNames(footerClassName)}
          withRequired={withRequired}
          customFooterComponent={customFooterComponent}
        >
          {footerItem}
        </ModalFooter>
      }
      maskCloseable={false}
      onCancel={onCancel}
      title={title}
      {...props}
    />
  )
}
