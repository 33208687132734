import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import IFrame from '../../../../../../components/IFrame'
import { SIGHTLINE_IFRAME_ACTION, SIGHTLINE_IFRAME_ORIGIN, SIGHTLINE_IFRAME_URL } from './const'
import type { ISightlineMessage } from './types'

const SIGHTLINE_IFRAME_HEIGHT = 480

export const SightlineSelectModal = ({
  onSelect
}: {
  onSelect: (sightline: ISightlineMessage) => void
}) => {
  const { t } = useTranslation('myprojects')

  const selectProject = (event: MessageEvent) => {
    if (event.origin !== SIGHTLINE_IFRAME_ORIGIN) {
      return
    }

    if (event.data.action === SIGHTLINE_IFRAME_ACTION) {
      onSelect(event.data.engagement as ISightlineMessage)
    }
  }

  useEffect(() => {
    window.addEventListener('message', selectProject, false)

    return () => window.removeEventListener('message', selectProject)
  }, [])

  return (
    <IFrame
      src={SIGHTLINE_IFRAME_URL}
      height={SIGHTLINE_IFRAME_HEIGHT}
      title={t('sightlineModal.title')}
    />
  )
}
