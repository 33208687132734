import { useTranslation } from 'react-i18next'
import { mixed, object } from 'yup'
import { MAX_XLSX_FILE_SIZE } from '../modules/project/constants'

export enum EUploadPricingLocalFileTemplate {
  inputFile = 'inputFile'
}
export interface IFormValues {
  [EUploadPricingLocalFileTemplate.inputFile]: File | null
}

export const useUploadPricingLocalFileTemplate = () => {
  const { t } = useTranslation('pricing')

  const defaultValues: IFormValues = {
    [EUploadPricingLocalFileTemplate.inputFile]: null
  }
  const validationSchema = object()
    .shape({
      [EUploadPricingLocalFileTemplate.inputFile]: mixed().test(
        'fileSize',
        t('tabs.inputs.localFile.table.upload.uploadFileTooLarge')!,
        value => {
          if (!value) return true
          return (value as File).size <= MAX_XLSX_FILE_SIZE
        }
      )
    })
    .required()

  return { validationSchema, defaultValues }
}
