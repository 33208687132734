import { Url } from '../../../constants/urls'
import { SDKDropdownButton } from '../../SDK/DropdownButton'
import { SDKIcon } from '../../SDK/Icon'

export interface IMenuItem {
  label: string
  iconName: string
  iconClassName?: string
  noOutline?: boolean
  url?: Url | string
  disabled?: boolean
}

export const DropdownMenu = ({
  id,
  menuItems,
  withDot,
  dotColor
}: {
  menuItems: IMenuItem[]
  id?: number | string
  dotColor?: string
  withDot?: boolean
}) => {
  return (
    <SDKDropdownButton
      customTriggerNode={true}
      customTriggerClassName='custom-node'
      data={menuItems}
      prefixTemplate={item => (
        <SDKIcon
          code={item.iconName}
          outline={!item.noOutline}
          className={item.iconClassName}
          disabled={item.disabled}
        />
      )}
      onSelect={(_, item) => item.onClick(id)}
    >
      <SDKIcon withDot={withDot} dotColor={dotColor} code={'vertical-more'} className='pointer' />
    </SDKDropdownButton>
  )
}
