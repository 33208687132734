import { ESortType, INewsfeedResponse } from '../../../modules/updates/types'
import api from '../../api'
import { UpdatesUrl } from '../../constants/urls'

export interface IGetNewsfeedEventsPayload {
  searchValue: string
  sortOrder: ESortType
  limit: number
  offset: number
}

export const getNewsfeedEvents = async ({
  searchValue,
  sortOrder,
  limit,
  offset
}: IGetNewsfeedEventsPayload): Promise<INewsfeedResponse> => {
  return (
    await api.get(
      `${UpdatesUrl.Newsfeed}?searchTerm=${searchValue}&sortOrder=${sortOrder}&limit=${limit}&offset=${offset}`
    )
  ).data
}
