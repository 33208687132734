import { createReducer } from '@reduxjs/toolkit'
import {
  getDefaultResourceState,
  Resource,
  setResourceFullfilled,
  setResourcePending,
  setResourceRejected
} from '../../../../../../shared/utils/store'
import type { IProjectSetup, TUpdateModuleMetadata } from '../../types/inputsTab'
import type {
  IChildScope,
  ILocalFileParseError,
  IParentComplexScopeFull,
  IPriceYear,
  IScopeQuestions,
  IUpdateChildScope,
  IUpdateParentScope,
  TParentScope
} from '../../types/scope'
import {
  clearCreateChildScope,
  clearCreateParentScope,
  clearDeleteCustomModal,
  clearGetLocalFileResults,
  clearGetLocalFileScopePricing,
  clearGetLocalFileScopeTranslationPricing,
  clearGetParentScope,
  clearGetScopeMetadata,
  clearUpdateBusinessUnit,
  clearUpdateChildScope,
  clearUpdateChildScopeOrder,
  clearUpdateChildScopeToggle,
  clearUpdateModuleMetadata,
  clearUpdateParentScope,
  clearUpdateParentScopeOrder,
  clearUpdateParentScopeToggle,
  clearUpdateTransactions,
  clearUploadLocalFile,
  createChildScope,
  createParentScope,
  deleteCustomModal,
  getLocalFileResults,
  getLocalFileScopePricing,
  getLocalFileScopeTranslationPricing,
  getParentScope,
  getScopeMetadata,
  updateBusinessUnit,
  updateChildScope,
  updateChildScopeOrder,
  updateChildScopeToggle,
  updateModuleMetadata,
  updateParentScope,
  updateParentScopeOrder,
  updateParentScopeToggle,
  updateTransactions,
  uploadLocalFile
} from './actions'

export interface PricingState {
  getScopeMetadata: Resource<IScopeQuestions | null>
  createChildScope: Resource<IChildScope | null>
  updateChildScope: Resource<IChildScope | null>
  createParentScope: Resource<TParentScope | null>
  getParentScope: Resource<IParentComplexScopeFull | null>
  updateParentScope: Resource<TParentScope | null>
  updateChildScopeToggle: Resource<IUpdateChildScope | null>
  updateParentScopeToggle: Resource<IUpdateParentScope | null>
  updateChildScopeOrder: Resource<IUpdateParentScope | null>
  updateParentScopeOrder: Resource<IUpdateParentScope | null>
  updateModuleMetadata: Resource<TUpdateModuleMetadata | null>
  updateBusinessUnit: Resource<IProjectSetup | null>
  updateTransactions: Resource<IProjectSetup | null>
  uploadLocalFile: Resource<File | ILocalFileParseError[] | null>
  localFileResults: Resource<File | null>
  localFileScopePricing: Resource<IPriceYear[] | null>
  localFileScopeTranslationPricing: Resource<IPriceYear[] | null>
  deleteCustomModule: Resource<string | null>
}

const initialState: PricingState = {
  getScopeMetadata: getDefaultResourceState(null),
  createChildScope: getDefaultResourceState(null),
  updateChildScope: getDefaultResourceState(null),
  createParentScope: getDefaultResourceState(null),
  getParentScope: getDefaultResourceState(null),
  updateParentScope: getDefaultResourceState(null),
  updateChildScopeToggle: getDefaultResourceState(null),
  updateParentScopeToggle: getDefaultResourceState(null),
  updateChildScopeOrder: getDefaultResourceState(null),
  updateParentScopeOrder: getDefaultResourceState(null),
  updateModuleMetadata: getDefaultResourceState(null),
  updateBusinessUnit: getDefaultResourceState(null),
  updateTransactions: getDefaultResourceState(null),
  uploadLocalFile: getDefaultResourceState(null),
  localFileResults: getDefaultResourceState(null),
  localFileScopePricing: getDefaultResourceState([]),
  localFileScopeTranslationPricing: getDefaultResourceState([]),
  deleteCustomModule: getDefaultResourceState(null)
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getScopeMetadata.pending, state => {
      setResourcePending(state.getScopeMetadata)
    })
    .addCase(getScopeMetadata.rejected, (state, actions) => {
      setResourceRejected(state.getScopeMetadata, actions)
    })
    .addCase(getScopeMetadata.fulfilled, (state, actions) => {
      state.getScopeMetadata.data = actions.payload.scopeQuestions
      setResourceFullfilled(state.getScopeMetadata)
    })
    .addCase(clearGetScopeMetadata, state => {
      state.getScopeMetadata = initialState.getScopeMetadata
    })

    .addCase(createChildScope.pending, state => {
      setResourcePending(state.createChildScope)
    })
    .addCase(createChildScope.rejected, (state, actions) => {
      setResourceRejected(state.createChildScope, actions)
    })
    .addCase(createChildScope.fulfilled, (state, actions) => {
      state.createChildScope.data = actions.payload
      setResourceFullfilled(state.createChildScope)
    })
    .addCase(clearCreateChildScope, state => {
      state.createChildScope = initialState.createChildScope
    })

    .addCase(updateChildScope.pending, state => {
      setResourcePending(state.updateChildScope)
    })
    .addCase(updateChildScope.rejected, (state, actions) => {
      setResourceRejected(state.updateChildScope, actions)
    })
    .addCase(updateChildScope.fulfilled, (state, actions) => {
      state.updateChildScope.data = actions.payload
      setResourceFullfilled(state.updateChildScope)
    })
    .addCase(clearUpdateChildScope, state => {
      state.updateChildScope = initialState.updateChildScope
    })

    .addCase(createParentScope.pending, state => {
      setResourcePending(state.createParentScope)
    })
    .addCase(createParentScope.rejected, (state, actions) => {
      setResourceRejected(state.createParentScope, actions)
    })
    .addCase(createParentScope.fulfilled, (state, actions) => {
      state.createParentScope.data = actions.payload
      setResourceFullfilled(state.createParentScope)
    })
    .addCase(clearCreateParentScope, state => {
      state.createParentScope = initialState.createParentScope
    })

    .addCase(getParentScope.pending, state => {
      setResourcePending(state.getParentScope)
    })
    .addCase(getParentScope.rejected, (state, actions) => {
      setResourceRejected(state.getParentScope, actions)
    })
    .addCase(getParentScope.fulfilled, (state, actions) => {
      state.getParentScope.data = actions.payload
      setResourceFullfilled(state.getParentScope)
    })
    .addCase(clearGetParentScope, state => {
      state.getParentScope = initialState.getParentScope
    })

    .addCase(updateParentScope.pending, state => {
      setResourcePending(state.updateParentScope)
    })
    .addCase(updateParentScope.rejected, (state, actions) => {
      setResourceRejected(state.updateParentScope, actions)
    })
    .addCase(updateParentScope.fulfilled, (state, actions) => {
      state.updateParentScope.data = actions.payload
      setResourceFullfilled(state.updateParentScope)
    })
    .addCase(clearUpdateParentScope, state => {
      state.updateParentScope = initialState.updateParentScope
    })

    .addCase(updateChildScopeToggle.pending, state => {
      setResourcePending(state.updateChildScopeToggle)
    })
    .addCase(updateChildScopeToggle.rejected, (state, actions) => {
      setResourceRejected(state.updateChildScopeToggle, actions)
    })
    .addCase(updateChildScopeToggle.fulfilled, (state, actions) => {
      state.updateChildScopeToggle.data = actions.payload
      setResourceFullfilled(state.updateChildScopeToggle)
    })
    .addCase(clearUpdateChildScopeToggle, state => {
      state.updateChildScopeToggle = initialState.updateChildScopeToggle
    })

    .addCase(updateParentScopeToggle.pending, state => {
      setResourcePending(state.updateParentScopeToggle)
    })
    .addCase(updateParentScopeToggle.rejected, (state, actions) => {
      setResourceRejected(state.updateParentScopeToggle, actions)
    })
    .addCase(updateParentScopeToggle.fulfilled, (state, actions) => {
      state.updateParentScopeToggle.data = actions.payload
      setResourceFullfilled(state.updateParentScopeToggle)
    })
    .addCase(clearUpdateParentScopeToggle, state => {
      state.updateParentScopeToggle = initialState.updateParentScopeToggle
    })

    .addCase(updateChildScopeOrder.pending, state => {
      setResourcePending(state.updateChildScopeOrder)
    })
    .addCase(updateChildScopeOrder.rejected, (state, actions) => {
      setResourceRejected(state.updateChildScopeOrder, actions)
    })
    .addCase(updateChildScopeOrder.fulfilled, (state, actions) => {
      state.updateChildScopeOrder.data = actions.payload
      setResourceFullfilled(state.updateChildScopeOrder)
    })
    .addCase(clearUpdateChildScopeOrder, state => {
      state.updateChildScopeOrder = initialState.updateChildScopeOrder
    })

    .addCase(updateParentScopeOrder.pending, state => {
      setResourcePending(state.updateParentScopeOrder)
    })
    .addCase(updateParentScopeOrder.rejected, (state, actions) => {
      setResourceRejected(state.updateParentScopeOrder, actions)
    })
    .addCase(updateParentScopeOrder.fulfilled, (state, actions) => {
      state.updateParentScopeOrder.data = actions.payload
      setResourceFullfilled(state.updateParentScopeOrder)
    })
    .addCase(clearUpdateParentScopeOrder, state => {
      state.updateParentScopeOrder = initialState.updateParentScopeOrder
    })

    .addCase(updateModuleMetadata.pending, state => {
      setResourcePending(state.updateModuleMetadata)
    })
    .addCase(updateModuleMetadata.rejected, (state, actions) => {
      setResourceRejected(state.updateModuleMetadata, actions)
    })
    .addCase(updateModuleMetadata.fulfilled, (state, actions) => {
      state.updateModuleMetadata.data = actions.payload
      setResourceFullfilled(state.updateModuleMetadata)
    })
    .addCase(clearUpdateModuleMetadata, state => {
      state.updateModuleMetadata = initialState.updateModuleMetadata
    })

    .addCase(updateBusinessUnit.pending, state => {
      setResourcePending(state.updateBusinessUnit)
    })
    .addCase(updateBusinessUnit.rejected, (state, actions) => {
      setResourceRejected(state.updateBusinessUnit, actions)
    })
    .addCase(updateBusinessUnit.fulfilled, (state, actions) => {
      state.updateModuleMetadata.data = actions.payload
      setResourceFullfilled(state.updateBusinessUnit)
    })
    .addCase(clearUpdateBusinessUnit, state => {
      state.updateBusinessUnit = initialState.updateBusinessUnit
    })

    .addCase(updateTransactions.pending, state => {
      setResourcePending(state.updateTransactions)
    })
    .addCase(updateTransactions.rejected, (state, actions) => {
      setResourceRejected(state.updateTransactions, actions)
    })
    .addCase(updateTransactions.fulfilled, (state, actions) => {
      state.updateModuleMetadata.data = actions.payload
      setResourceFullfilled(state.updateTransactions)
    })
    .addCase(clearUpdateTransactions, state => {
      state.updateTransactions = initialState.updateTransactions
    })

    .addCase(uploadLocalFile.pending, state => {
      setResourcePending(state.uploadLocalFile)
    })
    .addCase(uploadLocalFile.rejected, (state, actions) => {
      setResourceRejected(state.uploadLocalFile, actions)
    })
    .addCase(uploadLocalFile.fulfilled, (state, actions) => {
      state.uploadLocalFile.data = actions.payload
      setResourceFullfilled(state.uploadLocalFile)
    })
    .addCase(clearUploadLocalFile, state => {
      state.uploadLocalFile = initialState.uploadLocalFile
    })

    .addCase(getLocalFileResults.pending, state => {
      setResourcePending(state.localFileResults)
    })
    .addCase(getLocalFileResults.rejected, (state, actions) => {
      setResourceRejected(state.localFileResults, actions)
    })
    .addCase(getLocalFileResults.fulfilled, (state, actions) => {
      state.localFileResults.data = actions.payload
      setResourceFullfilled(state.localFileResults)
    })
    .addCase(clearGetLocalFileResults, state => {
      state.localFileResults = initialState.localFileResults
    })

    .addCase(getLocalFileScopePricing.pending, state => {
      setResourcePending(state.localFileScopePricing)
    })
    .addCase(getLocalFileScopePricing.rejected, (state, actions) => {
      setResourceRejected(state.localFileScopePricing, actions)
    })
    .addCase(getLocalFileScopePricing.fulfilled, (state, actions) => {
      state.localFileScopePricing.data = actions.payload
      setResourceFullfilled(state.localFileScopePricing)
    })
    .addCase(clearGetLocalFileScopePricing, state => {
      state.localFileScopePricing = initialState.localFileScopePricing
    })

    .addCase(getLocalFileScopeTranslationPricing.pending, state => {
      setResourcePending(state.localFileScopeTranslationPricing)
    })
    .addCase(getLocalFileScopeTranslationPricing.rejected, (state, actions) => {
      setResourceRejected(state.localFileScopeTranslationPricing, actions)
    })
    .addCase(getLocalFileScopeTranslationPricing.fulfilled, (state, actions) => {
      state.localFileScopeTranslationPricing.data = actions.payload
      setResourceFullfilled(state.localFileScopeTranslationPricing)
    })
    .addCase(clearGetLocalFileScopeTranslationPricing, state => {
      state.localFileScopeTranslationPricing = initialState.localFileScopeTranslationPricing
    })

    .addCase(deleteCustomModal.pending, state => {
      setResourcePending(state.deleteCustomModule)
    })
    .addCase(deleteCustomModal.rejected, (state, actions) => {
      setResourceRejected(state.deleteCustomModule, actions)
    })
    .addCase(deleteCustomModal.fulfilled, (state, actions) => {
      state.deleteCustomModule.data = actions.payload
      setResourceFullfilled(state.deleteCustomModule)
    })
    .addCase(clearDeleteCustomModal, state => {
      state.deleteCustomModule = initialState.deleteCustomModule
    })
)
