import type { CheckboxProps } from '@appkit4/react-components/checkbox'
import type { UseControllerProps } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { SDKCheckbox } from '../../SDK/Checkbox'

interface IFormCheckbox extends CheckboxProps {
  label: string
  required: boolean
}

type Props = Partial<IFormCheckbox> & UseControllerProps

export const FormCheckbox = ({ required, label, control, name, ...props }: Props) => {
  return (
    <>
      <Controller
        name={name}
        rules={{ required }}
        control={control}
        render={({ field }) => <SDKCheckbox label={label} {...field} {...props} />}
      />
    </>
  )
}
