import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '../../../ActionButton'
import { SDKSwitch } from '../../../SDK/Switch'
import { EAction } from '../../const'

export interface ICardActionParameter {
  icon: string
  tooltip: string
}

export const CardActionButton = ({
  handleClick,
  type,
  checked,
  hideLine,
  isEditButtonVisible,
  isToggleDisabled
}: {
  handleClick: () => void
  type: EAction
  checked?: boolean
  isEditButtonVisible: boolean
  hideLine?: boolean
  isToggleDisabled: boolean
}) => {
  const { t } = useTranslation()
  const [isToggleEnabled, setIsToggleEnabled] = useState(checked)

  const wrapper = (children: JSX.Element, noLine?: boolean) => (
    <div className={classNames(!noLine && 'border-left', 'ap-px-spacing-5')}>{children}</div>
  )

  useEffect(() => {
    setIsToggleEnabled(checked)
  }, [checked])

  const onEdit = (_e: React.MouseEvent) => {
    _e.stopPropagation()
    handleClick()
  }

  const onSwitch = () => {
    handleClick()
    setIsToggleEnabled(!isToggleEnabled)
  }

  if (type === EAction.Edit) {
    return wrapper(
      isEditButtonVisible ? (
        <ActionButton icon='edit' content={t('buttons.edit')!} handleClick={onEdit} />
      ) : (
        <div className='ap-px-spacing-5'></div>
      )
    )
  }
  if (type === EAction.Enable) {
    return wrapper(
      <SDKSwitch
        onChange={onSwitch}
        defaultChecked={checked}
        checked={checked && isToggleEnabled}
        disabled={isToggleDisabled}
      >
        {t('buttons.enable')!}
      </SDKSwitch>,
      hideLine
    )
  }

  return <></>
}
