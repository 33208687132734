import { UserRole } from '../../types/user'
import { useApp } from '../app.context'

export const useMyRole = () => {
  const {
    appContext: { user }
  } = useApp()

  return {
    amIExternal: user?.roles.includes(UserRole.ExternalUser),
    amIAdmin: user?.roles.includes(UserRole.Admin)
  }
}
