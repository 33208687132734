import { SDKList } from '../SDK/List'
import { EListItem } from './const'
import { CustomCountryItem } from './items/CustomCountryItem'
import { TListItem } from './type'

type Unboxed<T> = T extends Array<infer U> ? U : T

export const ListWrapper = (props: TListItem) => {
  const renderItem = (item: Unboxed<typeof props.data>, _: number) => {
    const Component = () => {
      switch (props.listItem) {
        case EListItem.CustomCountryItem:
          return (
            <CustomCountryItem
              item={item}
              actionCallback={props.handleClick}
              selectedCountryIds={props.selectedCountryIds}
            />
          )
        default:
          return <></>
      }
    }

    return (
      <div
        key={`item-key-${item[props.itemKey as keyof Unboxed<typeof props.data>]}`}
        className={props.itemClassName || ''}
      >
        <Component />
      </div>
    )
  }
  return (
    <SDKList
      data={props.data}
      itemKey={props.itemKey}
      renderItem={renderItem}
      className={props.listClassName || ''}
    ></SDKList>
  )
}
