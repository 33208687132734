import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../../../../../context/app.context'
import { usePricingTab } from '../../../../../../../context/pricingTab.context'
import { useProject } from '../../../../../../../context/project.context'
import { type IFormValues } from '../../../../../../../schemas/useEditParentScopeSchema'
import { LoadingStatus } from '../../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../../store'
import type { IParentComplexScopeFull } from '../../../types/scope'
import * as actions from '../../store/actions'
import * as selectors from '../../store/selectors'
import { parentScopePayloadModel } from '../const'

export const useParentScope = (
  scope: Partial<IParentComplexScopeFull>,
  onClose: (params?: { refreshData: boolean }) => void
) => {
  const { t } = useTranslation('pricing')
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { loading, error } = useSelector(
    scope?.parentScopeId ? selectors.updateParentScope : selectors.createParentScope
  )

  const notificationText = t(`tabs.inputs.parentScopeModal.notification.editSuccessInfo`, {
    name: scope?.name
  })
  const { project } = useProject()
  const {
    tabContent: { tab }
  } = usePricingTab()

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: notificationText,
        status: 'success'
      })
      onClose({ refreshData: true })
    } else if (loading === LoadingStatus.Failed && error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
      onClose()
    }
  }, [loading])

  const onSubmit = (data: IFormValues) => {
    const payload = parentScopePayloadModel(data, scope)

    if (scope?.parentScopeId) {
      dispatch(
        actions.updateParentScope({
          projectId: project?.projectId!,
          scope: payload,
          tab
        })
      )
    } else {
      dispatch(
        actions.createParentScope({
          projectId: project?.projectId!,
          scope: payload,
          tab
        })
      )
    }
  }

  return { onSubmit, onClose, loading }
}
