import classNames from 'classnames'
import { SDKText } from '../../../../../../../../../components/SDK/Text'
import { useProject } from '../../../../../../../../../context/project.context'
import { useTransformNumberToMoney } from '../../../../../../../utils/useTransformNumberToMoney'
import type { IParentScopeYearTotal } from '../../../../../types/scope'
import { useFeesAmountCalculation } from '../../utils/useFeesAmountCalculation'

interface IMultipleRowsGrid {
  fees: IParentScopeYearTotal[]
  isLastElement: boolean
}

export const MultipleRowsGrid = ({ fees, isLastElement }: IMultipleRowsGrid) => {
  const { project } = useProject()
  const yearsCount = project?.yearsCount ? project.yearsCount : 1
  const { modifiedFees } = useFeesAmountCalculation(yearsCount, fees)
  const transformNumberToMoney = useTransformNumberToMoney()

  return (
    <div className='row'>
      <div className='ap-flex justify-content-around'>
        {modifiedFees.map((item, index) => (
          <>
            <div
              className={classNames(
                !isLastElement && 'border-bottom-1 ap-border-color-background-border',
                `col col-sm-${12 / yearsCount} text-align-right ap-pr-spacing-4 ap-py-spacing-3`
              )}
            >
              <SDKText>{transformNumberToMoney(item.totalValue)}</SDKText>
            </div>
            {index !== modifiedFees.length - 1 && (
              <div className='border-right ap-my-spacing-4'></div>
            )}
          </>
        ))}
      </div>
    </div>
  )
}
