import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import {
  ApplicationInsights,
  ITelemetryItem,
  ITelemetryPlugin
} from '@microsoft/applicationinsights-web'

const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING,
    extensions: [reactPlugin as unknown as ITelemetryPlugin],
    extensionConfig: {},
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true
  }
})

appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
  env.tags = env.tags || []
  env.tags['ai.cloud.role'] = process.env.REACT_APP_NAME
})

export { appInsights, reactPlugin }
