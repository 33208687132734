import classNames from 'classnames'
import type { ISDKText } from '../Text'
import { SDKText } from '../Text'

import './index.scss'

export interface ISDKTextButton extends ISDKText {
  children: string | JSX.Element | null
  onClick?: () => void
  disabled?: boolean
}

export const SDKTextButton = ({ children, onClick, disabled, ...props }: ISDKTextButton) => {
  if (!children) {
    return null
  }

  if (disabled) {
    return (
      <div>
        <SDKText {...props} className={classNames(['button', 'icon-not-allowed'])}>
          {children}
        </SDKText>
      </div>
    )
  }

  return (
    <div className='text-button' onClick={onClick}>
      <SDKText {...props} className={classNames(['button', props.className])}>
        {children}
      </SDKText>
    </div>
  )
}
