import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../../../context/app.context'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../store'
import {
  clearDeletePBITemplate,
  deletePBITemplate,
  getPBITemplates
} from '../../../../fiscalYearSetup/store/actions'
import * as selectors from '../../../../fiscalYearSetup/store/selectors'

export const useDeletePBITemplate = (yearId?: number, templateName?: string) => {
  const { t } = useTranslation('documents')
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()

  const { error, loading } = useSelector(selectors.deletePBITemplate)

  const onDelete = (id: number | string) => {
    if (yearId) {
      dispatch(deletePBITemplate({ templateId: Number(id), yearId }))
    } else {
      sendNotification({
        message: t('powerBITemplates.deleteTemplate.error', { name: templateName }),
        status: 'error'
      })
    }
  }

  useEffect(() => {
    if (loading === LoadingStatus.Failed && error?.length) {
      sendNotification({
        message: error || t('powerBITemplates.deleteTemplate.error', { name: templateName }),
        status: 'error'
      })
    } else if (loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t('powerBITemplates.deleteTemplate.success', { name: templateName }),
        status: 'success'
      })
      dispatch(getPBITemplates({ yearId: yearId! }))
    }
    dispatch(clearDeletePBITemplate())
  }, [loading])

  return { onDelete, loading }
}
