import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { SDKText, TTextType } from '../../../../../../components/SDK/Text'
import { usePricingTab } from '../../../../../../context/pricingTab.context'
import { useCardGridColumnCalculation } from '../../../../../../utils/useCardGridColumnCalculation'
import { useTransformNumberToMoney } from '../../../../utils/useTransformNumberToMoney'
import type { IParentScopeYearTotal } from '../../types/scope'
import './style.scss'

export const FeesList = ({
  fees,
  keyItem,
  shouldDisplayValues,
  textType = 'subheading',
  textClassNames,
  weight = 1,
  gridValues,
  flexStyles = 'justify-content-around',
  localFile,
  discountModule,
  showQuotesWithFractionDigits
}: {
  fees: IParentScopeYearTotal[]
  keyItem: string
  shouldDisplayValues?: boolean
  textType?: TTextType
  textClassNames?: string
  weight?: 1 | 2 | 3
  gridValues?: number[]
  flexStyles?: string
  localFile?: boolean
  discountModule?: boolean
  showQuotesWithFractionDigits?: boolean
}) => {
  const { t } = useTranslation('pricing')
  const { feeCol, feeItemCol } = useCardGridColumnCalculation()
  const { priceYears } = usePricingTab()
  const transformNumberToMoney = useTransformNumberToMoney()
  const discountCssStyle: CSSProperties = discountModule ? { padding: 0 } : {}

  return (
    <div
      className={`fees-list-container col ${gridValues ? `col-${gridValues[1]}` : `col-${feeCol}`}`}
      style={discountCssStyle}
    >
      <div
        className={`${flexStyles} ${localFile || discountModule ? 'ap-flex' : 'row w-100'}`}
        style={discountCssStyle}
      >
        {fees?.map((item, index) => (
          <div
            key={`${keyItem}-${item.totalValue}-${index}`}
            className={
              localFile
                ? `local-file-width ${!(index < priceYears.length) && 'hidden'}`
                : `col col-${feeItemCol} text-ellipsis`
            }
            style={discountCssStyle}
          >
            {/* TODO temporary solution - remove when it will base on BE */}
            {index < priceYears.length ? (
              <>
                {shouldDisplayValues ? (
                  <SDKText type={textType} className={textClassNames} weight={weight}>
                    {transformNumberToMoney(item.totalValue, showQuotesWithFractionDigits)}
                  </SDKText>
                ) : (
                  <SDKText weight={weight} type={textType} className={textClassNames}>{`${t(
                    `tabs.inputs.fy`,
                    {
                      year: item.yearName
                    }
                  )}`}</SDKText>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
