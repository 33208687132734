import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '../../../../../../components/ActionButton'
import { LoadingOverlay } from '../../../../../../components/SDK/LoadingOverlay'
import { SDKText } from '../../../../../../components/SDK/Text'
import { usePricingTab } from '../../../../../../context/pricingTab.context'
import { LoadingStatus } from '../../../../../../shared/types/enums'
import { useTransformNumberToMoney } from '../../../../utils/useTransformNumberToMoney'
import { ELocalFileSyncType } from '../../const/enums'
import { ELocalFile } from '../../types'
import { IParentComplexScope } from '../../types/scope'
import { useDownloadResults } from './utils/useDownloadResults'

interface IScopesWrapperProps {
  children: JSX.Element
  totalFee?: number
  withIcons?: boolean
  localFileSynchronization?: ELocalFileSyncType | undefined
  setUploadModal?: Dispatch<SetStateAction<ELocalFile | null>>
  parentScope?: IParentComplexScope[]
  title?: string
}
export const ScopesWrapper = ({
  children,
  totalFee,
  withIcons,
  localFileSynchronization,
  setUploadModal,
  parentScope,
  title
}: IScopesWrapperProps) => {
  const { t } = useTranslation('pricing')
  const {
    tabContent: { tab }
  } = usePricingTab()
  const { handleDownloadResults, loading } = useDownloadResults()
  const transformNumberToMoney = useTransformNumberToMoney()
  const pricingOptionsUrl =
    'https://taxsource.pwc.com/-/media/Tax-Source/Article-Attachments-Bucket/2023/02/03/21/12/GCD--Pricing-Options-for-Local-PwC-Involvement--Final-072823-For-internal-use-only-pdf.ashx?rev=697250a2ab484cb3b7fbbb98b2c730bc&revision=697250a2-ab48-4cb3-b7fb-bb98b2c730bc&hash=0CF3C93EE93E35203C05697471C40BDE551183B6'
  const toolkitRepositoryUrl =
    'https://taxsource.pwc.com/95473c9cfa6c4a9995820de81d0c9113/Publications/2023/01/17/07/07/GCD-Toolkit-Repository?showBreadcrumbs=true'

  return (
    <>
      {loading === LoadingStatus.Pending && <LoadingOverlay />}
      <div className='ap-bg-color-background-default ap-p-spacing-5 ap-border-radius-2'>
        <div className='ap-flex justify-content-between ap-pb-spacing-3'>
          <div className='ap-flex align-items-center'>
            <SDKText type='heading' weight={2} className='ap-text-color-text-heading'>
              {title
                ? `${title} ${t(`tabs.inputs.results`)}`
                : `${t(`inputs.tabs.${tab}.tabTitle`)} ${t(`tabs.inputs.results`)}`}
            </SDKText>
            {withIcons && (
              <div className='ap-flex align-items-center ap-ml-spacing-7'>
                <ActionButton
                  content={t('tabs.inputs.localFile.table.downloadButtonDescription')}
                  icon='download-light'
                  handleClick={() => handleDownloadResults()}
                  disabled={parentScope && parentScope.length === 0}
                  tooltipStyle={{ whiteSpace: 'pre-wrap' }}
                />
                <ActionButton
                  content={t('tabs.inputs.localFile.table.uploadButtonDescription')}
                  icon='upload-light'
                  handleClick={() => setUploadModal?.(ELocalFile.Results)}
                  disabled={parentScope && parentScope.length === 0}
                  tooltipStyle={{ whiteSpace: 'pre-wrap' }}
                />
                <ActionButton
                  content={t('tabs.inputs.localFile.table.pricingOptionsDescription')}
                  icon='cost-dollar'
                  handleClick={() => window.open(pricingOptionsUrl, 'blank')}
                  tooltipStyle={{ whiteSpace: 'pre-wrap' }}
                />
                <ActionButton
                  content={t('tabs.inputs.localFile.table.toolkitRepositoryDescription')}
                  icon='service'
                  handleClick={() => window.open(toolkitRepositoryUrl, 'blank')}
                  tooltipStyle={{ whiteSpace: 'pre-wrap' }}
                />
                {localFileSynchronization === ELocalFileSyncType.Synced && (
                  <ActionButton
                    content={t('tabs.inputs.localFile.table.tableSyncedDescription')}
                    icon='link'
                    handleClick={() => null}
                    tooltipStyle={{ whiteSpace: 'pre-wrap' }}
                  />
                )}
                {localFileSynchronization === ELocalFileSyncType.OutOfSync && (
                  <ActionButton
                    content={t('tabs.inputs.localFile.table.tableNotSyncedDescription')}
                    icon='link-unlinked'
                    handleClick={() => null}
                    tooltipStyle={{ whiteSpace: 'pre-wrap' }}
                  />
                )}
              </div>
            )}
          </div>
          {totalFee !== undefined && (
            <SDKText type='subheading' weight={2} className='ap-text-neutral-14'>
              {title
                ? `${title} ${t(`tabs.inputs.totalFee`)} ${transformNumberToMoney(totalFee)}`
                : `${t(`inputs.tabs.${tab}.tabTitle`)} ${t(
                    `tabs.inputs.totalFee`
                  )} ${transformNumberToMoney(totalFee)}`}
            </SDKText>
          )}
        </div>

        <div>{children}</div>
      </div>
    </>
  )
}
