import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Url } from '../../../../../../constants/urls'
import { useAppDispatch } from '../../../../../../store'
import { DocumentListItem } from '../../../../components/DocumentListItem'
import { EDITING_EMAIL_TEMPLATE } from '../../../../constants'
import * as actions from '../../../../store/actions'
import { getEmailTemplates } from '../../../../store/selectors'

export const EmailTemplatesList = () => {
  const { t } = useTranslation('systemManagement')
  const navigation = useNavigate()
  const dispatch = useAppDispatch()
  const { data } = useSelector(getEmailTemplates)
  const mappedData = data.map(item => ({ name: item.templateName, id: item.templateId }))

  const onEdit = (templateId: string) => {
    navigation(`${location.pathname}${Url.EditEmailTemplatePage}/${templateId}`)
    dispatch(actions.clearUpdateEmailTemplate())
    const emailTemplate = data.find(item => item.templateId === templateId)
    localStorage.setItem(EDITING_EMAIL_TEMPLATE, JSON.stringify(emailTemplate))
  }

  return (
    <div className='row'>
      {mappedData.map(item => (
        <div key={item.id} className='col-md-4'>
          <DocumentListItem
            {...item}
            idTitle={t('templateID')}
            onEdit={templateId => onEdit(templateId)}
          />
        </div>
      ))}
    </div>
  )
}
