import { object, string } from 'yup'
import { ICustomModule } from '../modules/project/components/GCDPricing/types/inputsTab'

export interface IFormValues {
  name: string
}

export const useAddEditCustomModuleSchema = (customModule: ICustomModule | null) => {
  const getDefaultValues = (customModuleData: ICustomModule | null) => ({
    name: customModuleData?.name || ''
  })

  const defaultValues = getDefaultValues(customModule)

  const validationSchema = object()
    .shape({
      name: string().required()
    })
    .required()

  return {
    defaultValues,
    getDefaultValues,
    validationSchema
  }
}
