import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { EAction } from '../../../../../../components/CardDnDWrapper/const'
import { LoadingOverlay } from '../../../../../../components/SDK/LoadingOverlay'
import { LoadingStatus } from '../../../../../../shared/types/enums'
import { useTableItems } from '../../hooks/useTableItems'
import { updateChildScopeOrder, updateParentScopeOrder } from '../../modals/store/selectors'
import { ITransformedData, TableType } from '../../types/dnd'
import type { IBenchmarkQuotes, IQuote, ITransactionalModule } from '../../types/inputsTab'
import type { IParentScopeYearTotal } from '../../types/scope'
import { DraggableTable } from '../DraggableTable'

interface IDragLayerProps {
  transformedData: ITransformedData
  benchmarkQuotes?: IBenchmarkQuotes
  quotes?: Partial<IQuote>
  transactionalModules?: ITransactionalModule[]
  transactionalModulesTotals?: IParentScopeYearTotal[]
  customScopeWrapperTitle?: string
  withoutFeesResult?: boolean
  actions?: EAction[]
}

export const DragLayer = ({
  transformedData,
  quotes,
  benchmarkQuotes,
  transactionalModules,
  transactionalModulesTotals,
  customScopeWrapperTitle,
  withoutFeesResult = false,
  actions
}: IDragLayerProps) => {
  const [content, setContent] = useState<ITransformedData>(transformedData)
  const { loading: childLoading } = useSelector(updateChildScopeOrder)
  const { loading: parentLoading } = useSelector(updateParentScopeOrder)
  const { returnItemsForTable, moveRowHandler, loading } = useTableItems(
    content,
    setContent,
    actions,
    benchmarkQuotes,
    quotes
  )
  useEffect(() => setContent(transformedData), [transformedData])

  const isLoading =
    childLoading === LoadingStatus.Pending ||
    parentLoading === LoadingStatus.Pending ||
    loading === LoadingStatus.Pending

  return (
    <div>
      {isLoading && <LoadingOverlay />}
      {content.tables.map((table, index) => (
        <DraggableTable
          key={table.id}
          tableIndex={index}
          moveRowHandler={moveRowHandler}
          table={table}
          fees={table.tableName === TableType.Scope ? quotes?.parentScopeYearTotals : undefined}
          benchmarkFees={table.tableName === TableType.Scope ? benchmarkQuotes : undefined}
          transactionalModules={transactionalModules}
          transactionalModulesTotals={transactionalModulesTotals}
          customScopeWrapperTitle={customScopeWrapperTitle}
          withoutFeesResult={withoutFeesResult}
        >
          <div
            className={classNames(
              `${
                table.tableName === TableType.ScopeIncludes ||
                (table.tableName === TableType.ScopeExcludes && 'ap-bg-color-background-alt')
              }`
            )}
          >
            <div
              className={`${
                table.tableName === TableType.ScopeExcludes &&
                'ap-bg-color-background-default ap-px-spacing-5 ap-pt-spacing-1'
              }`}
            >
              {returnItemsForTable(index, table.id, table.tableName)}
            </div>
          </div>
        </DraggableTable>
      ))}
    </div>
  )
}
