import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'
import type { IScopeQuestions } from '../modules/project/components/GCDPricing/types/scope'

export enum EEScopeFields {
  clientName = 'clientName',
  yearsCount = 'yearsCount',
  countryId = 'countryId'
}
export interface IFormValues {
  [EEScopeFields.clientName]: string
  [EEScopeFields.yearsCount]: string
  [EEScopeFields.countryId]: string
}

export const useScopeDefinitionSchema = (scope: IScopeQuestions | null) => {
  const { t } = useTranslation()

  const defaultValues = {
    [EEScopeFields.clientName]: scope?.clientName || '',
    [EEScopeFields.yearsCount]: scope?.yearsCount || '0',
    [EEScopeFields.countryId]: scope?.countryId || '0'
  }

  const validationSchema = object()
    .shape({
      [EEScopeFields.clientName]: string().required(t('form.requiredFields')!)
    })
    .required()

  return { validationSchema, defaultValues }
}
