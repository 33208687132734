import { useMemo } from 'react'
import type { IOption } from '../../../../../../../types/form'
import type { Currency } from '../../../../../../../types/project'
import type { FiscalYearBase } from '../../../../../../fiscalYearSetup/types'

export const useCountriesData = (currencies: Currency[] | null, years: FiscalYearBase[]) => {
  const currencyList: IOption[] = useMemo(
    () =>
      currencies?.map(currency => ({
        value: String(currency.currencyId),
        label: currency.symbol
      })) || [],
    [currencies]
  )

  const yearList: IOption[] = useMemo(
    () =>
      years?.map(year => ({
        value: String(year.yearId),
        label: year.name
      })) || [],
    [years]
  )

  return { currencyList, yearList }
}
