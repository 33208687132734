import { useEffect, useState } from 'react'
import { useApp } from '../../context/app.context'
import { LoadingStatus } from '../../shared/types/enums'
import { SDKCheckbox } from '../SDK/Checkbox'
import { SDKIcon } from '../SDK/Icon'
import { LoadingOverlay } from '../SDK/LoadingOverlay'
import { SDKModal } from '../SDK/Modal'
import { SDKText } from '../SDK/Text'

interface IDeleteModalProps {
  loading: LoadingStatus
  displayTextFromTranslation: (text: string) => string
  onSubmit: () => void
  onError?: () => void
  onSuccess?: () => void
  onModalClose?: () => void
  error?: string | null
}
export const DeleteModal = ({
  displayTextFromTranslation,
  onSubmit,
  onModalClose,
  onError,
  onSuccess,
  error,
  loading
}: IDeleteModalProps) => {
  const { sendNotification } = useApp()

  const [isAgreed, setIsAgreed] = useState(false)

  const onClose = () => {
    setIsAgreed(false)
    onModalClose?.()
  }

  useEffect(() => {
    if (error?.length) {
      onError?.()
      sendNotification({
        message: error,
        status: 'error'
      })
      onClose()
    }
    if (loading === LoadingStatus.Succeeded) {
      onSuccess?.()
      sendNotification({
        message: displayTextFromTranslation('successInfo'),
        status: 'success'
      })
      onClose()
    }
  }, [error, loading])

  return (
    <SDKModal
      onCancel={onClose}
      title={displayTextFromTranslation('title')}
      submitLabel={displayTextFromTranslation('submit')}
      onSubmit={onSubmit}
      withRequired={false}
      disabledSubmit={!isAgreed}
      withCancel
      visible
    >
      <div className='w-100'>
        {loading === LoadingStatus.Pending && <LoadingOverlay type='modal' />}
        <SDKText>{displayTextFromTranslation('text')}</SDKText>
        <SDKCheckbox
          onChange={(checked: boolean) => setIsAgreed(checked)}
          label={displayTextFromTranslation('checkboxLabel')!}
          className='ap-pt-spacing-3'
        />
        {isAgreed && (
          <div className='ap-bg-color-background-default ap-border-radius-2 ap-my-spacing-3 ap-p-spacing-5 ap-flex'>
            <SDKIcon code='warning' outline={false} className='ap-text-primary-orange-05' />
            <SDKText>{displayTextFromTranslation('info')}</SDKText>
          </div>
        )}
      </div>
    </SDKModal>
  )
}
