import { IAddEditLegalDocument } from '../../../modules/systemManagement/types'
import api from '../../api'
import { SystemManagementUrl } from '../../constants/urls'

export interface IAddLegalDocumentPayload {
  uniqueIdentifier: string
  name: string
  content: string
  requiresApproval: boolean
}

export const addLegalDocumentData = async (
  params: IAddLegalDocumentPayload
): Promise<IAddEditLegalDocument> => {
  return (await api.post(`${SystemManagementUrl.LegalDocuments}`, params)).data
}
