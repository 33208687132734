import type { IRegion } from '../../../modules/regions/types'
import api from '../../api'
import { FiscalYearUrl, RegionUrl } from '../../constants/urls'

export interface IEditRegionPayload {
  name: string
}

export const editRegionData = async (
  yearId: number,
  regionId: number,
  params: IEditRegionPayload
): Promise<IRegion> => {
  return (
    await api.put(`${FiscalYearUrl.Years}/${yearId}/${RegionUrl.Regions}/${regionId}`, params)
  ).data
}
