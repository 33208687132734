import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../../../context/app.context'
import { useSightlineVisibility } from '../../../../../context/hooks/useSightlineVisibility'
import { useProject } from '../../../../../context/project.context'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../store'
import { EExportAction, SIGHTLINE_ACTIONS } from '../../../constants'
import { clearExportProject, exportProject } from '../../../store/actions'
import * as selectors from '../../../store/selectors'
import { useExportProjectFromSightline } from './useExportProjectFromSightline'

export const useDropdownData = (
  projectId: number,
  setShowLoadingModal: (value: boolean) => void,
  setShowSendExcelToSightlineModal: (value: boolean) => void
) => {
  const { t } = useTranslation('myprojects')
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { visible: isSightlineVisible } = useSightlineVisibility()
  const { project } = useProject()
  const { error: exportError, loading: exportLoading } = useSelector(selectors.getExportProject)

  const { onExportProjectFromSightline } = useExportProjectFromSightline(
    setShowSendExcelToSightlineModal
  )

  const onExport = () => {
    if (project?.countrySnapshotCountryIds?.length) {
      setShowLoadingModal(true)
      dispatch(exportProject(String(projectId)))
    } else {
      sendNotification({
        message: t('projectDetail.projectTabs.countrySnapshot.errors.noPublishedCountry'),
        status: 'error'
      })
    }
  }

  useEffect(() => {
    if (exportError?.length) {
      sendNotification({
        message: exportError,
        status: 'error'
      })
      dispatch(clearExportProject())
    }
  }, [exportError])

  useEffect(() => {
    if (exportLoading !== LoadingStatus.Pending) {
      setShowLoadingModal(false)
    }
  }, [exportLoading])

  let dropdownData = [
    {
      value: EExportAction.DownloadExcel,
      label: t('projectDetail.projectTabs.tara.results.exportDropdownList.downloadExcel'),
      iconName: 'xls',
      onClick: () => onExport()
    },
    {
      value: EExportAction.SendExcelToSightline,
      label: t('projectDetail.projectTabs.tara.results.exportDropdownList.sendExcelToSightline'),
      iconName: 'paper-airplane',
      onClick: () => onExportProjectFromSightline()
    }
  ]

  if (!isSightlineVisible || !project?.tpEngagementId) {
    dropdownData = dropdownData.filter(item => !SIGHTLINE_ACTIONS.includes(item.value))
  }
  const defaultExportAction = project?.tpEngagementId ? onExportProjectFromSightline : onExport

  return { dropdownData, defaultExportAction }
}
