import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { Module } from '../constants/modules'
import TaraFieldsReducer from '../modules/TaraFields/store/reducer'
import CountriesReducer from '../modules/countries/store/reducer'
import CountriesSnapshotReducer from '../modules/countriesSnapshot/store/reducer'
import CountriesSnapshotMFEReducer from '../modules/countrySnapshotMFE/store/reducer'
import FiscalYearReducer from '../modules/fiscalYearSetup/store/reducer'
import MyProjectsReducer from '../modules/myprojects/store/reducer'
import PricingHelperReducer from '../modules/project/components/GCDPricing/modals/store/reducer'
import PricingReducer from '../modules/project/components/GCDPricing/store/reducer'
import ProjectReducer from '../modules/project/store/reducer'
import QuestionnaireReducer from '../modules/questionnaires/store/reducer'
import RegionsReducer from '../modules/regions/store/reducer'
import SearchReducer from '../modules/search/store/reducer'
import SystemManagement from '../modules/systemManagement/store/reducer'
import UpdatesReducer from '../modules/updates/store/reducer'
import UserReducer from '../modules/users/store/reducer'

export const reducers = {
  [Module.Countries]: CountriesReducer,
  [Module.CountriesSnapshot]: CountriesSnapshotReducer,
  [Module.CountriesSnapshotMFE]: CountriesSnapshotMFEReducer,
  [Module.FiscalYear]: FiscalYearReducer,
  [Module.MyProjects]: MyProjectsReducer,
  [Module.Pricing]: PricingReducer,
  [Module.PricingHelper]: PricingHelperReducer,
  [Module.Project]: ProjectReducer,
  [Module.Questionnaire]: QuestionnaireReducer,
  [Module.Regions]: RegionsReducer,
  [Module.Search]: SearchReducer,
  [Module.SystemManagement]: SystemManagement,
  [Module.TaraFields]: TaraFieldsReducer,
  [Module.User]: UserReducer,
  [Module.Updates]: UpdatesReducer
}

export const store = configureStore({
  reducer: combineReducers({ ...reducers })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
