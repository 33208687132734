import { createReducer } from '@reduxjs/toolkit'
import {
  getDefaultResourceState,
  Resource,
  setResourceFullfilled,
  setResourcePending,
  setResourceRejected
} from '../../../shared/utils/store'
import type { TCategory, TQuestion, TSubcategory } from '../types'
import {
  addCategory,
  addQuestion,
  addSubcategory,
  clearAddCategory,
  clearAddQuestion,
  clearAddSubcategory,
  clearCategories,
  clearDeleteCategory,
  clearDeleteQuestion,
  clearDeleteSubcategory,
  clearQuestions,
  clearSwapQuestionsOrder,
  clearUpdateCategory,
  clearUpdateQuestion,
  clearUpdateSubcategory,
  deleteCategory,
  deleteQuestion,
  deleteSubcategory,
  getCategories,
  getQuestions,
  swapQuestionsOrder,
  updateCategory,
  updateSingleQuestion,
  updateSubcategory
} from './actions'

export interface CountriesSnapshotState {
  addCategory: Resource<TCategory | null>
  addQuestion: Resource<TQuestion | null>
  addSubcategory: Resource<TSubcategory | null>
  categories: Resource<TCategory[]>
  deleteCategory: Resource<string | null>
  deleteQuestion: Resource<string | null>
  swapQuestionsOrder: Resource<string | null>
  deleteSubcategory: Resource<string | null>
  questions: Resource<TQuestion[]>
  updateCategory: Resource<TCategory | null>
  updateQuestion: Resource<TQuestion | null>
  updateSubcategory: Resource<TSubcategory | null>
}

const initialState: CountriesSnapshotState = {
  addCategory: getDefaultResourceState(null),
  addQuestion: getDefaultResourceState(null),
  addSubcategory: getDefaultResourceState(null),
  categories: getDefaultResourceState([]),
  deleteCategory: getDefaultResourceState(null),
  deleteQuestion: getDefaultResourceState(null),
  swapQuestionsOrder: getDefaultResourceState(null),
  deleteSubcategory: getDefaultResourceState(null),
  questions: getDefaultResourceState([]),
  updateCategory: getDefaultResourceState(null),
  updateQuestion: getDefaultResourceState(null),
  updateSubcategory: getDefaultResourceState(null)
}

export default createReducer(initialState, builder =>
  builder
    //Categories
    .addCase(getCategories.pending, state => {
      setResourcePending(state.categories)
    })
    .addCase(getCategories.rejected, (state, actions) => {
      setResourceRejected(state.categories, actions)
    })
    .addCase(getCategories.fulfilled, (state, actions) => {
      state.categories.data = actions.payload
      setResourceFullfilled(state.categories)
    })
    .addCase(clearCategories, state => {
      state.categories = initialState.categories
    })
    .addCase(addCategory.pending, state => {
      setResourcePending(state.addCategory)
    })
    .addCase(addCategory.rejected, (state, actions) => {
      setResourceRejected(state.addCategory, actions)
    })
    .addCase(addCategory.fulfilled, (state, actions) => {
      state.addCategory.data = actions.payload
      setResourceFullfilled(state.addCategory)
    })
    .addCase(clearAddCategory, state => {
      state.addCategory = initialState.addCategory
    })
    .addCase(updateCategory.pending, state => {
      setResourcePending(state.updateCategory)
    })
    .addCase(updateCategory.rejected, (state, actions) => {
      setResourceRejected(state.updateCategory, actions)
    })
    .addCase(updateCategory.fulfilled, (state, actions) => {
      setResourceFullfilled(state.updateCategory)
      state.updateCategory.data = actions.payload
    })
    .addCase(clearUpdateCategory, state => {
      state.updateCategory = initialState.updateCategory
    })

    .addCase(deleteCategory.pending, state => {
      setResourcePending(state.deleteCategory)
    })
    .addCase(deleteCategory.rejected, (state, actions) => {
      setResourceRejected(state.deleteCategory, actions)
    })
    .addCase(deleteCategory.fulfilled, (state, actions) => {
      setResourceFullfilled(state.deleteCategory)
      state.deleteCategory.data = actions.payload
    })
    .addCase(clearDeleteCategory, state => {
      state.deleteCategory = initialState.deleteCategory
    })

    //Subcategories
    .addCase(addSubcategory.pending, state => {
      setResourcePending(state.addSubcategory)
    })
    .addCase(addSubcategory.rejected, (state, actions) => {
      setResourceRejected(state.addSubcategory, actions)
    })
    .addCase(addSubcategory.fulfilled, (state, actions) => {
      state.addSubcategory.data = actions.payload
      setResourceFullfilled(state.addSubcategory)
    })
    .addCase(clearAddSubcategory, state => {
      state.addSubcategory = initialState.addSubcategory
    })
    .addCase(updateSubcategory.pending, state => {
      setResourcePending(state.updateSubcategory)
    })
    .addCase(updateSubcategory.rejected, (state, actions) => {
      setResourceRejected(state.updateSubcategory, actions)
    })
    .addCase(updateSubcategory.fulfilled, (state, actions) => {
      setResourceFullfilled(state.updateSubcategory)
      state.updateSubcategory.data = actions.payload
    })
    .addCase(clearUpdateSubcategory, state => {
      state.updateSubcategory = initialState.updateSubcategory
    })

    .addCase(deleteSubcategory.pending, state => {
      setResourcePending(state.deleteSubcategory)
    })
    .addCase(deleteSubcategory.rejected, (state, actions) => {
      setResourceRejected(state.deleteSubcategory, actions)
    })
    .addCase(deleteSubcategory.fulfilled, (state, actions) => {
      setResourceFullfilled(state.deleteSubcategory)
      state.deleteSubcategory.data = actions.payload
    })
    .addCase(clearDeleteSubcategory, state => {
      state.deleteSubcategory = initialState.deleteSubcategory
    })

    //Questions
    .addCase(getQuestions.pending, state => {
      setResourcePending(state.questions)
    })
    .addCase(getQuestions.rejected, (state, actions) => {
      setResourceRejected(state.questions, actions)
    })
    .addCase(getQuestions.fulfilled, (state, actions) => {
      state.questions.data = actions.payload
      setResourceFullfilled(state.questions)
    })
    .addCase(clearQuestions, state => {
      state.questions = initialState.questions
    })

    .addCase(addQuestion.pending, state => {
      setResourcePending(state.addQuestion)
    })
    .addCase(addQuestion.rejected, (state, actions) => {
      setResourceRejected(state.addQuestion, actions)
    })
    .addCase(addQuestion.fulfilled, (state, actions) => {
      state.addQuestion.data = actions.payload
      setResourceFullfilled(state.addQuestion)
    })
    .addCase(clearAddQuestion, state => {
      state.addQuestion = initialState.addQuestion
    })

    .addCase(deleteQuestion.pending, state => {
      setResourcePending(state.deleteQuestion)
    })
    .addCase(deleteQuestion.rejected, (state, actions) => {
      setResourceRejected(state.deleteQuestion, actions)
    })
    .addCase(deleteQuestion.fulfilled, (state, actions) => {
      setResourceFullfilled(state.deleteQuestion)
      state.deleteQuestion.data = actions.payload
    })
    .addCase(clearDeleteQuestion, state => {
      state.deleteQuestion = initialState.deleteQuestion
    })

    .addCase(swapQuestionsOrder.pending, state => {
      setResourcePending(state.swapQuestionsOrder)
    })
    .addCase(swapQuestionsOrder.rejected, (state, actions) => {
      setResourceRejected(state.swapQuestionsOrder, actions)
    })
    .addCase(swapQuestionsOrder.fulfilled, (state, actions) => {
      setResourceFullfilled(state.swapQuestionsOrder)
      state.swapQuestionsOrder.data = actions.payload
    })
    .addCase(clearSwapQuestionsOrder, state => {
      state.deleteQuestion = initialState.swapQuestionsOrder
    })

    .addCase(updateSingleQuestion.pending, state => {
      setResourcePending(state.updateQuestion)
    })
    .addCase(updateSingleQuestion.rejected, (state, actions) => {
      setResourceRejected(state.updateQuestion, actions)
    })
    .addCase(updateSingleQuestion.fulfilled, (state, actions) => {
      setResourceFullfilled(state.updateQuestion)
      state.updateQuestion.data = actions.payload
    })
    .addCase(clearUpdateQuestion, state => {
      state.updateQuestion = initialState.updateQuestion
    })
)
