import { ESightlineRedirectAction } from '../../../modules/project/constants'
import { ResponseCode } from '../../../shared/types/enums'
import { authorizeSightline } from '../../../shared/utils/authorizeSightline'
import api from '../../api'
import { DataExportUrl, ProjectsUrl, UploadUrl } from '../../constants/urls'

export const uploadToSightline = async (
  params: { countryIds: number[]; projectId: number },
  rejectWithValue: any
): Promise<string> => {
  const { projectId, countryIds } = params
  const urlIds = countryIds
    .map((id, i) => (i === 0 ? `countriesIds=${id}` : `&countriesIds=${id}`))
    .join('')
  const project = `projectId=${projectId}`

  try {
    const response = await api.get(
      `${DataExportUrl.DataExport}/${ProjectsUrl.Template}/${UploadUrl.uploadTemplateToSightline}?${urlIds}&${project}`
    )
    return response.data
  } catch (error: any) {
    if (error.code === ResponseCode.Unauthorized) {
      authorizeSightline(error.message, ESightlineRedirectAction.UploadToSightline)
      return ''
    } else {
      return rejectWithValue(error.message)
    }
  }
}
