import type { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormInput } from '../../../../../../../../../../components/Form/FormInput'
import type { IFormValues } from '../../../../../../../../../../schemas/useAddEditCustomModuleSchema'

export const AddEditCustomModuleForm = ({ control }: { control: Control<IFormValues, any> }) => {
  const { t } = useTranslation('pricing')

  return (
    <div className='ap-container add-edit-custom-module-form'>
      <div className='form-container'>
        <div className='row'>
          <div className='col'>
            <FormInput
              name={'name'}
              type='text'
              label={t('tabs.addModule.modal.form.name.label')!}
              required={true}
              className='ap-mb-spacing-5'
              control={control}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
