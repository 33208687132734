import { useTranslation } from 'react-i18next'
import { ActionButton } from '../../../../../../../../components/ActionButton'
import { CardFees } from '../../../../../../../../components/CardDnDWrapper/components/cardItems/CardFees'
import { SDKBadge } from '../../../../../../../../components/SDK/Badge'
import { SDKIcon } from '../../../../../../../../components/SDK/Icon'
import { SDKText } from '../../../../../../../../components/SDK/Text'
import { usePricingTab } from '../../../../../../../../context/pricingTab.context'
import { EComplexity, EScope } from '../../../../const/enums'
import { getParentScopeModalTab } from '../../../../modals/ParentScopeModal/const'
import { IQuote } from '../../../../types/inputsTab'
import { IParentComplexScope } from '../../../../types/scope'

export const useTableColumn = (quotes: IQuote) => {
  const { t, i18n } = useTranslation('pricing')
  const { priceYears } = usePricingTab()
  const {
    triggerParentScopeModal,
    tabContent: { tab }
  } = usePricingTab()

  const renderBadge = (row: IParentComplexScope) => {
    const text = t(`tabs.inputs.localFile.table.complexityValue.${row.complexity}`)
    if (
      row.scopeLocationType != EScope.CentralPreparation &&
      row.scopeLocationType != EScope.LocalPreparationBasedOnCentralModules
    ) {
      return 'N/A'
    }

    switch (row.complexity) {
      case EComplexity.Small:
        return <SDKBadge value={text} type='success' />
      case EComplexity.Medium:
        return <SDKBadge value={text} type='warning' />
      case EComplexity.Large:
        return <SDKBadge value={text} type='danger' />
      default:
        return 'N/A'
    }
  }

  const renderTranslation = (value: boolean) => (
    <div className='ap-flex align-items-center'>
      {value && (
        <SDKIcon
          code='circle-checkmark'
          outline={false}
          size={16}
          className='ap-text-states-success-05'
        />
      )}
      <SDKText
        type='body-s'
        className={`${value ? 'ap-text-states-success-05' : 'ap-text-neutral-14'}`}
      >
        {t(`tabs.inputs.localFile.table.translationValue.${value}`)}
      </SDKText>
    </div>
  )

  const columns = [
    {
      key: 'entityName',
      renderCell: (row: IParentComplexScope) => (
        <SDKText className='text-ellipsis ap-block'>{row.entityName}</SDKText>
      )
    },
    { key: 'region' },
    { key: 'country' },
    {
      key: 'scopeLocationType',
      renderCell: (row: IParentComplexScope) => (
        <SDKText className='text-ellipsis ap-block'>
          {t(`tabs.inputs.localFile.table.scopeValue.${row.scopeLocationType}`)}
        </SDKText>
      )
    },
    { key: 'complexity', renderCell: (row: IParentComplexScope) => renderBadge(row) },
    {
      key: 'translation',
      renderCell: (row: IParentComplexScope) =>
        row.translation ? renderTranslation(row.translation) : i18n.t('na')
    },
    {
      key: 'priceYears',
      class: `year-count-${priceYears.length}`,
      renderCell: (row: IParentComplexScope) => (
        <CardFees
          withoutLeftBorder={true}
          fees={row.priceYears}
          isMobile={false}
          id={row.parentScopeId}
          quotes={quotes}
        />
      )
    },
    {
      key: 'parentScopeId',
      class: 'row-width',
      renderCell: (row: IParentComplexScope) => (
        <ActionButton
          content=''
          icon='edit'
          handleClick={() => {
            triggerParentScopeModal(
              {
                ...row
              },
              getParentScopeModalTab(tab)
            )
          }}
        />
      )
    }
  ]

  return { columns }
}
