export const REFRESH_INTERVAL = 20 * 60 * 1000

export enum ELocalStorageStyling {
  IsMobile = 'isMobile',
  Mode = 'mode',
  Theme = 'theme'
}

export enum ESessionStorage {
  AppMode = 'app-mode',
  ShouldRefresh = 'should-refresh'
}

export enum PwcCookiesConsentOption {
  AcceptAll = 'acceptAll',
  DeclineOptional = 'declineOptional'
}
