import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormInput } from '../../../../components/Form/FormInput'
import { SDKModal } from '../../../../components/SDK/Modal'
import { SDKTooltip } from '../../../../components/SDK/Tooltip'
import { EAddCategoryFields, useAddCategorySchema } from '../../../../schemas/useAddCategorySchema'
import * as selectors from '../../store/selectors'
import { useAddEditCategory } from './utils/useAddEditCategory'

interface IAddCategoryModal {
  visible: boolean
  setVisible: (data: boolean) => void
  categoryId: number | null
  yearId?: number
}

export const AddEditCategoryModal = ({
  visible,
  setVisible,
  categoryId,
  yearId
}: IAddCategoryModal) => {
  const { t } = useTranslation('countriesSnapshot')
  const { error } = categoryId
    ? useSelector(selectors.getUpdateCategory)
    : useSelector(selectors.getAddCategory)
  const { validationSchema, defaultValues } = useAddCategorySchema(categoryId)
  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid, isDirty },
    setError
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'all',
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    if (error && error.includes('already exists')) {
      setError(EAddCategoryFields.category, {
        message: `${t('newCategory.form.errors.categoryExist')}`
      })
    }
  }, [error])

  const { onSubmit, onClose } = useAddEditCategory(setVisible, reset, categoryId, yearId)

  return (
    <form>
      <SDKModal
        visible={visible}
        onCancel={onClose}
        title={t(categoryId !== null ? 'newCategory.form.titleEdit' : 'newCategory.form.titleAdd')!}
        disabledSubmit={categoryId ? !isDirty || !isValid : !isValid}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='ap-container add-fiscal-year-form'>
          <div className='form-container'>
            <div className='row ap-mt-spacing-2'>
              <div className='col'>
                <SDKTooltip disabled={isDirty} content={t('newCategory.form.tooltip')!}>
                  <FormInput
                    name={EAddCategoryFields.category}
                    type='text'
                    label={t('newCategory.form.fields.category')!}
                    required={true}
                    control={control}
                    suffixIcon
                  />
                </SDKTooltip>
              </div>
            </div>
          </div>
        </div>
      </SDKModal>
    </form>
  )
}
