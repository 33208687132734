import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Layout } from '../../components/Layout'
import { NavigationTileGrid } from '../../components/NavigationTileGrid'
import { Url } from '../../constants/urls'

const SystemManagement = () => {
  const { t } = useTranslation('systemManagement')
  const location = useLocation()

  const list = [
    {
      icon: 'email',
      name: t('emailTemplates.title'),
      url: `${location.pathname}${Url.EmailTemplatesPage}`
    },
    {
      icon: 'news',
      name: t('legalDocuments.title'),
      url: `${location.pathname}${Url.LegalDocumentsPage}`
    },
    {
      icon: 'audience',
      name: t('users.title'),
      url: `${location.pathname}${Url.UsersPage}`
    },
    {
      icon: 'news-report',
      name: t('reports.title'),
      url: `${location.pathname}${Url.ReportsPage}`
    },
    {
      icon: 'download',
      name: t('pricingToolImport.title'),
      url: `${location.pathname}${Url.PricingToolImportPage}`
    }
  ]

  return (
    <Layout>
      <div className='system-documents-page'>
        <NavigationTileGrid list={list} />
      </div>
    </Layout>
  )
}

export default SystemManagement
