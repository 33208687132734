import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Module } from '../../../../../constants/modules'
import { createCustomModuleRequest } from '../../../../../services/requests/pricing/createCustomModule'
import { exportResultsFlatRequest } from '../../../../../services/requests/pricing/exportResultsFlatRequest'
import { exportResultsFullRequest } from '../../../../../services/requests/pricing/exportResultsFullRequest'
import { exportResultsWithSightlineRequest } from '../../../../../services/requests/pricing/exportResultsWithSightlineRequest'
import { getBenchmarkData } from '../../../../../services/requests/pricing/getBenchmarkData'
import { getCbCrData } from '../../../../../services/requests/pricing/getCbCrData'
import { getCustomModuleData } from '../../../../../services/requests/pricing/getCustomModuleData'
import { getCustomModulesData } from '../../../../../services/requests/pricing/getCustomModulesData'
import { getDataCollectionData } from '../../../../../services/requests/pricing/getDataCollectionData'
import { getDiscountData } from '../../../../../services/requests/pricing/getDiscountData'
import { getLocalFileData } from '../../../../../services/requests/pricing/getLocalFileData'
import { getMasterFileData } from '../../../../../services/requests/pricing/getMasterFileData'
import { getPricingResultsData } from '../../../../../services/requests/pricing/getPricingResultsData'
import { getProjectManagementData } from '../../../../../services/requests/pricing/getProjectManagementData'
import { getProjectSetupData } from '../../../../../services/requests/pricing/getProjectSetupData'
import { getScopeAssumptionsData } from '../../../../../services/requests/pricing/getScopeAssumptionsData'
import { getScopeDefinitionData } from '../../../../../services/requests/pricing/getScopeDefinitionData'
import { getTPFormsData } from '../../../../../services/requests/pricing/getTPFormsData'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { ICustomModule } from '../types/inputsTab'
import { PricingState } from './reducer'

const moduleName: string = String(Module.Pricing)

export const getCustomModules = createAsyncThunk(
  `${moduleName}/getCustomModules`,
  (projectId: number) => getCustomModulesData(projectId)
)
export const clearCustomModules = createAction(`${moduleName}/clearCustomModules`)
export const createCustomModule = createAsyncThunk(
  `${moduleName}/createCustomModule`,
  ({ customModule, projectId }: { customModule: Partial<ICustomModule>; projectId: number }) =>
    createCustomModuleRequest(customModule, projectId)
)
export const clearCreateCustomModule = createAction(`${moduleName}/clearCreateCustomModule`)

export const getScopeDefinition = createAsyncThunk(
  `${moduleName}/getScopeDefinition`,
  ({ projectId }: { projectId: number }) => getScopeDefinitionData(projectId)
)
export const clearGetScopeDefinition = createAction(`${moduleName}/clearGetScopeDefinition`)

export const getProjectSetup = createAsyncThunk(
  `${moduleName}/getProjectSetup`,
  ({ projectId }: { projectId: number }) => getProjectSetupData(projectId),
  {
    condition: ({}, { getState }) => {
      const state: PricingState = (<any>getState())?.Pricing
      if (state.projectSetup.loading === LoadingStatus.Pending) {
        // Already fetched or in progress, don't need to re-fetch
        return false
      }
      return true
    }
  }
)
export const clearGetProjectSetup = createAction(`${moduleName}/clearGetProjectSetup`)

export const getMasterFile = createAsyncThunk(
  `${moduleName}/getMasterFile`,
  ({ projectId }: { projectId: number }) => getMasterFileData(projectId)
)
export const clearGetMasterFile = createAction(`${moduleName}/clearGetMasterFile`)

export const getLocalFile = createAsyncThunk(
  `${moduleName}/getLocalFile`,
  ({ projectId }: { projectId: number }) => getLocalFileData(projectId)
)
export const clearGetLocalFile = createAction(`${moduleName}/clearGetLocalFile`)

export const getBenchmark = createAsyncThunk(
  `${moduleName}/getBenchmark`,
  ({ projectId }: { projectId: number }) => getBenchmarkData(projectId)
)
export const clearGetBenchmark = createAction(`${moduleName}/clearGetBenchmark`)

export const getTPForms = createAsyncThunk(
  `${moduleName}/getTPForms`,
  ({ projectId }: { projectId: number }) => getTPFormsData(projectId)
)
export const clearGetTPForms = createAction(`${moduleName}/clearGetTPForms`)

export const getCbCr = createAsyncThunk(
  `${moduleName}/getCbCr`,
  ({ projectId }: { projectId: number }) => getCbCrData(projectId)
)
export const clearGetCbCr = createAction(`${moduleName}/clearGetCbCr`)

export const getDataCollection = createAsyncThunk(
  `${moduleName}/getDataCollection`,
  ({ projectId }: { projectId: number }) => getDataCollectionData(projectId)
)
export const clearGetDataCollection = createAction(`${moduleName}/clearGetDataCollection`)

export const getScopeAssumptions = createAsyncThunk(
  `${moduleName}/getScopeAssumptions`,
  ({ projectId }: { projectId: number }) => getScopeAssumptionsData(projectId)
)
export const clearGetScopeAssumptions = createAction(`${moduleName}/clearGetScopeAssumptions`)

export const getProjectManagement = createAsyncThunk(
  `${moduleName}/getProjectManagement`,
  ({ projectId }: { projectId: number }) => getProjectManagementData(projectId)
)
export const clearGetProjectManagement = createAction(`${moduleName}/clearGetProjectManagement`)

export const getDiscount = createAsyncThunk(
  `${moduleName}/getDiscount`,
  ({ projectId }: { projectId: number }) => getDiscountData(projectId)
)
export const clearGetDiscount = createAction(`${moduleName}/clearGetDiscount`)

export const getCustomModule = createAsyncThunk(
  `${moduleName}/getCustomModule`,
  ({ projectId, customModuleId }: { projectId: number; customModuleId: number }) =>
    getCustomModuleData(projectId, customModuleId)
)
export const clearGetCustomModule = createAction(`${moduleName}/clearGetCustomModule`)

export const getPricingResults = createAsyncThunk(
  `${moduleName}/getPricingResults`,
  ({ projectId }: { projectId: number }) => getPricingResultsData(projectId)
)
export const clearGetPricingResults = createAction(`${moduleName}/clearGetPricingResults`)

export const exportResultsFlat = createAsyncThunk(
  `${moduleName}/exportResultsFlat`,
  ({ projectId }: { projectId: number }) => exportResultsFlatRequest(projectId)
)
export const clearExportResultsFlat = createAction(`${moduleName}/clearExportResultsFlat`)

export const exportResultsFull = createAsyncThunk(
  `${moduleName}/exportResultsFull`,
  ({ projectId }: { projectId: number }) => exportResultsFullRequest(projectId)
)
export const clearExportResultsFull = createAction(`${moduleName}/clearExportResultsFull`)

export const exportResultsWithSightline = createAsyncThunk(
  `${moduleName}/exportResultsWithSightline`,
  ({ projectId }: { projectId: number }, { rejectWithValue }) =>
    exportResultsWithSightlineRequest(projectId, rejectWithValue)
)
export const clearExportResultsWithSightline = createAction(
  `${moduleName}/clearExportResultsWithSightline`
)
