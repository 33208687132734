import { useState } from 'react'
import type { Control, UseFormClearErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { EmptyState } from '../../../../../../../../components/EmptyState'
import { FormRadioButton } from '../../../../../../../../components/Form/FormRadioButton'
import { RegionsAccordion } from '../../../../../../../../components/RegionsAccordion'
import { SDKNotice } from '../../../../../../../../components/SDK/Notice'
import {
  EAddEditCountryFields,
  IFormValues
} from '../../../../../../../../schemas/useAddEditCountrySchema'
import { RegionContainer } from '../../../../../../../../shared/types/enums'
import { getRadioButtonFieldName } from '../../utils/getRadioButtonFieldName'
import { mapRegionsDataToOptions } from '../../utils/mapRegionsDataToOptions'
import { useNoticeVisble } from './utils/useNoticeVisible'

interface IRegionsSection {
  control: Control<IFormValues>
  clearErrors: UseFormClearErrors<IFormValues>
}

export const RegionsSection = ({ control, clearErrors }: IRegionsSection) => {
  const { t } = useTranslation('countries')
  const [fieldName, setFieldName] = useState<EAddEditCountryFields>(
    EAddEditCountryFields.regionTara
  )

  const getFieldName = (data: string) => {
    const formFieldName = getRadioButtonFieldName(data)
    setFieldName(formFieldName)
  }
  const isNoticeVisible = useNoticeVisble(fieldName, control, clearErrors)

  return (
    <>
      {isNoticeVisible && (
        <SDKNotice
          status='warning'
          duration={undefined}
          message={t('addEditCountry.form.errors.requiredRegions')!}
        />
      )}
      <RegionsAccordion withBorder mapRegions={mapRegionsDataToOptions} getFieldName={getFieldName}>
        {({ title, sectionRegions }) =>
          sectionRegions.length ? (
            <FormRadioButton
              name={getRadioButtonFieldName(title as RegionContainer)}
              required={true}
              className='ap-mb-spacing-5 regions-section'
              options={sectionRegions}
              control={control}
              withDivider
            />
          ) : (
            <EmptyState type='accordion-select' />
          )
        }
      </RegionsAccordion>
    </>
  )
}
