import { useTranslation } from 'react-i18next'
import { SDKButton } from '../../../../components/SDK/Button'
import { SDKIcon } from '../../../../components/SDK/Icon'

interface IFiscalYearSetupHeaderProps {
  onButtonClick: () => void
}

const FiscalYearSetupHeader = ({ onButtonClick }: IFiscalYearSetupHeaderProps) => {
  const { t } = useTranslation('fiscalYearSetup')

  return (
    <div className='fiscal-year-setup-header'>
      <SDKButton onClick={() => onButtonClick()} className='expandable-button'>
        <SDKIcon code='plus'></SDKIcon>
        {t('addFiscalYear')!}
      </SDKButton>
    </div>
  )
}

export default FiscalYearSetupHeader
