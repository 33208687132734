import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { CardListItem } from '../../components/CardListItem'
import { EAction, ECardListItem } from '../../components/CardListItem/const'
import type { IActionCallback } from '../../components/CardListItem/type'
import { CardListWrapper } from '../../components/CardListWrapper'
import { EmptyState } from '../../components/EmptyState'
import { RHF_SET_VALUE_OPTIONS } from '../../components/Form/utils'
import { HeaderButtons } from '../../components/HeaderButtons'
import { Layout } from '../../components/Layout'
import { SDKAccordion } from '../../components/SDK/Accordion'
import { SDKAccordionItem } from '../../components/SDK/AccordionItem'
import { SDKLoading } from '../../components/SDK/Loading'
import { SDKModal } from '../../components/SDK/Modal'
import { Url } from '../../constants/urls'
import { FiscalYearContextProvider, useFiscalYear } from '../../context/fiscalYear.context'
import { EEditFieldNameFields, useEditFieldNameSchema } from '../../schemas/useEditFieldNameSchema'
import { LoadingStatus } from '../../shared/types/enums'
import { useAppDispatch } from '../../store'
import { EditFieldNameModal } from './components/EditFieldNameModal'
import * as actions from './store/actions'
import * as selectors from './store/selectors'
import type { IFormulaCountry, IFormulaDescription } from './types'
import { useEditFieldName } from './utils/useEditFieldName'

import './index.scss'

export type TFieldNameValue = {
  previous: string
  current: string
}

const TaraFieldsContent = () => {
  const { t } = useTranslation('taraFields')
  const dispatch = useAppDispatch()
  const [editFieldNameVisible, setEditFieldNameVisible] = useState(false)
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false)
  const [fieldNameValue, setFieldNameValue] = useState<TFieldNameValue | null>(null)
  const [defaultField, setDefaultField] = useState<IFormulaDescription | null>(null)
  const { loading, data } = useSelector(selectors.getFormulaOverrides)
  const resource = useSelector(selectors.getFormulaOverrideDescription)
  const location = useLocation()
  const navigate = useNavigate()
  const { fiscalYearInfo } = useFiscalYear()

  useEffect(() => {
    if (fiscalYearInfo) {
      dispatch(actions.getFormulaOverrides(fiscalYearInfo.yearId))
    }
  }, [fiscalYearInfo])

  const onEdit = (id: string | number) => {
    navigate(`${location.pathname}${Url.EditTaraFieldsPage}/${id}`)
  }

  const onAdd = () => {
    navigate(`${location.pathname}${Url.AddTaraFieldsPage}`)
  }

  const { validationSchema, defaultValues } = useEditFieldNameSchema(
    resource.data.length ? defaultField || resource.data[0] : null
  )

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isDirty, isValid }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    reset(defaultValues)
  }, [defaultField, resource.data])

  const { onSubmit, onClose } = useEditFieldName(
    reset,
    setDefaultField,
    setEditFieldNameVisible,
    fiscalYearInfo
  )

  const showModal = () => {
    setEditFieldNameVisible(true)
    dispatch(actions.getFormulaOverrideDescription())
  }

  const changeFormValues = (fieldValue: TFieldNameValue) => {
    const desc = resource.data.find(f => f.fieldName === fieldValue.current)?.description
    setValue(EEditFieldNameFields.name, fieldValue.current)
    setValue(EEditFieldNameFields.description, desc || '')
    setDefaultField({
      fieldName: fieldValue.current,
      description: desc || ''
    })
  }

  const changeName = (action: 'save' | 'close') => {
    if (!fieldNameValue) {
      return
    }

    switch (action) {
      case 'save':
        changeFormValues(fieldNameValue)
        break

      default:
        setValue(EEditFieldNameFields.name, fieldNameValue.previous, RHF_SET_VALUE_OPTIONS)
        break
    }
    setConfirmationModalVisible(false)
  }

  const onSelectFieldName = (currentName: string, previousName: string) => {
    const newFieldValue = {
      previous: previousName,
      current: currentName
    }
    setFieldNameValue(newFieldValue)

    if (isDirty) {
      setConfirmationModalVisible(true)
    } else {
      changeFormValues(newFieldValue)
    }
  }

  return (
    <Layout
      buttonComponent={
        <HeaderButtons
          name={t('addOverride')}
          additionalButtonName={t('changeDescription')!}
          additionalButtonOnClick={() => showModal()}
          icon='plus'
          additionalButtonIcon='edit'
          onClick={onAdd}
          disabled={false}
          withAdditionalButton
        />
      }
    >
      {loading === LoadingStatus.Idle || loading === LoadingStatus.Pending ? (
        <SDKLoading />
      ) : data && Object.keys(data).length === 0 ? (
        <EmptyState />
      ) : (
        data && (
          <SDKAccordion
            multiple={true}
            className='ap-my-spacing-5 requirements-deadlines-penalties-fields'
          >
            {Object.keys(data)
              .sort()
              .map((element, i) => (
                <SDKAccordionItem
                  key={`${element}-${i}`}
                  itemKey={`${element}-${i}`}
                  title={element}
                  className='ap-bg-color-background-alt  ap-pb-spacing-2 ap-mb-spacing-3'
                >
                  {data &&
                    data[element as keyof typeof data].map((country: IFormulaCountry) => (
                      <CardListItem
                        key={`country-${country.countryFormulaId}`}
                        actions={[EAction.Edit]}
                        cardListItem={ECardListItem.TaraFieldsCard}
                        handleClick={(callback: IActionCallback) => onEdit(callback.id)}
                        {...country}
                      />
                    ))}
                </SDKAccordionItem>
              ))}
          </SDKAccordion>
        )
      )}
      <SDKModal
        visible={editFieldNameVisible}
        onCancel={onClose}
        title={t('modal.title')!}
        disabledSubmit={!isDirty || !isValid}
        onSubmit={handleSubmit(onSubmit)}
      >
        <CardListWrapper classNames='field-name-modal-content' resource={resource}>
          <EditFieldNameModal
            control={control}
            formulas={resource.data}
            onSelectFieldName={onSelectFieldName}
          />
        </CardListWrapper>
      </SDKModal>
      <SDKModal
        visible={confirmationModalVisible}
        onCancel={() => changeName('close')}
        submitLabel={t('confirmationModal.save')!}
        cancelText={t('confirmationModal.cancel')!}
        withCancel
        title={''}
        onSubmit={() => changeName('save')}
        withRequired={false}
      >
        {t('confirmationModal.text')}
      </SDKModal>
    </Layout>
  )
}

export const TaraFields = () => {
  return (
    <FiscalYearContextProvider>
      <TaraFieldsContent />
    </FiscalYearContextProvider>
  )
}
