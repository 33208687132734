import { useProject } from '../../../context/project.context'

export const useTransformNumberToMoney = () => {
  const { project } = useProject()

  return (money: number, withFractionDigits: boolean = false) => {
    return money.toLocaleString('en-US', {
      style: 'currency',
      maximumFractionDigits: withFractionDigits ? 2 : 0,
      currency: project?.outputCurrencySymbol
    })
  }
}

export const renderDecimalNumber = (number: number) => {
  return number.toFixed(2)
}
