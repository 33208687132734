import classNames from 'classnames'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '../../../../../../components/ActionButton'
import { SDKButton } from '../../../../../../components/SDK/Button'
import { SDKIcon } from '../../../../../../components/SDK/Icon'
import { LoadingOverlay } from '../../../../../../components/SDK/LoadingOverlay'
import { SDKPanel } from '../../../../../../components/SDK/Panel'
import { SDKParagraph } from '../../../../../../components/SDK/Paragraph'
import { SDKText } from '../../../../../../components/SDK/Text'
import { LoadingStatus } from '../../../../../../shared/types/enums'
import { DATE_ISO_FORMAT, formatUtc } from '../../../../../../utils/dateFormatters'
import { ELocalFileCardType } from '../../const/enums'
import { ELocalFile } from '../../types'
import { ISightlineMetadata } from '../../types/inputsTab'
import styles from './index.module.scss'
import { useLocalFileCard } from './utils/useLocalFileCard'

interface ILocalFileCard {
  type: ELocalFileCardType
  sightlineMetadata?: ISightlineMetadata
  setUploadModal?: Dispatch<SetStateAction<ELocalFile | null>>
  className?: string
  selectedFile?: File | null
  setSelectedFile?: Dispatch<SetStateAction<File | null>>
}

export const LocalFileCard = ({
  type,
  sightlineMetadata,
  className,
  setUploadModal,
  selectedFile,
  setSelectedFile
}: ILocalFileCard) => {
  const { t, i18n } = useTranslation('pricing')
  const syncTime = formatUtc(sightlineMetadata?.lastTimeSyncedDate, DATE_ISO_FORMAT)
  const userName = `${sightlineMetadata?.lastTimeSyncedBy?.firstName} ${sightlineMetadata?.lastTimeSyncedBy?.lastName}`
  const icon =
    type === ELocalFileCardType.Basic ? 'icon-upload-light-outline' : 'icon-change-outline'
  const { syncWithSightline, onDownloadTemplate, loading } = useLocalFileCard(type)

  const uploadFile = () => {
    setUploadModal?.(ELocalFile.UserData)
  }

  const onDeleteFile = () => {
    setSelectedFile?.(null)
  }

  const action = type === ELocalFileCardType.Basic ? uploadFile : syncWithSightline

  return (
    <>
      {loading === LoadingStatus.Pending && <LoadingOverlay />}
      <SDKPanel
        className={classNames(
          styles['local-file-card'],
          className,
          'border-1 ap-border-color-background-border ap-mt-spacing-3 ap-mt-spacing-5 col col-xl-6'
        )}
      >
        <div className='justify-content-between align-items-center ap-mb-spacing-5'>
          <div className='ap-flex justify-content-between align-items-center flex-wrap'>
            <SDKText type='subheading'>{t(`tabs.inputs.localFile.${type}.title`)}</SDKText>
            {type === ELocalFileCardType.Basic && selectedFile ? (
              <>
                <div className='ap-flex align-items-center justify-content-end'>
                  <SDKIcon code='document-text' />
                  <SDKText className='ap-pr-spacing-2' type='body'>
                    {selectedFile.name}
                  </SDKText>
                  <SDKText className='ap-text-neutral-14 ap-pr-spacing-4'>{`${selectedFile.size}kb`}</SDKText>
                  <ActionButton
                    kind='neutral'
                    icon='delete'
                    content=''
                    handleClick={onDeleteFile}
                  />
                </div>
              </>
            ) : (
              <SDKButton kind='secondary' icon={icon} onClick={action}>
                {type === ELocalFileCardType.Basic
                  ? t('tabs.inputs.localFile.basic.btn')
                  : i18n.t('buttons.syncWithSightline')}
              </SDKButton>
            )}
          </div>
        </div>
        <SDKText type='body'>{t(`tabs.inputs.localFile.${type}.description`)}</SDKText>

        {type === ELocalFileCardType.Basic ? (
          <>
            <SDKText type='body' weight={3}>
              {t('tabs.inputs.localFile.basic.descriptionComplexity')}
            </SDKText>
            <SDKText type='body'>{t('tabs.inputs.localFile.basic.descriptionPart2')}</SDKText>
            <SDKText type='body' weight={3}>
              {t('tabs.inputs.localFile.basic.descriptionEntityName')}
            </SDKText>
            <SDKText type='body'>{t('tabs.inputs.localFile.basic.descriptionPart3')}</SDKText>
          </>
        ) : (
          ''
        )}

        {type === ELocalFileCardType.Sightline && sightlineMetadata?.lastTimeSyncedDate && (
          <SDKParagraph className='ap-text-neutral-14 ap-mt-spacing-4'>
            {t('tabs.inputs.localFile.sightline.syncedInfo', { date: syncTime, user: userName })}
          </SDKParagraph>
        )}
        {type === ELocalFileCardType.Basic && (
          <SDKButton
            compact
            onClick={onDownloadTemplate}
            kind='text'
            icon='icon-download-light-outline'
            className='ap-mt-spacing-4 ap-pl-spacing-1 ap-pr-spacing-3'
          >
            {t('tabs.inputs.localFile.basic.downloadBtn')}
          </SDKButton>
        )}
      </SDKPanel>
    </>
  )
}
