import type { AxiosRequestConfig } from 'axios'
import axios from 'axios'
import { CookieStorage } from 'cookie-storage'
import { User } from 'oidc-client-ts'
import type { RequestUrls } from './type'

export const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_HOST
})

class Api {
  public static getOidcStorageKey = `oidc.user:${process.env.REACT_APP_AUTHORITY_HOST}:${process.env.REACT_APP_CLIENT_ID}`

  private getConfig(): AxiosRequestConfig {
    const user = this.getUser()

    return {
      headers: {
        Authorization: `Bearer ${user?.access_token}`
      }
    }
  }

  private getUser() {
    const cookie = new CookieStorage()
    const oidcStorage = cookie.getItem(Api.getOidcStorageKey)

    if (!oidcStorage) {
      return null
    } else {
      return User.fromStorageString(oidcStorage)
    }
  }

  public async delete(url: RequestUrls) {
    return apiInstance.delete(url, this.getConfig())
  }

  public async get(url: RequestUrls, config?: any) {
    return apiInstance.get(url, { ...this.getConfig(), ...config })
  }

  public async patch(url: RequestUrls, data?: any) {
    return apiInstance.patch(url, data, {
      ...this.getConfig(),
      headers: { ...this.getConfig().headers, ['Content-Type']: 'application/json' }
    })
  }

  public async post(url: RequestUrls, data?: any, config?: any, rawData: boolean = false) {
    if (rawData)
      return apiInstance.post(url, data, {
        ...this.getConfig(),
        headers: { ...this.getConfig().headers, ['Content-Type']: 'application/json' },
        transformRequest: [d => `"${d}"`]
      })
    return apiInstance.post(url, data, { ...this.getConfig(), ...config })
  }

  public async put(url: RequestUrls, data?: any, config?: any) {
    return apiInstance.put(url, data, { ...this.getConfig(), ...config })
  }
}

const api = new Api()

export default api
