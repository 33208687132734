import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Module } from '../../../constants/modules'
import { createServiceAccountRequest } from '../../../services/requests/user/createServiceAccountRequest'
import { getUserData } from '../../../services/requests/user/getUserData'
import { getUsersData } from '../../../services/requests/user/getUsersData'
import { runUsersSync } from '../../../services/requests/user/runUsersSync'
import { updateUserCookieSettingsRequest } from '../../../services/requests/user/updateUserCookieSettingsRequest'
import { updateUserRequest } from '../../../services/requests/user/updateUserRequest'
import { updateUserRoleData } from '../../../services/requests/user/updateUserRoleData'
import type { IUser } from '../../../types/user'

const moduleName: string = String(Module.User)

export const getUser = createAsyncThunk(`${moduleName}/getUser`, (userId: number) =>
  getUserData(userId)
)
export const clearGetUser = createAction(`${moduleName}/clearGetUser`)
export const getUsers = createAsyncThunk(`${moduleName}/getUsers`, (searchText: string) =>
  getUsersData(searchText)
)
export const clearGetUsers = createAction(`${moduleName}/clearGetUsers`)
export const updateUser = createAsyncThunk(`${moduleName}/updateUser`, (user: IUser) =>
  updateUserRequest(user)
)
export const updateUserCookieSettings = createAsyncThunk(
  `${moduleName}/updateUserCookieSettings`,
  (user: IUser) => updateUserCookieSettingsRequest(user)
)
export const clearUpdateUser = createAction(`${moduleName}/clearUpdateUser`)
export const updateUserRole = createAsyncThunk(
  `${moduleName}/updateUserRole`,
  ({ userId, isAdministrator }: { userId: number; isAdministrator: boolean }) =>
    updateUserRoleData(userId, isAdministrator)
)
export const clearUpdateUserRole = createAction(`${moduleName}/clearUpdateUserRole`)
export const createServiceAccount = createAsyncThunk(
  `${moduleName}/createServiceAccount`,
  (email: IUser['email']) => createServiceAccountRequest(email)
)
export const clearCreateServiceAccount = createAction(`${moduleName}/clearCreateServiceAccount`)

export const syncUsers = createAsyncThunk(`${Module.BackgroundJobs}/syncUsers`, () =>
  runUsersSync()
)
export const clearSyncUsers = createAction(`${Module.BackgroundJobs}/clearSyncUsers`)
