import { createReducer } from '@reduxjs/toolkit'
import {
  getDefaultResourceState,
  Resource,
  setResourceFullfilled,
  setResourcePending,
  setResourceRejected
} from '../../../shared/utils/store'
import type { ICountry } from '../../countries/types'
import type { FiscalYearWithQuestionCount } from '../../fiscalYearSetup/types'
import type { IQuestionLanguage, IQuestionnaireCountry, IQuestionnaireQuestion } from '../type'
import {
  addAnswer,
  clearAddAnswer,
  clearGetAllLanguages,
  clearGetQuestionnaireYears,
  clearQuestionnaireCountries,
  clearQuestionnaireQuestions,
  clearUpdateCountryPublish,
  clearUpdateCountryVerify,
  getAllLanguages,
  getQuestionnaireCountries,
  getQuestionnaireQuestions,
  getQuestionnaireYears,
  updateCountryPublish,
  updateCountryVerify
} from './actions'

export interface ProjectState {
  addAnswer: Resource<IQuestionnaireQuestion | null>
  countries: Resource<IQuestionnaireCountry[]>
  languages: Resource<IQuestionLanguage[]>
  questions: Resource<IQuestionnaireQuestion[]>
  updateCountryPublish: Resource<ICountry | null>
  updateCountryVerify: Resource<ICountry | null>
  years: Resource<FiscalYearWithQuestionCount[]>
}
const initialState: ProjectState = {
  addAnswer: getDefaultResourceState(null),
  countries: getDefaultResourceState([]),
  languages: getDefaultResourceState([]),
  questions: getDefaultResourceState([]),
  updateCountryPublish: getDefaultResourceState(null),
  updateCountryVerify: getDefaultResourceState(null),
  years: getDefaultResourceState([])
}

export default createReducer(initialState, builder =>
  builder
    //countries
    .addCase(getQuestionnaireCountries.pending, state => {
      setResourcePending(state.countries)
    })
    .addCase(getQuestionnaireCountries.rejected, (state, actions) => {
      setResourceRejected(state.countries, actions)
    })
    .addCase(getQuestionnaireCountries.fulfilled, (state, actions) => {
      state.countries.data = actions.payload
      setResourceFullfilled(state.countries)
    })
    .addCase(clearQuestionnaireCountries, state => {
      state.countries = initialState.countries
    })

    //questions
    .addCase(getQuestionnaireQuestions.pending, state => {
      setResourcePending(state.questions)
    })
    .addCase(getQuestionnaireQuestions.rejected, (state, actions) => {
      setResourceRejected(state.questions, actions)
    })
    .addCase(getQuestionnaireQuestions.fulfilled, (state, actions) => {
      state.questions.data = actions.payload
      setResourceFullfilled(state.questions)
    })
    .addCase(clearQuestionnaireQuestions, state => {
      state.questions = initialState.questions
    })

    //years
    .addCase(getQuestionnaireYears.pending, state => {
      setResourcePending(state.years)
    })
    .addCase(getQuestionnaireYears.rejected, (state, actions) => {
      setResourceRejected(state.years, actions)
    })
    .addCase(getQuestionnaireYears.fulfilled, (state, actions) => {
      state.years.data = actions.payload
      setResourceFullfilled(state.years)
    })
    .addCase(clearGetQuestionnaireYears, state => {
      state.years = initialState.years
    })

    .addCase(addAnswer.pending, state => {
      setResourcePending(state.addAnswer)
    })
    .addCase(addAnswer.rejected, (state, actions) => {
      setResourceRejected(state.addAnswer, actions)
    })
    .addCase(addAnswer.fulfilled, (state, actions) => {
      setResourceFullfilled(state.addAnswer)
      state.addAnswer.data = actions.payload
    })
    .addCase(clearAddAnswer, state => {
      state.addAnswer = initialState.addAnswer
    })

    .addCase(updateCountryPublish.pending, state => {
      setResourcePending(state.updateCountryPublish)
    })
    .addCase(updateCountryPublish.rejected, (state, actions) => {
      setResourceRejected(state.updateCountryPublish, actions)
    })
    .addCase(updateCountryPublish.fulfilled, (state, actions) => {
      setResourceFullfilled(state.updateCountryPublish)
      state.updateCountryPublish.data = actions.payload
    })
    .addCase(clearUpdateCountryPublish, state => {
      state.updateCountryPublish = initialState.updateCountryPublish
    })

    .addCase(updateCountryVerify.pending, state => {
      setResourcePending(state.updateCountryVerify)
    })
    .addCase(updateCountryVerify.rejected, (state, actions) => {
      setResourceRejected(state.updateCountryVerify, actions)
    })
    .addCase(updateCountryVerify.fulfilled, (state, actions) => {
      setResourceFullfilled(state.updateCountryVerify)
      state.updateCountryVerify.data = actions.payload
    })
    .addCase(clearUpdateCountryVerify, state => {
      state.updateCountryVerify = initialState.updateCountryVerify
    })

    .addCase(getAllLanguages.pending, state => {
      setResourcePending(state.languages)
    })
    .addCase(getAllLanguages.rejected, (state, actions) => {
      setResourceRejected(state.languages, actions)
    })
    .addCase(getAllLanguages.fulfilled, (state, actions) => {
      state.languages.data = actions.payload
      setResourceFullfilled(state.languages)
    })
    .addCase(clearGetAllLanguages, state => {
      state.languages = initialState.languages
    })
)
