import { EAnswerTypeFromBE } from '../../../modules/countriesSnapshot/pages/AddEditQuestion/constants/answerType'
import type { TQuestion } from '../../../modules/countriesSnapshot/types'
import api from '../../api'
import { CountrySnapshotUrl, FiscalYearUrl } from '../../constants/urls'
import { IAddQuestionPayload } from './addNewQuestion'

export const updateQuestion = async (
  params: IAddQuestionPayload,
  yearId: number,
  categoryId: number,
  subcategoryId: number,
  questionType: EAnswerTypeFromBE,
  questionId: number
): Promise<TQuestion> => {
  return (
    await api.put(
      `${FiscalYearUrl.Years}/${yearId}/${CountrySnapshotUrl.Categories}/${categoryId}/${CountrySnapshotUrl.Subcategories}/${subcategoryId}/${CountrySnapshotUrl.Questions}/${CountrySnapshotUrl.Type}/${questionType}/${questionId}`,
      params
    )
  ).data
}
