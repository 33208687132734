import {
  ICalculationResultByFormat,
  TFileType
} from '../../../modules/project/types/projectCalculation'
import { generateFileFromUrl } from '../../../shared/utils/generateFileFromUrl'
import { CalculationUrl } from '../../constants/urls'

export const getCalculationResultByFormat = async (
  calculationId: number,
  fileType: TFileType
): Promise<ICalculationResultByFormat> => {
  const url = `${CalculationUrl.Calculation}/${calculationId}/${fileType}`
  const blob = await generateFileFromUrl(url)

  return { blob, fileType }
}
