import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Url } from '../constants/urls'
import { useApp } from '../context/app.context'
import * as actions from '../modules/myprojects/store/actions'
import * as selectors from '../modules/myprojects/store/selectors'
import type { IProjectCard } from '../modules/myprojects/types'
import { LoadingStatus } from '../shared/types/enums'
import { useAppDispatch } from '../store'

export const useProjectFromUrl = (): {
  project: IProjectCard | null
  refreshProject: (_clearData?: boolean) => void
  clearProject: () => void
} => {
  const { t } = useTranslation('errors')
  const dispatch = useAppDispatch()
  const { project: currentProjectId } = useParams()
  const { data: project, loading, error } = useSelector(selectors.getProject)
  const navigate = useNavigate()
  const { sendNotification } = useApp()

  const clearProject = () => {
    dispatch(actions.clearGetProject())
  }

  const refreshProject = (clearData = true) => {
    if (clearData) {
      dispatch(actions.clearGetProject())
    }

    if (!!currentProjectId) {
      dispatch(actions.getProject(Number(currentProjectId)))
    }
  }

  useEffect(() => {
    if (
      !!currentProjectId &&
      Number(currentProjectId) !== project?.projectId &&
      loading !== LoadingStatus.Pending
    ) {
      dispatch(actions.getProject(Number(currentProjectId)))
    }
  }, [currentProjectId])

  useEffect(() => {
    if (error === t('project.project404', { projectId: currentProjectId })) {
      sendNotification({
        message: error,
        status: 'error'
      })

      navigate(Url.MyProjectsPage)
    }
  }, [error])

  const calculatedProject: IProjectCard | null =
    currentProjectId === String(project?.projectId) ? project : null

  return { project: calculatedProject, refreshProject, clearProject }
}
