import { useTranslation } from 'react-i18next'
import { EmptyState } from '../../../../components/EmptyState'
import { SDKButton } from '../../../../components/SDK/Button'
import { SDKIcon } from '../../../../components/SDK/Icon'
import { TSubcategory } from '../../types'
import { EListType, ListItem } from '../ListItem'
import './index.scss'

interface ISubcategoriesListProps {
  subcategories: TSubcategory[]
  categoryId: number
  yearId: number
  openModal: (s: number | null, c?: number | null) => void
}

export const SubcategoriesList = ({
  subcategories,
  categoryId,
  yearId,
  openModal
}: ISubcategoriesListProps) => {
  const { t } = useTranslation('countriesSnapshot')

  return (
    <div className='subcategories-list'>
      <div className='add-subcategory ap-my-spacing-5'>
        <SDKButton kind='secondary' onClick={() => openModal(null, categoryId)}>
          <SDKIcon code='plus'></SDKIcon> {t('addSubcategory')}
        </SDKButton>
      </div>
      <div className='list'>
        {!subcategories.length && <EmptyState />}
        {subcategories.map(subcategory => (
          <ListItem
            data={subcategory}
            id={subcategory.subcategoryId}
            yearId={yearId}
            type={EListType.Subcategory}
            key={`category-id-${subcategory.categoryId}-${subcategory.subcategoryId}`}
            openEditModal={openModal}
            optionalId={subcategory.categoryId}
          />
        ))}
      </div>
    </div>
  )
}
