import { SDKIcon } from '../SDK/Icon'
import { SDKTextButton } from '../SDK/TextButton'

import styles from './index.module.scss'

export const TileAdditionalInfo = ({ icon, text }: { icon: string; text: string }) => {
  return (
    <div className={styles['tile-additional-info']}>
      <div className=''>
        <SDKTextButton>
          <SDKIcon code={icon} className='ap-text-states-warning-07'></SDKIcon>
        </SDKTextButton>
      </div>
      <div>{text}</div>
    </div>
  )
}
