import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../context/app.context'
import { LoadingStatus } from '../../../shared/types/enums'
import { useAppDispatch } from '../../../store'
import * as actions from '../store/actions'
import * as selectors from '../store/selectors'

export const useAddEditFiscalYearModal = (
  yearId: number | null,
  setVisible: (value: boolean) => void,
  setYearId: (value: number | null) => void
) => {
  const { t } = useTranslation('fiscalYearSetup')
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const addYearData = useSelector(selectors.getAddYear)
  const updateYearData = useSelector(selectors.getUpdateYear)
  const modal = yearId ? updateYearData : addYearData
  const clearModal = yearId ? actions.clearUpdateYear : actions.clearAddYear
  const notificationMessage = t(
    `newFiscalYear.form.${yearId ? 'updateSuccessInfo' : 'successInfo'}`,
    { year: modal.data?.name ?? '' }
  )
  const onAdd = () => {
    dispatch(actions.clearAddYear())
    setVisible(true)
    setYearId(null)
  }

  const onEdit = (id: number) => {
    dispatch(actions.clearUpdateYear())
    setVisible(true)
    setYearId(id)
  }

  useEffect(() => {
    if (modal.loading === LoadingStatus.Failed && modal.error?.length) {
      sendNotification({
        message: modal.error,
        status: 'error'
      })
    } else if (modal.loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: notificationMessage,
        status: 'success'
      })
      dispatch(actions.getYears())
    }
  }, [modal])

  useEffect(() => {
    return () => {
      dispatch(clearModal())
    }
  }, [])

  return { modal, onAdd, onEdit }
}
