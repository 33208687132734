import { array, number, object, string } from 'yup'
import { IBusinessUnit } from '../modules/project/components/GCDPricing/types/inputsTab'

export enum EBusinessUnitFields {
  regionBusinessUnits = 'regionBusinessUnits'
}
export interface IFormValues {
  [EBusinessUnitFields.regionBusinessUnits]: IBusinessUnit[]
}

export const useAddBusinessUnitSchema = (businessUnits: IBusinessUnit[]) => {
  const defaultValues = {
    [EBusinessUnitFields.regionBusinessUnits]: businessUnits.map(bu => ({
      businessUnits: bu.businessUnits,
      regionName: bu.regionName,
      regionId: bu.regionId
    }))
  }

  const validationSchema = object()
    .shape({
      [EBusinessUnitFields.regionBusinessUnits]: array().of(
        object().shape({
          businessUnits: string(),
          regionName: string(),
          regionId: number()
        })
      )
    })
    .required()

  return { validationSchema, defaultValues }
}
