import { useAuth } from 'react-oidc-context'
import { useNavigate, useParams } from 'react-router-dom'
import { Url } from '../../constants/urls'
import { Error404 } from '../error/error404'
import { RedirectReason } from './const'

const UndefinedPage = ({ reason }: { reason?: RedirectReason }) => {
  const auth = useAuth()
  const { response } = useParams()
  const navigate = useNavigate()
  const isTokenExpired = !!auth?.user?.expired

  if (reason === RedirectReason.ServerReturns404 || response === RedirectReason.ServerReturns404) {
    if (isTokenExpired) {
      navigate(Url.IndexPage)
    } else {
      navigate(Url.MyProjectsPage)
    }
  }

  return <Error404 />
}

export default UndefinedPage
