import type { SelectProps } from '@appkit4/react-components/select'
import type { Control, FieldValues, Path } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { SDKSelect } from '../../SDK/Select'

interface IFormSelect<T extends FieldValues> extends SelectProps {
  label: string
  name: Path<T>
  control: Control<T, any>
  portalMode?: boolean
  showErrorMessage?: boolean
}

type Props<T extends FieldValues> = Partial<IFormSelect<T>>

export const FormSelect = <T extends FieldValues>({
  label,
  control,
  name,
  portalMode,
  onSelect,
  showErrorMessage = false,
  ...props
}: Props<T>) => {
  return (
    <>
      <Controller
        name={name as Path<T>}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <SDKSelect
              onSelect={v => {
                field.onChange(v)
                onSelect?.(v)
              }}
              id={name}
              fieldValue={field.value}
              placeholder={label}
              error={!!error}
              dropdownRenderMode={portalMode ? 'portal' : 'default'}
              {...field}
              {...props}
            />
            {showErrorMessage && !!error && (
              <div className='ap-field-email-validation-error'>{error?.message}</div>
            )}
          </>
        )}
      />
    </>
  )
}
