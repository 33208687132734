import { IProjectResultByQuestion } from '../../../modules/project/types/projectResults'
import api from '../../api'
import { CountrySnapshotUrl, FiscalYearUrl, ProjectsUrl } from '../../constants/urls'

export const getProjectResultsData = async (
  yearId: number,
  projectId: number
): Promise<IProjectResultByQuestion[]> => {
  const url = `${FiscalYearUrl.Years}/${yearId}/${ProjectsUrl.Projects}/${projectId}/${CountrySnapshotUrl.CountrySnapshot}/${CountrySnapshotUrl.Results}`
  return (await api.get(url)).data
}
