import type { ICountryListItem } from '../../../modules/countries/types'
import api from '../../api'
import { CountryUrl, FiscalYearUrl } from '../../constants/urls'

export const getCountriesData = async (id: number): Promise<ICountryListItem[]> => {
  if (!id) {
    return []
  }

  return (await api.get(`${FiscalYearUrl.Years}/${id}/${CountryUrl.Countries}`)).data
}
