import { useTranslation } from 'react-i18next'
import { SDKColumn } from '../../../../../../components/SDK/Column'
import { SDKTableSeparatedByLines } from '../../../../../../components/SDK/TableSeparatedByLines'
import {
  renderAdditionalInformationCell,
  renderAnswerCell,
  renderBreadcrumbsWithTextCell,
  renderEditCell
} from '../../../../../../components/TableCells'
import { EAnswerTypeForEdit } from '../../../../../countriesSnapshot/pages/AddEditQuestion/constants/answerType'
import { IQuestionnaireQuestion } from '../../../../type'
import './index.scss'

interface IQuestionsTableProps {
  questions: IQuestionnaireQuestion[]
  onEdit: (id: number) => void
}
const QuestionsTable = ({ questions, onEdit }: IQuestionsTableProps) => {
  const { t } = useTranslation('questionnaires')
  const originalData = questions.map(item => {
    if (item.answer?.isNotApplicable)
      return { ...item, type: EAnswerTypeForEdit.PlainText, selected: false }
    return {
      ...item,
      selected: false
    }
  })

  return (
    <div className='questionnaires-questions-table-container'>
      <SDKTableSeparatedByLines originalData={originalData} hasTitle>
        <SDKColumn
          field='questionText'
          renderCell={row =>
            renderBreadcrumbsWithTextCell(
              [row.category.name, row.subcategory.name],
              row.questionText
            )
          }
        >
          {t('table.question')}
        </SDKColumn>
        <SDKColumn
          field='questionText'
          renderCell={(row, field) =>
            renderAnswerCell({
              field,
              answerText: row.answer?.answerText || row.answer?.rate || '',
              type: row.type,
              calendarAnswerResponse: row.answer?.calendarAnswerResponse,
              id: row.questionId,
              selectedAnswers: row.answer?.selectedAnswers,
              offset: {
                days: row.answer?.calendarAnswerResponse?.daysOffset,
                months: row.answer?.calendarAnswerResponse?.monthsOffset
              },
              isNotAnswered: row.answer?.isNotAnswered,
              notAsweredText: t('na'),
              isEnabled: row.isEnabled
            })
          }
        >
          {t('table.answer')}
        </SDKColumn>
        <SDKColumn
          field='questionText'
          renderCell={row => renderAdditionalInformationCell(row.answer?.openText, row.answer?.url)}
        >
          {t('table.additionalInformation')}
        </SDKColumn>
        <SDKColumn
          field='questionId'
          renderCell={row =>
            renderEditCell(
              () => onEdit(row.questionId),
              !row.answer?.isNotApplicable,
              row.isAdminReviewRequired,
              t('table.adminReviewRequiredTooltip'),
              t('table.systemAnsweredQuestionTooltip'),
              t('table.editTooltip')
            )
          }
        ></SDKColumn>
      </SDKTableSeparatedByLines>
    </div>
  )
}
export default QuestionsTable
