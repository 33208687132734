import { Label } from '../../Label'
import { SDKParagraph } from '../../SDK/Paragraph'

export const CardField = ({
  className,
  label,
  value
}: {
  className?: string
  label: string
  value: string
}) => {
  return (
    <div className={className}>
      <Label>{label}</Label>
      <SDKParagraph weight={3}>{value}</SDKParagraph>
    </div>
  )
}
