import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { useNavigate } from 'react-router-dom'
import { SDKButton } from '../../../components/SDK/Button'
import { Url } from '../../../constants/urls'
import { ErrorPage } from '../error'
import { ErrorTitle } from '../error/components/ErrorTitle'

export const Error404 = () => {
  const { t } = useTranslation('errors')
  const auth = useAuth()
  const navigate = useNavigate()

  const onClick = () => {
    if (auth.isAuthenticated) {
      navigate(Url.MyProjectsPage)
    } else {
      navigate(Url.IndexPage)
    }
  }

  return (
    <ErrorPage>
      <>
        <ErrorTitle kind='subheading' text={t('exceptions.exception404Hint')} />

        <SDKButton onClick={() => onClick()}>{t('404.button')}</SDKButton>
      </>
    </ErrorPage>
  )
}
