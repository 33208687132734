import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CardListItem } from '../../../../../../../../components/CardListItem'
import { EAction, ECardListItem } from '../../../../../../../../components/CardListItem/const'
import type { IActionCallback } from '../../../../../../../../components/CardListItem/type'
import { RHF_SET_VALUE_OPTIONS } from '../../../../../../../../components/Form/utils'
import { SDKModal } from '../../../../../../../../components/SDK/Modal'
import { UserSearchBox } from '../../../../../../../../components/UserSearchBox'
import { EAddEditCountryFields } from '../../../../../../../../schemas/useAddEditCountrySchema'
import {
  EAddLeaderEditorFields,
  useAddLeaderEditorSchema,
  type IFormValues
} from '../../../../../../../../schemas/useAddLeaderEditorSchema'
import type { IUserBase } from '../../../../../../../../types/user'
import type { ICountryLeader } from '../../../../../../types'
import { ModalType } from '../ContactSection'
import { useAddLeaderEditorModal } from './utils/useAddLeaderEditorModal'

import { SDKItemDataType } from '../../../../../../../../components/SDK/DropdownButton'
import UserAvatar from '../../../../../../../../components/UserAvatar'
import './index.scss'

interface IAddLeaderEditorModal {
  countryEditors: number[]
  countryLeaders: number[]
  modalTitle: string
  onSubmit: (values: IUserBase[] | ICountryLeader[]) => void
  setVisible: (data: boolean) => void
  type: ModalType
  visible: boolean
}

export const AddLeaderEditorModal = ({
  countryEditors,
  countryLeaders,
  modalTitle,
  onSubmit,
  setVisible,
  type,
  visible
}: IAddLeaderEditorModal) => {
  const { t } = useTranslation('countries')
  const { validationSchema, defaultValues } = useAddLeaderEditorSchema()
  const [searchValue, setSearchValue] = useState('')
  const {
    handleSubmit,
    reset,
    resetField,
    getValues,
    setValue,
    trigger,
    formState: { isValid }
  } = useForm<IFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
    mode: 'all',
    reValidateMode: 'onChange'
  })
  const values = getValues()
  const { searchedUsersLoading, offeredUsers, onDelete, selectedUsers, searchedUsers } =
    useAddLeaderEditorModal(searchValue, setValue, trigger, values)
  const finalOfferedUsers =
    type === EAddEditCountryFields.countryLeaders
      ? offeredUsers.filter(user => !countryLeaders.includes(Number(user.value)))
      : offeredUsers.filter(user => !countryEditors.includes(Number(user.value)))

  const closeModal = () => {
    reset()
    setVisible(false)
  }

  const onSave = () => {
    onSubmit(selectedUsers)
    closeModal()
  }

  const renderUserAvatar = (options: SDKItemDataType) => {
    const user = searchedUsers.find(u => u.userId === Number(options.value))
    return user ? <UserAvatar user={user} /> : <></>
  }

  return (
    <SDKModal
      onCancel={() => closeModal()}
      onSubmit={handleSubmit(onSave)}
      disabledSubmit={!isValid}
      submitLabel={t('common:buttons.add')!}
      title={modalTitle}
      visible={visible}
      withRequired={false}
    >
      <form>
        <div className='row'>
          <div className='col ap-mt-spacing-3'>
            <UserSearchBox
              data={finalOfferedUsers}
              loading={searchedUsersLoading}
              onSearchChange={value => setSearchValue(value)}
              onSelect={value => {
                setValue(
                  EAddLeaderEditorFields.userIds,
                  [...values[EAddLeaderEditorFields.userIds], Number(value)],
                  RHF_SET_VALUE_OPTIONS
                )
                resetField(EAddLeaderEditorFields.users)
                trigger()
              }}
              placeholder={t('addLeaderEditor.users')!}
              prefixTemplate={options => renderUserAvatar(options)}
            />
          </div>
        </div>
        {selectedUsers.map(user => (
          <div className='country-editor-leader-list g-col-12' key={user.userId}>
            <CardListItem
              actions={[EAction.Delete]}
              cardListItem={ECardListItem.UserCard}
              handleClick={(callback: IActionCallback) => onDelete(Number(callback.id))}
              {...user}
            />
          </div>
        ))}
      </form>
    </SDKModal>
  )
}
