import { useAuth } from 'react-oidc-context'
import { useNavigate } from 'react-router-dom'
import { ESessionStorage } from '../../constants/tokens'
import { Url } from '../../constants/urls'
import { signOut } from '../../services/requests/auth/signOut'
import { useApp } from '../app.context'

export const useLogout = () => {
  const { updateUserContext } = useApp()
  const navigate = useNavigate()
  const auth = useAuth()

  const logout = () => {
    signOut().then(() => {
      auth.removeUser()
      sessionStorage.removeItem(ESessionStorage.AppMode)
      updateUserContext(null)
      navigate(Url.SignOutPage)
    })
  }

  return { logout }
}
