import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../../../context/app.context'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../store'
import {
  ESightlineRedirectAction,
  PowerBIAction,
  SIGHTLINE_ACTION,
  TEMPLATE_ID
} from '../../../constants'
import {
  clearGetPBICalculationResultByTemplateIdFromSightline,
  getPBICalculationResultByTemplateIdFromSightline
} from '../../../store/actions'
import * as selectors from '../../../store/selectors'

export const useSendPowerBIToSightline = (
  setShowDownloadPowerBIModal: (value: PowerBIAction | null) => void,
  calculationId?: number
) => {
  const { t } = useTranslation('myprojects')
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { error, loading } = useSelector(selectors.getPBICalculationResultByTemplateIdFromSightline)

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded) {
      setShowDownloadPowerBIModal(null)
      sendNotification({
        message: error || t('powerBIActionModal.send.success'),
        status: 'success'
      })
    } else if (loading === LoadingStatus.Failed && error?.length) {
      sendNotification({
        message: error || t('powerBIActionModal.send.error'),
        status: 'error'
      })
    }
    dispatch(clearGetPBICalculationResultByTemplateIdFromSightline())
  }, [loading])

  const sendPowerBIToSightline = (
    templateId: number | null,
    setTemplateId?: (value: number | null) => void
  ) => {
    if (calculationId && templateId) {
      dispatch(getPBICalculationResultByTemplateIdFromSightline({ calculationId, templateId }))
      setTemplateId?.(null)
    }
  }

  useEffect(() => {
    const action = localStorage.getItem(SIGHTLINE_ACTION)
    const templateId = localStorage.getItem(TEMPLATE_ID)
    if (
      action === ESightlineRedirectAction.GetPowerBICalculationResultByTemplateIdFromSightline &&
      templateId
    ) {
      sendPowerBIToSightline(Number(templateId))
      localStorage.removeItem(SIGHTLINE_ACTION)
      localStorage.removeItem(TEMPLATE_ID)
    }
  }, [])

  return { sendPowerBIToSightline }
}
