import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useSelector } from 'react-redux'
import { useProjectFromUrl } from '../hooks/useProjectFromUrl'
import * as countriesActions from '../modules/countries/store/actions'
import * as countriesSelectors from '../modules/countries/store/selectors'
import type { ICountryListItem, TCountryBase } from '../modules/countries/types'
import {
  PRICING_TABS_COMPONENTS,
  PRICING_TABS_KEYS,
  PRICING_TAB_DATA_SELECTORS
} from '../modules/project/components/GCDPricing/const'
import {
  EPricingTab,
  EPricingTabId,
  LAST_PRICING_TAB_ID,
  PricingTab
} from '../modules/project/components/GCDPricing/const/enums'
import { usePricingTabs } from '../modules/project/components/GCDPricing/hooks/usePricingTabs'
import {
  EParentScopeModalType,
  getArrayOfYears,
  parentScopeCircumscribedModel
} from '../modules/project/components/GCDPricing/modals/ParentScopeModal/const'
import * as scopeActions from '../modules/project/components/GCDPricing/modals/store/actions'
import * as scopeSelectors from '../modules/project/components/GCDPricing/modals/store/selectors'
import * as actions from '../modules/project/components/GCDPricing/store/actions'
import * as selectors from '../modules/project/components/GCDPricing/store/selectors'
import CustomModule from '../modules/project/components/GCDPricing/tabs/CustomModule/CustomModule'
import type {
  ITabContent,
  PricingTabContextState
} from '../modules/project/components/GCDPricing/types/context'
import type {
  IChildScope,
  IParentComplexScopeFull
} from '../modules/project/components/GCDPricing/types/scope'
import { LoadingStatus } from '../shared/types/enums'
import { useAppDispatch } from '../store'
import type { IOption } from '../types/form'

export type TPricingYearsCount = 1 | 2 | 3

const defaultContext: PricingTabContextState = {
  childScope: null,
  clientName: '',
  countries: [],
  countriesSelector: [],
  country: undefined,
  isChildScopeModalVisible: false,
  isParentScopeModalVisible: false,
  parentScope: {} as Partial<IParentComplexScopeFull>,
  parentScopeType: EParentScopeModalType.ParentScope,
  priceYears: [],
  scopeDefinitionLoading: false,
  customModuleId: null,
  refreshData: () => {},
  setCountries: (_i: ICountryListItem[]) => {},
  setCountry: (_i: TCountryBase) => {},
  setChildScopeModalVisible: (_i: boolean) => {},
  setParentScopeModalVisible: (_i: boolean) => {},
  setPriceYears: (_y: number[]) => {},
  setTabId: (_i: number) => {},
  tabContent: {
    selector: PRICING_TAB_DATA_SELECTORS[EPricingTabId.ScopeDefinition],
    tab: EPricingTab.ScopeDefinition,
    tabComponent: PRICING_TABS_COMPONENTS[PRICING_TABS_KEYS[EPricingTabId.ScopeDefinition + 1]],
    tabId: EPricingTabId.ScopeDefinition
  },
  setCustomModuleId: (_i: number) => {},
  triggerChildScopeModal: (_i: IChildScope) => {},
  triggerParentScopeModal: (_i: Partial<IParentComplexScopeFull>, _t?: EParentScopeModalType) => {},
  setCurrentTabIndex: () => {}
}

const usePricingTabContext = (): PricingTabContextState => {
  const [tabId, setTabId] = useState<EPricingTabId>(EPricingTabId.ScopeDefinition)
  const [tabContent, setTabContent] = useState<ITabContent>(defaultContext.tabContent)
  const [isChildScopeModalVisible, setChildScopeModalVisible] = useState(
    defaultContext.isChildScopeModalVisible
  )
  const [isParentScopeModalVisible, setParentScopeModalVisible] = useState(
    defaultContext.isParentScopeModalVisible
  )
  const [childScope, setChildScope] = useState<IChildScope | null>(defaultContext.childScope)
  const [parentScope, setParentScope] = useState<Partial<IParentComplexScopeFull>>(
    defaultContext.parentScope
  )
  const [parentScopeType, setParentScopeType] = useState<EParentScopeModalType | null>(
    defaultContext.parentScopeType
  )
  const [clientName, setClientName] = useState<string>(defaultContext.clientName)
  const [country, setCountry] = useState<TCountryBase | null | undefined>(defaultContext.country)
  const [countries, setCountries] = useState<ICountryListItem[]>(defaultContext.countries)
  const [countriesSelector, setCountriesSelector] = useState<IOption[]>(
    defaultContext.countriesSelector
  )
  const [priceYears, setPriceYears] = useState<number[]>(defaultContext.priceYears)
  const [customModuleId, setCustomModuleId] = useState<number | null>(defaultContext.customModuleId)
  const dispatch = useAppDispatch()
  const { project } = useProjectFromUrl()
  const { data: scopeDefinitionData, loading: scopeDefinitionDataLoading } = useSelector(
    selectors.getScopeDefinitionData
  )
  const { data: parentScopeData, loading: parentScopeDataDataLoading } = useSelector(
    scopeSelectors.getParentScope
  )
  const { data: allPublishedCountries, loading: countriesLoading } = useSelector(
    countriesSelectors.getPublishedCountries
  )

  const scopeDefinitionLoading = useMemo(
    () =>
      scopeDefinitionDataLoading === LoadingStatus.Pending ||
      parentScopeDataDataLoading === LoadingStatus.Pending ||
      countriesLoading === LoadingStatus.Pending,
    [scopeDefinitionDataLoading, parentScopeDataDataLoading, countriesLoading]
  )
  const { tabs } = usePricingTabs(tabId)

  const refreshData = () => {
    if (project) {
      const projectId = project.projectId
      switch (tabId) {
        case EPricingTabId.ScopeDefinition:
          dispatch(actions.getScopeDefinition({ projectId }))
          break
        case EPricingTabId.ProjectSetup:
          dispatch(actions.getProjectSetup({ projectId }))
          break
        case EPricingTabId.MasterFile:
          dispatch(actions.getMasterFile({ projectId }))
          break
        case EPricingTabId.LocalFile:
          dispatch(actions.getLocalFile({ projectId }))
          break
        case EPricingTabId.Benchmark:
          dispatch(actions.getBenchmark({ projectId }))
          break
        case EPricingTabId.TPForms:
          dispatch(actions.getTPForms({ projectId }))
          break
        case EPricingTabId.CbCR:
          dispatch(actions.getCbCr({ projectId }))
          break
        case EPricingTabId.DataCollection:
          dispatch(actions.getDataCollection({ projectId }))
          break
        case EPricingTabId.ScopeAssumptions:
          dispatch(actions.getScopeAssumptions({ projectId }))
          break
        case EPricingTabId.ProjectManagement:
          dispatch(actions.getProjectManagement({ projectId }))
          break
        case EPricingTabId.Discount:
          dispatch(actions.getDiscount({ projectId }))
      }

      if (tabId > LAST_PRICING_TAB_ID && tabs[tabId - 1].moduleId) {
        dispatch(actions.getCustomModule({ projectId, customModuleId: tabs[tabId - 1].moduleId! }))
      }
    }
  }

  const closeModals = () => {
    setChildScope(null)
    setParentScope(defaultContext.parentScope)
    setChildScopeModalVisible(false)
    setChildScopeModalVisible(false)
  }

  const clearUpMetadata = () => {
    setTabContent(defaultContext.tabContent)
    setCountry(defaultContext.country)
    setCountries(defaultContext.countries)
    setCountriesSelector(defaultContext.countriesSelector)
    setPriceYears([])
    setCustomModuleId(null)
    dispatch(actions.clearGetScopeDefinition())
  }

  useEffect(() => {
    return clearUpMetadata()
  }, [])

  useEffect(() => {
    if (project?.projectId) {
      closeModals()
      clearUpMetadata()
      dispatch(actions.getScopeDefinition({ projectId: project.projectId! }))
      dispatch(countriesActions.getPublishedCountries(Number(project.yearId)))
    }
  }, [project?.projectId])

  useEffect(() => {
    if (parentScopeDataDataLoading === LoadingStatus.Succeeded && parentScopeType) {
      setParentScope(() =>
        parentScopeCircumscribedModel(
          { ...parentScopeData, customModuleId: customModuleId ?? undefined },
          parentScopeType
        )
      )
      setParentScopeModalVisible(true)
    }
  }, [parentScopeData])

  useEffect(() => {
    if (scopeDefinitionData && project) {
      setPriceYears(() =>
        getArrayOfYears(project.yearName, scopeDefinitionData.scopeQuestions.yearsCount)
      )
      setClientName(() => scopeDefinitionData.scopeQuestions.clientName)
    }
  }, [scopeDefinitionData, project])

  useEffect(() => {
    if (allPublishedCountries && project) {
      const filteredCountries: ICountryListItem[] =
        allPublishedCountries.filter(publishedCountry =>
          project.pricingCountryIds?.includes(publishedCountry.countryId)
        ) ?? []
      setCountries(filteredCountries)
      setCountriesSelector(
        filteredCountries.map(
          availableCountry =>
            ({
              label: availableCountry.name,
              value: String(availableCountry.countryId)
            } as IOption)
        )
      )
    }
  }, [allPublishedCountries, project])

  useEffect(() => {
    if (countries && scopeDefinitionData && project) {
      const foundCountry = countries.find(
        availableCountry =>
          availableCountry.countryId === scopeDefinitionData.scopeQuestions.countryId
      )
      setCountry(foundCountry ? ({ ...foundCountry } as TCountryBase) : null)
    }
  }, [countries, scopeDefinitionData, project])

  useEffect(() => {
    closeModals()
    refreshData()

    if (tabId > LAST_PRICING_TAB_ID) {
      setTabContent({
        selector: PRICING_TAB_DATA_SELECTORS[EPricingTabId.CustomModule],
        tab: EPricingTab.CustomModule,
        tabComponent: CustomModule,
        tabId
      })
      if (tabs[tabId - 1]) {
        setCustomModuleId(tabs[tabId - 1].moduleId!)
      }
    } else {
      setTabContent({
        selector: PRICING_TAB_DATA_SELECTORS[tabId],
        tab: PricingTab[tabId],
        tabComponent: PRICING_TABS_COMPONENTS[PRICING_TABS_KEYS[tabId - 1]],
        tabId
      })
    }
  }, [tabId])

  const triggerChildScopeModal = (scope: IChildScope) => {
    setChildScope({
      ...scope,
      customModuleId: customModuleId ?? undefined
    })
    setChildScopeModalVisible(true)
  }

  const triggerParentScopeModal = (
    scope: Partial<IParentComplexScopeFull>,
    type: EParentScopeModalType = EParentScopeModalType.ParentScope
  ) => {
    setParentScope(() =>
      parentScopeCircumscribedModel(
        {
          ...scope,
          customModuleId: type === EParentScopeModalType.CustomModule ? customModuleId : undefined
        },
        type
      )
    )
    setParentScopeType(type)
    if (project?.projectId) {
      if (scope?.parentScopeId) {
        dispatch(
          scopeActions.getParentScope({
            projectId: project?.projectId,
            parentScopeId: scope?.parentScopeId,
            tab: PricingTab[tabId],
            type,
            customModuleId
          })
        )
      } else {
        dispatch(scopeActions.clearGetParentScope())
        setParentScopeModalVisible(true)
      }
    }
  }

  useEffect(() => {
    if (!isChildScopeModalVisible) {
      setChildScope(null)
    }
  }, [isChildScopeModalVisible])

  useEffect(() => {
    if (!isParentScopeModalVisible) {
      setParentScope(defaultContext.parentScope)
    }
  }, [isParentScopeModalVisible])

  return {
    childScope,
    clientName,
    countries,
    countriesSelector,
    country,
    isChildScopeModalVisible,
    isParentScopeModalVisible,
    parentScope,
    parentScopeType,
    priceYears,
    scopeDefinitionLoading,
    refreshData,
    setCountries,
    setCountry,
    setChildScopeModalVisible,
    setParentScopeModalVisible,
    setPriceYears,
    setTabId,
    triggerChildScopeModal,
    triggerParentScopeModal,
    tabContent,
    customModuleId,
    setCustomModuleId
  }
}

const PricingTabContext = createContext<PricingTabContextState>(defaultContext)

export const usePricingTab = (): PricingTabContextState => {
  return useContext(PricingTabContext)
}

export const PricingTabContextConsumer = PricingTabContext.Consumer

export const PricingTabContextProvider = ({
  currentTabIndex,
  setCurrentTabIndex,
  children
}: {
  children: React.ReactElement
  currentTabIndex: number
  setCurrentTabIndex: Dispatch<SetStateAction<number>>
}) => {
  const {
    clientName,
    countries,
    countriesSelector,
    country,
    childScope,
    isChildScopeModalVisible,
    isParentScopeModalVisible,
    parentScope,
    parentScopeType,
    priceYears,
    scopeDefinitionLoading,
    refreshData,
    setCountries,
    setCountry,
    setChildScopeModalVisible,
    setParentScopeModalVisible,
    setPriceYears,
    setTabId,
    triggerChildScopeModal,
    triggerParentScopeModal,
    tabContent,
    customModuleId,
    setCustomModuleId
  } = usePricingTabContext()

  useEffect(() => {
    setTabId(currentTabIndex as EPricingTabId)
  }, [currentTabIndex])

  return (
    <PricingTabContext.Provider
      value={{
        clientName,
        countries,
        countriesSelector,
        country,
        childScope,
        isChildScopeModalVisible,
        isParentScopeModalVisible,
        parentScope,
        parentScopeType,
        priceYears,
        scopeDefinitionLoading,
        refreshData,
        setCountries,
        setCountry,
        setChildScopeModalVisible,
        setParentScopeModalVisible,
        setPriceYears,
        setTabId,
        triggerChildScopeModal,
        triggerParentScopeModal,
        tabContent,
        customModuleId,
        setCustomModuleId,
        setCurrentTabIndex
      }}
    >
      {children}
    </PricingTabContext.Provider>
  )
}
