import type { ButtonProps } from '@appkit4/react-components/button'
import { Button } from '@appkit4/react-components/button'
import classNames from 'classnames'

import './index.scss'

interface IButton extends ButtonProps {
  fullWidth?: boolean
  heightAuto?: boolean
}

export const SDKButton = ({ fullWidth, heightAuto, className, ...props }: IButton) => {
  return (
    <Button
      className={classNames([className, fullWidth && 'full-width', heightAuto && 'height-auto'])}
      {...props}
    />
  )
}
