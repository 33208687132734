import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SDKModal } from '../../../../../../../../../components/SDK/Modal'
import { useSubmitButton } from '../../../../../../../../../hooks/useSubmitButton'
import { useAddEditCustomModuleSchema } from '../../../../../../../../../schemas/useAddEditCustomModuleSchema'
import type { ICustomModule } from '../../../../../types/inputsTab'
import { AddEditCustomModuleForm } from './components/AddEditCustomModuleForm'
import { useAddEditCustomModule } from './utils/useAddEditCustomModule'

export const AddEditCustomModuleModal = ({
  customModule,
  onCloseModal,
  visible
}: {
  customModule: ICustomModule | null
  onCloseModal: () => void
  visible: boolean
}) => {
  const { t } = useTranslation('pricing')
  const { defaultValues, getDefaultValues, validationSchema } =
    useAddEditCustomModuleSchema(customModule)
  const { handleSubmit, setValue, control, reset, formState } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange'
  })
  const { onSubmit, onClose } = useAddEditCustomModule(customModule, onCloseModal, reset)

  const { disabled } = useSubmitButton({ formState })

  useEffect(() => {
    const updatedDefaultValues = getDefaultValues(customModule)
    setValue('name', updatedDefaultValues.name)
  }, [customModule])

  return (
    <form>
      <SDKModal
        disabledSubmit={disabled}
        onCancel={onClose}
        onSubmit={handleSubmit(onSubmit)}
        title={t(`tabs.addModule.modal.title.${customModule ? 'edit' : 'add'}`)!}
        visible={visible}
      >
        <AddEditCustomModuleForm control={control} />
      </SDKModal>
    </form>
  )
}
