import { useEffect } from 'react'
import type { FieldValues, UseFormSetError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Url } from '../../../../../constants/urls'
import { useApp } from '../../../../../context/app.context'
import {
  EAddEditDocumentFields,
  IFormValues
} from '../../../../../schemas/useAddEditDocumentSchema'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../store'
import * as actions from '../../../store/actions'
import { getAddLegalDocument, getUpdateLegalDocument } from '../../../store/selectors'
import { ILegalDocument } from '../../../types'

export const useAddEditLegalDocument = (
  setError: UseFormSetError<IFormValues>,
  currentDocument?: ILegalDocument
) => {
  const { t } = useTranslation('systemManagement')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const { sendNotification } = useApp()

  const { loading: addLoading, error: addError } = useSelector(getAddLegalDocument)
  const { loading: updateLoading, error: updateError } = useSelector(getUpdateLegalDocument)
  const loading = id ? updateLoading : addLoading
  const error = id ? updateError : addError
  const successMessage = id
    ? t('legalDocuments.updateDocument.success', { name: currentDocument?.name })
    : t('legalDocuments.addDocument.success')

  const onSubmit = async (data: FieldValues) => {
    const mappedData = {
      uniqueIdentifier: id || data.identifier,
      name: data.documentName,
      content: data.documentContent,
      requiresApproval: Boolean(data.requiresApproval)
    }
    if (id && currentDocument) {
      await dispatch(
        actions.updateLegalDocument({ documentId: currentDocument.documentId, ...mappedData })
      )
    } else {
      await dispatch(actions.addLegalDocument(mappedData))
    }
  }

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded) {
      navigate(`${Url.SystemManagementPage}${Url.LegalDocumentsPage}`)
      sendNotification({
        message: successMessage,
        status: 'success'
      })
    }
  }, [loading])

  useEffect(() => {
    if (error && error.includes('already exists')) {
      setError(EAddEditDocumentFields.documentName, {
        message: `${t('legalDocuments.form.documentNameExists')}`
      })
    }
  }, [error])

  return { onSubmit, error }
}
