import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { SDKText, TTextType } from '../../../../../../components/SDK/Text'
import { useCardGridColumnCalculation } from '../../../../../../utils/useCardGridColumnCalculation'
import style from '../../index.module.scss'
import { IParentScopeYearTotal } from '../../types/scope'
import { FeesList } from '../FeesList'
import './style.scss'

export const FeeQuote = ({
  fees,
  customTitle,
  textType = 'subheading',
  textClass,
  textWeight = 2,
  withoutButtons = false,
  gridValues,
  flexStyles,
  withoutEnableSwitch,
  localFile,
  discountModule,
  showQuotesWithFractionDigits
}: {
  fees: IParentScopeYearTotal[]
  customTitle?: string
  textType?: TTextType
  textClass?: string
  textWeight?: 1 | 2 | 3
  withoutButtons?: boolean
  gridValues?: number[]
  flexStyles?: string
  withoutEnableSwitch?: boolean
  localFile?: boolean
  discountModule?: boolean
  showQuotesWithFractionDigits?: boolean
}) => {
  const { t } = useTranslation('pricing')
  const { textCol } = useCardGridColumnCalculation()
  return (
    <div className='ap-flex'>
      <div
        className={classNames(
          localFile ? 'ap-flex justify-content-between fee-quote-local-file' : 'row',
          'w-100 ap-py-spacing-5'
        )}
      >
        <div className={`col ${gridValues ? `col-${gridValues[0]}` : `col-${textCol}`}`}>
          <SDKText
            type={textType}
            className={classNames('ap-text-color-text-heading', textClass)}
            weight={textWeight}
          >
            {customTitle || t(`tabs.inputs.feeQuote`)}
          </SDKText>
        </div>
        <FeesList
          fees={fees}
          keyItem='quote'
          shouldDisplayValues
          textType={textType}
          weight={textWeight}
          textClassNames={classNames('ap-text-color-text-heading', textClass)}
          flexStyles={flexStyles}
          gridValues={gridValues}
          localFile={localFile}
          discountModule={discountModule}
          showQuotesWithFractionDigits={showQuotesWithFractionDigits}
        />
      </div>
      <div
        className={
          style[
            withoutButtons
              ? 'no-buttons'
              : withoutEnableSwitch
              ? 'action-single-button-width'
              : 'action-buttons-width'
          ]
        }
      ></div>
    </div>
  )
}
