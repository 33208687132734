import type { AccordionItemProps } from '@appkit4/react-components/accordion'
import { AccordionItem } from '@appkit4/react-components/accordion'

export interface IAccordionItemProps extends AccordionItemProps {}

export const SDKAccordionItem = ({ itemKey, children, ...props }: IAccordionItemProps) => {
  return (
    <AccordionItem itemKey={itemKey} {...props}>
      {children}
    </AccordionItem>
  )
}
