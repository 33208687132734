import type { SelectValue } from '@appkit4/react-components/esm/combobox/Combobox'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MINIMAL_SEARCH_VALUE, USER_INPUT_SEARCH_DELAY } from '../../modules/users/const'
import { LoadingStatus } from '../../shared/types/enums'
import { useDebounce } from '../../shared/utils/useDebounce'
import type { IOption } from '../../types/form'
import { SDKItemDataType } from '../SDK/DropdownButton'
import { SearchBox } from '../SearchBox'

interface IUserSearchBoxMessages {
  lessThanMinimal: string
  loading: string
  noResultFound: string
}

const USER_SEARCH_BOX_MESSAGES: IUserSearchBoxMessages = {
  lessThanMinimal: 'users:filter.searchUsers.minimalSearchLength',
  loading: 'common:loadingData',
  noResultFound: 'common:form.errors.noResultFound'
}

export const UserSearchBox = ({
  data,
  loading,
  messages = USER_SEARCH_BOX_MESSAGES,
  onSearchChange,
  onSelect,
  placeholder,
  prefixTemplate
}: {
  data: IOption[]
  loading: LoadingStatus
  messages?: IUserSearchBoxMessages
  onSearchChange: (value: string) => void
  onSelect: (value: SelectValue) => void
  placeholder: string
  prefixTemplate?: (item: SDKItemDataType) => React.ReactNode
}) => {
  const { t } = useTranslation()
  const [userSearch, setUserSearch] = useState('')
  const debouncedValue = useDebounce(userSearch, USER_INPUT_SEARCH_DELAY)

  const dropdownResultsMessage = useMemo(() => {
    if (userSearch.length < MINIMAL_SEARCH_VALUE) {
      return messages.lessThanMinimal
    } else if (loading === LoadingStatus.Succeeded && data.length === 0) {
      return messages.noResultFound
    } else {
      return messages.loading
    }
  }, [loading, data, userSearch])

  useEffect(() => {
    if (debouncedValue.length >= MINIMAL_SEARCH_VALUE) {
      onSearchChange(debouncedValue)
    } else {
      onSearchChange('')
    }
  }, [debouncedValue])

  const displayData =
    userSearch.length >= MINIMAL_SEARCH_VALUE && loading === LoadingStatus.Succeeded

  return (
    <SearchBox
      data={displayData ? data : []}
      noResultFound={t(dropdownResultsMessage)!}
      onSearch={value => setUserSearch(value)}
      onSelect={onSelect}
      placeholder={placeholder}
      prefixTemplate={prefixTemplate}
    />
  )
}
