import { IProjectCard } from '../../../types'

export const getYearsFromProjects = (projects: IProjectCard[]) => [
  ...new Map(
    projects
      .map(project => ({
        yearId: Number(project.yearId),
        name: String(project.yearName)
      }))
      .map(item => [item.yearId, item])
  ).values()
]
