export function string2snakeCase(text?: string | null): any {
  if (!text) {
    return ''
  }

  const words = text
    .replace(/\W+/g, ' ')
    .replace(/\_+/g, ' ')
    .trim()
    .split(/ |\B(?=[A-Z])/)
  return words?.map((word: string) => word.toLowerCase()).join('_')
}
