import { Loading } from '@appkit4/react-components'
import classNames from 'classnames'
import styles from './index.module.scss'

export const LoadingSpinner = ({
  message,
  className = '',
  ...props
}: {
  message?: string
  className?: string
  [key: string]: any
}) => {
  const classes = classNames('ap-m-spacing-3', ...className?.split(' '))
  return (
    <>
      <Loading
        loadingType='circular'
        indeterminate={true}
        compact={false}
        className={classes}
        {...props}
      ></Loading>
      {message && <div className={styles.message}>{message}</div>}
    </>
  )
}
