import api from '../../../services/api'

export const renderAsyncImage = async (
  id: string,
  url?: string,
  height?: number
): Promise<boolean> => {
  if (id && url && height) {
    const imageId = id + '-image'
    try {
      let wraperElement = document.getElementById(id)
      let response = await api.get(url, { responseType: 'arraybuffer' })
      let arrayBufferView = new Uint8Array(response.data)
      let blob = new Blob([arrayBufferView], { type: 'image/jpeg' })
      let urlCreator = window.URL || window.webkitURL
      let imageUrl = urlCreator.createObjectURL(blob)
      let doesImageExist = document.getElementById(imageId)

      if (!doesImageExist) {
        const imageElement = document.createElement('img')
        imageElement.id = imageId
        imageElement.height = height
        imageElement.src = imageUrl

        if (wraperElement) {
          wraperElement.innerHTML = ''
          wraperElement.appendChild(imageElement)
        }
      }
    } catch (error) {
      console.error(error)
      return false
    }

    return true
  }

  return false
}
