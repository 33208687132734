import { array, object } from 'yup'
import { IUserBase } from '../types/user'

export enum EAddLeaderEditorFields {
  userIds = 'userIds',
  users = 'users'
}

export interface IFormValues {
  [EAddLeaderEditorFields.userIds]: number[]
  [EAddLeaderEditorFields.users]: IUserBase[] | null
}

export const useAddLeaderEditorSchema = () => {
  const defaultValues: IFormValues = {
    [EAddLeaderEditorFields.userIds]: [],
    users: null
  }

  const validationSchema = object().shape({
    [EAddLeaderEditorFields.userIds]: array().min(1)
  })

  return {
    defaultValues,
    validationSchema
  }
}
