import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Url } from '../constants/urls'
import * as actions from '../modules/fiscalYearSetup/store/actions'
import {
  getYearByName,
  getYears as getYearsSelector
} from '../modules/fiscalYearSetup/store/selectors'
import type { FiscalYearInfo } from '../modules/fiscalYearSetup/types'
import { LoadingStatus } from '../shared/types/enums'
import { useAppDispatch } from '../store'

export const useYearFromUrl = (): {
  year: FiscalYearInfo | undefined
  years: FiscalYearInfo[] | undefined
} => {
  const dispatch = useAppDispatch()
  const { year: currentYearName } = useParams()
  const { data: years, loading } = useSelector(getYearsSelector)
  const year = useSelector(getYearByName(currentYearName!))
  const navigate = useNavigate()

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded && !year && !!currentYearName) {
      navigate(Url.FiscalYearPage)
    }
  }, [loading])

  useEffect(() => {
    dispatch(actions.getYears())
  }, [currentYearName])

  return { year, years }
}
