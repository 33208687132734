import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormInput } from '../../../../components/Form/FormInput'
import { SDKModal } from '../../../../components/SDK/Modal'
import { SDKText } from '../../../../components/SDK/Text'
import { SDKTooltip } from '../../../../components/SDK/Tooltip'
import {
  EAddEditRegionFields,
  useAddEditRegionSchema
} from '../../../../schemas/useAddEditRegionSchema'
import './index.scss'
import { useAddEditRegion } from './utils/useAddEditRegion'

interface IAddRegionModal {
  visible: boolean
  setVisible: (data: boolean) => void
  regionContainer: string
  regionId: number | null
}

export const AddEditRegionModal = ({
  visible,
  setVisible,
  regionContainer,
  regionId
}: IAddRegionModal) => {
  const { t } = useTranslation('regions')
  const { validationSchema, defaultValues } = useAddEditRegionSchema(regionId)

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { isValid, isDirty }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'all',
    reValidateMode: 'onChange'
  })
  const { onSubmit, onClose } = useAddEditRegion(
    regionContainer,
    setVisible,
    reset,
    regionId,
    setError
  )

  return (
    <form>
      <SDKModal
        visible={visible}
        onCancel={onClose}
        title={t(regionId ? 'editRegion' : 'addRegion')!}
        disabledSubmit={regionId ? !isDirty : !isValid}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='ap-container add-fiscal-year-form'>
          <div className='form-container'>
            <div className='row ap-mt-spacing-2'>
              <div className='col'>
                <div className='region-type ap-mb-spacing-7'>
                  <SDKText type='body-s' className='ap-text-neutral-14'>
                    {t('newRegion.form.fields.regionType')!}
                  </SDKText>
                  <SDKText type='body' weight={2}>
                    {t(`containers.${regionContainer}`)}
                  </SDKText>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <SDKTooltip disabled={isDirty} content={t('newRegion.form.tooltip')!}>
                  <FormInput
                    name={EAddEditRegionFields.region}
                    type='text'
                    label={t('newRegion.form.fields.region')!}
                    required={true}
                    control={control}
                    suffixIcon
                  />
                </SDKTooltip>
              </div>
            </div>
          </div>
        </div>
      </SDKModal>
    </form>
  )
}
