import type { IPowerBITemplate } from '../../../modules/powerBITemplates/types'
import { ETemplateType } from '../../../modules/project/components/GCDPricing/const/enums'
import api from '../../api'
import { FiscalYearUrl, PowerBITemplateUrl } from '../../constants/urls'

export const getPBITemplatesData = async (
  yearId: number,
  templateType?: ETemplateType
): Promise<IPowerBITemplate[]> => {
  let uri = `${FiscalYearUrl.Years}/${yearId}/${PowerBITemplateUrl.PowerBITemplates}`
  if (templateType) {
    uri = `${uri}?templateType=${templateType}`
  }

  return (await api.get(uri)).data
}
