import type { TCategory } from '../../../modules/countriesSnapshot/types'
import api from '../../api'
import { CountrySnapshotUrl, FiscalYearUrl } from '../../constants/urls'

export interface IAddCategoryPayload {
  name: string
}

export const addNewCategory = async (
  params: IAddCategoryPayload,
  yearId: number
): Promise<TCategory> => {
  return (
    await api.post(`${FiscalYearUrl.Years}/${yearId}/${CountrySnapshotUrl.Categories}`, params)
  ).data
}
