import { EPricingTab } from '../../../modules/project/components/GCDPricing/const/enums'
import { EParentScopeModalType } from '../../../modules/project/components/GCDPricing/modals/ParentScopeModal/const'
import type { IParentComplexScopeFull } from '../../../modules/project/components/GCDPricing/types/scope'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export const getParentScopeRequest = async (
  projectId: number,
  tab: EPricingTab,
  parentScopeId: string | number,
  type: EParentScopeModalType,
  customModuleId?: number | null
): Promise<IParentComplexScopeFull> => {
  let url = `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/`

  if (type === EParentScopeModalType.CustomModule) {
    url += `${ProjectsUrl.Modules}/${ProjectsUrl.CustomModules}/${customModuleId}/${ProjectsUrl.ParentScopes}/${parentScopeId}`
  } else {
    url += `${ProjectsUrl.Modules}/${tab}/${
      type === EParentScopeModalType.Transaction
        ? ProjectsUrl.Transactions
        : ProjectsUrl.ParentScopes
    }/${parentScopeId}`
  }

  const data = (await api.get(url)).data

  if (type === EParentScopeModalType.Transaction) {
    return { ...data, parentScopeId: data.id }
  } else {
    return data
  }
}
