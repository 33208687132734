import { useEffect } from 'react'
import type { FieldValues, UseFormReset } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useApp } from '../../../../../context/app.context'
import { IAddQuestionPayload } from '../../../../../services/requests/countriesSnapshot/addNewQuestion'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../store'
import { IAnswer } from '../../../../project/types/projectResults'
import * as actions from '../../../store/actions'
import * as selectors from '../../../store/selectors'
import { TQuestionConditions } from '../../../types'
import { EAnswerType, EAnswerTypeFromBE } from '../constants/answerType'

export const useAddEditQuestion = (
  reset: UseFormReset<FieldValues>,
  categoryId?: number,
  subcategoryId?: number,
  yearId?: number,
  questionId?: number,
  questionConditions?: TQuestionConditions[]
) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { sendNotification } = useApp()
  const { pathname } = useLocation()
  const { loading, error } = questionId
    ? useSelector(selectors.getUpdateQuestion)
    : useSelector(selectors.getAddQuestion)

  const onSubmit = (data: FieldValues) => {
    const answerType: EAnswerType = data.answerType
    const answerTypeKey = Object.keys(EAnswerType).filter(
      key => EAnswerType[key as keyof typeof EAnswerType] === answerType
    )[0]

    let mappedData: IAddQuestionPayload = {
      questionText: data.question,
      defaultNaText: data.defaultNaText
    }
    if (answerType === EAnswerType.Single || answerType === EAnswerType.Multiple) {
      mappedData = questionId
        ? { ...mappedData, possibleAnswers: data.possibleAnswers }
        : {
            ...mappedData,
            possibleAnswers: data.possibleAnswers.map(
              (answer: IAnswer) => answer.possibleAnswerText
            )
          }
    }
    if (answerType === EAnswerType.Calendar) {
      mappedData = {
        ...mappedData,
        options: {
          localEntityOption: data.localEntityOption,
          otherOption: data.otherOption,
          relativeToCalendarYearOption: data.relativeToCalendarYearOption,
          relativeToFilingTaxReturnOption: data.relativeToFilingTaxReturnOption,
          relativeToFyeOption: data.relativeToFyeOption,
          relativeToInquiryOrAuditOption: data.relativeToInquiryOrAuditOption,
          ultimateParentEntityOption: data.ultimateParentEntityOption
        }
      }
    }

    if (yearId === undefined || categoryId === undefined || subcategoryId === undefined) {
      return
    }

    if (data.parentQuestionAnswerIds && data.parentQuestionAnswerIds?.length > 0) {
      const newQuestionConditions = data.parentQuestionAnswerIds.map(
        (x: number) =>
          ({
            parentQuestionId: data.parentQuestionId,
            possibleAnswerDefinitionId: x,
            operator: Number.parseInt(data.questionDependencyOperator),
            questionConditionId: questionConditions?.find(
              qc =>
                qc.parentQuestionId == data.parentQuestionId && qc.possibleAnswerDefinitionId == x
            )?.questionConditionId
          } as TQuestionConditions)
      )

      mappedData.questionConditions = newQuestionConditions
    }

    dispatch(actions.clearAddQuestion())
    dispatch(actions.clearUpdateQuestion())
    if (questionId) {
      dispatch(
        actions.updateSingleQuestion({
          params: mappedData,
          yearId,
          categoryId,
          subcategoryId,
          questionType: EAnswerTypeFromBE[answerTypeKey as keyof typeof EAnswerTypeFromBE],
          questionId
        })
      )
    } else {
      dispatch(
        actions.addQuestion({
          params: mappedData,
          yearId,
          categoryId,
          subcategoryId,
          questionType: EAnswerTypeFromBE[answerTypeKey as keyof typeof EAnswerTypeFromBE]
        })
      )
    }
  }

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded && yearId) {
      dispatch(actions.getQuestions(yearId))
      reset()
      const newPathname = pathname.split('/')

      if (questionId) {
        newPathname.splice(-2)
      } else {
        newPathname.pop()
      }
      navigate(`${newPathname.join('/')}#questions`)
    } else if (error) {
      sendNotification({
        message: error,
        status: 'error'
      })
    }
  }, [loading])

  return { loading, onSubmit }
}
