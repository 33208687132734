import { useCallback, useEffect } from 'react'
import type { UseFormReset } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { IFormValues } from '../../../../../schemas/useAddCategorySchema'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../store'
import * as actions from '../../../store/actions'
import * as selectors from '../../../store/selectors'

export const useAddEditCategory = (
  setVisible: (value: boolean) => void,
  reset: UseFormReset<IFormValues>,
  categoryId: number | null,
  yearId?: number
) => {
  const dispatch = useAppDispatch()
  const addCategoryData = useSelector(selectors.getAddCategory)
  const updateCategoryData = useSelector(selectors.getUpdateCategory)
  const loading: LoadingStatus = categoryId ? updateCategoryData.loading : addCategoryData.loading

  const onClose = useCallback(() => {
    setVisible(false)
    reset()
  }, [setVisible, reset])

  const onSubmit = (data: IFormValues) => {
    const mappedData = {
      name: data.category
    }
    if (categoryId !== null) {
      dispatch(actions.updateCategory({ params: mappedData, categoryId, yearId: yearId || 0 }))
    } else {
      dispatch(actions.addCategory({ params: mappedData, yearId: yearId || 0 }))
    }
  }

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded && yearId) {
      dispatch(actions.getCategories(yearId))
      onClose()
    }
  }, [loading])

  return { loading, onSubmit, onClose }
}
