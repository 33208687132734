import { IProjectResultByQuestion } from '../../../modules/project/types/projectResults'
import api from '../../api'
import { CountrySnapshotUrl, FiscalYearUrl } from '../../constants/urls'

export interface ICountrySnapshotMfeResultsPayload {
  countryCodes: string[]
  questionOrderNumbers: number[]
}

export const getCountrySnapshotMfeResultsData = async (
  yearName: string,
  params: ICountrySnapshotMfeResultsPayload
): Promise<IProjectResultByQuestion[]> => {
  const url = `${FiscalYearUrl.Years}/${yearName}/${CountrySnapshotUrl.CountrySnapshot}/${CountrySnapshotUrl.Questions}`
  return (await api.post(url, params)).data
}
