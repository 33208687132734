import { ESightlineRedirectAction } from '../../../modules/project/constants'
import { ResponseCode } from '../../../shared/types/enums'
import { authorizeSightline } from '../../../shared/utils/authorizeSightline'
import api from '../../api'
import {
  CalculationUrl,
  CountrySnapshotUrl,
  DataExportUrl,
  ProjectsUrl
} from '../../constants/urls'

export const exportProjectDataFromSightlineCS = async (
  projectId: number,
  rejectWithValue: any
): Promise<string> => {
  try {
    const response = await api.post(
      `${DataExportUrl.DataExport}/${ProjectsUrl.Projects}/${projectId}/${CountrySnapshotUrl.CountrySnapshot}/${CalculationUrl.Sightline}`
    )
    return response.data
  } catch (error: any) {
    if (error.code === ResponseCode.Unauthorized) {
      authorizeSightline(error.message, ESightlineRedirectAction.ExportProjectDataFromSightlineCS)
      return ''
    } else {
      return rejectWithValue(error.message)
    }
  }
}
