export enum Module {
  BackgroundJobs = 'backgroundJobs',
  Countries = 'yearCountries',
  CountriesSnapshot = 'countriesSnapshot',
  CountriesSnapshotMFE = 'countriesSnapshotMFE',
  FiscalYear = 'fiscalYear',
  MyProjects = 'myProjects',
  Pricing = 'Pricing',
  PricingHelper = 'PricingHelper',
  Project = 'Project',
  Questionnaire = 'questionnaire',
  Regions = 'yearRegions',
  Search = 'search',
  SystemManagement = 'systemManagement',
  TaraFields = 'taraFields',
  Updates = 'updates',
  User = 'user'
}
