import { useCallback, useEffect } from 'react'
import type { UseFormReset } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../../../../../../../../context/app.context'
import { useProjectFromUrl } from '../../../../../../../../../../hooks/useProjectFromUrl'
import { IFormValues } from '../../../../../../../../../../schemas/useAddEditCustomModuleSchema'
import { LoadingStatus } from '../../../../../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../../../../../store'
import { EPricingTab } from '../../../../../../const/enums'
import {
  clearUpdateModuleMetadata,
  updateModuleMetadata
} from '../../../../../../modals/store/actions'
import * as modalsSelectors from '../../../../../../modals/store/selectors'
import * as actions from '../../../../../../store/actions'
import * as selectors from '../../../../../../store/selectors'
import type { ICustomModule } from '../../../../../../types/inputsTab'

export const useAddEditCustomModule = (
  customModule: ICustomModule | null,
  onCloseModal: () => void,
  reset: UseFormReset<IFormValues>
) => {
  const { t } = useTranslation('pricing')
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { project } = useProjectFromUrl()
  const { loading: updateLoading, error: updateError } = useSelector(
    modalsSelectors.updateModuleMetadata
  )
  const { loading: createLoading, error: createError } = useSelector(selectors.createCustomModule)
  const loading = customModule ? updateLoading : createLoading
  const error = customModule ? updateError : createError
  const clearAction = customModule ? clearUpdateModuleMetadata : actions.clearCreateCustomModule
  const notificationText = t(
    `tabs.addModule.${customModule ? 'editSuccessInfo' : 'addSuccessInfo'}`,
    { name: customModule?.name }
  )

  const onClose = useCallback(() => {
    onCloseModal()
    reset()
    dispatch(clearAction())
  }, [reset])

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: notificationText,
        status: 'success'
      })
      onClose()
    } else if (loading === LoadingStatus.Failed && error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
      onClose()
    }
  }, [loading])

  const onSubmit = (data: IFormValues) => {
    if (customModule) {
      dispatch(
        updateModuleMetadata({
          projectId: Number(project?.projectId),
          tabName: EPricingTab.CustomModule,
          params: { ...customModule, name: data.name },
          customModuleId: customModule.moduleId
        })
      )
    } else {
      dispatch(
        actions.createCustomModule({
          customModule: {
            name: data.name,
            isOptional: false,
            isEnabled: true
          } as Partial<ICustomModule>,
          projectId: project?.projectId!
        })
      )
    }
  }

  return { onSubmit, onClose, loading }
}
