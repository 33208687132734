import { useCallback, useEffect, useState } from 'react'
import type { FieldValues, UseFormReset } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../context/app.context'
import { LoadingStatus } from '../../../shared/types/enums'
import { useAppDispatch } from '../../../store'
import {
  EAnswerTypeForEdit,
  EAnswerTypeFromBE
} from '../../countriesSnapshot/pages/AddEditQuestion/constants/answerType'
import * as fiscalYearsActions from '../../fiscalYearSetup/store/actions'
import * as fiscalYearsSelectors from '../../fiscalYearSetup/store/selectors'
import type { IAddNewAnswer } from '../store/actions'
import * as actions from '../store/actions'
import * as selectors from '../store/selectors'
import {
  EDateActionPerformer,
  EDateOffsetType,
  IAddAnswer,
  IAnswer,
  IQuestionnaireQuestion
} from '../type'

export const useAddAnswer = (
  reset: UseFormReset<FieldValues>,
  onAnswerSave: () => void,
  setShowTaraInfoModal: (value: boolean) => void,
  yearId: number,
  countryId: number,
  question: IQuestionnaireQuestion | null,
  query: string,
  draftAnswer?: IAnswer
) => {
  const { t } = useTranslation('questionnaires')
  const [holdNotification, setHoldNotification] = useState(false)
  const [newAnswerState, setNewAnswerState] = useState<IAddNewAnswer | null>(null)
  const dispatch = useAppDispatch()
  const { loading, error } = useSelector(selectors.getAddAnswer)
  const { loading: rejectLoading } = useSelector(fiscalYearsSelectors.rejectGateQuestionDraft)
  const { sendNotification } = useApp()
  const onClose = useCallback(() => {
    onAnswerSave()
    reset()
    dispatch(actions.clearAddAnswer())
  }, [reset, question])

  const onSubmit = (data: FieldValues, confirmed?: boolean) => {
    let canSubmit = true
    if (!question || !data) {
      return
    }
    let mappedData: IAddAnswer = { url: data.url }
    if (question.isGateQuestion && !draftAnswer) {
      const correctTara = !!(data.tara === 'correct')
      mappedData = { ...mappedData, isGateQuestionFormulaCorrect: correctTara }
      setShowTaraInfoModal(!correctTara)
      setHoldNotification(!correctTara)
      canSubmit = correctTara
    } else if (!!draftAnswer) {
      mappedData = { ...mappedData, isGateQuestionFormulaCorrect: false }
    }
    const type = question.type
    const questionTypeKey = Object.keys(EAnswerTypeForEdit).filter(
      key => EAnswerTypeForEdit[key as keyof typeof EAnswerTypeForEdit] === type
    )[0]

    switch (type) {
      case EAnswerTypeForEdit.Single:
        mappedData = {
          ...mappedData,
          openText: data.comment,
          selectedAnswerId: Number(data.singleChoice)
        }
        break
      case EAnswerTypeForEdit.Multiple:
        mappedData = {
          ...mappedData,
          openText: data.comment,
          selectedAnswerIds: data.multipleChoice.map((id: string) => Number(id))
        }
        break
      case EAnswerTypeForEdit.Calendar:
        mappedData = {
          ...mappedData,
          openText: data.comment,
          monthsOffset: data.months,
          daysOffset: data.days,
          dateActionPerformer: Number(EDateActionPerformer[data.action]),
          dateOffsetType: Number(EDateOffsetType[data.dates])
        }
        break
      case EAnswerTypeForEdit.Language:
        mappedData = {
          ...mappedData,
          openText: data.comment,
          selectedLanguageId: data.selectedLanguageId
        }
        break
      case EAnswerTypeForEdit.TaxRate:
      case EAnswerTypeForEdit.CurrencyRate:
        mappedData = { ...mappedData, openText: data.comment, rate: Number(data.rate) }
        break
      default:
        mappedData = { ...mappedData, answerText: data.answer }
        break
    }

    if (canSubmit || confirmed) {
      const newAnswerStatePayload = {
        yearId,
        countryId,
        questionId: question.questionId,
        questionType: EAnswerTypeFromBE[questionTypeKey as keyof typeof EAnswerTypeFromBE],
        params: mappedData
      }
      setNewAnswerState(newAnswerStatePayload)

      if (draftAnswer) {
        dispatch(
          fiscalYearsActions.rejectGateQuestionDraft({
            countryId: draftAnswer.countryId,
            draftAnswerId: draftAnswer.answerId,
            gateQuestionId: question.questionId,
            yearId
          })
        )
      } else {
        dispatch(actions.addAnswer(newAnswerStatePayload))
      }
    }

    if (confirmed) {
      setShowTaraInfoModal(false)
      setHoldNotification(false)
    }
  }

  useEffect(() => {
    if (rejectLoading === LoadingStatus.Succeeded && newAnswerState) {
      dispatch(fiscalYearsActions.clearRejectGateQuestionDraft())
      dispatch(actions.addAnswer(newAnswerState))
      setNewAnswerState(null)
    }
  }, [rejectLoading])

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded && !holdNotification) {
      dispatch(actions.getQuestionnaireQuestions({ yearId, countryId, query }))
      sendNotification({
        message: t(`success`),
        status: 'success'
      })
      onClose()
    }
    if (error?.length && !holdNotification) {
      sendNotification({
        message: error,
        status: 'error'
      })
      onClose()
    }
  }, [loading, holdNotification])

  return { setHoldNotification, onSubmit, onClose }
}
