import { Combobox } from '@appkit4/react-components'
import { useEffect, useState } from 'react'
import type { Control } from 'react-hook-form'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormTextArea } from '../../../../components/Form/FormTextArea'
import { LoadingSpinner } from '../../../../components/SDK/LoadingSpinner'
import { EEditFieldNameFields, IFormValues } from '../../../../schemas/useEditFieldNameSchema'
import { IFormulaDescription } from '../../types'

interface IEditFieldNameModal {
  control: Control<IFormValues>
  formulas: IFormulaDescription[]
  onSelectFieldName: (name: string, fieldName: string) => void
}

export const EditFieldNameModal = ({
  control,
  formulas,
  onSelectFieldName
}: IEditFieldNameModal) => {
  const { t } = useTranslation('taraFields')
  const [fieldNameValue, setFieldNameValue] = useState('')

  const fieldName = useWatch({
    control,
    name: EEditFieldNameFields.name
  })

  useEffect(() => {
    setFieldNameValue(fieldName)
  }, [fieldName])

  if (!fieldNameValue) {
    return <>{<LoadingSpinner />}</>
  } else {
    return (
      <form>
        <Combobox
          className='ap-mb-spacing-5'
          data={formulas.map(f => ({ label: f.fieldName, value: f.fieldName }))}
          defaultValue={fieldName}
          dropdownRenderMode='portal'
          labelKey={'label'}
          onSelect={val => {
            onSelectFieldName(String(val), fieldName)
          }}
          placeholder={t('addEditForm.fieldName')!}
          required={true}
          value={fieldName}
          valueKey={'value'}
          showClear={false}
        />

        <FormTextArea
          name={EEditFieldNameFields.description}
          label={t('modal.description')!}
          className='ap-my-spacing-5'
          required={false}
          control={control}
        />
      </form>
    )
  }
}
