import { IQuestionnaireCountry } from '../../../modules/questionnaires/type'
import api from '../../api'
import { CountryUrl, FiscalYearUrl, QuestionnaireUrl } from '../../constants/urls'

export const getQuestionnaireCountriesData = async (
  yearId: number
): Promise<IQuestionnaireCountry[]> => {
  return (
    await api.get(
      `${QuestionnaireUrl.Questionnaire}/${FiscalYearUrl.Years}/${yearId}/${CountryUrl.Countries}`
    )
  ).data
}
