import { useMemo } from 'react'
import { SDKTab } from '../../../components/SDK/Tab'
import { SDKTabs } from '../../../components/SDK/Tabs'
import { Mode } from '../../../constants/mode'
import { useApp } from '../../../context/app.context'
import './index.scss'

export const ModeToggle = () => {
  const {
    updateStyleContext,
    appContext: {
      style: { mode }
    }
  } = useApp()

  const activeIndex = useMemo(() => Object.values(Mode).indexOf(mode), [mode])

  const onTabChange = (_: number, value: string) => {
    updateStyleContext({ mode: value as Mode })
  }

  return (
    <SDKTabs
      className='ap-header-options-item mode-toggle'
      type='filled'
      activeIndex={activeIndex}
      onTabChange={onTabChange}
    >
      <SDKTab icon='icon-brightness-outline' value={Mode.Light}></SDKTab>
      <SDKTab icon='icon-moon-outline' value={Mode.Dark}></SDKTab>
    </SDKTabs>
  )
}
