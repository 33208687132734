import { type SelectValue } from '@appkit4/react-components/esm/combobox/Combobox'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { IOption } from '../../../types/form'
import { SDKCombobox, type TSelectValue } from '../../SDK/Combobox'

import style from './index.module.scss'

export interface IFormCombobox {
  className?: string
  data: IOption[]
  disabled: boolean
  label?: string
  onSelect: (_value: TSelectValue) => void
}

export const FormCombobox = ({
  className,
  data,
  disabled,
  label,
  onSelect
}: {
  className?: string
  data: IOption[]
  disabled?: boolean
  label?: string
  onSelect?: (_value: SelectValue) => void
}) => {
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation('myprojects')

  return (
    <SDKCombobox
      className={className}
      data={data}
      disabled={disabled}
      dropdownClassName={style['combobox-dropdown']}
      multiple={true}
      noResultFound={t('common:form.errors.noResultFound')!}
      onSelect={value => {
        if (typeof onSelect === 'function') {
          onSelect(value)
        }

        setVisible(false)
      }}
      onVisibleChange={v => setVisible(v)}
      placeholder={label ?? t('projectForm.projectCountries.countryIds.label')!}
      required={true}
      showSelectAll={true}
      visible={visible}
    />
  )
}
