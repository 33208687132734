import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Divider } from '../../../../../../components/Divider'
import { Label } from '../../../../../../components/Label'
import { SDKDropdownButton } from '../../../../../../components/SDK/DropdownButton'
import { SDKIcon } from '../../../../../../components/SDK/Icon'
import { SDKPanel } from '../../../../../../components/SDK/Panel'
import { SDKText } from '../../../../../../components/SDK/Text'
import { SDKTextButton } from '../../../../../../components/SDK/TextButton'
import { Url } from '../../../../../../constants/urls'
import type { FiscalYearInfo } from '../../../../types'
import { FiscalYearStatusBadge } from './components/FiscalYearStatusBadge'
import './index.scss'

export const FiscalYearCard = ({
  fiscalYear,
  onEdit,
  onDelete
}: {
  fiscalYear: FiscalYearInfo
  onEdit: (value: number) => void
  onDelete: (value: number) => void
  questionnairesView?: boolean
}) => {
  const { t } = useTranslation('fiscalYearSetup')
  const navigate = useNavigate()

  const menuItems = [
    {
      label: t('infoPanel.functions.settings'),
      iconName: 'setting',
      onClick: (id: number) => onEdit(id)
    },
    {
      label: t('infoPanel.functions.remove'),
      iconName: 'delete',
      onClick: (id: number) => onDelete(id)
    }
  ]
  const goToPage = (yearName: string) => {
    navigate(`${Url.FiscalYearPage}/${yearName}`)
  }
  return (
    <SDKPanel
      className='fiscal-year-card'
      footer={
        <SDKTextButton onClick={() => goToPage(fiscalYear.name)} className='ap-link'>
          <>
            {t('infoPanel.viewDetails.text')} <SDKIcon code='right-chevron'></SDKIcon>
          </>
        </SDKTextButton>
      }
    >
      <div className='ap-container mx-0 gx-0'>
        <div className='row'>
          <div className='col col-4'>
            <Label>{t('infoPanel.fiscalYear.label')}</Label>
            <div>
              <SDKText type='heading' weight={3}>
                {fiscalYear.name}
              </SDKText>
            </div>
          </div>
          <div className='col col-4'>
            <Label>{t('infoPanel.status.label')}</Label>
            <div>
              <FiscalYearStatusBadge isActive={fiscalYear.isActive} />
            </div>
          </div>
          <div className='col col-4'>
            <SDKDropdownButton
              customTriggerNode={true}
              customTriggerClassName='custom-node'
              data={menuItems}
              prefixTemplate={item => <SDKIcon code={item.iconName} />}
              onSelect={(_, item) => item.onClick(fiscalYear.yearId)}
            >
              <SDKIcon className='ap-button p-0' code={'vertical-more'} />
            </SDKDropdownButton>
          </div>
        </div>
      </div>

      {/* NOTE martin-s-sevcik 2023/04/13 - postponed now
      <div>
        <div className='ap-container px-0 mx-0 gx-0'>
          <div className='row '>
            <div className='col'>
              <div>
                <SDKText>{t('infoPanel.countries.label')}</SDKText>
              </div>
              <div>{fiscalYear.countries}</div>
            </div>
            <div className='col'>
              <SDKText>{t('infoPanel.countrySnapshots.label')}</SDKText>
            </div>
            <div>{fiscalYear.snapshots}</div>
          </div>
        </div>
      </div> */}

      <Divider />
    </SDKPanel>
  )
}
