import { ICardResult } from '../../../../../types/resultsTab' //EFeeType,
import { ModuleCard } from '../ModuleCard'

interface IOptionalScopes {
  data: ICardResult[]
  withBorderBottom?: boolean
  iconCode: string
  iconOnClickHandler: (cardName: string) => () => void
}

export const OptionalScopes = ({ data, iconCode, iconOnClickHandler }: IOptionalScopes) => (
  <>
    {data?.map((card, index) => (
      <div className='ap-bg-color-background-alt ap-py-spacing-6 ap-border-radius-2 ap-mt-spacing-5'>
        <ModuleCard
          card={card}
          index={index}
          iconCode={iconCode}
          iconOnClickHandler={iconOnClickHandler(card.name)}
        />
      </div>
    ))}
  </>
)
