import api from '../../api'
import { FiscalYearUrl } from '../../constants/urls'

export interface IDeleteFiscalYearPayload {
  isActive: boolean
  name: number
  yearId: number
}

export const deleteFiscalYear = async (yearId: number): Promise<IDeleteFiscalYearPayload> => {
  return (await api.delete(`${FiscalYearUrl.Years}/${yearId}`)).data
}
