import axios, { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { useNavigate } from 'react-router-dom'
import { useApp } from '../context/app.context'
import { apiInstance } from './api'
import { RedirectUrl } from './constants'

export enum RequestMethod {
  GET = 'get'
}

const WHITELISTED_URLS = ['calculation/', 'powerbitemplates/']

const AxiosErrorHandler = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { sendNotification } = useApp()
  const navigate = useNavigate()
  const [axiosInterceptorHooked, setAxiosInterceptorHooked] = useState(false)

  const hardRedirect = (url: string) => {
    window.location.href = url
  }

  const softRedirect = (url: string) => {
    navigate(url)
  }

  useEffect(() => {
    const responseInterceptor = apiInstance.interceptors.response.use(
      (response: any) => response,
      (error: AxiosError) => {
        const message = error.response?.data || error.message
        const toastErrorMessage =
          typeof message === 'string' ? message : t('form.errors.unexpectedError')
        const status = error?.response?.status
        const isAuthenticated = !!auth.isAuthenticated
        const requestUrl = error.response?.config?.url
        const requestMethod = error.response?.config?.method

        const err = { ...error, message, code: status?.toString() }

        if (status === 401) {
          // We do not handle in any way. Logic in Application component will handle the expired/missing token

          return Promise.reject(err)
        }

        if (status === 403) {
          sendNotification({
            message: toastErrorMessage,
            status: 'error'
          })
          hardRedirect(RedirectUrl.MyProjectsPage)

          return Promise.reject(err)
        }

        if (requestMethod === RequestMethod.GET && status === 404) {
          const isWhitelistedUrl = !!WHITELISTED_URLS.find(
            url => requestUrl && requestUrl.toLowerCase().indexOf(url) > -1
          )

          if (isWhitelistedUrl) {
            return Promise.reject(err)
          }

          sendNotification({
            message: toastErrorMessage,
            status: 'error'
          })

          if (isAuthenticated) {
            softRedirect(RedirectUrl.MyProjectsPage)
          } else {
            softRedirect(RedirectUrl.IndexPage)
          }

          return Promise.reject(err)
        }

        // GET, 4xx status code
        if (requestMethod === RequestMethod.GET && String(status)?.slice(0, 1) === '4') {
          sendNotification({
            message: toastErrorMessage,
            status: 'error'
          })

          if (isAuthenticated) {
            softRedirect(RedirectUrl.MyProjectsPage)
          } else {
            softRedirect(RedirectUrl.IndexPage)
          }

          return Promise.reject(err)
        }

        return Promise.reject(err)
      }
    )

    setAxiosInterceptorHooked(true)

    return () => {
      axios.interceptors.response.eject(responseInterceptor)
    }
  }, [])

  return axiosInterceptorHooked ? children : null
}

export default AxiosErrorHandler
