import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { CardListWrapper } from '../../components/CardListWrapper'
import { Layout } from '../../components/Layout'
import { Url } from '../../constants/urls'
import { useYearFromUrl } from '../../hooks/useYearFromUrl'
import { useAppDispatch } from '../../store'
import * as yearsActions from '../fiscalYearSetup/store/actions'
import * as yearsSelectors from '../fiscalYearSetup/store/selectors'
import PBITHeader from './components/PBITHeader'
import { PowerBITemplatesList } from './components/PowerBITemplatesList'

const PowerBITemplates = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { year } = useYearFromUrl()

  const powerBITemplates = useSelector(yearsSelectors.getPowerBITemplates)

  useEffect(() => {
    if (year?.yearId) {
      dispatch(yearsActions.getPBITemplates({ yearId: year.yearId }))
    }
  }, [year])

  return (
    <Layout
      buttonComponent={
        <PBITHeader
          onButtonClick={() =>
            navigate(`${location.pathname}${Url.PowerBITemplatesManagementPage}`)
          }
        />
      }
    >
      <CardListWrapper classNames='power-bi-templates-page' resource={powerBITemplates}>
        <PowerBITemplatesList list={powerBITemplates.data} yearId={year?.yearId} />
      </CardListWrapper>
    </Layout>
  )
}

export default PowerBITemplates
