import { usePricingTab } from '../context/pricingTab.context'

export const useCardGridColumnCalculation = () => {
  const { priceYears } = usePricingTab()

  switch (priceYears.length) {
    case 1:
      return { feeCol: 2, textCol: 10, feeItemCol: 12 }
    case 2:
      return { feeCol: 3, textCol: 9, feeItemCol: 6 }
    default:
      return { feeCol: 5, textCol: 7, feeItemCol: 4 }
  }
}
