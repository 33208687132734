import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useSightlineVisibility } from '../../../../../context/hooks/useSightlineVisibility'
import { useProjectFromUrl } from '../../../../../hooks/useProjectFromUrl'
import { EExportAction, PowerBIAction, SIGHTLINE_ACTIONS } from '../../../constants'
import { getTemplateCalculationById } from '../../../store/selectors'
import { ECalculationType } from '../../../types/projectCalculation'
import { useDownloadExcel } from './useDownloadExcel'
import { useDownloadInputData } from './useDownloadInputData'
import { useSightlineCalculationResult } from './useSightlineCalculationResult'

const ALWAYS_ACTIVE_ACTION = [EExportAction.DownloadInputData]

export const useTaraDropdownData = (
  setGetCalendarFeedModalVisible: (value: boolean) => void,
  setShowSightlineCalculationResulLoadingModal: (value: boolean) => void,
  setshowDownloadExcelLoadingModal: (value: boolean) => void,
  setShowDownloadInputDataLoadingModal: (value: boolean) => void,
  setShowPowerBIActionModal: (value: PowerBIAction) => void
) => {
  const { t } = useTranslation('myprojects')
  const { project } = useProjectFromUrl()
  const { data: calculatedData } = useSelector(getTemplateCalculationById)
  const { sendExcelToSightline } = useSightlineCalculationResult(
    setShowSightlineCalculationResulLoadingModal
  )
  const { downloadExcel } = useDownloadExcel(
    setshowDownloadExcelLoadingModal,
    calculatedData?.calculationId
  )
  const { downloadInputData } = useDownloadInputData(
    setShowDownloadInputDataLoadingModal,
    calculatedData?.calculationId
  )
  const { visible: isSightlineVisible } = useSightlineVisibility()

  let dropdownData = [
    {
      value: EExportAction.DownloadPowerBI,
      label: t('projectDetail.projectTabs.tara.results.exportDropdownList.downloadPowerBI'),
      iconName: 'bar-chart',
      onClick: () => setShowPowerBIActionModal(PowerBIAction.Download)
    },
    {
      value: EExportAction.SendPBIToSightline,
      label: t('projectDetail.projectTabs.tara.results.exportDropdownList.sendPBIToSightline'),
      iconName: 'paper-airplane',
      onClick: () => setShowPowerBIActionModal(PowerBIAction.Send)
    },
    {
      value: EExportAction.DownloadExcel,
      label: t('projectDetail.projectTabs.tara.results.exportDropdownList.downloadExcel'),
      iconName: 'xls',
      onClick: () => downloadExcel()
    },
    {
      value: EExportAction.SendExcelToSightline,
      label: t('projectDetail.projectTabs.tara.results.exportDropdownList.sendExcelToSightline'),
      iconName: 'paper-airplane',
      onClick: () => sendExcelToSightline()
    },
    {
      value: EExportAction.GetCalendarFeed,
      label: t('projectDetail.projectTabs.tara.results.exportDropdownList.getCalendarFeed'),
      iconName: 'calendar',
      onClick: () => setGetCalendarFeedModalVisible(true)
    },
    {
      value: EExportAction.DownloadInputData,
      label: t('projectDetail.projectTabs.tara.results.exportDropdownList.downloadInputData'),
      iconName: 'download-light',
      onClick: () => downloadInputData()
    }
  ]

  if (!isSightlineVisible || !project?.tpEngagementId) {
    dropdownData = dropdownData.filter(item => !SIGHTLINE_ACTIONS.includes(item.value))
  }
  let disabledItemValuesIfSuccess: string[] = []
  const disabledItemValuesIfError = dropdownData
    .map(item => !ALWAYS_ACTIVE_ACTION.includes(item.value) && item.value)
    .filter(Boolean)

  if (!calculatedData?.hasCalendar) {
    disabledItemValuesIfSuccess.push(EExportAction.GetCalendarFeed)
  }

  const defaultExportAction = project?.tpEngagementId
    ? calculatedData?.status === ECalculationType.Success
      ? sendExcelToSightline
      : downloadInputData
    : downloadExcel

  return {
    dropdownData,
    defaultExportAction,
    disabledItemValuesIfError,
    disabledItemValuesIfSuccess
  }
}
