import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useApp } from '../../../../../context/app.context'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../store'
import {
  clearDownloadProjectsReport,
  clearDownloadQuestionsAndAnswersReport,
  clearDownloadRDPFormulasReport,
  clearDownloadUsersReport,
  downloadProjectsReport,
  downloadQuestionsAndAnswersReport,
  downloadRDPFormulasReport,
  downloadUsersReport
} from '../../../store/actions'
import {
  getDownloadProjectsReport,
  getDownloadQuestionsAndAnswersReport,
  getDownloadRDPFormulasReport,
  getUsersReport
} from '../../../store/selectors'

export const useDownloadActions = (year: number | null) => {
  const { sendNotification } = useApp()
  const dispatch = useAppDispatch()
  const [showLoadingModal, setShowLoadingModal] = useState(false)
  const { loading: projectsLoading, error: projectsError } = useSelector(getDownloadProjectsReport)
  const { loading: questionsAndAnswersLoading, error: questionsAndAnswersError } = useSelector(
    getDownloadQuestionsAndAnswersReport
  )
  const { loading: formulasLoading, error: formulasError } = useSelector(
    getDownloadRDPFormulasReport
  )
  const { loading: usersLoading, error: usersError } = useSelector(getUsersReport)

  const downloadProjectsReportExcel = () => {
    dispatch(downloadProjectsReport({ yearId: year! }))
    setShowLoadingModal(true)
  }

  const downloadQuestionsAndAnswersReportExcel = () => {
    dispatch(downloadQuestionsAndAnswersReport({ yearId: year! }))
    setShowLoadingModal(true)
  }

  const downloadRDPFormulasReportExcel = () => {
    dispatch(downloadRDPFormulasReport({ yearId: year! }))
    setShowLoadingModal(true)
  }
  const downloadUsersReportExcel = () => {
    dispatch(downloadUsersReport())
    setShowLoadingModal(true)
  }

  useEffect(() => {
    if (projectsLoading === LoadingStatus.Failed && projectsError) {
      sendNotification({
        message: projectsError,
        status: 'error'
      })
    }
    if (projectsLoading !== LoadingStatus.Pending) {
      setShowLoadingModal(false)
    }
  }, [projectsLoading])

  useEffect(() => {
    if (questionsAndAnswersLoading === LoadingStatus.Failed && questionsAndAnswersError) {
      sendNotification({
        message: questionsAndAnswersError,
        status: 'error'
      })
    }
    if (questionsAndAnswersLoading !== LoadingStatus.Pending) {
      setShowLoadingModal(false)
    }
  }, [questionsAndAnswersLoading])

  useEffect(() => {
    if (formulasLoading === LoadingStatus.Failed && formulasError) {
      sendNotification({
        message: formulasError,
        status: 'error'
      })
    }
    if (formulasLoading !== LoadingStatus.Pending) {
      setShowLoadingModal(false)
    }
  }, [formulasLoading])

  useEffect(() => {
    if (usersLoading === LoadingStatus.Failed && usersError) {
      sendNotification({
        message: usersError,
        status: 'error'
      })
    }
    if (usersLoading !== LoadingStatus.Pending) {
      setShowLoadingModal(false)
    }
  }, [usersLoading])

  useEffect(() => {
    return () => {
      dispatch(clearDownloadUsersReport())
      dispatch(clearDownloadRDPFormulasReport())
      dispatch(clearDownloadQuestionsAndAnswersReport())
      dispatch(clearDownloadProjectsReport())
    }
  }, [])

  return {
    showLoadingModal,
    setShowLoadingModal,
    downloadProjectsReportExcel,
    downloadQuestionsAndAnswersReportExcel,
    downloadRDPFormulasReportExcel,
    downloadUsersReportExcel
  }
}
