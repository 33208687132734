import type { Control } from 'react-hook-form'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormInput } from '../../../../../../components/Form/FormInput'
import { FormRadioButton } from '../../../../../../components/Form/FormRadioButton'
import { FormSelect } from '../../../../../../components/Form/FormSelect'
import { InfoBox } from '../../../../../../components/InfoBox'
import { SDKParagraph } from '../../../../../../components/SDK/Paragraph'
import { SDKTooltip } from '../../../../../../components/SDK/Tooltip'
import type { IFormValues, ISelectOption } from '../../../../../../schemas/useAddFiscalYearSchema'
import { EAddFiscalYearFields } from '../../../../../../schemas/useAddFiscalYearSchema'
import type { IOption } from '../../../../../../types/form'

import './index.scss'

interface IAddFiscalYearForm {
  control: Control<IFormValues, any>
  dropdownList: ISelectOption[]
  radioList: IOption[]
  yearId: number | null
}

export const AddEditFiscalYearForm = ({
  control,
  dropdownList,
  radioList,
  yearId
}: IAddFiscalYearForm) => {
  const { t } = useTranslation('fiscalYearSetup')
  const {
    field: { value }
  } = useController({ name: EAddFiscalYearFields.fiscalYearData, control })
  const infoText =
    value === radioList[0].value
      ? t('newFiscalYear.form.radioSelectExistingDataInfo')
      : t('newFiscalYear.form.radioSelectScratchInfo')

  const {
    formState: { isDirty }
  } = useController({ name: EAddFiscalYearFields.year, control })

  return (
    <div className='ap-container add-fiscal-year-form'>
      <div className='form-container'>
        <SDKParagraph className='ap-mb-spacing-3' type='body' weight={2}>
          {t('newFiscalYear.form.informationTitle')}
        </SDKParagraph>
        <div className='row'>
          <div className='col'>
            <SDKTooltip
              disabled={yearId ? true : isDirty}
              content={t('newFiscalYear.form.tooltip')!}
            >
              <FormInput
                name={EAddFiscalYearFields.year}
                type='text'
                label={t('newFiscalYear.form.fields.year')!}
                required={true}
                className='ap-mb-spacing-5'
                control={control}
                suffixIcon={!Boolean(yearId)}
                disabled={Boolean(yearId)}
              />
            </SDKTooltip>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <FormSelect
              name={EAddFiscalYearFields.status}
              label={t('newFiscalYear.form.fields.status')!}
              required={true}
              className='ap-mb-spacing-5'
              data={dropdownList}
              control={control}
              portalMode={!!yearId}
            />
          </div>
        </div>
        {!yearId && (
          <>
            <SDKParagraph className='ap-mb-spacing-3' type='body' weight={2}>
              {t('newFiscalYear.form.dataTitle')}
            </SDKParagraph>
            <div className='row'>
              <div className='col'>
                <FormRadioButton
                  name={EAddFiscalYearFields.fiscalYearData}
                  label={t('newFiscalYear.form.fields.status')!}
                  required={true}
                  className='ap-mb-spacing-5'
                  options={radioList}
                  withFlex
                  control={control}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <InfoBox content={infoText} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
