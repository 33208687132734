import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SDKTabber } from '../../../../components/SDK/Tabber'
import { EFeatureFlag, useFeatureFlags } from '../../../../hooks/useFeatureFlags'
import { ESearchResultsTABSTab } from '../../constants/tab'
import { SearchResultsResponse } from '../../types/searchResults'
import CountrySnapshotResult from './components/CountrySnapshotResult'
import NoResults from './components/NoResults'
import PricingToolResult from './components/PricingToolResult'
import RDPResult from './components/RDPResult'

interface ISearchResultsProps {
  searchQuery: string
  searchResults: SearchResultsResponse | null
}

const SearchResults = ({ searchQuery, searchResults }: ISearchResultsProps) => {
  const { t } = useTranslation('search')
  const { isEnabled } = useFeatureFlags()

  const countrySnapshotResultElements =
    searchResults?.countrySnapshotResults.map((r, index) => (
      <CountrySnapshotResult
        resultObject={r}
        key={`country-snapshot-result-${index}`}
      ></CountrySnapshotResult>
    )) ?? []
  const rdpResultElements =
    searchResults?.rdpResults.map((r, index) => (
      <RDPResult resultObject={r} key={`rdp-result-${index}`}></RDPResult>
    )) ?? []

  const pricingResultElements =
    searchResults?.pricingToolResults.map((r, index) => (
      <PricingToolResult resultObject={r} key={`pricing-result-${index}`}></PricingToolResult>
    )) ?? []

  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    setActiveIndex(0)
  }, [searchResults])

  const componentWrapper = (elements: JSX.Element[], resultName: string, query: string) => (
    <>
      {elements.length > 0 ? (
        <div>{elements}</div>
      ) : (
        <NoResults resultsName={resultName} searchQuery={query} />
      )}
    </>
  )

  const tabs = [
    {
      label: t(`tabs.countrySnapshot`) + ` (${countrySnapshotResultElements.length})`,
      value: ESearchResultsTABSTab.CS,
      hashUrl: 'countrySnapshot',
      component: componentWrapper(
        countrySnapshotResultElements,
        t(`tabs.countrySnapshot`),
        searchQuery
      )
    },
    {
      label: t(`tabs.rdp`) + ` (${rdpResultElements.length})`,
      value: ESearchResultsTABSTab.RDP,
      hashUrl: 'rdp',
      component: componentWrapper(rdpResultElements, t(`tabs.rdp`), searchQuery)
    }
  ]

  const pricingResultsTab = {
    label: t(`tabs.pricingTools`) + ` (${pricingResultElements.length})`,
    value: ESearchResultsTABSTab.Pricing,
    hashUrl: 'pricing',
    component: componentWrapper(pricingResultElements, t(`tabs.pricingTools`), searchQuery)
  }

  const isPricingEnabled = isEnabled(EFeatureFlag.Pricing)
  if (isPricingEnabled) {
    tabs.push(pricingResultsTab)
  }

  return (
    <>
      <SDKTabber
        tabs={tabs}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        type='filled'
        className='ap-mb-spacing-4'
      />
      {tabs[activeIndex]?.component}
    </>
  )
}

export default SearchResults
