import api from '../../api'
import { FiscalYearUrl, PowerBITemplateUrl } from '../../constants/urls'

export const deletePowerBITemplate = async ({
  templateId,
  yearId
}: {
  templateId: number
  yearId: number
}): Promise<null> => {
  return (
    await api.delete(
      `${FiscalYearUrl.Years}/${yearId}/${PowerBITemplateUrl.PowerBITemplates}/${templateId}`
    )
  ).data
}
