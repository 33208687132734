import { useMemo } from 'react'
import type { FieldValues } from 'react-hook-form'
import type { IOption } from '../../../../../../../../../types/form'
import type { ICountryListItem } from '../../../../../../../../countries/types'

export const useCountries = (allPublishedCountries: ICountryListItem[], values: FieldValues) => {
  const countryList: IOption[] = useMemo(() => {
    return (
      (Array.isArray(allPublishedCountries) &&
        allPublishedCountries?.map(country => ({
          value: String(country.countryId),
          label: country.name
        }))) ||
      []
    )
  }, [allPublishedCountries])

  const selectedCountries = useMemo(
    () =>
      (Array.isArray(allPublishedCountries) &&
        allPublishedCountries?.filter(({ countryId }: ICountryListItem) =>
          values.countryIds.includes(String(countryId))
        )) ||
      [],
    [allPublishedCountries, values.countryIds]
  )

  const offeredCountries = useMemo(
    () =>
      values.countryIds.length
        ? countryList.filter(({ value }: IOption) => !values.countryIds.includes(String(value)))
        : countryList,
    [countryList, values.countryIds]
  )

  return { countryList, selectedCountries, offeredCountries }
}
