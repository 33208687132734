import { createReducer } from '@reduxjs/toolkit'
import {
  Resource,
  getDefaultResourceState,
  setResourceFullfilled,
  setResourcePending,
  setResourceRejected
} from '../../../shared/utils/store'
import { SearchResultsResponse } from '../types/searchResults'
import { clearGetSearchResults, getSearchResults } from './actions'

export interface SearchState {
  searchResults: Resource<SearchResultsResponse | null>
}

const initialState: SearchState = {
  searchResults: getDefaultResourceState(null)
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getSearchResults.pending, state => {
      setResourcePending(state.searchResults)
    })
    .addCase(getSearchResults.rejected, (state, actions) => {
      setResourceRejected(state.searchResults, actions)
    })
    .addCase(getSearchResults.fulfilled, (state, actions) => {
      state.searchResults.data = actions.payload
      setResourceFullfilled(state.searchResults)
    })
    .addCase(clearGetSearchResults, state => {
      state.searchResults = initialState.searchResults
    })
)
