import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { useNavigate } from 'react-router-dom'
import { SDKButton } from '../../../components/SDK/Button'
import { Url } from '../../../constants/urls'
import { ErrorPage } from '../error'
import { ErrorDescription } from '../error/components/ErrorDescription'

export const AuthenticationFailedPage = ({
  setIsAuthenticationFailed
}: {
  setIsAuthenticationFailed?: (value: boolean) => void
}) => {
  const { t } = useTranslation('errors')
  const auth = useAuth()
  const navigate = useNavigate()

  const goToHomePage = () => {
    if (setIsAuthenticationFailed) {
      setIsAuthenticationFailed(false)
    }
    navigate(Url.IndexPage)
  }

  const signIn = () => {
    if (setIsAuthenticationFailed) {
      setIsAuthenticationFailed(false)
    }
    let signinRedirectArgs = {
      state: `${location.pathname}${location.hash}`
    }
    auth.signinRedirect(signinRedirectArgs)
  }

  return (
    <ErrorPage errorTitle={t('authenticationFailedPage.title').toString()}>
      <>
        <ErrorDescription text={t('authenticationFailedPage.description')} />
        <div className='ap-flex flex-wrap'>
          <SDKButton onClick={() => goToHomePage()} className='ap-mr-spacing-3'>
            {t('authenticationFailedPage.buttonGoToHomePage')}
          </SDKButton>
          <SDKButton onClick={() => signIn()}>
            {t('authenticationFailedPage.buttonSignIn')}
          </SDKButton>
        </div>
      </>
    </ErrorPage>
  )
}
