import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { Layout } from '../../components/Layout'
import { SDKButton } from '../../components/SDK/Button'
import { SDKParagraph } from '../../components/SDK/Paragraph'

import style from './index.module.scss'

const IndexPage = () => {
  const { t } = useTranslation()
  const auth = useAuth()

  return (
    <Layout>
      <div className='ap-container index-page'>
        <div className='row'>
          <div className='col col-12 col-md-8 mt-2'>
            <SDKParagraph className={style['main-title']} type='large-heading'>
              Transfer Pricing Essentials
            </SDKParagraph>
          </div>

          {!auth.isAuthenticated && (
            <div className='col col-12 col-md-2 mt-2 ap-flex justify-content-center'>
              <SDKButton onClick={() => auth.signinRedirect()}>{t('login.button')}</SDKButton>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
