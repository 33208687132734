import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Url } from '../../../../../../../constants/urls'
import { useApp } from '../../../../../../../context/app.context'
import { usePricingTab } from '../../../../../../../context/pricingTab.context'
import { useProjectFromUrl } from '../../../../../../../hooks/useProjectFromUrl'
import { LoadingStatus } from '../../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../../store'
import { usePricingTabs } from '../../../hooks/usePricingTabs'
import { clearDeleteCustomModal, deleteCustomModal } from '../../../modals/store/actions'
import { getDeleteCustomModule } from '../../../modals/store/selectors'
import { ICustomModuleData } from '../../../types/inputsTab'

export const useDeleteCustomModule = (
  data: ICustomModuleData | null,
  refreshCustomModules: () => void
) => {
  const { t } = useTranslation('pricing')
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const navigate = useNavigate()
  const {
    tabContent: { tabId },
    setCurrentTabIndex
  } = usePricingTab()
  const { tabs } = usePricingTabs(tabId)
  const { project } = useProjectFromUrl()
  const { error, loading } = useSelector(getDeleteCustomModule)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const handleDelete = () => {
    if (data) {
      const { projectId, moduleId } = data
      dispatch(deleteCustomModal({ projectId, customModuleId: moduleId }))
    }
  }

  useEffect(() => {
    if (loading === LoadingStatus.Failed && error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
    } else if (loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t(`tabs.customModule.delete.success`, { name: data?.name! }),
        status: 'success'
      })
      refreshCustomModules()
      if (project?.projectId) {
        navigate(`${Url.MyProjectsPage}/${project.projectId}#${tabs[tabId - 2].hashUrl}`)
        setCurrentTabIndex?.(tabId - 1)
      }
    }
    setShowDeleteModal(false)
  }, [loading])

  useEffect(() => {
    return () => {
      dispatch(clearDeleteCustomModal())
    }
  }, [])

  return { showDeleteModal, setShowDeleteModal, handleDelete, loading }
}
