import type { TextAreaProps } from '@appkit4/react-components/field'
import { TextArea } from '@appkit4/react-components/field'
import { Ref, forwardRef, useEffect, useRef } from 'react'
import type { Path } from 'react-hook-form'

interface SDKTextAreaProps<T> extends TextAreaProps {
  id: Path<T> | string
  title: string
  disabledSpellcheck?: boolean
}

export const SDKTextArea = forwardRef(
  <T,>({ disabledSpellcheck, ...props }: SDKTextAreaProps<T>, ref: Ref<HTMLDivElement>) => {
    const textareaRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
      if (textareaRef && textareaRef.current) {
        const input = textareaRef.current.getElementsByClassName('ap-field-input')[0]
        if (input && props.placeholder) {
          input.setAttribute('placeholder', props.placeholder)
          if (disabledSpellcheck) {
            input.setAttribute('spellcheck', 'false')
          }
        }
      }
    }, [])

    return <TextArea ref={textareaRef || ref} {...props} placeholder={props.placeholder} />
  }
)
