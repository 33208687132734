import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo, useState } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RHF_SET_VALUE_OPTIONS } from '../../../../../../components/Form/utils'
import { LoadingOverlay } from '../../../../../../components/SDK/LoadingOverlay'
import { SDKModal } from '../../../../../../components/SDK/Modal'
import { SDKProgress } from '../../../../../../components/SDK/Progress'
import { useApp } from '../../../../../../context/app.context'
import { useSightlineVisibility } from '../../../../../../context/hooks/useSightlineVisibility'
import { useProject } from '../../../../../../context/project.context'
import { useSubmitButton } from '../../../../../../hooks/useSubmitButton'
import type { IFormValues } from '../../../../../../schemas/useAddEditProjectSchema'
import { useAddEditProjectSchema } from '../../../../../../schemas/useAddEditProjectSchema'
import { LoadingStatus } from '../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../store'
import * as countriesActions from '../../../../../countries/store/actions'
import * as countriesSelectors from '../../../../../countries/store/selectors'
import { EProjectFields } from '../../../../types'
import { FormMode } from '../../FormMode'
import { SightlineSelectModal } from '../SightlineSelectModal'
import type { ISightlineMessage } from '../SightlineSelectModal/types'
import { AddEditProjectForm } from './components/AddEditProjectForm'
import { EWizardStep } from './const'
import { useAddEditProjectModal } from './hooks/useAddEditProjectModal'
import { useWizardSteps } from './hooks/useWizardSteps'
import './index.scss'

interface IAddEditProjectModal {
  cleanUp: () => void
  onClose: () => void
  projectId: number | null
  mode: FormMode
}

export const AddEditProjectModal = ({
  cleanUp,
  onClose,
  projectId,
  mode
}: IAddEditProjectModal) => {
  const { t } = useTranslation('myprojects')
  const dispatch = useAppDispatch()
  const { activeStep, steps, updateActiveStep, wizardSteps } = useWizardSteps(projectId)
  const { defaultValues, validationSchemas } = useAddEditProjectSchema()
  const validationSchema = useMemo(() => validationSchemas[activeStep.code], [activeStep])
  const [showSightline, setShowSightline] = useState(false)
  const [sightlineNotificationMessage, setSightlineNotificationMessage] = useState(null)
  const { sendNotification } = useApp()
  const { visible } = useSightlineVisibility()
  const { project } = useProject()

  const { handleSubmit, control, resetField, getValues, setValue, trigger, formState } = useForm<
    FieldValues,
    IFormValues
  >({
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const { modal, onSubmit } = useAddEditProjectModal({ project, onClose, projectId })

  const { data: allPublishedCountries, loading: publishedCountriesLoading } = useSelector(
    countriesSelectors.getPublishedCountries
  )

  const dispatchCountries = () => {
    const yearId = getValues()[EProjectFields.YearId] || project?.yearId
    if (yearId) {
      dispatch(countriesActions.getPublishedCountries(yearId))
    }
  }

  useEffect(() => {
    cleanUp()
  }, [])

  useEffect(() => {
    if (modal.error) {
      sendNotification({ message: modal.error, status: 'error' })
    }
  }, [modal.error])

  useEffect(() => {
    if (sightlineNotificationMessage) {
      sendNotification({ message: sightlineNotificationMessage, status: 'success' })
    }
  }, [sightlineNotificationMessage])

  useEffect(() => {
    if (modal.loading === LoadingStatus.Succeeded) {
      const notificationMessage = t(
        `projectForm.notification.${projectId ? 'updateSuccessInfo' : 'successInfo'}`,
        {
          project: modal.data?.name ?? ''
        }
      )

      sendNotification({ message: notificationMessage, status: 'success' })
    }
  }, [modal.loading])

  useEffect(() => {
    if (activeStep.index < 0) {
      onClose()
    }

    if (activeStep.code === EWizardStep.ProjectCountries) {
      dispatchCountries()
      trigger()
    }

    if (formState.isDirty) {
      trigger()
    }
  }, [activeStep.index, project])

  const isLoading =
    publishedCountriesLoading !== LoadingStatus.Succeeded &&
    activeStep.code === EWizardStep.ProjectCountries

  useEffect(() => {
    dispatchCountries()
  }, [getValues()[EProjectFields.YearId]])

  useEffect(() => {
    Object.keys(defaultValues).map((key: string) =>
      setValue(key as EProjectFields, defaultValues[key as EProjectFields])
    )
  }, [project])

  useEffect(() => {
    if (!project) {
      setValue(EProjectFields.CountryIds, [])
    }
  }, [getValues()[EProjectFields.YearId]])

  const setSightline = (sightline: ISightlineMessage) => {
    setValue(EProjectFields.TpEngagementId, sightline.id, RHF_SET_VALUE_OPTIONS)
    setValue(EProjectFields.TpEngagementName, sightline.name, RHF_SET_VALUE_OPTIONS)
    setShowSightline(false)
    setSightlineNotificationMessage(() =>
      t('sightlineModal.successNotification', { name: sightline.name })
    )
  }

  useEffect(() => {
    if (!showSightline) {
      setTimeout(() => window.getSelection()?.removeAllRanges(), 300)
    }
  }, [showSightline])

  const { disabled } = useSubmitButton({ formState })

  let showModal = mode == FormMode.Add || (mode == FormMode.Edit && projectId != null)

  const onCancel = () => {
    onClose()
  }

  return (
    <>
      {visible && (
        <SDKModal
          onCancel={() => setShowSightline(false)}
          title={t('sightlineModal.title')!}
          footerClassName='add-project-modal-footer'
          visible={showSightline}
          withRequired={false}
        >
          <SightlineSelectModal onSelect={setSightline} />
        </SDKModal>
      )}

      {showModal && (
        <SDKModal
          disabledSubmit={disabled}
          onCancel={onCancel}
          onSubmit={handleSubmit(onSubmit)}
          submitLabel={t(`projectForm.submitLabel.${projectId ? 'save' : 'create'}`)!}
          title={
            projectId ? `${activeStep.title} ${project?.name ?? ''}` : t('projectForm.title.add')!
          }
          visible={!showSightline}
          wizardFooter={{
            step: projectId
              ? { current: 0, count: 1, setStep: updateActiveStep }
              : { current: activeStep.index, count: steps.length, setStep: updateActiveStep },
            nextButton: { disabled },
            onCancel: onCancel
          }}
        >
          {modal.loading === LoadingStatus.Pending && <LoadingOverlay type='modal' />}
          <>
            {!projectId && (
              <SDKProgress
                activeIndex={activeStep.index}
                className='fullsize'
                onActiveIndexChange={updateActiveStep}
                readonly
                steps={wizardSteps.map(wizardStep => t(`projectForm.${wizardStep}.title`))}
              ></SDKProgress>
            )}

            <AddEditProjectForm
              activeStep={activeStep}
              allPublishedCountries={allPublishedCountries}
              control={control}
              countriesLoading={!(publishedCountriesLoading === LoadingStatus.Succeeded)}
              isLoading={isLoading}
              onClickShowSightline={() => setShowSightline(true)}
              projectId={projectId}
              originalProjectId={project?.[EProjectFields.OriginalProjectId] || null}
              originalProjectName={project?.[EProjectFields.OriginalProjectName] || null}
              resetField={resetField}
              setValue={setValue}
              trigger={trigger}
              values={getValues()}
              hasChangedValues={!!project?.hasChangedValues}
            />
          </>
        </SDKModal>
      )}
    </>
  )
}
