import { useEffect, useMemo, useState } from 'react'
import { DEFAULT_DELAY, useDebounce } from '../../../shared/utils/useDebounce'
import { DEFAULT_PAGE_LIMIT } from '../../../shared/utils/usePagination'
import { useAppDispatch } from '../../../store'
import * as actions from '../store/actions'
import { ESortType, INewsfeed } from '../types'

export const useUpdates = (currentPage: number, offset: number, newsfeed?: INewsfeed[]) => {
  const dispatch = useAppDispatch()
  const [search, setSearch] = useState<string>('')
  const [sort, setSort] = useState<ESortType>(ESortType.Desc)

  const onChange = (
    value: string,
    _event: React.SyntheticEvent | React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearch(value)
  }

  const debouncedSearchValue = useDebounce<string>(search, DEFAULT_DELAY)

  useEffect(() => {
    dispatch(
      actions.getNewsfeed({
        searchValue: search,
        sortOrder: sort,
        limit: DEFAULT_PAGE_LIMIT,
        offset: search.length > 0 ? 0 : offset
      })
    )
  }, [sort, debouncedSearchValue, currentPage])

  const mappedNewsfeed = useMemo(
    () =>
      newsfeed?.map(item => ({
        id: String(item.id),
        primary: item.eventDescription,
        secondary: item.eventDate
      })),
    [newsfeed]
  )

  return { mappedNewsfeed, onChange, setSort, sort, debouncedSearchValue }
}
