import api from '../../api'
import { SystemManagementUrl } from '../../constants/urls'

export interface IAddApproveLegalDocumentDataPayload {
  approvedDocumentIds: number[]
}

export const addApproveLegalDocumentData = async (
  params: IAddApproveLegalDocumentDataPayload
): Promise<string> => {
  return (
    await api.post(
      `${SystemManagementUrl.LegalDocumentApprovals}/${SystemManagementUrl.Approvals}`,
      params
    )
  ).data
}
