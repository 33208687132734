import { useTranslation } from 'react-i18next'
import { SDKIcon } from '../../../../../../../../../../components/SDK/Icon'
import { SDKText } from '../../../../../../../../../../components/SDK/Text'
import { SDKTooltip } from '../../../../../../../../../../components/SDK/Tooltip'
import type { ICountry } from '../../../../../../../../../countries/types'

interface ICountryItemProps {
  item: ICountry
  handleClick: (_id: number) => void
}

export const CountryItem = ({ item, handleClick }: ICountryItemProps) => {
  const { t } = useTranslation()

  return (
    <>
      <SDKText weight={2}>{item.name}</SDKText>
      <div className='buttons-wrapper' onClick={() => handleClick(item.countryId)}>
        <div className='ap-mx-spacing-2'>
          <SDKTooltip content={t('buttons.delete')!}>
            <SDKIcon code='delete' className={'ap-text-color-text-primary'} />
          </SDKTooltip>
        </div>
      </div>
    </>
  )
}
