import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../context/app.context'
import { useAppDispatch } from '../../../store'
import { clearDeleteYear, deleteYear } from '../store/actions'
import * as selectors from '../store/selectors'
import { FiscalYearInfo } from '../types'

export const useDeleteFiscalYear = () => {
  const { t } = useTranslation('fiscalYearSetup')
  const dispatch = useAppDispatch()
  const { sendNotification } = useApp()
  const { data: years } = useSelector(selectors.getYears)
  const { loading, error } = useSelector(selectors.getDeleteYear)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [year, setYear] = useState<FiscalYearInfo | null>(null)

  const onDelete = (id: number) => {
    dispatch(clearDeleteYear())
    setShowDeleteModal(true)
    const currentYear = years.find(item => item.yearId === id)
    setYear(currentYear || null)
  }

  const onSubmitDelete = () => {
    if (year) {
      dispatch(deleteYear({ yearId: year.yearId }))
    } else {
      sendNotification({
        message: t('deleteFiscalYear.error'),
        status: 'error'
      })
    }
  }

  const onClose = () => {
    setShowDeleteModal(false)
    dispatch(clearDeleteYear())
  }

  return { onDelete, showDeleteModal, year, onSubmitDelete, error, loading, onClose }
}
