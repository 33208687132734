import { useEffect } from 'react'
import type { UseFormReset } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useApp } from '../../../../../context/app.context'
import {
  addApproveLegalDocument,
  clearAddApproveLegalDocument
} from '../../../../../modules/systemManagement/store/actions'
import * as selectors from '../../../../../modules/systemManagement/store/selectors'
import { IFormValues } from '../../../../../schemas/useLegalStatementsApprovalSchema'
import { LoadingStatus } from '../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../store'

export const useApproveDocument = (
  reset: UseFormReset<IFormValues>,
  setVisible: (v: boolean) => void
) => {
  const dispatch = useAppDispatch()
  const { loading } = useSelector(selectors.addApproveLegalDocument)

  const {
    appContext: { user },
    updateUserContext
  } = useApp()

  const cleanUp = () => {
    dispatch(clearAddApproveLegalDocument())
    reset()
    setVisible(false)
  }

  const onSubmit = async (data: IFormValues) => {
    dispatch(addApproveLegalDocument(data))
  }

  useEffect(() => {
    if (loading === LoadingStatus.Succeeded && user) {
      updateUserContext({ ...user, acceptedAllLegalDocuments: true })
      cleanUp()
    }
  }, [loading])

  return { cleanUp, onSubmit }
}
