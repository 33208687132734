import { Dispatch, SetStateAction } from 'react'
import { CardWithFee } from '../../../../../components/CardDnDWrapper/components/cards/CardWithFee'
import { DefaultCard } from '../../../../../components/CardDnDWrapper/components/cards/DefaultCard'
import { EAction, ECardDnDItem } from '../../../../../components/CardDnDWrapper/const'
import { IActionCallback } from '../../../../../components/CardDnDWrapper/type'
import { useOnCardClick } from '../../../../../components/CardDnDWrapper/utils/useOnCardClick'
import { Divider } from '../../../../../components/Divider'
import { DraggableItem } from '../components/DraggableItem'
import { EBenchmarkType, ITransformedData, TableType } from '../types/dnd'
import { IBenchmarkQuotes, IQuote } from '../types/inputsTab'
import { IChildScope, IParentScope, TParentScope } from '../types/scope'
import { useDragLayer } from './useDragLayer'

const DEFAULT_ACTIONS = [EAction.Enable, EAction.Edit]

export const useTableItems = (
  content: ITransformedData,
  setContent: Dispatch<SetStateAction<ITransformedData>>,
  actions?: EAction[],
  benchmarkQuotes?: IBenchmarkQuotes,
  quotes?: Partial<IQuote>
) => {
  const { onChildScopeClick, onParentScopeClick, loading } = useOnCardClick()
  const { moveRowHandler, hoveredItemIndex, setHoveredItemIndex, hoveredTableIndex } = useDragLayer(
    content,
    setContent
  )

  const transformBenchmarkQuotes = (benchmarkName: string) => {
    if (benchmarkName === EBenchmarkType.GlobalBenchmarking) {
      return {
        ...benchmarkQuotes,
        parentScopeYearTotals: benchmarkQuotes!.globalScopeYearTotals
      } as IQuote
    }
    if (benchmarkName === EBenchmarkType.LocalBenchmarking) {
      return {
        ...benchmarkQuotes,
        parentScopeYearTotals: benchmarkQuotes!.localScopeYearTotals
      } as IQuote
    }
    return {
      ...benchmarkQuotes,
      parentScopeYearTotals: benchmarkQuotes!.regionalScopeYearTotals
    } as IQuote
  }

  const returnItemsForTable = (index: number, tableId: string, tableName: TableType) => {
    const currentTable = content.tables[index]
    return content.tables[index].rows.map((item, i) => {
      return (
        <DraggableItem
          key={item.id}
          tableId={tableId}
          setContent={setContent}
          id={item.id}
          index={i}
          moveRowHandler={moveRowHandler}
          tableName={tableName}
          acceptType={currentTable.acceptType}
          hoveredItemIndex={hoveredTableIndex === index ? hoveredItemIndex : null}
          setHoveredItemIndex={setHoveredItemIndex}
          content={content}
        >
          {currentTable.rowsType === ECardDnDItem.cardWithFee ? (
            <CardWithFee
              data={item as IParentScope}
              quotes={
                benchmarkQuotes
                  ? transformBenchmarkQuotes(currentTable.benchmarkName || '')
                  : (quotes as IQuote)
              }
              actions={actions || DEFAULT_ACTIONS}
              actionCallback={(callback: IActionCallback) =>
                onParentScopeClick(callback, {
                  country: '',
                  localFeeCountryId: 0,
                  parentScopeId: item.parentScopeId,
                  priceYears: [],
                  ...item
                } as TParentScope)
              }
              country={(item as IParentScope).country}
              withoutDnD={currentTable.withoutDnD}
            />
          ) : (
            <DefaultCard
              data={item as IChildScope}
              actions={actions || DEFAULT_ACTIONS}
              actionCallback={(callback: IActionCallback) =>
                onChildScopeClick(callback, item as IChildScope)
              }
              greyBackground={tableName === TableType.ScopeAssumes}
            />
          )}
          {currentTable.rowsType === ECardDnDItem.cardWithFee && currentTable.rows.length > 1 && (
            <Divider />
          )}
        </DraggableItem>
      )
    })
  }

  return { returnItemsForTable, moveRowHandler, loading }
}
