import { SDKList } from '../../../../../../../../../../components/SDK/List'
import { SDKListItem } from '../../../../../../../../../../components/SDK/ListItem'
import type { ICountry, ICountryListItem } from '../../../../../../../../../countries/types'
import { CountryItem } from './CountryItem'

import './CountriesList.scss'

interface ICountriesListProps {
  countries: ICountryListItem[]
  handleClick: (_id: number) => void
}

export const CountriesList = ({ countries, handleClick }: ICountriesListProps) => {
  const renderItem = (item: ICountry, _: number) => {
    return (
      <SDKListItem
        key={`country-id-${item.countryId}`}
        className={'ap-border-neutral-02 country-list-item'}
      >
        <CountryItem item={item} handleClick={handleClick} />
      </SDKListItem>
    )
  }

  return (
    <SDKList
      data={countries}
      itemKey={'countryId'}
      renderItem={renderItem}
      className={'country-list'}
    ></SDKList>
  )
}
