import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SDKModal } from '../../../../components/SDK/Modal'
import { useSubmitButton } from '../../../../hooks/useSubmitButton'
import { EEditUserFields, useAddEditUserSchema } from '../../../../schemas/useAddEditUserSchema'
import type { IUser } from '../../../../types/user'
import { AddEditUserForm } from './components/AddEditUserForm'
import { EModalMode } from './const'
import { useAddEditUser } from './utils/useAddEditUser'

interface IAddEditUserModal {
  modalMode: EModalMode | null
  onCloseModal: () => void
  user: IUser
}

export const AddEditUserModal = ({ modalMode, onCloseModal, user }: IAddEditUserModal) => {
  const { t } = useTranslation('users')
  const {
    defaultValues,
    getDefaultValues,
    isAdministratorRadioList,
    isServiceAccountRadioList,
    isActiveRadioList,
    validationSchema
  } = useAddEditUserSchema(user)
  const { handleSubmit, setValue, control, reset, formState } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange'
  })
  const { onSubmit, onClose } = useAddEditUser(modalMode, onCloseModal, reset, user)

  const modalTitleKey: string = useMemo(() => {
    const TITLE_TRANSLATION_KEY = `userForm.title`

    switch (modalMode) {
      case EModalMode.CreateServiceAccount:
        return t(`${TITLE_TRANSLATION_KEY}.serviceAccount`)
      case EModalMode.EditServiceAccount:
        return t(`${TITLE_TRANSLATION_KEY}.editServiceAccount`)!
      default:
        return t(`${TITLE_TRANSLATION_KEY}.edit`)!
    }
  }, [modalMode])

  const { disabled } = useSubmitButton({ formState })

  useEffect(() => {
    const updatedDefaultValues = getDefaultValues(user)
    setValue(EEditUserFields.email, updatedDefaultValues[EEditUserFields.email])
    setValue(EEditUserFields.fullName, updatedDefaultValues[EEditUserFields.fullName])
    setValue(
      EEditUserFields.isAdministratorOption,
      updatedDefaultValues[EEditUserFields.isAdministratorOption]
    )
    setValue(
      EEditUserFields.isServiceAccountOption,
      updatedDefaultValues[EEditUserFields.isServiceAccountOption]
    )
    setValue(EEditUserFields.isActiveOption, updatedDefaultValues[EEditUserFields.isActiveOption])
  }, [user])

  return (
    <form>
      <SDKModal
        visible={modalMode !== null}
        onCancel={onClose}
        title={modalTitleKey}
        disabledSubmit={disabled}
        onSubmit={handleSubmit(onSubmit)}
      >
        <AddEditUserForm
          control={control}
          isActiveRadioList={isActiveRadioList}
          isAdministratorRadioList={isAdministratorRadioList}
          isServiceAccountRadioList={isServiceAccountRadioList}
          modalMode={modalMode!}
        />
      </SDKModal>
    </form>
  )
}
