import classNames from 'classnames'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CardListWrapper } from '../../../../components/CardListWrapper'
import { Layout } from '../../../../components/Layout'
import { LoadingModal } from '../../../../components/LoadingModal'
import { AnswerText } from '../../../../components/QuestionWithAnswersCard/components/AnswerText'
import { SDKAccordion } from '../../../../components/SDK/Accordion'
import { SDKAccordionItem } from '../../../../components/SDK/AccordionItem'
import { SDKButton } from '../../../../components/SDK/Button'
import { SDKIcon } from '../../../../components/SDK/Icon'
import { LoadingOverlay } from '../../../../components/SDK/LoadingOverlay'
import { SDKModal } from '../../../../components/SDK/Modal'
import { SDKText } from '../../../../components/SDK/Text'
import { SDKTextButton } from '../../../../components/SDK/TextButton'
import { Url } from '../../../../constants/urls'
import { useApp } from '../../../../context/app.context'
import { ProjectContextProvider, useProject } from '../../../../context/project.context'
import { LoadingStatus } from '../../../../shared/types/enums'
import { useAppDispatch } from '../../../../store'
import {
  EAnswerTypeForEdit,
  EAnswerTypeFromBE
} from '../../../countriesSnapshot/pages/AddEditQuestion/constants/answerType'
import { InfoPanel } from '../../../project/components/InfoPanel'
import type { IAnswer } from '../../../questionnaires/type'
import {
  clearDownloadPricingToolImport,
  downloadPricingToolImport
} from '../../../systemManagement/store/actions'
import { downloadPricingToolsImport } from '../../../systemManagement/store/selectors'
import * as actions from '../../store/actions'
import * as selectors from '../../store/selectors'
import { ChangedValuesTable } from './components/Table'
import './index.scss'
import { EProjectChangeType, IChangeItem, TAccordionValue, TTableValue } from './types'

const ChangedValuesContent = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {
    sendNotification,
    appContext: {
      style: { isMobile }
    }
  } = useApp()
  const { project, refreshProject } = useProject()
  const { t } = useTranslation('myprojects')
  const resource = useSelector(selectors.getProjectChanges)
  const { error, loading } = useSelector(selectors.updateProjectChanges)
  const { loading: downloadLoading } = useSelector(downloadPricingToolsImport)
  const [accordionValues, setAccordionValues] = useState<TAccordionValue>({})
  const [showModal, setShowModal] = useState(false)
  const [showLoadingModal, setShowLoadingModal] = useState(false)

  useEffect(() => {
    if (project) {
      dispatch(actions.getProjectChanges({ projectId: project.projectId }))
    }
  }, [project?.projectId])

  useEffect(() => {
    if (error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
      dispatch(actions.clearUpdateProjectChanges())
    }

    if (loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t('changedValues.modal.successInfo'),
        status: 'success'
      })
      refreshProject()
      dispatch(actions.clearUpdateProjectChanges())

      if (project) {
        navigate(`${Url.MyProjectsPage}/${project.projectId}`)
      }
    }
  }, [error, loading])

  useEffect(() => {
    if (downloadLoading === LoadingStatus.Failed) {
      sendNotification({
        message: t('changedValues.pricingToolImportTemplate.fileDownloadError'),
        status: 'error'
      })
      setShowLoadingModal(false)
      dispatch(clearDownloadPricingToolImport())
    }

    if (downloadLoading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t('changedValues.pricingToolImportTemplate.fileDownloaded'),
        status: 'success'
      })
      setShowLoadingModal(false)
      dispatch(clearDownloadPricingToolImport())
    }
  }, [downloadLoading])

  const returnQuestionAnswer = (questionType: number, answer: IAnswer) => {
    const type = Object.keys(EAnswerTypeFromBE).find(
      key => EAnswerTypeFromBE[key as keyof typeof EAnswerTypeFromBE] === questionType
    )

    let answerText: string
    switch (questionType as EAnswerTypeFromBE) {
      case EAnswerTypeFromBE.CurrencyRate:
      case EAnswerTypeFromBE.TaxRate:
        if (answer.isNotAnswered) {
          answerText = answer.answerText
        } else {
          answerText = answer.rate!.toString()
        }
        break
      default:
        answerText = answer.answerText
        break
    }

    return (
      <AnswerText
        answerText={answerText}
        type={type as EAnswerTypeForEdit}
        calendarAnswerResponse={answer.calendarAnswerResponse}
        selectedAnswers={answer.selectedAnswers}
        id={answer.answerId || 0}
        isNotAnswered={answer.isNotAnswered}
        daysOffset={answer.calendarAnswerResponse?.daysOffset}
        monthsOffset={answer.calendarAnswerResponse?.monthsOffset}
      />
    )
  }

  const pricingToolImportDownloadHandler = (versionId: number | null): void => {
    if (versionId) {
      dispatch(downloadPricingToolImport({ versionId }))
      setShowLoadingModal(true)
    }
  }

  const getPricingToolBtn = (versionId: number | null): ReactElement => (
    <SDKTextButton onClick={() => pricingToolImportDownloadHandler(versionId)}>
      <>
        <SDKIcon code='download' />
        Pricing Tool Import Template Version {versionId}
      </>
    </SDKTextButton>
  )

  const getValues = (item: IChangeItem) => {
    switch (item.projectChangeType) {
      case EProjectChangeType.AnswerChanged:
        return {
          newValue:
            item.newAnswer && item.questionType !== null
              ? returnQuestionAnswer(item.questionType, item.newAnswer)
              : '',
          oldValue:
            item.oldAnswer && item.questionType !== null
              ? returnQuestionAnswer(item.questionType, item.oldAnswer)
              : ''
        }
      case EProjectChangeType.FormulaChanged:
        return {
          newValue: item.newFormulaIsActive ? item.newFormula : t('changedValues.overrideDisabled'),
          oldValue: item.oldFormulaIsActive ? item.oldFormula : t('changedValues.overrideDisabled')
        }
      case EProjectChangeType.QuestionDeleted:
        return { newValue: t('changedValues.table.deletedQuestions'), oldValue: '' }

      case EProjectChangeType.PricingToolVersionIdChanged:
        return {
          newValue: getPricingToolBtn(item.newPricingToolVersionId),
          oldValue: getPricingToolBtn(item.oldPricingToolVersionId)
        }

      default:
        return { newValue: '', oldValue: '' }
    }
  }

  const groupTableValues = (values: TTableValue[]) => {
    const groupedValues = values.reduce((group, item) => {
      const { country } = item
      group[country] = group[country] ?? []
      group[country].push(item)
      return group
    }, {} as TAccordionValue)

    setAccordionValues(groupedValues)
  }

  const setCountryName = (name: string, type: EProjectChangeType) => {
    switch (type) {
      case EProjectChangeType.QuestionDeleted:
        return t('changedValues.deletedQuestions')
      default:
        return name
    }
  }

  useEffect(() => {
    const values: TTableValue[] = []
    if (resource.data) {
      resource.data.forEach(element => {
        const { newValue, oldValue } = getValues(element.changeItem)
        values.push({
          country: setCountryName(element.countryName, element.changeItem.projectChangeType),
          item: element.itemName,
          newValue: newValue || '',
          oldValue: oldValue || ''
        })
      })
      groupTableValues(values)
    }
  }, [resource.data])

  const onSubmit = () => {
    if (project) {
      dispatch(actions.updateProjectChanges({ projectId: project.projectId }))
    }

    setShowModal(false)
  }

  const headerButton = (
    <SDKButton className={classNames(isMobile && 'w-100')} onClick={() => setShowModal(true)}>
      {t('changedValues.headerButtonText')}
    </SDKButton>
  )

  return (
    <Layout breadcrumbs={{ project: project?.name! }} buttonComponent={headerButton}>
      {loading === LoadingStatus.Pending && <LoadingOverlay />}

      <InfoPanel text={t(`changedValues.info`)!} />
      <CardListWrapper classNames='changed-values-container' resource={resource}>
        <SDKAccordion multiple={true}>
          {Object.keys(accordionValues).map(country => (
            <SDKAccordionItem
              className='ap-bg-color-background-alt'
              itemKey={`item-key-${country}`}
              key={`item-key-${country}`}
              templateHeader={() => (
                <SDKText type='subheading' weight={2}>
                  {country}
                </SDKText>
              )}
            >
              <ChangedValuesTable originalData={accordionValues[country]} />
            </SDKAccordionItem>
          ))}
        </SDKAccordion>
      </CardListWrapper>
      {showModal && (
        <SDKModal
          onCancel={() => setShowModal(false)}
          title=''
          submitLabel={t('changedValues.modal.submitLabel')!}
          onSubmit={onSubmit}
          withRequired={false}
          withCancel
          visible
        >
          <SDKText>{t('changedValues.modal.text')}</SDKText>
        </SDKModal>
      )}

      {showLoadingModal && (
        <LoadingModal
          setVisible={setShowLoadingModal}
          text={t('changedValues.pricingToolImportTemplate.downloadingInfo')!}
        />
      )}
    </Layout>
  )
}

const ChangedValues = () => {
  return (
    <ProjectContextProvider>
      <ChangedValuesContent />
    </ProjectContextProvider>
  )
}

export default ChangedValues
