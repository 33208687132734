import classNames from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import type {
  Control,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormCard } from '../../../../../../components/Form/FormCard'
import { FormCheckboxGroup } from '../../../../../../components/Form/FormCheckboxGroup'
import { FormInput } from '../../../../../../components/Form/FormInput'
import { FormRadioButton } from '../../../../../../components/Form/FormRadioButton'
import { FormSelect } from '../../../../../../components/Form/FormSelect'
import { FormTextArea } from '../../../../../../components/Form/FormTextArea'
import { RHF_SET_VALUE_OPTIONS } from '../../../../../../components/Form/utils'
import { SDKBreadcrumbsWithText } from '../../../../../../components/SDK/BreadcrumbsWithText'
import { SDKCheckbox } from '../../../../../../components/SDK/Checkbox'
import { SDKIcon } from '../../../../../../components/SDK/Icon'
import { ISelectValue } from '../../../../../../components/SDK/Select'
import { SDKText } from '../../../../../../components/SDK/Text'
import { SDKTooltip } from '../../../../../../components/SDK/Tooltip'
import { EAddAnswerFields, IFormValues } from '../../../../../../schemas/useAddAnswerSchema'
import { IOption } from '../../../../../../types/form'
import { isNumeric } from '../../../../../../utils/isNumber'
import { EAnswerTypeForEdit } from '../../../../../countriesSnapshot/pages/AddEditQuestion/constants/answerType'
import { IAnswer } from '../../../../../project/types/projectResults'
import { IQuestionLanguage, IQuestionnaireQuestion } from '../../../../type'
import { AnswerTypeCalendar } from './AnswerTypeCalendar'

import './index.scss'

export interface IAnswerFormProps {
  question: IQuestionnaireQuestion | null
  control: Control<FieldValues, IFormValues>
  getValues: UseFormGetValues<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  languages: IQuestionLanguage[]
  trigger: UseFormTrigger<FieldValues>
  defaultValues: IFormValues
  cardClassName?: string
  isEditing?: boolean
}

const AnswerForm = ({
  question,
  control,
  getValues,
  setValue,
  languages,
  trigger,
  defaultValues,
  cardClassName,
  isEditing
}: IAnswerFormProps) => {
  const { t } = useTranslation('questionnaires')
  const [checkboxValue, setCheckboxValue] = useState<number[]>([])

  useEffect(() => {
    setCheckboxValue(defaultValues.multipleChoice)
  }, [defaultValues])

  const onCheckboxChange = (value: (string | number)[]) => {
    setCheckboxValue(value as number[])
    setValue(EAddAnswerFields.multipleChoice, value, RHF_SET_VALUE_OPTIONS)
    trigger([EAddAnswerFields.multipleChoice])
  }

  const onSelectLanguage = (value: ISelectValue) => {
    const selectedLanguageId = languages.find(l => l.name === value)?.languageId
    if (selectedLanguageId) {
      setValue(EAddAnswerFields.selectedLanguageId, selectedLanguageId)
    }
  }

  const getCurrencyRateExample = () => {
    let currencyRate = String(getValues()[EAddAnswerFields.rate])

    if (!currencyRate || currencyRate.length === 0) {
      return <></>
    }
    if (currencyRate?.slice(currencyRate.length - 1) === '.') {
      currencyRate = currencyRate?.slice(0, currencyRate.length - 1)
    }

    return (
      <>
        {isNumeric(currencyRate) &&
          t(
            `answerForm.currencyRateExample`,
            {
              currency: question?.currencyCode,
              value: currencyRate
            }!
          )}
      </>
    )
  }

  const text = (type: EAddAnswerFields, required?: boolean) => (
    <FormTextArea
      name={EAddAnswerFields[type]}
      label={t(`answerForm.${type}`)!}
      className='ap-mb-spacing-5 textearea-double-height'
      control={control}
      required={!!required}
    />
  )

  const select = (name: EAddAnswerFields) => (
    <FormSelect
      name={name}
      onSelect={onSelectLanguage}
      label={t(`answerForm.${name}`)!}
      required={true}
      className='ap-mb-spacing-5'
      data={languages.map(language => language.name)}
      control={control}
      showErrorMessage={true}
    />
  )

  const input = (name: EAddAnswerFields, labelText?: string) => (
    <FormInput
      name={EAddAnswerFields[name]}
      type='text'
      label={labelText || t(`answerForm.calendar.${name}`)!}
      required={true}
      control={control}
      onChange={value => {
        setValue(EAddAnswerFields[name], value, RHF_SET_VALUE_OPTIONS)
      }}
    />
  )

  const infoIcon = (content: string, disabledTooltip?: boolean) => (
    <SDKTooltip content={content} disabled={!!disabledTooltip}>
      <div className='display-inline'>
        <SDKIcon code='information' />
      </div>
    </SDKTooltip>
  )

  const radio = (name: EAddAnswerFields, options: IOption[], value?: string) => (
    <div className='ap-mb-spacing-5'>
      <FormRadioButton
        name={name}
        required={true}
        options={options}
        control={control}
        defaultValue={value}
      />
    </div>
  )

  const checkboxes = (name: EAddAnswerFields, options: IAnswer[]) => (
    <div className='ap-mb-spacing-5'>
      <FormCheckboxGroup
        name={name}
        required={true}
        control={control}
        value={checkboxValue}
        onChange={onCheckboxChange}
      >
        {options.map(option => (
          <SDKCheckbox
            key={`${option.id}`}
            value={option.id}
            label={`${option.possibleAnswerText}`}
          />
        ))}
      </FormCheckboxGroup>
    </div>
  )

  const taraElement = () => {
    const optionsTARA = [
      {
        label: t(`answerForm.taraFormulaCorrect`),
        value: 'correct',
        tooltip: t(`answerForm.taraFormulaCorrectTooltip`)
      },
      {
        label: t(`answerForm.taraFormulaNeedsChanges`),
        value: 'needsChanges',
        tooltip: t(`answerForm.taraFormulaNeedsChangesTooltip`)
      }
    ]

    return (
      <>
        {question?.isGateQuestion && (
          <>
            <div className='ap-bg-color-background-default ap-border-radius-2 ap-mb-spacing-3 ap-p-spacing-5'>
              <div
                className={classNames(
                  'ap-mb-spacing-3',
                  !isEditing && 'ap-pb-spacing-3 border-bottom-1 ap-border-color-background-border'
                )}
              >
                <SDKText weight={2}>{t(`answerForm.taraFormula`)}</SDKText>
                {infoIcon(t(`answerForm.taraFormulaTooltip`))}

                <div className='ap-text-neutral-17 ap-py-spacing-3'>
                  {question.gateQuestionFormula || (
                    <SDKText className='ap-text-color-text-primary'>
                      {t(`answerForm.unknown`)}
                    </SDKText>
                  )}
                </div>
              </div>
              {!isEditing && radio(EAddAnswerFields.tara, optionsTARA)}
            </div>

            {!isEditing && (
              <div className='ap-bg-color-background-default ap-border-radius-2 ap-mb-spacing-3 ap-p-spacing-5 ap-flex'>
                {infoIcon('', true)}

                <div className='ap-text-neutral-17'>
                  <div className='ap-pb-spacing-5'>
                    <SDKText>{t(`answerForm.taraFormulaAdditionalInfo1`)}</SDKText>
                  </div>

                  <SDKText>{t(`answerForm.taraFormulaAdditionalInfo2`)}</SDKText>
                </div>
              </div>
            )}
          </>
        )}
      </>
    )
  }

  const ExtraContent = useCallback(() => {
    switch (question?.type) {
      case EAnswerTypeForEdit.Single:
        const options = question.possibleAnswers.map(answer => ({
          label: answer.possibleAnswerText,
          value: String(answer.id)
        }))
        const defaultSingleValue = (checkboxValue.length && `${checkboxValue[0]}`) || undefined
        return (
          <>
            {radio(EAddAnswerFields.singleChoice, options, defaultSingleValue)}
            {taraElement()}
            {text(EAddAnswerFields.comment)}
          </>
        )
      case EAnswerTypeForEdit.Multiple:
        return (
          <>
            {checkboxes(EAddAnswerFields.multipleChoice, question.possibleAnswers)}
            {taraElement()}
            {text(EAddAnswerFields.comment)}
          </>
        )
      case EAnswerTypeForEdit.Calendar:
        return (
          <AnswerTypeCalendar
            radio={radio}
            input={input}
            text={text}
            getValues={getValues}
            control={control}
            question={question}
            taraElement={taraElement}
            defaultValues={defaultValues}
          />
        )
      case EAnswerTypeForEdit.Language:
        return (
          <>
            {select(EAddAnswerFields.language)}
            {taraElement()}
            {text(EAddAnswerFields.comment)}
          </>
        )
      case EAnswerTypeForEdit.CurrencyRate:
        return (
          <>
            <div className='ap-pb-spacing-5'>
              {input(
                EAddAnswerFields.rate,
                `${t(`answerForm.currencyRate`)!} ${question.currencyCode}?`
              )}
              <SDKText type='body-s' className='ap-text-neutral-14'>
                {getCurrencyRateExample()}
              </SDKText>
            </div>
            {taraElement()}
            {text(EAddAnswerFields.comment)}
          </>
        )
      case EAnswerTypeForEdit.TaxRate:
        return (
          <>
            <div className='ap-mb-spacing-5'>
              {input(EAddAnswerFields.rate, t(`answerForm.taxRate`)!)}
            </div>
            {taraElement()}
            {text(EAddAnswerFields.comment)}
          </>
        )
      default:
        return (
          <>
            {text(EAddAnswerFields.answer, true)} {taraElement()}
          </>
        )
    }
  }, [question, checkboxValue])

  const infoText = (content: string) => (
    <div className='ap-mb-spacing-5'>
      <SDKText type='body-s' className='italic'>
        {content}
      </SDKText>
    </div>
  )

  return (
    <div
      className={`add-answer-form ap-flex justify-content-center ${
        cardClassName || 'ap-my-spacing-5'
      }`}
    >
      {question && (
        <FormCard className={cardClassName || ''} title=''>
          <>
            <div className='ap-mb-spacing-5'>
              <SDKBreadcrumbsWithText
                breadcrumbs={[question.category.name, question.subcategory.name]}
                text={{
                  children: question.questionText,
                  weight: 2
                }}
              />
            </div>
            {(question.type === EAnswerTypeForEdit.CurrencyRate ||
              question.type === EAnswerTypeForEdit.TaxRate) && (
              <>
                {infoText(t('answerForm.infoText1'))}
                {infoText(t('answerForm.infoText2'))}
              </>
            )}
            <ExtraContent />
            <FormInput
              name={EAddAnswerFields.url}
              type='text'
              label={t('answerForm.url')!}
              className='ap-mb-spacing-5'
              control={control}
            />
          </>
        </FormCard>
      )}
    </div>
  )
}

export default AnswerForm
