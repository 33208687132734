import type { IUser } from '../../../types/user'
import api from '../../api'
import { UserUrl } from '../../constants/urls'

export const updateUserRoleData = async (
  userId: number,
  isAdministrator: boolean
): Promise<IUser> => {
  return (await api.patch(`${UserUrl.Users}/${userId}/${UserUrl.UpdateUserRole}`, isAdministrator))
    .data
}
