import classNames from 'classnames'
import React, { PropsWithChildren, useMemo, useRef } from 'react'
import { useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { SDKButton } from '../../../../../../components/SDK/Button'
import { SDKIcon } from '../../../../../../components/SDK/Icon'
import { SDKText } from '../../../../../../components/SDK/Text'
import { usePricingTab } from '../../../../../../context/pricingTab.context'
import { EBenchmarkingType } from '../../const/enums'
import { childScopeModel } from '../../modals/ChildScopeModal/const'
import { getParentScopeModalTab, scopePriceYearsModel } from '../../modals/ParentScopeModal/const'
import type { IDragItem, ITable } from '../../types/dnd'
import { TableType } from '../../types/dnd'
import type { IBenchmarkQuotes, ITransactionalModule } from '../../types/inputsTab'
import type { IChildScope, IParentScopeYearTotal } from '../../types/scope'
import { ScopeWrapper } from '../ScopeWrapper'

interface IDraggableTable {
  table: ITable
  tableIndex: number
  moveRowHandler: (
    itemId: string,
    hoverIndex: number,
    sourceColumn: string,
    targetColumn: string
  ) => void
  className?: string
  fees?: IParentScopeYearTotal[]
  benchmarkFees?: IBenchmarkQuotes
  transactionalModules?: ITransactionalModule[]
  transactionalModulesTotals?: IParentScopeYearTotal[]
  customScopeWrapperTitle?: string
  withoutFeesResult?: boolean
}

export const DraggableTable = ({
  children,
  table,
  className,
  moveRowHandler,
  transactionalModulesTotals,
  fees,
  benchmarkFees,
  transactionalModules,
  customScopeWrapperTitle,
  withoutFeesResult
}: PropsWithChildren<IDraggableTable>) => {
  const ref = useRef<HTMLDivElement>(null)
  const { t } = useTranslation('pricing')
  const { tableName, id, role, benchmarkName, countryHeader } = table
  const {
    priceYears,
    triggerChildScopeModal,
    triggerParentScopeModal,
    tabContent: { tab }
  } = usePricingTab()

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: table.acceptType,
    hover: (item: IDragItem, monitor) => {
      if (!ref.current) return
      const dragIndex = item.index
      const hoverIndex = React.Children.count(children)
      const sourceTableId = item.tableId
      const targetTableId = id

      if (sourceTableId === targetTableId || !monitor.canDrop()) {
        return
      }
      //Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset && clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY && hoverClientY > hoverMiddleY) {
        return
      }

      moveRowHandler(item.id, hoverIndex, sourceTableId, targetTableId)
      item.index = hoverIndex
      item.tableId = targetTableId
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  })

  const getBackgroundColor = () => {
    if (isOver) {
      if (canDrop) {
        return 'ap-bg-states-success-01'
      } else if (!canDrop) {
        return 'ap-bg-states-error-01'
      }
    }
    return ''
  }

  drop(ref)

  const addButtonLabel = benchmarkName ? 'Item' : tableName === TableType.Scope ? 'Scope' : 'Item'

  const shouldUseChildScopeModal = useMemo(() => tableName !== TableType.Scope, [tableName])
  const shouldUseParentScopeModal = useMemo(() => tableName === TableType.Scope, [tableName])

  const getBenchmarkingType = (name?: string) =>
    name
      ? EBenchmarkingType[name!.substr(0, name!.indexOf(' ')) as keyof typeof EBenchmarkingType]
      : undefined

  const bgColor =
    tableName === TableType.ScopeExcludes || tableName === TableType.ScopeIncludes
      ? 'ap-bg-color-background-alt'
      : ''
  const padding =
    tableName === TableType.ScopeExcludes
      ? 'ap-pb-spacing-4'
      : tableName === TableType.ScopeIncludes
      ? ''
      : 'ap-py-spacing-5'
  const childBgColor = tableName === TableType.ScopeExcludes ? 'ap-bg-color-background-default' : ''
  const scopeExcludesStyles = `${childBgColor} ap-pb-spacing-5`

  return (
    <div ref={ref} className={classNames(className, padding)}>
      <ScopeWrapper
        title={tableName}
        fees={fees}
        benchmarkFees={benchmarkFees}
        benchmarkName={benchmarkName}
        role={role}
        classes={`${bgColor} ${className} ${getBackgroundColor()} ${
          tableName === TableType.ScopeAssumes ? 'border-1' : 'border-0'
        }`}
        transactionalModules={transactionalModules}
        transactionalModulesTotals={transactionalModulesTotals}
        countryHeader={countryHeader}
        customName={customScopeWrapperTitle}
        withoutFeesResult={withoutFeesResult}
        withoutEnableSwitch={withoutFeesResult}
      >
        <div className={scopeExcludesStyles}>
          {children}
          <SDKButton
            className={tableName === TableType.ScopeExcludes ? 'ap-ml-spacing-5' : ''}
            kind='secondary'
            onClick={() => {
              if (shouldUseChildScopeModal) {
                triggerChildScopeModal(
                  childScopeModel({
                    scopeType: Number(id),
                    order: table.rows.length + 1
                  }) as IChildScope
                )
              }

              if (shouldUseParentScopeModal) {
                const benchmarkingType = getBenchmarkingType(benchmarkName)
                const modalType = getParentScopeModalTab(tab)

                triggerParentScopeModal(
                  {
                    benchmarkingType,
                    isUserItem: true,
                    localFeeCountryId: 0,
                    name: '',
                    parentScopeId: 0,
                    priceYears: scopePriceYearsModel(priceYears)
                  },
                  modalType
                )
              }
            }}
          >
            <SDKIcon code='plus' />
            <SDKText>{t(`tabs.inputs.addNew${addButtonLabel}`)}</SDKText>
          </SDKButton>
        </div>
      </ScopeWrapper>
    </div>
  )
}
