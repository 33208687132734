import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Layout } from '../../../../components/Layout'
import { useAppDispatch } from '../../../../store'
import * as yearActions from '../../../fiscalYearSetup/store/actions'
import { getYearByName } from '../../../fiscalYearSetup/store/selectors'
import { AddEditQuestionForm } from './components/AddEditQuestionForm'

export const AddEditQuestion = () => {
  const dispatch = useAppDispatch()
  const { year: currentYear, id: questionId } = useParams()
  const year = useSelector(getYearByName(currentYear!))

  useEffect(() => {
    if (!year) {
      dispatch(yearActions.getYears())
    }
  }, [])

  return (
    <>
      {year?.yearId ? (
        <AddEditQuestionForm yearId={year?.yearId} questionId={Number(questionId)} />
      ) : (
        <Layout tab='#questions'>
          <></>
        </Layout>
      )}
    </>
  )
}
