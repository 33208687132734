import { useState } from 'react'

export const DEFAULT_PAGE_LIMIT = 50

export const usePagination = (total?: number, limit = DEFAULT_PAGE_LIMIT) => {
  const [currentPage, setCurrentPage] = useState(1)
  const pagesAmount = total ? Math.ceil(total / limit) : 0
  const offset = (currentPage - 1) * limit

  const onPageChange = (page: number) => {
    setCurrentPage(page)
  }

  return { onPageChange, currentPage, pagesAmount, offset }
}
