import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useApp } from '../../../../context/app.context'
import * as actions from '../../../../modules/systemManagement/store/actions'
import { getUnapprovedLegalDocuments } from '../../../../modules/systemManagement/store/selectors'
import { ILegalDocumentWithApproval } from '../../../../modules/systemManagement/types'
import {
  ELegalStatementsApprovalFields,
  useLegalStatementsApprovalSchema
} from '../../../../schemas/useLegalStatementsApprovalSchema'
import { useAppDispatch } from '../../../../store'
import { CardListWrapper } from '../../../CardListWrapper'
import { SDKAccordion } from '../../../SDK/Accordion'
import { SDKAccordionItem } from '../../../SDK/AccordionItem'
import { SDKCheckbox } from '../../../SDK/Checkbox'
import { SDKModal } from '../../../SDK/Modal'
import { SDKTab } from '../../../SDK/Tab'
import { SDKTabs } from '../../../SDK/Tabs'
import './index.scss'
import { useApproveDocument } from './utils/useApproveDocument'

interface ILegalStatementsApprovalModalProps {
  setVisible: (data: boolean) => void
  visible: boolean
}

export const LegalStatementsApprovalModal = ({
  visible,
  setVisible
}: ILegalStatementsApprovalModalProps) => {
  const {
    appContext: {
      style: { isMobile }
    }
  } = useApp()

  const { t } = useTranslation('common')
  const dispatch = useAppDispatch()
  const [document, setDocument] = useState<ILegalDocumentWithApproval | null>(null)
  const [activeIndex, setActiveIndex] = useState<string>('0')
  const [accepted, setAccepted] = useState<boolean>(false)
  const documents = useSelector(getUnapprovedLegalDocuments)

  useEffect(() => {
    dispatch(actions.getUnapprovedLegalDocuments())
  }, [])

  useEffect(() => {
    if (documents.data) {
      setDocument(documents.data[0])
    }
  }, [documents])

  const { validationSchema, defaultValues } = useLegalStatementsApprovalSchema(
    documents.data.length || 1
  )

  const {
    handleSubmit,
    reset,
    formState: { isValid },
    getValues,
    setValue,
    trigger
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'all',
    reValidateMode: 'onChange'
  })

  const { onSubmit } = useApproveDocument(reset, setVisible)

  const onSelectDocument = (
    currentDocument: ILegalDocumentWithApproval | null,
    indexSet?: boolean
  ) => {
    const currentIds = getValues(ELegalStatementsApprovalFields.approvedDocumentIds)
    if (currentDocument && currentIds.some(id => id === currentDocument.documentId)) {
      setAccepted(true)
    } else {
      setAccepted(false)
    }
    setDocument(currentDocument)
    if (!indexSet && currentDocument) {
      const index = documents.data.findIndex(d => d.documentId === currentDocument.documentId)
      setActiveIndex(String(index))
    }
  }

  const openNextDocument = () => {
    const documentsWithoutApproval = documents.data.filter(
      d =>
        !getValues(ELegalStatementsApprovalFields.approvedDocumentIds).some(
          id => id === d.documentId
        )
    )
    if (documentsWithoutApproval.length) {
      const unapprovedDocumentIndex = documents.data.findIndex(
        doc => doc.documentId === documentsWithoutApproval[0].documentId
      )
      setActiveIndex(String(unapprovedDocumentIndex))
      setDocument(documents.data[unapprovedDocumentIndex])
      setAccepted(false)
    }
  }

  const onCheckboxChange = (isAccepted: boolean) => {
    if (!document) return
    const currentIds = getValues(ELegalStatementsApprovalFields.approvedDocumentIds)
    setAccepted(isAccepted)
    if (isAccepted) {
      setValue(ELegalStatementsApprovalFields.approvedDocumentIds, [
        ...currentIds,
        document.documentId
      ])
      trigger()
      setTimeout(() => openNextDocument(), 200)
    } else {
      setValue(
        ELegalStatementsApprovalFields.approvedDocumentIds,
        currentIds.filter(id => id !== document.documentId)
      )
      trigger()
    }
  }

  const onClickAccordion = (key: string) => {
    onSelectDocument(documents.data[Number(key)] || null, true)
    setActiveIndex(key)
  }

  const documentContent = document && (
    <div className='ap-py-spacing-3'>
      <div
        className='ap-p-spacing-3 ap-mb-spacing-3 border-1 ap-border-radius-2 ap-border-color-background-border document-content'
        dangerouslySetInnerHTML={{ __html: document.content }}
      ></div>
      <SDKCheckbox
        label={t('legalStatementsApproval.agree')!}
        checked={accepted}
        onChange={onCheckboxChange}
      />
    </div>
  )

  const modalContent = (
    <>
      {isMobile ? (
        <div className='mobile-view '>
          <SDKAccordion activeKeys={[activeIndex]} onClick={id => onClickAccordion(id[0])}>
            {documents.data.map((d, i) => (
              <SDKAccordionItem
                itemKey={`${i}`}
                key={`accordion-${d.documentId}`}
                title={d.name}
                className='border-1 ap-border-color-background-border p-0'
              >
                {documentContent}
              </SDKAccordionItem>
            ))}
          </SDKAccordion>
        </div>
      ) : (
        <div className='desktop-view'>
          <SDKTabs
            activeIndex={Number(activeIndex)}
            onTabChange={(index: number) => onSelectDocument(documents.data[index])}
          >
            {documents.data.map(d => (
              <SDKTab key={`tab-${d.documentId}`} label={d.name} />
            ))}
          </SDKTabs>
          {documentContent}
        </div>
      )}
    </>
  )

  return (
    <SDKModal
      onCancel={() => {}}
      onSubmit={handleSubmit(onSubmit)}
      submitLabel={t(`legalStatementsApproval.continue`)!}
      title={t('legalStatementsApproval.title')!}
      visible={visible}
      disabledSubmit={!isValid}
      withRequired={false}
      className='legal-statements-approval-modal'
    >
      <CardListWrapper classNames='legal-statements-approval' resource={documents}>
        {modalContent}
      </CardListWrapper>
    </SDKModal>
  )
}
