export enum LoadingStatus {
  Idle = 'idle',
  Pending = 'pending',
  Succeeded = 'succeeded',
  Failed = 'failed'
}

export enum RegionContainer {
  BenchmarkAndIndustryAnalysis = 'BenchmarkAndIndustryAnalysis',
  GcdPricing = 'GcdPricing',
  Tara = 'Tara'
}

export enum ResponseCode {
  Unauthorized = '418'
}
