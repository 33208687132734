import { SDKIcon } from '../SDK/Icon'
import { SDKText } from '../SDK/Text'

export const Filename = ({ value }: { value: any }) =>
  value ? (
    <SDKText weight={2}>
      <>
        <SDKIcon code={'document-text'} />
        {String(value)}
      </>
    </SDKText>
  ) : (
    <></>
  )
