import { createReducer } from '@reduxjs/toolkit'
import {
  getDefaultResourceState,
  Resource,
  setResourceFullfilled,
  setResourcePending,
  setResourceRejected
} from '../../../shared/utils/store'
import { IRegion } from '../types'
import {
  addRegion,
  clearAddRegion,
  clearDeleteRegion,
  clearEditRegion,
  clearRegions,
  deleteRegion,
  editRegion,
  getRegions
} from './actions'

export interface RegionsState {
  regions: Resource<IRegion[]>
  addRegion: Resource<IRegion | null>
  editRegion: Resource<IRegion | null>
  deleteRegion: Resource<IRegion | null>
}

const initialState: RegionsState = {
  regions: getDefaultResourceState([]),
  addRegion: getDefaultResourceState(null),
  editRegion: getDefaultResourceState(null),
  deleteRegion: getDefaultResourceState(null)
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getRegions.pending, state => {
      setResourcePending(state.regions)
    })
    .addCase(getRegions.rejected, (state, actions) => {
      setResourceRejected(state.regions, actions)
    })
    .addCase(getRegions.fulfilled, (state, actions) => {
      state.regions.data = actions.payload
      setResourceFullfilled(state.regions)
    })
    .addCase(clearRegions, state => {
      state.regions = initialState.regions
    })
    .addCase(addRegion.pending, state => {
      setResourcePending(state.addRegion)
    })
    .addCase(addRegion.rejected, (state, actions) => {
      setResourceRejected(state.addRegion, actions)
    })
    .addCase(addRegion.fulfilled, (state, actions) => {
      state.addRegion.data = actions.payload
      setResourceFullfilled(state.addRegion)
    })
    .addCase(clearAddRegion, state => {
      state.addRegion = initialState.addRegion
    })
    .addCase(editRegion.pending, state => {
      setResourcePending(state.editRegion)
    })
    .addCase(editRegion.rejected, (state, actions) => {
      setResourceRejected(state.editRegion, actions)
    })
    .addCase(editRegion.fulfilled, (state, actions) => {
      state.editRegion.data = actions.payload
      setResourceFullfilled(state.editRegion)
    })
    .addCase(clearEditRegion, state => {
      state.editRegion = initialState.editRegion
    })

    .addCase(deleteRegion.pending, state => {
      setResourcePending(state.deleteRegion)
    })
    .addCase(deleteRegion.rejected, (state, actions) => {
      setResourceRejected(state.deleteRegion, actions)
    })
    .addCase(deleteRegion.fulfilled, (state, actions) => {
      state.deleteRegion.data = actions.payload
      setResourceFullfilled(state.deleteRegion)
    })
    .addCase(clearDeleteRegion, state => {
      state.deleteRegion = initialState.deleteRegion
    })
)
