import { ILegalDocumentWithApproval } from '../../../modules/systemManagement/types'
import api from '../../api'
import { SystemManagementUrl } from '../../constants/urls'

export const getLegalDocumentsPublicData = async (
  identifier = ['cookies_policy']
): Promise<ILegalDocumentWithApproval[]> => {
  return (
    await api.get(
      `${SystemManagementUrl.LegalDocuments}/${SystemManagementUrl.Public}/?identifier=${identifier}`
    )
  ).data
}
