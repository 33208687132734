import { EPricingTab } from '../../../modules/project/components/GCDPricing/const/enums'
import type { TUpdateModuleMetadata } from '../../../modules/project/components/GCDPricing/types/inputsTab'
import type { IScopeQuestions } from '../../../modules/project/components/GCDPricing/types/scope'
import api from '../../api'
import { ProjectsUrl } from '../../constants/urls'

export interface IMetadataParams extends Partial<IScopeQuestions> {
  isEnabled?: boolean
  isOptional?: boolean
  isApplicable?: boolean
  isPermitted?: boolean
  discount?: number
  name?: string
}

export const updateModuleMetadataRequest = async (
  projectId: number,
  tabName: EPricingTab,
  params: IMetadataParams,
  customModuleId?: number
): Promise<TUpdateModuleMetadata> => {
  const url = customModuleId
    ? `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/${ProjectsUrl.Modules}/${ProjectsUrl.CustomModules}/${customModuleId}`
    : `${ProjectsUrl.Projects}/${projectId}/${ProjectsUrl.PricingTool}/${ProjectsUrl.Modules}/${tabName}`
  return (await api.put(url, params)).data
}
