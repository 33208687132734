import type { SwitchProps } from '@appkit4/react-components/switch'
import { Switch } from '@appkit4/react-components/switch'
import type { Path } from 'react-hook-form'

interface ISwitchProps<T> extends SwitchProps {
  id: Path<T> | string
}

export const SDKSwitch = <T,>({ children, ...props }: Partial<ISwitchProps<T>>) => {
  return <Switch {...props}>{children}</Switch>
}
