import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'
import { EDITING_EMAIL_TEMPLATE } from '../modules/systemManagement/constants'
import { IEmailTemplate } from '../modules/systemManagement/types'

export enum EEditEmailTemplateFields {
  templateName = 'templateName',
  subject = 'subject',
  message = 'message'
}
export interface IFormValues {
  [EEditEmailTemplateFields.templateName]: string
  [EEditEmailTemplateFields.subject]: string
  [EEditEmailTemplateFields.message]: string
}

export const useEditEmailTemplateSchema = () => {
  const { t } = useTranslation('systemManagement')
  const data = localStorage.getItem(EDITING_EMAIL_TEMPLATE)
  const emailTemplate: IEmailTemplate = data && JSON.parse(data)

  const defaultValues = {
    [EEditEmailTemplateFields.templateName]: emailTemplate?.templateName || '',
    [EEditEmailTemplateFields.subject]: emailTemplate?.subject || '',
    [EEditEmailTemplateFields.message]: emailTemplate?.message || ''
  }

  const validationSchema = object()
    .shape({
      [EEditEmailTemplateFields.templateName]: string().required(
        t('emailTemplates.form.errors.invalid')!
      ),
      [EEditEmailTemplateFields.subject]: string().required(
        t('emailTemplates.form.errors.invalid')!
      ),
      [EEditEmailTemplateFields.message]: string().required(
        t('emailTemplates.form.errors.invalid')!
      )
    })
    .required()

  return { validationSchema, defaultValues }
}
