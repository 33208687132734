import { Layout } from '../../components/Layout'
import { ModeToggle } from './ModeToggle'
import { SelectTheme } from './SelectTheme'

const Preferences = () => {
  return (
    <Layout>
      <div className='preferences-page'>
        <div className='ap-flex align-items-center'>
          <SelectTheme />
          <ModeToggle />
        </div>
      </div>
    </Layout>
  )
}

export default Preferences
