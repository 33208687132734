import { useTranslation } from 'react-i18next'
import { ResultsData } from '../ResultsData'

interface INoResultsProps {
  searchQuery: string
  resultsName: string
}

const NoResults = ({ searchQuery, resultsName }: INoResultsProps) => {
  const { t } = useTranslation('search')

  return (
    <ResultsData
      data={[
        {
          label: t('results.noResultsFor', { resultsName: resultsName, queryString: searchQuery }),
          paragraph: t('results.tryDifferentQuery')!,
          value: null
        }
      ]}
    />
  )
}

export default NoResults
