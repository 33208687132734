import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FieldBox } from '../../../../../../components/FieldBox'
import { SDKAccordion } from '../../../../../../components/SDK/Accordion'
import { SDKAccordionItem } from '../../../../../../components/SDK/AccordionItem'
import { LoadingSpinner } from '../../../../../../components/SDK/LoadingSpinner'
import { SDKParagraph } from '../../../../../../components/SDK/Paragraph'
import { useAppDispatch } from '../../../../../../store'
import * as actions from '../../../../../fiscalYearSetup/store/actions'
import * as selectors from '../../../../../fiscalYearSetup/store/selectors'
import type {
  IGateQuestionPending,
  IGateQuestionProhibitedAnswer,
  IGateQuestionQuestion
} from '../../types'
import { GateQuestionsButtons } from '../GateQuestionsButtons'
import {
  EGateQuestionDraftAnswerType,
  GQQuestionItemAnswer
} from './components/GQQuestionItemAnswer'
import styles from './index.module.scss'

const ITEM_KEY = 'question-'

export const GQQuestinItem = ({
  countryId,
  questions,
  onEditAnswer,
  yearId,
  editMode
}: {
  countryId: number
  questions: IGateQuestionQuestion[]
  onEditAnswer: ({
    answer,
    question
  }: {
    answer: IGateQuestionProhibitedAnswer
    question: IGateQuestionQuestion
  }) => void
  yearId: number
  editMode: boolean
}) => {
  const { t } = useTranslation('fiscalYearDetails')
  const [openedKeys, setOpenedKeys] = useState<string[]>([])
  const dispatch = useAppDispatch()

  const { data: answers } = useSelector(selectors.getGateQuestionsAnswers)

  const loadAnswers = (ids: number[]) => {
    ids.forEach(gateQuestionId =>
      dispatch(actions.getGateQuestionsPending({ countryId, gateQuestionId, yearId }))
    )
  }

  const onSelect = (activeKeys: string[]) => {
    const idsToLoad = activeKeys
      .filter(activeKey => !openedKeys.includes(activeKey))
      .map(id => Number(id.substring(ITEM_KEY.length)))
    loadAnswers(idsToLoad)

    setOpenedKeys(curr => [...new Set([activeKeys, curr].flat())])
  }

  return (
    <SDKAccordion multiple={true} onClick={onSelect}>
      {questions.map(question => {
        const questionKey = `${ITEM_KEY}${question.questionId}`
        const answer: IGateQuestionPending | undefined = answers.find(
          answerItem => answerItem.questionId === question.questionId
        )

        return (
          <SDKAccordionItem
            key={questionKey}
            itemKey={questionKey}
            title={`${question.category.name} ${String.fromCharCode(62)} ${
              question.subcategory.name
            }`}
            className='question-group'
          >
            <SDKParagraph weight={2}>{question.questionText}</SDKParagraph>

            {answer ? (
              <div>
                <div className={styles['answer-group']}>
                  <GQQuestionItemAnswer
                    answer={answer.oldAnswer}
                    answerType={EGateQuestionDraftAnswerType.OldAnswer}
                    calendarQuestionOptions={answer.calendarQuestionOptions}
                    calendarAnswerResponse={answer.newAnswer.calendarAnswerResponse}
                    id={answer.oldAnswer.answerId}
                    type={answer.type}
                  />

                  <GQQuestionItemAnswer
                    answer={answer.newAnswer}
                    answerType={EGateQuestionDraftAnswerType.NewAnswer}
                    calendarQuestionOptions={answer.calendarQuestionOptions}
                    calendarAnswerResponse={answer.newAnswer.calendarAnswerResponse}
                    id={answer.newAnswer.answerId}
                    onEditAnswer={selectedAnswer =>
                      onEditAnswer({ question, answer: selectedAnswer })
                    }
                    submitter={answer.newAnswerSubmittedBy}
                    type={answer.type}
                  />

                  <GateQuestionsButtons
                    countryId={countryId}
                    draftAnswerId={answer.newAnswer.answerId}
                    gateQuestionId={question.questionId}
                    yearId={yearId}
                    editMode={editMode}
                  />
                </div>

                {answer.relatedTaraFieldName && (
                  <FieldBox
                    label={t('gateQuestions.list.labels.relatedTaraFieldName')}
                    value={answer.relatedTaraFieldName}
                  />
                )}

                {answer.gateQuestionFormula && (
                  <FieldBox
                    label={t('gateQuestions.list.labels.gateQuestionFormula')}
                    value={answer.gateQuestionFormula}
                  />
                )}
              </div>
            ) : (
              <LoadingSpinner />
            )}
          </SDKAccordionItem>
        )
      })}
    </SDKAccordion>
  )
}
