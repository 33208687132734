import { ICountryListItem } from '../../../../modules/countries/types'
import { SDKPanel } from '../../../SDK/Panel'
import { SDKSwitch } from '../../../SDK/Switch'
import { SDKText } from '../../../SDK/Text'
import { EAction } from '../../const'
import { IActionCallback } from '../../type'

interface ICustomCountryItem {
  item: ICountryListItem
  actionCallback: (_callback: IActionCallback) => void
  selectedCountryIds: number[]
}

export const CustomCountryItem = ({
  item,
  selectedCountryIds,
  actionCallback
}: ICustomCountryItem) => {
  const MIN_NUMBER_OF_SELECTED_COUNTRIES = 1

  return (
    <SDKPanel className='border-1 ap-border-color-background-border ap-my-spacing-3 p-0 ap-container'>
      <div className='w-100 ap-flex justify-content-between'>
        <SDKText weight={2}>{item.name}</SDKText>
        <SDKSwitch
          onChange={() => actionCallback({ action: EAction.Change, id: item.countryId })}
          defaultChecked={selectedCountryIds.some(id => id === item.countryId)}
          disabled={
            selectedCountryIds.length === MIN_NUMBER_OF_SELECTED_COUNTRIES &&
            selectedCountryIds.some(id => id === item.countryId)
          }
        />
      </div>
    </SDKPanel>
  )
}
