import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { SDKIcon } from '../../../../../../components/SDK/Icon'
import { LoadingSpinner } from '../../../../../../components/SDK/LoadingSpinner'
import { SDKText } from '../../../../../../components/SDK/Text'
import { LoadingStatus } from '../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../store'
import { clearUpdateFormulaValidation } from '../../../../store/actions'
import * as selectors from '../../../../store/selectors'

export const ValidationResult = ({ error }: { error: string | null }) => {
  const { t } = useTranslation('taraFields')
  const { data: validationResult, loading } = useSelector(selectors.addFormulaValidation)
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(clearUpdateFormulaValidation())
    }
  }, [])

  const errorHeader = (
    <>
      <div className='ap-text-states-error-05 ap-my-spacing-4'>
        <SDKIcon code='warning' outline={false} />
        <SDKText weight={2}>{t('addEditForm.errorFound')}</SDKText>
      </div>
      <SDKText weight={2}>{t('addEditForm.error')}</SDKText>
    </>
  )

  return (
    <>
      {loading === LoadingStatus.Pending ? (
        <LoadingSpinner />
      ) : (
        validationResult !== null &&
        (validationResult.isValid && !error ? (
          <>
            <div className='ap-text-color-text-success ap-my-spacing-4'>
              <SDKIcon code='success' outline={false} />
              <SDKText weight={2}>{t('addEditForm.testLogic')}</SDKText>
            </div>
            <SDKText weight={2}>{t('addEditForm.result')}</SDKText>
            <div className='ap-text-neutral-14'>
              <SDKText type='body-s'>{validationResult?.result}</SDKText>
            </div>
          </>
        ) : (
          <>
            {errorHeader}
            {validationResult?.errors?.map((e, i) => (
              <div className='ap-text-neutral-14' key={`${i}-${e}`}>
                <SDKText type='body-s'>{e}</SDKText>
              </div>
            ))}
          </>
        ))
      )}
      {loading !== LoadingStatus.Pending && error && (
        <>
          {errorHeader}
          <div className='ap-text-neutral-14'>
            <SDKText type='body-s'>{error}</SDKText>
          </div>
        </>
      )}
    </>
  )
}
