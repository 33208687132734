import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'
import type { ICountry, ICountryLeader } from '../modules/countries/types'
import { RegionContainer } from '../shared/types/enums'
import type { IUserBase } from '../types/user'

export enum EAddEditCountryFields {
  code2 = 'code2',
  code3 = 'code3',
  currency = 'currency',
  name = 'name',
  regionTara = RegionContainer.Tara,
  regionGCD = RegionContainer.GcdPricing,
  regionBenchmark = RegionContainer.BenchmarkAndIndustryAnalysis,
  countryLeaders = 'countryLeaders',
  countryEditors = 'countryEditors'
}
export interface IFormValues {
  [EAddEditCountryFields.code2]: string
  [EAddEditCountryFields.code3]: string
  [EAddEditCountryFields.currency]: string
  [EAddEditCountryFields.name]: string
  [EAddEditCountryFields.regionTara]: string
  [EAddEditCountryFields.regionGCD]: string
  [EAddEditCountryFields.regionBenchmark]: string
  [EAddEditCountryFields.countryLeaders]: ICountryLeader[]
  [EAddEditCountryFields.countryEditors]: IUserBase[]
}

export const useAddEditCountrySchema = (country: ICountry | null) => {
  const { t } = useTranslation('countries')

  const getDefaultValues = (obj: ICountry | null) => ({
    [EAddEditCountryFields.code2]: obj?.code2 || '',
    [EAddEditCountryFields.code3]: obj?.code3 || '',
    [EAddEditCountryFields.currency]: obj?.currency || '',
    [EAddEditCountryFields.name]: obj?.name || '',
    [EAddEditCountryFields.regionTara]: obj?.regionIdsByType?.Tara
      ? obj?.regionIdsByType?.Tara.toString()
      : '',
    [EAddEditCountryFields.regionGCD]: obj?.regionIdsByType?.GcdPricing
      ? obj?.regionIdsByType?.GcdPricing.toString()
      : '',
    [EAddEditCountryFields.regionBenchmark]: obj?.regionIdsByType?.BenchmarkAndIndustryAnalysis
      ? obj?.regionIdsByType?.BenchmarkAndIndustryAnalysis.toString()
      : '',
    [EAddEditCountryFields.countryLeaders]: obj?.countryLeaders || [],
    [EAddEditCountryFields.countryEditors]: obj?.countryEditors || []
  })

  const defaultValues = useMemo(() => getDefaultValues(country), [country])

  const validationSchema = object()
    .shape({
      [EAddEditCountryFields.code2]: string()
        .required(t('addEditCountry.form.errors.invalid')!)
        .min(2, t('addEditCountry.form.errors.invalid')!)
        .max(2, t('addEditCountry.form.errors.invalid')!),
      [EAddEditCountryFields.code3]: string()
        .required(t('addEditCountry.form.errors.invalid')!)
        .min(3, t('addEditCountry.form.errors.invalid')!)
        .max(3, t('addEditCountry.form.errors.invalid')!),
      [EAddEditCountryFields.currency]: string()
        .required(t('addEditCountry.form.errors.invalid')!)
        .min(3, t('addEditCountry.form.errors.invalid')!)
        .max(3, t('addEditCountry.form.errors.invalid')!),
      [EAddEditCountryFields.name]: string().required(t('addEditCountry.form.errors.invalid')!),
      [EAddEditCountryFields.regionTara]: string().required(
        t('addEditCountry.form.errors.invalid')!
      ),
      [EAddEditCountryFields.regionGCD]: string().required(
        t('addEditCountry.form.errors.invalid')!
      ),
      [EAddEditCountryFields.regionBenchmark]: string().required(
        t('addEditCountry.form.errors.invalid')!
      )
    })
    .required()

  return { validationSchema, defaultValues, getDefaultValues }
}
