import { useNavigate } from 'react-router-dom'
import { Url } from '../../../../constants/urls'
import { useWizardStepsKeys } from '../AddEditProject/components/AddEditProjectModal/hooks/useWizardSteps'
import ProjectHeader from '../ProjectHeader'

export const MyProjectsHeaderButton = ({ showFilter }: { showFilter?: () => void }) => {
  const navigate = useNavigate()
  const wizardSteps = useWizardStepsKeys()

  return (
    <ProjectHeader
      onButtonClick={() => {
        navigate(`${Url.MyProjectsPage}${Url.AddProjectPage}/${wizardSteps[0]}`)
      }}
      showFilter={showFilter}
    />
  )
}
