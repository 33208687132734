import type { Control, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormCard } from '../../../../../../components/Form/FormCard'
import { FormInput } from '../../../../../../components/Form/FormInput'
import { FormRadioButton } from '../../../../../../components/Form/FormRadioButton'
import { FormRichTextEditor } from '../../../../../../components/Form/FormRichTextEditor'
import { SDKParagraph } from '../../../../../../components/SDK/Paragraph'
import { SDKText } from '../../../../../../components/SDK/Text'
import { SDKTooltip } from '../../../../../../components/SDK/Tooltip'
import {
  EAddEditDocumentFields,
  IFormValues
} from '../../../../../../schemas/useAddEditDocumentSchema'
import { IOption } from '../../../../../../types/form'
import { useAddEditDocumentForm } from './utils/useAddEditDocumentForm'

import './index.scss'

interface IAddEditDocumentForm {
  control: Control<FieldValues, IFormValues>
  isDirty: boolean
  radioList: IOption[]
  error?: string | null
}

export const AddEditDocumentForm = ({
  control,
  isDirty,
  radioList,
  error
}: IAddEditDocumentForm) => {
  const { t } = useTranslation('systemManagement')
  const { idValue } = useAddEditDocumentForm(control, error)

  return (
    <>
      <div className='legal-document-form'>
        <div className='ap-mt-spacing-4'>
          <FormCard withRequired title={t('legalDocuments.form.documentInfo')}>
            <div className='row'>
              <div className='col-md-8 ap-mb-spacing-5'>
                <SDKTooltip
                  disabled={isDirty}
                  content={t('legalDocuments.form.fields.documentNameTooltip')!}
                >
                  <FormInput
                    name={EAddEditDocumentFields.documentName}
                    type='text'
                    label={t('legalDocuments.form.fields.documentName')!}
                    required={true}
                    control={control}
                    suffixIcon
                  />
                </SDKTooltip>
              </div>
              <div className='col-md-4 ap-mb-spacing-5'>
                <SDKText type='body-s' className='ap-text-neutral-14'>
                  {t('legalDocuments.documentID')}
                </SDKText>
                <SDKParagraph>{idValue}</SDKParagraph>
              </div>
              <div className='col-12'>
                <SDKText type='body-s' className='ap-text-neutral-14'>
                  {t('legalDocuments.form.fields.approval')}
                </SDKText>
                <FormRadioButton
                  name={EAddEditDocumentFields.requiresApproval}
                  label={t('legalDocuments.form.fields.emailSubject')!}
                  value={radioList[0].value}
                  disabled
                  required={true}
                  control={control}
                  options={radioList}
                  withFlex
                />
              </div>
            </div>
          </FormCard>
        </div>
        <div className='ap-mt-spacing-4 ap-mb-spacing-5'>
          <FormCard title={t('legalDocuments.form.documentContent')}>
            <div className='ap-my-spacing-4'>
              <FormRichTextEditor
                control={control}
                name={EAddEditDocumentFields.documentContent}
                label={EAddEditDocumentFields.documentContent}
              />
            </div>
          </FormCard>
        </div>
      </div>
    </>
  )
}
