import { IEmailTemplate } from '../../../modules/systemManagement/types'
import api from '../../api'
import { SystemManagementUrl } from '../../constants/urls'

export interface IUpdateEmailTemplatePayload {
  templateName: string
  subject: string
  message: string
}

export const updateEmailTemplateData = async (
  templateId: string,
  params: IUpdateEmailTemplatePayload
): Promise<IEmailTemplate> => {
  return (await api.put(`${SystemManagementUrl.EmailTemplates}/${templateId}`, params)).data
}
