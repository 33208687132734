import api from '../../api'
import { SystemManagementUrl } from '../../constants/urls'

export const createLegalDocumentTestRequest = async (
  templateId: string,
  email: string
): Promise<string> => {
  const url = `${SystemManagementUrl.EmailTemplates}/${templateId}/${SystemManagementUrl.Test}`
  return (await api.post(url, email, null, true)).data
}
