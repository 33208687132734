import React, { createContext, useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { useProjectFromUrl } from '../hooks/useProjectFromUrl'
import * as actions from '../modules/myprojects/store/actions'
import * as selectors from '../modules/myprojects/store/selectors'
import type { IProject, IProjectCard } from '../modules/myprojects/types'
import { useAppDispatch } from '../store'

interface ProjectContextState {
  myProjects: IProject[]
  project: IProjectCard | null
  projects: IProject[]
  refreshProject: (_clearData?: boolean) => void
  refreshProjects: () => void
  isCalculationRunning: boolean
  setIsCalculationRunning: (value: boolean) => void
}

const defaultContext: ProjectContextState = {
  myProjects: [],
  project: null,
  projects: [],
  refreshProject: () => {},
  refreshProjects: () => {},
  isCalculationRunning: false,
  setIsCalculationRunning: () => {}
}

const useProjectContext = (): ProjectContextState => {
  const [isCalculationRunning, setIsCalculationRunning] = useState(
    defaultContext.isCalculationRunning
  )
  const dispatch = useAppDispatch()
  const { project, refreshProject: refreshProjectData } = useProjectFromUrl()
  const { data: projects } = useSelector(selectors.getProjects)
  const { data: myProjects } = useSelector(selectors.getMyProjects)

  const refreshProjects = () => {
    dispatch(actions.getProjects())
    dispatch(actions.getMyProjects())
  }

  const refreshProject = (clearData = true) => {
    refreshProjects()
    refreshProjectData(clearData)
  }

  return {
    myProjects: myProjects ?? [],
    project,
    projects: projects ?? [],
    refreshProject,
    refreshProjects,
    isCalculationRunning,
    setIsCalculationRunning
  }
}

const ProjectContext = createContext<ProjectContextState>(defaultContext)

export const useProject = (): ProjectContextState => {
  return useContext(ProjectContext)
}

export const ProjectContextConsumer = ProjectContext.Consumer

export const ProjectContextProvider = ({ children }: { children: React.ReactElement }) => {
  const {
    myProjects,
    project,
    projects,
    refreshProject,
    refreshProjects,
    isCalculationRunning,
    setIsCalculationRunning
  } = useProjectContext()

  return (
    <ProjectContext.Provider
      value={{
        myProjects,
        project,
        projects,
        refreshProject,
        refreshProjects,
        isCalculationRunning,
        setIsCalculationRunning
      }}
    >
      {children}
    </ProjectContext.Provider>
  )
}
