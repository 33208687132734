export enum UserRole {
  Admin = 'Admin',
  CountryEditor = 'CountryEditor',
  InternalUser = 'InternalUser',
  ExternalUser = 'ExternalUser'
}

export enum CookieSettings {
  AcceptAll = 1,
  DeclineOptional = 2
}

export interface IUser extends IUserBase {
  acceptedAllLegalDocuments: boolean
  cookieSettings?: CookieSettings
  isActive: boolean
  isAdministrator: boolean
  isServiceAccount: boolean
  roles: UserRole[]
}

export interface IUserBase {
  email: string
  firstName: string
  guid: string
  hasAvatar: boolean
  lastName: string
  userId: number
}
