import classNames from 'classnames'
import { SDKText } from '../../../../../../../../../components/SDK/Text'
import styles from './index.module.scss'

interface IResultsList {
  data: string[]
  withBorder?: boolean
}

export const ResultsList = ({ data, withBorder = true }: IResultsList) => {
  return (
    <ul className={`ap-text-neutral-17 ${withBorder && 'border-right'}  ap-pr-spacing-6`}>
      {data &&
        data.map((text, index) => (
          <li
            className={classNames(
              styles['results-list-item'],
              withBorder
                ? 'border-bottom-1 ap-border-color-background-border'
                : styles['optional-scope']
            )}
            key={`row-text-${index}`}
          >
            <SDKText className='ap-text-color-neutral-17'>{text}</SDKText>
          </li>
        ))}
    </ul>
  )
}
