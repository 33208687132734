import { useEffect } from 'react'
import { usePreviousValue } from './usePreviousValue'

export const useInterval = <T>(callback: (data: T) => void, delay: number | null, data: T) => {
  const savedCallback = usePreviousValue(callback)

  useEffect(() => {
    if (!delay && delay !== 0) {
      return
    }
    const id = setInterval(() => (savedCallback ? savedCallback(data) : null), delay)

    return () => clearInterval(id)
  }, [delay, data])
}
