import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Module } from '../../../constants/modules'
import { addProjectRequest } from '../../../services/requests/projects/addProject'
import { copyProjectRequest } from '../../../services/requests/projects/copyProject'
import { deleteProjectRequest } from '../../../services/requests/projects/deleteProject'
import { getCurrenciesRequest } from '../../../services/requests/projects/getCurrencies'
import { getProjectRequest } from '../../../services/requests/projects/getProject'
import { getProjectAssignedUsersData } from '../../../services/requests/projects/getProjectAssignedUsersData'
import { getProjectAvailableUsersData } from '../../../services/requests/projects/getProjectAvailableUsersData'
import { getProjectChangesData } from '../../../services/requests/projects/getProjectChangesData'
import { getProjectsRequest } from '../../../services/requests/projects/getProjects'
import { syncWithSightlineRequest } from '../../../services/requests/projects/syncWithSightlineRequest'
import { updateProjectRequest } from '../../../services/requests/projects/updateProject'
import { updateProjectChangesRequest } from '../../../services/requests/projects/updateProjectChangesRequest'
import type { IAddProjectForm, ICopyProjectFields, IEditProjectForm } from '../types'

const moduleName: string = String(Module.MyProjects)

export const getProjects = createAsyncThunk(`${moduleName}/getProjects`, () => {
  return getProjectsRequest()
})
export const clearGetProjects = createAction(`${moduleName}/clearGetProjects`)
export const getMyProjects = createAsyncThunk(`${moduleName}/getMyProjects`, () => {
  return getProjectsRequest(true)
})
export const clearGetMyProjects = createAction(`${moduleName}/clearGetMyProjects`)
export const getProject = createAsyncThunk(`${moduleName}/getProject`, (projectId: number) =>
  getProjectRequest(projectId)
)
export const clearGetProject = createAction(`${moduleName}/clearGetProject`)
export const addProject = createAsyncThunk(`${moduleName}/addProject`, (params: IAddProjectForm) =>
  addProjectRequest(params)
)
export const clearAddProject = createAction(`${moduleName}/clearAddProject`)
export const updateProject = createAsyncThunk(
  `${moduleName}/updateProject`,
  ({ params, projectId }: { params: IEditProjectForm; projectId: number }) =>
    updateProjectRequest(params, projectId)
)
export const clearUpdateProject = createAction(`${moduleName}/clearUpdateProject`)

export const copyProject = createAsyncThunk(
  `${moduleName}/copyProject`,
  (params: ICopyProjectFields) => copyProjectRequest(params)
)
export const clearCopyProject = createAction(`${moduleName}/clearCopyProject`)

export const deleteProject = createAsyncThunk(
  `${moduleName}/deleteProject`,
  ({ projectId }: { projectId: number }) => deleteProjectRequest(projectId)
)
export const clearDeleteProject = createAction(`${moduleName}/clearDeleteProject`)
export const getCurrencies = createAsyncThunk(`${moduleName}/getCurrencies`, () =>
  getCurrenciesRequest()
)
export const clearGetCurrencies = createAction(`${moduleName}/clearGetCurrencies`)
export const getProjectAssignedUsers = createAsyncThunk(
  `${moduleName}/getProjectAssignedUsers`,
  (projectId: number) => getProjectAssignedUsersData(projectId)
)
export const clearGetProjectAssignedUsers = createAction(
  `${moduleName}/clearGetProjectAssignedUsers`
)
export const getProjectAvailableUsers = createAsyncThunk(
  `${moduleName}/getProjectAvailableUsers`,
  ({ projectId, searchText }: { projectId: number | null; searchText: string }) =>
    getProjectAvailableUsersData(projectId, searchText)
)
export const clearGetProjectAvailableUsers = createAction(
  `${moduleName}/clearGetProjectAvailableUsers`
)
export const getProjectChanges = createAsyncThunk(
  `${moduleName}/getProjectChanges`,
  ({ projectId }: { projectId: number }) => getProjectChangesData(projectId)
)
export const clearGetProjectChanges = createAction(`${moduleName}/clearGetProjectChanges`)

export const updateProjectChanges = createAsyncThunk(
  `${moduleName}/updateProjectChanges`,
  ({ projectId }: { projectId: number }) => updateProjectChangesRequest(projectId)
)
export const clearUpdateProjectChanges = createAction(`${moduleName}/clearUpdateProjectChanges`)
export const syncWithSightline = createAsyncThunk(
  `${moduleName}/syncWithSightline`,
  ({ projectId }: { projectId: number }, { rejectWithValue }) =>
    syncWithSightlineRequest(projectId, rejectWithValue)
)
export const clearSyncWithSightline = createAction(`${moduleName}/clearSyncWithSightline`)
