import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { SDKList } from '../../../components/SDK/List'
import { SDKListItem } from '../../../components/SDK/ListItem'
import { SDKModal } from '../../../components/SDK/Modal'
import { SDKText } from '../../../components/SDK/Text'
import { useApp } from '../../../context/app.context'
import { LoadingStatus } from '../../../shared/types/enums'
import { useAppDispatch } from '../../../store'
import * as actions from '../store/actions'
import * as selectors from '../store/selectors'
import type { ICountry, ICountryListItem } from '../types'
import { CountryItem } from './CountryItem'

interface ICountriesListProps {
  countries: ICountryListItem[]
}

export const CountriesList = ({ countries }: ICountriesListProps) => {
  const { t } = useTranslation('countries')
  const { error, loading } = useSelector(selectors.updateCountryVisibility)
  const [showHideModal, setShowHideModal] = useState(false)
  const [country, setCountry] = useState<ICountry | null>(null)
  const { sendNotification } = useApp()
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(actions.clearUpdateCountryVisibility())
    }
  }, [])

  useEffect(() => {
    if (error?.length) {
      setShowHideModal(false)
      sendNotification({
        message: error,
        status: 'error'
      })
    }
    if (loading === LoadingStatus.Succeeded && country) {
      dispatch(actions.getCountries(country.yearId))
      sendNotification({
        message: t('modal.hide.success'),
        status: 'success'
      })
    }
  }, [error, loading])

  const hideCountry = (c: ICountry | null) => {
    setCountry(c)
    dispatch(
      actions.updateCountryVisibility({ yearId: c?.yearId || 0, countryId: c?.countryId || 0 })
    )
  }

  const renderItem = (item: ICountry, _: number) => {
    return (
      <SDKListItem key={`country-id-${item.countryId}`}>
        <CountryItem item={item} setCountry={setCountry} setShowHideModal={setShowHideModal} />
      </SDKListItem>
    )
  }
  return (
    <>
      <SDKList data={countries} itemKey={'countryId'} renderItem={renderItem}></SDKList>
      <SDKModal
        onCancel={() => setShowHideModal(false)}
        onSubmit={() => hideCountry(country)}
        title={`${t('modal.hide.title1')} ${country?.name} ${t('modal.hide.title2')}`}
        submitLabel={t('modal.hide.confirm')!}
        visible={showHideModal}
        withCancel={true}
        withRequired={false}
      >
        <SDKText>{`${t('modal.hide.content1')} ${country?.name} ${t(
          'modal.hide.content2'
        )}`}</SDKText>
      </SDKModal>
    </>
  )
}
