import { PropsWithChildren } from 'react'
import { SDKIcon } from '../../../../../../../../../components/SDK/Icon'
import { SDKText } from '../../../../../../../../../components/SDK/Text'
import { SDKTextButton } from '../../../../../../../../../components/SDK/TextButton'
import { useCardGridColumnCalculation } from '../../../../../../../../../utils/useCardGridColumnCalculation'
import { EPricingTab } from '../../../../../const/enums'
import type { IParentScopeYearTotal } from '../../../../../types/scope'
import { SingleRowGrid } from '../SingleRowGrid'
interface IResultNameWrapper {
  name?: EPricingTab
  isOptionalScope?: boolean
  customName?: string
  fees?: IParentScopeYearTotal[]
  iconCode?: string
  iconOnClickHandler?: () => void
}

export const ResultNameWrapper = ({
  children,
  name,
  isOptionalScope = false,
  customName,
  fees,
  iconCode,
  iconOnClickHandler
}: PropsWithChildren<IResultNameWrapper>) => {
  const { textCol, feeCol } = useCardGridColumnCalculation()

  return (
    <div
      className={`ap-bg-color-background-alt ap-border-radius-2 ${
        !isOptionalScope ? 'ap-mb-spacing-5 ap-p-spacing-6' : 'ap-px-spacing-6'
      } `}
    >
      <div className='row'>
        <div
          className={`col col-sm-${textCol} ${!isOptionalScope && 'border-right'} ${
            !fees && 'ap-pb-spacing-4'
          } ap-pr-spacing-6 ap-flex`}
        >
          <SDKText type='subheading' className='ap-text-neutral-17' weight={2}>
            {customName || name || ''}
          </SDKText>
          {iconCode && (
            <SDKTextButton onClick={iconOnClickHandler} className='ap-text-color-text-primary $}'>
              <SDKIcon code={iconCode} />
            </SDKTextButton>
          )}
        </div>
        {fees && (
          <div className={`col col-sm-${feeCol}`}>
            <SingleRowGrid fees={fees} />
          </div>
        )}
        {children}
      </div>
    </div>
  )
}
