export enum EEmailTemplateVariable {
  Name = 'Name',
  Surname = 'Surname',
  Country = 'Country',
  Region = 'Region',
  FiscalYear = 'Fiscal Year'
}

export const emailTemplateVariableList = [
  { id: 1, title: `User ${EEmailTemplateVariable.Name}`, value: EEmailTemplateVariable.Name },
  { id: 2, title: `User ${EEmailTemplateVariable.Surname}`, value: EEmailTemplateVariable.Surname },
  { id: 3, title: EEmailTemplateVariable.Country, value: EEmailTemplateVariable.Country },
  { id: 4, title: EEmailTemplateVariable.Region, value: EEmailTemplateVariable.Region },
  { id: 5, title: EEmailTemplateVariable.FiscalYear, value: EEmailTemplateVariable.FiscalYear }
]

export const EDITING_EMAIL_TEMPLATE = 'editing_email_data'
export const EDITING_LEGAL_STATEMENT_DOCUMENT = 'editing_legal_statement_document'
