import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SimpleCard } from '../../../../../../../../../../components/CardListItem/cards/SimpleCard'
import { EAction } from '../../../../../../../../../../components/CardListItem/const'
import { FormErrorMessage } from '../../../../../../../../../../components/Form/FormErrorText'
import { FormInput } from '../../../../../../../../../../components/Form/FormInput'
import { FormSelect } from '../../../../../../../../../../components/Form/FormSelect'
import { FormTextArea } from '../../../../../../../../../../components/Form/FormTextArea'
import { RHF_SET_VALUE_OPTIONS } from '../../../../../../../../../../components/Form/utils'
import { InfoBox } from '../../../../../../../../../../components/InfoBox'
import { SDKButton } from '../../../../../../../../../../components/SDK/Button'
import { SDKIcon } from '../../../../../../../../../../components/SDK/Icon'
import { SDKLoading } from '../../../../../../../../../../components/SDK/Loading'
import { SDKText } from '../../../../../../../../../../components/SDK/Text'
import { SDKTextButton } from '../../../../../../../../../../components/SDK/TextButton'
import { SDKTooltip } from '../../../../../../../../../../components/SDK/Tooltip'
import { Url } from '../../../../../../../../../../constants/urls'
import { useSightlineVisibility } from '../../../../../../../../../../context/hooks/useSightlineVisibility'
import { LoadingStatus } from '../../../../../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../../../../../store'
import * as yearsActions from '../../../../../../../../../fiscalYearSetup/store/actions'
import * as yearsSelectors from '../../../../../../../../../fiscalYearSetup/store/selectors'
import * as projectsActions from '../../../../../../../../store/actions'
import * as projectsSelectors from '../../../../../../../../store/selectors'
import { EProjectFields } from '../../../../../../../../types'
import { useCountriesData } from '../../../../hooks/useCountriesData'
import type { IAddEditProjectForm } from '../../types'

export const ProjectInfoStepForm = ({
  allPublishedCountries,
  control,
  countriesLoading,
  isDirty,
  onClickShowSightline,
  projectId,
  originalProjectId,
  originalProjectName,
  setValue,
  values
}: {
  isDirty: boolean
  originalProjectId: number | null
  originalProjectName: string | null
  onClickShowSightline: () => void
} & Pick<
  IAddEditProjectForm,
  | 'allPublishedCountries'
  | 'control'
  | 'countriesLoading'
  | 'onClickShowSightline'
  | 'projectId'
  | 'setValue'
  | 'trigger'
  | 'values'
>) => {
  const { t } = useTranslation('myprojects')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { visible } = useSightlineVisibility()
  const { data: currencies, loading: currenciesLoading } = useSelector(
    projectsSelectors.getCurrencies
  )
  const { data: years, loading: yearsLoading } = useSelector(yearsSelectors.getPublishedYears)
  const { currencyList, yearList } = useCountriesData(currencies, years)

  const removeEngagement = () => {
    setValue(EProjectFields.TpEngagementId, null, RHF_SET_VALUE_OPTIONS)
    setValue(EProjectFields.TpEngagementName, null, RHF_SET_VALUE_OPTIONS)
  }

  const shouldDisplayNoCountriesError =
    values[EProjectFields.YearId] > 0 &&
    !countriesLoading &&
    !allPublishedCountries.length &&
    !projectId

  const shouldDisplaySighline = visible

  const isLoading =
    currenciesLoading === LoadingStatus.Pending || yearsLoading === LoadingStatus.Pending

  useEffect(() => {
    dispatch(yearsActions.getPublishedYears())
    dispatch(projectsActions.getCurrencies())
  }, [])

  if (isLoading) {
    return <SDKLoading />
  }

  return (
    <>
      <div className='row'>
        <div className='col'>
          <SDKTooltip
            disabled={isDirty}
            content={t('projectForm.projectInfo.projectName.tooltip')!}
          >
            <FormInput
              name={EProjectFields.Name}
              type='text'
              label={t('projectForm.projectInfo.projectName.label')!}
              required={true}
              className='ap-mb-spacing-5'
              control={control}
              suffixIcon
            />
          </SDKTooltip>
        </div>
      </div>

      <div className='row'>
        <div className='col col-6'>
          <FormSelect
            disabled={!!projectId}
            name={EProjectFields.YearId}
            label={t('projectForm.projectInfo.fiscalYear.label')!}
            required={true}
            className='ap-mb-spacing-5'
            data={yearList}
            control={control}
            portalMode={true}
            onSelect={value => {
              setValue(EProjectFields.YearId, value, RHF_SET_VALUE_OPTIONS)
              setValue(
                EProjectFields.YearName,
                yearList.find(year => year.value === value)?.label,
                RHF_SET_VALUE_OPTIONS
              )
            }}
          />
          {shouldDisplayNoCountriesError && (
            <FormErrorMessage text={t('projectForm.errors.noCountrisForThisYear')} />
          )}
        </div>
        <div className='col col-6'>
          <FormSelect
            name={EProjectFields.OutputCurrencyId}
            label={t('projectForm.projectInfo.outputCurrency.label')!}
            required={true}
            className='ap-mb-spacing-5'
            data={currencyList}
            control={control}
            portalMode={true}
            onSelect={value => {
              setValue(EProjectFields.OutputCurrencyId, value, RHF_SET_VALUE_OPTIONS)
              setValue(
                EProjectFields.OutputCurrencySymbol,
                currencyList.find(currency => currency.value === value)?.label,
                RHF_SET_VALUE_OPTIONS
              )
            }}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <FormTextArea
            name={EProjectFields.Description}
            label={t('projectForm.projectInfo.description.label')}
            className='ap-mb-spacing-5'
            control={control}
          />
        </div>
      </div>
      {shouldDisplaySighline && (
        <>
          <div className='row ap-flex align-items-center'>
            <div className='col-8'>
              <SDKText className='ap-mb-spacing-3' type='body' weight={2}>
                {t('projectForm.projectInfo.engagementCenter.title')}
              </SDKText>
            </div>

            <div className='col-4 ap-flex justify-content-end'>
              <SDKButton kind='text' compact onClick={onClickShowSightline}>
                <SDKText weight={2}>
                  <>
                    <SDKIcon code='plus' />
                    {t(
                      `projectForm.projectInfo.engagementCenter.${
                        values[EProjectFields.TpEngagementId] ? 'editButton' : 'selectButton'
                      }.label`
                    )}
                  </>
                </SDKText>
              </SDKButton>
            </div>
          </div>

          <div className='row'>
            <div className='col mb-1'>
              {values[EProjectFields.TpEngagementId] ? (
                <div className='my-1'>
                  <SimpleCard
                    actions={[EAction.Delete]}
                    actionCallback={() => removeEngagement()}
                    item={{
                      id: values[EProjectFields.TpEngagementId],
                      name: values[EProjectFields.TpEngagementName]
                    }}
                  />
                </div>
              ) : (
                <SDKText className='text-uppercase'>{t('common:na')}</SDKText>
              )}
            </div>
          </div>

          <div className='row'>
            <div className='col'>
              <InfoBox
                margin='my-0'
                content={t('projectForm.projectInfo.engagementCenter.infoText')}
              />
            </div>
          </div>
        </>
      )}
      {originalProjectId && (
        <div className='row my-2'>
          <div className='ap-flex'>
            <SDKText className='ap-text-neutral-10'>
              {t('projectForm.projectInfo.copiedFrom')}
            </SDKText>
            <SDKTextButton
              onClick={() => navigate(`${Url.MyProjectsPage}/${originalProjectId}#CS-questions`)}
              className='ap-link text-no-wrap mx-1'
            >
              {`${originalProjectId} - ${originalProjectName}`}
            </SDKTextButton>
          </div>
        </div>
      )}
    </>
  )
}
