import type { IPowerBITemplate } from '../../../modules/powerBITemplates/types'
import { getUrlQueries } from '../../../utils/getUrlQueries'
import api from '../../api'
import { FiscalYearUrl, PowerBITemplateUrl } from '../../constants/urls'
import type { IAddPBITemplateUploadPayload } from './addPBITemplate'

export interface IEditPBITemplateUploadPayload extends IAddPBITemplateUploadPayload {
  templateId: number
}

export const updatePBITemplateRequest = async ({
  identifier,
  name,
  templateType,
  templateFile,
  templateId,
  thumbnailFile,
  yearId
}: IEditPBITemplateUploadPayload): Promise<IPowerBITemplate> => {
  const data = new FormData()
  data.append('templateFile', templateFile)
  data.append('thumbnailFile', thumbnailFile)

  const queries = getUrlQueries({ identifier, name, templateType })

  return (
    await api.put(
      `${FiscalYearUrl.Years}/${yearId}/${PowerBITemplateUrl.PowerBITemplates}/${templateId}${queries}`,
      data,
      {
        ['Content-Type']: 'multipart/form-data'
      }
    )
  ).data
}
