import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormCombobox } from '../../../../../../../../../../components/Form/FormCombobox'
import { RHF_SET_VALUE_OPTIONS } from '../../../../../../../../../../components/Form/utils'
import { InfoBox } from '../../../../../../../../../../components/InfoBox'
import { SDKButton } from '../../../../../../../../../../components/SDK/Button'
import type { TSelectValue } from '../../../../../../../../../../components/SDK/Combobox'
import { SDKIcon } from '../../../../../../../../../../components/SDK/Icon'
import { LoadingSpinner } from '../../../../../../../../../../components/SDK/LoadingSpinner'
import { SDKParagraph } from '../../../../../../../../../../components/SDK/Paragraph'
import { useApp } from '../../../../../../../../../../context/app.context'
import { LoadingStatus } from '../../../../../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../../../../../store'
import * as countriesActions from '../../../../../../../../../countries/store/actions'
import * as actions from '../../../../../../../../store/actions'
import * as selectors from '../../../../../../../../store/selectors'
import { EProjectFields } from '../../../../../../../../types'
import { useCountries } from '../../hooks/useCountries'
import type { IAddEditProjectForm } from '../../types'
import { CountriesList } from '../CountryList/CountriesList'

import './index.scss'

export const ProjectCountriesStepForm = ({
  allPublishedCountries,
  projectId,
  resetField,
  setValue,
  trigger,
  values,
  hasChangedValues
}: Pick<
  IAddEditProjectForm,
  | 'allPublishedCountries'
  | 'projectId'
  | 'resetField'
  | 'setValue'
  | 'trigger'
  | 'values'
  | 'hasChangedValues'
>) => {
  const { t, i18n } = useTranslation('myprojects')
  const { selectedCountries, offeredCountries } = useCountries(allPublishedCountries, values)
  const dispatch = useAppDispatch()
  const shouldSyncWithSightline = !!projectId && values.tpEngagementId
  const sync = useSelector(selectors.syncWithSightline)
  const { sendNotification } = useApp()

  const syncWithSightline = () => {
    if (projectId) {
      dispatch(actions.syncWithSightline({ projectId }))
    }
  }

  useEffect(() => {
    if (sync.error?.length) {
      sendNotification({
        message: sync.error,
        status: 'error'
      })
    }

    if (sync.loading === LoadingStatus.Succeeded && sync.data) {
      sendNotification({
        message: i18n.t('syncWithSightlineSuccessInfo'),
        status: 'success'
      })
      dispatch(countriesActions.getPublishedCountries(values.yearId))
      setValue(
        EProjectFields.CountryIds,
        sync.data.map(country => String(country.countryId)),
        RHF_SET_VALUE_OPTIONS
      )
    }
  }, [sync])

  useEffect(() => {
    return function cleanUp() {
      dispatch(actions.clearSyncWithSightline())
    }
  }, [])

  const onSelect = (value: TSelectValue) => {
    const newValues = [...values[EProjectFields.CountryIds], ...(value as string[])].filter(Boolean)
    if (
      (newValues?.length === 0 || (Array.isArray(value) && value.length === 0)) &&
      offeredCountries.length
    ) {
      newValues.push(offeredCountries[0].value)
    }

    setValue(EProjectFields.CountryIds, newValues, RHF_SET_VALUE_OPTIONS)
    resetField('countries')
    trigger()
  }

  return (
    <>
      <div className='row'>
        {shouldSyncWithSightline && (
          <div className='col border-1 ap-border-color-background-border ap-border-radius-2 ap-m-spacing-4 ap-p-spacing-5 ap-flex justify-content-center flex-column'>
            {sync.loading === LoadingStatus.Pending ? (
              <LoadingSpinner />
            ) : (
              <>
                <SDKButton
                  kind='secondary'
                  className='ap-mb-spacing-4'
                  disabled={hasChangedValues}
                  onClick={() => syncWithSightline()}
                >
                  <SDKIcon code='change'></SDKIcon>
                  {i18n.t('buttons.syncWithSightline')}
                </SDKButton>
                <SDKParagraph>
                  {t('projectForm.projectCountries.syncWithSightline.description')}
                </SDKParagraph>
              </>
            )}
          </div>
        )}
      </div>
      <div className='row'>
        <div className='col'>
          <FormCombobox data={offeredCountries} disabled={hasChangedValues} onSelect={onSelect} />
        </div>
      </div>

      <CountriesList
        countries={selectedCountries}
        handleClick={countryId => {
          setValue(
            EProjectFields.CountryIds,
            values[EProjectFields.CountryIds].filter((id: string) => id !== String(countryId)),
            RHF_SET_VALUE_OPTIONS
          )

          trigger()
        }}
      />

      <div className='row'>
        <div className='col'>
          <InfoBox content={t('projectForm.projectCountries.infoText')} />
        </div>
      </div>
    </>
  )
}
