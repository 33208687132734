import { useEffect, useState } from 'react'
import type { Control, FieldValues, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormCard } from '../../../../../../components/Form/FormCard'
import { FormUploadInput } from '../../../../../../components/Form/FormUploadInput'
import { RHF_SET_VALUE_OPTIONS } from '../../../../../../components/Form/utils'
import { LoadingOverlay } from '../../../../../../components/SDK/LoadingOverlay'
import { SDKText } from '../../../../../../components/SDK/Text'
import {
  EUploadPricingToolImportFields,
  IFormValues
} from '../../../../../../schemas/useUploadPricingToolImportSchema'
import { LoadingStatus } from '../../../../../../shared/types/enums'
import {
  DATE_ISO_FORMAT_WITH_TIME,
  GMT_TIME,
  formatUtc
} from '../../../../../../utils/dateFormatters'
import { addPricingToolsImport } from '../../../../store/selectors'
import { IPricingToolsImportInfo } from '../../../../types'

interface IAddImportFormForm {
  control: Control<FieldValues, IFormValues>
  pricingToolsImportInfo?: IPricingToolsImportInfo | null
  setValue: UseFormSetValue<FieldValues>
}

export const AddImportForm = ({
  control,
  pricingToolsImportInfo,
  setValue
}: IAddImportFormForm) => {
  const { t } = useTranslation('systemManagement')
  const { loading } = useSelector(addPricingToolsImport)
  const [shouldReloadComponent, setShouldReloadComponent] = useState(false)
  const ACCEPTED_FILE_TYPE = '.xlsx'

  useEffect(() => {
    setTimeout(() => {
      setShouldReloadComponent(false)
      setValue(EUploadPricingToolImportFields.inputFile, null, RHF_SET_VALUE_OPTIONS)
    }, 100)
  }, [shouldReloadComponent])

  return (
    <>
      <div className='legal-document-form'>
        <div className='ap-mt-spacing-4'>
          <FormCard title={t('pricingToolImport.importInfo')}>
            <div className='row'>
              <div className='col ap-mb-spacing-5'>
                <SDKText type='body' className='ap-text-neutral-14'>
                  {`${t('pricingToolImport.lastImportDate')}: ${
                    `${formatUtc(
                      pricingToolsImportInfo?.importTimeStamp,
                      DATE_ISO_FORMAT_WITH_TIME
                    )} ${GMT_TIME}` || t('pricingToolImport.na')
                  } ${t('by')}: ${pricingToolsImportInfo?.importedBy}`}
                </SDKText>
              </div>
            </div>

            <div className='ap-my-spacing-4'>
              {!shouldReloadComponent && (
                <FormUploadInput
                  name={EUploadPricingToolImportFields.inputFile}
                  control={control}
                  label={t('pricingToolImport.uploadInstruction')!}
                  acceptFileType={ACCEPTED_FILE_TYPE}
                  onErrorFunction={() => setShouldReloadComponent(true)}
                />
              )}
            </div>
          </FormCard>
        </div>
      </div>
      {loading === LoadingStatus.Pending && <LoadingOverlay />}
    </>
  )
}
