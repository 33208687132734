import { ICountry } from '../../../modules/countries/types'
import api from '../../api'
import { CountryUrl, FiscalYearUrl, QuestionnaireUrl } from '../../constants/urls'

export const updateVerifyCountry = async ({
  yearId,
  countryId
}: {
  yearId: number
  countryId: number
}): Promise<ICountry> => {
  return (
    await api.patch(
      `${FiscalYearUrl.Years}/${yearId}/${CountryUrl.Countries}/${countryId}/${QuestionnaireUrl.IsVerified}`,
      true
    )
  ).data
}
