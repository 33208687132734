import { createReducer } from '@reduxjs/toolkit'
import {
  getDefaultResourceState,
  Resource,
  setResourceFullfilled,
  setResourcePending,
  setResourceRejected
} from '../../../shared/utils/store'
import {
  IAddEditLegalDocument,
  IEmailTemplate,
  ILegalDocument,
  ILegalDocumentWithApproval,
  IPricingToolsImportInfo
} from '../types'
import {
  addApproveLegalDocument,
  addLegalDocument,
  addPricingToolsImport,
  clearAddApproveLegalDocument,
  clearAddLegalDocument,
  clearAddPricingToolsImport,
  clearCreateLegalDocumentTest,
  clearDeleteLegalDocument,
  clearDownloadPricingToolImport,
  clearDownloadProjectsReport,
  clearDownloadQuestionsAndAnswersReport,
  clearDownloadRDPFormulasReport,
  clearDownloadUsersReport,
  clearGetEmailTemplates,
  clearGetLegalDocuments,
  clearGetLegalDocumentsPublic,
  clearGetPricingToolsImportInfo,
  clearGetUnapprovedLegalDocuments,
  clearUpdateEmailTemplate,
  clearUpdateLegalDocument,
  createLegalDocumentTest,
  deleteLegalDocument,
  downloadPricingToolImport,
  downloadProjectsReport,
  downloadQuestionsAndAnswersReport,
  downloadRDPFormulasReport,
  downloadUsersReport,
  getEmailTemplates,
  getLegalDocuments,
  getLegalDocumentsPublic,
  getPricingToolsImportInfo,
  getUnapprovedLegalDocuments,
  updateEmailTemplate,
  updateLegalDocument
} from './actions'

export interface SystemManagementState {
  emailTemplates: Resource<IEmailTemplate[]>
  updateEmailTemplate: Resource<IEmailTemplate | null>
  legalDocuments: Resource<ILegalDocument[]>
  legalDocumentsPublic: Resource<ILegalDocumentWithApproval[]>
  unapprovedLegalDocuments: Resource<ILegalDocumentWithApproval[]>
  addApproveLegalDocument: Resource<string | null>
  addLegalDocument: Resource<IAddEditLegalDocument | null>
  updateLegalDocument: Resource<IAddEditLegalDocument | null>
  deleteLegalDocument: Resource<null>
  createLegalDocumentTest: Resource<string | null>
  projectsReport: Resource<File | null>
  questionsAndAnswersReport: Resource<File | null>
  rdpFormulasReport: Resource<File | null>
  usersReport: Resource<File | null>
  pricingToolsImportInfo: Resource<IPricingToolsImportInfo | null>
  addPricingToolsImport: Resource<string | null>
  pricingToolsImportTemplate: Resource<File | null>
}

const initialState: SystemManagementState = {
  emailTemplates: getDefaultResourceState([]),
  updateEmailTemplate: getDefaultResourceState(null),
  legalDocuments: getDefaultResourceState([]),
  legalDocumentsPublic: getDefaultResourceState([]),
  unapprovedLegalDocuments: getDefaultResourceState([]),
  addApproveLegalDocument: getDefaultResourceState(null),
  addLegalDocument: getDefaultResourceState(null),
  updateLegalDocument: getDefaultResourceState(null),
  deleteLegalDocument: getDefaultResourceState(null),
  createLegalDocumentTest: getDefaultResourceState(null),
  projectsReport: getDefaultResourceState(null),
  questionsAndAnswersReport: getDefaultResourceState(null),
  rdpFormulasReport: getDefaultResourceState(null),
  usersReport: getDefaultResourceState(null),
  pricingToolsImportInfo: getDefaultResourceState(null),
  addPricingToolsImport: getDefaultResourceState(null),
  pricingToolsImportTemplate: getDefaultResourceState(null)
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getEmailTemplates.pending, state => {
      setResourcePending(state.emailTemplates)
    })
    .addCase(getEmailTemplates.rejected, (state, actions) => {
      setResourceRejected(state.emailTemplates, actions)
    })
    .addCase(getEmailTemplates.fulfilled, (state, actions) => {
      state.emailTemplates.data = actions.payload
      setResourceFullfilled(state.emailTemplates)
    })
    .addCase(clearGetEmailTemplates, state => {
      state.emailTemplates = initialState.emailTemplates
    })
    .addCase(updateEmailTemplate.pending, state => {
      setResourcePending(state.updateEmailTemplate)
    })
    .addCase(updateEmailTemplate.rejected, (state, actions) => {
      setResourceRejected(state.updateEmailTemplate, actions)
    })
    .addCase(updateEmailTemplate.fulfilled, (state, actions) => {
      setResourceFullfilled(state.updateEmailTemplate)
      state.updateEmailTemplate.data = actions.payload
    })
    .addCase(clearUpdateEmailTemplate, state => {
      state.updateEmailTemplate = initialState.updateEmailTemplate
    })
    .addCase(getLegalDocuments.pending, state => {
      setResourcePending(state.legalDocuments)
    })
    .addCase(getLegalDocuments.rejected, (state, actions) => {
      setResourceRejected(state.legalDocuments, actions)
    })
    .addCase(getLegalDocuments.fulfilled, (state, actions) => {
      state.legalDocuments.data = actions.payload
      setResourceFullfilled(state.legalDocuments)
    })
    .addCase(clearGetLegalDocuments, state => {
      state.legalDocuments = initialState.legalDocuments
    })

    .addCase(getLegalDocumentsPublic.pending, state => {
      setResourcePending(state.legalDocumentsPublic)
    })
    .addCase(getLegalDocumentsPublic.rejected, (state, actions) => {
      setResourceRejected(state.legalDocumentsPublic, actions)
    })
    .addCase(getLegalDocumentsPublic.fulfilled, (state, actions) => {
      state.legalDocumentsPublic.data = actions.payload
      setResourceFullfilled(state.legalDocumentsPublic)
    })
    .addCase(clearGetLegalDocumentsPublic, state => {
      state.legalDocumentsPublic = initialState.legalDocumentsPublic
    })

    .addCase(getUnapprovedLegalDocuments.pending, state => {
      setResourcePending(state.unapprovedLegalDocuments)
    })
    .addCase(getUnapprovedLegalDocuments.rejected, (state, actions) => {
      setResourceRejected(state.unapprovedLegalDocuments, actions)
    })
    .addCase(getUnapprovedLegalDocuments.fulfilled, (state, actions) => {
      state.unapprovedLegalDocuments.data = actions.payload
      setResourceFullfilled(state.unapprovedLegalDocuments)
    })
    .addCase(clearGetUnapprovedLegalDocuments, state => {
      state.unapprovedLegalDocuments = initialState.unapprovedLegalDocuments
    })
    .addCase(addApproveLegalDocument.pending, state => {
      setResourcePending(state.addApproveLegalDocument)
    })
    .addCase(addApproveLegalDocument.rejected, (state, actions) => {
      setResourceRejected(state.addApproveLegalDocument, actions)
    })
    .addCase(addApproveLegalDocument.fulfilled, (state, actions) => {
      state.addApproveLegalDocument.data = actions.payload
      setResourceFullfilled(state.addApproveLegalDocument)
    })
    .addCase(clearAddApproveLegalDocument, state => {
      state.addApproveLegalDocument = initialState.addApproveLegalDocument
    })
    .addCase(addLegalDocument.pending, state => {
      setResourcePending(state.addLegalDocument)
    })
    .addCase(addLegalDocument.rejected, (state, actions) => {
      setResourceRejected(state.addLegalDocument, actions)
    })
    .addCase(addLegalDocument.fulfilled, (state, actions) => {
      state.addLegalDocument.data = actions.payload
      setResourceFullfilled(state.addLegalDocument)
    })
    .addCase(clearAddLegalDocument, state => {
      state.addLegalDocument = initialState.addLegalDocument
    })
    .addCase(updateLegalDocument.pending, state => {
      setResourcePending(state.updateLegalDocument)
    })
    .addCase(updateLegalDocument.rejected, (state, actions) => {
      setResourceRejected(state.updateLegalDocument, actions)
    })
    .addCase(updateLegalDocument.fulfilled, (state, actions) => {
      state.updateLegalDocument.data = actions.payload
      setResourceFullfilled(state.updateLegalDocument)
    })
    .addCase(clearUpdateLegalDocument, state => {
      state.updateLegalDocument = initialState.updateLegalDocument
    })

    .addCase(deleteLegalDocument.pending, state => {
      setResourcePending(state.deleteLegalDocument)
    })
    .addCase(deleteLegalDocument.rejected, (state, actions) => {
      setResourceRejected(state.deleteLegalDocument, actions)
    })
    .addCase(deleteLegalDocument.fulfilled, (state, actions) => {
      state.deleteLegalDocument.data = actions.payload
      setResourceFullfilled(state.deleteLegalDocument)
    })
    .addCase(clearDeleteLegalDocument, state => {
      state.deleteLegalDocument = initialState.deleteLegalDocument
    })

    .addCase(createLegalDocumentTest.pending, state => {
      setResourcePending(state.createLegalDocumentTest)
    })
    .addCase(createLegalDocumentTest.rejected, (state, actions) => {
      setResourceRejected(state.createLegalDocumentTest, actions)
    })
    .addCase(createLegalDocumentTest.fulfilled, (state, actions) => {
      state.createLegalDocumentTest.data = actions.payload
      setResourceFullfilled(state.createLegalDocumentTest)
    })
    .addCase(clearCreateLegalDocumentTest, state => {
      state.createLegalDocumentTest = initialState.createLegalDocumentTest
    })

    .addCase(downloadProjectsReport.pending, state => {
      setResourcePending(state.projectsReport)
    })
    .addCase(downloadProjectsReport.rejected, (state, actions) => {
      setResourceRejected(state.projectsReport, actions)
    })
    .addCase(downloadProjectsReport.fulfilled, (state, actions) => {
      state.projectsReport.data = actions.payload
      setResourceFullfilled(state.projectsReport)
    })
    .addCase(clearDownloadProjectsReport, state => {
      state.projectsReport = initialState.projectsReport
    })

    .addCase(downloadQuestionsAndAnswersReport.pending, state => {
      setResourcePending(state.questionsAndAnswersReport)
    })
    .addCase(downloadQuestionsAndAnswersReport.rejected, (state, actions) => {
      setResourceRejected(state.questionsAndAnswersReport, actions)
    })
    .addCase(downloadQuestionsAndAnswersReport.fulfilled, (state, actions) => {
      state.questionsAndAnswersReport.data = actions.payload
      setResourceFullfilled(state.questionsAndAnswersReport)
    })
    .addCase(clearDownloadQuestionsAndAnswersReport, state => {
      state.questionsAndAnswersReport = initialState.questionsAndAnswersReport
    })

    .addCase(downloadRDPFormulasReport.pending, state => {
      setResourcePending(state.rdpFormulasReport)
    })
    .addCase(downloadRDPFormulasReport.rejected, (state, actions) => {
      setResourceRejected(state.rdpFormulasReport, actions)
    })
    .addCase(downloadRDPFormulasReport.fulfilled, (state, actions) => {
      state.rdpFormulasReport.data = actions.payload
      setResourceFullfilled(state.rdpFormulasReport)
    })
    .addCase(clearDownloadRDPFormulasReport, state => {
      state.rdpFormulasReport = initialState.rdpFormulasReport
    })

    .addCase(downloadUsersReport.pending, state => {
      setResourcePending(state.usersReport)
    })
    .addCase(downloadUsersReport.rejected, (state, actions) => {
      setResourceRejected(state.usersReport, actions)
    })
    .addCase(downloadUsersReport.fulfilled, (state, actions) => {
      state.usersReport.data = actions.payload
      setResourceFullfilled(state.usersReport)
    })
    .addCase(clearDownloadUsersReport, state => {
      state.usersReport = initialState.usersReport
    })

    .addCase(getPricingToolsImportInfo.pending, state => {
      setResourcePending(state.pricingToolsImportInfo)
    })
    .addCase(getPricingToolsImportInfo.rejected, (state, actions) => {
      setResourceRejected(state.pricingToolsImportInfo, actions)
    })
    .addCase(getPricingToolsImportInfo.fulfilled, (state, actions) => {
      state.pricingToolsImportInfo.data = actions.payload
      setResourceFullfilled(state.pricingToolsImportInfo)
    })
    .addCase(clearGetPricingToolsImportInfo, state => {
      state.pricingToolsImportInfo = initialState.pricingToolsImportInfo
    })

    .addCase(addPricingToolsImport.pending, state => {
      setResourcePending(state.addPricingToolsImport)
    })
    .addCase(addPricingToolsImport.rejected, (state, actions) => {
      setResourceRejected(state.addPricingToolsImport, actions)
    })
    .addCase(addPricingToolsImport.fulfilled, (state, actions) => {
      state.addPricingToolsImport.data = actions.payload
      setResourceFullfilled(state.addPricingToolsImport)
    })
    .addCase(clearAddPricingToolsImport, state => {
      state.addPricingToolsImport = initialState.addPricingToolsImport
    })

    .addCase(downloadPricingToolImport.pending, state => {
      setResourcePending(state.pricingToolsImportTemplate)
    })
    .addCase(downloadPricingToolImport.rejected, (state, actions) => {
      setResourceRejected(state.pricingToolsImportTemplate, actions)
    })
    .addCase(downloadPricingToolImport.fulfilled, (state, actions) => {
      state.pricingToolsImportTemplate.data = actions.payload
      setResourceFullfilled(state.pricingToolsImportTemplate)
    })
    .addCase(clearDownloadPricingToolImport, state => {
      state.pricingToolsImportTemplate = initialState.pricingToolsImportTemplate
    })
)
