import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'
import {
  EPowerBITemplateFields,
  type IPowerBITemplate,
  type IPowerBITemplateForm
} from '../modules/powerBITemplates/types'
import { ETemplateType } from '../modules/project/components/GCDPricing/const/enums'
import { IOption } from '../types/form'

export type IFormValues = IPowerBITemplateForm

export const useAddEditPowerBITemplateSchema = (template?: IPowerBITemplate) => {
  const { t } = useTranslation('documents')

  const defaultValues: IFormValues = {
    [EPowerBITemplateFields.Identifier]: template?.identifier || '',
    [EPowerBITemplateFields.Name]: template?.name || '',
    [EPowerBITemplateFields.TemplateType]: String(template?.templateType) || '0',
    [EPowerBITemplateFields.TemplateFile]: null,
    [EPowerBITemplateFields.TemplateFileName]: template?.templateFileName || '',
    [EPowerBITemplateFields.TemplateId]: template?.templateId || 0,
    [EPowerBITemplateFields.ThumbnailFile]: null,
    [EPowerBITemplateFields.ThumbnailFileName]: template?.thumbnailFileName || ''
  }

  let objectSchema: {} = {
    [EPowerBITemplateFields.Name]: string().required(t('projectForm.form.errors.name')!)
  }

  if (!template) {
    objectSchema = {
      ...objectSchema,
      [EPowerBITemplateFields.TemplateFile]: object().required(t('projectForm.form.errors.file')!),
      [EPowerBITemplateFields.ThumbnailFile]: object().required(
        t('projectForm.form.errors.thumbnal')!
      )
    }
  }

  const templateTypeRadioList: IOption[] = [
    {
      value: ETemplateType.TaraFull.toString(),
      label: t('powerBITemplates.form.templateType.taraFull')
    },
    {
      value: ETemplateType.TaraCalendarOnly.toString(),
      label: t('powerBITemplates.form.templateType.taraCalendarOnly')
    }
  ]

  const validationSchema = object().shape(objectSchema).required()

  return {
    defaultValues,
    validationSchema,
    templateTypeRadioList
  }
}
