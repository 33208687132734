import { useEffect } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { useController } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useApp } from '../../../../../../../context/app.context'
import {
  EAddEditDocumentFields,
  IFormValues
} from '../../../../../../../schemas/useAddEditDocumentSchema'
import { DEFAULT_DELAY, useDebounce } from '../../../../../../../shared/utils/useDebounce'

export const useAddEditDocumentForm = (
  control: Control<FieldValues, IFormValues>,
  error?: string | null
) => {
  const { id } = useParams()
  const { sendNotification } = useApp()

  const {
    field: { value }
  } = useController({ name: EAddEditDocumentFields.documentName, control })
  const {
    field: { value: idValue, onChange }
  } = useController({ name: EAddEditDocumentFields.identifier, control })

  const debouncedValue = useDebounce<string>(value, DEFAULT_DELAY)

  useEffect(() => {
    if (!id) {
      const documentID = value.replace(/\s+/g, '_').toLowerCase()
      onChange(documentID)
    }
  }, [debouncedValue])

  useEffect(() => {
    if (error?.length && !error?.includes('already exists')) {
      sendNotification({
        message: error,
        status: 'error'
      })
    }
  }, [error])

  return { idValue }
}
