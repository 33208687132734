import { useMemo } from 'react'
import { object, string } from 'yup'
import { IFormulaDescription } from '../modules/TaraFields/types'
import type { IOption } from '../types/form'

export interface ISelectOption extends IOption {
  descValue: string
}

export enum EEditFieldNameFields {
  name = 'name',
  description = 'description'
}
export interface IFormValues {
  [EEditFieldNameFields.name]: string
  [EEditFieldNameFields.description]: string
}

export const useEditFieldNameSchema = (field: IFormulaDescription | null) => {
  const getDefaultValues = (obj: IFormulaDescription | null) => ({
    [EEditFieldNameFields.name]: obj?.fieldName || '',
    [EEditFieldNameFields.description]: obj?.description || ''
  })

  const defaultValues = useMemo(() => getDefaultValues(field), [field])
  const validationSchema = object()
    .shape({
      [EEditFieldNameFields.name]: string().required(),
      [EEditFieldNameFields.description]: string().required()
    })
    .required()

  return { validationSchema, defaultValues }
}
