import { SDKParagraph } from '../../../../../../components/SDK/Paragraph'
import { SDKText } from '../../../../../../components/SDK/Text'

interface IResultsData {
  label: string
  paragraph?: string
  value: string | null
}

export const ResultsData = ({ data }: { data: IResultsData[] }) => {
  return (
    <div className='row ap-mb-spacing-2'>
      {data?.map((col, index) => (
        <SDKParagraph key={`results-meta-data-${index}`} className='col'>
          <>
            <SDKText weight={3}>{col.label}</SDKText> {col.value}
            {col.paragraph && (
              <>
                <br />
                {col.paragraph}
              </>
            )}
          </>
        </SDKParagraph>
      )) || null}
    </div>
  )
}
