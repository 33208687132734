import Radio from '@appkit4/react-components/esm/radio/Radio'
import RadioGroup from '@appkit4/react-components/esm/radio/RadioGroup'
import classNames from 'classnames'
import { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '../../../../../../components/ActionButton'
import { SDKButton } from '../../../../../../components/SDK/Button'
import { LoadingOverlay } from '../../../../../../components/SDK/LoadingOverlay'
import { SDKPanel } from '../../../../../../components/SDK/Panel'
import { SDKSwitch } from '../../../../../../components/SDK/Switch'
import { SDKText } from '../../../../../../components/SDK/Text'
import type { IMetadataParams } from '../../../../../../services/requests/pricing/updateModuleMetadataRequest'
import { LoadingStatus } from '../../../../../../shared/types/enums'
import { EPricingTab } from '../../const/enums'
import { usePermittedTab } from '../../hooks/usePermittedTab'
import styles from './index.module.scss'
import { useUpdateMetadata } from './utils/useUpdateMetadata'

interface IScopeConfigurator {
  name: EPricingTab
  moduleMetadata?: IMetadataParams
  customName?: string
  customQuestion?: string
  withOptionalSwitch?: boolean
  withRadioBtn?: boolean
  withEditName?: boolean
  withDelete?: boolean
  onDelete?: (value: boolean) => void
  onEditName?: (value: boolean) => void
  customModuleId?: number
}

export const ScopeConfigurator = ({
  name,
  customName,
  customQuestion,
  withOptionalSwitch = true,
  withRadioBtn = true,
  children,
  moduleMetadata,
  withEditName,
  withDelete,
  onDelete,
  onEditName,
  customModuleId
}: PropsWithChildren<IScopeConfigurator>) => {
  const { t, i18n } = useTranslation('pricing')
  const [isEnabled, setIsEnable] = useState(moduleMetadata?.isEnabled)
  const [isOptional, setIsOptional] = useState(moduleMetadata?.isOptional)
  const [isApplicable, setIsApplicable] = useState(false)
  const { updateMetadata, loading } = useUpdateMetadata(name, customModuleId)
  usePermittedTab(moduleMetadata?.isPermitted || false)

  useEffect(() => {
    setIsOptional(moduleMetadata?.isOptional)
    setIsEnable(moduleMetadata?.isEnabled)
    setIsApplicable(Boolean(moduleMetadata?.isApplicable))
  }, [moduleMetadata])

  const options = [
    { value: 1, label: i18n.t('radioList.yes') },
    { value: 0, label: i18n.t('radioList.no') }
  ]
  const title = customName || t(`inputs.tabs.${name}.tabTitle`)
  const params = { isApplicable, isEnabled, isOptional, discount: moduleMetadata?.discount }

  const onOptionalChange = (value: boolean) => {
    setIsOptional(value)
    updateMetadata({ ...params, name: customName || undefined, isOptional: value })
  }

  const onEnableChange = (value: boolean) => {
    setIsEnable(value)
    updateMetadata({ ...params, name: customName || undefined, isEnabled: value })
  }

  const onRadioButtonChange = (value: number) => {
    const isApplicableValue = Boolean(value)
    if (!isApplicableValue) {
      setIsEnable(false)
      setIsApplicable(isApplicableValue)
      updateMetadata({
        ...params,
        isApplicable: isApplicableValue,
        name: customName || undefined,
        isEnabled: false
      })

      return
    }

    setIsApplicable(isApplicableValue)
    updateMetadata({ ...params, isApplicable: isApplicableValue })
  }

  return (
    <>
      {loading === LoadingStatus.Pending && <LoadingOverlay />}
      <SDKPanel
        className={classNames(
          styles['scope-configurator'],
          'border-1 ap-border-color-background-border ap-mb-spacing-5 ap-mt-spacing-3'
        )}
      >
        <div
          className={classNames(
            'ap-flex flex-wrap justify-content-between align-items-center',
            withRadioBtn ? 'ap-mb-spacing-5' : ''
          )}
        >
          <div className='ap-flex align-items-center'>
            <SDKText
              type='heading'
              weight={2}
              className='ap-text-color-text-heading ap-mr-spacing-1'
            >
              {title}
            </SDKText>
            {withEditName && (
              <ActionButton icon='edit' content='' handleClick={() => onEditName?.(true)} />
            )}
          </div>
          <div className='ap-flex'>
            {withOptionalSwitch && (
              <SDKSwitch
                onChange={onOptionalChange}
                className='ap-mr-spacing-5'
                checked={isOptional}
              >
                {i18n.t('buttons.optional')!}
              </SDKSwitch>
            )}
            <SDKSwitch onChange={onEnableChange} checked={isEnabled}>
              {i18n.t('buttons.enable')!}
            </SDKSwitch>
            {withDelete && (
              <SDKButton
                className='ap-ml-spacing-4'
                icon='icon-delete-outline'
                kind='text'
                onClick={() => onDelete?.(true)}
              >
                {i18n.t('buttons.delete')}
              </SDKButton>
            )}
          </div>
        </div>
        {withRadioBtn && (
          <>
            <SDKText>
              {customQuestion || t('tabs.inputs.scopeConfiguratorQuestion', { name: title })}
            </SDKText>

            <RadioGroup
              defaultValue={isApplicable ? 1 : 0}
              onChange={(value: number) => onRadioButtonChange(value)}
              className={'sdk-radio flex ap-mt-spacing-3'}
              value={isApplicable ? 1 : 0}
            >
              <div className={classNames('sdk-radio flex row')}>
                {options?.map(({ value, label }) => (
                  <div key={`${value}-radio`} className={'col-4 ap-mr-spacing-3'}>
                    <Radio value={value}>{label}</Radio>
                  </div>
                ))}
              </div>
            </RadioGroup>
          </>
        )}
        {children}
      </SDKPanel>
    </>
  )
}
