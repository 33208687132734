import { useTranslation } from 'react-i18next'
import { SDKButton } from '../../../../../../components/SDK/Button'
import { SDKIcon } from '../../../../../../components/SDK/Icon'

interface IQuestionHeaderProps {
  onButtonClick: () => void
  isValid: boolean
}

const QuestionHeader = ({ onButtonClick, isValid }: IQuestionHeaderProps) => {
  const { t } = useTranslation('countriesSnapshot')

  return (
    <div className='question-header'>
      <SDKButton onClick={() => onButtonClick()} className='expandable-button' disabled={!isValid}>
        <SDKIcon code='save'></SDKIcon>
        {t('newQuestion.saveQuestionButton')!}
      </SDKButton>
    </div>
  )
}

export default QuestionHeader
